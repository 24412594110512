import { FormInstance } from 'antd';
import { Form, message } from 'antd';
import { useApp } from '@/utils/useapp';
import { useEffect } from 'react';
import { showErrorMessage } from '@/utils/show-error-message';

export const useFetchDistance = (form: FormInstance) => {
  const app = useApp();

  const pickupCityId = Form.useWatch('pickup_city_id', form);
  const destinationCityId = Form.useWatch('destination_city_id', form);

  const fetchDistance = async (
    fromCityId: number | undefined,
    toCityId: number | undefined,
  ) => {
    if (!fromCityId || !toCityId) {
      return;
    }

    try {
      const resp = await app.service.get('tools/city-distance', {
        params: {
          from_city_id: fromCityId,
          to_city_id: toCityId,
        },
      });
      return resp.data.distance_mile;
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  const updateDistance = async (fromCityId: number, toCityId: number) => {
    const distance = await fetchDistance(fromCityId, toCityId);
    form.setFieldValue('distance', distance);
  };

  useEffect(() => {
    updateDistance(pickupCityId, destinationCityId);
  }, [pickupCityId, destinationCityId]);
  return [fetchDistance];
};
