import { Form, Row, Col, Input, Button, Space, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { ReactElement } from 'react';
import { chain, get, trim, uniq, difference } from 'lodash';

interface ISMSForm {
  form: FormInstance;
  sendToElement?: ReactElement;
  sendToExtra?: ReactElement;
  bodyExtra?: ReactElement;
  onChange?: (name: string, value: any) => void;
}

export const validateForm = (values: any) => {
  if (!values.sendTo) {
    message.error('Please Input Send To');
    return false;
  }

  if (!values.body) {
    message.error('Please Input Body');
    return false;
  }

  return true;
};

const SMSForm: React.FC<ISMSForm> = ({ form, bodyExtra, onChange }) => {
  const handleOnChange = async (name: string, value: any) => {
    form.setFieldValue(name, value);
  };

  const initForm = () => {
    const values = form.getFieldsValue();

    if (!get(values, 'sendTo', false)) {
      values.sendToList = [''];
    }

    if (difference(values, form.getFieldsValue())) {
      form.setFieldsValue(values);
    }
  };

  React.useEffect(() => {
    initForm();
  }, []);

  return (
    <>
      <Form.Item
        label={'Send To'}
        name="sendTo"
        rules={[{ required: true, message: 'required' }]}
      >
        <Input id="sendTo" placeholder="Enter send to" />
      </Form.Item>
      <Form.Item
        label={<>Body: {bodyExtra && bodyExtra}</>}
        name="message"
        rules={[{ required: true, message: 'required' }]}
      >
        <Input.TextArea
          id="message"
          rows={12}
          placeholder="Enter message body"
        />
      </Form.Item>
    </>
  );
};

export default SMSForm;
