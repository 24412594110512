import { useCallback } from 'react';
import type { DocumentType, InvoiceType } from '../interface/types';
import { useApp } from '@/utils/useapp';
import { generateAuthData } from '@/utils/drayaiAuth';
import type { ClassifyResult } from '../interface/types';

export function useDocumentClassify() {
  const app = useApp();

  const getBase64 = useCallback(
    (file: File, isShow: boolean): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () =>
          resolve(
            isShow
              ? (reader.result as string)
              : (reader.result as string).split(',')[1],
          );
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    [],
  );

  const classifyDocument = async (file: File): Promise<ClassifyResult> => {
    const base64Content = await getBase64(file, false);

    const classifyResult = await app.service.api(
      `${DRAYAI_FAST_API_URL}/ai/file/classify`,
      {
        method: 'post',
        data: {
          file: { filename: file.name, content: base64Content },
          ...generateAuthData(''),
        },
      },
    );

    const result = classifyResult?.result;
    const record_id = classifyResult?.record_id;

    const validInvoiceTypes: InvoiceType[] = ['DRAY', 'LTL', 'FTL'];
    const detectedInvoiceType = result?.invoice_type?.toUpperCase();

    if (
      result.file_type === 'invoice' &&
      !validInvoiceTypes.includes(detectedInvoiceType as InvoiceType)
    ) {
      throw new Error('Unable to determine invoice type');
    }

    return {
      file_type: 'invoice',
      invoice_type: validInvoiceTypes.includes(
        detectedInvoiceType as InvoiceType,
      )
        ? (detectedInvoiceType as InvoiceType)
        : undefined,
      original_type: result.file_type as DocumentType,
      original_text: result.original_text,
      record_id: record_id,
    };
  };

  /**
   * send feedback to the AI
   * @param record_id the record id of the classification
   * @param is_adopted true: adopt, false: not adopt
   * @param corrected_result the corrected result (optional)
   * @param feedback_comment the feedback comment (optional)
   */
  const classifyFeedback = async (
    record_id: string,
    is_adopted: boolean,
    corrected_result?: any,
    feedback_comment?: string,
  ): Promise<any> => {
    const response = await app.service.api(
      `${DRAYAI_FAST_API_URL}/ai/file/classify/feedback`,
      {
        method: 'post',
        data: {
          record_id,
          is_adopted,
          corrected_result,
          feedback_comment,
          ...generateAuthData(''),
        },
      },
    );

    return response;
  };

  return {
    classifyDocument,
    getBase64,
    classifyFeedback,
  };
}
