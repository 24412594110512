import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EmailTemplateEditor } from '@/components/EmailTemplateEditor';
import { useApp } from '@/utils/useapp';
import { Card, FormInstance, Form, message, Select } from 'antd';
import { get, truncate, has } from 'lodash';
import { EmailTemlateDrawer } from '@/components/EmailTemplateDrawer';
import { PRESET_DATA_MAP } from '@/pages/setting/emailTemplate/components/data';
import { PreviewEmailContentDrawer } from '../PreviewEmailContentDrawer';
import { useForm } from 'antd/es/form/Form';
import { SendToListAndCCListFormItems } from '../SendToListAndCCListFormItems';
import { TEmailTemplate, TCollection } from '@/types';
import { NormalList } from './NormalList';
import { SpecificTabs } from './SpecificTabs';
import { TEMPLATE_TYPE_NORMAL, TEMPLATE_TYPE_SPECIFICTABS } from './data';
import { validateForm } from '../index';
import { serialize } from 'object-to-formdata';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  target: string;
  targetId: number;
  form: FormInstance;
  sending: boolean;
  hasSendToList?: boolean;
  type?: string;
  sendURI: string;
  emailCustomProps?: ReactNode;
  options?: { [key: string]: any };
  previewURI?: string;
  resolveTemplate?: (
    subject: string,
    body: string,
  ) => { subject: string; body: string };
  onSending: (sending: boolean) => void;
  onCancelSend: () => void;
  onSent: () => void;
  alertMessage?: ReactElement;
}
const gridStyle: React.CSSProperties = {
  width: '50%',
  height: '90%',
  overflowY: 'scroll',
};

export const SendEmailWithTemplates: React.FC<Props> = ({
  target,
  targetId,
  form,
  sending,
  hasSendToList = true,
  type = TEMPLATE_TYPE_NORMAL,
  sendURI,
  previewURI,
  emailCustomProps,
  options = {},
  onSending,
  resolveTemplate,
  onCancelSend,
  onSent,
  alertMessage,
}) => {
  const app = useApp();
  const [templateData, setTemplateData] = useState<
    TCollection<TEmailTemplate>
  >();
  const [visableTemplate, setVisableTemplate] = useState(false);
  const [templateForm] = useForm();
  const [templateDrawerTitle, setTemplateDrawerTitle] = useState(
    'Create Template',
  );
  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const presetDataMap = useMemo(
    () => has(PRESET_DATA_MAP, target) && get(PRESET_DATA_MAP, target),
    [target],
  );

  const TemplateCardComponent = useMemo(() => {
    if (type == TEMPLATE_TYPE_SPECIFICTABS) {
      return SpecificTabs;
    }
    return NormalList;
  }, [type]);

  const fetchEmailTemplates = React.useCallback(async () => {
    setLoading(true);

    try {
      const result = await app.service.get(
        `emailTemplates/${target}/${targetId}`,
        {
          params: {},
        },
      );

      result && setTemplateData(result);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  }, []);

  const handleChange = (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    form.setFieldValue([name], value);
  };

  const onChange = (template: TEmailTemplate | undefined) => {
    let subject,
      body = '';
    if (template) {
      subject = template.subject;
      body = template.body;
      resolveTemplate && ({ subject, body } = resolveTemplate(subject, body));
    }

    form.setFieldsValue({
      subject,
      body,
    });
  };

  const handleCreateTemplate = () => {
    setVisableTemplate(true);
  };

  const handleEditTemplate = (template: TEmailTemplate) => {
    setVisableTemplate(true);
    setTemplateDrawerTitle('Edit Template');
    templateForm.resetFields();
    templateForm.setFieldsValue({ ...template });
  };

  const handlePreview = async () => {
    if (!previewURI) {
      return;
    }

    const data = await form.getFieldsValue();

    setLoading(true);
    try {
      const resp = await app.service.post(`${previewURI}`, {
        data,
      });

      setPreviewContent(resp.data);
      setOpenPreview(true);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePreSend = async () => {
    let data;
    try {
      data = await form.validateFields();
    } catch {
      return;
    }

    if (false === validateForm(data)) {
      onCancelSend();
      return false;
    }

    if (previewURI && presetDataMap) {
      handlePreview();
    } else {
      handleSend();
    }
  };

  const handleSend = async () => {
    if (!sendURI) {
      return;
    }

    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    if (form.getFieldValue('new_files')) {
      values.new_files = form.getFieldValue('new_files');
    }

    const config = {
      data: serialize(
        {
          ...options,
          ...values,
        },
        { indices: true, nullsAsUndefineds: true },
      ),
      requestType: 'form',
    };

    setLoading(true);
    try {
      await app.service.post(`${sendURI}`, config);
      // await app.service.post(`${sendURI}`, {
      //   data: {
      //     ...options,
      //     ...data,
      //   },
      // });
      message.success('Sent');
      onSent();
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      onSending(false);
      setLoading(false);
    }
  };

  const onClosePreviewContent = () => {
    setOpenPreview(false);
    setPreviewContent(null);
    onCancelSend();
  };

  const handleOnSavedTemplate = () => {
    fetchEmailTemplates();
  };

  useEffect(() => {
    sending && handlePreSend();
  }, [sending]);

  useEffect(() => {
    fetchEmailTemplates();
  }, [target, targetId]);

  return (
    <>
      <Form layout="vertical" form={form}>
        <Card title="">
          <Card.Grid style={gridStyle}>
            <TemplateCardComponent
              target={target}
              targetId={targetId}
              form={form}
              templateData={templateData}
              fetchTemplates={fetchEmailTemplates}
              onChange={onChange}
              handleCreateTemplate={handleCreateTemplate}
              handleEditTemplate={handleEditTemplate}
            />
          </Card.Grid>
          <Card.Grid
            hoverable={false}
            style={{
              ...gridStyle,
              height: '85vh',
            }}
          >
            {alertMessage && (
              <>
                {' '}
                {alertMessage} <br />
              </>
            )}
            {hasSendToList && <SendToListAndCCListFormItems form={form} />}
            <Form.Item name="subject" hidden noStyle shouldUpdate></Form.Item>
            <Form.Item name="body" hidden noStyle shouldUpdate></Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <EmailTemplateEditor
                  presetDataMap={presetDataMap}
                  handlePreview={handlePreview}
                  info={{
                    subject: getFieldValue('subject'),
                    body: getFieldValue('body'),
                  }}
                  onChange={handleChange}
                />
              )}
            </Form.Item>
            {emailCustomProps}
          </Card.Grid>
        </Card>
      </Form>

      <EmailTemlateDrawer
        target={target}
        targetId={targetId}
        form={templateForm}
        title={templateDrawerTitle}
        visible={visableTemplate}
        onSaved={handleOnSavedTemplate}
        setVisible={(value) => setVisableTemplate(value)}
      />

      <PreviewEmailContentDrawer
        open={openPreview}
        onClose={onClosePreviewContent}
        onConfirmSend={handleSend}
        disabled={loading}
        content={previewContent}
      />
    </>
  );
};
