import { useApp } from '@/utils/useapp';
import { Drawer, Form, message, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { RebillInterface } from '@/Interfaces/RebillInterface';
import RebillForm from './RebillForm';
import { Button } from 'antd';
import { serialize } from 'object-to-formdata';
import { showErrorMessage } from '@/utils/show-error-message';

const RebillDrawer: FC<{
  rebill: RebillInterface | null;
  rebillableType: string;
  rebillableId: number;
  options?: {
    task_id: number;
    action: string;
  };
  open: boolean;
  disabled?: boolean;
  onClose: () => void;
  onSaved: () => void;
}> = ({
  rebill,
  rebillableType,
  rebillableId,
  options,
  open,
  disabled = false,
  onSaved,
  onClose,
}) => {
  const app = useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);
    try {
      if (rebill) {
        await app.service.put(`rebills/${rebill.id}`, {
          data: {
            status: values.status,
            ...(options ? options : {}),
          },
        });
      } else {
        const config = {
          data: serialize(
            {
              ...values,
              rebillable_type: rebillableType,
              rebillable_id: rebillableId,
              ...(options ? options : {}),
            },
            { indices: true, nullsAsUndefineds: true },
          ),
          requestType: 'form',
        };

        await app.service.post('rebills', config);
      }

      onSaved();
      handleClose();
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (rebill) {
      form.setFieldsValue(rebill);
    }
  }, [rebill]);

  return (
    <Drawer
      title={'Rebill'}
      placement="right"
      width="40%"
      onClose={handleClose}
      destroyOnClose={true}
      open={open}
      footer={
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          {!disabled && (
            <Button type="primary" onClick={handleSave} loading={loading}>
              Save
            </Button>
          )}
        </Space>
      }
    >
      <RebillForm form={form} disabled={disabled} />
    </Drawer>
  );
};

export default RebillDrawer;
