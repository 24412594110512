import { useApp } from '@/utils/useapp';
import {
  Button,
  Input,
  message,
  Modal,
  Spin,
  Table,
  TableColumnsType,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { ButtonType } from 'antd/lib/button';
import usePagination from '@/components/usePagination';
import { TCollection } from '@/types';
import { SorterResult } from 'antd/lib/table/interface';
import { TablePaginationConfig } from 'antd/es/table';
import { UserWalletTransactionInterface } from '@/Interfaces/UserWalletTransactionInterface';
import RenderTransactionAmount from './RenderTransactionAmount';
import { showErrorMessage } from '@/utils/show-error-message';

const TransactionLog: React.FC<{
  user_wallet_id?: number;
}> = ({ user_wallet_id }) => {
  const app = useApp();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeRowId, setActiveRowId] = useState(0);

  const [data, setData] = useState<TCollection<UserWalletTransactionInterface>>(
    {
      data: [],
      meta: {
        total: 0,
        per_page: 100,
        current_page: 1,
        last_page: 1,
      },
    },
  );
  const [search, setSearch] = useState<string | undefined>();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pagination = usePagination(data);

  const fetchData = async (pagination?: TablePaginationConfig) => {
    setIsLoading(true);
    try {
      const resp = await app.service.get('userWallets/transactionLogs', {
        params: {
          user_wallet_id,
          search,
          is_finance_log: 0,
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 100,
        },
      });

      setData(resp);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      fetchData();
    }
  }, [isModalVisible]);

  const columns = useMemo(() => {
    const columns: TableColumnsType<UserWalletTransactionInterface> = [
      {
        title: 'Time',
        dataIndex: 'created_at',
        width: 150,
      },
      {
        title: 'Operator',
        dataIndex: ['operator', 'name'],
        width: 80,
      },
      {
        title: 'OP',
        dataIndex: 'operation_type',
        width: 150,
      },
      {
        title: 'Number#',
        dataIndex: 'reference_number',
        width: 150,
      },
      {
        title: 'Memo',
        dataIndex: 'memo',
        width: 150,
      },
      {
        title: 'Transaction Amount',
        dataIndex: 'transaction_amount',
        width: 150,
        render: (text, record) => <RenderTransactionAmount amount={text} />,
      },
      {
        title: 'Account Balance',
        dataIndex: 'wallet_balance',
        width: 150,
      },
      {
        title: 'Credit',
        dataIndex: 'wallet_credit',
        width: 150,
      },
      {
        title: 'Available Balance',
        dataIndex: 'available_balance',
        width: 150,
      },
    ];
    return columns;
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    setSearch(inputValue);
  };

  return (
    <>
      <Button type={'link'} onClick={showModal}>
        Logs
      </Button>
      <Modal
        cancelText="Close"
        footer={false}
        width={1400}
        title="Transation Logs"
        open={isModalVisible}
        onCancel={handleCancel}
      >
        <Input.Search
          onChange={handleChange}
          placeholder="Search"
          allowClear
          onSearch={fetchData}
        />
        <br />
        <br />
        <Spin spinning={isLoading} tip="Loading...">
          <Table
            scroll={{
              y: 700,
            }}
            sticky
            pagination={pagination}
            rowKey="id"
            rowClassName={(record: any) => [
              record?.id == activeRowId && 'rowActive',
            ]}
            onRow={(record: any, rowIndex: any) => {
              return {
                onClick: (event) => {
                  setActiveRowId && setActiveRowId(record?.id || 0);
                },
              };
            }}
            size="small"
            columns={columns}
            dataSource={data?.data || []}
            onChange={fetchData}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default TransactionLog;
