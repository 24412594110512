import React, { useEffect } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Button, message, TablePaginationConfig } from 'antd';
import { Header } from '@/components/CommonHeader';
import { useForm } from 'antd/lib/form/Form';
import { TCollection, TSpotRate } from '@/types';
import usePagination from '@/components/usePagination';
import { useParams } from 'umi';
import { useSpotRateFormDrawer } from '@/components/useSpotRateForm';
import SpotRateTable from './components/SpotRateTable';
import SpotRateFilter from './components/SpotRateFilter';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TSpotRate[]>>();
  const [filter] = useForm();
  const {
    SpotRateFormDrawer,
    openSpotRateFormDrawer,
  } = useSpotRateFormDrawer();

  const pagination = usePagination(data);

  const params: any = useParams();

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('spotRates', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        showErrorMessage(e);
      }

      setLoading(false);
    },
    [],
  );

  const handleSearch = async () => {
    const values = await filter.getFieldsValue(true);
    const data = !values.fdest
      ? { ...values }
      : { ...values, fdest: values.fdest.name ?? '' };
    setLoading(true);
    try {
      const res = await app.service.get('spotRates', {
        params: data,
      });
      setData(res);
    } catch (e: any) {
      showErrorMessage(e);
    }
    setLoading(false);
  };

  const handleRemove = async (id: number) => {
    setLoading(true);

    try {
      await app.service.delete(`spotRates/${id}`);
      message.success('Deleted');
      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (params.id) {
      openSpotRateFormDrawer(params.id);
    }
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Spot Rate"
        rightElement={
          <div>
            <Button type="primary" onClick={() => openSpotRateFormDrawer('')}>
              New Spot Rate
            </Button>
          </div>
        }
      ></Header>

      <SpotRateFilter
        form={filter}
        onFinish={fetchData}
        onSearch={handleSearch}
      />

      <SpotRateTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        onChange={fetchData}
        handleRemove={handleRemove}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
      <SpotRateFormDrawer />
    </div>
  );
};

export default Index;
