import { ConsigneeSyncSelect } from '@/components/ConsigneeSyncSelect';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
// import { FormInstance } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { TerminalSelect } from '@/components/TerminalSelect';
import styles from 'res/css/ui.scss';

import ContainerTable from '@/components/ContainerTable';

import { WarehouseSelect } from '@/components/WarehouseSelect';
// import { OCEAN_PORT, RAMP_PORT } from '@/pages/dispatchs/components/data';
// import { CHASSIS_OWNER_MAP, CHASSIS_OWNER_NO_IDEA } from '../../data';
import { useForm } from 'antd/lib/form/Form';
import { useApp } from '@/utils/useapp';
import {
  CHASSIS_OWNER_MAP,
  CHASSIS_OWNER_NO_IDEA,
} from '@/pages/orders/components/data';
import { OCEAN_PORT, RAMP_PORT } from '@/pages/dispatchs/components/data';
import { ContactList } from '@/components/ContactList';
import { showErrorMessage } from '@/utils/show-error-message';

const { Title } = Typography;

export const ToOrderForm: React.FC<{
  id: number | null;
  onCreated: () => void;
  onClose: () => void;
}> = ({ id, onCreated, onClose }) => {
  const [
    terminalDeliveryOrderAddress,
    setTerminalDeliveryOrderAddress,
  ] = React.useState('');
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const app = useApp();
  const destinationType = Form.useWatch('destination_type', form);
  const portOfDischarge = Form.useWatch('port_of_discharge_id', form);
  const portOfDischargeEta = Form.useWatch('port_of_discharge_eta', form);
  ('');
  useEffect(() => {
    if (destinationType && destinationType == OCEAN_PORT) {
      form.setFieldValue(
        'final_port_id',
        form.getFieldValue('port_of_discharge_id') || null,
      );
      form.setFieldValue(
        'final_port_eta',
        form.getFieldValue('port_of_discharge_eta') || null,
      );
    }
  }, [destinationType, portOfDischarge, portOfDischargeEta]);

  React.useEffect(() => {
    if (
      form.getFieldValue('terminal') &&
      form.getFieldValue('terminal')['delivery_order_address']
    ) {
      setTerminalDeliveryOrderAddress(
        form.getFieldValue('terminal')['delivery_order_address'],
      );
    }
  }, [form]);

  const handleAddContainer = () => {
    const newContainers = form.getFieldValue('containers');
    if (newContainers) {
      newContainers.push({});
      form.setFieldsValue({
        containers: newContainers,
        container_count: newContainers.length,
      });
    }
  };

  const handleSave = async (values: any) => {
    const formData = { ...values };
    // console.log(values);
    formData.scenarios_snapshot = JSON.stringify(
      formData.scenarios_snapshot || [],
    );
    formData.possible_charges_snapshot = JSON.stringify(
      formData.possible_charges_snapshot || [],
    );
    formData.containers = JSON.stringify(formData.containers || []);
    formData.warehouse_id = formData.warehouse?.id || 0;
    formData.terminal_id = formData.terminal?.id || 0;

    delete formData.warehouse;
    delete formData.terminal;
    try {
      setLoading(true);
      await app.service.post(`preOrders/toOrder/${id}`, {
        data: formData,
      });
      message.success('Order has been made!');
      onCreated && onCreated();
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (id: number) => {
    try {
      setLoading(true);
      const resp = await app.service.get(`preOrders/${id}`);
      const preOrder = resp.data;

      const containers = [];

      for (let i = 0; i < preOrder.cntr_qty; i++) {
        containers.push({
          number: '',
          package: '',
          weight: '',
          delivery_reference: '',
          delivery_type: RAMP_PORT,
          live_or_drop: 1,
          urgent: false,
          type: preOrder.cntr_size || '',
          is_overweight: preOrder.is_overweight,
          is_soc: preOrder.is_soc,
          is_reefer: preOrder.is_reefer,
          is_dg: preOrder.is_dg,
        });
      }

      form.setFieldsValue({
        containers,
        user_id: preOrder.user_id,
        rate_uid: preOrder.rate.uid,
        rate_id: preOrder.rate.id,
        destination_type: preOrder.destination_type,
        port_of_discharge_id: preOrder.port_of_discharge_id,
        port_of_discharge_eta: preOrder.port_of_discharge_eta,
        final_port_id: preOrder.final_port_id,
        final_port_eta: preOrder.final_port_eta,
        scenarios_snapshot: preOrder.scenarios,
        possible_charges_snapshot: preOrder.possible_charges,
        customer_memo: preOrder.customer_memo,
      });
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <div>
      <Form
        disabled={loading}
        form={form}
        onFinish={handleSave}
        layout="vertical"
      >
        <Form.Item noStyle name="id">
          <Input hidden />
        </Form.Item>
        <Form.Item noStyle name="scenarios_snapshot">
          <Input hidden />
        </Form.Item>
        <Form.Item noStyle name="possible_charges_snapshot">
          <Input hidden />
        </Form.Item>
        <Form.Item noStyle name="rate_id">
          <Input hidden />
        </Form.Item>
        <Form.Item noStyle name="rate_uid">
          <Input hidden />
        </Form.Item>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label="MBL"
              name="mbl_number"
              rules={[{ required: true, message: 'MBL is required' }]}
            >
              <Input placeholder="Enter mbl" />
            </Form.Item>
            <Form.Item label="Booking#" name="booking_number">
              <Input placeholder="Enter booking number" />
            </Form.Item>
            {/* <Form.Item label="Container Count" name="container_count">
              <Input
                type="number"
                min="0"
                max="50"
                placeholder="Enter container count"
              />
            </Form.Item> */}
            <Form.Item
              label="Reference"
              name="customer_reference_number"
              rules={[{ required: true, message: 'Reference is required' }]}
            >
              <Input placeholder="Enter reference" />
            </Form.Item>
            <Form.Item
              name="destination_type"
              label="Destination Type"
              rules={[
                { required: true, message: 'Destination Type is required.' },
              ]}
            >
              <Radio.Group>
                <Radio value={OCEAN_PORT}>Ocean Port</Radio>
                <Radio value={RAMP_PORT}>Ramp Port</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Port of Discharge" shouldUpdate required>
              {({ getFieldValue, setFieldsValue }) => (
                <Input.Group compact>
                  <Form.Item
                    name="port_of_discharge_id"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: 'Port of Discharge is required',
                      },
                    ]}
                  >
                    <IntermodalRegionSelect
                      placeholder="Port of Discharge"
                      style={{ width: '40%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="port_of_discharge_eta"
                    rules={[{ required: true, message: 'ETA is required' }]}
                  >
                    <Input hidden />
                  </Form.Item>
                  <DatePicker
                    placeholder="ETA"
                    style={{ width: '60%' }}
                    value={
                      getFieldValue('port_of_discharge_eta')
                        ? moment(getFieldValue('port_of_discharge_eta'))
                        : null
                    }
                    onChange={(date, dateString) =>
                      setFieldsValue({
                        port_of_discharge_eta: dateString,
                      })
                    }
                  />
                </Input.Group>
              )}
            </Form.Item>
            <Form.Item label="Final Port" shouldUpdate required>
              {({ getFieldValue, setFieldsValue }) => (
                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name="final_port_id"
                    rules={[
                      { required: true, message: 'Final Port is required' },
                    ]}
                  >
                    <IntermodalRegionSelect
                      disabled={getFieldValue('destination_type') == OCEAN_PORT}
                      placeholder="Select POD / Ramp"
                      style={{ width: '40%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="final_port_eta"
                    rules={[{ required: true, message: 'ETA is required' }]}
                  >
                    <Input hidden />
                  </Form.Item>

                  <DatePicker
                    disabled={getFieldValue('destination_type') == OCEAN_PORT}
                    placeholder="ETA"
                    style={{ width: '60%' }}
                    value={
                      getFieldValue('final_port_eta')
                        ? moment(getFieldValue('final_port_eta'))
                        : null
                    }
                    onChange={(date, dateString) =>
                      setFieldsValue({
                        final_port_eta: dateString,
                      })
                    }
                  />
                </Input.Group>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  label="Chassis"
                  name="chassis_owner"
                  initialValue={CHASSIS_OWNER_NO_IDEA}
                  rules={[{ required: false, message: 'Chassis is required' }]}
                >
                  <Select>
                    {Object.keys(CHASSIS_OWNER_MAP).map((key: string) => {
                      return (
                        <Select.Option key={key} value={Number(key)}>
                          {
                            CHASSIS_OWNER_MAP[
                              (key as unknown) as keyof typeof CHASSIS_OWNER_MAP
                            ]
                          }
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
            </Form.Item>
            <Title level={4}>Delivery</Title>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  label="Warehouse"
                  name="warehouse"
                  rules={[{ required: true, message: 'Warehouse is required' }]}
                >
                  <WarehouseSelect
                    selected={getFieldValue('warehouse')}
                    onSelect={(warehouse: any) => {
                      setFieldsValue({
                        warehouse,
                        delivery_address:
                          warehouse?.delivery_order_address || '',
                      });
                    }}
                    editable={true}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              label="Address"
              name="delivery_address"
              rules={[{ required: true, message: 'Address is required' }]}
            >
              <TextArea rows={4} placeholder="Enter delivery address" />
            </Form.Item>
          </Col>
          <Col span={11} style={{ marginLeft: '10px' }}>
            <Form.Item label="Sell Rate ID" name="rate_id">
              <Input disabled placeholder="Enter sell rate id" />
            </Form.Item>
            <Form.Item label="Buy Rate ID" name="buy_rate_id">
              <Input placeholder="Enter buy rate id" />
            </Form.Item>
            <Form.Item
              name="user_id"
              label="user (Customer)"
              rules={[{ required: true, message: 'Customer is required' }]}
            >
              <UserSyncSelect disabled />
            </Form.Item>
            <Form.Item name="consignee_id" label="Consignee">
              <UserSyncSelect disabled />
            </Form.Item>
            {/* <Form.Item label="Service Fee" name="service_fee">
              <Input placeholder="Enter service fee" />
            </Form.Item> */}
            <Form.Item label="Customer Memo" name="customer_memo" shouldUpdate>
              <Input.TextArea rows={4} />
            </Form.Item>
            <Divider />
            <h4 style={{ marginBottom: '30px', marginTop: '32px' }}>
              Pickup Terminal
            </h4>
            <Form.Item label="Terminal" shouldUpdate required>
              {({ getFieldValue, setFieldsValue }) => (
                <>
                  <Form.Item
                    noStyle
                    name="terminal"
                    rules={[
                      { required: true, message: 'Terminal is required' },
                    ]}
                  >
                    <TerminalSelect
                      selected={getFieldValue('terminal')}
                      onSelect={(terminal: any) => {
                        setTerminalDeliveryOrderAddress(
                          terminal?.delivery_order_address || '',
                        );
                        setFieldsValue({
                          terminal: terminal,
                        });
                      }}
                    />
                  </Form.Item>
                  <Typography.Text className={styles.table_td_weak}>
                    {' '}
                    Please enter TBD if have no idea of the Terminal.
                  </Typography.Text>
                </>
              )}
            </Form.Item>
            <Form.Item label="Address" shouldUpdate>
              <Input.TextArea
                disabled
                rows={4}
                value={terminalDeliveryOrderAddress}
              />
            </Form.Item>
            <Form.Item
              label="Commodity"
              name="commodity"
              rules={[{ required: true, message: 'Terminal is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={10}>
          <Col span={24}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => (
                <Form.Item
                  name="contacts"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}>
                  <ContactList
                    data={getFieldValue('contacts') || []}
                    updateData={(list) =>
                      setFieldValue('contacts', list)
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row> */}
        <Row>
          <Col span={24}>
            <Title level={4}>
              Containers <Button onClick={handleAddContainer}>Add</Button>
            </Title>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item name="containers" initialValue={[]}>
                  <ContainerTable
                    containers={getFieldValue('containers')}
                    onChange={(containers) => {
                      setFieldsValue({
                        containers,
                        container_count: containers ? containers.length : 0,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Space direction="horizontal">
              <Button type="primary" htmlType="submit" block>
                Save
              </Button>
              <Button block onClick={() => onClose()}>
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
