import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import AutoResizeTable from '@/components/AutoResizeTable';
import { Button, Input, message, Space, Tag } from 'antd';
import React from 'react';
import { get } from 'lodash';
import Edit from './components/Edit';
import AlgoCard from '../rate/components/AlgoCard';
import { showErrorMessage } from '@/utils/show-error-message';

const Setting = () => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [selected, setSelected] = React.useState<any[]>([]);

  const fetchData = React.useCallback(async () => {
    setLoading(true);

    try {
      const result = await app.service.get('intermodalRegions', {
        params: {},
      });
      setData(result.data);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  }, [setData]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelected(selectedRows);
    },
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'IR',
        dataIndex: 'name',
        key: 'name',
        width: 220,
      },
      {
        title: 'Config (Group)',
        dataIndex: ['rate_engine_config', 'name'],
        key: ['rate_engine_config', 'name'],
        width: 220,
        sorter: (a, b) => a.rate_engine_config_id - b.rate_engine_config_id,
      },
      {
        title: 'Active',
        dataIndex: 'active',
        sorter: (a, b) =>
          get(a.rate_engine_config, 'active') ==
          get(b.rate_engine_config, 'active')
            ? 1
            : -1,
        width: 80,
        render: (text, record) => {
          if (get(record.rate_engine_config, 'active')) {
            return <Tag color="success">Yes</Tag>;
          } else {
            return <Tag color="error">No</Tag>;
          }
        },
      },
      {
        title: 'Linear Algo Only',
        dataIndex: 'linear_algo_only',
        sorter: (a, b) =>
          get(a.rate_engine_config, 'linear_algo_only') ==
          get(b.rate_engine_config, 'linear_algo_only')
            ? 1
            : -1,
        width: 150,
        render: (text, record) => {
          if (get(record.rate_engine_config, 'linear_algo_only')) {
            return <Tag color="success">Yes</Tag>;
          } else {
            return <Tag color="error">No</Tag>;
          }
        },
      },
      {
        title: 'Enabled Algo',
        dataIndex: 'algo_id',

        render: (text, record) => {
          if (record.rate_engine_config && record.rate_engine_config.algo) {
            return (
              <AlgoCard
                name={record.rate_engine_config?.algo?.name}
                rateEngineConfigId={record.id}
                algoFormular={record.rate_engine_config?.algo?.formular}
                footer={`Created At ${record.rate_engine_config?.algo?.created_at}`}
              />
            );
          }
          return '-';
        },
      },
      {
        title: 'Range',
        render: (text, record, index) => (
          <>
            {(get(record, 'rate_engine_config.ranges') || []).map((range) => (
              <div key={range.start}>
                {range.start} - {range.end}, Markup: {range.markup}, Min:{' '}
                {range.min}
              </div>
            ))}
          </>
        ),
      },
      {
        title: 'No Linear Algo Markup',
        dataIndex: ['rate_engine_config', 'no_linear_algo_markup'],
        width: 200,
      },
      {
        title: 'Toll',
        dataIndex: ['rate_engine_config', 'toll'],
        width: 80,
        sorter: (a, b) =>
          get(a.rate_engine_config, 'toll') == get(b.rate_engine_config, 'toll')
            ? 1
            : -1,
        render: (text, record) => {
          if (get(record.rate_engine_config, 'toll')) {
            return <Tag color="success">Yes</Tag>;
          } else {
            return <Tag color="error">No</Tag>;
          }
        },
      },
      {
        title: 'Terminal Level',
        dataIndex: ['rate_engine_config', 'terminal_level'],
        width: 120,
        sorter: (a, b) =>
          get(a.rate_engine_config, 'terminal_level') ==
          get(b.rate_engine_config, 'terminal_level')
            ? 1
            : -1,
        render: (text, record) => {
          if (get(record.rate_engine_config, 'terminal_level')) {
            return <Tag color="success">Yes</Tag>;
          } else {
            return <Tag color="error">No</Tag>;
          }
        },
      },
      {
        title: 'Action',
        render: (text, record: any) => (
          <>
            <Edit
              rateEngineConfigId={record.rate_engine_config_id}
              onSaved={fetchData}
            >
              <Button size="small" type="link">
                Edit Config
              </Button>
            </Edit>
          </>
        ),
      },
    ],
    [fetchData],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  const display = React.useMemo(() => {
    return [...data].filter((i) =>
      i.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, data]);

  return (
    <div className={styles.main}>
      <Header title="Setting"></Header>
      <div className={styles.filter}>
        <Space>
          <div
            style={{
              width: '250px',
            }}
          >
            <Input
              allowClear
              placeholder="Search by Name"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <Edit
            onSaved={fetchData}
            intermodalRegionId={selected.map((s) => s.id)}
          >
            <Button disabled={selected.length === 0} type="primary">
              Combine IR
            </Button>
          </Edit>
        </Space>
      </div>
      <AutoResizeTable
        className="mt-sm"
        loading={loading}
        rowSelection={rowSelection}
        pagination={false}
        size="small"
        rowKey="id"
        columns={columns}
        // onChange={fetchData}
        dataSource={display}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
    </div>
  );
};

export default Setting;
