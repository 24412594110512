import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Drawer,
  message,
  Space,
  Table,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  TablePaginationConfig,
  Typography,
  InputNumber,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import { DrawerSheet } from './components/DrawerSheet';
import { useForm } from 'antd/lib/form/Form';
import { TIncomeStatement, TCollection, TIncomeSheet } from '@/types';
import usePagination from '@/components/usePagination';
import moment from 'moment';
import AutoResizeTable from '@/components/AutoResizeTable';
import { showErrorMessage } from '@/utils/show-error-message';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: TIncomeStatement;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Index = () => {
  const app = useApp();

  const [editingKey, setEditingKey] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [title] = React.useState('Income Statement');
  const [isCreate, setIsCreate] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TIncomeStatement>>();
  const [drawer_data, setDrawerData] = React.useState<TIncomeSheet>();
  const [form] = useForm();
  const [filter] = useForm();
  const [drawer_form] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('accountings/incomeStatements', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        showErrorMessage(e);
      }

      setLoading(false);
    },
    [],
  );

  const handleFilterSubmit = async () => {
    const values = await filter.validateFields();
    setLoading(true);

    try {
      const result = await app.service.post('accountings/incomeStatement', {
        data: values,
      });
      setDrawerData(result);
      setIsCreate(true);
      setVisible(true);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleCreate = async () => {
    const values = await filter.validateFields();
    setLoading(true);

    try {
      await app.service.post('accountings/incomeStatements/archive', {
        data: values,
      });
      message.success('Saved');
      fetchData();
      setVisible(false);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleLoad = async (income_state: TIncomeStatement) => {
    setLoading(true);

    try {
      const income_sheet = await app.service.get(
        `accountings/incomeStatements/${income_state.id}`,
      );
      const result = income_sheet.data && income_sheet.data.result;
      setDrawerData(result);
      setIsCreate(false);
      setVisible(true);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleEdit = async (id: number) => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      await app.service.put(`accountings/incomeStatements/${id}`, {
        data: values,
      });
      message.success('Saved');
      fetchData();
      setEditingKey('');
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const isEditing = (record: any) => record.id === editingKey;

  const edit = (record: any & { key: React.Key }) => {
    form.setFieldsValue({ remark: '', ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      editable: false,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      editable: false,
    },
    {
      title: 'Operator',
      dataIndex: 'operator_id',
      key: 'operator_id',
      width: 200,
      editable: false,
      render: (text: any, record: TIncomeStatement) => (
        <>{record.operator && record.operator.username}</>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: 200,
      editable: false,
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      width: 200,
      editable: false,
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      editable: true,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => handleEdit(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Typography.Link onClick={cancel}>Cancel</Typography.Link>
          </span>
        ) : (
          <Space>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Typography.Link onClick={() => handleLoad(record)}>
              Load
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className={styles.main}>
      <Header title="Archived Income Statement List"></Header>

      <div className={styles.filter}>
        <Form layout="vertical" form={filter} onFinish={handleFilterSubmit}>
          <Row gutter={16}>
            <Col>
              <Form.Item
                name="start_date"
                initialValue={moment().format('YYYY-01-01')}
                noStyle
              >
                <Input hidden />
              </Form.Item>
              <Form.Item
                name="end_date"
                initialValue={moment().format('YYYY-12-31')}
                noStyle
              >
                <Input hidden />
              </Form.Item>
              <Form.Item label="Invoice Date" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const start_date = getFieldValue('start_date');
                  const end_date = getFieldValue('end_date');
                  return (
                    <DatePicker.RangePicker
                      value={
                        start_date && end_date
                          ? [moment(start_date), moment(end_date)]
                          : undefined
                      }
                      onChange={(value) =>
                        setFieldsValue({
                          start_date: value
                            ? value[0]?.format('YYYY-MM-DD')
                            : null,
                          end_date: value
                            ? value[1]?.format('YYYY-MM-DD')
                            : null,
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary" loading={loading}>
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Form form={form} component={false}>
        <AutoResizeTable
          loading={loading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          rowKey={'id'}
          dataSource={data?.data || []}
          onChange={fetchData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={pagination}
        />
      </Form>

      <Drawer
        title={title}
        placement="right"
        width="1280px"
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            {isCreate && (
              <Button type="primary" loading={loading} onClick={handleCreate}>
                Archived
              </Button>
            )}
          </Space>
        }
      >
        <DrawerSheet
          form={drawer_form}
          dataSource={drawer_data}
          onArchive={handleCreate}
        />
      </Drawer>
    </div>
  );
};

export default Index;
