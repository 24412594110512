import { useApp } from '@/utils/useapp';
import {
  message,
  UploadProps,
  Card,
  Empty,
  Spin,
  Space,
  Tooltip,
  Typography,
} from 'antd';

import { RcFile } from 'antd/lib/upload';
import React, { useEffect } from 'react';
import { DraggerWithPaste } from '@/components/Upload/DraggerWithPaste';
import { ACCEPT_FILE_TYPES } from '@/components/Upload/data';
import { checkFileSize, FILE_MAX_SIZE } from '@/components/Upload/Validate';
import { saveAs } from 'file-saver';
import { get } from 'lodash';
import { FileItemRender } from './FileItemRender';
import { PODImageCutModal, IImage } from './PODImageCutModal';
import { AfterUploadedModal } from './AfterUploadedModal';
import { showErrorMessage } from '@/utils/show-error-message';

interface IDocument {
  title: string;
  targetModel: any;
  target: string;
  targetId: number;
  disabled?: boolean;
  onUpdated?: () => void;
}
export const Documents: React.FC<IDocument> = ({
  title = '',
  targetModel,
  target,
  targetId,
  disabled = false,
  onUpdated,
}) => {
  const [fileList, setFileList] = React.useState<UploadProps['fileList']>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [customerUploadList, setCustomerUploadList] = React.useState<
    UploadProps['fileList']
  >([]);
  const [adminUploadList, setAdminUploadList] = React.useState<
    UploadProps['fileList']
  >([]);
  const [openImageCut, setOpenImageCut] = React.useState(false);
  const [image, setImage] = React.useState<IImage>();
  const [disabledPODCut, setDisabledPODCut] = React.useState(false);
  const [showAfterUploaded, setShowAfterUploaded] = React.useState(false);
  const [uploadedFiles, setUploadFiles] = React.useState([]);

  const app = useApp();

  const resolveResponseFileList = async () => {
    const customerFiles: UploadProps['fileList'] = [];
    const adminFiles: UploadProps['fileList'] = [];

    fileList?.map((file: any) => {
      if (get(file, 'custom_properties.is_customer_upload')) {
        customerFiles.push(file);
      } else {
        adminFiles.push(file);
      }
    });

    setCustomerUploadList(customerFiles);
    setAdminUploadList(adminFiles);
  };

  const fetchData = async () => {
    setIsLoading(true);

    app.service
      .get(`documents/${target}/${targetId}`, {})
      .then((resp) => {
        setFileList(resp.data.map((document: any) => document));
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const beforeUpload = (file: RcFile) => {
    if (disabled) {
      return false;
    }

    const sizeOk = checkFileSize(file);

    if (!sizeOk) {
      message.error(`File must smaller than ${FILE_MAX_SIZE}MB!`);
    }
    return sizeOk;
  };

  const resolveNewFiles = (newUploadFiles: any) => {
    const fileIds = fileList.map((f) => f.id);
    return newUploadFiles.filter((newFile) => !fileIds.includes(newFile.id));
  };

  const uploadToServer = async (formData: FormData, action: string) => {
    setIsLoading(true);
    try {
      const newFiles = await app.service.post(action, {
        data: formData,
      });

      setShowAfterUploaded(true);
      setUploadFiles(resolveNewFiles(newFiles.data));

      fetchData();
      onUpdated && onUpdated();
      message.success('Document uploaded successfully');
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyToAdminUploadDocument = async (fileId: number) => {
    setIsLoading(true);
    try {
      const resp = await app.service.post(
        `documents/${target}/${targetId}/${fileId}/copyCustomerMedia`,
      );

      setShowAfterUploaded(true);
      setUploadFiles([resp.data]);

      fetchData();
      onUpdated && onUpdated();

      message.success('Copied Success');
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshFileList = (newDocument: any) => {
    const collection: UploadProps['fileList'] = fileList?.map((item) => {
      if (item.id === newDocument.id) {
        return newDocument;
      }
      return item;
    });
    setFileList(collection);
  };

  const handleUpdate = async (id: number, values: any) => {
    try {
      const _document = await app.service.put(`documents/${id}`, {
        data: values,
      });

      refreshFileList(_document.data);
      onUpdated && onUpdated();
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  const handleDownload = async (document: any) => {
    try {
      const file = await app.service.get(`documents/${document.id}`, {
        responseType: 'blob',
      });
      saveAs(file, document.name);
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  const props: UploadProps = {
    name: 'documents[0]',
    multiple: true,
    beforeUpload,
    accept: ACCEPT_FILE_TYPES,
    action: `documents/${target}/${targetId}`,
    customRequest: async (options) => {
      const formData = new FormData();
      formData.append('documents[0]', options.file);

      await uploadToServer(formData, options.action);
    },
  };

  const handlePreview = async (file: any) => {
    const resp = await app.service.get(`documents/${file.id}/url`);
    const url = resp;
    const w = window.open(url, '_blank');
  };

  const handleRemove = async (file: any) => {
    if (disabled) {
      return;
    }
    setIsLoading(true);
    try {
      await app.service.delete(`documents/${file.id}`);
      setFileList(fileList?.filter((item) => item.id !== file.id));
      message.success(`${file.name} removed`);
      onUpdated && onUpdated();
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasteFiles = async (pasteFiles: any) => {
    const formData = new FormData();
    pasteFiles?.map((pasteFile: any, i: number) =>
      formData.append('documents[' + i + ']', pasteFile),
    );
    await uploadToServer(formData, `documents/${target}/${targetId}`);
  };

  const handleSaveImageCut = async (isOverwrite: boolean, imageFile: File) => {
    const formData = new FormData();
    if (!image.id) {
      return;
    }
    setDisabledPODCut(true);

    formData.append('document', imageFile);

    try {
      if (isOverwrite) {
        const _document = await app.service.post(
          `documents/${image.id}/updateMedia`,
          {
            data: formData,
          },
        );

        refreshFileList(_document);
        message.success('Image update');
      } else {
        await app.service.post(
          `documents/${target}/${targetId}/${image.id}/addMedia`,
          {
            data: formData,
          },
        );

        fetchData();
        message.success('Image Add');
      }

      handleCloseImageCut();
      onUpdated && onUpdated();
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setDisabledPODCut(false);
    }
  };

  const handleCloseImageCut = () => {
    setOpenImageCut(false);
    setImage(undefined);
  };

  const handleOpenImageCut = async (file: any) => {
    setImage({
      id: file.id,
      url: '',
      name: file.name,
      fileName: file.file_name,
    });
    setOpenImageCut(true);
  };

  const handleOnCloseAfterUploadedModal = () => {
    setShowAfterUploaded(false);
    setUploadFiles([]);
  };

  const handleOnSavedAfterUploadModal = () => {
    fetchData();
    setShowAfterUploaded(false);
    setUploadFiles([]);
  };

  React.useEffect(() => {
    resolveResponseFileList();
  }, [fileList]);

  return (
    <>
      <DraggerWithPaste
        title={title}
        acceptFileTypes={ACCEPT_FILE_TYPES}
        uploadProp={props}
        disabled={isLoading}
        fileList={[]}
        showUploadList={false}
        handlePasteFiles={handlePasteFiles}
      />

      <div
        className={
          adminUploadList?.length == 0 && customerUploadList?.length == 0
            ? 'mt-lg'
            : ''
        }
        style={{ minHeight: '5vh' }}
      >
        <Spin spinning={isLoading}>
          {adminUploadList?.length > 0 && (
            <Card title="Admin Upload">
              {adminUploadList?.map((file, index) => (
                <FileItemRender
                  file={file}
                  key={index}
                  // containers={containers}
                  handleUpdate={handleUpdate}
                  handlePreview={handlePreview}
                  handleDownload={handleDownload}
                  handleRemove={handleRemove}
                  onOpenCutImage={handleOpenImageCut}
                  handleCopyToAdminUploadDocument={
                    handleCopyToAdminUploadDocument
                  }
                />
              ))}
            </Card>
          )}
          {customerUploadList?.length > 0 && (
            <Card title={<Space>Customer Upload</Space>}>
              {customerUploadList?.map((file, index) => (
                <FileItemRender
                  file={file}
                  key={index}
                  // containers={containers}
                  handleUpdate={handleUpdate}
                  handlePreview={handlePreview}
                  handleDownload={handleDownload}
                  handleRemove={handleRemove}
                  onOpenCutImage={handleOpenImageCut}
                  handleCopyToAdminUploadDocument={
                    handleCopyToAdminUploadDocument
                  }
                />
              ))}
            </Card>
          )}
        </Spin>
      </div>
      {!isLoading &&
        adminUploadList?.length == 0 &&
        customerUploadList?.length == 0 && (
          <div className="mt-lg">
            <Empty />
          </div>
        )}

      {image && (
        <PODImageCutModal
          image={image}
          open={openImageCut}
          disabled={disabledPODCut}
          onSave={handleSaveImageCut}
          onClose={handleCloseImageCut}
        />
      )}
      {showAfterUploaded && (
        <AfterUploadedModal
          open={showAfterUploaded}
          files={uploadedFiles}
          ltlShipment={targetModel}
          onClose={handleOnCloseAfterUploadedModal}
          onSaved={handleOnSavedAfterUploadModal}
        />
      )}
    </>
  );
};
