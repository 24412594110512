import { Space, Tag } from 'antd';
import React, { useMemo } from 'react';
import actionUtil, {
  TTarget,
  TARGET_TYPE_FTL_SHIPMENT,
} from '../../Interfaces/ActionInterface';
import { ActionIcon } from './ActionIcon';
import OperationLogs from '../OperationLogs';
import { WhoseTaskTag } from './WhoseTaskTag';
import { SOPLink } from '@/components/Tasks/SOPLink';

const DefaultTaskColumn: React.FC<TTarget & { showLog: boolean }> = ({
  task,
  target,
  targetModel,
  showLog = true,
}) => {
  const operation = useMemo(() => task?.operation, [task]);
  const nameColor = useMemo(
    () => (task?.sequential_execution ? 'text-primary' : ''),
    [task],
  );

  return (
    <Space size={'small'}>
      <ActionIcon operation={operation} />
      <div>
        <WhoseTaskTag whoseTask={task?.whose_task} />
        <span
          className={[
            nameColor,
            task?.is_filter_status ? 'text-line-through' : '',
          ].join(' ')}
        >
          {task?.sop ? (
            <SOPLink title={task.name} taskId={task.id} color={nameColor} />
          ) : (
            task?.name
          )}
        </span>
      </div>

      {operation && operation.action in actionUtil.ACTION_COLORS && (
        <Tag
          color={
            actionUtil.ACTION_COLORS[
              operation.action as keyof typeof actionUtil.ACTION_COLORS
            ]
          }
        >
          {operation.action}
        </Tag>
      )}

      {showLog && (
        <>
          {!!operation?.action_by && (
            <small className="text-gray">{operation.action_by.name}</small>
          )}
          {!!operation?.action_by && (
            <small className="text-gray">{operation.action_at}</small>
          )}

          {operation?.id && (
            <OperationLogs
              target={target}
              targetModel={targetModel}
              operationId={operation?.id}
            />
          )}
        </>
      )}
    </Space>
  );
};

export default DefaultTaskColumn;
