import { showErrorMessage } from '@/utils/show-error-message';
import { Form, Input, message, Modal, ModalProps } from 'antd';
import { useState } from 'react';

type TModal = {
  handleOk?: (param: { name: string; description: string }) => void;
  handleCancel?: () => void;
};

const useSaveAsTemplateModal = () => {
  const [_visible, _setVisible] = useState(false);

  const openSaveAsTemplateModal = () => _setVisible(true);

  const SaveAsTemplateModal = (props: ModalProps & TModal) => {
    const { visible, handleOk, handleCancel } = props;
    const [form] = Form.useForm();

    const _handleOk = () => {
      handleOk && handleOk(form.getFieldsValue(true));
      _setVisible(false);
    };

    const _handleCancel = () => {
      try {
        handleCancel && handleCancel();
        _setVisible(false);
      } catch (e) {
        showErrorMessage(e);
      }
    };

    return (
      <Modal
        title="New SpotRate Template"
        open={visible ?? _visible}
        okText="Save"
        onOk={_handleOk}
        onCancel={_handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'please input this field' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'please input this field' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return { openSaveAsTemplateModal, SaveAsTemplateModal };
};

export default useSaveAsTemplateModal;
