import { Button } from 'antd';
import { useApp } from '@/utils/useapp';
import { useEffect, useState } from 'react';
import React from 'react';
import {
  TAction,
  TARGET_TYPE_CFS_SHIPMENT,
} from '../../../Interfaces/ActionInterface';
import SendEmailModal from './SendEmailModal';

const SendDELVerification: React.FC<TAction> = ({
  task,
  targetModel: shipment,
  onDone,
}) => {
  const app = useApp();
  const [open, setOpen] = useState(false);

  const handleCloseOpen = () => {
    setOpen(false);
  };

  const handleOnSent = () => {
    onDone();
    handleCloseOpen();
  };

  return (
    <>
      <Button
        disabled={task?.disabled}
        size="small"
        onClick={() => setOpen(true)}
      >
        Send Email
      </Button>
      {open && shipment && (
        <SendEmailModal
          open={open}
          admin={app.store.auth.account}
          task={task}
          targetModel={shipment}
          target={TARGET_TYPE_CFS_SHIPMENT}
          onClose={handleCloseOpen}
          onDone={handleOnSent}
        />
      )}
    </>
  );
};

export default SendDELVerification;
