import React, { useEffect, useState } from 'react';
import { Button, Drawer, DrawerProps, message } from 'antd';
import { useApp } from '@/utils/useapp';
import { Rating } from '@/types';
import RatingTable from './RatingTable';
import useNewRatingModal from './NewRatingModal';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  id: number;
  visible: boolean;
  onClose?: DrawerProps['onClose'];
}

const Index: React.FC<Props> = ({ id, visible, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [ratingData, setRatingData] = useState<Rating[]>([]);
  const app = useApp();

  const { openNewRatingModal, NewRatingModal } = useNewRatingModal();

  const getDataById = (id: number | undefined) => {
    if (!id) {
      return;
    }
    setLoading(true);
    app.service
      .get(`vendors/${id}/ratings`)
      .then(({ data }) => {
        setRatingData([...data]);
      })
      .catch((error) => {
        showErrorMessage(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    getDataById(id);
  }, [id]);

  return (
    <Drawer
      title=""
      placement="right"
      width="80%"
      open={visible}
      onClose={onClose}
      destroyOnClose={true}
    >
      <div>
        <Button type="primary" onClick={() => openNewRatingModal(id)}>
          New Vendor Rating
        </Button>
      </div>
      <RatingTable
        loading={loading}
        dataSource={ratingData}
        onFinish={() => getDataById(id)}
      />
      <NewRatingModal onOk={() => getDataById(id)} />
    </Drawer>
  );
};

export default Index;
