import React, { useState, useCallback, useMemo } from 'react';
import moment from 'moment';

import {
  Form,
  Select,
  message,
  Switch,
  Drawer,
  Space,
  Button,
  Input,
  Tag,
  DatePicker,
} from 'antd';

import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';

import { BulkUpdateFormItem } from '@/components/BulkUpdateFormItem';
import {
  ORDER_STATES,
  ORDER_STATE_CANCELED,
  ORDER_SALES_TYPE_MAP,
} from '@/pages/orders/components/data';

import { IntermodalRegionOceanSelect } from '@/components/IntermodalRegionOceanSelect';
import { TerminalSelect } from '@/components/TerminalSelect';

import { chain, uniq } from 'lodash';

import { OCEAN_PORT, RAMP_PORT } from '@/pages/dispatchs/components/data';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  containers: any;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}
const CONTAINER_KEY = 'CONTAINER_';
const ORDER_KEY = 'ORDER_';

export const DrawerFormBulkUpdate: React.FC<Props> = ({
  containers = [],
  open = false,
  onClose,
  onSubmit,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [names, setNames] = useState<Array<string>>([]);
  const orderIds = useMemo(() => uniq(containers.map((c: any) => c.order_id)), [
    containers,
  ]);

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields(names);
    } catch {
      return;
    }

    const containerValues: any = chain(
      names.filter((n) => -1 !== n.indexOf(CONTAINER_KEY)),
    )
      .reduce(
        (result, n) => ({
          ...result,
          [n.replace(CONTAINER_KEY, '')]: values[n],
        }),
        {},
      )
      .value();

    const orderValues: any = chain(
      names.filter((n) => -1 !== n.indexOf(ORDER_KEY)),
    )
      .reduce(
        (result, n) => ({
          ...result,
          [n.replace(ORDER_KEY, '')]: values[n],
        }),
        {},
      )
      .value();

    if (containerValues.terminal) {
      containerValues.terminal_id = containerValues.terminal.id;
      delete containerValues.terminal;
    }

    if (containerValues.warehouse) {
      containerValues.warehouse_id = containerValues.warehouse.id;
      delete containerValues.warehouse;
    }

    if (containerValues.pod_terminal) {
      containerValues.pod_terminal_id = containerValues.pod_terminal.id;
      delete containerValues.pod_terminal;
    }

    if (orderValues.consignee) {
      orderValues.consignee_id = values.consignee.id;
      delete values.consignee;
    }

    setLoading(true);

    try {
      await app.service.put('dispatch/bulk', {
        data: {
          orders:
            Object.keys(orderValues).length > 0
              ? orderIds.map((id: any) => ({
                  id,
                  ...orderValues,
                }))
              : [],
          containers:
            Object.keys(containerValues).length > 0
              ? containers.map((c: any) => ({
                  id: c.id,
                  ...containerValues,
                }))
              : [],
        },
      });
      message.success('Saved');
      form.resetFields();
      setNames([]);
      onSubmit();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    form.resetFields();
    setNames([]);
    onClose();
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const handleListenNamesRequired = (name: string, required: boolean) => {
    const _names = Object.assign([], names);
    if (_names.find((n) => n == name)) {
      !required && setNames(_names.filter((n) => n != name));
    } else {
      _names.push(name);
      required && setNames(_names);
    }
  };

  return (
    <>
      <Drawer
        title={'Bulk Update'}
        placement={'right'}
        width={'50%'}
        onClose={handleClose}
        destroyOnClose={true}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        Selected:
        <div style={{ marginBottom: '10px' }}>
          {containers?.map((c: any) => (
            <Tag key={c.order.uid + '-' + c.number} color="processing">
              {c.order.uid} <br />
              {c.number}
            </Tag>
          ))}
        </div>
        <Form layout="vertical" form={form} onFinish={handleSave}>
          <Form.Item name="isSync" noStyle>
            <Input hidden></Input>
          </Form.Item>
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}pod_terminal`}
            label="POD Terminal"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <TerminalSelect
                    selected={getFieldValue(`${CONTAINER_KEY}pod_terminal`)}
                    disabled={
                      names?.includes(`${CONTAINER_KEY}pod_terminal`)
                        ? false
                        : true
                    }
                    redirectable={false}
                    onSelect={(terminal: any) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}pod_terminal`, //TODO terminal
                          value: terminal,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}terminal`}
            label="IR Terminal"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <TerminalSelect
                    selected={getFieldValue(`${CONTAINER_KEY}terminal`)}
                    disabled={
                      names?.includes(`${CONTAINER_KEY}terminal`) ? false : true
                    }
                    redirectable={false}
                    onSelect={(terminal: any) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}terminal`, //TODO terminal
                          value: terminal,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}destination_type`}
            label="Destination Type"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Select
                disabled={
                  names?.includes(`${CONTAINER_KEY}destination_type`)
                    ? false
                    : true
                }
                onChange={(val) =>
                  handleChange({
                    target: {
                      name: `${CONTAINER_KEY}destination_type`,
                      value: val,
                    },
                  })
                }
              >
                <Select.Option value={OCEAN_PORT}>Ocean Port</Select.Option>
                <Select.Option value={RAMP_PORT}>Ramp Port</Select.Option>
              </Select>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}port_of_loading_etd`}
            label="POL ETD"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}port_of_loading_etd`)
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}port_of_loading_etd`)
                        ? moment(
                            getFieldValue(
                              `${CONTAINER_KEY}port_of_loading_etd`,
                            ),
                          )
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}port_of_loading_etd`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}port_of_discharge_etd`}
            label="POD ETD"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}port_of_discharge_etd`)
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}port_of_discharge_etd`)
                        ? moment(
                            getFieldValue(
                              `${CONTAINER_KEY}port_of_discharge_etd`,
                            ),
                          )
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}port_of_discharge_etd`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}port_of_discharge_id`}
            label="POD"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <IntermodalRegionOceanSelect
                className="w100"
                disabled={
                  names?.includes(`${CONTAINER_KEY}port_of_discharge_id`)
                    ? false
                    : true
                }
                onChange={(id) => {
                  handleChange({
                    target: {
                      name: `${CONTAINER_KEY}port_of_discharge_id`,
                      value: id,
                    },
                  });
                }}
              />
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}port_of_discharge_eta`}
            label="POD ETA"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}port_of_discharge_eta`)
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}port_of_discharge_eta`)
                        ? moment(
                            getFieldValue(
                              `${CONTAINER_KEY}port_of_discharge_eta`,
                            ),
                          )
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}port_of_discharge_eta`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}rail_departure_date`}
            label="Rail Departure"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}rail_departure_date`)
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}rail_departure_date`)
                        ? moment(
                            getFieldValue(
                              `${CONTAINER_KEY}rail_departure_date`,
                            ),
                          )
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}rail_departure_date`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}final_port_eta`}
            label="IR ETA"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}final_port_eta`)
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}final_port_eta`)
                        ? moment(
                            getFieldValue(`${CONTAINER_KEY}final_port_eta`),
                          )
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}final_port_eta`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}freight_released_at`}
            label="Freight"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}freight_released_at`)
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}freight_released_at`)
                        ? moment(
                            getFieldValue(
                              `${CONTAINER_KEY}freight_released_at`,
                            ),
                          )
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}freight_released_at`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}customs_released_at`}
            label="Customs"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}customs_released_at`)
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}customs_released_at`)
                        ? moment(
                            getFieldValue(
                              `${CONTAINER_KEY}customs_released_at`,
                            ),
                          )
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}customs_released_at`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}terminal_released_at`}
            label="	Terminal"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}terminal_released_at`)
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}terminal_released_at`)
                        ? moment(
                            getFieldValue(
                              `${CONTAINER_KEY}terminal_released_at`,
                            ),
                          )
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}terminal_released_at`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}grounded_at`}
            label="	Ground"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}grounded_at`)
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}grounded_at`)
                        ? moment(getFieldValue(`${CONTAINER_KEY}grounded_at`))
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}grounded_at`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}port_lfd`}
            label="Port LFD"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}port_lfd`) ? false : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}port_lfd`)
                        ? moment(getFieldValue(`${CONTAINER_KEY}port_lfd`))
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}port_lfd`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}line_lfd`}
            label="Line LFD"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}line_lfd`) ? false : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}line_lfd`)
                        ? moment(getFieldValue(`${CONTAINER_KEY}line_lfd`))
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}line_lfd`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}actual_terminal_pickuped_at`}
            label="Terminal Pickup"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(
                        `${CONTAINER_KEY}actual_terminal_pickuped_at`,
                      )
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(
                        `${CONTAINER_KEY}actual_terminal_pickuped_at`,
                      )
                        ? moment(
                            getFieldValue(
                              `${CONTAINER_KEY}actual_terminal_pickuped_at`,
                            ),
                          )
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}actual_terminal_pickuped_at`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />
        </Form>
      </Drawer>
    </>
  );
};
