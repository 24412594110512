import React from 'react';
import { useApp } from '@/utils/useapp';
import { Space, message, Tooltip } from 'antd';
import { CNTREasyTracking } from '@/pages/dispatchs/components/CNTREasyTracking';
import { MBLLink } from './MBLLInk';
import { CopyButton } from './CopyButton';
import { FileSearchOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { TagBadge } from '@/pages/orders/components/OrderIdColumn';
import CntrAttributeBadge from './CntrAttributeBadge';
import { showErrorMessage } from '@/utils/show-error-message';
const CntrAndMblColumnRender: React.FC<{
  container: any;
  order: any;
  onSaved: () => void;
}> = ({ container, order, onSaved }) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);

  const goToTrackingPage = async (id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.get(
        `containers/${container.id}/easyTracking`,
        {},
      );
      const signedUrl = get(resp, 'url', '');
      if (signedUrl) {
        window.open(decodeURIComponent(signedUrl), '_target');
      }
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CNTREasyTracking order={order} container={container} onSaved={onSaved} />
      <br />
      <Space>
        <MBLLink mblNumber={order?.mbl_number || ''} />
        {order?.mbl_number && <CopyButton value={order.mbl_number} />}
        {(container.number && order.trackeasy_shipment_id ? true : false) && (
          <span style={{ color: '#1890ff' }}>
            <FileSearchOutlined
              onClick={() => goToTrackingPage(container.id)}
            />
          </span>
        )}

        {container.type && <TagBadge text={container.type} />}
        {order.user?.agent_id && <TagBadge text="Agent" />}
      </Space>
    </>
  );
};

export default CntrAndMblColumnRender;
