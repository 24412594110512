import React, { useMemo, useState } from 'react';
import {
  message,
  Popover,
  Button,
  Spin,
  Form,
  InputNumber,
  Input,
  Select,
  Space,
  Row,
  Col,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { EditSRReasonOptions } from './data';
import { useApp } from '@/utils/useapp';
import { TAction } from '../Interfaces/ActionInterface';
import Decimal from 'decimal.js';
import { showErrorMessage } from '@/utils/show-error-message';

const EditSR: React.FC<
  TAction & {
    action: string;
    name: string;
  }
> = ({ target, targetModel, task, action, name, onDone }) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const reasonOptions = useMemo(() => EditSRReasonOptions(target), [target]);

  const [form] = useForm();

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }
    setLoading(true);
    try {
      await app.service.post(`tasks/${target}/${targetModel.id}/editRate`, {
        data: {
          task_id: task.id,
          action: action,
          attribute: 'sell_rate',
          value: values.value,
          reason: values.reason,
          reason_type: values.reason_type,
        },
      });
      onDone?.();
      form.resetFields();
      setOpen(false);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popover
      title={'Edit SR'}
      trigger={'click'}
      placement={'top'}
      onOpenChange={(open: boolean) => {
        setOpen(open);
      }}
      open={open}
      content={
        <div style={{ width: '16vw' }}>
          <Spin spinning={loading}>
            <Form
              layout="horizontal"
              form={form}
              disabled={task?.disabled || !task.is_can_edit}
            >
              <Form.Item label="Original SR" rules={[{ required: false }]}>
                <InputNumber
                  value={targetModel.sell_rate}
                  disabled
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item noStyle shouldUpdate required>
                {({ getFieldValue, setFieldsValue }) => {
                  const diff =
                    targetModel.sell_rate && getFieldValue('value')
                      ? new Decimal(getFieldValue('value')).minus(
                          new Decimal(targetModel.sell_rate),
                        )
                      : null;

                  return (
                    <Row>
                      <Col span={18}>
                        <Form.Item
                          label="New SR"
                          name={'value'}
                          rules={[
                            {
                              required: true,
                              validator: (_, value) => {
                                if (!value) {
                                  return Promise.reject(
                                    new Error('Please enter a new SR'),
                                  );
                                }

                                if (
                                  new Decimal(value).equals(
                                    targetModel.sell_rate,
                                  )
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      'Origin SR = New SR, Please check',
                                    ),
                                  );
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={5} style={{ marginTop: 5 }}>
                        {diff &&
                          (diff.greaterThan(0) ? (
                            <span className="text-danger">
                              +{diff.toFixed(2)}
                            </span>
                          ) : (
                            <span className="text-success">
                              {diff.toFixed(2)}
                            </span>
                          ))}
                      </Col>
                    </Row>
                  );
                }}
              </Form.Item>
              <Form.Item
                label="Reason"
                name="reason_type"
                rules={[{ required: true }]}
              >
                <Select options={reasonOptions} />
              </Form.Item>
              <Form.Item
                label="Memo"
                name="reason"
                rules={[{ required: true }]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSave} loading={loading}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      }
    >
      <Button type="default" size="small">
        Edit SR
      </Button>
    </Popover>
  );
};

export default EditSR;
