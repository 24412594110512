import {
  Button,
  Popover,
  Space,
  Typography,
  DatePicker,
  Input,
  message,
} from 'antd';

import React, { useEffect, useMemo, useState } from 'react';
import { get, upperFirst, has, isArray } from 'lodash';
import {
  ACTION_DONE,
  ACTION_NA,
  ACTION_UNDO,
  ACTION_CREATE_DO,
  ACTION_SEND_DO,
  ACTION_INPUT,
  ACTION_SEND_PRE_DEL_APPT,
  ACTION_DATE,
  ACTION_DATE_TIME,
  ACTION_UPLOAD,
  ACTION_SEND_POD,
  ACTION_EDIT,
  ACTION_SELLING_RATE_LINK,
  ACTION_BUY_RATE_LINK,
  ACTION_EDIT_SELL_RATE_ID,
  ACTION_DATE_TIME_RANGE,
  ACTION_SEND_PU_REQUEST,
  ACTION_VENDOR_RATING,
  ACTION_USE_CHASSIS_FRONT_LINK,
  ACTION_STREET_TURN,
  ACTION_RESCHEDULE,
  ACTION_CONFIRM_DO,
  ACTION_SEND_VERIFICATION,
} from '@/pages/setting/containerTasks/components/data';
import {
  TItemProps,
  TCommonProps,
  TOnCheckProps,
  TOnDoneProps,
  TItemActionProps,
} from '../';
import { GeneralAction } from './GeneralAction';
import { SendPodAction } from './SendPodAction/index';
import { SendPURequest } from './SendPURequest';
import { RateLink } from '@/components/Rate/RateLink';
import {
  OPEN_TYPE_BUY_RATE,
  OPEN_TYPE_SELL_RATE,
} from '@/components/Rate/OpenBuyRateOrSellRateDrawer';
import { EditAction } from './EditAction';
import { CheckOutlined, EnterOutlined } from '@ant-design/icons';
import { ConfirmTimeBasedUpdate } from './ConfirmTimeBasedUpdate';
import moment from 'moment';
import { CreateDOAction } from './CreateDOAction';
import { SendDOAction } from './SendDO';
import { Upload } from '@/pages/orders/components/Upload';
import { SendPreDelApptAction } from './SendPreDelApptAction';
import { DateTimeRangeAction } from './DateTimeRangeAction';
import QuestionnarieDrawer from '@/pages/questionnaires/components/QuestionnarieDrawer';
import { useApp } from '@/utils/useapp';
import { RescheduledAction } from './RescheduledAction';
import { ConfirmDoAction } from './ConfirmDoAction';
import { SendSOCEmptyReturnDepotVerification } from './SendSOCEmptyReturnDepotVerification';

export const ACTIONS = [
  ACTION_UNDO,
  ACTION_DATE,
  ACTION_DATE_TIME,
  ACTION_DATE_TIME_RANGE,
  ACTION_SELLING_RATE_LINK,
  ACTION_BUY_RATE_LINK,
  ACTION_USE_CHASSIS_FRONT_LINK,
  ACTION_STREET_TURN,
  ACTION_DATE,
  ACTION_INPUT,
  ACTION_RESCHEDULE,
  ACTION_CONFIRM_DO,
];

export const RenderActions: React.FC<
  TCommonProps & TItemProps & TOnCheckProps & TOnDoneProps
> = ({ taskType, item, container, onDone, onCheck }) => {
  const [questionnarieId, setQuestionnarieId] = React.useState<number>(0);
  const [onChecking, setOnChecking] = React.useState<boolean>(false);
  const [useChassisFrontLink, setUseChassisFrontLink] = React.useState<
    string | null
  >();
  const [
    updatingData,
    setUpdatingData,
  ] = React.useState<TItemActionProps | null>(null);
  const app = useApp();

  const handleOnCheck = async (data: TItemActionProps) => {
    setOnChecking(true);
    await onCheck({
      task: item.task,
      task_type: item.task_type,
      setting_container_task_id: item.setting_container_task_id,
      id: item?.id,
      ...data,
    });

    setOnChecking(false);
  };

  if (!item.actions) {
    return <></>;
  }

  const _actions = useMemo(() => {
    if (!item.is_done) {
      return item.actions;
    }

    const newActions: any = [];
    const hasReschedule = item?.actions.find(
      (actionItem) => actionItem.action === ACTION_RESCHEDULE,
    );

    item?.actions.map((actionItem) => {
      if (ACTIONS.includes(actionItem?.action)) {
        newActions.push(actionItem);
      } else if (
        !newActions?.find((newAction) => newAction.action == ACTION_UNDO) &&
        !hasReschedule
      ) {
        newActions.push({ ...actionItem, action: ACTION_UNDO });
      }
    });

    return newActions;
  }, [item]);

  const handleSubmit = async (
    values: any,
    openResponseId: number | undefined,
  ) => {
    try {
      openResponseId
        ? await app.service.put(`vendorRatingsV2/${openResponseId}`, {
            data: {
              responses: values,
            },
          })
        : await app.service.post('vendorRatingsV2', {
            data: {
              questionnaire_id: questionnarieId,
              model_id: container.vendor.id,
              responses: values,
              container_id: container?.id,
            },
          });
      setQuestionnarieId(0);
      message.success('Saved');
    } catch (e: any) {
      setQuestionnarieId(0);
      message.warning(e?.data?.message || e?.data?.error || e?.message || e);
    }
  };

  const handleSaveUseChassisFrontLink = (action: any, update_name: string) => {
    handleOnCheck({
      action,
      name: update_name,
      value: useChassisFrontLink,
    });
  };

  useEffect(
    () =>
      container.use_chassis_front_link &&
      setUseChassisFrontLink(container.use_chassis_front_link),
    [container],
  );

  return (
    <>
      <Space>
        {_actions?.map((actionRecord: any) => {
          const { action, update_name, disabled } = actionRecord;

          const actionDisabled =
            item.disabled || (action == ACTION_UNDO ? false : disabled);

          let value;

          if (isArray(update_name)) {
            if (has(update_name, 'time')) {
              value = get(container, update_name[get(update_name, 'time')]);
            }
          } else {
            value = get(container, update_name);
          }

          switch (action) {
            case ACTION_SEND_PRE_DEL_APPT:
              return (
                <SendPreDelApptAction
                  item={{ ...item, action }}
                  taskType={taskType}
                  container={container}
                  onDone={onDone}
                />
              );
            case ACTION_CREATE_DO:
              return (
                <CreateDOAction
                  item={item}
                  container={container}
                  onDone={onDone}
                />
              );
            case ACTION_INPUT: {
              let _value, placeholder;
              if (update_name == 'sell_rate_id') {
                _value = get(container, 'sell_rate.rate_uid');
                placeholder = 'Input Sell Rate ID';
              } else if (update_name == 'buy_rate_id') {
                _value = get(container, 'buy_rate.uid');
                placeholder = 'Input Buy Rate ID';
              } else {
                _value = value;
                placeholder = 'Input';
              }
              return (
                <EditAction
                  container={container}
                  name={update_name}
                  value={_value}
                  placeholder={placeholder}
                  onDone={onDone}
                />
              );
            }

            case ACTION_DATE:
              return (
                <ConfirmTimeBasedUpdate
                  container={container}
                  handleOnCheck={handleOnCheck}
                  onDone={onDone}
                  updatingData={updatingData}
                >
                  <DatePicker
                    disabled={item.disabled}
                    getPopupContainer={(trigger: any) => trigger.parentElement}
                    size="small"
                    value={value ? moment(value) : null}
                    onChange={(value) => {
                      setUpdatingData({
                        action,
                        name: update_name,
                        value: value ? value.format('YYYY-MM-DD') : null,
                      });
                    }}
                  />
                </ConfirmTimeBasedUpdate>
              );
            case ACTION_DATE_TIME_RANGE:
              return (
                <ConfirmTimeBasedUpdate
                  container={container}
                  handleOnCheck={handleOnCheck}
                  updatingData={updatingData}
                >
                  <DateTimeRangeAction
                    container={container}
                    disabled={item.disabled_date}
                    item={item}
                    name={update_name}
                    onUpdating={(data) => {
                      setUpdatingData({
                        action,
                        ...data,
                      });
                    }}
                    onDone={onDone}
                  />
                </ConfirmTimeBasedUpdate>
              );
            case ACTION_DATE_TIME:
              return (
                <ConfirmTimeBasedUpdate
                  container={container}
                  handleOnCheck={handleOnCheck}
                  updatingData={updatingData}
                >
                  <DatePicker
                    disabled={item.disabled}
                    getPopupContainer={(trigger: any) => trigger.parentElement}
                    showTime
                    size="small"
                    value={value ? moment(value) : null}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(value) => {
                      setUpdatingData({
                        action,
                        name: update_name,
                        value: value
                          ? value.format('YYYY-MM-DD HH:mm:ss')
                          : null,
                      });
                    }}
                  />
                </ConfirmTimeBasedUpdate>
              );
            case ACTION_STREET_TURN:
              return (
                <ConfirmTimeBasedUpdate
                  container={container}
                  handleOnCheck={handleOnCheck}
                  onDone={onDone}
                  updatingData={updatingData}
                >
                  <DatePicker
                    disabled={item.disabled}
                    getPopupContainer={(trigger: any) => trigger.parentElement}
                    size="small"
                    placeholder="ST Turn"
                    value={value ? moment(value) : null}
                    onChange={(value) => {
                      setUpdatingData({
                        action,
                        name: update_name,
                        value: value ? value.format('YYYY-MM-DD') : null,
                      });
                    }}
                  />
                </ConfirmTimeBasedUpdate>
              );
            case ACTION_RESCHEDULE:
              return (
                <RescheduledAction
                  taskType={taskType}
                  item={{ ...item, action: action }}
                  container={container}
                  onDone={onDone}
                />
              );
            case ACTION_SEND_POD:
              return (
                <SendPodAction
                  taskType={taskType}
                  item={{ ...item, action: action }}
                  container={container}
                  onDone={onDone}
                />
              );

            case ACTION_SELLING_RATE_LINK:
              if (has(container, 'sell_rate.rate_uid')) {
                return (
                  <RateLink
                    type={OPEN_TYPE_SELL_RATE}
                    snapshotableId={container.id}
                    snapshotableType="container"
                    rateUid={get(container, 'sell_rate.rate_uid')}
                    hasBanModifyFields={true}
                    onSaved={onDone}
                  />
                );
              }
              return <></>;
            case ACTION_USE_CHASSIS_FRONT_LINK:
              return (
                <Space.Compact>
                  {useChassisFrontLink && (
                    <span className="mt-sm mr-xs">
                      <a
                        href={useChassisFrontLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <EnterOutlined />
                      </a>
                    </span>
                  )}
                  <Input
                    value={useChassisFrontLink || ''}
                    size="small"
                    placeholder="Chassis front link"
                    allowClear
                    onChange={(e) => {
                      setUseChassisFrontLink(e.target.value);
                    }}
                  />
                  <Button
                    type="ghost"
                    size="small"
                    onClick={() =>
                      handleSaveUseChassisFrontLink(action, update_name)
                    }
                    className="ant-btn-icon-only select-modal-icon"
                  >
                    <span className="icon-gray">
                      {' '}
                      <CheckOutlined />
                    </span>
                  </Button>
                </Space.Compact>
              );
            case ACTION_BUY_RATE_LINK:
              if (has(container, 'buy_rate.uid')) {
                return (
                  <RateLink
                    type={OPEN_TYPE_BUY_RATE}
                    rateUid={get(container, 'buy_rate.uid')}
                    hasBanModifyFields={true}
                    onSaved={onDone}
                  />
                );
              }
              return <></>;
            case ACTION_SEND_PU_REQUEST:
              return (
                <SendPURequest
                  taskType={taskType}
                  item={{ ...item, action }}
                  container={container}
                  onDone={onDone}
                />
              );
            case ACTION_SEND_DO:
              return (
                <SendDOAction
                  item={{ ...item, action }}
                  taskType={taskType}
                  container={container}
                  onDone={onDone}
                />
              );
            case ACTION_CONFIRM_DO:
              return (
                <ConfirmDoAction
                  item={{ ...item, action }}
                  taskType={taskType}
                  container={container}
                  onDone={onDone}
                />
              );
            case ACTION_VENDOR_RATING:
              return (
                <>
                  {container.vendor ? (
                    // <Popover
                    //   trigger="click"
                    //   placement="left"
                    //   destroyTooltipOnHide
                    //   content={
                    //     <Button onClick={() => setQuestionnarieId(1)}>Score</Button>
                    //     // <VendorRating
                    //     //   vendor_id={container.vendor?.id || 0}
                    //     //   score={container.vendor?.score || 'N/A'}
                    //     //   container={container}
                    //     //   refreshContainer={() => onDone && onDone()}
                    //     // />
                    //   }>
                    //   <RatingTag rating={container.vendor?.score || 0} />
                    // </Popover>
                    <Button
                      size="small"
                      type="link"
                      onClick={() => setQuestionnarieId(1)}
                    >
                      Rate
                    </Button>
                  ) : (
                    <Typography.Text type="warning">
                      Vendor is required
                    </Typography.Text>
                  )}
                </>
              );
            case ACTION_UPLOAD:
              return (
                <Upload
                  showActionTasks={false}
                  showFileActionTips={false}
                  container={container}
                  onClosedWithUpdate={onDone}
                  order={container?.order}
                  children={
                    <Button size="small" disabled={item.disabled}>
                      {action}
                    </Button>
                  }
                />
              );
            case ACTION_SEND_VERIFICATION:
              return (
                <SendSOCEmptyReturnDepotVerification
                  item={{ ...item, action }}
                  taskType={taskType}
                  container={container}
                  onDone={onDone}
                />
              );
            default: {
              // if (item.action_at && action == ACTION_NA) {
              //   return <></>;
              // }
              return (
                <Button
                  key={action}
                  size="small"
                  disabled={item.disabled || actionDisabled || onChecking}
                  onClick={() => {
                    handleOnCheck({ action, name: update_name || undefined });
                  }}
                >
                  {upperFirst(action)}
                </Button>
              );
            }
          }
        })}
      </Space>
      {!!questionnarieId && (
        <QuestionnarieDrawer
          questionnarieId={1}
          onClose={() => setQuestionnarieId(0)}
          onSumbit={(data, id) => {
            handleSubmit(data, id);
            handleOnCheck({
              ...item,
              action: ACTION_DONE,
            });
          }}
          openByVendorContainer={{
            container_id: container.id,
            vendor_id: container.vendor.id,
          }}
        />
      )}
    </>
  );
};
