import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Button, message, Card } from 'antd';
import { Header } from '@/components/CommonHeader';
import { CardForm } from './components/CardForm';
import { useForm } from 'antd/lib/form/Form';
import { get } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();

  const fetchData = React.useCallback(async () => {
    setLoading(true);
    try {
      const result = await app.service.get('accountings/accountingBlocks', {
        params: {},
      });
      const blocked_at = get(result.data, 'blocked_at');
      if (!blocked_at) {
        form.setFieldsValue({
          blocked: false,
          time: null,
        });
      } else {
        form.setFieldsValue({
          blocked: true,
          time: blocked_at,
        });
      }
      await form.setFieldsValue(result);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  }, []);

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }
    const { time, blocked } = values;
    if (blocked && !time) {
      message.error('Please Select date');
      return false;
    }
    setLoading(true);

    try {
      await app.service.put('accountings/accountingBlocks', {
        data: values,
      });
      message.success('Saved');
      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="Accounting Block / UnBlock"></Header>
      <Card loading={loading} style={{ width: '98%', marginLeft: '20px' }}>
        <CardForm key="card_form" form={form} onSubmit={handleSave} />
        <div style={{ textAlign: 'left' }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Index;
