import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { message, Card, Space, Button } from 'antd';
import { Header } from '@/components/CommonHeader';
import { CardForm } from './components/CardForm';
import { useForm } from 'antd/lib/form/Form';
import { KEY_EXPIRED_RATE_INVISIBLE, KEY_COMPANY } from './components/data';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();

  const fetchData = React.useCallback(async () => {
    setLoading(true);

    try {
      const result = await app.service.get('settings', {
        params: {},
      });

      const expired_rate_invisible = result.data.find(
        (ele: { key: string; value: boolean }) =>
          ele.key === KEY_EXPIRED_RATE_INVISIBLE,
      );

      const company = result.data.find(
        (ele: { key: string; value: boolean }) => ele.key === KEY_COMPANY,
      );

      await form.setFieldsValue({
        [KEY_EXPIRED_RATE_INVISIBLE]: expired_rate_invisible.value,
        [KEY_COMPANY]: company?.value,
      });
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  }, []);

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    const data = [
      {
        key: KEY_EXPIRED_RATE_INVISIBLE,
        value: values.expired_rate_invisible,
      },
      {
        key: KEY_COMPANY,
        value: values.company,
      },
    ];

    setLoading(true);

    try {
      await app.service.put('settings/bulkUpdate', { data });
      // await app.service.put('settings/expired_rate_invisible', {
      //   data: {
      //     key: 'expired_rate_invisible',
      //     value: values.expired_rate_invisible,
      //   },
      // });
      message.success('Saved');
      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Setting"
        rightElement={
          <Space>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      ></Header>
      <Card loading={loading} style={{ width: '98%', marginLeft: '20px' }}>
        <CardForm form={form} onSubmit={handleSave} />
      </Card>
    </div>
  );
};

export default Index;
