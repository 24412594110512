import React, { useState } from 'react';
import { message } from 'antd';
import { useApp } from '@/utils/useapp';
import { TAction, TLtlShipmentAction } from '../Interfaces/ActionInterface';
import { AttributeChangeTarget } from '@/Interfaces/AttributeChangeLog/AttributeChangeTarget';
import AmountChangePopover from '@/components/AttributeChange/AmountChangePopover';
import { showErrorMessage } from '@/utils/show-error-message';

const EditRate: React.FC<
  TAction & {
    action: string;
    name: string;
  }
> = ({ target, targetModel, task, action, name, onDone }) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const handleSave = async (values: { value: number; reason: string }) => {
    setLoading(true);
    try {
      await app.service.post(`tasks/${target}/${targetModel.id}/editRate`, {
        data: {
          task_id: task.id,
          action: action,
          attribute: name,
          value: values.value,
          reason: values.reason,
        },
      });
      onDone?.();
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AmountChangePopover
      title={action}
      buttonText={action}
      attribute={name}
      target={AttributeChangeTarget?.LTL_SHIPMENT}
      targetId={targetModel.id}
      disabled={task?.disabled || !task.is_can_edit}
      onSave={handleSave}
    />
  );
};

export default EditRate;
