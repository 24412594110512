import { useApp } from '@/utils/useapp';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { useState } from 'react';
import { BaseButtonProps } from 'antd/lib/button/button';
import { STATE_ESTIMATE } from '../data';
import moment from 'moment-timezone';
import { showErrorMessage } from '@/utils/show-error-message';

type TProps = {
  form: FormInstance;
  state: string;
  orderInvoiceId: number;
  handleDateRelatedChange: (field: string, value: any) => void;
  onSetLoading: (loading: boolean) => void;
  onSaved: (orderInvoiceId: number) => void;
};

export const InvoiceConfirmButton: React.FC<TProps & BaseButtonProps> = ({
  form,
  state,
  orderInvoiceId,
  handleDateRelatedChange,
  onSetLoading,
  onSaved,
  ...props
}) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);

  const toSetLoading = (loading: boolean) => {
    setLoading(loading);
    onSetLoading && onSetLoading(loading);
  };

  const handleConfirm = async () => {
    if (!orderInvoiceId) {
      return false;
    }

    let data = {};

    if (!form.getFieldValue('invoiced_at')) {
      handleDateRelatedChange(
        'invoiced_at',
        moment().tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm'),
      );
      const values = await form.getFieldsValue();

      data = {
        invoiced_at: values.invoiced_at || null,
        due_at: values.due_at || null,
        penalty_at: values.penalty_at || null,
      };
    }

    toSetLoading(true);

    try {
      await app.service.patch(
        `orderInvoices/${orderInvoiceId}/invoiceConfirm`,
        {
          data,
        },
      );

      message.success('Saved');
      onSaved(orderInvoiceId);
    } catch (e: any) {
      showErrorMessage(e);
    }

    toSetLoading(false);
  };

  if (state == STATE_ESTIMATE) {
    return (
      <Popconfirm
        placement="left"
        title="Sure to confirm?"
        okText="Confirm"
        cancelText="Cancel"
        onConfirm={handleConfirm}
      >
        <Tooltip
          placement="right"
          title="Invoice confirm will turn state from estimate to open"
        >
          <Button type="primary" disabled={loading} {...props}>
            Invoice Confirm
          </Button>
        </Tooltip>
      </Popconfirm>
    );
  } else {
    return <></>;
  }
};
