import React from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Space,
  Upload,
  message,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import { Header } from '@/components/CommonHeader';
import { MinusCircleOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { IMAGE_TYPES } from '@/components/Upload/data';
import ContactManager from '@/pages/truckload/components/ContactManager';
import { ContactInfo } from '@/components/Tasks/Interfaces/ContactInfoInterface';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  model?: any;
  onCreated: () => void;
  onClose: () => void;
}

const CarrierForm: React.FC<Props> = ({ model, onCreated, onClose }) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [logo, setLogo] = React.useState<string | null>(null);
  const [contacts, setContacts] = React.useState<ContactInfo[]>([]);

  const id = model?.id;

  const [form] = useForm();

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`ftl/carriers/${id}`);
      form.setFieldsValue({
        ...resp.data,
      });
      setLogo(resp.data.logo_url);
      if (resp.data.contacts && Array.isArray(resp.data.contacts)) {
        setContacts(resp.data.contacts);
      }
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handelSave = async (values: any) => {
    try {
      if (id) {
        await app.service.put(`ftl/carriers/${id}`, {
          data: {
            ...values,
            contacts,
          },
        });
      } else {
        await app.service.post('ftl/carriers', {
          data: {
            ...values,
            contacts,
          },
        });
      }

      onCreated && onCreated();
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  React.useEffect(() => {
    if (id) {
      form.resetFields();
      fetchData();
    }
  }, [id]);

  return (
    <>
      <Header title={id ? `Edit Carrier#${id}` : 'New Carrier'} />
      <Form
        layout="vertical"
        disabled={loading}
        onFinish={handelSave}
        initialValues={{}}
        form={form}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input placeholder="Enter name." />
        </Form.Item>
        <Form.Item label="Primary Scac Code" name="scac_code">
          <Input placeholder="Enter scac code." />
        </Form.Item>
        <Form.Item label="Sub Scac Codes">
          <Form.List name="sub_scac_codes">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => {
                  return (
                    <Form.Item
                      {...field}
                      key={field.key}
                      rules={[
                        {
                          required: true,
                          message: 'Please input scac code',
                        },
                      ]}
                    >
                      <Input
                        style={{ width: '95%' }}
                        value={form.getFieldValue('sub_scac_codes')[index]}
                        onChange={(e) => {
                          const sub_scac_codes =
                            form.getFieldValue('sub_scac_codes') || [];
                          sub_scac_codes[index] = e.target.value;
                          form.setFieldsValue({ sub_scac_codes });
                        }}
                      />

                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ marginLeft: '10px' }}
                        onClick={() => remove(field.name)}
                      />
                    </Form.Item>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{
                      width: '100%',
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item label="Dot Number" name="dot_number">
          <Input placeholder="Enter dot number." />
        </Form.Item>
        <Form.Item label="Mc Number" name="mc_number">
          <Input placeholder="Enter mc number." />
        </Form.Item>
        <Form.Item
          label={
            <Space align="center">
              <span>Quick Link</span>
              <Form.Item
                name="use_shipment_id"
                valuePropName="checked"
                noStyle
                shouldUpdate
              >
                <Checkbox>
                  Use $ to represent the Vendor Shipment ID in the URL
                </Checkbox>
              </Form.Item>
            </Space>
          }
          name="quick_link"
        >
          <Input placeholder="Enter quick link URL." />
        </Form.Item>

        {/* Contact Information Section */}
        <Form.Item label="Contact Information">
          <ContactManager contacts={contacts} onChange={setContacts} />
        </Form.Item>

        <Form.Item label="Logo" name="logo">
          <Upload
            maxCount={1}
            multiple={false}
            listType="picture-card"
            fileList={[]}
            accept={IMAGE_TYPES}
            action={`ftl/carriers/${id}/upload-logo`}
            customRequest={async (options) => {
              if (options.file) {
                const formData = new FormData();
                formData.append('logo', options.file);
                try {
                  const resp = await app.service.post(options.action, {
                    data: formData,
                  });
                  setLogo(resp.logo_url);
                } catch (error) {
                  message.error('Failed to upload logo');
                  form.setFieldsValue({ logo: undefined });
                }
              }
            }}
          >
            {logo ? (
              <img src={logo} alt="logo" width={100} height={100} />
            ) : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}> Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Row gutter={10}>
          <Space direction="horizontal">
            <Button type="primary" loading={loading} htmlType="submit" block>
              Save
            </Button>
            <Button block onClick={() => onClose()}>
              Cancel
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};

export default CarrierForm;
