import React, { useState } from 'react';
import {
  Form,
  Select,
  message,
  Switch,
  Drawer,
  Space,
  Button,
  Input,
} from 'antd';

import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import { AntDDatePicker, presetDates } from 'antd-datepicker';
import moment from 'moment';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import { CargoTypeSelect } from '@/components/CargoTypeSelect';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  ids: any;
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

type saveDataItem = {
  id: number;
  expired_at?: string;
  visible?: boolean;
  from_facility_type?: string;
  to_facility_type?: string;
  cargo_type_id?: number;
  note?: string;
};

export const DrawerFormBulkUpdate: React.FC<Props> = ({
  ids = [],
  visible = false,
  onClose,
  onSubmit,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    const data: saveDataItem[] = [];
    ids.map((id: any) => {
      const item: saveDataItem = {
        id,
      };
      if (values.expiredState) {
        item.expired_at = values.expired_at;
      }

      if (values.visbibleState) {
        item.visible = values.visible;
      }

      if (values.fromFacilityTypeState) {
        item.from_facility_type = values.from_facility_type;
      }

      if (values.toFacilityTypeState) {
        item.to_facility_type = values.to_facility_type;
      }

      if (values.cargoTypeIdState) {
        item.cargo_type_id = values.cargo_type_id;
      }

      if (values.noteState) {
        item.note = values.note;
      }
      data.push(item);
    });

    setLoading(true);

    try {
      await app.service.put('rates/bulkUpdate', { data: data });
      message.success('Saved');
      form.resetFields();
      onSubmit();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  return (
    <Drawer
      title={'Bulk Update'}
      placement={'right'}
      width={'50%'}
      onClose={handleClose}
      destroyOnClose={true}
      open={visible}
      extra={
        <Space>
          <Button onClick={handleClose}>Close</Button>
          <Button type="primary" loading={loading} onClick={handleSave}>
            Save
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSave}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <>
              <div style={{ marginBottom: '10px' }}>
                <Form.Item
                  name={'fromFacilityTypeState'}
                  noStyle
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(checked) =>
                      handleChange({
                        target: {
                          name: 'fromFacilityTypeState',
                          value: checked,
                        },
                      })
                    }
                  />
                  <span> From Facility Type</span>
                </Form.Item>
              </div>
              <Form.Item
                name="from_facility_type"
                label=""
                rules={[
                  {
                    required: getFieldValue('fromFacilityTypeState')
                      ? true
                      : false,
                    message: 'From Facility Type is required',
                  },
                ]}
              >
                <FacilityTypeSelect
                  disabled={
                    getFieldValue('fromFacilityTypeState') ? false : true
                  }
                  getValueForOption="name"
                />
              </Form.Item>
            </>
          )}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <>
              <div style={{ marginBottom: '10px' }}>
                <Form.Item
                  name={'toFacilityTypeState'}
                  noStyle
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(checked) =>
                      handleChange({
                        target: {
                          name: 'toFacilityTypeState',
                          value: checked,
                        },
                      })
                    }
                  />
                  <span> To Facility Type</span>
                </Form.Item>
              </div>
              <Form.Item
                name="to_facility_type"
                label=""
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: getFieldValue('toFacilityTypeState')
                      ? true
                      : false,
                    message: 'To Facility Type is required',
                  },
                ]}
              >
                <FacilityTypeSelect
                  disabled={getFieldValue('toFacilityTypeState') ? false : true}
                  getValueForOption="name"
                />
              </Form.Item>
            </>
          )}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <>
              <div style={{ marginBottom: '10px' }}>
                <Form.Item
                  name={'cargoTypeIdState'}
                  noStyle
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(checked) =>
                      handleChange({
                        target: {
                          name: 'cargoTypeIdState',
                          value: checked,
                        },
                      })
                    }
                  />
                  <span> Cargo Type</span>
                </Form.Item>
              </div>
              <Form.Item
                name="cargo_type_id"
                label=""
                rules={[
                  {
                    required: getFieldValue('cargoTypeIdState') ? true : false,
                    message: 'Cargo Type is required',
                  },
                ]}
              >
                <CargoTypeSelect
                  disabled={getFieldValue('cargoTypeIdState') ? false : true}
                />
              </Form.Item>
            </>
          )}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <>
              <div style={{ marginBottom: '10px' }}>
                <Form.Item
                  name={'expiredState'}
                  noStyle
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(checked) =>
                      handleChange({
                        target: {
                          name: 'expiredState',
                          value: checked,
                        },
                      })
                    }
                  />
                  <span> Expired Date</span>
                </Form.Item>
              </div>
              <Form.Item
                name={'expired_at'}
                label=""
                noStyle
                rules={[
                  {
                    required: getFieldValue('expiredState') ? true : false,
                    message: 'Expired Date is required',
                  },
                ]}
              >
                <Input hidden />
              </Form.Item>
              <Form.Item label="">
                <AntDDatePicker
                  disabled={getFieldValue('expiredState') ? false : true}
                  presetDates={presetDates}
                  style={{ width: '100%' }}
                  value={
                    getFieldValue('expired_at')
                      ? moment(getFieldValue('expired_at'))
                      : null
                  }
                  onChange={(v) =>
                    handleChange({
                      target: {
                        name: 'expired_at',
                        value: v?.format('YYYY-MM-DD'),
                      },
                    })
                  }
                />
              </Form.Item>
            </>
          )}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <>
              <div style={{ marginBottom: '10px' }}>
                <Form.Item
                  noStyle
                  name={'visbibleState'}
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(checked) =>
                      handleChange({
                        target: {
                          name: 'visbibleState',
                          value: checked,
                        },
                      })
                    }
                  />
                  <span> Visible</span>
                </Form.Item>
              </div>
              <Form.Item
                name={'visible'}
                label=""
                rules={[
                  {
                    required: getFieldValue('visbibleState') ? true : false,
                    message: 'Visible is required',
                  },
                ]}
              >
                <Select
                  disabled={getFieldValue('visbibleState') ? false : true}
                  onChange={(visible) =>
                    handleChange({
                      target: {
                        name: 'visible',
                        value: visible,
                      },
                    })
                  }
                >
                  <Select.Option value={true}>Visible</Select.Option>
                  <Select.Option value={false}>InVisble</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <>
              <div style={{ marginBottom: '10px' }}>
                <Form.Item noStyle name={'noteState'} valuePropName="checked">
                  <Switch
                    onChange={(checked) =>
                      handleChange({
                        target: {
                          name: 'noteState',
                          value: checked,
                        },
                      })
                    }
                  />
                  <span> Note For Customer</span>
                </Form.Item>
              </div>
              <Form.Item
                label=""
                name="note"
                rules={[
                  {
                    required: getFieldValue('noteState') ? true : false,
                    message: 'Note is required',
                  },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input.TextArea
                  disabled={getFieldValue('noteState') ? false : true}
                  rows={5}
                  placeholder="Enter note"
                />
              </Form.Item>
            </>
          )}
        </Form.Item>
      </Form>
    </Drawer>
  );
};
