import { useApp } from '@/utils/useapp';
import { Space } from 'antd';
import React from 'react';
import { UserWalletInterface } from '@/Interfaces/UserWalletInterface';
import { Decimal } from 'decimal.js';

type Props = {
  userId: number;
};

const UserWallet: React.FC<Props> = ({ userId }) => {
  const app = useApp();
  const [
    userWallet,
    setUserWallet,
  ] = React.useState<UserWalletInterface | null>(null);

  const fetchUserAccount = async () => {
    if (!userId) {
      setUserWallet(null);
      return;
    }
    const { data } = await app.service.get(
      `userWallets/userWalletByUserId/${userId}`,
    );
    setUserWallet(data);
  };

  React.useEffect(() => {
    fetchUserAccount();
  }, [userId]);

  return (
    <>
      {userWallet && userWallet.id ? (
        <Space>
          {/* <div>Balance: {userWallet.balance}</div> */}
          {/* <div>Credit: {userWallet.credit}</div> */}
          {/* <div>Available Balance: {userWallet.available_balance}</div> */}
          <div>
            Prepay Balance:{' '}
            {userWallet.prepay_balance
              ? new Decimal(userWallet.prepay_balance).toFixed(2)
              : 0}
          </div>
          {/* <div>Prepaid Amount: {userWallet.prepaid_amount}</div> */}
          {/* <div>Settled Amount: {userWallet.settled_amount}</div> */}
        </Space>
      ) : null}
    </>
  );
};

export default UserWallet;
