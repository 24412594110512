import { Button, Form, List, Modal, Tooltip, Avatar, message } from 'antd';
import { CACHE_ADMIN_KEY } from '@/stores/cache';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { sortBy, truncate } from 'lodash';
import { useApp } from '@/utils/useapp';
import { observer } from 'mobx-react';
import { PushpinFilled } from '@ant-design/icons';
import { EditableItem } from './EditableItem';
import { EditMentionsInput } from './EditMentionsInput';
import { appendMemoAttributeToData } from './data';
import { showErrorMessage } from '@/utils/show-error-message';

type TargetType = {
  disabled?: boolean;
  target: string;
  targetId: number;
  memoAttribute?: string;
  options?: Record<string, any>;
  onSaved?: (targetId: number) => void;
};

type CustomSaveAndFetchType = {
  toSave?: (comment: string) => void;
  fetch?: (setComment: (comment: string) => void) => void;
};

export const MemoList: React.FC<TargetType & CustomSaveAndFetchType> = observer(
  ({ disabled = false, targetId, ...props }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [comments, setComments] = useState<any[]>([]);
    const [comment, setComment] = useState<string>('');
    const isModelValid = targetId && props.target;

    const app = useApp();

    const pinConment = async (item: any, pin: boolean) => {
      setLoading(true);
      let data = {
        pin,
      };

      props.options && (data = { ...data, ...props.options });

      try {
        await app.service.put(`adminComments/${item.id}`, {
          data: appendMemoAttributeToData(data, props?.memoAttribute),
        });
        props.onSaved && props.onSaved(targetId);
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setLoading(false);
      }
    };

    const fetchComments = async () => {
      if (props.fetch) {
        return props.fetch(setComment);
      }
      if (!isModelValid) {
        return;
      }

      setLoading(true);
      try {
        const resp = await app.service.get(
          `adminComments/${props.target}/${targetId}`,
        );
        setComments(resp.data);
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (isModelValid) {
        fetchComments();
      }
    }, [isModelValid]);

    const _comments = useMemo(() => {
      return sortBy(comments, ['pin', 'pin_at']).reverse();
    }, [comments]);

    const rows = app.store.cache.data[CACHE_ADMIN_KEY];

    if (rows === undefined) {
      app.store.cache.fetch(CACHE_ADMIN_KEY);
    }

    return (
      <List
        loading={loading}
        className="demo-loadmore-list"
        itemLayout="horizontal"
      >
        {_comments.map((item) => (
          <List.Item
            key={item.id}
            // actions={[]}
          >
            <List.Item.Meta
              avatar={
                item.admin?.avatar_url ? (
                  <Avatar src={item.admin.avatar_url} />
                ) : (
                  <Avatar>{item.admin?.display_name?.charAt(0) || 'S'}</Avatar>
                )
              }
              title={
                <>
                  <span>{item.admin?.display_name || 'System'}</span> @
                  <span className="text-gray">{item.created_at} </span>
                  {!!item.pin && (
                    <PushpinFilled
                      style={{ color: item.pin ? 'orange' : 'black' }}
                      onClick={() => pinConment(item, false)}
                    />
                  )}
                </>
              }
              description={
                <EditableItem
                  disabled={disabled}
                  item={item}
                  pinConment={pinConment}
                  memoAttribute={props.memoAttribute}
                  onSaved={() => props?.onSaved && props.onSaved(targetId)}
                />
              }
            />
          </List.Item>
        ))}
      </List>
    );
  },
);
