import { getNumber } from '@/utils/getNumber';
import { showErrorMessage } from '@/utils/show-error-message';
import { useApp } from '@/utils/useapp';
import {
  Alert,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  ModalProps,
  Row,
  Switch,
} from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

const { Item, useForm } = Form;

const defaultInitialValues = {
  is_warning: false,
  weights: 1,
  items: {
    'Response Speed': 0,
    'On Time Rate': 0,
    Service: 0,
    'Additional Charge': 0,
  },
  memo: '',
};

const useNewRatingModal = () => {
  const [form] = useForm();
  const [_visible, _setVisible] = useState(false);
  const [ratingId, setRatingId] = useState<number | undefined>(undefined);
  const [itemId, setItemId] = useState<number | undefined>(undefined);

  const openNewRatingModal = (
    ratingId: number,
    itemId?: number,
    initialValues?: typeof defaultInitialValues,
  ) => {
    setRatingId(ratingId);
    if (itemId && initialValues) {
      form.setFieldsValue({
        ...initialValues,
        vendor_id: ratingId,
        id: itemId,
      });
      setItemId(itemId);
    } else {
      form.setFieldsValue({ ...defaultInitialValues, vendor_id: ratingId });
    }
    _setVisible(true);
  };

  const NewRatingModal = (props: ModalProps) => {
    const { visible, onOk } = props;

    const app = useApp();

    const _handleOk = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      try {
        const values = form.getFieldsValue(true);
        const updateTime = moment().format('YYYY-MM-DD HH:mm:ss');

        if (itemId) {
          await app.service.put(`vendorRatings/${itemId}`, {
            data: { ...values, vendor_id: ratingId, updated_at: updateTime },
          });
        } else {
          await app.service.post('vendorRatings', {
            data: { ...values, vendor_id: ratingId, updated_at: updateTime },
          });
        }

        message.success('saved');
        _setVisible(false);
        setRatingId(undefined);
      } catch (err: any) {
        showErrorMessage(err);
      }
      onOk && onOk(e);
    };

    const computeTotalRating = () => {
      const values = form.getFieldsValue(true);
      const response_speed = getNumber(values?.['items']?.['Response Speed']);
      const on_time_rate = getNumber(values?.['items']?.['On Time Rate']);
      const service = getNumber(values?.['items']?.['Service']);
      const additional_charge = getNumber(
        values?.['items']?.['Additional Charge'],
      );
      return (
        response_speed * 0.15 +
        on_time_rate * 0.3 +
        service * 0.3 +
        additional_charge * 0.25
      );
    };

    return (
      <Modal
        title="New Rating"
        open={visible ?? _visible}
        okText="Save"
        onOk={(e) => _handleOk(e)}
        onCancel={() => {
          _setVisible(false);
        }}
      >
        <Alert
          style={{ marginBottom: '16px' }}
          type="info"
          message="The rating you can input is between 0 and 5"
        />
        <Alert
          style={{ marginBottom: '16px' }}
          type="info"
          message={
            <>
              <div>The total rating is based on the formula listed below: </div>
              <div>
                Response Speed x 15% + On Time Rate x 30% + Service x 30% +
                Additional Charge x 25%
              </div>
              <div>
                Vendor ratings = (rating1.score * rating1.weights +
                rating2.score * rating2.weights + ....) / (rating1.weights +
                rating2.weights + ...)
              </div>
            </>
          }
        />

        <Form form={form} layout="vertical">
          <Row>
            <Col span={12}>
              <Item shouldUpdate noStyle>
                {() => (
                  <Item
                    label="Warning"
                    name="is_warning"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Item>
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item shouldUpdate noStyle>
                {() => (
                  <Item label="Weights" name="weights">
                    <InputNumber />
                  </Item>
                )}
              </Item>
            </Col>
          </Row>

          <Item shouldUpdate noStyle>
            {() => (
              <Item label="Response Speed" name={['items', 'Response Speed']}>
                <InputNumber placeholder="Response Speed" />
              </Item>
            )}
          </Item>

          <Item label="On Time Rate" name={['items', 'On Time Rate']}>
            <InputNumber placeholder="On Time Rate" />
          </Item>
          <Item label="Service" name={['items', 'Service']}>
            <InputNumber placeholder="Service" />
          </Item>
          <Item label="Additional Charge" name={['items', 'Additional Charge']}>
            <InputNumber placeholder="Additional Charge" />
          </Item>
          <Item label="Memo" name="memo">
            <Input.TextArea placeholder="Memo" />
          </Item>
          <Divider />
          <Item noStyle shouldUpdate>
            {() => {
              return <Item>Total Rating: {computeTotalRating()}</Item>;
            }}
          </Item>
        </Form>
      </Modal>
    );
  };

  return { openNewRatingModal, NewRatingModal };
};

export default useNewRatingModal;
