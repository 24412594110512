import { Button, Modal, Space, message, Table } from 'antd';
import React, { useState } from 'react';
import { ColumnsType } from 'antd/es/table';

interface IApplyToContainersModal {
  // orderId: number;
  // warehouse: any;
  open: boolean;
  applying?: boolean;
  containers: number[];
  onAppliedToContainers: (ids: number[]) => void;
  onClose: () => void;
}

export const ApplyToContainersModal: React.FC<IApplyToContainersModal> = ({
  // orderId,
  // warehouse,
  open,
  applying = false,
  containers,
  onAppliedToContainers,
  onClose,
}) => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onAppliedToContainers && onAppliedToContainers(selectedRowKeys);
    // if (!orderId) {
    //   return false;
    // }

    // if (!warehouse) {
    //   message.error('Warehouse is required');
    //   return false;
    // }

    // setLoading(true);
    // try {
    //   const resp = await app.service.put(
    //     `orders/${orderId}/applyWarehouseToContainers`,
    //     {
    //       data: {
    //         warehouse_id: warehouse.id,
    //         containerIds: selectedRowKeys,
    //       },
    //     },
    //   );

    //   message.success('Saved');
    // } catch (error: any) {
    //   showErrorMessage(error);
    // } finally {
    //   setLoading(false);
    // }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'No.',
      key: 'number',
      dataIndex: 'number',
      width: 120,
      fixed: true,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      width: 80,
    },
    {
      title: 'IR',
      key: 'IR',
      dataIndex: ['final_port', 'name'],
      width: 220,
    },
    {
      title: 'IR ETA',
      key: 'final_port_eta',
      dataIndex: 'final_port_eta',
      width: 160,
    },
    {
      title: 'Whs',
      key: 'Whs',
      dataIndex: ['warehouse', 'delivery_order_address'],
      width: 220,
    },
    {
      title: 'Seal No.',
      key: 'seal_number',
      dataIndex: 'seal_number',
      width: 120,
    },
    {
      title: 'Package',
      key: 'package',
      dataIndex: 'package',
      width: 100,
    },
    {
      title: 'Weight',
      key: 'weight',
      dataIndex: 'weight',
      width: 220,
    },

    {
      title: 'Pickup No.',
      key: 'pickup_number',
      dataIndex: 'pickup_number',
      width: 120,
    },
    {
      title: 'L.F.D',
      key: 'lfd',
      dataIndex: 'lfd',
      width: 120,
    },
    {
      title: 'REF#',
      key: 'ref',
      dataIndex: 'ref',
      width: 120,
    },
    {
      title: '',
      key: 'checkboxes',
      dataIndex: 'checkboxes',
      align: 'center',
      width: 250,
    },
  ];

  return (
    <Modal
      title="Apply to Containers"
      width="70%"
      destroyOnClose
      cancelText="Close"
      footer={
        <Space>
          <Button disabled={applying} onClick={handleOnClose}>
            No
          </Button>
          <Button
            type="primary"
            disabled={applying || selectedRowKeys.length <= 0}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Space>
      }
      open={open}
      onCancel={handleOnClose}
    >
      <Table
        pagination={false}
        size="small"
        rowKey="id"
        footer={() => (
          <div className="flex justify-between">
            <div>
              <Space direction="vertical" align="end">
                <h3>
                  Selected count / Total count ({selectedRowKeys?.length || 0} /{' '}
                  {containers?.length})
                </h3>
              </Space>
            </div>
          </div>
        )}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={containers || []}
        scroll={{ x: 1500 }}
        sticky
      />
    </Modal>
  );
};
