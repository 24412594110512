import React, { useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';

import { Popover, message, List, Space } from 'antd';
import { TbClockExclamation } from 'react-icons/tb';
import { TTarget } from '../Interfaces/ActionInterface';
import { TaskInterface } from '../Interfaces/TaskInterface';
import TitleSlaTooltip from './TitleSlaTooltip';
import TaskSla from './TaskSla';
import { WhoseTaskTag } from './TaskColumn/WhoseTaskTag';
import { TaskOperationInterface } from '../Interfaces/TaskOperationInterface';
import { showErrorMessage } from '@/utils/show-error-message';

const TaskOverdue: React.FC<TTarget> = ({ target, targetModel }) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const handleOpenChange = async (open: boolean) => {
    if (!open) {
      return;
    }

    if (!targetModel.id) {
      return null;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(
        `tasks/${target}/${targetModel.id}/overdueTasks`,
        {
          params: {},
        },
      );
      setData(resp.data);
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex">
      {targetModel?.is_task_overdue && (
        <Popover
          zIndex={100}
          title={
            <>
              Task Overdue (<TitleSlaTooltip />)
            </>
          }
          mouseEnterDelay={0.1}
          mouseLeaveDelay={0.1}
          placement="right"
          onOpenChange={handleOpenChange}
          content={
            <List
              loading={loading}
              dataSource={data}
              renderItem={(operation: TaskOperationInterface) => (
                <List.Item>
                  <Space size="small">
                    <WhoseTaskTag whoseTask={operation.whose_task} />
                    {operation.task_name}
                    <TaskSla operation={operation} />
                  </Space>
                </List.Item>
              )}
            />
          }
        >
          <div className="text-danger flex justify-center align-center">
            <TbClockExclamation />
          </div>
        </Popover>
      )}
    </div>
  );
};

export default TaskOverdue;
