import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Space, Popover, Divider } from 'antd';
import { useApp } from '@/utils/useapp';
import { TLtlShipmentAction } from '../Interfaces/ActionInterface';

export const EditAction: React.FC<
  TLtlShipmentAction & {
    action: string;
    name: string;
    value: string;
    placeholder?: string;
    ButtonElement?: React.ReactNode;
  }
> = ({
  target,
  action,
  name,
  value,
  placeholder = 'Input',
  targetModel,
  task,
  onDone,
  onSaveOperation,
  ButtonElement,
}) => {
  const app = useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenChange = async (open: boolean) => {
    setOpen(open);
  };

  const handleSave = async () => {
    await onSaveOperation(
      task,
      action,
      name,
      form.getFieldValue(name),
      setLoading,
      onDone,
    );

    setOpen(false);
  };

  return (
    <>
      <Popover
        onOpenChange={handleOpenChange}
        open={open}
        trigger="click"
        placement="right"
        content={
          <>
            <Form
              layout="vertical"
              form={form}
              initialValues={{ [name]: value }}
              disabled={task?.disabled || !task.is_can_edit}
            >
              <Form.Item name={name}>
                <Input placeholder={placeholder} allowClear />
              </Form.Item>
            </Form>
            <Divider />
            <Row>
              <Space>
                <Button type="primary" disabled={loading} onClick={handleSave}>
                  Save
                </Button>
              </Space>
            </Row>
          </>
        }
      >
        <div className="cursor-pointer">
          {ButtonElement ? (
            ButtonElement
          ) : (
            <Button type="link">{value ? value : <EditOutlined />}</Button>
          )}
        </div>
      </Popover>
    </>
  );
};
