import { useApp } from '@/utils/useapp';
import { message, Modal, Table } from 'antd';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { columns } from './columns';

export const MarginPercentageChangeLog: React.FC<{
  id: number;
  icon?: ReactNode;
  type: string;
}> = ({ id, icon, type }) => {
  const app = useApp();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await app.service.get(
        `companies/${id}/marginPercentageChangeLog`,
        {
          params: {
            service_type: type,
          },
        },
      );
      setData(resp.data);
    } catch (error) {
      message.error('Failed to fetch margin percentage change log');
    } finally {
      setLoading(false);
    }
  }, [app, id, type]);

  useEffect(() => {
    if (isModalVisible && id) {
      fetchData();
    }
  }, [isModalVisible, id]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div onClick={showModal}>{icon ? icon : <a> log </a>}</div>
      <Modal
        cancelText="Close"
        footer={false}
        open={isModalVisible}
        onCancel={handleCancel}
      >
        <Table
          loading={loading}
          scroll={{
            y: 700,
          }}
          sticky
          pagination={false}
          rowKey="id"
          size="small"
          columns={columns}
          dataSource={data}
        />
      </Modal>
    </>
  );
};
