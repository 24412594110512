import { useApp } from '@/utils/useapp';
import { message, Form, Modal, Space, Button, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import SMSForm from '@/components/SMSForm';
import { serialize } from 'object-to-formdata';
import { get, uniq } from 'lodash';
import actionUtil, {
  TAction,
} from '@/components/Tasks/Interfaces/ActionInterface';
import useAction from '../../useAction';
import { showErrorMessage } from '@/utils/show-error-message';

const SendSMSModal: React.FC<
  TAction & { open: boolean; onClose: () => void }
> = ({ task, targetModel, target, open, onDone, onClose }) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [smsForm] = useForm();
  const { sendSMS } = useAction({ target, targetModel });

  const handleOnClose = () => {
    smsForm.resetFields();
    onClose();
  };

  const handleSendSMS = async () => {
    try {
      const data = await smsForm.validateFields();

      const config = {
        data: serialize(
          {
            ...data,
            task_id: task.id,
            action: actionUtil.SEND_SMS_TO_SHIPPER,
          },
          { indices: true, nullsAsUndefineds: true },
        ),
        requestType: 'form',
      };

      await sendSMS(config, setLoading, onDone);

      smsForm.resetFields();
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  const initForm = () => {
    const sendTo = `+1 ${targetModel.pickup_contact_phone}`;

    const pickupNumber =
      get(targetModel, 'pickup_number', '') ||
      get(targetModel, 'customer_reference_number', '');
    const shipperName = targetModel.pickup_contact_name;
    const operatorName = get(targetModel, 'operator.name', '');

    const formatDimensions = (item: any) => {
      if (item.dimension_unit === 'cm') {
        return `${Math.ceil(item.length * 0.393701)}*${Math.ceil(
          item.width * 0.393701,
        )}*${Math.ceil(item.height * 0.393701)} inches`;
      }
      return `${item.length}*${item.width}*${item.height} ${
        item.dimension_unit === 'in' ? 'inches' : item.dimension_unit
      }`;
    };

    const formatWeight = (item: any) => {
      if (item.weight_unit === 'kg') {
        return `${Math.ceil(item.total_weight * 2.20462)} lbs`;
      }
      return `${item.total_weight} ${item.weight_unit}`;
    };

    smsForm.setFieldsValue({
      sendTo: sendTo?.length > 0 ? sendTo : [''],
      message: `Hi ${shipperName},
This is ${operatorName} from Drayeasy. I just wanted to kindly remind you that your customer has scheduled a pickup from your location for tomorrow.
Here are the shipment details:
- Ref#: ${pickupNumber}
- Pallet Count and Commodity: [Details]
${targetModel.items
  .map(
    (item: any, index: number) =>
      `   - item${index + 1} ${formatDimensions(item)} ${item.units} ${
        item.package_type
      } total ${formatWeight(item)}`,
  )
  .join('\n')}
Please ensure the shipment is ready for pickup at the scheduled time. If you have any questions or need further assistance, feel free to reach out.
Thank you for your cooperation!
Best regards,
${operatorName}
Drayeasy Team`,
    });
  };

  useEffect(() => {
    open && initForm();
  }, [open, targetModel]);

  return (
    <>
      <Modal
        title={`LTL - ${task.name}`}
        width={'75%'}
        onCancel={handleOnClose}
        destroyOnClose={true}
        open={open}
        footer={
          <>
            <Space>
              <Button disabled={loading} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button type="primary" disabled={loading} onClick={handleSendSMS}>
                Send
              </Button>
            </Space>
          </>
        }
      >
        <div style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
          <Spin spinning={loading}>
            <Form form={smsForm} layout="vertical">
              <SMSForm form={smsForm} />
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default SendSMSModal;
