import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Space,
  Table,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import usePagination from '@/components/usePagination';
import { TCollection, TUser } from '@/types';
import { TablePaginationConfig } from 'antd/es/table';
import { TransactionLog } from '@/pages/orders/components/TransactionLog';
import AddCreditModal from './components/SetCreditModal';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';
import { showErrorMessage } from '@/utils/show-error-message';

export type TUserAccount = {
  id: number;
  user_id: number;
  prepaid_amount: number;
  hold_amount: number;
  settled_amount: number;
  balance: number;
};

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TUserAccount>>();
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig, sortBy = '', sortValue = '') => {
      const params = filter.getFieldsValue();
      if (undefined === params.start) {
        params.start = '';
      }
      if (undefined === params.end) {
        params.end = '';
      }

      if (sortBy) {
        params.sort_by = sortBy;
      }

      if (sortValue) {
        params.sort_value = sortValue;
      }

      setLoading(true);
      try {
        const result = await app.service.get('userAccounts', {
          params: {
            ...params,
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });
        setData(result);
      } catch (e: any) {
        showErrorMessage(e);
      }

      setLoading(false);
    },
    [],
  );

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (v, record) => {
          return record.user?.name;
        },
        width: 200,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        render: (v, record) => {
          return record.user?.company?.name;
        },
        width: 200,
      },
      {
        title: 'Prepaid Amount',
        dataIndex: 'prepaid_amount',
        key: 'prepaid_amount',
        width: 200,
        sorter: true,
        render: (v) => {
          return new Intl.NumberFormat('en-US').format(v);
        },
      },
      {
        title: 'Prepaid Balance',
        dataIndex: 'prepaid_balance',
        key: 'prepaid_balance',
        width: 200,
        sorter: true,
        render: (v) => {
          return new Intl.NumberFormat('en-US').format(v);
        },
      },
      {
        title: 'Credit',
        dataIndex: 'credit_amount',
        key: 'credit_amount',
        width: 200,
        sorter: true,
        render: (v) => {
          return new Intl.NumberFormat('en-US').format(v);
        },
      },
      {
        title: 'Order Amount',
        dataIndex: 'order_amount',
        key: 'order_amount',
        width: 200,
        sorter: true,
        render: (v) => {
          return new Intl.NumberFormat('en-US').format(v);
        },
      },
      {
        title: 'Settled Amount',
        dataIndex: 'settled_amount',
        key: 'settled_amount',
        width: 200,
        sorter: true,
        render: (v) => {
          return new Intl.NumberFormat('en-US').format(v);
        },
      },
      {
        title: 'Available Balance',
        dataIndex: 'balance',
        key: 'balance',
        width: 200,
        sorter: true,
        render: (v) => {
          return new Intl.NumberFormat('en-US').format(v);
        },
      },
      {
        title: 'Action',
        key: 'action',
        width: 100,
        align: 'center',
        fixed: 'right',
        render: (text, record: TUserAccount) => {
          return (
            <Space split={<Divider type="vertical" />}>
              <TransactionLog account_id={record.id} />

              {app.store.auth.access('update.userAccount') && (
                <AddCreditModal
                  account={record}
                  onOk={() => fetchData(pagination)}
                />
              )}
            </Space>
          );
        },
      },
    ],
    [pagination],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="User Accounts"></Header>

      <div className={styles.filter}>
        <div style={{ width: '100%' }}>
          <Form layout="vertical" form={filter} onFinish={fetchData}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label={'User'} name="user_id">
                  <UserSyncSelect
                    mode="multiple"
                    maxTagCount="responsive"
                    type="user"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={'Company'} name="company_id">
                  <CompanySyncSelect allowClear />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label={'User Email'} name="email">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Sales" name="sales_id">
                  <UserSyncSelect type="admin" allowClear />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Sales Support" name="sales_support_id">
                  <UserSyncSelect type="admin" allowClear />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label={' '}>
                  <Space>
                    <Button htmlType="submit" type="primary">
                      Search
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <Table
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey={(record) => `${record.id}-${record.user_id}`}
        columns={columns}
        dataSource={data?.data || []}
        onChange={(pagination, filters, sorter: any) => {
          let sortBy = sorter.field;
          let sortValue = null;

          if (null === sorter.order) {
            sortBy = null;
          } else {
            sortValue = sorter.order === 'ascend' ? 'asc' : 'desc';
          }

          fetchData(pagination, sortBy, sortValue);
        }}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
    </div>
  );
};

export default Index;
