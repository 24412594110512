import { Button } from 'antd';
import { useApp } from '@/utils/useapp';
import { useEffect, useState } from 'react';
import React from 'react';
import {
  TCfsShipmentAction,
  TARGET_TYPE_CFS_SHIPMENT,
  TLtlShipmentAction,
} from '@/components/Tasks/Interfaces/ActionInterface';
import SendBOLEmailModal from './SendBOLEmailModal';
import { use } from 'echarts';

const SendBOL: React.FC<TCfsShipmentAction> = ({
  task,
  targetModel: cfsShipment,
  onDone,
  onSaveOperation,
}) => {
  const app = useApp();
  const [open, setOpen] = useState(false);

  const handleCloseOpen = () => {
    setOpen(false);
  };

  const handleOnSent = () => {
    onDone();
    handleCloseOpen();
  };

  return (
    <>
      <Button
        disabled={task?.disabled}
        size="small"
        onClick={() => setOpen(true)}
      >
        Send BOL
      </Button>
      {open && cfsShipment && (
        <SendBOLEmailModal
          open={open}
          admin={app.store.auth.account}
          task={task}
          targetModel={cfsShipment}
          target={TARGET_TYPE_CFS_SHIPMENT}
          onClose={handleCloseOpen}
          onDone={handleOnSent}
        />
      )}
    </>
  );
};

export default SendBOL;
