import React, { useState, useCallback } from 'react';
import { useObserver } from 'mobx-react';

import {
  Form,
  Select,
  message,
  Switch,
  Drawer,
  Space,
  Button,
  Input,
  Tag,
} from 'antd';

import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import { AntDDatePicker, presetDates } from 'antd-datepicker';
import moment from 'moment';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import { CargoTypeSelect } from '@/components/CargoTypeSelect';
import { BulkUpdateFormItem } from '@/components/BulkUpdateFormItem';
import {
  ORDER_SALES_TYPE_MAP,
  ORDER_STATES,
  ORDER_STATE_CANCELED,
} from './data';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { ConsigneeSyncSelect } from '@/components/ConsigneeSyncSelect';
import { concat, findIndex, truncate } from 'lodash';
import { SyncContainerState } from './SyncContainerState';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  orders: any;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

type saveDataItem = {
  id: number;
  expired_at?: string;
  visible?: boolean;
  from_facility_type?: string;
  to_facility_type?: string;
  cargo_type_id?: number;
  note?: string;
};

export const DrawerFormBulkUpdate: React.FC<Props> = ({
  orders = [],
  open = false,
  onClose,
  onSubmit,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [names, setNames] = useState<Array<string>>([]);
  const [openSyncContainer, setOpenSyncContainer] = useState(false);

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields(names);
    } catch {
      return;
    }

    const isSync = await form.getFieldValue('isSync');

    if (values.consignee) {
      values.consignee_id = values.consignee.id;
      delete values.consignee;
    }

    const data: any = [];

    orders.map((order: any) => {
      const item = {
        id: order.id,
        ...values,
        isSync,
      };

      data.push(item);
    });

    setLoading(true);

    try {
      await app.service.put('orders/bulk', { data: data });
      message.success('Saved');
      form.resetFields();
      setNames([]);
      onSubmit();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    form.resetFields();
    setNames([]);
    onClose();
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const handleListenNamesRequired = (name: string, required: boolean) => {
    const _names = Object.assign([], names);
    if (_names.find((n) => n == name)) {
      !required && setNames(_names.filter((n) => n != name));
    } else {
      _names.push(name);
      required && setNames(_names);
    }
  };

  const handleChangeStatus = (value: any) => {
    handleChange({
      target: {
        name: 'state',
        value: value,
      },
    });
    if (value == ORDER_STATE_CANCELED) {
      setOpenSyncContainer(true);
    } else {
      handleChange({
        target: {
          name: 'isSync',
          value: false,
        },
      });
    }
  };

  const handleYesToSyncContainer = async () => {
    handleChange({
      target: {
        name: 'isSync',
        value: true,
      },
    });
    setOpenSyncContainer(false);
  };

  const handleNoToSyncContainer = async () => {
    handleChange({
      target: {
        name: 'isSync',
        value: false,
      },
    });
    setOpenSyncContainer(false);
  };

  return (
    <>
      <Drawer
        title={'Bulk Update'}
        placement={'right'}
        width={'50%'}
        onClose={handleClose}
        destroyOnClose={true}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        Selected:
        <div style={{ marginBottom: '10px' }}>
          {orders?.map((order: any) => (
            <Tag key={order.uid} color="processing">
              {order.uid}
            </Tag>
          ))}
        </div>
        <Form layout="vertical" form={form} onFinish={handleSave}>
          <Form.Item name="isSync" noStyle>
            <Input hidden></Input>
          </Form.Item>
          <BulkUpdateFormItem
            form={form}
            name="state"
            label="Status"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Select
                disabled={names?.includes('state') ? false : true}
                onChange={(state) => handleChangeStatus(state)}
              >
                {ORDER_STATES.map((k) => (
                  <Select.Option key={k} value={k}>
                    {k}
                  </Select.Option>
                ))}
              </Select>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name="sales_type_id"
            label="Sales Type"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Select
                disabled={names?.includes('sales_type_id') ? false : true}
                onChange={(state) =>
                  handleChange({
                    target: {
                      name: 'sales_type_id',
                      value: state,
                    },
                  })
                }
              >
                {Object.keys(ORDER_SALES_TYPE_MAP).map((k) => (
                  <Select.Option key={k} value={k}>
                    {ORDER_SALES_TYPE_MAP[k]}
                  </Select.Option>
                ))}
              </Select>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name="operator_id"
            label="Operator"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <UserSyncSelect
                disabled={names?.includes('operator_id') ? false : true}
                type="admin"
              />
            }
          />

          <BulkUpdateFormItem
            form={form}
            name="sales_id"
            label="Sales"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <UserSyncSelect
                disabled={names?.includes('sales_id') ? false : true}
                type="admin"
              />
            }
          />

          <BulkUpdateFormItem
            form={form}
            name="document_person_id"
            label="D.P."
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <UserSyncSelect
                disabled={names?.includes('document_person_id') ? false : true}
                type="admin"
              />
            }
          />

          <BulkUpdateFormItem
            form={form}
            name="user_id"
            label="Customer"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <UserSyncSelect
                disabled={names?.includes('user_id') ? false : true}
              />
            }
          />

          <BulkUpdateFormItem
            form={form}
            name="consignee_id"
            label="Consignee"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <ConsigneeSyncSelect
                disabled={names?.includes('consignee_id') ? false : true}
              />
            }
          />
          <BulkUpdateFormItem
            form={form}
            name="project_code"
            label="Project Code"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Input
                disabled={names?.includes('project_code') ? false : true}
              />
            }
          />
        </Form>
      </Drawer>
      <SyncContainerState
        open={openSyncContainer}
        loading={loading}
        onNo={handleNoToSyncContainer}
        onYes={handleYesToSyncContainer}
      />
    </>
  );
};
