import { useState } from 'react';
import { Button, message } from 'antd';
import { TCfsShipmentAction } from '@/components/Tasks/Interfaces/ActionInterface';
import actionUtils from '@/components/Tasks/Interfaces/ActionInterface';
import { showErrorMessage } from '@/utils/show-error-message';

const CreateBOL: React.FC<TCfsShipmentAction> = ({
  task,
  targetModel: cfsShipment,
  onDone,
  onSaveOperation,
}) => {
  const [loading, setLoading] = useState(false);

  const handleCreateBOL = async () => {
    try {
      onSaveOperation(
        task,
        actionUtils.CREAT_BOL,
        undefined,
        undefined,
        (loading: boolean) => {
          setLoading(loading);
        },
        onDone,
      );
    } catch (err: any) {
      showErrorMessage(err, { fallback: 'Failed to create BOL' });
    }
  };

  return (
    <>
      <Button
        disabled={task?.disabled || loading}
        size="small"
        onClick={handleCreateBOL}
      >
        Create BOL
      </Button>
    </>
  );
};

export default CreateBOL;
