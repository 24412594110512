import React from 'react';
import {
  Button,
  message,
  Drawer,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Table,
  TableColumnsType,
  Space,
  InputNumber,
  Select,
  Radio,
} from 'antd';
import { TInvoice } from '@/types';
import { FormInstance, useWatch } from 'antd/lib/form/Form';
import { BankAccountSelect } from '@/components/BankAccountSelect';
import moment from 'moment';
import { sumBy, values } from 'lodash';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import classNames from 'classnames';
import {
  INVOICE_TYPE_ORDER,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_MAP,
} from './data';
import UserBalance from './UserBalance';
import UserWallet from './UserWallet';

interface Props {
  form: FormInstance;
  invoices: TInvoice[];
}

const PaymentForm: React.FC<Props> = ({ form, invoices = [] }) => {
  const invoicesAmount = useWatch('invoices_amount', form) || {};
  const paymentMethod = useWatch('payment_method', form);
  const userId = useWatch('user_id', form);

  const payInFull = () => {
    const _invoicesAmount: { [key: string | number]: number } = {};

    for (const i in invoices) {
      _invoicesAmount[invoices[i].id] = invoices[i].amount_due;
    }

    form.setFieldValue('invoices_amount', _invoicesAmount);
  };

  const columns: TableColumnsType<any> = React.useMemo(
    () => [
      {
        title: () => (
          <Space>
            <span>INV#</span>
            <Button type="link" size="small" onClick={payInFull}>
              Pay In Full
            </Button>
          </Space>
        ),
        dataIndex: 'uid',
      },
      {
        title: 'Order#/LTL#/FTL#',
        dataIndex: ['order', 'uid'],
        key: 'invoiceable_id',
        sorter: true,
        width: 160,
        render: (text, record) => {
          return record?.invoiceable?.uid || '';
        },
      },
      {
        title: 'CNTR#/Pro#',
        dataIndex: 'container_numbers',
        key: 'container_numbers',
        render: (text, record) => {
          if (record?.invoiceable_type == INVOICE_TYPE_ORDER) {
            return (
              <>
                {record.container_numbers
                  ? record.container_numbers.join(',')
                  : '-'}
              </>
            );
          }
          return record.invoiceable.pro_number || '';
        },
        sorter: true,
        width: 150,
      },
      {
        title: 'Invoice Date',
        dataIndex: 'invoiced_at',
      },
      {
        title: 'Due Date',
        dataIndex: 'due_at',
      },
      {
        title: 'Amount Total',
        dataIndex: 'amount_total',
      },
      {
        title: 'Amount Due',
        dataIndex: 'amount_due',
        render: (value) => {
          return (
            <span
              className={classNames({ 'bg-warning': parseFloat(value) === 0 })}
            >
              {value}
            </span>
          );
        },
      },
      {
        title: 'Amount Paid',
        dataIndex: 'amount_paid',
        render: (_, record: TInvoice, index) => {
          return (
            <InputNumber
              style={{ width: '100%' }}
              value={invoicesAmount[record.id]}
              onChange={(value) =>
                form.setFieldValue('invoices_amount', {
                  ...invoicesAmount,
                  [record.id]: value,
                })
              }
            />
          );
        },
      },
      {
        title: 'Customer',
        dataIndex: ['user', 'name'],
        render: (value, record) => {
          return `${record.user?.company?.code}(${record.user?.name})`;
        },
      },
    ],
    [invoicesAmount],
  );

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          payment_method: PAYMENT_METHOD_CASH,
        }}
      >
        <Form.Item name="id" noStyle>
          <Input hidden />
        </Form.Item>
        <Form.Item
          name="user_id"
          label="Customer"
          rules={[{ required: true, message: 'Customer is required' }]}
          help={<UserWallet userId={userId} />}
        >
          <UserSyncSelect disabled />
        </Form.Item>
        <Form.Item
          name="payment_method"
          label="Payment method"
          rules={[{ required: true, message: 'Payment method is required' }]}
        >
          <Radio.Group>
            {Object.keys(PAYMENT_METHOD_MAP).map((key) => (
              <Radio key={key} value={+key}>
                {PAYMENT_METHOD_MAP[key]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {paymentMethod == PAYMENT_METHOD_CASH && (
          <Form.Item
            name="bank_account_id"
            label="Bank Account"
            rules={[{ required: true, message: 'Bank Account is required' }]}
          >
            <BankAccountSelect />
          </Form.Item>
        )}
        <Form.Item name="check_number" label="Check#">
          <Input />
        </Form.Item>
        <Row gutter={24}>
          <Col md={8}>
            <Form.Item label="Deposit Date" name="deposited_at">
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.deposited_at !== curValues.deposited_at
                }
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const value = getFieldValue('deposited_at');
                  return (
                    <DatePicker
                      style={{ width: '100%' }}
                      value={value ? moment(value) : null}
                      onChange={(v) =>
                        setFieldsValue({
                          deposited_at: v?.format('YYYY-MM-DD'),
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Post Date"
              name="post_at"
              required
              rules={[{ required: true, message: 'Post Date is required' }]}
            >
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.post_at !== curValues.post_at
                }
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const value = getFieldValue('post_at');
                  return (
                    <DatePicker
                      style={{ width: '100%' }}
                      value={value ? moment(value) : null}
                      onChange={(v) =>
                        setFieldsValue({
                          post_at: v?.format('YYYY-MM-DD'),
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item name="void_at" label="Void Date">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="invoices_amount" noStyle>
          <Input hidden />
        </Form.Item>
        <Table
          className="mb-md"
          bordered
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={invoices}
          scroll={{ y: 300 }}
          pagination={false}
          summary={(pageData) => {
            let amountTotal = 0,
              amountDue = 0;

            const amountPaid = sumBy(values(invoicesAmount), (v) =>
              parseFloat(v || 0),
            );

            pageData.forEach(({ amount_due, amount_total }) => {
              amountTotal += parseFloat(amount_total || 0);
              amountDue += parseFloat(amount_due || 0);
            });

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={5} align="right">
                    <strong>Total</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <strong>{amountTotal.toFixed(2)}</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <strong>{amountDue.toFixed(2)}</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <strong>{amountPaid.toFixed(2)}</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} colSpan={100} />
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />

        <Form.Item name="remark" label="Remark">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </>
  );
};

export default PaymentForm;
