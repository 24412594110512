import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message, Row, Col, Divider, Card } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import React, { useEffect, useMemo } from 'react';
import { VendorFormW9 } from './VendorFormW9';
import { serialize } from 'object-to-formdata';
import { get, values } from 'lodash';
import { FORM_TYPE_W8 } from '../data';
import { showErrorMessage } from '@/utils/show-error-message';

interface TProps {
  id: number;
  visible: boolean;
  mc_number?: string;
  onClose: () => void;
  onSaved?: (data: any) => void;
}

export const VendorFormW9Drawer: React.FC<TProps> = ({
  id,
  visible,
  mc_number,
  onClose,
  onSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();
  const title = useMemo(() => `${!id ? 'Create' : 'Edit'} Vendor Form W9`, [
    id,
  ]);

  const fetchData = async (id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.get(`vendorFormW9s/${id}`);
      form.setFieldsValue({
        ...resp.data,
      });
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    fetchData(id);
  }, [id]);

  useEffect(() => {
    !id &&
      mc_number &&
      !form.getFieldValue('mc_number') &&
      form.setFieldValue('mc_number', mc_number);
  }, [mc_number, id]);

  const validateValues = (values: any) => {
    if (values.id) {
      return true;
    }

    if (values.form_type === FORM_TYPE_W8 && values.files.length == 0) {
      message.error('Please upload documents');
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }
    !values.social_security_number && delete values.social_security_number;
    !values.employer_identification_number &&
      delete values.employer_identification_number;

    if (!validateValues(values)) {
      return false;
    }

    setLoading(true);

    const config = {
      data: serialize(
        {
          ...values,
        },
        { indices: true, nullsAsUndefineds: true },
      ),
      requestType: 'form',
    };

    try {
      const resp = !values.id
        ? await app.service.post('vendorFormW9s', config)
        : await app.service.put(`vendorFormW9s/${values.id}`, {
            data: values,
          });
      message.success('Saved');
      onSaved && onSaved(resp.data);
      handleClose();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };
  return (
    <Drawer
      title={title}
      width={'85%'}
      onClose={handleClose}
      destroyOnClose={true}
      open={visible}
      extra={
        <Space>
          <Button onClick={handleClose}>Close</Button>
          <Button type="primary" loading={loading} onClick={handleSave}>
            Save
          </Button>
        </Space>
      }
    >
      <VendorFormW9 form={form} />
    </Drawer>
  );
};
