import { useApp } from '@/utils/useapp';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import React, { useState } from 'react';
import { BaseButtonProps } from 'antd/lib/button/button';
import { auth } from '@/stores';
import { showErrorMessage } from '@/utils/show-error-message';

type TProps = {
  is_sales_confirmed: boolean;
  orderInvoiceId: number;
  onSetLoading: (loading: boolean) => void;
  onSaved: (orderInvoiceId: number) => void;
};

export const SalesConfirmButton: React.FC<TProps & BaseButtonProps> = ({
  is_sales_confirmed,
  orderInvoiceId,
  onSetLoading,
  onSaved,
  ...props
}) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);

  const toSetLoading = (loading: boolean) => {
    setLoading(loading);
    onSetLoading && onSetLoading(loading);
  };

  const handleConfirm = async () => {
    toSetLoading(true);
    if (!orderInvoiceId) {
      return false;
    }

    const isSalesConfirmed = is_sales_confirmed ? false : true;

    try {
      await app.service.patch(`orderInvoices/${orderInvoiceId}/salesConfirm`, {
        data: {
          is_sales_confirmed: isSalesConfirmed,
        },
      });

      message.success('Saved');
      onSaved(orderInvoiceId);
    } catch (e: any) {
      showErrorMessage(e);
    }

    toSetLoading(false);
  };

  return (
    <Popconfirm
      placement="left"
      title={`Sure to ${is_sales_confirmed ? 'cancel confirm' : 'confirm'}?`}
      okText="Confirm"
      cancelText="Cancel"
      onConfirm={handleConfirm}
    >
      <Tooltip
        placement="right"
        title="Sales confirm or cancel confirm this invoice"
      >
        <Button
          type="primary"
          disabled={loading || !auth.hasRole(['accounting', 'controller'])}
          {...props}
        >
          {is_sales_confirmed ? 'Cancel Sales Confirm' : 'Sales Confirm'}
        </Button>
      </Tooltip>
    </Popconfirm>
  );
};
