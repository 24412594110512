import {
  TTarget,
  TARGET_TYPE_LTL_SHIPMENT,
  TARGET_TYPE_FTL_SHIPMENT,
  TARGET_TYPE_CFS_SHIPMENT,
} from '../Interfaces/ActionInterface';

export enum LTLShipmentEditSRReason {
  ADD_ACCESSORIALS = 'Add Accessorials',
  EDIT_ADDRESS_TYPE = 'Edit Address Type',
  OTHER = 'Other',
}

export enum FTLShipmentEditBRReason {
  ADD_ACCESSORIALS = 'Add Accessorials',
  EDIT_ADDRESS_TYPE = 'Edit Address Type',
  OTHER = 'Other',
}

export enum CFShipmentEditSRReason {
  ADD_ACCESSORIALS = 'Add Accessorials',
  EDIT_ADDRESS_TYPE = 'Edit Address Type',
  OTHER = 'Other',
}

export const EditSRReasonOptions = (
  target: TTarget['target'],
): { label: string; value: string }[] => {
  let reasonEnum;

  switch (target) {
    case TARGET_TYPE_LTL_SHIPMENT:
      reasonEnum = LTLShipmentEditSRReason;
      break;
    case TARGET_TYPE_FTL_SHIPMENT:
      reasonEnum = FTLShipmentEditBRReason;
      break;
    case TARGET_TYPE_CFS_SHIPMENT:
      reasonEnum = CFShipmentEditSRReason;
      break;
  }

  return Object.values(reasonEnum).map((value) => ({
    label: value,
    value: value,
  }));
};

export enum RebillStatus {
  Confirmed = 'Confirmed',
  Canceled = 'Canceled',
}

export const RebillStatusOptions = [
  { label: 'Confirmed', value: RebillStatus.Confirmed },
  { label: 'Canceled', value: RebillStatus.Canceled },
];

//Reclass，Dryrun和Other
export enum RebillReason {
  Reclass = 'Reclass',
  Dryrun = 'Dryrun',
  Other = 'Other',
}

export const RebillReasonOptions = [
  { label: 'Reclass', value: RebillReason.Reclass },
  { label: 'Dryrun', value: RebillReason.Dryrun },
  { label: 'Other', value: RebillReason.Other },
];
