import { Button } from 'antd';
import { useState } from 'react';
import React from 'react';
import { upperFirst } from 'lodash';
import { formatActionInfo, TCommonProps, TItemProps, TOnDoneProps } from '../';
import SendSOCEmptyReturnDepotVerificationDrawer from '../SendSOCEmptyReturnDepotVerification';

export const SendSOCEmptyReturnDepotVerification: React.FC<
  TCommonProps & TItemProps & TOnDoneProps
> = ({ item, container, onDone }) => {
  const [open, setOpen] = useState(false);

  const handleOnOpen = () => {
    setOpen(true);
  };

  const handleCloseOpen = () => {
    setOpen(false);
  };

  const handleOnSent = () => {
    onDone && onDone();
    handleCloseOpen();
  };

  return (
    <>
      <Button
        disabled={!container.soc_return_location}
        key={item.action}
        size="small"
        onClick={handleOnOpen}
      >
        {upperFirst(item.action)}
      </Button>
      {open && container && (
        <SendSOCEmptyReturnDepotVerificationDrawer
          open={open}
          container={container}
          onClose={handleCloseOpen}
          onSent={handleOnSent}
          actionInfo={formatActionInfo(item, item.action)}
        />
      )}
    </>
  );
};
