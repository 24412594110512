import {
  Table,
  Button,
  Input,
  Space,
  List,
  Popover,
  message,
  Tooltip,
  Modal,
  TablePaginationConfig,
  Tabs,
} from 'antd';
import { useApp } from '@/utils/useapp';
import React, { useEffect, useState } from 'react';
import usePagination from '@/components/usePagination';
import { TCollection } from '@/types';
import { showErrorMessage } from '@/utils/show-error-message';

export interface BIPlace {
  id: string;
  facilities: {
    name: string;
    count: number;
  }[];
  companies: {
    name: string;
    count: number;
  }[];
  formatted_address: string;
  city: string;
  state: string;
  zipcode: string;
  address_types: string[];
  address_types_confidence: string;
  is_dock: boolean;
  dock_confidence: string;
  order_count: number;
  pu_count: number;
  del_count: number;
  client_count: number;
  internal_memo: string;
}

const maxCountFacility = (record: BIPlace) => {
  return record.facilities.reduce(
    (prev, curr) => (prev.count > curr.count ? prev : curr),
    { name: '', count: 0 },
  ).name;
};

enum EModelType {
  DRAY = 'App\\Models\\Container',
  LTL = 'App\\Domains\\TL\\Models\\LTLShipment',
  FTL = 'App\\Domains\\FTL\\Models\\FTLShipment',
}

interface BITableProps {
  modelType: EModelType;
  data?: TCollection<BIPlace>;
  loading: boolean;
  facilityName: string;
  setFacilityName: (value: string) => void;
  address: string;
  setAddress: (value: string) => void;
  city: string;
  setCity: (value: string) => void;
  zipcode: string;
  setZipcode: (value: string) => void;
  handleKeyDown: (e: React.KeyboardEvent) => void;
  fetchData: () => void;
  resetData: () => void;
  handleTableChange: (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any,
  ) => void;
  pagination: TablePaginationConfig;
}

const BITableContent: React.FC<BITableProps> = ({
  data,
  loading,
  facilityName,
  setFacilityName,
  address,
  setAddress,
  city,
  setCity,
  zipcode,
  setZipcode,
  handleKeyDown,
  fetchData,
  resetData,
  handleTableChange,
  pagination,
}) => {
  const app = useApp();

  const handleCopy = (record: BIPlace) => {
    console.log(record);
    const content = `${maxCountFacility(record)}

${record.formatted_address}, ${record.city}, ${record.state}, ${
      record.zipcode
    }`;
    navigator.clipboard.writeText(content);
    message.success(content);
  };

  const handleMemo = (record: BIPlace) => {
    console.log(record);
    Modal.confirm({
      title: 'Edit Memo',
      content: (
        <Input.TextArea
          defaultValue={record.internal_memo}
          onChange={(e) => {
            record.internal_memo = e.target.value;
          }}
          rows={4}
        />
      ),
      onOk: async () => {
        try {
          await app.service.patch(`bi/places/${record.id}`, {
            data: {
              internal_memo: record.internal_memo,
            },
          });
          message.success('Memo updated successfully');
        } catch (err: any) {
          showErrorMessage(err);
        }
      },
    });
  };

  const columns = [
    {
      title: 'Facility Name',
      key: 'facilityName',
      width: 200,
      render: (text: string, record: BIPlace) => (
        <Space>
          <span>{maxCountFacility(record)}</span>
          {record.facilities.length > 1 && (
            <Popover
              content={
                <List
                  size="small"
                  dataSource={record.facilities}
                  renderItem={(item) => (
                    <List.Item>
                      {item.name} ({item.count})
                    </List.Item>
                  )}
                />
              }
            >
              <a>{record.facilities.length - 1} more</a>
            </Popover>
          )}
        </Space>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'formatted_address',
      key: 'formatted_address',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 150,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Zipcode',
      dataIndex: 'zipcode',
      key: 'zipcode',
    },
    {
      title: 'Business Type',
      dataIndex: 'address_types',
      key: 'address_types',
    },
    {
      title: 'Dock',
      dataIndex: 'is_dock',
      key: 'is_dock',
      width: 100,
      render: (text: boolean, record: BIPlace) =>
        record.dock_confidence ? (
          <span>{`${record.is_dock ? 'Yes' : 'No'} (${
            record.dock_confidence
          })`}</span>
        ) : (
          ''
        ),
    },
    {
      title: 'Order Count',
      dataIndex: 'order_count',
      key: 'order_count',
      sorter: true,
    },
    {
      title: 'PU Count',
      dataIndex: 'pu_count',
      key: 'pu_count',
      sorter: true,
    },
    {
      title: 'Del Count',
      dataIndex: 'del_count',
      key: 'del_count',
      sorter: true,
    },
    {
      title: 'Client Count',
      dataIndex: 'client_count',
      key: 'client_count',
      sorter: true,
      render: (text: number, record: BIPlace) => (
        <Popover
          content={
            <List
              size="small"
              dataSource={record.companies}
              renderItem={(item) => (
                <List.Item>
                  {item.name} ({item.count})
                </List.Item>
              )}
            />
          }
        >
          <a>{text}</a>
        </Popover>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, record: BIPlace) => (
        <Space>
          <a onClick={() => handleCopy(record)}>Copy</a>

          <a onClick={() => handleMemo(record)}>
            <Tooltip title={record.internal_memo}>
              <span>Memo</span>
            </Tooltip>
          </a>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div style={{ marginBottom: '16px', display: 'flex', gap: '8px' }}>
        <Input
          placeholder="Facility Name"
          style={{ width: '150px' }}
          value={facilityName}
          onChange={(e) => setFacilityName(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Input
          placeholder="Address"
          style={{ width: '150px' }}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Input
          placeholder="City"
          style={{ width: '150px' }}
          value={city}
          onChange={(e) => setCity(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Input
          placeholder="Zipcode"
          style={{ width: '100px' }}
          value={zipcode}
          onChange={(e) => setZipcode(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button type="primary" onClick={fetchData}>
          Search
        </Button>
        <Button onClick={resetData}>Reset</Button>
      </div>
      <Table
        columns={columns}
        dataSource={data?.data}
        loading={loading}
        rowKey="id"
        scroll={{ x: true }}
        onChange={handleTableChange}
        sortDirections={['ascend', 'descend']}
        pagination={pagination}
      />
    </>
  );
};

const BIPlace: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TCollection<BIPlace>>();
  const pagination = usePagination(data);
  const [facilityName, setFacilityName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [activeTab, setActiveTab] = useState(EModelType.DRAY);
  const app = useApp();

  const fetchData = async (
    pagination?: TablePaginationConfig,
    sort?: { field?: string; order?: string },
  ) => {
    setLoading(true);
    const params = {
      ...(facilityName && { facility_name: facilityName }),
      ...(address && { address1: address }),
      ...(city && { city: city }),
      ...(zipcode && { zipcode: zipcode }),
      ...(sort?.field && { sort_by: sort.field }),
      ...(sort?.order && {
        sort_value: sort.order === 'ascend' ? 'asc' : 'desc',
      }),
      page: pagination?.current || 1,
      per_page: pagination?.pageSize || 20,
      model_type: activeTab,
    };

    try {
      const response = await app.service.get('bi/places', {
        params,
      });
      setData(response);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any,
  ) => {
    fetchData(
      pagination,
      sorter.order
        ? {
            field: sorter.field,
            order: sorter.order,
          }
        : undefined,
    );
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      fetchData();
    }
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key as EModelType);
  };

  const resetData = () => {
    setFacilityName('');
    setAddress('');
    setCity('');
    setZipcode('');
    setData(undefined);
  };

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const items = [
    {
      key: EModelType.DRAY,
      label: 'Dray',
      children: (
        <BITableContent
          modelType={EModelType.DRAY}
          data={data}
          loading={loading}
          facilityName={facilityName}
          setFacilityName={setFacilityName}
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          zipcode={zipcode}
          setZipcode={setZipcode}
          handleKeyDown={handleKeyDown}
          fetchData={() => fetchData()}
          resetData={resetData}
          handleTableChange={handleTableChange}
          pagination={pagination}
        />
      ),
    },
    {
      key: EModelType.LTL,
      label: 'LTL',
      children: (
        <BITableContent
          modelType={EModelType.LTL}
          data={data}
          loading={loading}
          facilityName={facilityName}
          setFacilityName={setFacilityName}
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          zipcode={zipcode}
          setZipcode={setZipcode}
          handleKeyDown={handleKeyDown}
          fetchData={() => fetchData()}
          resetData={resetData}
          handleTableChange={handleTableChange}
          pagination={pagination}
        />
      ),
    },
    {
      key: EModelType.FTL,
      label: 'FTL',
      children: (
        <BITableContent
          modelType={EModelType.FTL}
          data={data}
          loading={loading}
          facilityName={facilityName}
          setFacilityName={setFacilityName}
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          zipcode={zipcode}
          setZipcode={setZipcode}
          handleKeyDown={handleKeyDown}
          fetchData={() => fetchData()}
          resetData={resetData}
          handleTableChange={handleTableChange}
          pagination={pagination}
        />
      ),
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      <Tabs activeKey={activeTab} onChange={handleTabChange} items={items} />
    </div>
  );
};

export default BIPlace;
