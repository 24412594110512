import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Space,
  Table,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import {
  TDemand,
  TStatisticsInternalRegion,
  TStatisticsInternalRegionArrayItem,
} from '@/types';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import moment from 'moment';
import { PortSelect } from '@/components/PortSelect';
import { WarehouseSelect } from '@/components/WarehouseSelect';
import ReactECharts from 'echarts-for-react';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TDemand[]>([]);
  const [filter] = useForm();

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const result = await app.service.get('statistics/demand', {
        params: {
          ...filter.getFieldsValue(),
        },
      });

      setData(result?.data ?? []);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  }, []);

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await filter.setFieldsValue({ [name]: value });
  };

  const generateOptions = () => {
    const drawnData = data.map((item) => {
      return [+new Date(item.date), item.point];
    });
    return {
      tooltip: {
        trigger: 'axis',
        position: function (pt: any) {
          return [pt[0], '10%'];
        },
      },
      xAxis: {
        type: 'time',
        boundaryGap: false,
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%'],
      },
      series: [
        {
          name: 'Value',
          type: 'line',
          smooth: true,
          symbol: 'none',
          areaStyle: {},
          data: drawnData,
        },
      ],
    };
  };

  return (
    <div className={styles.main}>
      <div className={styles.filter}>
        <Form
          layout="vertical"
          form={filter}
          onFinish={fetchData}
          style={{ width: '100%' }}
        >
          <Row gutter={8}>
            <Col flex="1 1 auto">
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="intermodal_region_id"
                    label="IR"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <IntermodalRegionSelect
                      selected={getFieldValue('intermodal_region_id')}
                      onSelect={(id: string) =>
                        handleChange({
                          target: { name: 'intermodal_region_id', value: id },
                        })
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col flex="1 1 auto">
              <Form.Item name="start_date" style={{ display: 'none' }} />
              <Form.Item name="end_date" style={{ display: 'none' }} />
              <Form.Item label="Date" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const startDate = getFieldValue('start_date');
                  const endDate = getFieldValue('end_date');
                  return (
                    <DatePicker.RangePicker
                      style={{ width: '100%' }}
                      value={
                        startDate && endDate
                          ? [moment(startDate), moment(endDate)]
                          : undefined
                      }
                      onChange={(value) =>
                        setFieldsValue({
                          start_date: value
                            ? value[0]?.format('YYYY-MM-DD')
                            : null,
                          end_date: value
                            ? value[1]?.format('YYYY-MM-DD')
                            : null,
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Col>
            <Col flex="0 0 auto">
              <Form.Item label=" ">
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <ReactECharts
        option={generateOptions()}
        style={{ flexGrow: 1 }}
        // notMerge={true}
        // lazyUpdate={true}
        // theme={'theme_name'}
        // onChartReady={this.onChartReadyCallback}
        // onEvents={EventsDict}
        // opts={}
      />
    </div>
  );
};

export default Index;
