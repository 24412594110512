import React, { useState } from 'react';
import { Form, FormInstance, Input, InputNumber, Button } from 'antd';

interface Props {
  attribute: string;
  form: FormInstance;
  toSave: (values: { amount: number; reason: string }) => void;
  disabled?: boolean;
}

export const AmountChangeForm: React.FC<Props> = ({
  attribute,
  form,
  toSave,
  disabled = false,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }
    setLoading(true);
    await toSave(values);
    setLoading(false);
  };

  return (
    <Form layout="vertical" form={form} disabled={disabled}>
      <Form.Item label="Amount" name={attribute} rules={[{ required: true }]}>
        <InputNumber min={0} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item label="Reason" name="reason" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={handleSave} loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
