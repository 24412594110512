import React, { useState } from 'react';
import { Input } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { TAction } from '../../Interfaces/ActionInterface';

const InputButton: React.FC<
  TAction & {
    disabled?: boolean;
    action: string;
    name: string;
    value: string;
    placeholder?: string;
    onSaveOperation: () => void;
  }
> = ({
  task,
  target,
  action,
  name,
  value,
  placeholder,
  onDone,
  onSaveOperation,
  disabled,
}) => {
  const [text, setText] = useState(value);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    onSaveOperation(task, action, name, text, setLoading, onDone);
  };

  return (
    <Input
      size="small"
      disabled={disabled}
      value={text}
      allowClear
      onChange={(e) => {
        setText(e.target.value);
      }}
      placeholder={placeholder ?? 'Input'}
      addonAfter={
        !disabled && (
          <span className="text-pointer" onClick={handleSave}>
            <CheckOutlined />
          </span>
        )
      }
    />
  );
};

export default InputButton;
