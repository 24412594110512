import { useEffect, useState } from 'react';
import request from '@/utils/request';

export interface APIResponse<T> {
  data: T;
  status: string;
}

export interface CFSConfig {
  reimbursement_margin: number;
}

export function useCFSConfig() {
  const [config, setConfig] = useState<CFSConfig | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const data = (await request.get(
          'cfs/tools/config',
        )) as APIResponse<CFSConfig>;
        setConfig(data.data);
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error('Failed to fetch CFS config'),
        );
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  }, []);

  return { config, loading, error };
}
