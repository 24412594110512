import { useApp } from '@/utils/useapp';
import { message, Form, Modal, Space, Button, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import EmailForm from '@/components/EmailForm';
import { serialize } from 'object-to-formdata';
import { get, uniq } from 'lodash';
import { DOCUMENT_FILE_TYPE_POD } from '@/pages/orders/components/Upload/data';
import moment from 'moment';
import { showErrorMessage } from '@/utils/show-error-message';

export type TActionInfo = {
  type: string;
  data: { [key: string]: string };
};

interface ISendPodConfirmEmailModal {
  containerId: number;
  open: boolean;
  admin: any;
  actionInfo: TActionInfo;
  onSent: () => void;
  onClose: () => void;
}
export const SendPodConfirmEmailModal: React.FC<ISendPodConfirmEmailModal> = ({
  containerId,
  open,
  admin,
  actionInfo,
  onSent,
  onClose,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [emailForm] = useForm();
  const [docs, setDocs] = useState<any[]>([]);
  const [container, setContainers] = useState<any>();

  const fetchContainer = async () => {
    if (!containerId) {
      return false;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(`dispatch/${containerId}`);
      setContainers(resp.data);
      fetchOrderDocuments(resp.data);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchOrderDocuments = async (container: any) => {
    if (!container) {
      return;
    }

    try {
      const respData = await app.service.get(
        `documents/order/${container.order_id}`,
        {
          params: {
            containerIds: container.id,
          },
        },
      );
      emailForm.setFieldsValue({
        documents: respData.data
          .filter(
            (d) =>
              get(d, 'custom_properties.document_type') ==
              DOCUMENT_FILE_TYPE_POD,
          )
          ?.map((d) => d.id),
      });

      setDocs(
        respData.data.map((d: any) => {
          return {
            label: `[${get(d, 'custom_properties.document_type', 'No Type')}]${
              d.file_name
            }`,
            value: d.id,
          };
        }),
      );
    } catch (err: any) {
      setDocs([]);
    }
  };

  const handleOnClose = () => {
    emailForm.resetFields();
    onClose();
  };

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      const data = await emailForm.validateFields();

      const config = {
        data: serialize(
          { ...data, taskAction: actionInfo },
          { indices: true, nullsAsUndefineds: true },
        ),
        requestType: 'form',
      };

      await app.service.post(
        `containers/${container.id}/sendEmailFromInternalTask`,
        config,
      );

      message.success('Sent');

      emailForm.resetFields();

      onSent();
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const initForm = () => {
    const salesEmail = get(container, 'order.sales.email', '');
    const salesSupportEmail = get(container, 'order.sales_support.email', '');

    const sendToList = get(container, 'order.contact_emails');

    const warehouseCity = get(container, 'warehouse.city.full_name');
    const zipcode = get(container, 'warehouse.zipcode', '');
    let warehouseInfo = '';
    warehouseCity && (warehouseInfo += `${warehouseCity}`);
    zipcode && (warehouseInfo += ` ${zipcode}`);
    warehouseInfo += '';

    emailForm.setFieldsValue({
      sendToList: sendToList.length > 0 ? sendToList : [''],
      ccList: uniq([admin?.email, salesEmail, salesSupportEmail]).filter(
        (i) => i,
      ),
      subject: `POD (proof of delivery) for MBL ${container.order.mbl_number} / Container ${container.number} from ${container.final_port.name} to ${warehouseInfo}`,
      body: `
Dear customer,

Please find attached POD for MBL ${container?.order?.mbl_number} / Container ${
        container.number
      } from ${container?.final_port?.name} to ${warehouseInfo}.

Warehouse Check in time: ${container?.warehouse_checkin_at || ''}
Warehouse Check out time: ${container?.warehouse_checkout_at || ''}
Waiting time: ${
        moment(container?.warehouse_checkout_at).diff(
          moment(container?.warehouse_checkin_at),
          'hours',
        ) || ''
      }

Feel free to let me know if you have any questions.
      `,
      addText: false,
    });
  };

  React.useEffect(() => {
    if (open && container) {
      initForm();
    }
  }, [open, container]);

  React.useEffect(() => {
    if (open && containerId) {
      fetchContainer();
    }
  }, [containerId, open]);

  return (
    <>
      <Modal
        title="Send POD Confirm Email"
        width={'70%'}
        onCancel={handleOnClose}
        destroyOnClose={true}
        open={open}
        footer={
          <>
            <Space>
              <Button disabled={loading} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={loading}
                onClick={handleSendEmail}
              >
                Send
              </Button>
            </Space>
          </>
        }
      >
        <div style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
          <Spin spinning={loading}>
            <Form form={emailForm} layout="vertical">
              <EmailForm
                form={emailForm}
                docs={docs}
                has={{
                  from: false,
                  attachment: true,
                  showSelectAttachments: true,
                }}
              />
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};
