import React from 'react';
import { Button, message, Drawer, Space } from 'antd';
import { useApp } from '@/utils/useapp';
import { TBill } from '@/types';
import { useForm } from 'antd/lib/form/Form';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  onSaved: () => void;
  bills: TBill[];
}

const AutoChassisDispute: React.FC<Props> = ({ onSaved, bills = [] }) => {
  const app = useApp();

  const [submitting, setSubmitting] = React.useState(false);

  const handleDispute = async () => {
    if (bills.find((b) => b.is_approved)) {
      return message.error(
        'There has approved bill which is not allowed to disput',
      );
    }

    if (bills.length === 0) {
      return message.error('Please select at least one bill');
    }

    setSubmitting(true);

    try {
      await app.service.post('orderBills/bulk/dispute', {
        data: {
          ids: bills.map((b) => b.id),
        },
      });

      message.success('Saved');

      onSaved && onSaved();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setSubmitting(false);
  };

  return (
    <>
      <Button type="primary" onClick={handleDispute}>
        Auto Chassis Dispute
      </Button>
    </>
  );
};

export default AutoChassisDispute;
