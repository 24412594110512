import { message } from 'antd';
import { useApp } from '@/utils/useapp';
import { useState } from 'react';
import { showErrorMessage } from '@/utils/show-error-message';

export interface CFSLocation {
  id: number;
  firms_code?: string;
  verified?: boolean;
  facility_type?: number;
  facility_type_label?: string;
  name: string;
  address1: string;
  address2?: string;
  city: {
    id: number;
    name: string;
    alias?: string | null;
    state: string;
    country: string;
    unlocode?: string;
    latitude?: number;
    longitude?: number;
    has_population?: number;
    created_at?: string;
    updated_at?: string;
    full_name?: string;
  };
  state?: string | null;
  zipcode: string;
  track_link?: string | null;
  business_day_start?: string | null;
  business_day_end?: string | null;
  business_time_start?: string | null;
  business_time_end?: string | null;
  contact_name?: string | null;
  contact_phone?: string | null;
  contact_ext?: string | null;
  contact_email?: string | null;
  pickup_location_memo?: string | null;
  created_at?: string;
  updated_at?: string;
  enabled?: boolean;
}

export const usePickupLocation = (
  onSelectCfsLocation?: (location: CFSLocation) => void,
) => {
  const app = useApp();
  const [cfsLocation, setCfsLocation] = useState<CFSLocation | null>(null);
  const fetchPickupLocation = async (id: number) => {
    try {
      const resp = await app.service.get(`cfs/cfsLocations/${id}`);
      return resp.data;
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  const updatePickupInfo = async (pickupCfsLocationId: number) => {
    if (!pickupCfsLocationId) {
      return;
    }
    const location = await fetchPickupLocation(pickupCfsLocationId);
    if (!location) {
      return;
    }
    setCfsLocation(location);
    if (onSelectCfsLocation) {
      onSelectCfsLocation(location);
    }
  };

  const updateCFSInfo = async (pickupCfsLocationId: number, data: any) => {
    try {
      await app.service.put(`cfs/cfsLocations/${pickupCfsLocationId}`, {
        data,
      });
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  return { cfsLocation, setCfsLocation, updatePickupInfo, updateCFSInfo };
};
