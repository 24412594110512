import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get } from 'lodash';
import React, { useMemo, useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { CompanyForm } from './CompanyForm';
import { CACHE_COMPANY_KEY } from '@/stores/cache';
import { serialize } from 'object-to-formdata';
import { showErrorMessage } from '@/utils/show-error-message';

export type TCompanyDrawerProps = {
  id?: number | null;
  open: boolean;
  disabled?: boolean;
  onClose: () => void;
  onSaved?: (id: number) => void;
};

export const CompanyDrawer: React.FC<TCompanyDrawerProps> = ({
  id = 0,
  open,
  disabled = false,
  onClose,
  onSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);

  const [form] = useForm();

  const fetchData = async () => {
    if (!id) {
      return;
    }

    try {
      const resp = await app.service.get(`companies/${id}`);
      await form.setFieldsValue(resp.data);
    } catch (error: any) {
      showErrorMessage(error);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    values.vendor_ids = values.vendors ? values.vendors.map((v) => v.id) : [];
    delete values.vendors;

    if (values.tier_rate === undefined) {
      values.tier_rate = null;
    }

    // hardcode for drayeasy, only super-admin can save this company
    if (values.code === 'DRAYEASY' && !app.store.auth.hasRole('super-admin')) {
      message.error('Only super-admin can save this company');
      return;
    }

    setLoading(true);

    // prevent formdata from sending boolean value
    for (const key in values) {
      if (typeof values[key] === 'boolean') {
        values[key] = values[key] ? 1 : 0;
      }
    }

    // for each contact, prevent boolean value from being sent as string
    if (values['contacts'] && Array.isArray(values['contacts'])) {
      values['contacts'] = values['contacts'].map((item: any) => {
        for (const key in item) {
          if (typeof item[key] === 'boolean') {
            item[key] = item[key] ? 1 : 0;
          }
        }
        return item;
      });
    }

    const config = {
      data: serialize(
        {
          ...values,
        },
        { indices: true, nullsAsUndefineds: true },
      ),
      requestType: 'form',
    };

    const request = !values.id
      ? app.service.post('companies', config)
      : app.service.put(`companies/${values.id}`, { data: values });

    request
      .then((resp: any) => {
        message.success('Saved');
        onSaved && onSaved(resp.data);
        app.store.cache.fetch(CACHE_COMPANY_KEY, true);

        handleClose();
      })
      .catch((err: any) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const initForm = () => {
    form.resetFields();
    form.setFieldsValue({
      code: '',
      name: '',
      address: '',
      billing_address: '',
    });
  };

  useEffect(() => {
    if (open) {
      if (!id) {
        initForm();
      } else {
        fetchData();
      }
    }
  }, [open, id]);

  return (
    <>
      <Drawer
        title={`${id ? 'Edit' : 'Create'} Company`}
        placement="right"
        width="80%"
        onClose={handleClose}
        destroyOnClose={true}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <CompanyForm form={form} onSubmit={handleSave} />
      </Drawer>
    </>
  );
};
