import React, { useMemo } from 'react';
import { Button, message, Table, Space, Tabs, Tooltip, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useApp } from '@/utils/useapp';
import { get, meanBy, truncate } from 'lodash';
import moment from 'moment';
import RebillDrawer from './RebillDrawer';
import { RebillInterface } from '@/Interfaces/RebillInterface';
import { RebillStatus, RebillReason } from '@/components/Tasks/Actions/data';
import { TAction } from '@/components/Tasks/Interfaces/ActionInterface';
import { RebillableType } from './data';
import { showErrorMessage } from '@/utils/show-error-message';

const RebillTable: React.FC<{
  rebillableType: RebillableType;
  rebillableId: number;
  disabled?: boolean;
  options?: {
    task_id: number;
    action: string;
  };
  onSaved?: () => void;
}> = ({ rebillableType, rebillableId, disabled, options, onSaved }) => {
  const [data, setData] = React.useState<RebillInterface[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [rebill, setRebill] = React.useState<RebillInterface | null>(null);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

  const app = useApp();

  const handleOpen = (rebill: RebillInterface | null) => {
    setRebill(rebill);
    setOpenDrawer(true);
  };

  const fetchData = async () => {
    if (!rebillableId || !rebillableType) {
      return;
    }
    setLoading(true);
    try {
      const resp = await app.service.get('rebills', {
        params: {
          rebillable_type: rebillableType,
          rebillable_id: rebillableId,
        },
      });

      setData(resp.data);
    } catch (error: any) {
      showErrorMessage(error, { fallback: 'Failed to fetch rebills' });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setRebill(null);
    setOpenDrawer(false);
  };

  const handleSaved = () => {
    handleClose();
    onSaved?.();
  };

  React.useEffect(() => {
    if (rebillableId && rebillableType) {
      fetchData();
    }
  }, [rebillableId, rebillableType]);

  const defaultColumns: ColumnsType<RebillInterface> = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Rebill#',
      key: 'order_number',
      dataIndex: 'order_number',
      width: 100,
    },
    {
      title: 'Reason',
      key: 'reason',
      dataIndex: 'reason',
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
    },
    {
      title: 'Memo',
      key: 'memo',
      dataIndex: 'memo',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: 'Creator',
      key: 'operator',
      render: (text, record) => {
        return record.creator?.name || 'System';
      },
    },
    {
      title: 'Created Time',
      key: 'created_at',
      dataIndex: 'created_at',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <Button type="link" onClick={() => handleOpen(record)}>
            {record.status == RebillStatus.Confirmed ? 'Edit' : 'View'}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Row justify="end">
        <Button type="primary" onClick={() => handleOpen(null)}>
          Add
        </Button>
      </Row>
      <Table
        rowKey="id"
        scroll={{
          y: 500,
        }}
        loading={loading}
        size="small"
        pagination={false}
        columns={defaultColumns}
        dataSource={data}
      />

      {openDrawer && (
        <RebillDrawer
          disabled={disabled || rebill?.status == RebillStatus.Canceled}
          rebillableType={rebillableType}
          rebillableId={rebillableId}
          options={options}
          onSaved={handleSaved}
          rebill={rebill}
          open={openDrawer}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default RebillTable;
