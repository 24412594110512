import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Button, Divider, Drawer, message, Popconfirm, Space } from 'antd';
import React, { useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import AutoResizeTable from '@/components/AutoResizeTable';
import { Filter } from './components/Filter';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    total: 0,
  });
  const [socReturnLocation, setSocReturnLocation] = useState<any>(null);
  const [form] = useForm();
  const [filter] = useForm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setSocReturnLocation(null);
    setVisible(false);
    form.resetFields();
  };

  const handleNewVendor = () => {
    showDrawer();
  };

  const fetchData = async (pagination?: TablePaginationConfig) => {
    setLoading(true);
    try {
      const result = await app.service.get('socReturnLocations', {
        params: {
          ...filter.getFieldsValue(),
          per_page: pagination?.pageSize || 25,
          page: pagination?.current || 1,
        },
      });
      setData(result.data);
      setPagination({
        current: result.meta.current_page,
        pageSize: result.meta.per_page,
        total: result.meta.total,
      });
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const isNew = useMemo(() => {
    return !socReturnLocation;
  }, [socReturnLocation]);

  const handleSave = async () => {
    let data;
    try {
      data = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);
    const formValue = Object.assign({}, data);

    const request = isNew
      ? app.service.post('socReturnLocations', { data: formValue })
      : app.service.put(`socReturnLocations/${socReturnLocation}`, {
          data: formValue,
        });

    request
      .then((resp) => {
        fetchData();
        onClose();
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemove = (id: number) => {
    setLoading(true);
    app.service
      .delete(`socReturnLocations/${id}`)
      .then((resp) => {
        fetchData();
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    const item = data.find((item) => item.id === id);
    if (!item) {
      return;
    }
    setVisible(true);
    setSocReturnLocation(id);
    form.setFieldsValue({ ...item });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = () => {
    fetchData();
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: 200,
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
      width: 200,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="Soc Return Locations"
        rightElement={
          <div>
            <Button type="primary" onClick={handleNewVendor}>
              New Soc Return Location
            </Button>
          </div>
        }
      ></Header>

      <Filter loading={loading} filter={filter} onSearch={handleSearch}>
        {' '}
      </Filter>
      <div>
        <AutoResizeTable
          pagination={pagination}
          loading={loading}
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={[...data]}
          scroll={{ x: 1500 }}
          onChange={fetchData}
          sticky
        />
      </div>
      <Drawer
        title=""
        placement={'right'}
        width={'40%'}
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <DrawerForm form={form} />
      </Drawer>
    </div>
  );
};

export default Index;
