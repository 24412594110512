import {
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Typography,
  Switch,
  Table,
  Button,
  InputNumber,
  Alert,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { CitySelect } from '@/components/CitySelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import type { FormInstance } from 'antd/es/form';
import moment from 'moment';
import { TerminalsSelect } from '@/components/TerminalsSelect';
import { AntDDatePicker } from 'antd-datepicker';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import ContainerSizeSelect from '@/components/ContainerSizeSelect';
import { useWatch } from 'antd/lib/form/Form';
import { ColumnsType } from 'antd/lib/table';
import { useApp } from '@/utils/useapp';
import { PossibleCharges } from '@/components/PossibleCharges';
import { get } from 'lodash';
import { TModifyProps } from '@/components/Rate/data';
import { showErrorMessage } from '@/utils/show-error-message';

export const SellRateForm: React.FC<
  {
    form: FormInstance;
    rateId?: number | null;
    showBuyRateDetail?: (buyRate: any) => void;
  } & TModifyProps
> = ({
  form,
  disabled = false,
  banModifyFields = [],
  rateId,
  showBuyRateDetail,
}) => {
  const app = useApp();

  const buyRates = useWatch('buy_rates', form) || [];
  const intermodalRegionId = useWatch('intermodal_region_id', form) || null;
  const vendor = useWatch('vendor', form) || null;

  const [iRPossibleCharge, setIRPossibleCharge] = useState([]);
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const toPercentage = () => {
    const base = form.getFieldValue('base_rate');
    if (!base) {
      return '';
    }
    const fsc = form.getFieldValue('fuel_surcharge');
    return fsc ? ((parseFloat(fsc) / parseFloat(base)) * 100).toFixed(2) : '';
  };

  const calculate = (isPercentage = false) => {
    const baseRate = form.getFieldValue('base_rate');
    const tollFee = form.getFieldValue('toll_fee');
    let fuelSurcharge = form.getFieldValue('fuel_surcharge');
    let fuelSurchargePercentage = form.getFieldValue(
      'fuel_surcharge_percentage',
    );
    if (isPercentage) {
      fuelSurcharge = (
        (parseFloat(form.getFieldValue('base_rate')) *
          parseFloat(form.getFieldValue('fuel_surcharge_percentage'))) /
        100
      ).toFixed(2);
    } else {
      fuelSurchargePercentage = toPercentage();
    }
    form.setFieldsValue({
      total_rate: (
        parseFloat(baseRate || 0) +
        parseFloat(fuelSurcharge || 0) +
        parseFloat(tollFee || 0)
      ).toFixed(2),
      ...(isPercentage
        ? {
            fuel_surcharge: fuelSurcharge,
          }
        : {
            fuel_surcharge_percentage: fuelSurchargePercentage,
          }),
    });
  };

  const handleValuesChange = (values: any) => {
    if (values) {
      if (
        Object.prototype.hasOwnProperty.call(values, 'base_rate') ||
        Object.prototype.hasOwnProperty.call(values, 'fuel_surcharge') ||
        Object.prototype.hasOwnProperty.call(values, 'toll_fee')
      ) {
        calculate(false);
      } else if (
        Object.prototype.hasOwnProperty.call(
          values,
          'fuel_surcharge_percentage',
        )
      ) {
        calculate(true);
      }
    }
  };

  const addBuyRate = async (id: any) => {
    if (!id) {
      return;
    }
    try {
      const resp = await app.service.get(`rates/${id}`);
      form.setFieldValue('buy_rates', [...buyRates, resp.data]);
    } catch (error) {
      message.error('Buy Rate Not Found.');
    }
  };

  const deleteBuyRate = React.useCallback(
    (index: number) => {
      const _buyRates = [...buyRates];
      _buyRates.splice(index, 1);
      form.setFieldValue('buy_rates', _buyRates);
    },
    [buyRates],
  );

  const buyRateColumns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        render: (text, record, index) => {
          if (showBuyRateDetail) {
            return (
              <Typography.Link onClick={() => showBuyRateDetail(record)}>
                {get(record, 'uid')}
              </Typography.Link>
            );
          }

          return <span> {get(record, 'id')}</span>;
        },
      },
      {
        title: 'vendor',
        dataIndex: ['vendor', 'name'],
      },
      {
        title: 'Base Rate',
        dataIndex: 'base_rate',
      },
      {
        title: 'FSC',
        dataIndex: 'fuel_surcharge',
      },
      {
        title: 'Truck Rate',
        dataIndex: 'truck_rate',
      },
      {
        title: '',
        dataIndex: 'action',
        render: (text, record, index) => {
          return <a onClick={() => deleteBuyRate(index)}>Delete</a>;
        },
      },
    ],
    [deleteBuyRate],
  );

  useEffect(() => {
    calculate(false);
  }, [rateId]);

  const fetchIRPossibleCharges = async (id: number) => {
    try {
      const resp = await app.service.get(
        `intermodalRegions/${id}/possibleCharges`,
      );
      setIRPossibleCharge(resp.data || []);
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  const handleSetDefaultPossibleCharge = () => {
    if (intermodalRegionId) {
      form.setFieldValue(
        'possible_charges',
        iRPossibleCharge.map(({ id, ...row }) => row),
      );
    } else {
      message.error('No Intermodal region selected');
    }
  };

  useEffect(() => {
    if (intermodalRegionId) {
      fetchIRPossibleCharges(intermodalRegionId);
    }
  }, [intermodalRegionId]);

  return (
    <div>
      <Form
        form={form}
        disabled={disabled}
        onValuesChange={handleValuesChange}
        layout="vertical"
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item name="customer_id" label="Customer">
                  <UserSyncSelect
                    onChange={(userId: any) => {
                      form.setFieldsValue({
                        customer_id: userId ? userId : null,
                      });
                    }}
                    placeholder="Default All Customer"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="vendor" label="Vendor">
                  {vendor ? vendor.name : 'DrayEasy'}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="intermodal_region_id"
                  label="Intermodal Region"
                  required
                >
                  <IntermodalRegionSelect
                    disabled={
                      disabled ||
                      (banModifyFields.includes('intermodal_region_id')
                        ? true
                        : false)
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="from_facility_type"
                  label="From Facility Type"
                  required
                >
                  <FacilityTypeSelect
                    getValueForOption="name"
                    disabled={
                      disabled ||
                      (banModifyFields.includes('from_facility_type')
                        ? true
                        : false)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, setFieldValue }) => (
                    <Form.Item
                      name="terminal_ids"
                      label="Terminals"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <TerminalsSelect
                        btnSize="middle"
                        disabled={
                          disabled ||
                          (banModifyFields.includes('terminal_ids')
                            ? true
                            : false)
                        }
                        defaultSelected={getFieldValue('terminal_ids')}
                        onChange={(selected) =>
                          setFieldValue('terminal_ids', selected)
                        }
                        intermodal_region_id={getFieldValue(
                          'intermodal_region_id',
                        )}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      name="to_city"
                      label="To City"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: true }]}
                    >
                      <CitySelect
                        disabled={
                          disabled ||
                          (banModifyFields.includes('to_city_id')
                            ? true
                            : false)
                        }
                        selected={getFieldValue('to_city')}
                        onSelect={(id) =>
                          handleChange({
                            target: { name: 'to_city', value: id },
                          })
                        }
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="To Zipcode" name="to_zipcode">
                  <Input
                    disabled={
                      disabled ||
                      (banModifyFields.includes('to_zipcode') ? true : false)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="to_facility_type"
                  label="To Facility Type"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true }]}
                >
                  <FacilityTypeSelect
                    getValueForOption="name"
                    disabled={
                      disabled ||
                      (banModifyFields.includes('to_facility_type')
                        ? true
                        : false)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={18}></Col>
              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      getFieldValue('to_facility_type') == 'FBA Location' && (
                        <Form.Item
                          label="FBA Facility Code"
                          name="fba_facility_code"
                          required
                        >
                          <Input
                            disabled={
                              disabled ||
                              (banModifyFields.includes('fba_facility_code')
                                ? true
                                : false)
                            }
                          />
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
              <Col span={5}>
                <Form.Item label="Base Rate" name="base_rate" required>
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={
                      disabled ||
                      (banModifyFields.includes('base_rate') ? true : false)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label="FSC">
                  <Input.Group compact>
                    <Form.Item name="fuel_surcharge" required noStyle>
                      <InputNumber
                        style={{ width: '50%' }}
                        disabled={
                          disabled ||
                          (banModifyFields.includes('fuel_surcharge')
                            ? true
                            : false)
                        }
                        addonAfter="$"
                      />
                    </Form.Item>
                    <Form.Item
                      name="fuel_surcharge_percentage"
                      required
                      noStyle
                    >
                      <Input
                        type="number"
                        style={{ width: '50%' }}
                        addonAfter="%"
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name="toll_fee" label="Toll Fee">
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={
                      disabled ||
                      (banModifyFields.includes('toll_fee') ? true : false)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name="total_rate" label="Total">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
              <Col span={6}>
                <Form.Item name="container_sizes" label="CNTR Size">
                  <ContainerSizeSelect
                    disabled={
                      disabled ||
                      (banModifyFields.includes('container_sizes')
                        ? true
                        : false)
                    }
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="All"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Attributes">
                  <Form.Item name="is_haz" valuePropName="checked" noStyle>
                    <Checkbox>HAZ</Checkbox>
                  </Form.Item>
                  <Form.Item name="is_ow" valuePropName="checked" noStyle>
                    <Checkbox>OW</Checkbox>
                  </Form.Item>
                  <Form.Item name="is_reefer" valuePropName="checked" noStyle>
                    <Checkbox>REEFER</Checkbox>
                  </Form.Item>
                  <Form.Item name="is_soc" valuePropName="checked" noStyle>
                    <Checkbox>SOC</Checkbox>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={6}>
                <Form.Item
                  label="Expired Date"
                  name="expired_at"
                  rules={[
                    { required: true, message: 'Expired Date is Required.' },
                  ]}
                >
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => (
                      <>
                        <AntDDatePicker
                          style={{ width: '100%' }}
                          onChange={(date) =>
                            handleChange({
                              target: {
                                name: 'expired_at',
                                value: date
                                  ? date.format('YYYY-MM-DD HH:mm:ss')
                                  : null,
                              },
                            })
                          }
                          value={
                            getFieldValue('expired_at')
                              ? moment(getFieldValue('expired_at'))
                              : null
                          }
                        />
                      </>
                    )}
                  </Form.Item>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item label="Created Date">
                      <DatePicker
                        style={{ width: '100%' }}
                        value={
                          getFieldValue('created_at')
                            ? moment(getFieldValue('created_at'))
                            : null
                        }
                        disabled
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  valuePropName="checked"
                  label="Visible"
                  name="visible"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item label="Contract Rate Name" name="contract_rate_name">
              <Input
                disabled={
                  disabled ||
                  (banModifyFields.includes('contract_rate_name')
                    ? true
                    : false)
                }
              />
            </Form.Item>
            <Form.Item label="Memo" name="memo">
              <Input.TextArea
                rows={12}
                disabled={
                  disabled || (banModifyFields.includes('memo') ? true : false)
                }
              />
            </Form.Item>
            <Form.Item label="Note For Customer" name="note">
              <Input.TextArea
                rows={10}
                disabled={
                  disabled || (banModifyFields.includes('note') ? true : false)
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Alert
          message="Unique Route: IR + City + From Facility Type + To Facility Type + Terminal + Vendor + Customer + Contract Rate Name"
          type="info"
          showIcon
        />

        <Divider />
        <Form.Item label="Related Buy Rates">
          <Form.Item name="buy_rates" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <div>
            <Input.Search
              style={{ width: '300px' }}
              placeholder="Enter Buy Rate ID"
              onSearch={addBuyRate}
              enterButton="Add"
              allowClear
            />
          </div>
          <div className="mt-sm">
            <Table
              scroll={{ x: 'auto', y: 350 }}
              rowKey="id"
              columns={buyRateColumns}
              dataSource={buyRates}
              size="small"
            />
          </div>
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item
              name="possible_charges"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <PossibleCharges
                data={getFieldValue('possible_charges') || []}
                updateData={(list) =>
                  handleChange({
                    target: { name: 'possible_charges', value: list },
                  })
                }
                extra={
                  <Button onClick={handleSetDefaultPossibleCharge}>
                    Default
                  </Button>
                }
                defaultPossibleCharge={iRPossibleCharge || []}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
