import { useApp } from '@/utils/useapp';
import { List, Button, Card, Row, Tooltip, message, Space } from 'antd';
import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SubTabInterface } from '..';
import { showErrorMessage } from '@/utils/show-error-message';

export type OpDataItem = {
  task: string;
  task_id: number;
  whose_task: number;
  intend_count: number;
  intend_container_ids: Array<number>;
  overdue_count: number;
  overdue_container_ids: Array<number>;
};

export const Task: React.FC<
  {
    isTaskTab: boolean;
  } & SubTabInterface
> = ({
  activeOpdashboardTab,
  isTaskTab = false,
  refresh = true,
  setRefresh,
  goToDispatch,
}) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<OpDataItem[]>([]);

  const fetchData = async () => {
    if (!activeOpdashboardTab?.id || loading) {
      return;
    }

    setData([]);

    setLoading(true);

    try {
      const resp = await app.service.get(
        `opdashboard/task/${activeOpdashboardTab.id}`,
        {
          params: {
            opdashboard_tab_id: activeOpdashboardTab.id,
          },
        },
      );

      setData(resp.data || []);
      refresh && setRefresh(false);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const goDispatchDetail = (containerIds: number[]) => {
    if (!containerIds || containerIds.length === 0) {
      return;
    }

    goToDispatch(containerIds);
  };

  useEffect(() => {
    if (activeOpdashboardTab?.id && isTaskTab) {
      fetchData();
    }
  }, [isTaskTab, activeOpdashboardTab]);

  useEffect(() => {
    if (activeOpdashboardTab?.id && isTaskTab && refresh) {
      fetchData();
    }
  }, [refresh]);

  return useObserver(() => {
    return (
      <Card
        title={
          <Space>
            Task
            <Tooltip
              title={
                <>
                  <div>
                    <div>Red: The number of containers that are overdue.</div>
                    <div>
                      Blue: The number of containers that are intended to be
                      dispatched.
                    </div>
                  </div>
                </>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        }
        loading={loading}
        bodyStyle={{ padding: '16px' }}
        style={{ height: '60vh', overflow: 'scroll' }}
      >
        {data?.length > 0 &&
          data.map((row) => (
            <>
              <div
                className="flex justify-between pb-xs pt-xs"
                style={{ borderBottom: '1px solid #f0f0f0' }}
              >
                <div>{row.task}</div>
                <div
                  style={{
                    border: '1px solid rgb(214, 228, 255)',
                    // backgroundColor: 'rgb(240, 245, 255)',
                    color: 'rgb(0, 122, 204)',
                    padding: '4px 8px',
                    borderRadius: '10px',
                    height: '26px',
                    // width: '80px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{ width: '20px' }}
                    onClick={() => goDispatchDetail(row.overdue_container_ids)}
                    className={`flex justify-end mr-sm ${
                      row.overdue_count > 0
                        ? 'text-danger text-pointer'
                        : 'text-primary'
                    }`}
                  >
                    {row.overdue_count}
                  </div>
                  /
                  <div
                    style={{ width: '20px' }}
                    onClick={() => goDispatchDetail(row.intend_container_ids)}
                    className={`flex text-primary ml-sm ${
                      row.intend_count > 0 ? 'text-pointer' : ''
                    }`}
                  >
                    {row.intend_count}
                  </div>
                </div>
              </div>
            </>
          ))}
      </Card>
    );
  });
};
