import { Button, message } from 'antd';
import { useApp } from '@/utils/useapp';
import { useEffect, useState } from 'react';
import React from 'react';
import RatingDrawer from '@/components/Rating/RatingDrawer';
import { TLtlShipmentAction } from '../../Interfaces/ActionInterface';
import { ModelTarget } from '@/components/Rating/Interfaces/ModelType';
import { ResponseTarget } from '@/components/Rating/Interfaces/RepsonseType';
import { RatingTag } from '@/components/RatingTag';
import { showErrorMessage } from '@/utils/show-error-message';

const RatingButton: React.FC<TLtlShipmentAction> = ({
  task,
  targetModel: ltlShipment,
  onDone,
  onSaveOperation,
}) => {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState<any>();

  const fetchResponse = async () => {
    if (!ltlShipment.carrier_id) {
      return;
    }

    try {
      const resp = await app.service.get(
        `ratings/${ModelTarget.LtlCarrier}/${ltlShipment.carrier_id}/fetchRating`,
        {
          params: {
            responsible_id: ltlShipment?.id,
            responsible_target: ResponseTarget.LtlShipment,
          },
        },
      );

      setRating(resp.data);
    } catch (e: any) {
      showErrorMessage(e);
    }
  };
  const handleCloseOpen = () => {
    setOpen(false);
  };

  const handleOnSaved = () => {
    onDone();
    setOpen(false);
  };

  useEffect(() => {
    if (ltlShipment.carrier_id) {
      fetchResponse();
    }
  }, [ltlShipment]);

  return (
    <>
      {rating ? (
        <span className="text-pointer" onClick={() => setOpen(true)}>
          <RatingTag rating={rating.score} />
        </span>
      ) : (
        <Button
          disabled={task?.disabled}
          size="small"
          onClick={() => setOpen(true)}
        >
          Rating
        </Button>
      )}

      {open && ltlShipment && ltlShipment.carrier_id && (
        <RatingDrawer
          modelId={ltlShipment.carrier_id}
          model={ltlShipment.carrier}
          modelTarget={ModelTarget.LtlCarrier}
          responsibleTarget={ResponseTarget.LtlShipment}
          responsible={ltlShipment}
          ratingId={rating?.id}
          onSaved={handleOnSaved}
          onClose={handleCloseOpen}
          open={open}
        />
      )}
    </>
  );
};

export default RatingButton;
