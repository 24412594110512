import { useApp } from '@/utils/useapp';

import React, { ReactElement, useState } from 'react';
import { Space, Tag, message, Typography, Tooltip, TagProps } from 'antd';
import { BaseType } from 'antd/lib/typography/Base';
import {
  FileSearchOutlined,
  WarningOutlined,
  GoogleOutlined,
} from '@ant-design/icons';
import { get, uniqueId } from 'lodash';
import { CONTAINER_TROUBLE_STATUES } from '@/pages/dispatchs/components/data';
import { ORDER_SOURCE_GOOGLESHEET_SYNC } from './data';
import { OrderDrawer } from './OrderDrawer';
import { useForm } from 'antd/lib/form/Form';
import { FaHandHoldingDollar } from 'react-icons/fa6';
import {
  STAY_IN_WAREHOUSE,
  TBD,
} from '@/components/ContainerTable/components/data';
import { showErrorMessage } from '@/utils/show-error-message';

export type TOrderRecord = {
  record: any;
  container?: any;
  showUrgent?: boolean;
  showFileSearch?: boolean;
  refreshOrder: (id: number) => void;
};
const tagStyle = {
  padding: '1px',
  fontSize: '10px',
  display: 'inline',
  margin: '0px',
};

export const TagBadge: React.FC<
  { key?: string; color?: string; text: string } & TagProps
> = ({ key, color = 'processing', text }) => (
  <Tag style={tagStyle} key={key || uniqueId()} color={color}>
    {text}
  </Tag>
);

//deprecated
const OrderBadge: React.FC<{ order: any; container?: any }> = ({
  order,
  container,
}) => {
  const list = [];
  let soc, dg, ow, reefer, md, bd, prepaid, oog, tl;
  // exam = false;

  const containers = container ? [container] : get(order, 'containers', []);

  containers.forEach((c: any) => {
    if (c.is_soc) {
      soc = true;
    }
    if (c.is_dg) {
      dg = true;
    }
    if (c.is_overweight) {
      ow = true;
    }
    if (c.is_reefer) {
      reefer = true;
    }

    if (c.is_multi_deliveries) {
      md = true;
    }

    if (c.is_bonded) {
      bd = true;
    }
    if (c.is_out_of_gauge) {
      oog = true;
    }
    if (c.transload) {
      tl = true;
    }

    //TODO--------
    // if (c.inside_terminal_exam_site_at || c.outside_terminal_exam_site_at) {
    //   exam = true;
    // }
  });

  if (order.is_prepaid) {
    prepaid = true;
  }

  let title = '';
  if (soc) {
    if (containers[0]?.soc_return_location_id == -1) {
      title = STAY_IN_WAREHOUSE;
    } else if (
      containers[0]?.soc_return_location_id === 0 ||
      containers[0]?.soc_return_location_id === null
    ) {
      title = TBD;
    } else {
      title = containers[0].soc_return_location?.address || '';
    }
  }

  soc &&
    list.push(
      <Tooltip title={title}>
        <span>
          <TagBadge key="soc" text="SOC" />
        </span>
      </Tooltip>,
    );
  dg && list.push(<TagBadge key="dg" text="DG" />);
  ow && list.push(<TagBadge key="bd" text="OW" />);
  bd && list.push(<TagBadge key="ow" text="BD" />);
  reefer && list.push(<TagBadge key="reefer" text="REFFER" />);
  md && list.push(<TagBadge key="md" text="MD" />);
  tl && list.push(<TagBadge key="md" text="TL" />);
  oog && list.push(<TagBadge key="oog" text="OOG" />);
  prepaid &&
    list.push(
      <Tooltip title="Prepaid">
        <span className="text-primary">
          <FaHandHoldingDollar />
        </span>
      </Tooltip>,
    );
  //TODO
  // exam &&
  //   list.push(
  //     <Tag key="exam" color="processing">
  //       EXAM
  //     </Tag>,
  //   );

  return (
    <Space direction="horizontal" size={4} wrap>
      {list}
    </Space>
  );
};

export const IDTooltip: React.FC<{
  children: ReactElement;
  title?: string;
  type?: BaseType;
}> = ({ children, title = 'Urgent', type = 'danger' }) => {
  return (
    <Tooltip title={title}>
      <Typography.Text type={type}>{children}</Typography.Text>
    </Tooltip>
  );
};
export const OrderIdColumn: React.FC<TOrderRecord> = ({
  record,
  container,
  showUrgent = false,
  showFileSearch = true,
  refreshOrder,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleTracking = async (id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.get(`orders/${id}/easyTracking`, {});
      const signedUrl = get(resp, 'url', '');
      if (signedUrl) {
        window.open(decodeURIComponent(signedUrl), '_target');
      }
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (id: number) => {
    setOpen(true);
  };

  const handleCloseOpen = () => {
    setOpen(false);
  };

  const handleUpdated = () => {
    setOpen(false);
    refreshOrder(record.id);
  };

  // const troubles = React.useMemo(() => {
  //   const list =
  //     container && container.trouble_status
  //       ? Object.keys(CONTAINER_TROUBLE_STATUES)
  //         .filter(
  //           (field: string) =>
  //             container.trouble_status && container.trouble_status[field],
  //         )
  //         .map((t: string) => CONTAINER_TROUBLE_STATUES[t])
  //       : [];

  //   if (container && container.is_diversion) {
  //     list.push('Diversion');
  //   }

  //   return list;
  // }, [container]);

  const IDLink = <a onClick={() => handleEdit(record.id)}>{record.uid}</a>;
  return (
    <>
      <Space style={{ marginRight: '4px' }}>
        {showUrgent ? <IDTooltip children={IDLink}></IDTooltip> : IDLink}

        {showFileSearch && record.trackeasy_shipment_id != 0 && (
          <span className="text-primary">
            <FileSearchOutlined onClick={() => handleTracking(record.id)} />
          </span>
        )}
        {/* {troubles && troubles.length > 0 && (
          <Tooltip title={troubles.join(',')}>
            <span className="text-danger">
              <WarningOutlined />
            </span>
          </Tooltip>
        )} */}
        {record.source == ORDER_SOURCE_GOOGLESHEET_SYNC && (
          <Tooltip title="Sync from Google Sheet">
            <GoogleOutlined className="text-primary" />
          </Tooltip>
        )}
      </Space>
      {/* <OrderBadge order={record} container={container} /> */}
      {open && (
        <OrderDrawer
          visible={open}
          setVisible={handleCloseOpen}
          orderId={record.id}
          refreshOrder={refreshOrder}
          handelAdded={handleUpdated}
        />
      )}
    </>
  );
};
