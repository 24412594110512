import { useApp } from '@/utils/useapp';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Skeleton,
  Statistic,
  Table,
  message,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { Pie } from '@ant-design/plots';
import Files from './Files';
import { VendorCell } from '@/components/VendorCell';
import { useVendorRateDrawer } from '@/pages/entity/vendors/components/useVendorRateDrawer';
import { VendorDrawer } from '@/pages/entity/vendors/components/VendorDrawer';
import { showErrorMessage } from '@/utils/show-error-message';

interface ISurmmary {
  type: string;
  value: number;
}

const PieChart: FC<{ data: ISurmmary[] }> = ({ data }) => {
  const config = {
    data: data,
    angleField: 'value',
    colorField: 'type',
    label: {
      text: 'value',
      style: {
        fontWeight: 'bold',
      },
    },
    legend: {
      color: {
        title: false,
        position: 'right',
        rowPadding: 5,
      },
    },
  };
  return <Pie {...config} />;
};

const SummaryTable: FC<{ data: ISurmmary[] }> = ({ data }) => {
  return (
    <div>
      <Row gutter={16}>
        {data.map((item, index) => {
          return (
            <Col key={index} span={4}>
              <Card>
                <Statistic title={item.type} value={item.value} />
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

const Amount = ({
  amount,
  expiredAt,
}: {
  amount: number;
  expiredAt: string;
}) => {
  return (
    <>
      <div>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(amount)}
      </div>
      <div>{expiredAt}</div>
    </>
  );
};

const InsuranceTable = () => {
  const [loading, setLoading] = useState(false);
  const [vendorInsurances, setVendorInsurances] = useState<any[]>([]);
  const [openVendorDrawer, setOpenVendorDrawer] = useState(false);
  const [vendorId, setVendorId] = useState<number>(0);

  const app = useApp();

  const fetchVendorInsuranceDetails = async () => {
    try {
      setLoading(true);
      const res = await app.service.get('risks/vendorInsurance');
      setVendorInsurances(res.data);
    } catch (e: any) {
      showErrorMessage(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchVendorInsuranceDetails();
  }, []);

  const {
    VendorRateFormDrawer,
    openVendorRateFormDrawer,
  } = useVendorRateDrawer();

  const showVendorDetail = (id: number) => {
    if (!id) {
      return;
    }

    setVendorId(id);
    setOpenVendorDrawer(true);
  };

  const handleCloseVendorDrawer = () => {
    setOpenVendorDrawer(false);
    setVendorId(0);
  };

  const columns = [
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      render: (text: string, record: any) => {
        return (
          <VendorCell vendor={record} showVendorDetail={showVendorDetail} />
        );
      },
    },
    {
      title: 'View Dispatch',
      dataIndex: 'view_dispatch',
      key: 'view_dispatch',
      render: (text: string, record: any) => {
        return (
          <a
            type="link"
            target="_blank"
            rel="noreferrer"
            href={`${window.location.origin}/dispatchs?link_vendor_id=${record?.id}`}
          >
            View{' '}
          </a>
        );
      },
    },
    {
      title: 'MC#',
      dataIndex: 'mc_number',
      key: 'mc_number',
      render: (text: string, record: any) => {
        return record.vendor_insurance
          ? record.vendor_insurance.mc_number
          : 'NA';
      },
    },
    {
      title: 'General Liability Amount',
      dataIndex: 'general_liability_amount',
      key: 'general_liability_amount',
      render: (text: string, record: any) => {
        return record.vendor_insurance ? (
          <Amount
            amount={record.vendor_insurance.general_liability_amount}
            expiredAt={
              record.vendor_insurance.general_liability_amount_expired_at
            }
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: 'Cargo Insurance Amount',
      dataIndex: 'cargo_insurance_amount',
      key: 'cargo_insurance_amount',
      render: (text: string, record: any) => {
        return record.vendor_insurance ? (
          <Amount
            amount={record.vendor_insurance.cargo_insurance_amount}
            expiredAt={
              record.vendor_insurance.cargo_insurance_amount_expired_at
            }
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: 'Automobile Insurance Amount',
      dataIndex: 'automobile_insurance_amount',
      key: 'automobile_insurance_amount',
      render: (text: string, record: any) => {
        return record.vendor_insurance ? (
          <Amount
            amount={record.vendor_insurance.automobile_insurance_amount}
            expiredAt={
              record.vendor_insurance.automobile_insurance_amount_expired_at
            }
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: 'Contract',
      dataIndex: 'contract',
      render: (text: string, record: any) => {
        return record.contract ? 'Yes' : 'No';
      },
    },
    // {
    //   title: 'Ralated Documents',
    //   dataIndex: 'related_documents',
    //   key: 'related_documents',
    //   render: (text: string, record: any) => {
    //     return <Files vendor={record} />;
    //   }
    // }
  ];

  return (
    <>
      <Table
        style={{ width: '100%' }}
        loading={loading}
        columns={columns}
        dataSource={vendorInsurances}
      />
      <VendorDrawer
        id={vendorId}
        // onSaved={fetchData}
        onClose={handleCloseVendorDrawer}
        visible={openVendorDrawer}
      />
    </>
  );
};

const Insurance = () => {
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<ISurmmary[]>([]);

  const app = useApp();
  const fetchSumary = async () => {
    try {
      setLoading(true);
      const res = await app.service.get('risks/insuranceSummary');
      setSummary(res.data);
    } catch (e: any) {
      console.log(e.error || 'network error');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSumary();
  }, []);

  return (
    <>
      <h2>Insurance Summary</h2>
      <Row align="middle">
        <Col span={18}>
          {loading && <Skeleton active />}
          {!loading && <SummaryTable data={summary} />}
        </Col>
        <Col span={6}>
          <PieChart data={summary} />
        </Col>
      </Row>
      <h2>Vendor Compliance Details</h2>
      <Row>
        <InsuranceTable />
      </Row>
    </>
  );
};

export default Insurance;
