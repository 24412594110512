import { Select, message, Divider, Button, Space, Radio } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import type { SelectProps } from 'antd';
import { uniq } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';

interface IUserSelect {
  selected: Array<number>;
  billTo?: any;
  onSelect: (userIds: Array<any> | null) => void;
}

const ALL = 0;
const ENABLED = 2;
const DISABLED = -1;

export const UserSelect: React.FC<IUserSelect & SelectProps> = ({
  selected,
  billTo,
  onSelect,
  ...props
}) => {
  const app = useApp();

  const [users, setUsers] = useState<any>([]);

  const [type, setType] = useState(ENABLED);

  const fetchUsers = async () => {
    try {
      const result = await app.service.get(
        'users/all?sort_by=_company&sort_value=asc',
      );

      setUsers(result.data);
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  const validateSelect = (option: any): boolean => {
    if (billTo?.id) {
      if (option?.find((o: any) => o.bill_to_id != billTo.id)) {
        message.error(`There are users who do not bill to ${billTo.name}`);
        return false;
      } else {
        return true;
      }
    }

    const billIds: Array<any> = [];

    option?.map((o: any) => {
      billIds.push(o.bill_to_id);
    });

    if (uniq(billIds).length > 1) {
      message.error('The selected user must be the same billing to');
      return false;
    }

    return true;
  };

  const handleChange = (val: any, option: any) => {
    if (false === validateSelect(option)) {
      return;
    }
    onSelect(option.map((o) => o.id));
  };

  const handleSelectAll = () => {
    onSelect(options.map((o) => o.value));
  };
  // const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

  useEffect(() => {
    fetchUsers();
  }, []);

  const options = useMemo(() => {
    let _users =
      billTo && billTo.id
        ? users.filter((u: any) => u.bill_to_id == billTo.id)
        : users;

    _users = _users.filter((u: any) => (type ? type == u.status : true));
    return _users.map((u: any) => ({
      value: u.id,
      label: `${u.company?.code || u._company || ''}(${u.name})`,
      ...u,
    }));
  }, [users, billTo, type]);

  const selectProps: any = useMemo(() => {
    const valueItem = selected ? { value: selected } : {};
    return {
      mode: 'multiple',
      ...valueItem,
      style: {
        width: '100%',
      },
      placeholder: 'Select Customers...',
      maxTagCount: 'responsive',
      ...props,
    };
  }, [selected, props]);

  return (
    <Select
      {...selectProps}
      onChange={handleChange}
      options={options}
      allowClear
      showSearch
      filterOption={(input, option) => {
        return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            {billTo && billTo.id && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button onClick={handleSelectAll}>All</Button>
                </Space>
              </>
            )}
            <Divider style={{ margin: '8px 0' }} />
            <div className="flex justify-center">
              <Radio.Group
                value={type}
                onChange={(e) => setType(e.target.value)}
                size="small"
              >
                <Radio.Button value={ALL}>All</Radio.Button>
                <Radio.Button value={ENABLED}>Enabled</Radio.Button>
                <Radio.Button value={DISABLED}>Disabled</Radio.Button>
              </Radio.Group>
            </div>
          </>
        );
      }}
    />
  );
};
