import React, { useMemo } from 'react';
import { Button, message, Rate, Space, Tabs, Tooltip } from 'antd';
import { ModelType } from '../Interfaces/ModelType';
import {
  FTLShipmentTargetClass,
  getTarget,
  LTLCarrierTargetClass,
  LTLShipmentTargetClass,
  CFSCarrierTargetClass,
  CFSShipmentTargetClass,
  ResponseType,
} from '../Interfaces/RepsonseType';
import { QuestionInterface } from '../Interfaces/QuestionInterface';
import { ColumnsType } from 'antd/lib/table';
import { useApp } from '@/utils/useapp';
import { get, meanBy, truncate } from 'lodash';
import moment from 'moment';
import { RatingTag } from '@/components/RatingTag';
import RatingDrawer from '../RatingDrawer';
import Table from 'antd/es/table';
import useRating from '../useRating';
import { RatingInterface } from '../Interfaces/RatingInterface';
import { showErrorMessage } from '@/utils/show-error-message';

const STATUS_MAP = ['Closed', 'Open'];

const AvgRate: React.FC<{
  response: RatingInterface[];
  responsibleType: string;
  id: number;
}> = ({ response = [], id, responsibleType }) => {
  const v = meanBy(
    response.filter(
      (resp) => resp.status == 0 && resp.responsible_type == responsibleType,
    ),
    `responses.${id}`,
  );

  return <Rate disabled value={v} allowHalf />;
};

const RatingTable: React.FC<
  ModelType &
    ResponseType & {
      open: boolean;
      score?: number;
      onSaved?: () => void;
    }
> = ({
  open = false,
  modelId,
  modelTarget,
  model,
  responsible,
  responsibleTarget,
  score = 'N/A',
  onSaved,
}) => {
  const { fetchQuestions } = useRating();

  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [questions, setQuestions] = React.useState<QuestionInterface[]>([]);
  const [ratingId, setRatingId] = React.useState<number>(0);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

  const [month, setMonth] = React.useState<number>(0);
  const app = useApp();

  const fetchRatings = async () => {
    if (!open || !modelId || !modelTarget) {
      return;
    }

    setLoading(true);

    try {
      const resp = await app.service.get(`ratings/${modelTarget}/${modelId}`, {
        params: {
          responsible_id: responsible?.id,
          responsible_target: responsibleTarget,
        },
      });
      // sort data if responsbile_type is LTLCarrier, sort first
      const _data = resp.data.sort((a, b) => {
        if (a.responsible_type == LTLCarrierTargetClass) {
          return -1;
        }
        return 1;
      });

      setData(_data);
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchQuestionData = async () => {
    const _questions = await fetchQuestions({ modelId, modelTarget });
    setQuestions(_questions);
  };

  const handleOpen = (id: number) => {
    setRatingId(id);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setRatingId(0);
    setOpenDrawer(false);
  };

  const handleSaved = () => {
    handleClose();
    fetchRatings();
    onSaved && onSaved();
  };

  React.useEffect(() => {
    if (open) {
      fetchRatings();
      fetchQuestionData();
    }
  }, [open]);

  const filteredData = useMemo(() => {
    const oneMonthAgo = moment().subtract(month, 'months');
    const filteredData = month
      ? data.filter((data) => {
          // Parse created_at string using Moment.js
          const createdAtDate = moment(data.created_at);
          // Check if created_at is within the past 1 month
          return createdAtDate.isSameOrAfter(oneMonthAgo, 'day');
        })
      : data;

    return filteredData;
  }, [data, month]);

  const defaultColumns: ColumnsType<RatingInterface> = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Responsible',
      key: 'responsible',
      dataIndex: 'responsible',
      width: 300,
      render: (text, record) => {
        const textMap = {
          [LTLCarrierTargetClass]: 'LTL Carrier',
          [LTLShipmentTargetClass]: 'LTL Shipment',
          [FTLShipmentTargetClass]: 'FTL Shipment',
          [CFSCarrierTargetClass]: 'CFS Carrier',
          [CFSShipmentTargetClass]: 'CFS Shipment',
        };

        const _text = textMap[record.responsible_type as keyof typeof textMap];
        if (get(record, 'responsible.uid')) {
          return `${_text} ( ${record.responsible.uid})`;
        }

        return _text;
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, record) => {
        return STATUS_MAP[record.status] || '';
      },
    },
    {
      title: 'Score',
      key: 'score',
      dataIndex: 'score',
    },
    {
      title: 'Comment',
      key: 'comment',
      dataIndex: 'comment',
      render: (text, record) => {
        return record.responses.comment &&
          record.responses.comment.length > 10 ? (
          <Tooltip title={record.responses.comment}>
            {truncate(record.responses.comment || '', {
              length: 10,
            })}
          </Tooltip>
        ) : (
          record.responses.comment || ''
        );
      },
    },
    // {
    //   title: 'CNTR#',
    //   key: 'container',
    //   render: (text, record) => {
    //     return record.container?.number;
    //   },
    // },
    {
      title: 'Operator',
      key: 'operator',
      render: (text, record) => {
        return record.operator?.name || 'System';
      },
    },
    {
      title: 'Created At',
      key: 'created_at',
      dataIndex: 'created_at',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        const target = getTarget(record.responsible_type);
        return (
          <Button type="link" onClick={() => handleOpen(record.id)}>
            {target == responsibleTarget ? 'Edit' : 'View'}
          </Button>
        );
      },
    },
  ];

  return (
    <div
      style={{
        width: '60vw',
      }}
    >
      <Space>
        <strong>
          Average Rating <RatingTag rating={+score || null} />
        </strong>
        {responsible && (
          <Button type="link" onClick={() => handleOpen(0)}>
            New Score
          </Button>
        )}
      </Space>
      <br />
      <Space direction="horizontal">
        <Tabs
          defaultActiveKey="0"
          onChange={(v) => setMonth(+v)}
          items={[
            {
              label: 'All',
              key: '0',
            },
            {
              label: 'Past 1 Month',
              key: '1',
            },
            {
              label: 'Past 3 Month',
              key: '3',
            },
            {
              label: 'Past 6 Month',
              key: '6',
            },
            {
              label: 'Past 12 Month',
              key: '12',
            },
          ]}
        />
      </Space>
      <br />
      <Space direction="vertical">
        {questions?.map((q: QuestionInterface, index: number) => {
          return (
            <Space key={q.id}>
              <span style={{ width: '100px' }}>
                {index + 1} {q.title}
              </span>
              <AvgRate
                response={filteredData}
                id={q.id}
                responsibleType={q.responsible_type}
              />
            </Space>
          );
        })}
      </Space>
      <br />
      <strong>Rating History</strong>
      <Table
        scroll={{
          y: 500,
        }}
        loading={loading}
        size="small"
        // pagination={}
        columns={defaultColumns}
        dataSource={data}
      />

      {openDrawer && (
        <RatingDrawer
          model={model}
          modelId={modelId}
          modelTarget={modelTarget}
          responsibleTarget={responsibleTarget}
          responsible={responsible}
          ratingId={ratingId}
          onSaved={handleSaved}
          onClose={handleClose}
          open={openDrawer}
        />
      )}
    </div>
  );
};

export default RatingTable;
