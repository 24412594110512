import React, { useEffect, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Typography, message } from 'antd';
import { ApplyToContainersModal } from '@/pages/orders/components/OrderDrawer/ApplyToContainersModal';
import { useApp } from '@/utils/useapp';
import useDispatch from '@/pages/dispatchs/components/Table/useDispatch';
import { showErrorMessage } from '@/utils/show-error-message';

export const PresetDataComponent: React.FC<{
  container: any;
  setSelectedContainers: (containers: any) => void;
}> = ({ container, setSelectedContainers }) => {
  const app = useApp();
  const { resolveOrder, ...dispatch } = useDispatch();

  const [showApplyToContainer, setShowApplyToContainer] = useState(false);
  const [containers, setContainers] = useState([]);

  const fetchContainers = async () => {
    try {
      const resp = await dispatch.fetchData(
        { pageSize: 500, current: 1 },
        {
          mbl_number: container.order.mbl_number,
          warehouse_id: container.warehouse_id,
          page: 1,
          per_page: 500,
        },
      );

      setContainers(resp.data);
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  const handleOnApplyToContainers = () => {
    setShowApplyToContainer(true);
  };

  const handleOnAppliedToContainers = (ids: number[]) => {
    setSelectedContainers(containers.filter((c) => ids.includes(c.id)));
    handleClose();
  };

  const handleClose = () => {
    setShowApplyToContainer(false);
  };

  useEffect(() => {
    if (container?.id) {
      fetchContainers();
    }
  }, [container]);

  return (
    <>
      <Typography.Link onClick={handleOnApplyToContainers}>
        Apply to Containers
      </Typography.Link>
      {showApplyToContainer && (
        <ApplyToContainersModal
          open={showApplyToContainer}
          containers={containers}
          onAppliedToContainers={handleOnAppliedToContainers}
          onClose={handleClose}
        />
      )}
    </>
  );
};
