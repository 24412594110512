import React, { ReactElement, useState } from 'react';

import {
  Form,
  Select,
  message,
  Drawer,
  Space,
  Button,
  Input,
  Tag,
  Alert,
} from 'antd';

import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  containers: any;
  onClose?: () => void;
  onSaved: () => void;
}

export const SellRateBulkUpdate: React.FC<Props> = ({
  containers = [],
  onClose,
  onSaved,
}) => {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);

    try {
      await app.service.put('dispatch/bulkUpdateSellRate', {
        data: {
          ids: containers.map((c: any) => c.id),
          ...values,
        },
      });
      message.success('Saved');
      form.resetFields();
      onSaved();
      handleClose();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    form.resetFields();
    setOpen(false);
    onClose && onClose();
  };

  const handleOpen = () => {
    if (containers.length === 0) {
      message.error('No containers selected');
      return;
    }

    setOpen(true);
  };

  return (
    <>
      <div className="cursor-pointer" onClick={handleOpen}>
        Bulk Update SR
      </div>
      {open && (
        <Drawer
          title={'SR Bulk Update'}
          placement={'right'}
          width={'50%'}
          onClose={handleClose}
          destroyOnClose={true}
          open={open}
          extra={
            <Space>
              <Button onClick={handleClose}>Close</Button>
              <Button type="primary" loading={loading} onClick={handleSave}>
                Save
              </Button>
            </Space>
          }
        >
          Selected:
          <div style={{ marginBottom: '10px' }}>
            {containers?.map((c: any) => (
              <Tag key={c.order.uid + '-' + c.number} color="processing">
                {c.order.uid} <br />
                {c.number}
              </Tag>
            ))}
          </div>
          <Form layout="vertical" form={form} onFinish={handleSave}>
            <Form.Item
              label="Sell Rate ID"
              name="sell_rate_id"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Drawer>
      )}
    </>
  );
};
