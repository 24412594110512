import React from 'react';
import { Modal, Button, message } from 'antd';
import { useApp } from '@/utils/useapp';
import { saveAs } from 'file-saver';
import type { UploadProps } from 'antd';
import { DraggerWithPaste } from '@/components/Upload/DraggerWithPaste';
import { EXCEL_TYPES } from '@/components/Upload/data';
import { showErrorMessage } from '@/utils/show-error-message';

interface TExcelUpload {
  uploadAction: string;
  enableEmptyUpload?: boolean;
  onUploaded: (data?: any) => void;
}
export const Excels: React.FC<TExcelUpload> = ({
  uploadAction,
  onUploaded,
}) => {
  const app = useApp();

  const [isLoading, setIsLoading] = React.useState(false);
  const uploadToServer = async (formData: FormData, file: any) => {
    setIsLoading(true);

    try {
      const resp = await app.service.post(uploadAction, {
        data: formData,
      });

      // message.success(file.name + ' uploaded successfully');
      onUploaded(resp.data);
    } catch (err: any) {
      if (err.data) {
        if (err.data.errors) {
          message.error(
            <>
              <div>Error</div>
              {Object.keys(err.data.errors).map((row) => (
                <div key={row}>
                  <div>Row {row}</div>
                  <ul>
                    {err.data.errors[row].map((error: any, i: number) => (
                      <li key={i}>{error}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </>,
            5,
          );
        } else {
          showErrorMessage(err);
        }
      } else {
        message.error('System Error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const draggerProps: UploadProps = {
    name: 'file',
    multiple: false,
    action: uploadAction,
    accept: EXCEL_TYPES,
    fileList: [],
    headers: {
      Authorization: `Bearer ${app.store.auth.token}`,
    },
    customRequest: async (options) => {
      const formData = new FormData();
      formData.append('file', options.file);

      await uploadToServer(formData, options.file);
    },
  };

  const handlePasteFiles = async (pasteFiles: any) => {
    const formData = new FormData();
    formData.append('file', pasteFiles[0]);

    await uploadToServer(formData, pasteFiles[0]);
  };

  return (
    <DraggerWithPaste
      uploadProp={draggerProps}
      disabled={isLoading}
      acceptFileTypes={EXCEL_TYPES}
      handlePasteFiles={handlePasteFiles}
    />
  );
};

const Upload: React.FC<{ onUploaded: (data: any) => void }> = ({
  onUploaded,
}) => {
  const app = useApp();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [enableEmptyUpload, setEnableEmptyUpload] = React.useState(false);

  const showModal = () => {
    setEnableEmptyUpload(true);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUploaded = (data: any) => {
    onUploaded(data);
    setIsModalVisible(false);
  };

  const downloadTemplate = async () => {
    try {
      const file = await app.service.get('quotes/template/download', {
        responseType: 'blob',
      });
      const filename = 'Quote-routes-import-template.xlsx';
      saveAs(file, filename);
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Import Quote Routes
      </Button>
      <Modal
        title="Import Quote Routes"
        open={isModalVisible}
        onOk={downloadTemplate}
        okText="Download Template"
        onCancel={handleCancel}
      >
        <Excels
          uploadAction="quotes/bulk"
          enableEmptyUpload={enableEmptyUpload}
          onUploaded={handleUploaded}
        />
      </Modal>
    </>
  );
};

export default Upload;
