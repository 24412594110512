import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  message,
  Space,
  Row,
  Form,
  Col,
  Input,
  DatePicker,
  Select,
  Table,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { Column } from '@ant-design/plots';
import { DEPARTMENTS } from '@/pages/setting/admin/components/DrawerForm';
import { TITLES } from '@/pages/setting/admin/components/data';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [filter] = useForm();

  const fetchData = React.useCallback(async () => {
    setLoading(true);

    try {
      const result = await app.service.get('analysis/assignments', {
        params: {
          ...filter.getFieldsValue(),
        },
      });

      setSelectedRowKeys(result.map((row) => row.name));
      setData(result);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  }, []);

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Max',
        key: 'max',
        dataIndex: 'max',
        sorter: (a: any, b: any) => b['max'] - a['max'],
      },
      {
        title: 'OP Lead',
        key: 'op lead',
        dataIndex: 'op lead',
        sorter: (a: any, b: any) => b['op lead'] - a['op lead'],
      },
      {
        title: 'OP',
        key: 'op',
        dataIndex: 'op',
        sorter: (a: any, b: any) => b['op'] - a['op'],
      },
      {
        title: 'OP Assistant',
        key: 'op assistant',
        dataIndex: 'op assistant',
        sorter: (a: any, b: any) => b['op assistant'] - a['op assistant'],
      },
      {
        title: 'Customer Expert',
        key: 'customer expert',
        dataIndex: 'customer expert',
        sorter: (a: any, b: any) => b['customer expert'] - a['customer expert'],
      },
      {
        title: 'Customer Specialist',
        key: 'customer specialist',
        dataIndex: 'customer specialist',
        sorter: (a: any, b: any) =>
          b['customer specialist'] - a['customer specialist'],
      },
    ];
  }, []);

  const chartData = React.useMemo(() => {
    const rows: any[] = [];
    data.forEach((row) => {
      if (selectedRowKeys.indexOf(row.name) === -1) {
        return;
      }
      rows.push({
        name: row.name,
        type: 'op lead',
        value: row['op lead'],
      });
      rows.push({
        name: row.name,
        type: 'op',
        value: row['op'],
      });
      rows.push({
        name: row.name,
        type: 'op assistant',
        value: row['op assistant'],
      });
      rows.push({
        name: row.name,
        type: 'customer expert',
        value: row['customer expert'],
      });
      rows.push({
        name: row.name,
        type: 'customer specialist',
        value: row['customer specialist'],
      });
    });
    return rows;
  }, [selectedRowKeys, data]);

  const chartConfig = {
    data: chartData,
    xField: 'name',
    yField: 'value',
    isGroup: true,
    seriesField: 'type',
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className={styles.main}>
      <Header title="Assignments"></Header>

      <div className={styles.filter}>
        <Form
          layout="vertical"
          className="w100"
          form={filter}
          onFinish={fetchData}
        >
          <Row gutter={16}>
            <Col span="auto">
              <Form.Item name="created_at_start_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="created_at_end_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Created At" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const created_at_start_date = getFieldValue(
                    'created_at_start_date',
                  );
                  const created_at_end_date = getFieldValue(
                    'created_at_end_date',
                  );
                  return (
                    <DatePicker.RangePicker
                      value={
                        created_at_start_date && created_at_end_date
                          ? [
                              moment(created_at_start_date),
                              moment(created_at_end_date),
                            ]
                          : undefined
                      }
                      onChange={(value) =>
                        setFieldsValue({
                          created_at_start_date: value
                            ? value[0]?.format('YYYY-MM-DD')
                            : null,
                          created_at_end_date: value
                            ? value[1]?.format('YYYY-MM-DD')
                            : null,
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Col>
            <Col span="auto">
              <Form.Item name="eta_start_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="eta_end_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="ETA" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const eta_start_date = getFieldValue('eta_start_date');
                  const eta_end_date = getFieldValue('eta_end_date');
                  return (
                    <DatePicker.RangePicker
                      ranges={{
                        Today: [moment(), moment()],
                        'Next day': [
                          moment().add(1, 'day'),
                          moment().add(1, 'day'),
                        ],
                        'Next 3 days': [
                          moment().add(1, 'day'),
                          moment().add(3, 'day'),
                        ],
                        'This Week': [
                          moment().startOf('isoWeek'),
                          moment().endOf('isoWeek'),
                        ],
                        'Next Week': [
                          moment().startOf('isoWeek').add(1, 'weeks'),
                          moment().endOf('isoWeek').add(1, 'weeks'),
                        ],
                        'Next 2 Weeks': [
                          moment().startOf('isoWeek').add(1, 'weeks'),
                          moment().endOf('isoWeek').add(2, 'weeks'),
                        ],
                        'Next 3 Weeks': [
                          moment().startOf('isoWeek').add(1, 'weeks'),
                          moment().endOf('isoWeek').add(3, 'weeks'),
                        ],
                        'Next 4 Weeks': [
                          moment().startOf('isoWeek').add(1, 'weeks'),
                          moment().endOf('isoWeek').add(4, 'weeks'),
                        ],
                      }}
                      value={
                        eta_start_date && eta_end_date
                          ? [moment(eta_start_date), moment(eta_end_date)]
                          : undefined
                      }
                      onChange={(value) =>
                        setFieldsValue({
                          eta_start_date: value
                            ? value[0]?.format('YYYY-MM-DD')
                            : null,
                          eta_end_date: value
                            ? value[1]?.format('YYYY-MM-DD')
                            : null,
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Title" name="title">
                <Select
                  allowClear
                  mode="multiple"
                  options={TITLES.map((d) => ({ value: d, label: d }))}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Department" name="department">
                <Select
                  allowClear
                  mode="multiple"
                  options={DEPARTMENTS.map((d) => ({ value: d, label: d }))}
                />
              </Form.Item>
            </Col>
            <Col span="auto">
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button onClick={() => filter.resetFields()}>
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Table
        rowSelection={rowSelection}
        loading={loading}
        pagination={false}
        size="small"
        rowKey="name"
        columns={columns}
        dataSource={data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <div className="mt-md">
        <Column {...chartConfig} />
      </div>
    </div>
  );
};

export default Index;
