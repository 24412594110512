import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';
import { showErrorMessage } from '@/utils/show-error-message';

export interface FTLModelSelectProps {
  identify: string;
}

export const FTLModelSelect = <T extends { id: number; name: string }>({
  identify,
  ...props
}: FTLModelSelectProps) => {
  const app = useApp();
  const [data, setData] = useState<T[]>([]);

  const fetchData = async () => {
    try {
      const resp = await app.service.get(`ftl/${identify}s`);
      setData(resp.data);
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Select
      {...props}
      placeholder={`Select ${identify}.`}
      options={data?.map((item) => ({
        value: item.id,
        label: item.name,
      }))}
      allowClear
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      style={{
        width: 200,
      }}
      {...props}
    >
      <Select.Option value={10}>Vendor 1</Select.Option>
      {data?.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};
