import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
  Typography,
  Button,
  Popconfirm,
} from 'antd';
const { Text } = Typography;
import React, { useMemo, useState } from 'react';
import { CitySelect } from '@/components/CitySelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { useApp } from '@/utils/useapp';
import { debounce, update } from 'lodash';
import { VendorDivisionSyncSelect } from '@/components/VendorDivisionSyncSelect';
import { FormInstance } from 'antd/es/form';
import { SAFETY_RATING_TYPE_MAP } from '../data';
import { WarningFilled } from '@ant-design/icons';
import { AttributesFormItems } from './AttributesFormItems';
import { showErrorMessage } from '@/utils/show-error-message';

interface IInfoTab {
  form: FormInstance;
  currentVendorDivisionName: string;
  currentShortName: string;
  currentIntermodalRegionName: string;
  onVerifiedUpdated: () => void;
  handleChange: (event: any) => void;
  setCurrentVendorDivisionName: (name: string) => void;
  setCurrentIntermodalRegionName: (name: string) => void;
  setCurrentShortName: (name: string) => void;
}

export const InfoTab: React.FC<IInfoTab> = ({
  form,
  currentVendorDivisionName,
  currentShortName,
  currentIntermodalRegionName,
  onVerifiedUpdated,
  handleChange,
  setCurrentVendorDivisionName,
  setCurrentIntermodalRegionName,
  setCurrentShortName,
}) => {
  const app = useApp();

  const [similarNames, setSimilarrNames] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);

  const debounceFetcher = useMemo(() => {
    const handleCheckNameSimilarity = async (name: string) => {
      setSimilarrNames([]);
      if (name.length < 3) {
        return;
      }
      // setIsLoading(true);
      try {
        const resp = await app.service.get('vendors/checkNameSimilarity', {
          params: { name },
        });

        setSimilarrNames(resp.data);
      } catch (err: any) {
        showErrorMessage(err);
      }
    };
    return debounce(handleCheckNameSimilarity, 500);
  }, []);

  const setFullName = (
    shortName?: string,
    vendorDivisionName?: string,
    intermodalRegionName?: string,
  ) => {
    let name = '';
    shortName && (name += shortName);
    vendorDivisionName && (name += ` (${vendorDivisionName ?? ''})`);
    intermodalRegionName && (name += ` - ${intermodalRegionName}`);

    form.setFieldValue('name', name);
  };

  const handleSelectVendorDivision = (vendorDivision: any) => {
    const division = vendorDivision?.name || '';
    form.setFieldValue('vendor_division', vendorDivision);
    setCurrentVendorDivisionName(division);
    setFullName(currentShortName, division, currentIntermodalRegionName);
  };

  const handleSyncByFMCSA = async () => {
    const truckerMC = form.getFieldValue('trucker_mc');
    if (!truckerMC || truckerMC.length <= 0) {
      message.error('Trucker MC is Required');
      return false;
    }
    setIsSyncing(true);
    try {
      const resp = await app.service.get('vendors/syncByFMCSA', {
        params: { trucker_mc: truckerMC },
      });

      if (resp.rating) {
        form.setFieldsValue({
          safety_rating: resp.rating,
        });
        message.success('Sync Success');
      }
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setIsSyncing(false);
    }
  };

  const handleFetchVendorInfo = async () => {
    const usDot = form.getFieldValue('us_dot');
    if (!usDot || usDot.length <= 0) {
      message.error('Us Dot is Required');
      return false;
    }
    try {
      const resp = await app.service.get(
        'vendors/fetchVendorInfoByTruckerUsDot',
        {
          params: { us_dot: usDot },
        },
      );

      if (resp) {
        form.setFieldsValue({ ...resp.data });
        const keys = Object.keys(resp.data);
        if (keys.length == 0) {
          message.warning('No data Updated');
        } else {
          message.success(keys.join(', ') + ' has been synced successfully.');
        }
      }
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  return (
    <>
      <Row gutter={10}>
        <Col span={12}>
          <h3>Basic Info:</h3>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Form.Item
                label="Name"
                name="short_name"
                rules={[{ required: true }]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                  placeholder="Enter Name"
                  onChange={(e) => {
                    setFullName(
                      e.target.value,
                      currentVendorDivisionName,
                      currentIntermodalRegionName,
                    );
                    setCurrentShortName(e.target.value);
                  }}
                  onBlur={(e) => debounceFetcher(e.target.value)}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label="Full Name"
            name="name"
            rules={[{ required: true }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              disabled
              placeholder="Enter Name"
              onBlur={(e) => debounceFetcher(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Division"
            name="vendor_division"
            rules={[{ required: false }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => (
                <VendorDivisionSyncSelect
                  selected={getFieldValue('vendor_division')}
                  onSelect={handleSelectVendorDivision}
                />
              )}
            </Form.Item>
          </Form.Item>

          <div>
            {similarNames.length > 0 && (
              <>
                <Space>
                  <Popover
                    placement="bottom"
                    content={
                      <Space direction="vertical">
                        {similarNames.map((name) => (
                          <Text type="secondary" key={name}>
                            {name}
                          </Text>
                        ))}
                      </Space>
                    }
                  >
                    <WarningFilled style={{ color: '#f4bb42' }} />{' '}
                  </Popover>
                  <Text type="danger">
                    Similar names are detected. Make sure this is a new vendor
                    before saving.{' '}
                  </Text>
                </Space>
              </>
            )}
          </div>
          <Row gutter={8}>
            <Col>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    label="Intermodal Region"
                    name="intermodal_region_id"
                    rules={[{ required: true }]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <IntermodalRegionSelect
                      onChange={(value: any, option: any) => {
                        setCurrentIntermodalRegionName(option?.label || '');
                        setFullName(
                          getFieldValue('short_name'),
                          currentVendorDivisionName,
                          option?.label || '',
                        );
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="city"
                    label="City"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true }]}
                  >
                    <CitySelect
                      selected={getFieldValue('city')}
                      onSelect={(city) =>
                        handleChange({ target: { name: 'city', value: city } })
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Address"
            name="address"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Internal Memo"
            name="internal_memo"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input.TextArea rows={6} placeholder="Enter Internal Memo" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <h3>Public Info:</h3>
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item
                label="SCAC Code"
                name="scac_code"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Enter SCAC Code" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={
                  <Space>
                    <>Us Dot</>
                    <Popconfirm
                      title={'Are you sure'}
                      onConfirm={handleFetchVendorInfo}
                    >
                      <Button size="small" type="primary" ghost>
                        Sync By Us Dot
                      </Button>
                    </Popconfirm>
                  </Space>
                }
                name="us_dot"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Enter us dot" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Trucker MC"
                name="trucker_mc"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  // onBlur={(e) => checkExistCreditOrInsurance(e.target.value)}
                  placeholder="Enter Trucker MC"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Overview"
            name="overview"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item
            label="Website url"
            name="website_url"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item
                label="Contact Person"
                name="contact"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Contact Email"
                name="email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Contact Phone"
                name="phone"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Contact Wechat"
                name="wechat"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={
              <Space>
                Safety Rating
                <Button
                  type="primary"
                  size="small"
                  ghost
                  disabled={isSyncing}
                  onClick={handleSyncByFMCSA}
                >
                  Sync by FMCSA
                </Button>
              </Space>
            }
            name="safety_rating"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            {/* <Input type={'number'} placeholder="Enter Safety Rating" /> */}
            <Select
              style={{ width: '100%' }}
              placeholder="Select Safety Rating Type"
            >
              {Object.keys(SAFETY_RATING_TYPE_MAP).map((key: string) => {
                return (
                  <Select.Option key={key} value={key}>
                    {
                      SAFETY_RATING_TYPE_MAP[
                        (key as unknown) as keyof typeof SAFETY_RATING_TYPE_MAP
                      ]
                    }
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <AttributesFormItems
            form={form}
            onVerifiedUpdated={onVerifiedUpdated}
          />
        </Col>
      </Row>
    </>
  );
};
