import React, { useEffect, useMemo } from 'react';

import {
  DatePicker,
  Input,
  Space,
  message,
  Button,
  Table,
  Form,
  Modal,
  Row,
  Col,
  Typography,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { get, isObject, every, has } from 'lodash';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { WarehouseFormItem } from '@/pages/orders/components/DeliveryOrderDrawer/WarehouseFormItem';
import { WarehouseSelect } from '@/components/WarehouseSelect';
import { SERIAL_NUMBER_MAP } from './data';
import { useWatch } from 'antd/lib/form/Form';
import { FormInstance } from 'antd/es/form';
import { ColumnsType } from 'antd/es/table';
import { RerserveTime } from '../RerserveTime';
import { DocumentsStoreLocal } from '@/components/Upload/DocumentsStoreLocal';
import { Documents } from '@/components/Upload/Documents';

import { serialize } from 'object-to-formdata';

import moment from 'moment';
import { showErrorMessage } from '@/utils/show-error-message';

const TaskActionDatesTable: React.FC<{
  container: any;
  values: {
    pre_del_sent_at: string;
    pre_del_confirm_at: string;
    schedule_delivery_at: string;
    schedule_delivery_at_reserve_time: any;
    warehouse_checkin_at: string;
    warehouse_checkout_at: string;
  };
  form: FormInstance;
  onChange: (event: { target: { name: string; value: any } }) => void;
}> = ({ container, values, onChange }) => {
  const record = useMemo(
    () => ({
      schedule_delivery_at_reserve_time:
        values.schedule_delivery_at_reserve_time,
    }),
    [values.schedule_delivery_at_reserve_time],
  );

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'PreDel Sent',
        key: 'pre_del_sent_at',
        dataIndex: 'pre_del_sent_at',
        width: 100,
        render: (text, record, index) => (
          <DatePicker
            style={{ width: '100%' }}
            value={
              values.pre_del_sent_at ? moment(values.pre_del_sent_at) : null
            }
            onChange={(v) =>
              onChange({
                target: {
                  name: 'pre_del_sent_at',
                  value: v?.format('YYYY-MM-DD'),
                },
              })
            }
          />
        ),
      },
      {
        title: 'PreDel Confirm',
        key: 'pre_del_confirm_at',
        dataIndex: 'pre_del_confirm_at',
        width: 100,
        render: (text, record, index) => (
          <DatePicker
            style={{ width: '100%' }}
            value={
              values.pre_del_confirm_at
                ? moment(values.pre_del_confirm_at)
                : null
            }
            onChange={(v) =>
              onChange({
                target: {
                  name: 'pre_del_confirm_at',
                  value: v?.format('YYYY-MM-DD'),
                },
              })
            }
          />
        ),
      },
      {
        title: 'SKD Delivery Time',
        key: 'schedule_delivery_at',
        dataIndex: 'schedule_delivery_at',
        width: 100,
        render: (text, _record, index) => (
          <Space>
            <DatePicker
              value={
                values.schedule_delivery_at
                  ? moment(values.schedule_delivery_at)
                  : null
              }
              onChange={(date, dateString) =>
                onChange({
                  target: { name: 'schedule_delivery_at', value: dateString },
                })
              }
            />
            <RerserveTime
              name="schedule_delivery_at_reserve_time"
              is_append_to_default={false}
              record={record}
              isContainer={false}
              saveText="Ok"
              customUpdate={(value) =>
                onChange({
                  target: { name: 'schedule_delivery_at_reserve_time', value },
                })
              }
            />
          </Space>
        ),
      },
      {
        title: 'WHS Checkin Time',
        key: 'warehouse_checkin_at',
        dataIndex: 'warehouse_checkin_at',
        width: 100,
        render: (text, record, index) => (
          <>
            <DatePicker
              showTime
              value={
                values.warehouse_checkin_at
                  ? moment(values.warehouse_checkin_at)
                  : null
              }
              format="YYYY-MM-DD HH:mm"
              onChange={(value) =>
                onChange({
                  target: {
                    name: 'warehouse_checkin_at',
                    value: value?.format('YYYY-MM-DD HH:mm:ss'),
                  },
                })
              }
            />
          </>
        ),
      },
      {
        title: 'WHS Checkout Time',
        key: 'warehouse_checkout_at',
        dataIndex: 'warehouse_checkout_at',
        width: 100,
        render: (text) => (
          <>
            <DatePicker
              showTime
              value={
                values.warehouse_checkout_at
                  ? moment(values.warehouse_checkout_at)
                  : null
              }
              format="YYYY-MM-DD HH:mm"
              onChange={(value) =>
                onChange({
                  target: {
                    name: 'warehouse_checkout_at',
                    value: value?.format('YYYY-MM-DD HH:mm:ss'),
                  },
                })
              }
            />
          </>
        ),
      },
    ],
    [container, values],
  );

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={[container]}
      pagination={false}
    />
  );
};

export const MultipleDeliveries: React.FC<{
  container: any;
  onSaved: () => void;
}> = ({ container, onSaved }) => {
  if (!get(container, 'is_multi_deliveries')) {
    return <></>;
  }

  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [form] = Form.useForm();

  const fetchData = async () => {
    if (!container?.id) {
      return;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(
        `containers/${container.id}/getMultipleDeliveries`,
      );
      form.setFieldsValue({
        multiple_deliveries: { ...resp.data },
      });
      form.setFieldValue('multiple_deliveries', resp.data);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const validateValues = (values: any) => {
    if (!values.multiple_deliveries) {
      message.error('Warehouse is reuqired');
      return false;
    }

    for (const i in values.multiple_deliveries) {
      if (
        !values.multiple_deliveries[i]?.warehouse ||
        !values.multiple_deliveries[i]?.warehouse?.id
      ) {
        message.error('Warehouse is reuqired');
        return false;
      }
    }

    return true;
  };

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    if (!validateValues(values)) {
      return false;
    }

    const _values = Object.assign({}, values);

    for (const i in _values.multiple_deliveries) {
      if (_values.multiple_deliveries[i].warehouse) {
        _values.multiple_deliveries[i].warehouse_id =
          _values.multiple_deliveries[i].warehouse.id;
        delete _values.multiple_deliveries[i].warehouse;
      }
    }

    const config = {
      data: serialize(
        {
          ..._values,
        },
        { indices: true, nullsAsUndefineds: true },
      ),
      requestType: 'form',
    };

    setLoading(true);
    try {
      await app.service.post(
        `containers/${container.id}/saveMultipleDeliveries`,
        config,
      );
      message.success('Saved');
      fetchData();
      onSaved();
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!open || !container) {
      return;
    }

    fetchData();
  }, [container, open]);

  return (
    <>
      <span className="cursor-pointer text-gray" onClick={() => setOpen(true)}>
        <PlusOutlined />
      </span>
      {open && (
        <Modal
          title="Multiple Deliveries"
          open={open}
          width={'60%'}
          bodyStyle={{
            maxHeight: '75vh',
            minHeight: '30vh',
            overflowY: 'scroll',
            padding: '2rem',
          }}
          onCancel={handleClose}
          footer={
            <Space>
              <Button type="primary" onClick={handleSave}>
                Save
              </Button>
              <Button onClick={handleClose}>Close</Button>
            </Space>
          }
        >
          <Form layout="vertical" form={form}>
            <Form.List name="multiple_deliveries">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => {
                    const serialNumber = Number(index) + 1;
                    return (
                      <>
                        <Form.Item
                          label={
                            <Space>
                              {
                                SERIAL_NUMBER_MAP[
                                  (serialNumber as unknown) as keyof typeof SERIAL_NUMBER_MAP
                                ]
                              }
                              {index != 0 && (
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              )}
                            </Space>
                          }
                          shouldUpdate
                          key={field.key}
                          {...fields}
                        >
                          {({ getFieldValue, setFieldsValue }) => (
                            <>
                              <Form.Item
                                noStyle
                                rules={[
                                  {
                                    required: false,
                                    message: 'Warehouse is required',
                                  },
                                ]}
                              >
                                <WarehouseSelect
                                  forbidToModfiySpecifyField={true}
                                  selected={getFieldValue([
                                    'multiple_deliveries',
                                    index,
                                    'warehouse',
                                  ])}
                                  onSelect={(warehouse: any) => {
                                    form.setFieldValue(
                                      [
                                        'multiple_deliveries',
                                        index,
                                        'warehouse',
                                      ],
                                      warehouse,
                                    );
                                  }}
                                  placeholder="Select Warehouse"
                                  renderOption={(warehouse: any) => {
                                    return (
                                      <Space>
                                        <strong>[{warehouse.code}]</strong>
                                        <span>{warehouse.name}</span>
                                        <small>【{warehouse.address}】</small>
                                      </Space>
                                    );
                                  }}
                                  editable={true}
                                />
                              </Form.Item>
                              {getFieldValue([
                                'multiple_deliveries',
                                index,
                                'warehouse',
                              ]) && (
                                <Form.Item label="Address">
                                  <Input.TextArea
                                    disabled
                                    rows={4}
                                    value={
                                      getFieldValue([
                                        'multiple_deliveries',
                                        index,
                                        'warehouse',
                                      ]).delivery_order_address
                                    }
                                  ></Input.TextArea>
                                </Form.Item>
                              )}

                              <TaskActionDatesTable
                                form={form}
                                container={container}
                                values={{
                                  pre_del_sent_at: getFieldValue([
                                    'multiple_deliveries',
                                    index,
                                    'pre_del_sent_at',
                                  ]),
                                  pre_del_confirm_at: getFieldValue([
                                    'multiple_deliveries',
                                    index,
                                    'pre_del_confirm_at',
                                  ]),
                                  schedule_delivery_at: getFieldValue([
                                    'multiple_deliveries',
                                    index,
                                    'schedule_delivery_at',
                                  ]),
                                  schedule_delivery_at_reserve_time: getFieldValue(
                                    [
                                      'multiple_deliveries',
                                      index,
                                      'schedule_delivery_at_reserve_time',
                                    ],
                                  ),
                                  warehouse_checkin_at: getFieldValue([
                                    'multiple_deliveries',
                                    index,
                                    'warehouse_checkin_at',
                                  ]),
                                  warehouse_checkout_at: getFieldValue([
                                    'multiple_deliveries',
                                    index,
                                    'warehouse_checkout_at',
                                  ]),
                                }}
                                onChange={(event) => {
                                  form.setFieldValue(
                                    [
                                      'multiple_deliveries',
                                      index,
                                      event.target.name,
                                    ],
                                    event.target.value,
                                  );
                                }}
                              />

                              <Form.Item label="Upload POD">
                                {getFieldValue([
                                  'multiple_deliveries',
                                  index,
                                  'id',
                                ]) ? (
                                  <Documents
                                    title=""
                                    target="multiple_deliveries"
                                    targetId={getFieldValue([
                                      'multiple_deliveries',
                                      index,
                                      'id',
                                    ])}
                                  />
                                ) : (
                                  <DocumentsStoreLocal
                                    onFilesChange={(newFiles: any) =>
                                      form.setFieldValue(
                                        ['multiple_deliveries', index, 'files'],
                                        newFiles,
                                      )
                                    }
                                  />
                                )}
                              </Form.Item>
                            </>
                          )}
                        </Form.Item>
                      </>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Delivery
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Modal>
      )}
    </>
  );
};
