import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  Drawer,
  message,
  Popconfirm,
  Space,
  Table,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import AutoResizeTable from '@/components/AutoResizeTable';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [cargoType, setCargoTypes] = useState<any>(null);
  const [form] = useForm();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleNewVendor = () => {
    showDrawer();
  };

  const fetchData = async ({ page } = { page: 1 }) => {
    setLoading(true);
    try {
      const result = await app.service.get('cargoTypes');
      setData(result.data);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const isNew = useMemo(() => {
    return !cargoType;
  }, [cargoType]);

  const handleSave = async () => {
    let data;
    try {
      data = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);
    const formValue = Object.assign({}, data);

    const request = isNew
      ? app.service.post('cargoTypes', { data: formValue })
      : app.service.put(`cargoTypes/${cargoType}`, { data: formValue });

    request
      .then((resp) => {
        fetchData();
        setCargoTypes(null);
        onClose();
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemove = (id: number) => {
    setLoading(true);
    app.service
      .delete(`cargoTypes/${id}`)
      .then((resp) => {
        fetchData();
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    const item = data.find((item) => item.id === id);
    if (!item) {
      return;
    }
    setVisible(true);
    setCargoTypes(id);
    form.setFieldsValue({ ...item });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 80,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 20,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="Cargo Types"
        rightElement={
          <div>
            <Button type="primary" onClick={handleNewVendor}>
              New Cargo Type
            </Button>
          </div>
        }
      ></Header>

      {/* <Filter loading={loading} filter={filter} onSearch={handleSearch} onChange={handleFilterChange}> </Filter> */}
      <div>
        <AutoResizeTable
          pagination={{
            defaultPageSize: 20,
          }}
          loading={loading}
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={[...data]}
          sticky
        />
      </div>
      <Drawer
        title=""
        placement={'right'}
        width={'40%'}
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <DrawerForm form={form} isNew={isNew} updateData={cargoType} />
      </Drawer>
    </div>
  );
};

export default Index;
