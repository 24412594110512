import React, { useCallback } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm, useWatch } from 'antd/lib/form/Form';
import moment from 'moment';
import { get } from 'lodash';
import AutoResizeTable from '@/components/AutoResizeTable';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';
import { NameLink, TYPE_VENDOR } from './NameLink';
import { UserLink } from './UserLink';
import { CompanyLink } from './CompanyLink';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { CSVLink } from 'react-csv';
import { showErrorMessage } from '@/utils/show-error-message';

// Define interface for the data items
interface AgingReportItem {
  name: string;
  company: string;
  has_contract: boolean;
  agent_name: string;
  business_development_name: string;
  sales_name?: string;
  sales_support_name?: string;
  ltl_sales_name?: string;
  ltl_sales_support_name?: string;
  ftl_sales_name?: string;
  ftl_sales_support_name?: string;
  terms: string;
  total: string;
  current: string;
  due_1_30: string;
  due_31_60: string;
  due_61: string;
  user_id?: number | string;
  vendor_id?: number | string;
  company_id?: number | string;
  user_accounting_memo?: string;
  company_accounting_memo?: string;
}

// Define interface for user and company objects used in refreshing data
interface UserData {
  name: string;
  accounting_memo: string;
}

interface CompanyData {
  name: string;
  accounting_memo: string;
}

// Define interface for summary data
interface AgingReportSummary {
  total: string;
  current: string;
  due_1_30: string;
  due_31_60: string;
  due_61: string;
}

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<AgingReportItem[]>([]);
  const [summary, setSummary] = React.useState<AgingReportSummary | null>(null);
  const [filter] = useForm();
  const [exportingData, setExportingData] = React.useState<any[]>([]);

  const type = useWatch('type', filter);

  const handleSave = async () => {
    const values = await filter.validateFields();
    setLoading(true);

    try {
      const result = await app.service.post('accountings/agingReport', {
        data: { ...values },
      });
      const rows = get(result, 'rows', []) as AgingReportItem[];
      const summary = get(result, 'summary', {}) as AgingReportSummary;
      setData(rows);
      setSummary(summary);
      setExportingData(toExportData(rows));
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const refreshName = useCallback(
    (index: number, name: string) => {
      if (!data) {
        return;
      }

      const collection = data.map((item: AgingReportItem, i: number) => {
        if (i === index) {
          return {
            ...item,
            name: name,
          };
        }
        return item;
      });

      setData(collection);
    },
    [data, setData],
  );

  const refreshUser = useCallback(
    (index: number, user: UserData) => {
      if (!data) {
        return;
      }

      const collection = data.map((item: AgingReportItem, i: number) => {
        if (i === index) {
          return {
            ...item,
            name: user.name,
            user_accounting_memo: user.accounting_memo,
          };
        }
        return item;
      });

      setData(collection);
    },
    [data, setData],
  );

  const refreshCompany = useCallback(
    (index: number, company: CompanyData) => {
      if (!data) {
        return;
      }

      const collection = data.map((item: AgingReportItem, i: number) => {
        if (i === index) {
          return {
            ...item,
            company: company.name,
            company_accounting_memo: company.accounting_memo,
          };
        }
        return item;
      });

      setData(collection);
    },
    [data, setData],
  );

  const toExportData = useCallback(
    (data: AgingReportItem[]) => {
      // to csv data
      const rows = data.map((item: AgingReportItem) => {
        const row = [
          item.name,
          item.company,
          item.has_contract ? 'Yes' : 'No',
          item.agent_name,
          item.business_development_name,
        ];

        // Add the appropriate sales-related data based on type
        if (type === 'all') {
          row.push(
            item.sales_name || '',
            item.sales_support_name || '',
            item.ltl_sales_name || '',
            item.ltl_sales_support_name || '',
            item.ftl_sales_name || '',
            item.ftl_sales_support_name || '',
          );
        } else if (type === 'drayeasy') {
          row.push(item.sales_name || '', item.sales_support_name || '');
        } else if (type === 'ltl') {
          row.push(
            item.ltl_sales_name || '',
            item.ltl_sales_support_name || '',
          );
        } else if (type === 'ftl') {
          row.push(
            item.ftl_sales_name || '',
            item.ftl_sales_support_name || '',
          );
        }

        // Add financial data
        row.push(
          item.terms,
          item.total,
          item.current,
          item.due_1_30,
          item.due_31_60,
          item.due_61,
        );
        return row;
      });

      // Create appropriate headers based on type
      let headers = ['Name', 'Company', 'Contract', 'Agent', 'BD'];

      if (type === 'all') {
        headers = headers.concat([
          'Sales',
          'Sales Support',
          'LTL Sales',
          'LTL Sales Support',
          'FTL Sales',
          'FTL Sales Support',
        ]);
      } else if (type === 'drayeasy') {
        headers = headers.concat(['Sales', 'Sales Support']);
      } else if (type === 'ltl') {
        headers = headers.concat(['LTL Sales', 'LTL Sales Support']);
      } else if (type === 'ftl') {
        headers = headers.concat(['FTL Sales', 'FTL Sales Support']);
      }

      headers = headers.concat([
        'Terms',
        'Total',
        'Current',
        'Over 1 - 30 Days',
        'Over 31 - 60 Days',
        'Over 60 Days',
      ]);

      // add header
      rows.unshift(headers);

      return rows;
    },
    [type],
  );

  const columns: ColumnsType<AgingReportItem> = React.useMemo(() => {
    // Helper function to create number sorter for financial columns
    const createNumberSorter = (key: keyof AgingReportItem) => (
      a: AgingReportItem,
      b: AgingReportItem,
    ) =>
      parseFloat(a[key].replace(/,/g, '')) -
      parseFloat(b[key].replace(/,/g, ''));

    // Base columns that appear in all report types
    const baseColumns: ColumnsType<AgingReportItem> = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        render: (text: string, record: AgingReportItem, index: number) => {
          if (record.user_id) {
            return (
              <UserLink
                name={record.name}
                id={record.user_id as number}
                accountingMemo={record.user_accounting_memo || ''}
                onSaved={(user: UserData) => refreshUser(index, user)}
              />
            );
          }

          if (record.vendor_id) {
            return (
              <NameLink
                type={TYPE_VENDOR}
                name={record.name}
                id={record.vendor_id as number}
                onSaved={(name: string) => refreshName(index, name)}
              />
            );
          }

          return text;
        },
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: 200,
        render: (text: string, record: AgingReportItem, index: number) => {
          if (record.company_id) {
            return (
              <CompanyLink
                name={record.company}
                id={record.company_id as number}
                accountingMemo={record.company_accounting_memo || ''}
                onSaved={(company: CompanyData) =>
                  refreshCompany(index, company)
                }
              />
            );
          }

          return text;
        },
      },
      {
        title: 'Contract',
        dataIndex: 'has_contract',
        key: 'has_contract',
        width: 200,
        render: (v: boolean) => (v ? 'Yes' : 'No'),
      },
      {
        title: 'Agent',
        dataIndex: 'agent_name',
        key: 'agent_name',
        width: 100,
      },
      {
        title: 'BD',
        dataIndex: 'business_development_name',
        key: 'business_development_name',
        width: 100,
      },
      // Terms will be at position 5 + optional columns
      {
        title: 'Terms',
        dataIndex: 'terms',
        key: 'terms',
        width: 200,
      },
      // Financial columns
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: 200,
        sorter: createNumberSorter('total'),
      },
      {
        title: 'Current',
        dataIndex: 'current',
        key: 'current',
        width: 200,
        sorter: createNumberSorter('current'),
      },
      {
        title: 'Over 1 - 30 Days',
        dataIndex: 'due_1_30',
        key: 'due_1_30',
        width: 200,
        sorter: createNumberSorter('due_1_30'),
      },
      {
        title: 'Over 31 - 60 Days',
        dataIndex: 'due_31_60',
        key: 'due_31_60',
        width: 200,
        sorter: createNumberSorter('due_31_60'),
      },
      {
        title: 'Over 60 Days',
        dataIndex: 'due_61',
        key: 'due_61',
        width: 200,
        sorter: createNumberSorter('due_61'),
      },
    ];

    // Define type-specific columns
    const typeSpecificColumns = {
      drayeasy: [
        {
          title: 'Sales',
          dataIndex: 'sales_name',
          key: 'sales_name',
          width: 100,
        },
        {
          title: 'Sales Support',
          dataIndex: 'sales_support_name',
          key: 'sales_support_name',
          width: 150,
        },
      ],
      ltl: [
        {
          title: 'LTL Sales',
          dataIndex: 'ltl_sales_name',
          key: 'ltl_sales_name',
          width: 100,
        },
        {
          title: 'LTL Sales Support',
          dataIndex: 'ltl_sales_support_name',
          key: 'ltl_sales_support_name',
          width: 150,
        },
      ],
      ftl: [
        {
          title: 'FTL Sales',
          dataIndex: 'ftl_sales_name',
          key: 'ftl_sales_name',
          width: 100,
        },
        {
          title: 'FTL Sales Support',
          dataIndex: 'ftl_sales_support_name',
          key: 'ftl_sales_support_name',
          width: 150,
        },
      ],
    };

    // Create a copy to avoid mutating the base columns
    const result = [...baseColumns];

    // Insert appropriate columns based on type
    if (type === 'all') {
      // Insert all special columns
      result.splice(
        5,
        0,
        ...typeSpecificColumns.drayeasy,
        ...typeSpecificColumns.ltl,
        ...typeSpecificColumns.ftl,
      );
    } else if (type === 'drayeasy') {
      result.splice(5, 0, ...typeSpecificColumns.drayeasy);
    } else if (type === 'ltl') {
      result.splice(5, 0, ...typeSpecificColumns.ltl);
    } else if (type === 'ftl') {
      result.splice(5, 0, ...typeSpecificColumns.ftl);
    }

    return result;
  }, [refreshUser, refreshCompany, refreshName, type]);

  return (
    <div className={styles.main}>
      <Form
        className="w100"
        layout="vertical"
        form={filter}
        onFinish={handleSave}
      >
        <Header
          title={
            <Space align="center">
              <div>Aging Report</div>
              <div>
                <Form.Item
                  name="type"
                  initialValue="all"
                  style={{ marginBottom: '0px' }}
                >
                  <Radio.Group>
                    <Radio.Button value="all">All</Radio.Button>
                    <Radio.Button value="drayeasy">Dray</Radio.Button>
                    <Radio.Button value="ltl">LTL</Radio.Button>
                    <Radio.Button value="ftl">FTL</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Space>
          }
        ></Header>
        <div className={styles.filter}>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="Date as of">
                <Space>
                  <Form.Item noStyle name="date" initialValue={'invoice_date'}>
                    <Select style={{ width: '10em' }}>
                      <Select.Option value="post_date">
                        {'Post Date'}
                      </Select.Option>
                      <Select.Option value="invoice_date">
                        {'Inovice Date'}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="end_date"
                    initialValue={moment().format('YYYY-MM-DD')}
                    noStyle
                  >
                    <Input hidden />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => {
                      const end_date = getFieldValue('end_date');
                      return (
                        <DatePicker
                          value={end_date ? moment(end_date) : null}
                          onChange={(value) =>
                            setFieldsValue({
                              end_date: value
                                ? value?.format('YYYY-MM-DD')
                                : null,
                            })
                          }
                          style={{ width: '100%' }}
                        />
                      );
                    }}
                  </Form.Item>
                </Space>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Company" name="company_id">
                <CompanySyncSelect />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Sales" name="sales_id">
                <UserSyncSelect
                  type="admin"
                  addNullOption
                  nullOptionValue={-1}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Sales Support" name="sales_support_id">
                <UserSyncSelect
                  type="admin"
                  addNullOption
                  nullOptionValue={-1}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Agent" name="agent_id">
                <UserSyncSelect
                  type="user"
                  addNullOption
                  nullOptionValue={-1}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Business Development"
                name="business_development_id"
              >
                <UserSyncSelect
                  type="admin"
                  addNullOption
                  nullOptionValue={-1}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Space align="end">
                <Form.Item
                  label="&nbsp;"
                  name="invoiceable"
                  initialValue={true}
                  valuePropName="checked"
                >
                  <Checkbox>Invoice</Checkbox>
                </Form.Item>
                <Form.Item
                  label="&nbsp;"
                  name="billable"
                  initialValue={true}
                  valuePropName="checked"
                >
                  <Checkbox>Bill</Checkbox>
                </Form.Item>
              </Space>
            </Col>
            {/* </Col> */}
            <Col span={3}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Submit
                  </Button>
                  <CSVLink data={exportingData} filename="agingreport.csv">
                    <Button>Export</Button>
                  </CSVLink>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
      <AutoResizeTable
        loading={loading}
        pagination={false}
        size="small"
        rowKey={(record) =>
          `${record.name}_${record.company}_${Math.random()
            .toString(36)
            .substring(7)}`
        }
        columns={columns}
        dataSource={data ? data : []}
        summary={() =>
          summary ? (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.table_summary_row}>
                <Table.Summary.Cell
                  index={0}
                  colSpan={type === 'all' ? 12 : 8}
                  align={'right'}
                >
                  Total{' '}
                  {data && data.length > 0
                    ? data.length + ' Records'
                    : '0 Record'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {summary.total}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {summary.current}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {summary.due_1_30}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  {summary.due_31_60}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {summary.due_61}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          ) : (
            <></>
          )
        }
        sticky
        scroll={{
          x: 'auto',
        }}
      />
    </div>
  );
};

export default Index;
