import { DISPATCH_STATUS_OP_CLOSED } from '@/pages/dispatchs/components/data';
import { showErrorMessage } from '@/utils/show-error-message';
import { useApp } from '@/utils/useapp';
import { Tooltip, message } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { FaTruckMoving } from 'react-icons/fa';

export const OnTheWayTruckIcon: React.FC<{
  container: any;
  onUpdated?: () => void;
}> = ({ container, onUpdated }): React.ReactElement | null => {
  // container.dispatach_status == DISPATCH_STATUS_OP_CLOSED for optimize performance
  // you don't need to show the truck icon if the container is op closed
  // met_exam_trucker_confirmed_site_delivery_apt_at

  const scheduleDeliveryAt =
    container.met_exam_trucker_confirmed_site_delivery_apt_at ||
    container.schedule_delivery_at;
  if (
    !scheduleDeliveryAt ||
    container.actual_delivered_at ||
    container.dispatach_status == DISPATCH_STATUS_OP_CLOSED
  ) {
    return null;
  }

  const isTruckerOnTheWay = !!container.custom_properties?.isTruckerOnTheWay;

  let color = 'text-danger';

  if (isTruckerOnTheWay) {
    color = 'text-primary';
  }

  const app = useApp();

  const triggerIconColor = async () => {
    const data = {
      values: {
        isTruckerOnTheWay: isTruckerOnTheWay ? false : true,
      },
    };
    try {
      await app.service.patch(
        `containers/${container.id}/patchUpdateCustomProperties`,
        { data },
      );

      onUpdated && onUpdated();
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  // Red: when the container's delivery date is today but it is not on the way nor delivered yet
  // Blue: when the trucker confirmed the container is on the way to the warehouse
  // Disappear: when the container is delivered, the car icon will disappear.

  // Operation: left click on the car when the trucker confirms the driver is on the way
  if (isToday(scheduleDeliveryAt)) {
    return (
      <span className={`cursor-pointer ${color}`} onClick={triggerIconColor}>
        <Tooltip
          title={
            isTruckerOnTheWay
              ? 'Trucker is on the way.'
              : 'Confirm if the trucker is en route. Click here to confirm.'
          }
        >
          <FaTruckMoving />
        </Tooltip>
      </span>
    );
  }

  return null;
};

const isToday = (dateString: string): boolean => {
  if (!dateString) {
    return false;
  }
  // Parse the date using Moment.js in the PDT timezone
  const givenDate = moment.tz(dateString, 'America/Los_Angeles');

  // Get today's date in the PDT timezone
  const today = moment().tz('America/Los_Angeles');

  // Compare the dates to see if they are the same day
  return givenDate.format('YYYY-MM-DD') === today.format('YYYY-MM-DD');
};
