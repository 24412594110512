import React from 'react';

import {
  Card,
  DatePicker,
  Popover,
  Tag,
  Row,
  message,
  Button,
  Input,
  Descriptions,
  Switch,
  Typography,
} from 'antd';
import { useApp } from '@/utils/useapp';
import moment from 'moment';
import { get } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';

type ITerminalFeeName = {
  name: string;
  label: string;
  value: boolean;
};

const RELEASE_TYPES_MAP = {
  freight_released_at: 'Freight Release',
  customs_released_at: 'Customs Release',
  terminal_released_at: 'Terminal Release',
};

export const ReleaseStatus: React.FC<{
  container: any;
  refreshContainer: (id: number) => void;
}> = ({ container, refreshContainer }) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [allReleasedAt, setAllReleasedAt] = React.useState(null);

  const handlePatchUpdate = async (id: number, values: any) => {
    setLoading(true);
    try {
      await app.service.patch(`containers/${id}`, { data: values });
      refreshContainer(id);
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateRelease = async (name: string, dateString: string) => {
    await handlePatchUpdate(container.id, { [name]: dateString });
  };

  const handleUpdateAllReleased = async () => {
    const values: any = {};

    toReleaseList().map((release: any) => {
      values[release.name] = allReleasedAt;
    });

    await handlePatchUpdate(container.id, values);
  };

  const toReleaseList = () => {
    return Object.keys(RELEASE_TYPES_MAP).map((name: any) => ({
      name,
      label:
        RELEASE_TYPES_MAP[(name as unknown) as keyof typeof RELEASE_TYPES_MAP],
      value: container[name] ? container[name] : null,
    }));
  };

  return (
    <Popover
      trigger="click"
      placement="right"
      content={() => {
        return (
          <>
            <div className="p-md" style={{ width: '25vw' }}>
              <div>
                <div>
                  <Input.Group style={{ width: '100%' }} compact>
                    <DatePicker
                      style={{ width: '60%' }}
                      value={allReleasedAt ? moment(allReleasedAt) : null}
                      onChange={(date, dateString: any) =>
                        setAllReleasedAt(dateString)
                      }
                    />
                    <Button
                      style={{ width: '40%' }}
                      disabled={!allReleasedAt}
                      type="primary"
                      onClick={handleUpdateAllReleased}
                    >
                      All Released
                    </Button>
                  </Input.Group>
                  <Card
                    className={`mt-md dispatch-release-cards ${
                      container.isAllReleased
                        ? 'dispatch-released-border-color'
                        : 'dispatch-hold-border-color'
                    }`}
                    loading={loading}
                  >
                    {toReleaseList().map((release: any) => (
                      <Card
                        key={release.name}
                        className={`dispatch-release-card ${
                          release.value ? 'released-bg' : 'hold-bg'
                        }`}
                      >
                        <Row className="mb-xs">
                          <span>{release.label}</span>
                        </Row>
                        <Row>
                          <DatePicker
                            style={{ width: '100%' }}
                            placeholder={release.label}
                            value={release.value ? moment(release.value) : null}
                            onChange={(date, dateString) =>
                              handleUpdateRelease(release.name, dateString)
                            }
                          />
                        </Row>
                      </Card>
                    ))}
                    <Descriptions
                      className="mt-md"
                      layout="vertical"
                      bordered
                      column={3}
                    >
                      {get(container, 'terminal_fee_names', []).map(
                        (fee: ITerminalFeeName, i: number) => (
                          <Descriptions.Item
                            key={i}
                            label={
                              <>
                                <div>{fee.label}</div>
                                <Typography.Text type="danger">
                                  {get(container, `vendor.will_pay_${fee.name}`)
                                    ? ' (Trucker will pay)'
                                    : ''}
                                </Typography.Text>
                              </>
                            }
                          >
                            <Switch
                              checked={get(
                                container,
                                `terminal_fees.${fee.name}`,
                                false,
                              )}
                              onClick={(v) => {
                                handlePatchUpdate(container.id, {
                                  terminal_fees: {
                                    ...container.terminal_fees,
                                    [fee.name]: v,
                                  },
                                });
                              }}
                            />
                          </Descriptions.Item>
                        ),
                      )}
                    </Descriptions>
                  </Card>
                </div>
              </div>
            </div>
          </>
        );
      }}
    >
      {container.isAllReleased ? (
        <Tag color="success" className="text-pointer">
          Released
        </Tag>
      ) : (
        <Tag color="red" className="text-pointer">
          Hold
        </Tag>
      )}
    </Popover>
  );
};
