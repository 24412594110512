import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  DatePicker,
  Drawer,
  Dropdown,
  message,
  Popconfirm,
  Popover,
  Select,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import React, { useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import { Filter } from './components/Filter';

import { TCollection, TSorter } from '@/types';
import usePagination from '@/components/usePagination';
import { each, get } from 'lodash';

import { InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { NavTabs } from '../../components/NavTabs';
import moment from 'moment';
import { DeliveryOrderDrawer } from '../orders/components/DeliveryOrderDrawer';
import { DrawerForm } from './components/DrawerForm';
import { OrderDrawer } from '../orders/components/OrderDrawer';
import AutoResizeTable from '@/components/AutoResizeTable';
import { WarningOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { OCEAN_PORT, RAMP_PORT } from '../dispatchs/components/data';
import { IntermodalRegionOceanSelect } from '@/components/IntermodalRegionOceanSelect';
import { Memo } from '../dispatchs/components/Memo';
import { TerminalSelect } from '@/components/TerminalSelect';
import { CopyMultipleButton } from '@/components/CopyMultipleButton';
import CntrAndMblColumnRender from '@/components/CntrAndMblColumnRender';

import {
  BIG_FIVE,
  DIFF_BETWEEN_PORT_LFD_AND_LINE_LFD_SCAC_CODES,
  SCAC_CODE_ORDER,
} from './data';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { QuestionCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { FaTrainSubway } from 'react-icons/fa6';
import { DrawerFormBulkUpdate } from './components/DrawerFormBulkUpdate';
import { LogActivities } from '@/components/LogActivities';
import { showErrorMessage } from '@/utils/show-error-message';

const ContainerTags: React.FC<{ container: any }> = ({ container }) => {
  const list = [];

  container.is_soc &&
    list.push(
      <Tag key="soc" color="processing">
        SOC
      </Tag>,
    );
  container.is_dg &&
    list.push(
      <Tag key="dg" color="processing">
        DG
      </Tag>,
    );
  container.is_overweight &&
    list.push(
      <Tag key="ow" color="processing">
        OW
      </Tag>,
    );
  container.is_reefer &&
    list.push(
      <Tag key="reefer" color="processing">
        REFFER
      </Tag>,
    );
  if (container.is_exam) {
    list.push(
      <Tag key="exam" color="processing">
        EXAM
      </Tag>,
    );
  }

  return <>{list}</>;
};

const WeatherEvents: React.FC<{ container: any }> = ({ container }) => {
  const [hovered, setHovered] = useState(false);

  const handleHoverChange = (open: boolean) => {
    setHovered(open);
  };

  const today = moment();
  const color = container.weather_events?.every((e: any) =>
    e.end_at
      ? moment(e.end_at).isAfter(today, 'day')
      : moment(e.start_at).isAfter(moment(today).add(10, 'days'), 'day'),
  )
    ? '#F7F1E9'
    : '#F9930A' || '#08c';

  return (
    <Popover
      style={{ width: 500 }}
      content={
        <>
          {container.weather_events.map((e: any) => (
            <div key={e.id}>
              <a href={e.public_link} target="_blank" rel="noreferrer">
                {e.event}
              </a>{' '}
              <small>
                {moment(e.event.start_at).format('MM/DD/YYYY')} -{' '}
                {e.event.end_at
                  ? moment(e.event.end_at).format('MM/DD/YYYY')
                  : ''}
              </small>
            </div>
          ))}
        </>
      }
      placement="right"
      title="Weather Events"
      trigger="hover"
      open={hovered}
      onOpenChange={handleHoverChange}
    >
      <WarningOutlined style={{ color: color }} />
    </Popover>
  );
};

const DeliveryOrderField: React.FC<{
  container: any;
  render: any;
  separator?: string;
}> = ({ container, render, separator = ' ' }) => {
  return container.delivery_orders
    .filter((d: any) => d.state !== 'canceled')
    .map((d: any) => <React.Fragment key={d.id}>{render(d)}</React.Fragment>)
    .reduce(
      (accumulator: any, current: any) =>
        accumulator === null ? (
          current
        ) : (
          <>
            {accumulator}
            {separator}
            {current}
          </>
        ),
      null,
    );
};

DeliveryOrderField.displayName = 'DeliveryOrderField';

const TerminalDisplay: React.FC<{ terminal: any; onRemove: () => void }> = ({
  terminal,
  onRemove,
}) => {
  if (!terminal) {
    return null;
  }

  return (
    <>
      <Space>
        <a rel="noreferrer" target="_blank" href={terminal.tracking_link}>
          {terminal.firms}
        </a>
        {onRemove && (
          <Popconfirm
            title="Are you sure？"
            onConfirm={() => onRemove()}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <CloseCircleOutlined style={{ color: 'red' }} />
          </Popconfirm>
        )}
        {terminal.track_advise && (
          <Tooltip title={terminal.track_advise}>
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </Space>
      {/* <div>{terminal.type}</div> */}
    </>
  );
};

const Index = () => {
  const app = useApp();

  const [doForm] = useForm();
  const [showDoForm, setShowDoForm] = React.useState(false);
  const [deliveryOrder, setDeliveryOrder] = useState<any>();

  const [showOrderForm, setShowOrderForm] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<TCollection<any>>();
  const [container, setContainer] = useState<any>(null);
  const [orderId, setOrderId] = useState<any>(null);
  const [statesCount, setStatesCount] = useState({});
  const [activeRowId, setActiveRowId] = useState(0);
  const [terminals, setTerminals] = useState([]);
  const [podTerminals, setPodTerminals] = useState([]);
  const [scacCodes, setScacCodes] = useState<string[]>([]);

  const [form] = useForm();
  const [filter] = useForm();

  const [activeKey, setActiveKey] = useState('pre_alert');

  const pagination = usePagination(data);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [openBulkUpdate, setOpenBulkUpdate] = useState(false);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    onSelectMultiple: (_selected: any, selectedRows: any, _changeRows: any) => {
      const selectedRowKeys = selectedRows.map((s: any) => s.id);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const handleBulkUpdate = () => {
    if (selectedRowKeys?.length <= 0) {
      message.error('Please select data to update');
      return false;
    }
    setOpenBulkUpdate(true);
  };

  const fetchData = React.useCallback(
    async (_pagination?: TablePaginationConfig, sorter: TSorter = {}) => {
      setLoading(true);
      const filterValue = filter.getFieldsValue();

      filterValue.warehouse_id = get(filterValue, 'warehouse.id', '');
      delete filterValue.warehouse;
      filterValue.consignee_id = get(filterValue, 'consignee.id', '');
      delete filterValue.consignee;
      filterValue.vendor_id = get(filterValue, 'vendor.id', '');
      delete filterValue.vendor;

      filterValue.terminal_id =
        get(filterValue, 'terminal.id', '') ||
        get(filterValue, 'terminal_id', '');
      delete filterValue.terminal;

      filterValue[activeKey] = true;

      const _paginationValue = {
        ...pagination,
        ..._pagination,
      };

      try {
        const resp = await app.service.get('containers', {
          params: {
            ...filterValue,
            ignore_hi: true,
            page: _paginationValue?.current || 1,
            per_page: _paginationValue?.pageSize || 20,
            ...sorter,
          },
        });
        setData(resp);
      } catch (error: any) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    },
    [activeKey, pagination],
  );

  const fetchoStatesCount = React.useCallback(async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('containers/statesCount', {
        params: { ignore_hi: true },
      });
      setStatesCount(resp.data);
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const fetchTerminals = async (key = 'terminal_id') => {
    try {
      const resp: any = await app.service.get('containers/terminals', {
        params: {
          [activeKey]: true,
          key,
        },
      });
      if (key == 'pod_terminal_id') {
        setPodTerminals(resp.data);
      } else {
        setTerminals(resp.data);
      }
    } catch (error: any) {
      showErrorMessage(error);
    }
  };

  const fetchRelatedTerminals = async (code: string, key = 'terminal_id') => {
    try {
      const resp: any = await app.service.get('containers/relatedTerminas', {
        params: {
          [activeKey]: true,
          scac_code: code,
          key,
        },
      });
      if (key == 'pod_terminal_id') {
        setPodTerminals(resp.data);
      } else {
        setTerminals(resp.data);
      }
    } catch (error: any) {
      showErrorMessage(error);
    }
  };

  const fetchScacCodes = async () => {
    try {
      const resp: any = await app.service.get('containers/scacCodes', {
        params: {
          [activeKey]: true,
        },
      });
      const codes = SCAC_CODE_ORDER.filter((code: string) =>
        resp.data.includes(code),
      );

      setScacCodes(codes);
    } catch (error: any) {
      showErrorMessage(error);
    }
  };

  const handleClickCopyAllCNTR = () => {
    let copyText = '';
    each(selectedRows, (container) => {
      copyText += `${container.number}\r`;
    });
    if (copy(copyText)) {
      message.success('Copied to clipboard');
    }
  };

  const handleClickCopyAllMBL = () => {
    let copyText = '';
    each(selectedRows, (container) => {
      copyText += get(container, 'order.mbl_number', false)
        ? `${get(container, 'order.mbl_number', false)}\r`
        : '';
    });
    if (copy(copyText)) {
      message.success('Copied to clipboard');
    }
  };

  const handleIrEtaEst = async (id: number) => {
    try {
      await app.service.get(`containers/${id}/irEtaEst`);
      fetchData();
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    let data;
    try {
      data = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);
    const formValue = Object.assign({}, data);
    formValue.consignee_id = get(formValue, 'consignee.id', 0);
    formValue.user_id = get(formValue, 'user.id', 0);

    delete formValue.consignee;
    delete formValue.user;
    try {
      !data.id
        ? await app.service.post('containers', { data: formValue })
        : await app.service.put(`containers/${container}`, { data: formValue });

      message.success('Saved');
      fetchData();
      onClose();
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchData({ current: 1 });
  };

  const handlePatchUpdate = async (id: number, values: any) => {
    setLoading(true);
    try {
      await app.service.patch(`containers/${id}`, { data: values });
      fetchData();
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEasyTrackOrder = async (id: number) => {
    setLoading(true);
    try {
      if (!data) {
        return;
      }
      await app.service.put(`orders/${id}/easyTrack`, {});
      message.success('Track Success');
      fetchData();
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: () => (
        <>
          <Space>
            CNTR#
            <CopyMultipleButton
              onClick={handleClickCopyAllCNTR}
            ></CopyMultipleButton>
          </Space>{' '}
          <Space>
            MBL
            <CopyMultipleButton
              onClick={handleClickCopyAllMBL}
            ></CopyMultipleButton>
          </Space>
        </>
      ),
      dataIndex: 'number',
      key: 'number',
      width: 260,
      fixed: 'left',
      render: (text, record) => (
        <CntrAndMblColumnRender
          container={record}
          order={record.order}
          onSaved={fetchData}
        />
      ),
    },
    {
      title: 'POD Terminal',
      dataIndex: 'pod_terminal_id',
      key: 'pod_terminal_id',
      width: 150,
      sorter: true,
      fixed: 'left',
      render: (text, record) => {
        const destinationType = record?.destination_type || '';
        const code = get(record, 'order.mbl_number', '').slice(0, 4);

        return (
          <>
            {activeKey == 'pod_terminal' ? (
              <TerminalSelect
                renderOption={(v: any) => (
                  <>
                    <strong>{v.firms}</strong>
                    <br />
                    <small>{v.full_name} </small>
                    <br />
                    <small>{v.type} </small>
                    <br />
                    {v.street && (
                      <>
                        <span>{v.street}</span>
                        <br />
                      </>
                    )}
                    <span>
                      {v?.city?.full_name} {v.zipcode}
                    </span>
                  </>
                )}
                disabled={
                  destinationType == OCEAN_PORT || !BIG_FIVE.includes(code)
                }
                selected={record.pod_terminal}
                redirectable={true}
                onSelect={(terminal: any) =>
                  handlePatchUpdate(record.id, {
                    pod_terminal_id: terminal?.id || 0,
                  })
                }
              />
            ) : (
              <TerminalDisplay
                terminal={record.pod_terminal}
                onRemove={() =>
                  handlePatchUpdate(record.id, {
                    pod_terminal_id: 0,
                  })
                }
              />
            )}
          </>
        );
      },
    },
    {
      title: 'IR Terminal',
      dataIndex: 'terminal_id',
      key: 'terminal_id',
      width: 150,
      sorter: true,
      fixed: 'left',
      render: (text, record) => {
        const destinationType = record?.destination_type || '';

        return (
          <TerminalSelect
            renderOption={(v: any) => (
              <>
                <strong>{v.firms}</strong>
                <br />
                <small>{v.full_name} </small>
                <br />
                <small>{v.type}</small>
                <br />
                {v.street && (
                  <>
                    <span>{v.street}</span>
                    <br />
                  </>
                )}
                <span>
                  {v?.city?.full_name} {v.zipcode}
                </span>
              </>
            )}
            // disabled={destinationType == OCEAN_PORT}
            selected={record.terminal}
            redirectable={true}
            onSelect={(terminal: any) =>
              handlePatchUpdate(record.id, {
                terminal_id: terminal?.id || 0,
              })
            }
          />
        );
      },
    },

    {
      title: 'Destination Type',
      dataIndex: 'destination_type',
      key: 'destination_type',
      width: 150,
      render: (text, record) => {
        return (
          <Select
            style={{
              width: '100%',
            }}
            value={record.destination_type}
            onChange={(v) =>
              handlePatchUpdate(record.id, {
                destination_type: v,
              })
            }
          >
            <Select.Option value={OCEAN_PORT}>Ocean Port</Select.Option>
            <Select.Option value={RAMP_PORT}>Ramp Port</Select.Option>
          </Select>
        );
      },
    },
    {
      title: 'POL ETD',
      dataIndex: 'port_of_loading_etd',
      key: 'port_of_loading_etd',
      width: 150,
      sorter: true,
      render: (text, record) => {
        return (
          <DatePicker
            style={{ width: '100%' }}
            value={
              record.port_of_loading_etd
                ? moment(record.port_of_loading_etd)
                : null
            }
            onChange={(date, dateString) =>
              handlePatchUpdate(record.id, {
                port_of_loading_etd: dateString,
              })
            }
          />
        );
      },
    },
    {
      title: 'POD',
      dataIndex: 'port_of_discharge',
      key: 'port_of_discharge',
      width: 200,
      render: (text, record) => {
        const destinationType = record?.destination_type || '';
        return (
          <IntermodalRegionOceanSelect
            className="w100"
            value={record.port_of_discharge_id}
            onChange={(id) =>
              handlePatchUpdate(record.id, {
                port_of_discharge_id: id || 0,
                final_port_id:
                  destinationType === OCEAN_PORT ? id : record.final_port_id,
              })
            }
          />
        );
      },
    },
    {
      title: 'IR',
      dataIndex: 'final_port',
      key: 'final_port',
      width: 200,
      render: (text, record) => {
        const destinationType = record?.destination_type || '';
        return (
          <IntermodalRegionSelect
            className="w100"
            disabled={destinationType === OCEAN_PORT}
            value={record.final_port_id}
            onChange={(id: any) =>
              handlePatchUpdate(record.id, {
                final_port_id: id || 0,
              })
            }
          />
        );
      },
    },
    {
      title: 'POD ETA',
      dataIndex: 'port_of_discharge_eta',
      key: 'port_of_discharge_eta',
      width: 150,
      sorter: true,
      render: (text, record) => {
        const destinationType = record?.destination_type || '';
        return (
          <DatePicker
            style={{ width: '100%' }}
            value={
              record.port_of_discharge_eta
                ? moment(record.port_of_discharge_eta)
                : null
            }
            onChange={(date, dateString) =>
              handlePatchUpdate(record.id, {
                port_of_discharge_eta: dateString,
                final_port_eta:
                  destinationType === OCEAN_PORT
                    ? dateString
                    : record.final_port_eta,
              })
            }
          />
        );
      },
    },
    {
      title: 'Rail Departure',
      dataIndex: 'rail_departure_date',
      key: 'rail_departure_date',
      width: 150,
      sorter: true,
      render: (text, record) => {
        const destinationType = record?.destination_type || '';
        return (
          <DatePicker
            disabled={destinationType === OCEAN_PORT}
            style={{ width: '100%' }}
            value={
              record.rail_departure_date
                ? moment(record.rail_departure_date)
                : null
            }
            onChange={(date, dateString) =>
              handlePatchUpdate(record.id, {
                rail_departure_date: dateString,
              })
            }
          />
        );
      },
    },
    {
      title: 'IR ETA',
      dataIndex: 'final_port_eta',
      key: 'final_port_eta',
      width: 210,
      sorter: true,
      render: (text, record) => {
        const destinationType = record?.destination_type || '';
        return (
          <Space>
            <DatePicker
              disabled={destinationType === OCEAN_PORT}
              style={{ width: '100%' }}
              value={
                record.final_port_eta ? moment(record.final_port_eta) : null
              }
              onChange={(date, dateString) =>
                handlePatchUpdate(record.id, {
                  final_port_eta: dateString,
                })
              }
            />
            {destinationType != OCEAN_PORT && (
              <Button
                style={{
                  padding: 0,
                }}
                onClick={() => handleIrEtaEst(record.id)}
                type="link"
              >
                Est
              </Button>
            )}
            {record?.rail_departure_date && (
              <Tooltip title="Depatured">
                <FaTrainSubway
                  style={{
                    marginBottom: '-2px',
                  }}
                  color="#1890ff"
                />
              </Tooltip>
            )}
          </Space>
        );
      },
    },

    {
      title: 'Freight',
      dataIndex: 'freight_released_at',
      key: 'freight_released_at',
      width: 150,
      render: (text, record) => {
        return (
          <>
            <DatePicker
              allowClear
              style={{ width: '100%' }}
              value={
                record.freight_released_at
                  ? moment(record.freight_released_at)
                  : null
              }
              onChange={(date, dateString) =>
                handlePatchUpdate(record.id, {
                  freight_released_at: dateString,
                })
              }
            />
          </>
        );
      },
    },
    {
      title: 'Customs',
      dataIndex: 'customs_released_at',
      key: 'customs_released_at',
      width: 150,
      render: (text, record) => {
        return (
          <>
            <DatePicker
              allowClear
              style={{ width: '100%' }}
              value={
                record.customs_released_at
                  ? moment(record.customs_released_at)
                  : null
              }
              onChange={(date, dateString) =>
                handlePatchUpdate(record.id, {
                  customs_released_at: dateString,
                })
              }
            />
          </>
        );
      },
    },
    {
      title: 'Terminal Released',
      dataIndex: 'terminal_released_at',
      key: 'terminal_released_at',
      width: 150,
      render: (text, record) => {
        const disabled =
          record.destination_type == RAMP_PORT && !record.rail_departure_date;

        return (
          <>
            <DatePicker
              disabled={disabled}
              allowClear
              style={{ width: '100%' }}
              value={
                record.terminal_released_at
                  ? moment(record.terminal_released_at)
                  : null
              }
              onChange={(date, dateString) =>
                handlePatchUpdate(record.id, {
                  terminal_released_at: dateString,
                })
              }
            />
          </>
        );
      },
    },

    {
      title: 'Ground',
      dataIndex: 'grounded_at',
      key: 'grounded_at',
      width: 150,
      render: (text, record) => {
        const disabled =
          record.destination_type == RAMP_PORT && !record.rail_departure_date;
        return (
          <>
            <DatePicker
              disabled={disabled}
              allowClear
              style={{ width: '100%' }}
              value={record.grounded_at ? moment(record.grounded_at) : null}
              onChange={(date, dateString) =>
                handlePatchUpdate(record.id, {
                  grounded_at: dateString,
                })
              }
            />
          </>
        );
      },
    },
    {
      title: 'Port LFD',
      dataIndex: 'port_lfd',
      key: 'port_lfd',
      width: 150,
      render: (text, record) => {
        const disabled =
          record.destination_type == RAMP_PORT && !record.rail_departure_date;
        return (
          <>
            <DatePicker
              disabled={disabled}
              allowClear
              style={{ width: '100%' }}
              value={record.port_lfd ? moment(record.port_lfd) : null}
              onChange={(date, dateString) =>
                handlePatchUpdate(record.id, { port_lfd: dateString })
              }
            />
          </>
        );
      },
    },
    {
      title: 'Line LFD',
      dataIndex: 'line_lfd',
      key: 'line_lfd',
      width: 150,
      render: (text, record) => {
        const disabled =
          record.destination_type == RAMP_PORT && !record.rail_departure_date;

        const code = get(record, 'order.mbl_number', '').slice(0, 4);
        const isDiff = DIFF_BETWEEN_PORT_LFD_AND_LINE_LFD_SCAC_CODES.includes(
          code,
        );

        return (
          <>
            <DatePicker
              disabled={disabled || !isDiff}
              allowClear
              style={{ width: '100%' }}
              value={record.line_lfd ? moment(record.line_lfd) : null}
              onChange={(date, dateString) =>
                handlePatchUpdate(record.id, { line_lfd: dateString })
              }
            />
          </>
        );
      },
    },

    {
      title: 'Apt Pickup',
      dataIndex: 'schedule_terminal_pickup_at',
      key: 'schedule_terminal_pickup_at',
      width: 150,
      render: (text, record) => {
        return record.schedule_terminal_pickup_at || 'N/A';
      },
    },
    {
      title: 'Terminal Pickup',
      dataIndex: 'containers.actual_terminal_pickuped_at',
      key: 'containers.actual_terminal_pickuped_at',
      sorter: true,
      width: 150,
      render: (text, record) => {
        const disabled =
          (record.destination_type == RAMP_PORT &&
            !record.rail_departure_date) ||
          !record.grounded_at ||
          !record.lfd;
        return (
          <>
            <DatePicker
              disabled={disabled}
              allowClear
              style={{ width: '100%' }}
              value={
                record.actual_terminal_pickuped_at
                  ? moment(record.actual_terminal_pickuped_at)
                  : null
              }
              onChange={(date, dateString) =>
                handlePatchUpdate(record.id, {
                  actual_terminal_pickuped_at: dateString,
                })
              }
            />
          </>
        );
      },
    },

    {
      title: 'Empty Return',
      dataIndex: 'actual_empty_returned_at',
      key: 'actual_empty_returned_at',
      width: 150,
      render: (text, record) => {
        const disabled =
          (record.destination_type == RAMP_PORT &&
            !record.rail_departure_date) ||
          !record.grounded_at ||
          !record.lfd ||
          !record.actual_terminal_pickuped_at;
        return (
          <>
            <DatePicker
              disabled={disabled}
              allowClear
              style={{ width: '100%' }}
              value={
                record.actual_empty_returned_at
                  ? moment(record.actual_empty_returned_at)
                  : null
              }
              onChange={(date, dateString) =>
                handlePatchUpdate(record.id, {
                  actual_empty_returned_at: dateString,
                })
              }
            />
          </>
        );
      },
    },

    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <Space>
          <Button type="link" size="small">
            <LogActivities
              id={record.id}
              icon={
                <div className="text-gray">
                  <InfoCircleOutlined />
                </div>
              }
              type="containers"
            />
          </Button>

          <Tooltip title="tracking">
            <Button
              type="link"
              size="small"
              onClick={() => handleEasyTrackOrder(record.order.id)}
            >
              <SyncOutlined className="text-gray" />
            </Button>
          </Tooltip>
          <Button type="link" size="small">
            <Memo
              initialValue={record.internal_memo}
              onSaved={fetchData}
              container={record}
            />
          </Button>
        </Space>
      ),
    },
  ];

  React.useEffect(() => {
    fetchoStatesCount();
  }, []);

  React.useEffect(() => {
    fetchData({ current: 1 });
    fetchTerminals('terminal_id');
    fetchTerminals('pod_terminal_id');
    fetchScacCodes();
  }, [activeKey]);

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const handelCloseBulkUpdate = () => {
    setSelectedRowKeys([]);
    setOpenBulkUpdate(false);
  };

  const handleSaveBulkUpdate = async () => {
    fetchData(pagination);
    handelCloseBulkUpdate();
  };

  return (
    <div className={styles.main}>
      <Header title="Tracking" />

      <NavTabs
        items={statesCount}
        activeKey={activeKey}
        onChange={handleTabChange}
      />

      <Filter
        loading={loading}
        filter={filter}
        statesCount={statesCount}
        onSearch={handleSearch}
        terminals={terminals || []}
        podTerminals={podTerminals || []}
        scacCodes={scacCodes || []}
        fetchRelatedTerminals={fetchRelatedTerminals}
        fetchTerminals={fetchTerminals}
        handleBulkUpdate={handleBulkUpdate}
      />
      <div>
        <AutoResizeTable
          loading={loading}
          pagination={pagination}
          bordered
          size="small"
          rowKey="id"
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          rowClassName={(record: any) => [
            record.id == activeRowId && 'rowActive',
          ]}
          onRow={(record: any, rowIndex: any) => {
            return {
              onClick: (event) => {
                setActiveRowId(record?.id || 0);
              },
            };
          }}
          columns={columns}
          dataSource={data?.data}
          onChange={(pagination, _2, sorter: any) => {
            const sort_value = sorter.order === 'ascend' ? 'asc' : 'desc';
            fetchData(pagination, {
              sort_by: sorter.field,
              sort_value,
            });
            filter.setFieldsValue({
              sort_by: sorter.field,
              sort_value,
            });
          }}
          scroll={{
            x: 1500,
          }}
          sticky
        />
      </div>
      <Drawer
        title=""
        placement={'right'}
        width="80%"
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <DrawerForm form={form} />
      </Drawer>

      <DeliveryOrderDrawer
        doForm={doForm}
        showDoForm={showDoForm && deliveryOrder}
        setShowDoForm={setShowDoForm}
        selectedCntrs={[]}
        deliveryOrder={deliveryOrder}
      />

      {openBulkUpdate && selectedRows.length > 0 && (
        <DrawerFormBulkUpdate
          containers={selectedRows}
          open={openBulkUpdate}
          onClose={handelCloseBulkUpdate}
          onSubmit={handleSaveBulkUpdate}
        />
      )}

      {showOrderForm && orderId && (
        <OrderDrawer
          visible={showOrderForm}
          setVisible={setShowOrderForm}
          orderId={orderId}
          refreshOrder={fetchData}
        />
      )}
    </div>
  );
};

export default Index;
