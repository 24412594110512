import React from 'react';
import { Modal } from 'antd';
import ContactManager from '@/pages/truckload/components/ContactManager';
import { ContactInfo } from '@/components/Tasks/Interfaces/ContactInfoInterface';

interface CarrierContactModalProps {
  visible: boolean;
  onClose: () => void;
  carrier: {
    id?: number;
    name: string;
    contacts?: ContactInfo[];
  } | null;
}

const DirectCarrierContactModal: React.FC<CarrierContactModalProps> = ({
  visible,
  onClose,
  carrier,
}) => {
  return (
    <Modal
      title={`${carrier?.name || 'Carrier'} Contact Information`}
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
      maskClosable={true}
      destroyOnClose
    >
      {carrier?.contacts && carrier.contacts.length > 0 ? (
        <ContactManager
          contacts={carrier.contacts}
          onChange={() => {
            console.log('onChange');
          }}
          readOnly={true}
        />
      ) : (
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          No contact information available
        </div>
      )}
    </Modal>
  );
};

export default DirectCarrierContactModal;
