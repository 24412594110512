import {
  Button,
  Col,
  DatePicker,
  Drawer,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Checkbox,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import type { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { EditableSelect, EditableText } from '@/components/Editable';
import { useForm } from 'antd/lib/form/Form';
import { useApp } from '@/utils/useapp';
// import { ContainerForm } from '../ContainerForm';
import { DrawerForm as ContainerForm } from '@/pages/containers/components/DrawerForm';
import { each, has, get, set } from 'lodash';
import { WarehouseSelect } from '@/components/WarehouseSelect';
import { TerminalSelect } from '@/components/TerminalSelect';
// import { DrawerForm as VendorForm } from '@/pages/entity/vendors/components/DrawerForm';
import { VendorDrawer } from '@/pages/entity/vendors/components/VendorDrawer';
import { DeliveryOrderDrawer, TSendOptions } from '../DeliveryOrderDrawer';
import { TerminalRedirectLink } from '@/components/TerminalRedirectLink';
import {
  DISPATCH_AP_STATUS_OPTIONS,
  DISPATCH_AR_STATUS_OPTIONS,
  DISPATCH_STATUS_OPTIONS,
  DISPATCH_ACTION_V2_OPTIONS,
} from '@/pages/dispatchs/components/data';
import { showErrorMessage } from '@/utils/show-error-message';

export const TruckingModal: React.FC<{
  isModalVisible: boolean;
  showContainers?: boolean;
  order: any;
  container?: any;
  selectedContainers?: any[];
  sendOptions?: TSendOptions;
  //handleContainerChange: (id: number, value: any) => void;
  //handleDoChange: (id: number, value: any) => void;
  onCancel: () => void;
  refreshOrder?: (id: number) => void;
  refreshContainer?: (id: number) => void;
}> = ({
  sendOptions = {},
  isModalVisible = false,
  showContainers = true,
  selectedContainers,
  order,
  container,
  onCancel,
  refreshOrder,
  refreshContainer,
}) => {
  const [showCntrForm, setShowCntrForm] = React.useState(false);
  const [showDoForm, setShowDoForm] = React.useState(false);
  const [truckerId, setTruckerId] = React.useState(0);

  const [loading, setLoading] = useState(false);
  const [selectedCntrs, setSelectedCntrs] = useState<any[]>(
    selectedContainers || get(order, 'containers', []),
  );

  const [cntrForm] = useForm();
  const [doForm] = useForm();
  const [truckerForm] = useForm();

  const [deliveryOrder, setDeliveryOrder] = useState<any>();

  const [deliveryOrders, setDeliveryOrders] = useState<any>();

  const [showWarning, setShowWarning] = useState(false);
  const [checkWarning, setCheckWarning] = useState(false);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (!container) {
      return setShowWarning(false);
    }

    if (
      deliveryOrders &&
      deliveryOrders.find((doItem: any) => doItem.confirmed_at)
    ) {
      return setShowWarning(false);
    }

    checkShowWarning();
  }, [deliveryOrders, container, open]);

  // const deliveryOrders = useMemo(() => {
  //   return [...get(order, 'delivery_orders', [])];
  // }, [order]);

  const checkShowWarning = async () => {
    if (!container || checkWarning || !open) {
      return;
    }
    setCheckWarning(true);
    try {
      const resp = await app.service.get(
        `containers/${container.id}/checkDoWarning`,
      );
      setShowWarning(resp.show_warning);
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setCheckWarning(false);
    }
  };

  const fetchData = async () => {
    const uri = container
      ? `deliveryOrders/container/${container.id}`
      : `deliveryOrders/order/${order.id}`;

    setLoading(true);
    try {
      const resp = await app.service.get(uri);
      setDeliveryOrders(resp.data);
      return resp;
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(true);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const app = useApp();

  const onClose = () => {
    setShowCntrForm(false);
    setTruckerId(0);
    cntrForm.resetFields();
    doForm.resetFields();
    truckerForm.resetFields();
    setDeliveryOrder(null);
  };

  //  container handlers
  const onContainerChange = (id: number, value: any) => {
    handleContainerChange(id, value);
  };

  const toRefreshOrder = async (id: number) => {
    if (typeof refreshOrder === 'function') {
      await refreshOrder(id);
    }
  };

  const toRefreshContainer = async (id: number) => {
    if (typeof refreshContainer === 'function') {
      await refreshContainer(id);
    }
  };

  const handleContainerChange = async (id: number, values: any) => {
    setLoading(true);
    try {
      const resp = await app.service.patch(`containers/${id}`, {
        data: values,
      });
      await toRefreshOrder(resp.data.order_id);
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const onDoChange = (id: number, value: any) => {
    handleDoChange(id, value);
  };

  const handleDoChange = async (id: number, values: any) => {
    setLoading(true);
    try {
      // if (!data) {
      //   return;
      // }
      const resp = await app.service.patch(`deliveryOrders/${id}`, {
        data: values,
      });
      const _order = await app.service.get(`orders/${resp.data.order_id}`);
      // const collection = data.data.map((item) => {
      //   if (item.id === _order.data.id) {
      //     return _order.data;
      //   }
      //   return item;
      // });
      //setData({ ...data, data: collection });
      await toRefreshOrder(resp.data.order_id);
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };
  const handleEditCntr = (id: number) => {
    const cntr = order.containers.find((c: any) => c.id === id);
    if (cntr) {
      cntrForm.setFieldsValue({
        ...cntr,
      });
    }

    setShowCntrForm(true);
  };

  const handleAddCntr = () => {
    cntrForm.setFieldsValue({
      chassis_owner: order.chassis_owner,
    });

    setShowCntrForm(true);
  };

  const handleSaveCntr = async () => {
    let data;
    try {
      data = await cntrForm.validateFields();
    } catch {
      return;
    }

    setLoading(true);
    try {
      if (data.outside_terminal_exam_site_terminal) {
        data.outside_terminal_exam_site_terminal =
          data.outside_terminal_exam_site_terminal.id;
      }
      delete data.outside_terminal_exam_site_terminal;
      const resp = !data.id
        ? await app.service.post('containers', {
            data: { ...data, order_id: order.id },
          })
        : await app.service.put(`containers/${data.id}`, {
            data: { ...data },
          });
      message.success('Saved');
      await toRefreshOrder(resp.data.order_id);
      onClose();
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeteteCntr = async (id: number) => {
    setLoading(true);
    try {
      await app.service.delete(`containers/${id}`);
      message.success('Deleted');
      await toRefreshOrder(order.id);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const cntrSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedCntrs(selectedRows);
    },
  };

  const copyToClipboard = (textToCopy: string) => {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  };

  const handleCopyContainers = async () => {
    const containers = order.containers;
    let copyText = '';
    each(containers, (container) => {
      copyText += `${container.number} `;
    });
    try {
      await copyToClipboard(copyText);
      message.success('Copied');
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  // end container handlers

  // delivery order handlers

  const handleAddNewDo = () => {
    setDeliveryOrder(null);
    setShowDoForm(true);
  };

  const handleEditDo = async (id: number) => {
    try {
      const resp = await app.service.get(`deliveryOrders/${id}`);
      doForm.setFieldsValue({
        ...resp.data,
        containers: resp.data.containers,
      });
      setDeliveryOrder(resp.data);
      setShowDoForm(true);
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  // trucker handlers
  const handleEditTrucker = async (id: number) => {
    if (!id) {
      return;
    }
    try {
      setTruckerId(id);
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  const handleConfirmed = async (id: number, checked: boolean) => {
    try {
      await app.service.patch(`deliveryOrders/${id}/confirmed`, {
        data: {
          isConfirmed: checked,
          containerId: container?.id ?? 0,
        },
      });

      fetchData();
      container?.id && toRefreshContainer(container.id);
      order?.id && toRefreshOrder(order.id);
      checked && setShowDoForm(false);
    } catch (err: any) {
      showErrorMessage(err);
    }
  };
  // end trucker handlers

  useEffect(() => {
    (order || container) && fetchData();
  }, [order, container]);

  // container form
  const cntrColumns: ColumnsType<any> = [
    {
      title: () => (
        <Space direction="horizontal">
          CNTR#{' '}
          <Button
            type="primary"
            onClick={() => handleCopyContainers()}
            size="small"
          >
            Copy All
          </Button>
        </Space>
      ),
      dataIndex: 'number',
      key: 'number',
      width: 150,
      // render: (text, record) => (
      //   <a onClick={() => handleEditCntr(record.id)}>{record.number}</a>
      // ),
    },
    {
      title: 'Dispatch Status',
      dataIndex: 'dispatch_status',
      key: 'dispatch_status',
      width: 180,
      render: (text, record) => (
        <>
          {DISPATCH_ACTION_V2_OPTIONS.find((d) => d.value == text)?.label}
          {/* <EditableSelect
            value={record.dispatch_status}
            options={DISPATCH_STATUS_OPTIONS}
            name="dispatch_status"
            onChange={(s) =>
              onContainerChange(record.id, { dispatch_status: s })
            }
          /> */}
        </>
      ),
    },
    {
      title: 'AR Status',
      dataIndex: 'ar_status',
      key: 'ar_status',
      width: 130,
      render: (text, record) => (
        <>
          {DISPATCH_AR_STATUS_OPTIONS.find((ar) => ar.value == text)?.label}
          {/* <EditableSelect
            value={record.ar_status}
            options={DISPATCH_AR_STATUS_OPTIONS}
            name="ar_status"
            onChange={(s) => onContainerChange(record.id, { ar_status: s })}
          /> */}
        </>
      ),
    },
    {
      title: 'AP Status',
      dataIndex: 'ap_status',
      key: 'ap_status',
      width: 130,
      render: (text, record) => (
        <>
          {DISPATCH_AP_STATUS_OPTIONS.find((ap) => ap.value == text)?.label}

          {/* <EditableSelect
            value={record.ap_status}
            options={DISPATCH_AP_STATUS_OPTIONS}
            name="ap_status"
            onChange={(s) => onContainerChange(record.id, { ap_status: s })}
          /> */}
        </>
      ),
    },
    {
      title: 'Freight Rls',
      dataIndex: 'freight_released_at',
      key: 'freight_released_at',
      width: 200,
      // render: (text, record) => (
      //   <>

      //     <DatePicker
      //       value={
      //         record.freight_released_at
      //           ? moment(record.freight_released_at)
      //           : null
      //       }
      //       onChange={(date, dateString) =>
      //         onContainerChange(record.id, { freight_released_at: dateString })
      //       }
      //     />
      //   </>
      // ),
    },
    {
      title: 'Custom Rls',
      dataIndex: 'customs_released_at',
      key: 'customs_released_at',
      width: 200,
      // render: (text, record) => (
      //   <>
      //     <DatePicker
      //       value={
      //         record.customs_released_at
      //           ? moment(record.customs_released_at)
      //           : null
      //       }
      //       onChange={(date, dateString) =>
      //         onContainerChange(record.id, { customs_released_at: dateString })
      //       }
      //     />
      //   </>
      // ),
    },
    {
      title: 'Terminal Rls',
      dataIndex: 'terminal_released_at',
      key: 'terminal_released_at',
      width: 200,
      // render: (text, record) => (
      //   <>
      //     <DatePicker
      //       value={
      //         record.terminal_released_at
      //           ? moment(record.terminal_released_at)
      //           : null
      //       }
      //       onChange={(date, dateString) =>
      //         onContainerChange(record.id, { terminal_released_at: dateString })
      //       }
      //     />
      //   </>
      // ),
    },
    {
      title: 'LFD',
      dataIndex: 'lfd',
      key: 'lfd',
      width: 200,
      // render: (text, record) => (
      //   <>
      //     <DatePicker
      //       value={record.lfd ? moment(record.lfd) : null}
      //       onChange={(date, dateString) =>
      //         onContainerChange(record.id, { lfd: dateString })
      //       }
      //     />
      //   </>
      // ),
    },
    {
      title: 'PU#',
      dataIndex: 'pickup_number',
      key: 'pickup_number',
      width: 200,
      // render: (text, record) => (
      //   <>
      //     <EditableText
      //       name="pickup_number"
      //       value={record.pickup_number || ''}
      //       onChange={(name: string, value: any) =>
      //         onContainerChange(record.id, { [name]: value })
      //       }
      //     />
      //   </>
      // ),
    },
    {
      title: 'Pickup Date',
      dataIndex: 'actual_terminal_pickuped_at',
      key: 'actual_terminal_pickuped_at',
      width: 200,
      // render: (text, record) => (
      //   <>
      //     <DatePicker
      //       value={
      //         record.actual_terminal_pickuped_at
      //           ? moment(record.actual_terminal_pickuped_at)
      //           : null
      //       }
      //       onChange={(date, dateString) =>
      //         onContainerChange(record.id, {
      //           actual_terminal_pickuped_at: dateString,
      //         })
      //       }
      //     />
      //   </>
      // ),
    },
    {
      title: 'Return Date',
      dataIndex: 'actual_empty_returned_at',
      key: 'actual_empty_returned_at',
      width: 200,
      // render: (text, record) => (
      //   <>
      //     <DatePicker
      //       value={
      //         record.actual_empty_returned_at
      //           ? moment(record.actual_empty_returned_at)
      //           : null
      //       }
      //       onChange={(date, dateString) =>
      //         onContainerChange(record.id, {
      //           actual_empty_returned_at: dateString,
      //         })
      //       }
      //     />
      //   </>
      // ),
    },
    {
      title: 'Chassis Usage',
      dataIndex: 'chassis_usage',
      key: 'chassis_usage',
      width: 150,
    },
    {
      title: 'Count',
      dataIndex: 'package',
      key: 'package',
      width: 200,
    },
    {
      title: 'weight',
      dataIndex: 'weight',
      key: 'weight',
      width: 150,
      render: (text, record) => <>{record.weight}KG</>,
    },
    {
      title: 'REF#',
      dataIndex: 'delivery_reference',
      key: 'delivery_reference',
      width: 300,
      // render: (text, record) => (
      //   <>
      //     <EditableText
      //       name="delivery_reference"
      //       value={record.delivery_reference || ''}
      //       onChange={(name: string, value: any) =>
      //         onContainerChange(record.id, { [name]: value })
      //       }
      //     />
      //   </>
      // ),
    },
    // {
    //   align: 'center',
    //   title: () => (
    //     <Space>
    //       Action <PlusCircleOutlined onClick={handleAddCntr} />
    //     </Space>
    //   ),
    //   key: 'action',
    //   fixed: 'right',
    //   width: 120,
    //   render: (text, record) => (
    //     <span>
    //       <a onClick={() => handleDeteteCntr(record.id)}>Delete</a>
    //     </span>
    //   ),
    // },
  ];

  // delivery order form
  const doColumns: ColumnsType<any> = [
    {
      title: () => (
        <>
          DO#{' '}
          <Button type="primary" onClick={handleAddNewDo} size="small">
            New DO
          </Button>
        </>
      ),
      dataIndex: 'id',
      key: 'id',
      width: 150,
      render: (text, record) => (
        <>
          <Space>
            <a onClick={() => handleEditDo(record.id)}>{record.id}</a>
            {get(record, 'canceled_at', null) && (
              <Tooltip title={get(record, 'canceled_reason', '')}>
                <Tag color="default">Canceled</Tag>
              </Tooltip>
            )}
          </Space>
        </>
      ),
    },
    {
      title: () => (
        <Space>
          <span>Confirm</span>
          <Tooltip title="Only one delivery order can be confirmed">
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'confirmed_at',
      key: 'confirmed_at',
      width: 100,
      render: (text, record) => (
        <Checkbox
          checked={get(record, 'confirmed_at', false)}
          onChange={(e) => handleConfirmed(record.id, e.target.checked)}
        />
      ),
    },
    {
      title: 'Trucker Info',
      dataIndex: 'trucker_info',
      key: 'trucker_info',
      width: 300,
      render: (text, record) => (
        <>
          {get(record, 'vendor.name')}{' '}
          <Tag
            color="processing"
            onClick={() => handleEditTrucker(get(record, 'vendor.id'))}
          >
            View Detail
          </Tag>
        </>
      ),
    },
    {
      // title: () => (
      //   <>
      //     Freight.L <Tag color="processing">Direct</Tag>
      //   </>
      // ),
      title: 'Freight.L',
      dataIndex: 'freight_released_at',
      key: 'freight_released_at',
      width: 200,
      render: (text, record) => (
        <Row align="middle">
          <Col span={22}>
            <TerminalSelect
              className="w100"
              selected={record.terminal}
              onSelect={(value: any) =>
                onDoChange(record.id, { terminal_id: value.id })
              }
            />
          </Col>
          <Col span={1} offset={1}>
            {!!record.terminal_id && (
              <TerminalRedirectLink id={record.terminal_id} />
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: 'Delivery to',
      dataIndex: 'delivery_to',
      key: 'delivery_to',
      width: 300,
      render: (text, record) => (
        <>
          <WarehouseSelect
            className="w100"
            selected={record.warehouse}
            onSelect={(value: any) =>
              onDoChange(record.id, { warehouse_id: value.id })
            }
          />
        </>
      ),
    },
    {
      title: 'Sent At',
      dataIndex: 'sent_email',
      width: 240,
      key: 'sent_at',
      render: (text, record) => (
        <>
          <div>{get(record, 'sent_do_at')}</div>
          <div>{get(record, 'sender.name')}</div>
        </>
      ),
    },
    {
      title: 'Cancel Sent At',
      dataIndex: 'cancel_sent_at',
      width: 240,
      key: 'cancel_sent_at',
      render: (text, record) => {
        if (!has(record, 'cancel_sender')) {
          return <></>;
        }
        return (
          <>
            <div>{get(record, 'canceled_at')}</div>
            <div>{get(record, 'cancel_sender.name')}</div>
          </>
        );
      },
    },
  ];

  // const deliveryOrders = useMemo(() => {
  //   return filter(
  //     [...get(order, 'delivery_orders', [])],
  //     (o) => o.state != 'canceled',
  //   );
  // }, [order]);

  return (
    <>
      <Modal
        destroyOnClose
        cancelText="Close"
        footer={
          <>
            <Button onClick={handleCancel}>Close</Button>
          </>
        }
        title="Information"
        width="80vw"
        open={isModalVisible}
        onCancel={handleCancel}
      >
        {showContainers && (
          <div>
            <Table
              rowSelection={{
                type: 'checkbox',
                ...cntrSelection,
              }}
              size="small"
              pagination={false}
              rowKey="id"
              columns={cntrColumns}
              dataSource={order.containers || []}
              scroll={{
                x: 1500,
              }}
            />
          </div>
        )}
        <div className="mt-lg">
          {showWarning && (
            <Tag color="red">
              DO has been sent but the trucker is not confirmed in our system,
              please check.{' '}
            </Tag>
          )}
          <Table
            size="small"
            pagination={false}
            rowKey="id"
            columns={doColumns}
            dataSource={deliveryOrders}
            scroll={{
              x: 1000,
            }}
          />
        </div>
      </Modal>
      <Drawer
        zIndex={1001}
        title="New Delivery Order"
        placement={'right'}
        width={'60%'}
        onClose={onClose}
        destroyOnClose={true}
        open={showCntrForm}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSaveCntr}>
              Save
            </Button>
          </Space>
        }
      >
        <ContainerForm form={cntrForm} />
      </Drawer>
      {showDoForm && (
        <DeliveryOrderDrawer
          doForm={doForm}
          showDoForm={showDoForm}
          setShowDoForm={setShowDoForm}
          selectedCntrs={selectedCntrs}
          refreshOrder={toRefreshOrder}
          deliveryOrder={deliveryOrder}
          sendOptions={sendOptions}
          order={order}
        />
      )}
      {
        <VendorDrawer
          id={truckerId}
          onSaved={() => toRefreshOrder(order.id)}
          onClose={() => setTruckerId(0)}
          visible={!!truckerId}
        />
      }
    </>
  );
};
