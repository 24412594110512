import { Button, Drawer, message, Modal, Space, Typography } from 'antd';
import ShipmentForm from './ShipmentForm';
import { ShipmentBusinessStatus, ShipmentFromType } from '../constants';
import { FC, useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { ShipmentParams } from './QuoteForm';
import { isEqual, omit } from 'lodash';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CopyButton } from '@/components/CopyButton';
import { ShipmentInterface } from '../shipments/Interfaces/ShipmentInterface';
import copy from 'copy-to-clipboard';
import { showErrorMessage } from '@/utils/show-error-message';

export interface ShipmentDrawerProps {
  visible: boolean;
  model?: any;
  params?: ShipmentParams | null;
  onSaved?: (id: number, close?: boolean) => void;
  onBooked?: (id: number) => void;
  onClose: () => void;
  refreshShipment?: (id: number) => Promise<void>;
  refreshShipments?: () => void;
}

const ShipmentDrawer: FC<ShipmentDrawerProps> = ({
  visible,
  model,
  params,
  onSaved,
  onBooked,
  onClose,
  refreshShipment,
  refreshShipments,
}) => {
  const manualMode = !params;
  const id = model?.id;
  const order_number = model?.order_number;
  const rate = params?.rate;
  const [loading, setLoading] = useState(false);
  const app = useApp();
  const [form] = useForm();
  const [formInitialValues, setFormInitialValues] = useState<any>(null);

  const [innerModel, setInnerModel] = useState<any>(model);

  useEffect(() => {
    setInnerModel(model);
  }, [model]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let resp = null;
      if (id) {
        resp = await app.service.get(`tl/ltl/shipments/${id}`);
        setInnerModel(model);
      } else {
        resp = await app.service.get(`tl/ltl/shipments?query=${order_number}`);
        setInnerModel(resp.data[0]);
        resp = await app.service.get(`tl/ltl/shipments/${resp.data[0].id}`);
      }

      const data = {
        ...resp.data,
        pickup_accessorials: resp.data.pickup_accessorials ?? [],
        destination_accessorials: resp.data.destination_accessorials ?? [],
        pickup_open_time: moment(resp.data.pickup_open_time, 'HH:mm'),
        pickup_close_time: moment(resp.data.pickup_close_time, 'HH:mm'),
        destination_open_time: moment(resp.data.destination_open_time, 'HH:mm'),
        destination_close_time: moment(
          resp.data.destination_close_time,
          'HH:mm',
        ),
      };
      form.setFieldsValue({ ...data });
      // 读取表单数据并设置为初始值
      // 避免设置表单外的值，进而导致后续判断是否修改不准确
      setFormInitialValues({ ...form.getFieldsValue() });
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handelSave = async (close = true) => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    if (values.is_insurance_entrusted && !values.cargo_value) {
      message.error('Please enter cargo value');
      return;
    } else if (!values.is_insurance_entrusted) {
      delete values.cargo_value;
    }

    setLoading(true);

    let resp = null;
    try {
      if (id) {
        resp = await app.service.patch(`tl/ltl/shipments/${id}`, {
          data: {
            ...values,
            pickup_open_time: moment(values.pickup_open_time).format('HH:mm'),
            pickup_close_time: moment(values.pickup_close_time).format('HH:mm'),
            destination_open_time: moment(values.destination_open_time).format(
              'HH:mm',
            ),
            destination_close_time: moment(
              values.destination_close_time,
            ).format('HH:mm'),
          },
        });
      } else {
        const addtion = manualMode
          ? {
              from_type: ShipmentFromType.MANUAL,
            }
          : {
              from_type: ShipmentFromType.QUOTE,
              quote_id: rate?.quote_id,
              rate_id: rate?.id,
              vendor_rate_id: rate?.vendor_rate_id,
            };
        resp = await app.service.post('tl/ltl/shipments', {
          data: {
            ...(manualMode ? values : omit(values, ['quote_rate'])),
            ...addtion,
            pickup_open_time: moment(values.pickup_open_time).format('HH:mm'),
            pickup_close_time: moment(values.pickup_close_time).format('HH:mm'),
            destination_open_time: moment(values.destination_open_time).format(
              'HH:mm',
            ),
            destination_close_time: moment(
              values.destination_close_time,
            ).format('HH:mm'),
          },
        });
      }

      onSaved && onSaved(resp?.data?.id, close);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmBook = async () => {
    setLoading(true);
    try {
      await app.service.patch(`tl/ltl/shipments/${id}/confirm-book`);
      fetchData();
      onBooked && onBooked(id);
      message.success('Shipment has been booked successfully');
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };
  const handleBook = () => {
    const isFormChanged = !isEqual(formInitialValues, form.getFieldsValue());
    if (!isFormChanged) {
      handleConfirmBook();
    } else {
      Modal.confirm({
        content:
          'The form has been changed. Are you sure you want to save and book?',
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          handelSave(false).then(() => handleConfirmBook());
        },
      });
    }
  };

  useEffect(() => {
    // 1. 设置 model 并打开 drawer，visible 为 truthy => 触发 useEffect 一次，设置 form 数据
    // 2. 关闭 drawer，visible 为 falsy =>
    //    resetFields 后，model / id 仍然有值，
    //    if block 外后续逻辑会继续执行，设置 form 数据 =>
    //    数据回显错误

    if (!visible) {
      form.resetFields();
      // 3. 以下 return 可以阻止后续逻辑执行，避免数据设置错误
      return;
    }

    if (id || order_number) {
      fetchData();
    } else {
      form.setFieldsValue({
        is_insurance_entrusted: true,
      });
      // 读取表单数据并设置为初始值
      // 避免设置表单外的值，进而导致后续判断是否修改不准确
      setFormInitialValues({ ...form.getFieldsValue() });
    }
  }, [id, visible]);

  const handleCopyInfo = (model: ShipmentInterface) => {
    const shipmentText = `
DE运单号: ${model.uid}
Customer Ref: ${model.customer_reference_number}
Pickup Number: ${model.pickup_number || '-'}
Dropoff Number: ${model.dropoff_number || '-'}
--------------------------------------------------------
提货信息:
${model.pickup_company_name}(${model.pickup_contact_email}, ${
      model.pickup_contact_phone
    } ${
      model.pickup_contact_phone_ext
        ? `- ${model.pickup_contact_phone_ext}`
        : ''
    })
${model.pickup_address1}${
      model.pickup_address2 ? `, ${model.pickup_address2}` : ''
    }, ${model.pickup_city}, ${model.pickup_state}, ${model.pickup_zipcode}
Address Type: ${model.pickup_address_type || '-'}
Accessorial: ${(model.pickup_accessorials || [])
      .map((accessorial) => accessorial)
      .join(', ')}
--------------------------------------------------------
收货信息:
${model.destination_company_name}(${model.destination_contact_email}, ${
      model.destination_contact_phone
    } ${
      model.destination_contact_phone_ext
        ? `- ${model.destination_contact_phone_ext}`
        : ''
    })
${model.destination_address1}${
      model.destination_address2 ? `, ${model.destination_address2}` : ''
    }, ${model.destination_city}, ${model.destination_state}, ${
      model.destination_zipcode
    }
Address Type: ${model.destination_address_type || '-'}
Accessorial: ${(model.destination_accessorials || [])
      .map((accessorial) => accessorial)
      .join(', ')}
--------------------------------------------------------
货物信息：
    ${model.items
      .map(
        (item, index) =>
          `Item${index + 1}: ${item.description} ${item.units} ${
            item.package_type
          }, 尺寸: ${item.length}*${item.width}*${item.height} ${
            item.dimension_unit
          }, 重量：${item.total_weight} ${item.weight_unit}`,
      )
      .join('\n')}`;

    if (copy(shipmentText)) {
      message.success('Copied');
    } else {
      message.error('Copy failed');
    }
  };

  return (
    <Drawer
      title={
        innerModel?.uid ? (
          <>
            <Space>
              Shipment {innerModel?.uid}
              <CopyButton value={innerModel?.uid} />
              <Typography.Link onClick={() => handleCopyInfo(innerModel)}>
                Info Copy
              </Typography.Link>
            </Space>
          </>
        ) : (
          'New Shipment'
        )
      }
      placement="right"
      width="90%"
      onClose={onClose}
      destroyOnClose={true}
      open={visible}
      extra={
        manualMode ? (
          <Space>
            <Button
              type="primary"
              loading={loading}
              onClick={() => handelSave()}
            >
              Save
            </Button>
            {!innerModel?.vendor_shipment_id &&
              innerModel?.business_status ===
                ShipmentBusinessStatus.CONFIRMING &&
              (innerModel?.from_type === ShipmentFromType.QUOTE ||
                innerModel?.from_type === ShipmentFromType.CLIENT_QUOTE) && (
                <Button
                  loading={loading}
                  type="primary"
                  onClick={() => innerModel && handleBook()}
                >
                  Book
                </Button>
              )}
            <Button onClick={onClose}>Close</Button>
          </Space>
        ) : (
          <Space>
            <Button
              type="primary"
              loading={loading}
              onClick={() => handelSave()}
            >
              Save
            </Button>
            <Button onClick={onClose}>Close</Button>
          </Space>
        )
      }
    >
      <ShipmentForm
        loading={loading}
        form={form}
        params={params}
        model={innerModel}
        disabled={!model?.id && !!model?.order_number}
        refreshShipment={refreshShipment}
        refreshShipments={refreshShipments}
      />
    </Drawer>
  );
};

export default ShipmentDrawer;
