import { useApp } from '@/utils/useapp';
import {
  Space,
  Button,
  message,
  Spin,
  Input,
  Tooltip,
  MenuProps,
  Dropdown,
  Row,
  Col,
  Select,
} from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import styles from 'res/css/ui.scss';
import usePagination from '@/components/usePagination';
import { TCollection } from '@/types';
import ShipmentDrawer from '../components/ShipmentDrawer';
import { HiRefresh } from 'react-icons/hi';
import { ShipmentBusinessStatus, ShipmentCustomerStatus } from '../constants';
import DraggableTabs from '@/components/DraggbleTabs';
import { FaPlus } from 'react-icons/fa';
import { TDispatchTab } from '@/pages/dispatchs';
import { EditableText } from '@/components/Editable';
import { useHistory, useLocation } from 'umi';
import { convertFilterValuesToQueryParams } from '@/pages/dispatchs/components/Filter';
import { Filter, toFilterValue } from '../components/Filter';
import update from 'immutability-helper';
import { ManageColumns } from '@/pages/dispatchs/components/ManageColumns';
import {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from 'antd/lib/table/interface';
import { FTLShipmentInterface } from './Interfaces/ShipmentInterface';
import { CACHE_FTL_COLOR_TAG_KEY } from '@/stores/cache';
import ColorTagSelect from '@/components/ColorTagSelect';
import ShipmentsTable from './ShipmentTable';
import { debounce } from 'lodash';
import ShipmentBulkUpdate from './ShipmentTable/ShipmentBulkUpdate';
import FileSaver from 'file-saver';
import { showErrorMessage } from '@/utils/show-error-message';

const SORT_MAP = {
  ascend: 'asc',
  descend: 'desc',
};

const sortOrder = (
  sort: { attribute: string; value: string },
  attribute: string,
) => {
  if (!sort) {
    return null;
  }

  const sortAttributeAndValue = sort.value.split('|');

  if (sortAttributeAndValue[0] != attribute) {
    return null;
  }

  if (sortAttributeAndValue[1] == 'asc') {
    return 'ascend';
  }

  if (sortAttributeAndValue[1] == 'desc') {
    return 'descend';
  }
};

const toFilterSortValue = (
  label: string,
  sortBy: string,
  sortValue: string,
) => {
  const flag = sortValue.toUpperCase();
  return toFilterValue(`${label} ${flag}`, 'sort', `${sortBy}|${sortValue}`);
};

export const CUSTOMER_STATUS_OPTIONS = [
  { value: ShipmentCustomerStatus.CONFIRMING, label: 'Order reviewing' },
  { value: ShipmentCustomerStatus.ASSIGNING_DRIVER, label: 'Assigning Driver' },
  { value: ShipmentCustomerStatus.PENDING_PICKUP, label: 'Pending Pickup' },
  {
    value: ShipmentCustomerStatus.IN_TRANSIT,
    label: 'In Transit',
  },
  {
    value: ShipmentCustomerStatus.DELIVERED,
    label: 'Delivered',
  },
  {
    value: ShipmentCustomerStatus.COMPLETED,
    label: 'Completed',
  },
  {
    value: ShipmentCustomerStatus.CANCELED,
    label: 'Canceled',
  },
  {
    value: ShipmentCustomerStatus.REJECT,
    label: 'Reject',
  },
];

export const BUSINESS_STATUS_OPTIONS = [
  { value: ShipmentBusinessStatus.CONFIRMING, label: 'Order reviewing' },
  { value: ShipmentBusinessStatus.ASSIGNING_DRIVER, label: 'Assigning Driver' },
  { value: ShipmentBusinessStatus.PENDING_PICKUP, label: 'Pending Pickup' },
  {
    value: ShipmentBusinessStatus.IN_TRANSIT,
    label: 'In Transit',
  },
  {
    value: ShipmentBusinessStatus.DELIVERED,
    label: 'Delivered',
  },
  {
    value: ShipmentBusinessStatus.OP_CLOSE,
    label: 'OP Closed',
  },
  {
    value: ShipmentBusinessStatus.TO_BE_CANCEL,
    label: 'To be canceled',
  },
  {
    value: ShipmentBusinessStatus.CANCELED,
    label: 'Canceled',
  },
  {
    value: ShipmentBusinessStatus.REJECT,
    label: 'Reject',
  },
];

const SEARCHQUERYTYPES = [
  {
    value: '',
    label: 'Default Search',
    placeholder: 'Search by LTL ID/Pro Number#/Reference#',
  },
  {
    value: 'pu',
    label: 'PU Facility Search',
    placeholder: 'Address, State, zip, contact, phone, email, company name',
  },
  {
    value: 'del',
    label: 'DEL Facility Search',
    placeholder: 'Address, State, zip, contact, phone, email, company name',
  },
];

const Shipments: React.FC = () => {
  const app = useApp();
  useEffect(() => {
    app.store.cache.fetch(CACHE_FTL_COLOR_TAG_KEY, true);
  }, []);

  const searchRef = useRef<string>('');

  const [data, setData] = useState<TCollection<FTLShipmentInterface>>();
  const [shipformVisible, setShipformVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<FTLShipmentInterface>();
  const [openId, setOpenId] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryInput, setSearchQueryInput] = useState('');
  const [searchQueryType, setSearchQueryType] = useState(
    SEARCHQUERYTYPES[0].value,
  );
  const [activeKey, setActiveKey] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [tabs, setTabs] = useState<TDispatchTab[]>([]);
  const [order, setOrder] = useState<string[]>([]);
  const [editTab, setEditTab] = useState<number>(0);
  const [tabCounts, setTabCounts] = useState<any>({});
  const [filterValues, setFilterValues] = useState<any[] | null>(null);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [defaultColumns, setDefaultColumns] = useState<any[]>([]);

  const location = useLocation();
  const pagination = usePagination(data);

  const fetchData = useCallback(
    async (
      _pagination?: TablePaginationConfig,
      _filters?: any,
      isEmptyQuery?: boolean,
      sorter?:
        | SorterResult<FTLShipmentInterface>
        | SorterResult<FTLShipmentInterface>[],
      query?: string,
    ) => {
      setLoading(true);
      _filters = _filters || filterValues || [];
      const sort = _filters?.find((f: any) => f.attribute === 'sort') || null;
      const params =
        (query || searchQuery) && !isEmptyQuery
          ? {
              query: query || searchQuery,
              query_type: searchQueryType,
              ...convertFilterValuesToQueryParams([sort || {}]),
            }
          : convertFilterValuesToQueryParams(_filters);

      try {
        const resp = await app.service.get('ftl/shipments', {
          params: {
            ...params,
            page: _pagination ? _pagination.current : pagination.current,
            per_page: _pagination ? _pagination.pageSize : pagination.pageSize,
            sort_by: Array.isArray(sorter)
              ? sort?.value?.split('|')?.[0]
              : sorter?.columnKey ||
                sorter?.field ||
                sort?.value?.split('|')?.[0],
            sort_value: Array.isArray(sorter)
              ? sort?.value?.split('|')?.[1]
              : sorter?.order === 'ascend'
              ? 'asc'
              : sorter?.order === 'descend'
              ? 'desc'
              : sort?.value?.split('|')?.[1],
          },
        });
        setData(resp);
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setLoading(false);
      }
    },
    [filterValues, searchQuery, searchQueryType, pagination],
  );

  const handleExport = useCallback(async () => {
    const _filters = filterValues || [];

    const sort = _filters?.find((f: any) => f.attribute === 'sort') || null;
    const params = searchQuery
      ? {
          query: searchQuery,
          query_type: searchQueryType,
          ...convertFilterValuesToQueryParams([sort || {}]),
        }
      : convertFilterValuesToQueryParams(_filters);
    try {
      const resp = await app.service.get('ftl/shipments/export', {
        params: {
          ...params,
          sort_by: Array.isArray(sort)
            ? undefined
            : sort?.columnKey || sort?.field,
          sort_value: Array.isArray(sort)
            ? undefined
            : sort?.order === 'ascend'
            ? 'asc'
            : sort?.order === 'descend'
            ? 'desc'
            : undefined,
        },
        responseType: 'blob',
      });

      const file = new Blob([resp]);
      FileSaver.saveAs(file, 'FTL shipments.csv');
    } catch (err: any) {
      showErrorMessage(err);
    }
  }, [filterValues, searchQuery, searchQueryType]);

  const handleInputChange = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 100),
    [],
  );

  const refreshFtlShipment = useCallback(
    async (id: number) => {
      if (!data || !id) {
        return;
      }
      const _shipment = await app.service.get(`ftl/shipments/${id}`);

      const collection = data.data.map((item) => {
        if (item.id === _shipment.data.id) {
          return _shipment.data;
        }
        return item;
      });
      setData({ ...data, data: collection });
    },
    [data],
  );

  const handleShow = (m: any) => {
    setModel(m);
    setShipformVisible(true);
  };

  const handlePatchUpdate = useCallback(
    async (shipmentId: number, model: string, values: any) => {
      setLoading(true);
      try {
        await app.service.patch(`ftl/${model}/${shipmentId}`, {
          data: values,
        });

        fetchData();
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setLoading(false);
      }
    },
    [fetchData],
  );

  const handleUpdateStatus = useCallback(
    async (shipmentId: number, values: any) => {
      try {
        await app.service.patch(`ftl/shipments/${shipmentId}/status`, {
          data: values,
        });
        fetchData();
      } catch (err: any) {
        showErrorMessage(err);
      }
    },
    [fetchData],
  );

  const handleNewFTLShipment = () => {
    setModel(undefined);
    setShipformVisible(true);
  };

  const checkedColumns = useMemo(() => {
    const tab = tabs.find((tab) => tab.id === activeKey);
    return tab?.columns || defaultColumns?.map((c: any) => c.key);
  }, [activeKey, tabs]);

  const history = useHistory();

  const handleTabChange = (key: string) => {
    setSearchQuery(searchRef.current || '');
    setActiveKey(+key);
    setSelectedRows([]);
    if (pagination) {
      pagination.current = 1;
    }
  };

  const handleTabMoved = (order: any[] = []) => {
    app.service.post('dispatchTabs/tabsOrder', {
      data: { order, mode: 4 },
    });
    setOrder(order);
  };

  const handleAddTab = async () => {
    setLoading(true);
    try {
      const reps = await app.service.post('dispatchTabs', {
        data: { name: `Tab ${tabs.length}`, mode: 4 },
      });
      setTabs([...tabs, { ...reps.data, filters: [] }]);
      setActiveKey(reps.data.id);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const _tabs = useMemo(() => {
    if (!order || order.length == 0) {
      return tabs;
    }
    const _order = order.map((i: string) => +i);
    const _tabs = [...tabs].sort((a, b) => {
      const orderA = _order.indexOf(a.id);
      const orderB = _order.indexOf(b.id);

      if (orderA !== -1 && orderB !== -1) {
        return orderA - orderB;
      }
      if (orderA !== -1) {
        return -1;
      }
      if (orderB !== -1) {
        return 1;
      }

      const ia = tabs.indexOf(a);
      const ib = tabs.indexOf(b);

      return ia - ib;
    });
    return _tabs;
  }, [order, tabs]);

  const tabMenu = (id: number): MenuProps['items'] => {
    const items: MenuProps['items'] = [
      {
        label: 'Edit',
        key: 'edit' + id,
        onClick: () => {
          setEditTab(id);
        },
      },
      {
        label: 'Delete',
        key: 'delete' + id,
        onClick: () => {
          handleDeleteTab(id);
        },
      },
    ];

    return items;
  };

  const handleDeleteTab = async (id: number) => {
    if (tabs.length == 1) {
      return;
    }

    setLoading(true);
    try {
      await app.service.delete(`dispatchTabs/${id}`);
      setTabs(tabs.filter((t) => t.id != id));
      message.success('Tab has been delete');
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveUpdateTab = async (id: number, data: any) => {
    if (!data?.name) {
      return;
    }
    const resp = await app.service.put(`dispatchTabs/${id}`, {
      data: data,
    });
    setTabs(tabs.map((t) => (t.id == id ? resp.data : t)));
    setFilterValues(resp.data.filters);
  };

  const handleUpdateTab = async (
    name: string,
    value: string | any[],
    id?: number,
  ) => {
    id = id || editTab;
    const tab = tabs.find((tab) => tab.id === id);
    if (tab) {
      try {
        await handleSaveUpdateTab(id, { ...tab, [name]: value });
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setEditTab(0);
      }
    }
  };

  const fetchTabs = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('dispatchTabs', {
        params: {
          mode: 4,
        },
      });
      setTabs(resp.data.tabs);
      setOrder(resp.data.order);
      handleTabChange(resp.data.order[0] || resp.data.tabs[0]?.id || 0);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleShowTabCount = async () => {
    try {
      const resp = await app.service.get('ftl/shipments/tabCounts', {
        params: { mode: 4 },
      });

      setTabCounts(resp);
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  useEffect(() => {
    const queryString = location.search;
    if (queryString) {
      const params = new URLSearchParams(queryString);

      const shipmentId: any = params.get('shipmentId') || 0;
      const search: string = params.get('search') || '';
      if (search) {
        setSearchQueryType(SEARCHQUERYTYPES[0].value);
        setSearchQueryInput(search);
        setSearchQuery(search);
        searchRef.current = search;
        params.delete('search');
        const newLocationSearch = params.toString();
        return history.replace(
          location.pathname +
            (newLocationSearch ? `?${newLocationSearch}` : ''),
        );
      }
      if (!shipmentId) {
        fetchTabs();
        return;
      }
      const _filter = [];
      if (shipmentId) {
        _filter.push({
          attribute: 'id',
          text: 'shipmentId ID: ' + shipmentId,
          value: shipmentId,
        });
      }

      fetchData(pagination, _filter, !search, undefined, search);
      setFilterValues(_filter);
      setOpenId(shipmentId);
    } else {
      if (searchRef.current) {
        const search = searchRef.current;
        setSearchQueryInput(search);
        setSearchQuery(search);
        fetchData(pagination, undefined, !search, undefined, search);
      }
      fetchTabs();
    }
  }, [location.search]);

  useEffect(() => {
    if (activeKey) {
      const filters = tabs.find((tab) => tab.id === activeKey)?.filters || [];
      if (location.search) {
        const params = new URLSearchParams(location.search);
        const open: any = params.get('open');
        if (!open) {
          history.replace(location.pathname);
        }
        setFilterValues([]);
      } else if (!location.search) {
        // 最终处理搜索参数
        const search = searchRef.current;
        history.replace(location.pathname);
        setFilterValues(filters);
        fetchData(pagination, filters, !search, undefined, search);
        searchRef.current = '';
      }
    }
  }, [activeKey]);

  const handleSearch = (value: any) => {
    fetchData(
      {
        current: 1,
        pageSize: pagination?.pageSize || 20,
      },
      filterValues,
      !value,
    );
  };

  const updateTab = useCallback(
    async (filters: any, pagination: any, isEmptyQuery?: boolean) => {
      setLoading(true);
      const tab = tabs.find((tab) => tab.id === activeKey);
      try {
        await handleSaveUpdateTab(activeKey, {
          ...tab,
          filters,
          mode: 4,
        });

        fetchData(pagination, filters, isEmptyQuery);
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setLoading(false);
      }
    },
    [filterValues],
  );

  const handleRemoveAllFilters = () => {
    setFilterValues([]);
    updateTab([], pagination);
  };

  const handleRemoveFilter = useCallback(
    (index: number) => {
      updateTab(update(filterValues, { $splice: [[index, 1]] }), pagination);
    },
    [filterValues, pagination],
  );

  const handleRemoveSortInFilterValues = (sortBy: string) => {
    if (!filterValues) {
      return;
    }
    const index = filterValues.findIndex((fv) => fv.attribute === 'sort');
    if (index === -1) {
      return;
    }
    const sortIndex = filterValues[index].value.indexOf(`${sortBy}|`);
    if (sortIndex === -1) {
      return;
    }
    handleRemoveFilter(index);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ShipmentInterface>,
  ) => {
    const sortValue = SORT_MAP[sorter.order as keyof typeof SORT_MAP] || '';
    const sortBy = sorter.field;
    // 禁用排序
    if (!sortValue) {
      handleRemoveSortInFilterValues(sortBy);
      fetchData(pagination, null, !searchQuery, sorter);
      return;
    }
    const column = columns.find((c) => c.key === sortBy);
    if (!column) {
      handleRemoveSortInFilterValues(sortBy);
      fetchData(pagination, null, !searchQuery, sorter);
      return;
    }
    const filterSortValue = toFilterSortValue(column?.title, sortBy, sortValue);
    if (!filterValues) {
      fetchData(pagination, null, !searchQuery, sorter);
      return;
    }
    const index = filterValues.findIndex((fv) => fv.attribute === 'sort');
    if (index !== -1) {
      updateTab(
        update(filterValues, { [index]: { $set: filterSortValue } }),
        pagination,
        !searchQuery,
      );
    } else {
      updateTab([...filterValues, filterSortValue], pagination, !searchQuery);
    }
  };

  const rowSelection: TableRowSelection<FTLShipmentInterface> = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const columns: ColumnsType<any> = useMemo(() => {
    const sort = filterValues?.find((f) => f.attribute === 'sort') || null;
    const _columns: ColumnsType<any> = defaultColumns?.map((c: any) => ({
      ...c,
      sortOrder: sortOrder(sort, c.key),
    }));

    const tab = tabs.find((t) => t.id === activeKey);
    const _defaultColumns = _columns?.map((c) => c.key);
    return _columns.filter((c) =>
      (tab?.columns || _defaultColumns)?.includes(c.key),
    );
  }, [filterValues, tabs, activeKey, pagination, defaultColumns]);

  return (
    <div className={styles.main}>
      <Header
        title="FTL Shipment"
        rightElement={
          <Space align="end" direction="horizontal">
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => fetchData()}
              shape="circle"
            >
              <HiRefresh size={18} color="#1890ff" />
            </Button>
            <Input.Group compact>
              <Select
                value={searchQueryType}
                onSelect={(value) => {
                  setSearchQueryType(value);
                }}
                style={{ width: '140px' }}
              >
                {SEARCHQUERYTYPES.map((type) => (
                  <Select.Option key={type.value} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
              <Input.Search
                style={{ width: '320px' }}
                placeholder={
                  SEARCHQUERYTYPES.find(
                    (type) => type.value === searchQueryType,
                  )?.placeholder
                }
                allowClear
                enterButton="Search"
                disabled={loading}
                value={searchQueryInput}
                onChange={(e) => {
                  setSearchQueryInput(e.target.value);
                  handleInputChange(e.target.value);
                }}
                onSearch={handleSearch}
              />
              {/* <Button
                disabled={loading}
                type="primary"
                onClick={() => handleSearch(false)}
              >
                Search
              </Button> */}
            </Input.Group>
            <Button type="primary" onClick={handleNewFTLShipment}>
              New FTL Shipment
            </Button>
          </Space>
        }
      />
      <Spin spinning={loading}>
        <div className="pl-sm pr-sm">
          <DraggableTabs
            onChange={handleTabChange}
            onTabMoved={handleTabMoved}
            activeKey={'' + activeKey}
            tabBarExtraContent={
              <Space>
                <Tooltip title="Update Counts">
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={handleShowTabCount}
                    shape="circle"
                  >
                    <HiRefresh size={18} color="#1890ff" />
                  </Button>
                </Tooltip>
                <Tooltip title=" New Tab">
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    shape="circle"
                    onClick={handleAddTab}
                  >
                    <FaPlus size={18} color="#1890ff" />
                  </Button>
                </Tooltip>
              </Space>
            }
            items={_tabs.map((tab: TDispatchTab) => {
              return {
                label:
                  editTab !== tab.id ? (
                    <Dropdown
                      menu={{ items: tabMenu(tab.id) }}
                      trigger={['contextMenu']}
                    >
                      <div>
                        {tab.name}
                        {tabCounts[tab.id] == undefined ? (
                          ''
                        ) : (
                          <span
                            style={{
                              background:
                                activeKey == tab.id ? '#F0F9FF' : '#F0F0F0',
                              borderRadius: '100px',
                              color:
                                activeKey == tab.id ? '#2F6EC0' : '#8C8C8C',
                              padding: '0px 8px',
                              fontWeight: '400',
                            }}
                          >
                            {tabCounts[tab.id]}
                          </span>
                        )}
                      </div>
                    </Dropdown>
                  ) : (
                    <EditableText
                      value={tab.name}
                      onChange={handleUpdateTab}
                      onHide={() => setEditTab(0)}
                      name="name"
                      autoFocus
                    />
                  ),
                key: '' + tab.id,
              };
            })}
          ></DraggableTabs>
        </div>
        {_tabs.length > 0 && (
          <Row>
            <Col span={16}>
              <Filter
                show={isShowFilter}
                values={filterValues || []}
                onChange={(v: any) => {
                  setSearchQuery('');
                  updateTab(
                    v,
                    { current: 1, pageSize: pagination?.pageSize || 20 },
                    true,
                  );
                }}
                removeAll={handleRemoveAllFilters}
                removeOne={handleRemoveFilter}
                activeFilters={
                  tabs.find((tab) => tab.id === activeKey)?.filters || []
                }
                hideFilter={() => setIsShowFilter(false)}
                loading={loading}
              />
            </Col>
            <Col
              span={8}
              style={{
                marginTop: isShowFilter ? '48px' : '0',
                marginBottom: isShowFilter ? '0' : '20px',
              }}
            >
              <Space>
                <Button
                  onClick={() => {
                    setIsShowFilter((isShowFilter) => !isShowFilter);
                  }}
                >
                  {isShowFilter ? 'Hide Filters' : 'Show Filters'}
                </Button>
                <div>
                  <ManageColumns
                    items={defaultColumns?.map((c: any) => {
                      return {
                        key: c.key,
                        name: c.title instanceof Function ? c.label : c.title,
                      };
                    })}
                    checkedColumns={checkedColumns}
                    onChange={(list) =>
                      handleUpdateTab('columns', list, activeKey)
                    }
                  />
                </div>
                <ColorTagSelect
                  model={'App\\Domains\\FTL\\Models\\FTLShipment'}
                />
                <Button type="primary" onClick={handleExport}>
                  Export
                </Button>
                <ShipmentBulkUpdate
                  shipments={selectedRows}
                  onSaved={() => {
                    fetchData();
                  }}
                />
              </Space>
            </Col>
          </Row>
        )}
        <div>
          <ShipmentsTable
            pagination={pagination}
            columns={columns}
            data={data}
            handleTableChange={handleTableChange}
            handleShow={handleShow}
            handlePatchUpdate={handlePatchUpdate}
            handleUpdateStatus={handleUpdateStatus}
            fetchData={fetchData}
            refreshFtlShipment={refreshFtlShipment}
            setDefaultColumns={setDefaultColumns}
            openId={openId}
            // loading={loading}
            rowSelection={rowSelection}
            selectedRows={selectedRows}
          />
        </div>
      </Spin>

      <ShipmentDrawer
        visible={shipformVisible}
        model={model}
        onSaved={(close = true) => {
          fetchData();
          close && setShipformVisible(false);
        }}
        onBooked={(id: number) => refreshFtlShipment(id)}
        onClose={() => {
          setShipformVisible(false);
        }}
      />
    </div>
  );
};

export default Shipments;
