// just need Date, operator, memo

export const columns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Operator',
    dataIndex: 'operator',
    key: 'operator',
    render: (text: string, record: any) => {
      return record?.operator?.name || 'System';
    },
  },
  {
    title: 'Memo',
    dataIndex: 'memo',
    key: 'memo',
  },
];
