import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Button, Divider, Tooltip, message, Popconfirm, Space } from 'antd';
import React, { useState } from 'react';
import { Header } from '@/components/CommonHeader';
import { Filter } from './components/Filter';
import type { ColumnsType } from 'antd/lib/table';
import AutoResizeTable from '@/components/AutoResizeTable';
import { DropZoneDrawer } from './components/DropZoneRateDrawer';
import { useForm } from 'antd/lib/form/Form';
import usePagination from '@/components/usePagination';
import { DROPZONE_TYPE_MAP } from './components/constants';
import Import from './components/Import';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dropZoneId, setDropZoneId] = useState<number>(0);
  const [response, setResponse] = useState<any>();
  const pagination = usePagination(response);
  const data = response?.data || [];

  const [filter] = useForm();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setDropZoneId(0);
  };

  const handleAdd = () => {
    showDrawer();
  };

  const handleEdit = (id: number) => {
    setDropZoneId(id);
    showDrawer();
  };

  const handleSaved = () => {
    setDropZoneId(0);
    onClose();
    fetchData();
  };

  const fetchData = async ({ page } = { page: 1 }) => {
    const filters = await filter.getFieldsValue();
    const _filter = Object.assign({}, filters);

    if (_filter.city) {
      _filter.city_id = _filter.city.id;
      delete _filter.city;
    }

    if (_filter.vendor) {
      _filter.vendor_id = _filter.vendor.id;
      delete _filter.vendor;
    }

    setLoading(true);
    try {
      const result = await app.service.get('dropZoneRates', {
        params: { ..._filter },
      });
      setResponse(result);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleRemove = (id: number) => {
    setLoading(true);

    app.service
      .delete(`dropZoneRates/${id}`)
      .then((resp) => {
        fetchData();
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 48,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 120,
      render: (text) => {
        return DROPZONE_TYPE_MAP[text];
      },
    },
    {
      title: 'Vendor',
      dataIndex: ['vendor', 'name'],
      key: 'vendor_id',
      width: 200,
      render: (text, record) => {
        return record.vendor ? record.vendor.name : 'DrayEasy';
      },
    },
    {
      title: 'IR',
      dataIndex: ['intermodal_region', 'name'],
      key: 'intermodal_region_id',
      width: 200,
    },
    {
      title: 'City',
      dataIndex: ['city', 'full_name'],
      key: 'city_id',
      width: 200,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width: 80,
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="Drop Zones"
        rightElement={
          <Space>
            <Import onImported={fetchData} />
            <Button type="primary" onClick={handleAdd}>
              New Drop Zone
            </Button>
          </Space>
        }
      ></Header>

      <Filter loading={loading} filter={filter} onSearch={fetchData}>
        {' '}
      </Filter>
      <div>
        <AutoResizeTable
          pagination={pagination}
          loading={loading}
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={[...data]}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>
      <DropZoneDrawer
        open={open}
        id={dropZoneId}
        onClose={onClose}
        onSaved={handleSaved}
      />
    </div>
  );
};

export default Index;
