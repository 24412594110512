import React from 'react';
import { Form, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';

interface WillPayCleanTruckFeeProps {
  form: FormInstance;
}

const WillPayCleanTruckFee: React.FC<WillPayCleanTruckFeeProps> = ({
  form,
}) => {
  return (
    <Form.Item
      name="will_pay_clean_truck_fee"
      label="Will Pay Clean Truck Fee"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
  );
};

export default WillPayCleanTruckFee;
