import { useApp } from '@/utils/useapp';
import { Drawer, message } from 'antd';
import { FC, useEffect, useMemo } from 'react';
import RatingForm from './RatingForm';
import { ModelType, ModelTarget } from '../Interfaces/ModelType';
import { ResponseType } from '../Interfaces/RepsonseType';
import { RatingInterface } from '../Interfaces/RatingInterface';

const RatingDrawer: FC<
  ModelType &
    ResponseType & {
      open: boolean;
      autoRating?: any;
      ratingId?: number;
      onClose?: () => void;
      onSaved?: () => void;
    }
> = ({
  modelId,
  modelTarget,
  model,
  responsibleTarget,
  responsible,
  ratingId,
  autoRating,
  open = false,
  onClose,
  onSaved,
}) => {
  const app = useApp();
  const allowHalf = useMemo(() => {
    if (modelTarget == ModelTarget.LtlCarrier) {
      return false;
    } else {
      return true;
    }
  }, [modelTarget]);

  const handleSubmit = async (values: any) => {
    if (!modelId || !modelTarget) {
      return;
    }

    try {
      !ratingId
        ? await app.service.post(`ratings/${modelTarget}/${modelId}`, {
            data: {
              responsible_id: responsible?.id,
              responsible_target: responsibleTarget,
              responses: values,
            },
          })
        : await app.service.put(`ratings/${ratingId}`, {
            data: {
              responses: values,
            },
          });
      // refreshContainer && refreshContainer();
      message.success('Submitted');
      onSaved && onSaved();
    } catch (e: any) {
      message.warning(e?.data?.message || e?.data?.error || e?.message || e);
    }
  };

  return (
    <Drawer
      title="Rating"
      placement="right"
      width="50%"
      onClose={onClose}
      destroyOnClose={true}
      open={open}
    >
      <RatingForm
        modelId={modelId}
        modelTarget={modelTarget}
        model={model}
        responsibleTarget={responsibleTarget}
        responsible={responsible}
        ratingId={ratingId}
        onSumbit={handleSubmit}
        autoRating={autoRating}
        allowHalf={allowHalf}
      />
    </Drawer>
  );
};

export default RatingDrawer;
