import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message, Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { CitySelect } from '@/components/CitySelect';
import ContainerSizeSelect, {
  CNTR_SIZE_40,
} from '@/components/ContainerSizeSelect';
import { showErrorMessage } from '@/utils/show-error-message';

export type TRequestedRateDrawer = {
  open: boolean;
  onClose: () => void;
  onSaved?: () => void;
};

const RequestedRateDrawer: React.FC<TRequestedRateDrawer> = ({
  open,
  onClose,
  onSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);

  const [form] = useForm();

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    values.to_city_id = values.to_city?.id;
    delete values.to_city;

    setLoading(true);

    app.service
      .post('requestedRates', { data: values })
      .then((resp: any) => {
        message.success('Saved');
        onSaved && onSaved();
        handleClose();
      })
      .catch((err: any) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const initForm = () => {
    form.resetFields();
    form.setFieldsValue({
      cntr_size: CNTR_SIZE_40,
    });
  };

  useEffect(() => {
    initForm();
  }, [open]);

  return (
    <>
      <Drawer
        title={'New Request Rate'}
        placement="right"
        width="80%"
        onClose={handleClose}
        destroyOnClose={true}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="intermodal_region_id"
            label="Intermodal Region"
            rules={[{ required: true, message: 'please input this field' }]}
          >
            <IntermodalRegionSelect />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldValue }) => (
              <Form.Item
                name="to_city"
                label="To City"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true }]}
              >
                <CitySelect
                  selected={getFieldValue('to_city')}
                  onSelect={(city) => setFieldValue('to_city', city)}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label="To Zipcode"
            name="to_zipcode"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Enter to zipcode" />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => (
              <Form.Item
                name="user_id"
                label="Customer"
                rules={[{ required: true, message: 'please input this field' }]}
              >
                <UserSyncSelect type="user" />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item label="CNTR Size" name="cntr_size">
            <ContainerSizeSelect style={{ width: '100%' }} allowClear />
          </Form.Item>
          <Form.Item name="memo" label="Memo">
            <Input.TextArea
              value={form.getFieldValue('memo')}
              rows={4}
              placeholder="Enter memo"
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default RequestedRateDrawer;
