import { TAccount, TTokenUser } from '@/types.d';
import { includes } from 'lodash';
import { action, computed, observable } from 'mobx';

const TOKEN = 'de_token';
const SUPER_ADMIN_BEN_ID = 1;

export class AuthStore {
  @observable user?: TAccount | null;

  /**
   * getter 检查是否已登录
   */
  @computed get loggedIn() {
    return this.token && this.account;
  }

  /**
   * getter 获取 Session ID
   */
  @computed get token() {
    return localStorage.getItem(TOKEN);
  }

  /**
   * getter 获取 account
   */
  @computed get account() {
    return this.user ? this.user : null;
  }

  @computed get enabled2Fa() {
    return this.user && this.user.two_fa_active;
  }

  @computed get superAdmin() {
    return this.user && this.user.roles.includes('super-admin');
  }

  @computed get superAdminBen() {
    return this.user && this.user.id == SUPER_ADMIN_BEN_ID;
  }

  @computed get hasProfitReportAllAccess() {
    const roles = ['super-admin', 'controller'];
    return this.user && roles.some((role) => this.user?.roles.includes(role));
  }

  /**
   * 登录
   */
  @action login(userObject: TTokenUser) {
    // 写入 localStorage
    const token = userObject.access_token;
    this.setToken(token);
    this.user = userObject.admin;
  }

  @action setAccount(account: TAccount | null) {
    this.user = account;
  }

  @action set2faEnabled(enabled: boolean) {
    if (this.user) {
      this.user.two_fa_active = enabled;
    }
  }

  @action refreshToken(token: string) {
    authService.refreshToken(token);
  }

  /**
   * 退出登录
   */
  @action logout() {
    // 删除 localStorage
    localStorage.removeItem(TOKEN);
    this.setToken('');
    this.setAccount(null);
    // 刷新页面
  }

  @action setToken(token: string) {
    localStorage.setItem(TOKEN, token);
  }

  /**
   * 权限校验
   * @param permissions 权限列表
   * @param operator 权限运算符, 可选 "and" 或 "or", 默认 "and"
   */
  access(permissions: Array<string> | string, operator = 'and') {
    if (includes(this.user?.permissions, '*.*')) {
      // 拥有全部权限
      return true;
    }
    if (typeof permissions === 'string') {
      permissions = [permissions];
    }
    let result = operator === 'and' ? true : false;
    permissions.forEach((permission) => {
      // 检查权限是否存在
      const exists = includes(this.user?.permissions, permission);
      if (operator === 'and') {
        result = result && exists;
      } else {
        result = result || exists;
      }
    });
    return result;
  }

  hasRole(roles: Array<string> | string, operator = 'or') {
    if (includes(this.user?.roles, 'super-admin')) {
      // 拥有全部权限
      return true;
    }
    if (typeof roles === 'string') {
      roles = [roles];
    }
    let result = operator === 'and' ? true : false;
    roles.forEach((roles) => {
      // 检查权限是否存在
      const exists = includes(this.user?.roles, roles);
      if (operator === 'and') {
        result = result && exists;
      } else {
        result = result || exists;
      }
    });
    return result;
  }
}

const auth = new AuthStore();

export default auth;
