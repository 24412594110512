import React from 'react';
import { Spin, message } from 'antd';
import { useApp } from '@/utils/useapp';
import type { UploadProps } from 'antd';
import { DraggerWithPaste } from './DraggerWithPaste';
import { EXCEL_TYPES } from './data';
import { serialize } from 'object-to-formdata';
import { showErrorMessage } from '@/utils/show-error-message';

interface TExcelUpload {
  uploadAction: string;
  enableEmptyUpload?: boolean;
  onUploaded: (response?: any) => void;
  disabledFileList?: boolean;
}
export const Excels: React.FC<TExcelUpload> = ({
  uploadAction,
  enableEmptyUpload = false,
  onUploaded,
  disabledFileList = false,
}) => {
  const app = useApp();

  const [isLoading, setIsLoading] = React.useState(false);
  const [fileList, setFileList] = React.useState<UploadProps['fileList']>([]);

  const uploadToServer = async (formData: FormData, file: any) => {
    setIsLoading(true);

    try {
      const response = await app.service.post(uploadAction, {
        data: formData,
      });

      setFileList([
        ...fileList,
        {
          uid: file?.uid || Date.now(),
          name: file.name,
          status: 'done',
        },
      ]);

      message.success(file.name + ' uploaded successfully');
      onUploaded(response);
    } catch (err: any) {
      if (err.data) {
        if (err.data.errors && Object.keys(err.data.errors).length > 0) {
          message.error(
            <>
              <div>Error</div>
              {Object.keys(err.data.errors).map((row) => (
                <div key={row}>
                  <div>Row {row}</div>
                  <ul>
                    {err.data.errors[row].map((error: any, i: number) => (
                      <li key={i}>{error}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </>,
            5,
          );
        } else {
          showErrorMessage(err);
        }
      } else {
        message.error('System Error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const draggerProps: UploadProps = {
    name: 'file',
    multiple: false,
    action: uploadAction,
    accept: EXCEL_TYPES,
    headers: {
      Authorization: `Bearer ${app.store.auth.token}`,
    },
    customRequest: async (options) => {
      const formData = new FormData();
      formData.append('file', options.file);

      await uploadToServer(formData, options.file);
    },
    onRemove: async (file) => {
      setFileList(fileList?.filter((item) => item.uid !== file.uid));
      message.success(`${file.name} removed`);
    },
  };

  const handlePasteFiles = async (pasteFiles: any) => {
    const formData = new FormData();
    formData.append('file', pasteFiles[0]);

    await uploadToServer(formData, pasteFiles[0]);
  };

  React.useEffect(() => {
    if (enableEmptyUpload) {
      setFileList([]);
    }
  }, [enableEmptyUpload]);

  return (
    <Spin spinning={isLoading} tip="Uploading...">
      <DraggerWithPaste
        uploadProp={draggerProps}
        fileList={disabledFileList ? [] : fileList}
        disabled={isLoading}
        acceptFileTypes={EXCEL_TYPES}
        handlePasteFiles={handlePasteFiles}
      />
    </Spin>
  );
};
