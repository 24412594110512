import { useApp } from '@/utils/useapp';
import { message, Form, Modal, Space, Button, Spin, FormInstance } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import EmailForm from '@/components/EmailForm';
import { serialize } from 'object-to-formdata';
import { get, chain, reduce, uniq, trim } from 'lodash';
import { TInvoice, TUser } from '@/types';
import { CONTACT_TYPE_ACCOUNTING } from '@/components/ContactList';
import moment from 'moment';
import { showErrorMessage } from '@/utils/show-error-message';

interface ICfsShipmentEmaiForm {
  cfsShipmentId: number;
  invoices: TInvoice[];
  emailForm: FormInstance;
}
const CfsShipmentEmaiForm: React.FC<ICfsShipmentEmaiForm> = ({
  cfsShipmentId,
  invoices,
  emailForm,
}) => {
  const app = useApp();

  const [docs, setDocs] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [cfsShipment, setCfsShipment] = useState();

  const fetchData = async () => {
    console.log('fetchData', cfsShipmentId);
    setLoading(true);
    try {
      const _order = await app.service.get(`cfs/shipments/${cfsShipmentId}`);
      setCfsShipment(_order.data);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchDocuments = async () => {
    if (!cfsShipmentId) {
      return;
    }

    try {
      const docs = await app.service.get(`documents/cfs_doc/${cfsShipmentId}`);

      const resData = [...docs.data];

      setDocs(
        resData.map((d: any) => {
          return {
            label: `[${get(d, 'custom_properties.document_type', 'No Type')}]${
              d.file_name
            }`,
            value: d.id,
          };
        }),
      );
    } catch (err: any) {
      setDocs([]);
    }
  };

  const toDrayeasyBody = () => {
    return trim(`
Hi,

Please find attached invoice. Appreciate the opportunity for us to serve you.

DrayEasy, Inc.
800 N Haven Ave #210,
Ontario, CA 91764
Phone: 1-818-826-2850
E-mail: ar@drayeasy.com
          `);
  };

  // invoice for customer /reference/ MBL/CNTR NO./xx POD/Warehouse zipcode/ETA
  const toSubject = () => {
    const uid = get(cfsShipment, 'uid', '');
    const customerRef = get(cfsShipment, 'customer_reference_number', '');

    let subject = `Invoice for ${uid}`;

    customerRef && (subject += `-${customerRef}`);

    return subject;
  };

  const initForm = () => {
    const cc = get(cfsShipment, 'user.email', '');
    const salesEmail = get(cfsShipment, 'sales.email', '');
    const salesSupportEmail = get(cfsShipment, 'sales_support.email', '');
    const contacts = get(invoices[0], 'user.bill_to.contacts', []).filter(
      (c: any) => {
        if (c.type instanceof Array) {
          return c.type.includes(CONTACT_TYPE_ACCOUNTING);
        }

        return false;
      },
    );

    const sendToList = contacts.map((c: any) => c.email);

    const _lowerSendToList = sendToList.map((send) => send.toLowerCase());

    const ccList = uniq([cc, salesEmail, salesSupportEmail]).filter(
      (cc) => cc && !_lowerSendToList.includes(cc.toLowerCase()),
    );

    emailForm.setFieldsValue({
      sendToList: sendToList.length > 0 ? sendToList : [''],
      ccList: ccList,
      subject: toSubject(),
      body: toDrayeasyBody(),
      addText: false,
    });
  };

  React.useEffect(() => {
    if (cfsShipment && invoices) {
      initForm();
    }
  }, [cfsShipment, invoices]);

  React.useEffect(() => {
    if (cfsShipmentId) {
      fetchDocuments();
      fetchData();
    }
  }, [cfsShipmentId, invoices]);

  return (
    <EmailForm
      form={emailForm}
      docs={docs}
      has={{
        from: false,
        attachment: true,
        showSelectAttachments: true,
      }}
    />
  );
};

export default CfsShipmentEmaiForm;
