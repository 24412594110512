import { useEffect, useState } from 'react';
import ShipmentsTable from '.';
import { useApp } from '@/utils/useapp';
import { message } from 'antd';
import ShipmentDrawer from '../../components/ShipmentDrawer';
import { showErrorMessage } from '@/utils/show-error-message';

interface IShipmentTableInstance {
  ids: number[];
}

const ShipmentTableInstance = ({ ids }: IShipmentTableInstance) => {
  const [data, setData] = useState<any>({});
  const [shipformVisible, setShipformVisible] = useState(false);
  const [model, setModel] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const app = useApp();

  const refreshFtlShipment = async (id: number) => {
    if (!data || !id) {
      return;
    }
    const _shipment = await app.service.get(`ftl/shipments/${id}`);

    const collection = data.data.map((item: any) => {
      if (item.id === _shipment.data.id) {
        return _shipment.data;
      }
      return item;
    });
    setData({ ...data, data: collection });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('ftl/shipments', {
        params: {
          ids,
          page: 1,
          per_page: 1000,
        },
      });
      setData(resp);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePatchUpdate = async (
    shipmentId: number,
    model: string,
    values: any,
  ) => {
    setLoading(true);
    try {
      await app.service.patch(`ftl/${model}/${shipmentId}`, {
        data: values,
      });

      fetchData();
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleShow = (m: any) => {
    setModel(m);
    setShipformVisible(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ShipmentsTable
        loading={loading}
        data={data}
        fetchData={fetchData}
        pagination={false}
        columns={undefined}
        handleTableChange={fetchData}
        handleShow={handleShow}
        handlePatchUpdate={handlePatchUpdate}
        refreshFtlShipment={refreshFtlShipment}
      />
      <ShipmentDrawer
        visible={shipformVisible}
        model={model}
        onSaved={() => {
          fetchData();
          setShipformVisible(false);
        }}
        onClose={() => {
          setShipformVisible(false);
        }}
      />
    </>
  );
};

export default ShipmentTableInstance;
