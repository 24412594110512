export enum SubTabs {
  Task = 'Task',
  Calendar = 'Calendar',
  NewOrder = 'New Order',
  PickedUp = 'Picked Up',
  Delivery = 'Delivery',
  All = 'All',
  InTransit = 'In Transit',
  Claim = 'Claim',
}

export const SubTabShowCount = {
  [SubTabs.NewOrder]: true,
  [SubTabs.PickedUp]: true,
  [SubTabs.Delivery]: true,
  [SubTabs.All]: true,
  [SubTabs.InTransit]: true,
  [SubTabs.Claim]: true,
};

export const SubTabShowDate = {
  [SubTabs.PickedUp]: true,
  [SubTabs.Delivery]: true,
};

export const SubTabDescription = {
  [SubTabs.Task]: 'Task',
  [SubTabs.Calendar]: 'Calendar',
  [SubTabs.NewOrder]: 'New Order',
  [SubTabs.PickedUp]: 'Picked Up',
  [SubTabs.Delivery]: 'Delivery',
  [SubTabs.All]: 'All',
  [SubTabs.InTransit]: 'In Transit',
  [SubTabs.Claim]: 'Claim',
};

export const HasTableSubTabs = [
  SubTabs.NewOrder,
  SubTabs.PickedUp,
  SubTabs.Delivery,
  SubTabs.All,
  SubTabs.InTransit,
  SubTabs.Claim,
];
