import { Cache } from '@/stores/cache';
import { useApp } from '@/utils/useapp';
import { Select, SelectProps } from 'antd';
import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { get, debounce } from 'lodash';

export type filterModelFn = (model: any, value: any) => boolean;

const defaultFilterModel: filterModelFn = () => {
  return true;
};

export type SyncCacheModelSelectProps = {
  renderOption?: string | ((model?: any) => ReactNode);
  getValueForOption?: string | ((model?: any) => any);
  onSelect?: (model?: any) => any;
  onGetRawOptinonOnChange?: (option: any) => void;
  filterModel?: filterModelFn;
  disabledFunc?: (model?: any) => boolean;
  classNameFunc?: (model?: any) => string;
  selected?: number;
  statusTypes?: any;
  multiple?: boolean;
  trySearchWhenNotFound?: boolean;
  addNullOption?: boolean;
  nullOptionValue?: any;
  nullOptionLabel?: any;
  codeOnly?: boolean;
} & SelectProps;

const getValueOrRunFunction: any = (value: any, fn: any) => {
  if (typeof fn === 'number' || typeof fn === 'string') {
    return get(value, fn);
  }

  if (typeof fn === 'function') {
    return fn(value);
  }

  return null;
};

const toValue: any = (row: any, fn: any) => {
  return fn ? getValueOrRunFunction(row, fn) : row.id;
};

const _SyncCacheModelSelect: React.FC<
  {
    cacheKey: string;
    cache: Cache;
    append?: Array<string>;
  } & SyncCacheModelSelectProps
> = observer(
  ({
    cache,
    cacheKey,
    renderOption,
    getValueForOption,
    optionFilterProp = 'label',
    onSelect,
    onChange,
    onGetRawOptinonOnChange,
    append,
    value,
    filterModel = defaultFilterModel,
    disabledFunc,
    classNameFunc,
    selected,
    trySearchWhenNotFound = false,
    addNullOption = false,
    nullOptionLabel = 'Null',
    nullOptionValue = null,
    ...props
  }) => {
    const loading = cache.loadingMap[cacheKey] !== false;

    const rows = cache.data[cacheKey];

    if (rows === undefined) {
      cache.fetch(cacheKey);
    }

    const handleOnChange = (value: any, option: any) => {
      if (onSelect) {
        onSelect(rows.find((row) => toValue(row, getValueForOption) == value));
      }

      if (onChange) {
        onChange(value, option);
      }
    };

    const options = React.useMemo(() => {
      const selectOptions = (rows || [])
        .filter((row) => filterModel(row, value))
        .map((row: any) => {
          const _append: { [key: string]: any } = {};
          append?.forEach((key) => {
            _append[key] = row[key];
          });

          return {
            value: toValue(row, getValueForOption),
            label: renderOption
              ? getValueOrRunFunction(row, renderOption)
              : row.name,
            ..._append,
            className:
              disabledFunc && disabledFunc(row)
                ? 'hide'
                : (classNameFunc && classNameFunc(row)) || '',
          };
        });

      if (addNullOption) {
        selectOptions.unshift({
          value: nullOptionValue,
          label: nullOptionLabel,
          className: '',
        });
      }

      return selectOptions;
    }, [
      rows,
      filterModel,
      value,
      selected,
      addNullOption,
      nullOptionLabel,
      nullOptionValue,
    ]);

    // Hack
    // 首先手动执行一次筛选，没有选项时再尝试更新 cache
    const handleOnSearch = debounce((searchText: string) => {
      let filteredOptions: Array<any> = [];
      if (props.filterOption === false) {
        filteredOptions = [];
      } else if (props.filterOption === true || props.filterOption == null) {
        filteredOptions = options.filter((option) => {
          const propValue =
            optionFilterProp === 'children'
              ? option.label
              : option[optionFilterProp as keyof typeof option];
          return propValue?.toString().includes(searchText);
        });
      } else {
        const filterOption = props.filterOption;
        if (!filterOption) {
          filteredOptions = [];
        }
        filteredOptions = options.filter((option) =>
          filterOption(searchText, option),
        );
      }
      // limitations
      if (searchText && filteredOptions.length === 0 && trySearchWhenNotFound) {
        cache.fetch(cacheKey, true);
      }
    }, 1000);

    return (
      <Select
        value={value === 0 ? null : value}
        loading={loading}
        optionFilterProp={optionFilterProp}
        showSearch
        allowClear
        virtual={false}
        onSearch={handleOnSearch} // allow override
        {...props}
        onChange={handleOnChange}
        options={options}
        dropdownStyle={{ zIndex: 10010 }}
      />
    );
  },
);

const SyncCacheModelSelect: React.FC<
  { cacheKey: string; append?: Array<string> } & SyncCacheModelSelectProps
> = ({ ...props }) => {
  const app = useApp();
  return <_SyncCacheModelSelect {...props} cache={app.store.cache} />;
};

export default SyncCacheModelSelect;
