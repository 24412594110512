import React, { useState } from 'react';
import Spreadsheet, { RowIndicatorProps } from 'react-spreadsheet';
import {
  Drawer,
  Space,
  Button,
  Dropdown,
  MenuProps,
  message,
  Divider,
} from 'antd';

import { useApp } from '@/utils/useapp';
import classNames from 'classnames';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  containers: any;
  onClose?: () => void;
  onSaved: () => void;
}

const RowIndicator: React.FC<
  RowIndicatorProps & {
    onDelete?: (row: number) => void;
    onInsert?: (row: number) => void;
  }
> = ({ row, label, selected, onSelect, onDelete, onInsert }) => {
  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      onSelect(row, event.shiftKey);
    },
    [onSelect, row],
  );

  const items: MenuProps['items'] = [
    {
      key: 'delete',
      label: 'Delete',
      onClick: () => onDelete && onDelete(row),
    },
    {
      key: 'insertAbove',
      label: 'Insert Above',
      onClick: () => onInsert && onInsert(row),
    },
    {
      key: 'insertBelow',
      label: 'Insert Below',
      onClick: () => onInsert && onInsert(row + 1),
    },
  ];

  return (
    <Dropdown
      menu={{
        items: items,
      }}
      trigger={['contextMenu']}
    >
      <th
        className={classNames('Spreadsheet__header', {
          'Spreadsheet__header--selected': selected,
        })}
        onClick={handleClick}
        tabIndex={0}
      >
        {label !== undefined ? label : row + 1}
      </th>
    </Dropdown>
  );
};

export const SellRateBulkUpdateWithExcel: React.FC<Props> = ({
  onClose,
  onSaved,
}) => {
  const app = useApp();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([[]]);
  // MBL，CNTR，SR
  const columnLabels = ['MBL', 'CNTR', 'SR'];
  const handleSave = async () => {
    setLoading(true);

    const filteredData = data.filter((row) =>
      row.some((cell: any) => cell?.value),
    );

    try {
      await app.service.put('dispatch/bulkUpdateSellRateWithExcel', {
        data: {
          data: filteredData.map((row: any) => ({
            mbl: row[0].value,
            cntr: row[1].value,
            sr: row[2].value,
          })),
        },
      });
      message.success('Saved');
      onSaved();
      handleClose();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const addRow = () => {
    setData([...data, []]);
  };

  const handleDeleteRow = (row: number) => {
    if (data.length > 1) {
      setData(data.filter((_, i) => i !== row));
    }
  };

  const handleInsertRow = (row: number) => {
    const newData = [...data];
    newData.splice(row, 0, []);
    setData(newData);
  };

  const clear = () => {
    setData([[]]);
  };

  return (
    <>
      <div className="cursor-pointer" onClick={handleOpen}>
        Bulk Update SR(Excel)
      </div>
      {open && (
        <Drawer
          title={'SR Bulk Update(Excel)'}
          placement={'right'}
          width={'50%'}
          onClose={handleClose}
          destroyOnClose={true}
          open={open}
          extra={
            <Space>
              <Button onClick={handleClose}>Close</Button>
              <Button type="primary" loading={loading} onClick={handleSave}>
                Save
              </Button>
            </Space>
          }
        >
          <Space>
            <Button onClick={addRow}>Add Row</Button>
            <Button onClick={clear}>Clear</Button>
          </Space>

          <Divider />
          <Spreadsheet
            columnLabels={columnLabels}
            data={data}
            onChange={(values: any[]) => setData(values)}
            RowIndicator={(props) => (
              <RowIndicator
                onDelete={handleDeleteRow}
                onInsert={handleInsertRow}
                {...props}
              />
            )}
          />
        </Drawer>
      )}
    </>
  );
};
