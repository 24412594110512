import React from 'react';

export const SOPLink: React.FC<{
  title: string;
  taskId: number;
  color: string;
}> = ({ title, color, taskId }) => {
  return (
    <a
      href={`${BASE_URL}/taskSop/${taskId}/redirect`}
      target="_blank"
      style={{ color: color ? color : 'inherit', textDecoration: 'underline' }}
      rel="noreferrer"
    >
      {title}
    </a>
  );
};
