import { ConsigneeSyncSelect } from '@/components/ConsigneeSyncSelect';
import { useApp } from '@/utils/useapp';
import {
  MinusCircleOutlined,
  PlusOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { TerminalSelect } from '@/components/TerminalSelect';
import styles from 'res/css/ui.scss';

import ContainerTable from '@/components/ContainerTable';

import { WarehouseSelect } from '@/components/WarehouseSelect';
import {
  PickupNumberAgentSyncSelect,
  PICKUP_NUMBER_AGENT_MYSELF_ID,
} from '@/components/PickupNumberAgentSyncSelect';
import { OCEAN_PORT, RAMP_PORT } from '@/pages/dispatchs/components/data';
import {
  CHASSIS_OWNER_MAP,
  CHASSIS_OWNER_NO_IDEA,
  getOWof45FeetReeferKGByLBS,
  getOWof45FeetStandardKGByLBS,
  getOWof53FeetStandardKGByLBS,
  OW_TYPE_45_FEET,
  OW_TYPE_53_FEET,
  WHO_WILL_PAY_TERMINAL_FEES_MAP,
} from '../data';
import { has } from 'lodash';
import {
  OW_TYPE_20_FEET,
  OW_TYPE_40_FEET,
  OW_TYPES,
  getOWof20FeetReeferKGByLBS,
  getOWof20FeetStandardKGByLBS,
  getOWof40FeetReeferKGByLBS,
  getOWof40FeetStandardKGByLBS,
} from '../data';
import { ServiceWrapper } from '.';
import {
  BuyRateDrawer,
  BUYRATE_UID_PREFIX,
} from '@/pages/rates/buyRates/components/buyRateDrawer';
import { TContainer } from '@/types';
import { LOADING_TYPE_TBD } from '@/components/ContainerTable/components/data';
import { showErrorMessage } from '@/utils/show-error-message';

const { Title } = Typography;

const CONTAINER_RELATED_FIELDS = ['type', 'weight', 'is_reefer'];
const TYPE_SELL_RATE = 1;
const TYPE_BUY_RATE = 2;

export const OrderForm: React.FC<{
  form: FormInstance;
  // snapshot: any;
  handleOpenSellRate: (conatiner: TContainer) => void;
  // handleOpenBuyRate: (buyRateId: string) => void;
  onApplyToContainers: (service: ServiceWrapper) => void;
  updateOrder: (order: any) => void;
}> = ({
  form,
  // snapshot,
  handleOpenSellRate,
  // handleOpenBuyRate,
  onApplyToContainers,
  // updateOrder,
}) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [openBuyRate, setOpenBuyRate] = React.useState(false);
  const [buyRateId, setBuyRateId] = React.useState(0);

  // const [openSellRate, setOpenSellRate] = React.useState(false);
  // const [sellRateId, setSellRateId] = React.useState(0);
  const destinationType = Form.useWatch('destination_type', form);
  const portOfDischarge = Form.useWatch('port_of_discharge_id', form);
  const portOfDischargeEta = Form.useWatch('port_of_discharge_eta', form);
  const finalPortId = Form.useWatch('final_port_id', form);
  const terminal = Form.useWatch('terminal', form);
  const id = Form.useWatch('id', form);

  const OW_20_REEFER_KG = getOWof20FeetReeferKGByLBS();
  const OW_20_STANDARD_KG = getOWof20FeetStandardKGByLBS();
  const OW_40_REEFER_KG = getOWof40FeetReeferKGByLBS();
  const OW_40_STANDARD_KG = getOWof40FeetStandardKGByLBS();
  const OW_45_STANDARD_KG = getOWof45FeetStandardKGByLBS();
  const OW_45_REEFER_KG = getOWof45FeetReeferKGByLBS();
  const OW_53_STANDARD_KG = getOWof53FeetStandardKGByLBS();

  useEffect(() => {
    if (destinationType && destinationType == OCEAN_PORT) {
      form.setFieldValue(
        'final_port_id',
        form.getFieldValue('port_of_discharge_id') || null,
      );
      form.setFieldValue(
        'final_port_eta',
        form.getFieldValue('port_of_discharge_eta') || null,
      );
    }
  }, [destinationType, portOfDischarge, portOfDischargeEta]);

  const notSameIR = useMemo(() => {
    if (!finalPortId || !terminal?.intermodal_region_id) {
      return false;
    }
    return finalPortId !== terminal?.intermodal_region_id;
  }, [finalPortId, terminal, id]);

  const handleAddContainer = () => {
    const newContainers = form.getFieldValue('containers');
    if (newContainers) {
      newContainers.push({
        loading_type: LOADING_TYPE_TBD,
      });
      form.setFieldsValue({
        containers: newContainers,
        container_count: newContainers.length,
      });
    }
  };

  const handleMatchCurrentRate = async (
    id: number,
    rate_id: string,
    index: number,
  ) => {
    setLoading(true);
    try {
      const resp = await app.service.patch(`containers/${id}/syncRate`, {
        data: {
          sell_rate_id: rate_id,
        },
      });
      message.success('Succeed');
      form.setFieldValue(['containers', index], {
        ...resp.data,
        sell_rate_id: rate_id,
      });
      // updateOrder(resp.data);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenBuyRate = (uid: string) => {
    if (!uid) {
      return;
    }
    const _rateId = Number(uid.replace(BUYRATE_UID_PREFIX, ''));
    setOpenBuyRate(true);
    setBuyRateId(_rateId);
  };

  const handleCloseBuyRate = () => {
    setOpenBuyRate(false);
    setBuyRateId(0);
  };

  const handleSyncToAllContainers = (
    id: number,
    rate_id: string,
    type: number,
  ) => {
    const patten = /[SB](?=\d+)/g;
    if (!patten.test(rate_id)) {
      message.error('The Rate ID Format is not correct');
      return;
    }
    const data: any = {};
    if (type == TYPE_SELL_RATE) {
      data['sell_rate_id'] = rate_id;
    } else if (type == TYPE_BUY_RATE) {
      data['buy_rate_id'] = rate_id;
    } else {
      return;
    }
    onApplyToContainers(
      new ServiceWrapper(
        `orders/${id}/syncRateToAllContainers`,
        data,
        app.service.put,
      ),
    );
  };

  const handleSyncDestinationToContainers = (id: number) => {
    onApplyToContainers(
      new ServiceWrapper(
        `orders/${id}/syncDestinationToContainers`,
        {
          destination_type: form.getFieldValue('destination_type'),
          final_port_id: form.getFieldValue('final_port_id'),
          final_port_eta: form.getFieldValue('final_port_eta'),
          port_of_discharge_id: form.getFieldValue('port_of_discharge_id'),
          port_of_discharge_eta: form.getFieldValue('port_of_discharge_eta'),
        },
        app.service.put,
      ),
    );
  };

  const handleSyncTerminalToContainers = (id: number) => {
    if (!form.getFieldValue('terminal')) {
      message.error('No Terminal Selected');
      return;
    }

    const service = new ServiceWrapper(
      `orders/${id}/syncTerminalToContainers`,
      {
        terminal_id: form.getFieldValue('terminal')?.id,
      },
      app.service.put,
    );

    onApplyToContainers(service);
  };

  const handleSyncWarehouseToContainers = (id: number) => {
    if (!form.getFieldValue('warehouse')) {
      message.error('No Warehouse Selected');
      return;
    }

    onApplyToContainers(
      new ServiceWrapper(
        `orders/${id}/applyWarehouseToContainers`,
        {
          warehouse_id: form.getFieldValue('warehouse')?.id,
          delivery_address: form.getFieldValue('delivery_address'),
        },
        app.service.put,
      ),
    );
  };

  const isOw = (currentWeight: number, compareWeight: number): boolean => {
    if (currentWeight >= compareWeight) {
      return true;
    } else {
      return false;
    }
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.containers) {
      changedValues.containers.map((container: any, index: number) => {
        if (container) {
          for (const i in CONTAINER_RELATED_FIELDS) {
            if (has(container, CONTAINER_RELATED_FIELDS[i])) {
              const { type, weight } = allValues.containers[index];
              const feetType = Number(type?.slice(0, 2) || 0);
              if (!OW_TYPES.includes(feetType)) {
                return;
              }
              const is_reefer =
                type &&
                (String(type).endsWith('RF') ||
                  String(type).endsWith('RH') ||
                  String(type).endsWith('RD'));

              allValues.containers[index].is_reefer = is_reefer;
              switch (feetType) {
                case OW_TYPE_20_FEET:
                  is_reefer &&
                    (allValues.containers[index].is_overweight = isOw(
                      Number(weight),
                      OW_20_REEFER_KG,
                    ));

                  !is_reefer &&
                    (allValues.containers[index].is_overweight = isOw(
                      Number(weight),
                      OW_20_STANDARD_KG,
                    ));

                  form.setFieldValue('containers', allValues.containers);
                  return;
                case OW_TYPE_40_FEET:
                  is_reefer &&
                    (allValues.containers[index].is_overweight = isOw(
                      Number(weight),
                      OW_40_REEFER_KG,
                    ));

                  !is_reefer &&
                    (allValues.containers[index].is_overweight = isOw(
                      Number(weight),
                      OW_40_STANDARD_KG,
                    ));

                  form.setFieldValue('containers', allValues.containers);
                  return;

                case OW_TYPE_45_FEET:
                  is_reefer &&
                    (allValues.containers[index].is_overweight = isOw(
                      Number(weight),
                      OW_45_REEFER_KG,
                    ));

                  !is_reefer &&
                    (allValues.containers[index].is_overweight = isOw(
                      Number(weight),
                      OW_45_STANDARD_KG,
                    ));

                  form.setFieldValue('containers', allValues.containers);
                  return;
                case OW_TYPE_53_FEET:
                  allValues.containers[index].is_overweight = isOw(
                    Number(weight),
                    OW_53_STANDARD_KG,
                  );

                  form.setFieldValue('containers', allValues.containers);
                  return;
                default:
                  return;
              }
            }
          }
        }
      });
    } else if (changedValues.destination_type) {
      if (changedValues.destination_type === OCEAN_PORT) {
        form.setFieldsValue({
          pu_number_agent_id: '',
          who_provide_pu_number: '',
        });
      }
    }
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={handleValuesChange}
        initialValues={{
          pu_number_agent_id: PICKUP_NUMBER_AGENT_MYSELF_ID,
        }}
      >
        <Form.Item noStyle name="id">
          <Input hidden />
        </Form.Item>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label="MBL"
              name="mbl_number"
              rules={[{ required: true, message: 'MBL is required' }]}
            >
              <Input placeholder="Enter mbl" />
            </Form.Item>
            <Form.Item label="Booking#" name="booking_number">
              <Input placeholder="Enter booking number" />
            </Form.Item>
            <Form.Item label="Reference" name="customer_reference_number">
              <Input placeholder="Enter reference" />
            </Form.Item>
            {/* <Form.Item label="Contact" name="contact">
              <Input placeholder="Enter contact" />
            </Form.Item>
            <Form.Item label="Contact Phone" name="contact_phone">
              <Input placeholder="Enter contact phone" />
            </Form.Item> */}
            <Form.Item label="Contact Emails">
              <Form.List
                initialValue={form.getFieldValue('contact_emails') || []}
                name="contact_emails"
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Form.Item label={''} key={field.key}>
                        <Form.Item
                          wrapperCol={{ xs: 10 }}
                          {...field}
                          noStyle
                          //validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input Contact Email',
                            },
                          ]}
                        >
                          <Input
                            minLength={5}
                            placeholder="Contact Email"
                            style={{
                              width: fields.length > 1 ? '95%' : '100%',
                            }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ marginLeft: '10px' }}
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{
                          width: '100%',
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
            <Form.Item
              name="destination_type"
              label={
                <Space>
                  <span>Destination type</span>
                  {!!form.getFieldValue('id') && (
                    <Button
                      type="link"
                      onClick={() =>
                        handleSyncDestinationToContainers(
                          form.getFieldValue('id'),
                        )
                      }
                      size="small"
                      ghost
                    >
                      Apply to Containers
                    </Button>
                  )}
                </Space>
              }
              rules={[
                { required: true, message: 'Destination Type is required.' },
              ]}
            >
              <Radio.Group>
                <Radio value={OCEAN_PORT}>Ocean Port</Radio>
                <Radio value={RAMP_PORT}>Ramp Port</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Port of Discharge" shouldUpdate required>
              {({ getFieldValue, setFieldsValue }) => (
                <Input.Group compact>
                  <Form.Item
                    name="port_of_discharge_id"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: 'Port of Discharge is required',
                      },
                    ]}
                  >
                    <IntermodalRegionSelect
                      placeholder="Port of Discharge"
                      style={{ width: '40%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="port_of_discharge_eta"
                    rules={[{ required: true, message: 'ETA is required' }]}
                  >
                    <Input hidden />
                  </Form.Item>
                  <DatePicker
                    placeholder="ETA"
                    style={{ width: '60%' }}
                    value={
                      getFieldValue('port_of_discharge_eta')
                        ? moment(getFieldValue('port_of_discharge_eta'))
                        : null
                    }
                    onChange={(date, dateString) =>
                      setFieldsValue({
                        port_of_discharge_eta: dateString,
                      })
                    }
                  />
                </Input.Group>
              )}
            </Form.Item>
            <Form.Item label="Final Port" shouldUpdate required>
              {({ getFieldValue, setFieldsValue }) => (
                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name="final_port_id"
                    rules={[
                      { required: true, message: 'Final Port is required' },
                    ]}
                  >
                    <IntermodalRegionSelect
                      disabled={getFieldValue('destination_type') == OCEAN_PORT}
                      placeholder="Select POD / Ramp"
                      style={{ width: '40%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="final_port_eta"
                    rules={[{ required: true, message: 'ETA is required' }]}
                  >
                    <Input hidden />
                  </Form.Item>

                  <DatePicker
                    disabled={getFieldValue('destination_type') == OCEAN_PORT}
                    placeholder="ETA"
                    style={{ width: '60%' }}
                    value={
                      getFieldValue('final_port_eta')
                        ? moment(getFieldValue('final_port_eta'))
                        : null
                    }
                    onChange={(date, dateString) =>
                      setFieldsValue({
                        final_port_eta: dateString,
                      })
                    }
                  />
                </Input.Group>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {() => (
                <Form.Item
                  label="Chassis"
                  name="chassis_owner"
                  initialValue={CHASSIS_OWNER_NO_IDEA}
                  rules={[{ required: false, message: 'Chassis is required' }]}
                >
                  <Select>
                    {Object.keys(CHASSIS_OWNER_MAP).map((key: string) => {
                      return (
                        <Select.Option key={key} value={Number(key)}>
                          {
                            CHASSIS_OWNER_MAP[
                              (key as unknown) as keyof typeof CHASSIS_OWNER_MAP
                            ]
                          }
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {() => (
                <Form.Item label="Project Code" name="project_code">
                  <Input />
                </Form.Item>
              )}
            </Form.Item>
            <Title level={4}>Delivery</Title>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  label={
                    <Space>
                      <span>Warehouse</span>
                      {!!getFieldValue('id') && (
                        <Button
                          type="link"
                          size="small"
                          loading={loading}
                          onClick={() =>
                            handleSyncWarehouseToContainers(getFieldValue('id'))
                          }
                        >
                          {' '}
                          Apply to Containers
                        </Button>
                      )}
                    </Space>
                  }
                  name="warehouse"
                  rules={[{ required: true, message: 'Warehouse is required' }]}
                >
                  <WarehouseSelect
                    selected={getFieldValue('warehouse')}
                    onSelect={(warehouse: any) => {
                      setFieldsValue({
                        warehouse,
                        delivery_address:
                          warehouse?.delivery_order_address || '',
                      });
                    }}
                    forbidToModfiySpecifyField
                    editable
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              label="Address"
              name="delivery_address"
              rules={[{ required: true, message: 'Address is required' }]}
            >
              <TextArea rows={4} placeholder="Enter delivery address" />
            </Form.Item>
          </Col>
          <Col span={11} style={{ marginLeft: '10px' }}>
            {/* <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  label={
                    <Space>
                      Sell Rate ID
                      {getFieldValue('sell_rate_id') && getFieldValue('id') && (
                        <RateLink
                          snapshot={snapshot}
                          rateUid={getFieldValue('sell_rate_id')}
                          hasBanModifyFields={true}
                          showLabel={true}
                        />
                      )}

                      {getFieldValue('sell_rate_id') && getFieldValue('id') && (
                        <Popconfirm
                          title={'Confirm'}
                          onConfirm={() =>
                            handleSyncRate(
                              getFieldValue('id'),
                              getFieldValue('sell_rate_id'),
                            )
                          }>
                          <Button type="link" size="small" ghost>
                            Sync Rate
                          </Button>
                        </Popconfirm>
                      )}
                      {getFieldValue('sell_rate_id') && getFieldValue('id') && (
                        <Button
                          type="link"
                          onClick={() =>
                            handleSyncToAllContainers(
                              getFieldValue('id'),
                              getFieldValue('sell_rate_id'),
                              TYPE_SELL_RATE,
                            )
                          }
                          size="small"
                          ghost>
                          Apply to Containers
                        </Button>
                      )}
                    </Space>
                  }
                  name="sell_rate_id"
                  rules={[
                    { required: false, message: 'Sell Rate ID is required' },
                  ]}>
                  <Input placeholder="Enter sell rate id" />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  label={
                    <Space>
                      Buy Rate ID
                      {getFieldValue('buy_rate_id') && (
                        <RateLink
                          rateUid={getFieldValue('buy_rate_id')}
                          showLabel={true}
                          hasBanModifyFields={true}
                        />
                      )}

                      {getFieldValue('buy_rate_id') && getFieldValue('id') && (
                        <Button
                          type="link"
                          size="small"
                          onClick={() =>
                            handleSyncToAllContainers(
                              getFieldValue('id'),
                              getFieldValue('buy_rate_id'),
                              TYPE_BUY_RATE,
                            )
                          }
                          ghost>
                          Apply to Containers
                        </Button>
                      )}
                    </Space>
                  }
                  name="buy_rate_id">
                  <Input placeholder="Enter buy rate id" />
                </Form.Item>
              )}
            </Form.Item> */}
            <Form.Item
              name="user_id"
              label="User (Customer)"
              rules={[{ required: true, message: 'Customer is required' }]}
            >
              <UserSyncSelect
                statusTypes={{
                  ALL: 0,
                  ENABLED: 2,
                  DISABLED: -1,
                }}
              />
            </Form.Item>
            <Form.Item name="consignee_id" label="Consignee">
              <ConsigneeSyncSelect />
            </Form.Item>
            {/* <Form.Item label="Service Fee" name="service_fee">
              <Input placeholder="Enter service fee" />
            </Form.Item> */}
            <Form.Item noStyle shouldUpdate>
              {() => (
                <Form.Item
                  label="Who will pay terminal fees"
                  name="who_will_pay_terminal_fees"
                  rules={[
                    {
                      required: false,
                      message: 'Who will pay terminal fees is required',
                    },
                  ]}
                >
                  <Select>
                    {Object.keys(WHO_WILL_PAY_TERMINAL_FEES_MAP).map(
                      (key: string) => {
                        return (
                          <Select.Option key={key} value={Number(key)}>
                            {
                              WHO_WILL_PAY_TERMINAL_FEES_MAP[
                                (key as unknown) as keyof typeof WHO_WILL_PAY_TERMINAL_FEES_MAP
                              ]
                            }
                          </Select.Option>
                        );
                      },
                    )}
                  </Select>
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item label="Customer Memo" name="customer_memo" shouldUpdate>
              <Input.TextArea rows={4} />
            </Form.Item>
            <Divider />
            <h4 style={{ marginBottom: '30px', marginTop: '32px' }}>
              Pickup Terminal{' '}
              {!!form.getFieldValue('id') && (
                <Button
                  type="link"
                  size="small"
                  onClick={() =>
                    handleSyncTerminalToContainers(form.getFieldValue('id'))
                  }
                  ghost
                >
                  Apply to Containers
                </Button>
              )}
            </h4>
            <Form.Item
              label={
                <Space>
                  <span className="mr-2">Terminal</span>{' '}
                  {notSameIR ? (
                    <Tooltip title="The IR of the pickup terminal and the final port are different. Please double-check.">
                      <span className="text-danger">
                        <WarningOutlined />
                      </span>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </Space>
              }
              shouldUpdate
              required
            >
              {({ getFieldValue, setFieldsValue }) => (
                <>
                  <Form.Item
                    noStyle
                    name="terminal"
                    rules={[
                      { required: true, message: 'Terminal is required' },
                    ]}
                  >
                    <TerminalSelect
                      selected={getFieldValue('terminal')}
                      onSelect={(terminal: any) => {
                        setFieldsValue({
                          terminal: terminal,
                        });
                      }}
                    />
                  </Form.Item>
                  <Typography.Text className={styles.table_td_weak}>
                    {' '}
                    Please enter TBD if have no idea of the Terminal.
                  </Typography.Text>
                </>
              )}
            </Form.Item>
            <Form.Item label="Address" shouldUpdate>
              {({ getFieldValue }) => (
                <Input.TextArea
                  disabled
                  rows={4}
                  value={getFieldValue('terminal')?.delivery_order_address}
                />
              )}
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) =>
                getFieldValue('destination_type') == RAMP_PORT && (
                  <>
                    <Title level={4}>PU#</Title>
                    <Form.Item
                      name="pu_number_agent_id"
                      label="Pickup Number Agent"
                      rules={[
                        {
                          validator: (_: any, value: number | string) => {
                            if (value === 0 || +value > 0) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('Please Select Pickup Number Agent'),
                            );
                          },
                        },
                      ]}
                    >
                      <PickupNumberAgentSyncSelect
                        pickupNumberAgentId={getFieldValue(
                          'pu_number_agent_id',
                        )}
                        onSelect={(pickupNumberAgent: any) => {
                          form.setFieldValue(
                            'pu_number_agent_id',
                            pickupNumberAgent?.id,
                          );
                        }}
                      />
                    </Form.Item>
                  </>
                )
              }
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4}>
              Containers <Button onClick={handleAddContainer}>Add</Button>
            </Title>
            <Row>
              <Col span={6}>
                <Form.Item label="Container Count" name="container_count">
                  <Input
                    type="number"
                    disabled
                    min="0"
                    max="50"
                    placeholder="Enter container count"
                  />
                </Form.Item>
              </Col>
              <Col>
                {!!form.getFieldValue('id') && (
                  <Popover
                    title="New Sell Rate"
                    trigger="click"
                    content={() => {
                      const [rateId, setRateId] = useState<string>('');
                      return (
                        <>
                          <Form.Item>
                            <Input
                              value={rateId}
                              onChange={(e) => setRateId(e.target.value)}
                            />
                          </Form.Item>
                          <Button
                            type="link"
                            onClick={() =>
                              handleSyncToAllContainers(
                                form.getFieldValue('id'),
                                rateId,
                                TYPE_SELL_RATE,
                              )
                            }
                            size="small"
                          >
                            Confirm
                          </Button>
                        </>
                      );
                    }}
                  >
                    <Form.Item label="&nbsp;">
                      <Button size="small" type="link">
                        Apply New Sell Rate to Containers
                      </Button>
                    </Form.Item>
                  </Popover>
                )}
              </Col>
            </Row>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item name="containers" initialValue={[]}>
                  <ContainerTable
                    containers={getFieldValue('containers')}
                    onChange={(containers) => {
                      setFieldsValue({
                        containers,
                        container_count: containers ? containers.length : 0,
                      });
                    }}
                    matchCurrentRate={handleMatchCurrentRate}
                    handleOpenSellRate={handleOpenSellRate}
                    handleOpenBuyRate={handleOpenBuyRate}
                    containerRemoveableOnEdit={false}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {openBuyRate && buyRateId && (
        <BuyRateDrawer
          isNew={false}
          disabled={true}
          id={buyRateId}
          open={openBuyRate}
          // onSaved={() => { }}
          onClose={handleCloseBuyRate}
        />
      )}

      {/* {openSellRate && sellRateId && (
          <SellRateDrawer
            visible={openSellRate}
            disabled={true}
            id={sellRateId}
            // onSaved={() => { }}
            onClose={handleCloseSellRate}
          />
        )} */}
    </div>
  );
};
