import { useApp } from '@/utils/useapp';
import { message, Tabs, Card, Spin, Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import BillForm from '../../../bills/components/BillDrawer/BillForm';
import { ApproveButton } from '@/pages/accounting/bills/components/BillDrawer/ApproveButton';
import React from 'react';
import { BILL_TYPE_ORDER } from '@/pages/accounting/bills/components/data';
import { showErrorMessage } from '@/utils/show-error-message';

interface IBillTab {
  invoiceableId: number;
  invoiceType: string;
  invoice?: any;
  containerNumber: string;
  setBills: (bills: any) => void;
}

export const BillsTab: React.FC<IBillTab> = ({
  invoiceableId,
  invoiceType,
  invoice,
  containerNumber,
  setBills,
  ...props
}) => {
  const app = useApp();

  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);
  const [tabs, setTabs] = React.useState<any>([]);
  const [containers, setContainers] = React.useState<Array<any>>([]);

  const fetchContainers = async () => {
    const resp = await app.service.get(`orders/${invoiceableId}/containers`);
    setContainers(resp.data);
  };

  const fetchBillData = async (billId: number) => {
    setLoading(true);

    try {
      const resp = await app.service.get(`orderBills/${billId}`);

      form.setFieldsValue(resp.data);

      fetchContainers();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const getEndpoint = () => {
    return invoiceType === BILL_TYPE_ORDER
      ? 'orderBills/getOrderBillsByOrderId'
      : 'orderBills/getOrderBillsByBillable';
  };

  const fetchBills = async () => {
    if (!invoiceableId) {
      return;
    }
    setLoading(true);
    try {
      const endpoint = getEndpoint();
      const params = {
        [invoiceType === BILL_TYPE_ORDER
          ? 'order_id'
          : 'invoiceable_id']: invoiceableId,
        [invoiceType === BILL_TYPE_ORDER
          ? ''
          : 'invoiceable_type']: invoiceType,
        container_number: containerNumber || undefined,
        invoice_id: invoice?.id || undefined,
      };
      const result = await app.service.get(endpoint, {
        params,
      });

      setTabs(result.data);

      setBills(result.data);
      result.data[0] && fetchBillData(result.data[0].id);
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const handleApprovedOrUnApproved = (billId: number) => {
    fetchBillData(billId);
  };

  const handleChangeTab = (activeKey: string) => {
    fetchBillData(Number(activeKey));
  };

  React.useEffect(() => {
    fetchBills();
  }, [invoiceableId]);

  const items = React.useMemo(
    () =>
      tabs.map((bill: any) => {
        return {
          label: `Bill ${bill.id}`,
          key: '' + bill.id,
          children: (
            <Card
              {...props}
              style={{
                maxHeight: invoiceType === BILL_TYPE_ORDER ? '45vh' : '',
                overflowY: 'scroll',
              }}
            >
              <div className="flex justify-end">
                {invoiceType === BILL_TYPE_ORDER ? (
                  <ApproveButton
                    form={form}
                    bill={bill}
                    // billId={bill.id}
                    onApprovedOrUnApproved={handleApprovedOrUnApproved}
                    size={'small'}
                    onSetLoading={setLoading}
                  />
                ) : (
                  <Button disabled>
                    {bill.is_approved ? 'Approved' : 'Unapproved'}
                  </Button>
                )}
              </div>
              <BillForm
                form={form}
                disabled={true}
                containers={containers}
                billableType={invoiceType}
              />
            </Card>
          ),
        };
      }),
    [tabs, invoiceType],
  );

  return (
    <Spin spinning={loading}>
      {tabs.length > 0 ? (
        <Tabs type="card" items={items} onChange={handleChangeTab} />
      ) : (
        <Card style={{ width: '100%', textAlign: 'center' }}> Empty Bill</Card>
      )}
    </Spin>
  );
};
