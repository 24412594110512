import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  message,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Header } from './components/Header';
import { Filter } from './components/Filter';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { get } from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
// import { DrawerForm } from './components/DrawerForm';
import { VendorDrawer } from './components/VendorDrawer';
import { RatingTag } from '@/components/RatingTag';
import AutoResizeTable from '@/components/AutoResizeTable';
import { LogActivities } from '@/components/LogActivities';
import { useVendorRateDrawer } from './components/useVendorRateDrawer';
import { useParams } from 'umi';
import { useContractRateDrawer } from './components/contractRate';
import { useContractRatesDrawer } from './components/contractRates';
import usePagination from '@/components/usePagination';
import { TCollection } from '@/types';
import { AuditOutlined } from '@ant-design/icons';
import { Contacts } from './components/Contacts';
import { VendorCell } from '@/components/VendorCell';
import VendorRating from '@/pages/dispatchs/components/VendorRating';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [data, setData] = useState<TCollection<any>>();

  const [blacklistedVender, setBlacklistedVender] = useState<any>(null);

  const pagination = usePagination(data);

  const [vendor, setVendor] = useState<any>(null);
  const {
    VendorRateFormDrawer,
    openVendorRateFormDrawer,
  } = useVendorRateDrawer();

  const {
    ContractRatesTableDrawer,
    openContractRatesTableDrawer,
  } = useContractRatesDrawer();
  const params: any = useParams();

  const showDrawer = () => {
    setVisible(true);
  };

  const handleNewVendor = () => {
    setVendor(null);
    showDrawer();
  };

  const handleFilterChange = (values: any) => {
    setFilter(values);
  };

  const handleReplace = async (vendor: any) => {
    if (!data) {
      return;
    }
    const collection = data.data.map((item) => {
      if (item.id === vendor.id) {
        return vendor;
      }
      return item;
    });
    setData({ ...data, data: collection });
  };

  const handlePatchUpdate = useCallback(
    async (id: number, value: any) => {
      setLoading(true);
      try {
        const resp = await app.service.patch(`vendors/${id}`, { data: value });
        const vendor = resp.data;
        handleReplace(vendor);
      } catch (error) {
        message.error('Error updating vendor');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const handelCloseDrawer = () => {
    setVisible(false);
    setVendor(null);
  };

  const handleSetEnabled = useCallback(
    async (id: string, enabled: boolean) => {
      setLoading(true);
      try {
        await app.service.put(`vendors/${id}/setEnabled`, {
          data: { enabled },
        });
        const vendor = data?.data.find((r) => r.id == id);
        vendor.enabled = enabled;
        handleReplace(vendor);
      } catch (error) {
        message.error('Error updating vendor');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const handleSetBlacklisted = useCallback(
    async (id: string, blacklisted: boolean) => {
      if (!blacklisted) {
        setLoading(true);
        try {
          const resp = await app.service.put(`vendors/${id}/setBlacklisted`, {
            data: { blacklisted },
          });
          const vendor = resp.data;
          handleReplace(vendor);
        } catch (error) {
          message.error('Error updating vendor');
        }
      } else {
        setBlacklistedVender({
          id,
          reason: '',
        });
      }
    },
    [data],
  );

  const handleSetPromo = useCallback(
    async (id: string, promo: boolean) => {
      setLoading(true);
      try {
        const resp = await app.service.put(`vendors/${id}/setPromo`, {
          data: { promo },
        });
        const vendor = resp.data;
        handleReplace(vendor);
      } catch (error) {
        message.error('Error updating vendor');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const handleConfirmSetBlacklisted = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await app.service.put(
        `vendors/${blacklistedVender.id}/setBlacklisted`,
        { data: { blacklisted: true, reason: blacklistedVender.reason } },
      );
      const vendor = resp.data;
      handleReplace(vendor);
      setBlacklistedVender(null);
    } catch (error) {
      message.error('Error updating vendor');
    } finally {
      setLoading(false);
    }
  }, [blacklistedVender]);

  const handleSetFavorited = useCallback(
    async (id: string, favorited: boolean) => {
      setLoading(true);
      try {
        const resp = await app.service.put(`vendors/${id}/setFavorited`, {
          data: { favorited },
        });
        const vendor = resp.data;
        handleReplace(vendor);
      } catch (error) {
        message.error('Error updating vendor');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const fetchData = async (pagination?: TablePaginationConfig) => {
    const _filter = Object.assign({}, filter);
    if (_filter.vendor_division) {
      _filter.vendor_division_id = _filter.vendor_division.id;
      delete _filter.vendor_division;
    }
    setLoading(true);
    try {
      const resp = await app.service.get('vendors', {
        params: {
          ..._filter,
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 20,
        },
      });
      setData(resp);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = (id: number) => {
    app.service
      .delete(`vendors/${id}`)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    setVisible(true);
    setVendor(id);
  };

  const handleSearch = () => {
    fetchData();
  };

  const handleSave = () => {
    fetchData();
    setVendor(null);
  };

  useEffect(() => {
    if (params.id) {
      openVendorRateFormDrawer(params.id);
    }
    fetchData();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      key: 'id',
      width: 80,
      render: (text, record) => <>{record.id}</>,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      width: 300,
      render: (text, record) => (
        <VendorCell
          vendor={record}
          showVendorDetail={handleEdit}
          onSaved={fetchData}
        />
      ),
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      width: 120,
      render: (text, record) => {
        return (
          <Popover
            trigger="click"
            destroyTooltipOnHide
            content={
              <VendorRating
                vendor_id={record.id || 0}
                score={record.score || 'N/A'}
              />
            }
          >
            <RatingTag rating={record?.score} />
          </Popover>
        );
      },
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 160,
      render: (text, record) => {
        return <>{get(record, 'city.full_name', '')}</>;
      },
    },
    {
      title: 'FBA Delivery',
      dataIndex: 'fba_delivery',
      key: 'fba_delivery',
      width: 100,
      render: (text, record) => {
        return (
          <>
            {record.fba_delivery ? (
              <Tag color="green">Yes</Tag>
            ) : (
              <Tag color="red">No</Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      key: 'contacts',
      width: 200,
      render: (text, record) => <Contacts vendor={record} />,
    },
    // {
    //   title: 'Phone',
    //   dataIndex: 'phone',
    //   key: 'phone',
    //   width: 160,
    // },
    // {
    //   title: 'Contact',
    //   dataIndex: 'contact',
    //   key: 'contact',
    //   width: 100,
    // },
    // {
    //   title: 'Email',
    //   dataIndex: 'email',
    //   key: 'email',
    //   width: 100,
    // },
    {
      title: 'Terms',
      dataIndex: 'terms',
      key: 'terms',
      width: 60,
      render: (text, record) => {
        return <>{record.terms || 0}</>;
      },
    },
    {
      title: 'MC#',
      dataIndex: 'trucker_mc',
      key: 'trucker_mc',
      width: 100,
    },
    {
      title: 'DOT#',
      dataIndex: 'us_dot',
      key: 'us_dot',
      width: 100,
    },
    {
      title: 'SCAC#',
      dataIndex: 'scac_code',
      key: 'scac_code',
      width: 100,
    },
    {
      title: 'Own Chassis',
      dataIndex: 'has_own_chassis',
      key: 'has_own_chassis',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.has_own_chassis}
              onClick={(v) =>
                handlePatchUpdate(record.id, { has_own_chassis: v })
              }
            />
          </>
        );
      },
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.enabled}
              onClick={(v) => handleSetEnabled(record.id, v)}
            />
          </>
        );
      },
    },
    {
      title: 'Favorited',
      dataIndex: 'favorited',
      key: 'favorited',
      width: 160,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.favorited}
              onClick={(v) => handleSetFavorited(record.id, v)}
            />
          </>
        );
      },
    },
    {
      title: 'Blacklisted',
      dataIndex: 'blacklisted',
      key: 'blacklisted',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.blacklisted}
              onClick={(v) => handleSetBlacklisted(record.id, v)}
            />
          </>
        );
      },
    },
    {
      title: 'Promo',
      dataIndex: 'promo',
      key: 'promo',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) => handleSetPromo(record.id, v)}
            />
          </>
        );
      },
    },
    {
      title: 'Update PC Date',
      dataIndex: 'update_possible_charges_at',
      key: 'update_possible_charges_at',
      width: 100,
    },
    {
      title: 'CreatedAt',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 100,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 250,
      render: (text, record) => (
        <Space>
          <a onClick={() => openContractRatesTableDrawer(record)}>Rates</a>
          <Divider type="vertical" />
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <LogActivities id={record.id} type={'vendors'} />
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="Vendor"
        rightElement={
          <Button type="primary" onClick={handleNewVendor}>
            New Vendor
          </Button>
        }
      ></Header>

      <Filter
        loading={loading}
        filter={filter}
        onSearch={handleSearch}
        onChange={handleFilterChange}
      >
        {' '}
      </Filter>
      <div>
        <AutoResizeTable
          loading={loading}
          pagination={pagination}
          size="small"
          rowKey="id"
          columns={columns}
          onChange={(pagination) => {
            fetchData(pagination);
          }}
          dataSource={data?.data}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>
      <VendorDrawer
        id={vendor}
        onSaved={handleSave}
        onClose={handelCloseDrawer}
        visible={visible}
      />
      <Modal
        title="Reason"
        open={!!blacklistedVender}
        onOk={handleConfirmSetBlacklisted}
        okText="Comfirm"
        onCancel={() => {
          setBlacklistedVender(null);
        }}
        cancelText="Cancel"
      >
        <TextArea
          rows={4}
          value={blacklistedVender?.reason || ''}
          onChange={(e) =>
            setBlacklistedVender({
              ...blacklistedVender,
              reason: e.target.value,
            })
          }
        />
      </Modal>
      <ContractRatesTableDrawer />
      <VendorRateFormDrawer />
    </div>
  );
};

export default Index;
