import { FC, useEffect, useMemo, useState } from 'react';
import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  TimePicker,
  message,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { ShipmentParams } from './QuoteForm';
import { debounce } from 'lodash';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import TopCard from './TopCard';
import { ShipmentBusinessStatus, ShipmentTimeType } from '../constants';

import { packageingTypes } from '@/utils/freight';
import { FTLModelSelect } from './FTLModelSelect';
import { TLVendorSelect } from '@/components/TLVendorSelect';
import { TLType } from '@/components/constants';
import { TCity } from '@/types';
import { AIfillBtn } from '@/components/AIFillButton';
import { useFetchDistance } from '../../hooks/useFetchDistance';
import TextArea from 'antd/lib/input/TextArea';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  params?: ShipmentParams | null;
  model?: any;
  form?: FormInstance | null;
  loading?: boolean;
}

const ShipmentForm: FC<Props> = ({ params, model, loading, form = null }) => {
  const rate = params?.rate;
  const quoteFields = params?.quoteFields;

  const manualMode = !params;
  if (!form) {
    [form] = useForm();
  }

  const disabled = useMemo(
    () =>
      loading ||
      model?.business_status >= ShipmentBusinessStatus.PENDING_PICKUP,
    [loading, model],
  );

  const app = useApp();

  const isCustomerDisabled = useMemo(() => {
    if (!model) {
      return false;
    }

    if (app.store.auth.superAdminBen) {
      return (
        !manualMode ||
        model?.business_status >= ShipmentBusinessStatus.PENDING_PICKUP
      );
    }

    return true;
  }, [app.store.auth, model, manualMode]);

  const [pickupCityList, setPickupCityList] = useState<TCity[]>([]);
  const [destinationCityList, setDestinationCityList] = useState<TCity[]>([]);

  const pickupZipcode = useWatch('pickup_zipcode', form);
  const destinationZipcode = useWatch('destination_zipcode', form);

  useFetchDistance(form);

  const debouncePickupCityFetcher = useMemo(() => {
    const fetchPickupCityList = async (pickup_zipcode: string) => {
      try {
        const resp = await app.service.get(
          `tl/tools/citylist/${pickup_zipcode}`,
        );
        setPickupCityList(resp.data);
      } catch (e: any) {
        showErrorMessage(e);
      }
    };
    return debounce(fetchPickupCityList, 300);
  }, []);

  const debounceDestinationCityFetcher = useMemo(() => {
    const fetchDestinationCityList = async (destination_zipcode: string) => {
      try {
        const resp = await app.service.get(
          `tl/tools/citylist/${destination_zipcode}`,
        );
        setDestinationCityList(resp.data);
      } catch (e: any) {
        showErrorMessage(e);
      }
    };
    return debounce(fetchDestinationCityList, 300);
  }, []);

  const calcuTotalSellRate = (
    sellRate: number | null,
    premium: number | null,
  ) => {
    const totalSellRate =
      (sellRate ? Number(sellRate) : 0) + (premium ? Number(premium) : 0);
    form.setFieldValue('total_sell_rate', totalSellRate);
  };

  const onFormValueChange = (changedValues: any) => {
    if (changedValues.pickup_zipcode) {
      debouncePickupCityFetcher(changedValues.pickup_zipcode);
    }

    if (changedValues.destination_zipcode) {
      debounceDestinationCityFetcher(changedValues.destination_zipcode);
    }
  };

  useEffect(() => {
    if (pickupZipcode) {
      debouncePickupCityFetcher(pickupZipcode);
    }
  }, [pickupZipcode]);

  useEffect(() => {
    if (destinationZipcode) {
      debounceDestinationCityFetcher(destinationZipcode);
    }
  }, [destinationZipcode]);

  useEffect(() => {
    if (manualMode) {
      form.setFieldsValue({
        ...quoteFields,
      });
    } else {
      form.setFieldsValue({
        ...quoteFields,
        quote_rate: rate?.quote_rate,
        estimated_pickup_date: quoteFields.pickup_date,
      });
    }
  }, [quoteFields, rate]);

  return (
    <>
      <Form
        layout="vertical"
        disabled={disabled}
        onValuesChange={onFormValueChange}
        form={form}
      >
        {rate && <TopCard rate={rate} />}
        <Space direction="vertical">
          {manualMode ? (
            <AIfillBtn
              form={form}
              setPickupCityList={setPickupCityList}
              setDestinationCityList={setDestinationCityList}
              formType="shipment"
            />
          ) : (
            <AIfillBtn
              form={form}
              fields={[
                'pickup_company_name',
                'pickup_contact_name',
                'pickup_contact_email',
                'pickup_contact_phone',
                'pickup_address1',
                'pickup_address2',
                'pickup_special_request',
                'pickup_open_time',
                'pickup_close_time',
                'destination_company_name',
                'destination_contact_name',
                'destination_contact_email',
                'destination_contact_phone',
                'destination_address1',
                'destination_address2',
                'destination_special_request',
                'destination_open_time',
                'destination_close_time',
                'pickup_number',
                'dropoff_number',
                'remarks',
              ]}
              setPickupCityList={setPickupCityList}
              setDestinationCityList={setDestinationCityList}
              formType="shipment"
            />
          )}
          <Row gutter={48}>
            <Col span={12}>
              <Card
                title="PICKUP"
                size="small"
                bordered={true}
                style={{
                  borderColor: '#d2ebff',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                headStyle={{
                  backgroundColor: '#d2ebff',
                  fontWeight: 'bold',
                }}
              >
                <Row gutter={32}>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_company_name"
                      label="Facility Name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter facility name." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_time_type"
                      label="Time Type"
                      initialValue={ShipmentTimeType.WINDOW}
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Select time type">
                        <Select.Option value={ShipmentTimeType.WINDOW}>
                          Window
                        </Select.Option>
                        <Select.Option value={ShipmentTimeType.SPECIFIC}>
                          Specific
                        </Select.Option>
                        <Select.Option value={ShipmentTimeType.BEHALF}>
                          Behalf
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.pickup_time_type !==
                        currentValues.pickup_time_type
                      }
                    >
                      {({ getFieldValue }) => (
                        <>
                          <Space direction="horizontal">
                            <Form.Item
                              name="pickup_open_time"
                              label="Open Time"
                              initialValue={moment('08:00', 'HH:mm')}
                              rules={[
                                {
                                  required:
                                    getFieldValue('pickup_time_type') ===
                                    ShipmentTimeType.WINDOW,
                                },
                              ]}
                              hidden={
                                getFieldValue('pickup_time_type') !==
                                ShipmentTimeType.WINDOW
                              }
                            >
                              <TimePicker
                                format={'HH:mm'}
                                suffixIcon={null}
                                placeholder={'Pickup open time'}
                                minuteStep={15}
                                style={{
                                  width: 100,
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              name="pickup_close_time"
                              label="Close Time"
                              initialValue={moment('21:00', 'HH:mm')}
                              rules={[
                                {
                                  required:
                                    getFieldValue('pickup_time_type') ===
                                    ShipmentTimeType.WINDOW,
                                },
                              ]}
                              hidden={
                                getFieldValue('pickup_time_type') !==
                                ShipmentTimeType.WINDOW
                              }
                            >
                              <TimePicker
                                format={'HH:mm'}
                                suffixIcon={null}
                                placeholder={'Pickup close time'}
                                minuteStep={15}
                                style={{
                                  width: 100,
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              name="pickup_specific_time"
                              label="Specific Time"
                              rules={[
                                {
                                  required:
                                    getFieldValue('pickup_time_type') ===
                                    ShipmentTimeType.SPECIFIC,
                                },
                              ]}
                              hidden={
                                getFieldValue('pickup_time_type') !==
                                ShipmentTimeType.SPECIFIC
                              }
                            >
                              <TimePicker
                                format={'HH:mm'}
                                suffixIcon={null}
                                placeholder={'Pickup specific time'}
                                minuteStep={15}
                                style={{
                                  width: 100,
                                }}
                              />
                            </Form.Item>
                          </Space>
                        </>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_contact_name"
                      label="Contact name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter name." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_contact_email"
                      label="Contact email"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter email." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Space.Compact>
                      <Form.Item
                        name="pickup_contact_phone"
                        label="Contact phone"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Enter phone." />
                      </Form.Item>
                      <Form.Item
                        label=" "
                        name="pickup_contact_phone_ext"
                        rules={[{ required: false }]}
                      >
                        <Input placeholder="Ext." />
                      </Form.Item>
                    </Space.Compact>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Zipcode"
                      name="pickup_zipcode"
                      rules={[{ required: true }]}
                    >
                      <Input
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        placeholder="Enter zipcode."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label="City"
                      name="pickup_city_id"
                      rules={[{ required: true }]}
                    >
                      <Select
                        allowClear
                        placeholder="Please select city"
                        options={pickupCityList.map((item) => ({
                          value: item.id,
                          label: `${item.name}, ${item.state}`,
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={16}>
                    <Form.Item
                      name="pickup_address1"
                      label="Address line1"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter address line1." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pickup_address2" label="Address line2">
                      <Input placeholder="Enter address line2." />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="pickup_special_request"
                      label="Special requirement"
                      rules={[{ max: 500 }]}
                    >
                      <TextArea
                        disabled={disabled}
                        placeholder="Enter special requirement."
                        autoSize={{ minRows: 1 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                title="DESTINATION"
                size="small"
                style={{
                  borderColor: '#daf8f6',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                headStyle={{ backgroundColor: '#daf8f6', fontWeight: 'bold' }}
              >
                <Row gutter={32}>
                  <Col span={8}>
                    <Form.Item
                      name="destination_company_name"
                      label="Facility name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter facility name." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_time_type"
                      label="Time Type"
                      initialValue={ShipmentTimeType.WINDOW}
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Select time type">
                        <Select.Option value={ShipmentTimeType.WINDOW}>
                          Window
                        </Select.Option>
                        <Select.Option value={ShipmentTimeType.SPECIFIC}>
                          Specific
                        </Select.Option>
                        <Select.Option value={ShipmentTimeType.BEHALF}>
                          Behalf
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.destination_time_type !==
                        currentValues.destination_time_type
                      }
                    >
                      {({ getFieldValue }) => (
                        <>
                          <Space direction="horizontal">
                            <Form.Item
                              name="destination_open_time"
                              label="Open Time"
                              initialValue={moment('08:00', 'HH:mm')}
                              rules={[
                                {
                                  required:
                                    getFieldValue('destination_time_type') ===
                                    ShipmentTimeType.WINDOW,
                                },
                              ]}
                              hidden={
                                getFieldValue('destination_time_type') !==
                                ShipmentTimeType.WINDOW
                              }
                            >
                              <TimePicker
                                format={'HH:mm'}
                                suffixIcon={null}
                                placeholder={'Destination open time'}
                                minuteStep={15}
                                style={{
                                  width: 100,
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              name="destination_close_time"
                              label="Close Time"
                              initialValue={moment('21:00', 'HH:mm')}
                              rules={[
                                {
                                  required:
                                    getFieldValue('destination_time_type') ===
                                    ShipmentTimeType.WINDOW,
                                },
                              ]}
                              hidden={
                                getFieldValue('destination_time_type') !==
                                ShipmentTimeType.WINDOW
                              }
                            >
                              <TimePicker
                                format={'HH:mm'}
                                suffixIcon={null}
                                placeholder={'Destination close time'}
                                minuteStep={15}
                                style={{
                                  width: 100,
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              name="destination_specific_time"
                              label="Specific Time"
                              rules={[
                                {
                                  required:
                                    getFieldValue('destination_time_type') ===
                                    ShipmentTimeType.SPECIFIC,
                                },
                              ]}
                              hidden={
                                getFieldValue('destination_time_type') !==
                                ShipmentTimeType.SPECIFIC
                              }
                            >
                              <TimePicker
                                format={'HH:mm'}
                                suffixIcon={null}
                                placeholder={'Destination specific time'}
                                minuteStep={15}
                                style={{
                                  width: 100,
                                }}
                              />
                            </Form.Item>
                          </Space>
                        </>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_contact_name"
                      label="Contact name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter name." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_contact_email"
                      label="Contact email"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter email." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Space.Compact>
                      <Form.Item
                        name="destination_contact_phone"
                        label="Contact phone"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Enter phone." />
                      </Form.Item>
                      <Form.Item
                        label=" "
                        name="destination_contact_phone_ext"
                        rules={[{ required: false }]}
                      >
                        <Input placeholder="Ext." />
                      </Form.Item>
                    </Space.Compact>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      label="Zip"
                      name="destination_zipcode"
                      rules={[{ required: true }]}
                    >
                      <Input
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        placeholder="Enter zipcode."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label="City"
                      name="destination_city_id"
                      rules={[{ required: true }]}
                    >
                      <Select
                        allowClear
                        placeholder="Please select city"
                        options={destinationCityList.map((item) => ({
                          value: item.id,
                          label: `${item.name}, ${item.state}`,
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={16}>
                    <Form.Item
                      name="destination_address1"
                      label="Address line1"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter address line1." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_address2"
                      label="Address line2"
                    >
                      <Input placeholder="Enter address line2." />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="destination_special_request"
                      label="Special requirement"
                      rules={[{ max: 500 }]}
                    >
                      <TextArea
                        disabled={disabled}
                        placeholder="Enter special requirement."
                        autoSize={{ minRows: 1 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Space>
                <Form.Item
                  label="Commodity"
                  name="commodity_name"
                  rules={[
                    { required: true, message: 'Missing total commodity name' },
                  ]}
                >
                  <Input placeholder="Enter commodity." />
                </Form.Item>
                <Form.Item
                  name={'total_weight'}
                  initialValue={0}
                  label={'Total weight'}
                  rules={[{ required: true, message: 'Missing total weight' }]}
                >
                  <InputNumber
                    min={0}
                    placeholder="Total weight"
                    disabled={
                      model?.business_status >=
                      ShipmentBusinessStatus.PENDING_PICKUP
                    }
                    addonAfter={
                      <Form.Item
                        name={'weight_unit'}
                        initialValue={'lbs'}
                        noStyle
                      >
                        <Select
                          style={{
                            width: 64,
                          }}
                          options={[
                            {
                              value: 'kg',
                              label: 'kg',
                            },
                            {
                              value: 'lbs',
                              label: 'lbs',
                            },
                          ]}
                          disabled={
                            model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                          }
                        />
                      </Form.Item>
                    }
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Total Units"
                  name="total_units"
                  initialValue={0}
                  rules={[{ required: true }]}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  name={'package_type'}
                  label={'Package Type'}
                  initialValue={'Pallet'}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Select package type."
                    options={packageingTypes.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    disabled={
                      model?.business_status >=
                      ShipmentBusinessStatus.PENDING_PICKUP
                    }
                    style={{
                      width: 200,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={'cartons'}
                  label={'Cartons'}
                  initialValue={null}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item label="Distance" name="distance">
                  <InputNumber
                    disabled
                    style={{
                      width: '100%',
                    }}
                    addonAfter="miles"
                  />
                </Form.Item>
              </Space>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Space direction="horizontal" size={16}>
                <Form.Item
                  label="Est Pickup Date"
                  name="estimated_pickup_date"
                  rules={[{ required: true }]}
                  getValueFromEvent={(onChange) =>
                    onChange ? moment(onChange).format('YYYY-MM-DD') : null
                  }
                  getValueProps={(v) => ({ value: v ? moment(v) : null })}
                >
                  <DatePicker
                    allowClear={false}
                    disabled={
                      !manualMode ||
                      model?.business_status >=
                        ShipmentBusinessStatus.PENDING_PICKUP
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  name="user_id"
                  label="Customer"
                  rules={[{ required: true }]}
                >
                  <UserSyncSelect
                    // disabled={
                    //   !manualMode ||
                    //   model?.business_status >=
                    //   ShipmentBusinessStatus.PENDING_PICKUP
                    // }
                    disabled={isCustomerDisabled}
                    style={{ width: 160 }}
                  />
                </Form.Item>
                <Form.Item name="operator_id" label="Operator">
                  <UserSyncSelect
                    style={{ width: 160 }}
                    type="admin"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="sales_id" label="Sales">
                  <UserSyncSelect
                    style={{ width: 160 }}
                    type="admin"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="sales_support_id" label="Sales Support">
                  <UserSyncSelect
                    style={{ width: 160 }}
                    type="admin"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="quote_rate" label="Quote rate">
                  <InputNumber
                    disabled={
                      !manualMode ||
                      model?.business_status >=
                        ShipmentBusinessStatus.PENDING_PICKUP
                    }
                  />
                </Form.Item>

                {manualMode ? (
                  <>
                    <Form.Item name="vendor_rate" label="Buy rate">
                      <InputNumber min={0} max={99999.99} />
                    </Form.Item>
                    <Form.Item
                      name="sell_rate"
                      label="Sell rate"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        min={0}
                        disabled={form.getFieldValue('id')}
                        onChange={(val: number | null) => {
                          calcuTotalSellRate(
                            val,
                            form.getFieldValue('premium'),
                          );
                        }}
                      />
                    </Form.Item>
                    {/* <Form.Item
                      name="premium"
                      label="Premium"
                      rules={[{ required: false }]}
                    >
                      <InputNumber disabled min={0} />
                    </Form.Item> */}
                    <Form.Item
                      name="total_sell_rate"
                      label="Total SR"
                      rules={[{ required: true }]}
                    >
                      <InputNumber disabled min={0} />
                    </Form.Item>
                  </>
                ) : (
                  <> </>
                )}
                <Form.Item name="quote_transit_days" label="Transit Days">
                  <InputNumber min={0} max={100} addonAfter="days" />
                </Form.Item>
                <Form.Item
                  label="Est Delivery Date"
                  name="estimated_delivery_date"
                  getValueFromEvent={(onChange) =>
                    onChange ? moment(onChange).format('YYYY-MM-DD') : null
                  }
                  getValueProps={(v) => ({ value: v ? moment(v) : null })}
                >
                  <DatePicker
                    disabled={
                      !manualMode ||
                      model?.business_status >=
                        ShipmentBusinessStatus.PENDING_PICKUP
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Space>
            </Col>
          </Row>
          {manualMode ? (
            <Row gutter={16}>
              <Col span={24}>
                <Space>
                  <Form.Item
                    name="vendor_id"
                    label="Vendor"
                    rules={[{ required: true }]}
                  >
                    <TLVendorSelect
                      tlType={TLType.FTL}
                      style={{ minWidth: '200px' }}
                      selected={form.getFieldValue('vendor_id')}
                      onSelect={(vendor: any) =>
                        form.setFieldValue('vendor_id', vendor?.id || null)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="carrier_id"
                    label="Carrier"
                    rules={[{ required: true }]}
                  >
                    <FTLModelSelect identify="carrier" />
                  </Form.Item>
                  <Form.Item
                    name="vendor_shipment_id"
                    label="Vendor Shipment ID"
                  >
                    <Input placeholder="Enter vendor shipment id." />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          ) : (
            <Row gutter={16}>
              <Col span={24}>
                <Space>
                  <Form.Item name="carrier_id" label="Carrier">
                    <FTLModelSelect identify="carrier" />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          )}
          <Row gutter={16}>
            <Col span={3}>
              <Form.Item
                label="Customer Reference Number"
                name="customer_reference_number"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Pickup Number" name="pickup_number">
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Dropoff Number" name="dropoff_number">
                <Input />
              </Form.Item>
            </Col>
            {/* <Col span={9}>
              <Form.Item
                name="is_insurance_entrusted"
                hidden
                noStyle
              ></Form.Item>
              <Form.Item name="cargo_value" hidden noStyle></Form.Item>
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    label={'Insurance'}
                    rules={[{ required: true, message: 'Required.' }]}
                  >
                    <Radio.Group
                      value={getFieldValue('is_insurance_entrusted')}
                      onChange={(event: RadioChangeEvent) => {
                        const isEntrusted = event.target.value;

                        if (isEntrusted) {
                          form.setFieldsValue({
                            is_insurance_entrusted: true,
                          });
                        } else {
                          form.setFieldsValue({
                            is_insurance_entrusted: false,
                            cargo_value: 0,
                            premium: 0,
                          });
                        }
                      }}
                    >
                      <Radio value={true}>
                        Purchase insurance by DrayEasy{' '}
                        <InputNumber
                          style={{ width: '8rem' }}
                          min={1}
                          size="small"
                          max={10000}
                          value={getFieldValue('cargo_value')}
                          placeholder="Cargo Value: max 10000usd"
                          disabled={
                            disabled || !getFieldValue('is_insurance_entrusted')
                          }
                          onChange={(val: number | null) => {
                            const premium = val ? calcPremium(val) : 0;
                            form.setFieldsValue({
                              cargo_value: val,
                              premium,
                            });
                            calcuTotalSellRate(
                              form.getFieldValue('sell_rate'),
                              premium,
                            );
                          }}
                        />
                        <Typography.Text type="secondary">
                          {' '}
                          Premium:{getFieldValue('premium')}
                        </Typography.Text>
                      </Radio>
                      <Radio value={false}>Basic Carrier Insurance </Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              </Form.Item>
            </Col> */}
          </Row>
          <Row>
            <Col span={20}>
              <Form.Item label="Remarks" name="remarks">
                <Input.TextArea
                  showCount
                  maxLength={500}
                  rows={4}
                  autoSize={{ minRows: 4, maxRows: 8 }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </Form>
    </>
  );
};

export default ShipmentForm;
