import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import formatNumber from '@/utils/formatNumber';
import {
  InsuranceInterface,
  INSURANCE_STATUS_INSURED,
  INSURANCE_STATUS_PENDING,
  INSURANCE_STATUS_REJECTED,
} from './Interfaces/InsuranceInterface';

const InsuranceDetail: React.FC<{ insurance: InsuranceInterface }> = ({
  insurance,
}) => (
  <>
    <p>Policy Number: {insurance.policy_number}</p>
    <p>Status: {insurance.status}</p>
    <p>Premium: ${formatNumber(insurance.premium_in_usd)}</p>
  </>
);

const InsuranceRejectedDetail: React.FC<{ insurance: InsuranceInterface }> = ({
  insurance,
}) => (
  <>
    <p>Rejected Reason: {insurance.rejected_reason}</p>
  </>
);

const InsuranceIcon: React.FC<{
  insurance: InsuranceInterface;
}> = ({ insurance }) => {
  switch (insurance.status) {
    case INSURANCE_STATUS_PENDING:
      return (
        <Popover
          placement="right"
          content={<InsuranceDetail insurance={insurance} />}
          title="Insurance"
        >
          <span className="text-warning">
            <InfoCircleOutlined />
          </span>
        </Popover>
      );
    case INSURANCE_STATUS_INSURED:
      return (
        <Popover
          placement="right"
          content={<InsuranceDetail insurance={insurance} />}
          title="Insurance"
        >
          <span className="text-success">
            <InfoCircleOutlined />
          </span>
        </Popover>
      );
    case INSURANCE_STATUS_REJECTED:
      return (
        <Popover
          placement="right"
          content={<InsuranceRejectedDetail insurance={insurance} />}
          title="Insurance"
        >
          <span className="text-danger">
            <InfoCircleOutlined />
          </span>
        </Popover>
      );
  }

  return <></>;
};

export default InsuranceIcon;
