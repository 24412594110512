import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  TablePaginationConfig,
  Tooltip,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import PaymentForm from './components/PaymentForm';
import { useForm } from 'antd/lib/form/Form';
import { TBillPayment, TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import moment from 'moment';
import { SorterResult } from 'antd/lib/table/interface';
import { truncate } from 'lodash';
import { BankAccountSelect } from '@/components/BankAccountSelect';
import { VendorSelect } from '@/components/VendorSelect';
import { TerminalSelect } from '@/components/TerminalSelect';
import { OceanCarrierSelect } from '@/components/OceanCarrierSelect';
import AutoResizeTable from '@/components/AutoResizeTable';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { TLVendorSelect } from '@/components/TLVendorSelect';
import { TLType } from '@/components/constants';
import { showErrorMessage } from '@/utils/show-error-message';

const ClearModal: React.FC<{ onComfirm: (date: string) => void }> = ({
  onComfirm,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [date, setDate] = React.useState<string>('');
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (!date) {
      return;
    }
    onComfirm && onComfirm(date);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setDate(dateString);
  };

  return (
    <>
      <a onClick={showModal}>Clear</a>
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <DatePicker value={date ? moment(date) : null} onChange={onChange} />
      </Modal>
    </>
  );
};

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TBillPayment>>();
  const [savable, setSavable] = React.useState(true);
  const [filter] = useForm();
  const [form] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (
      pagination?: TablePaginationConfig,
      _?: any,
      sorter?: SorterResult<TBillPayment>,
    ) => {
      setLoading(true);

      try {
        const result = await app.service.get('orderBillPayments', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
            sort_by: sorter?.columnKey || sorter?.field,
            sort_value:
              sorter?.order === 'ascend'
                ? 'asc'
                : sorter?.order === 'descend'
                ? 'desc'
                : undefined,
          },
        });

        setData(result);
      } catch (e: any) {
        showErrorMessage(e);
      }

      setLoading(false);
    },
    [],
  );

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);

    try {
      !values.id
        ? await app.service.post('orderBillPayments', { data: values })
        : await app.service.put(`orderBillPayments/${values.id}`, {
            data: values,
          });

      message.success('Saved');

      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handlePrint = async (payment: TBillPayment) => {
    setLoading(true);

    try {
      const file = await app.service.get(
        `orderBillPayments/${payment.id}/print`,
        {
          responseType: 'blob',
        },
      );
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleEdit = async (payment: TBillPayment) => {
    setLoading(true);

    try {
      form.resetFields();
      form.setFieldsValue(payment);
      setSavable(!payment.void_at);
      setVisible(true);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleReconcile = async (checked: boolean, payment: TBillPayment) => {
    setLoading(true);

    try {
      await app.service.put(`orderBillPayments/${payment.id}/reconcile`, {
        data: { reconciled: checked },
      });
      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleDoVoid = async (payment: TBillPayment) => {
    setLoading(true);

    try {
      await app.service.put(`orderBillPayments/${payment.id}/void`);
      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleClear = async (date: string, payment: TBillPayment) => {
    setLoading(true);

    try {
      await app.service.put(`orderBillPayments/${payment.id}/clear`, {
        data: {
          date,
        },
      });
      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleUnClear = async (payment: TBillPayment) => {
    setLoading(true);

    try {
      await app.service.put(`orderBillPayments/${payment.id}/unclear`);
      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        width: 80,
      },
      {
        title: 'Bill From',
        dataIndex: ['bill_to', 'name'],
        width: 160,
      },
      {
        title: 'INV#',
        width: 100,
        render: (_, record: TBillPayment) => {
          const text = record.bills?.map((i) => i.invoice_number).join(',');
          return (
            <Tooltip placement="topRight" title={text}>
              {truncate(text)}
            </Tooltip>
          );
        },
      },
      {
        title: 'Bank Account',
        dataIndex: ['bank_account', 'name'],
        width: 100,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: 100,
      },
      {
        title: 'Check#',
        dataIndex: 'check_number',
        width: 100,
      },
      {
        title: 'Post Date',
        dataIndex: 'post_at',
        width: 100,
      },
      {
        title: 'Cleared Date',
        dataIndex: 'cleared_at',
        width: 100,
        render: (_, record: TBillPayment) => {
          return <>{record.cleared_at}</>;
        },
      },
      {
        title: 'Void Date',
        dataIndex: 'void_at',
        width: 100,
        render: (_, record: TBillPayment) => {
          return (
            <>{record.void_at || record.cleared_at ? record.void_at : ''}</>
          );
        },
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space split="|">
            <a onClick={() => handleEdit(record)}>Edit</a>
            <a onClick={() => handlePrint(record)}>Print</a>
            {!(record.void_at || record.cleared_at) && (
              <Popconfirm
                title="Are you sure？"
                onConfirm={() => handleDoVoid(record)}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                <a>Void</a>
              </Popconfirm>
            )}
            {!record.void_at && (
              <>
                {record.cleared_at ? (
                  <Popconfirm
                    title="Are you sure？"
                    onConfirm={() => handleUnClear(record)}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  >
                    <a>Undo Clear</a>
                  </Popconfirm>
                ) : (
                  <ClearModal
                    onComfirm={(date: string) => handleClear(date, record)}
                  />
                )}
              </>
            )}
          </Space>
        ),
      },
    ],
    [],
  );

  const handleExport = async () => {
    setLoading(true);
    try {
      await app.service.download('orderBillPayments/export', {
        params: {
          ...filter.getFieldsValue(),
        },
      });
    } catch (e: any) {
      showErrorMessage(e);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="Bill Payment" />

      <div className={styles.filter}>
        <Form
          layout="vertical"
          form={filter}
          onFinish={fetchData}
          initialValues={{ date_field: 'post_at' }}
          style={{ width: '100%' }}
        >
          <Row gutter={16}>
            <Col md={3}>
              <Form.Item name="bill_id" label="Bill#">
                <Input />
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item name="invoice_number" label="INV#">
                <Input />
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item name="check_number" label="Check#">
                <Input />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item name="bank_account_id" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Bank Account" name="bank_account_id">
                <BankAccountSelect />
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item name="cleared" label="Cleared">
                <Select allowClear>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="start_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="end_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="date_field" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Date" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const startDate = getFieldValue('start_date');
                  const endDate = getFieldValue('end_date');
                  return (
                    <Input.Group compact>
                      <Select
                        value={getFieldValue('date_field')}
                        onChange={(value) =>
                          setFieldsValue({ date_field: value })
                        }
                        style={{ width: '30%' }}
                      >
                        <Select.Option value="post_at">Post Date</Select.Option>
                        <Select.Option value="created_at">
                          Create Date
                        </Select.Option>
                        <Select.Option value="cleared_at">
                          Cleared Date
                        </Select.Option>
                      </Select>
                      <DatePicker.RangePicker
                        style={{ width: '70%' }}
                        value={
                          startDate && endDate
                            ? [moment(startDate), moment(endDate)]
                            : undefined
                        }
                        onChange={(value) =>
                          setFieldsValue({
                            start_date: value
                              ? value[0]?.format('YYYY-MM-DD')
                              : null,
                            end_date: value
                              ? value[1]?.format('YYYY-MM-DD')
                              : null,
                          })
                        }
                      />
                    </Input.Group>
                  );
                }}
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Bill From" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  return (
                    <Input.Group>
                      <Form.Item
                        name="bill_to_type"
                        initialValue={'App\\Models\\Vendor'}
                        noStyle
                      >
                        <Form.Item noStyle>
                          <Select
                            style={{ width: '20%' }}
                            value={getFieldValue('bill_to_type')}
                            onChange={(value) =>
                              setFieldsValue({ bill_to_type: value })
                            }
                          >
                            <Select.Option value="App\Models\Vendor">
                              Dray Vendor
                            </Select.Option>
                            <Select.Option value="App\Models\Terminal">
                              Terminal
                            </Select.Option>
                            <Select.Option value="App\Models\OceanCarrier">
                              OceanCarrier
                            </Select.Option>
                            <Select.Option value="App\Domains\TL\Models\LTLVendor">
                              LTL Vendor
                            </Select.Option>
                            <Select.Option value="App\Domains\FTL\Models\FTLVendor">
                              FTL Vendor
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Form.Item>
                      <Form.Item name="bill_to_id" noStyle>
                        <Form.Item noStyle>
                          {getFieldValue('bill_to_type') ===
                            'App\\Models\\Vendor' && (
                            <VendorSelect
                              style={{ width: '80%' }}
                              selected={getFieldValue('bill_to')}
                              onSelect={(row) =>
                                setFieldsValue({
                                  bill_to: row,
                                  bill_to_id: row?.id,
                                  bill_to_type: 'App\\Models\\Vendor',
                                })
                              }
                            />
                          )}
                          {getFieldValue('bill_to_type') ===
                            'App\\Models\\Terminal' && (
                            <TerminalSelect
                              style={{ width: '80%' }}
                              selected={getFieldValue('bill_to')}
                              onSelect={(row) =>
                                setFieldsValue({
                                  bill_to: row,
                                  bill_to_id: row?.id,
                                  bill_to_type: 'App\\Models\\Terminal',
                                })
                              }
                            />
                          )}
                          {getFieldValue('bill_to_type') ===
                            'App\\Models\\OceanCarrier' && (
                            <OceanCarrierSelect
                              style={{ width: '80%' }}
                              value={getFieldValue('bill_to')}
                              onSelect={(row) =>
                                setFieldsValue({
                                  bill_to: row,
                                  bill_to_id: row?.id,
                                  bill_to_type: 'App\\Models\\OceanCarrier',
                                })
                              }
                            />
                          )}
                          {getFieldValue('bill_to_type') ===
                            'App\\Domains\\TL\\Models\\LTLVendor' && (
                            <TLVendorSelect
                              tlType={TLType.LTL}
                              style={{ width: '80%' }}
                              selected={getFieldValue('bill_to')}
                              onSelect={(row) =>
                                setFieldsValue({
                                  bill_to: row,
                                  bill_to_id: row?.id,
                                  bill_to_type:
                                    'App\\Domains\\TL\\Models\\LTLVendor',
                                })
                              }
                            />
                          )}
                          {getFieldValue('bill_to_type') ===
                            'App\\Domains\\FTL\\Models\\FTLVendor' && (
                            <TLVendorSelect
                              tlType={TLType.FTL}
                              style={{ width: '80%' }}
                              selected={getFieldValue('bill_to')}
                              onSelect={(row) =>
                                setFieldsValue({
                                  bill_to: row,
                                  bill_to_id: row?.id,
                                  bill_to_type:
                                    'App\\Domains\\FTL\\Models\\FTLVendor',
                                })
                              }
                            />
                          )}
                        </Form.Item>
                      </Form.Item>
                    </Input.Group>
                  );
                }}
              </Form.Item>
            </Col>
            <Col md={2}>
              <Form.Item name="is_voided" label="Void">
                <Select allowClear>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item name="amount_from" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="amount_to" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Amount Range" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  return (
                    <Input.Group
                      style={{
                        width: '100%',
                      }}
                      compact
                    >
                      <InputNumber
                        value={getFieldValue('amount_from')}
                        onChange={(v) => setFieldsValue({ amount_from: v })}
                        style={{ width: 'cal(50% - 15px)' }}
                      />
                      <Input
                        className="site-input-split"
                        style={{
                          width: 30,
                          borderLeft: 0,
                          borderRight: 0,
                          pointerEvents: 'none',
                        }}
                        placeholder="~"
                        disabled
                      />
                      <InputNumber
                        value={getFieldValue('amount_to')}
                        onChange={(v) => setFieldsValue({ amount_to: v })}
                        style={{ width: 'cal(50% - 15px)' }}
                      />
                    </Input.Group>
                  );
                }}
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button onClick={() => filter.resetFields()}>
                    Clear All
                  </Button>
                  <Button onClick={handleExport}>Export</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title={form.getFieldValue('id')}
        placement="right"
        width={'80%'}
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button
              type="primary"
              disabled={!savable}
              loading={loading}
              onClick={handleSave}
            >
              Save
            </Button>
          </Space>
        }
      >
        <PaymentForm form={form} bills={form.getFieldValue('bills')} />
      </Drawer>
    </div>
  );
};

export default Index;
