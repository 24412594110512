import { Button, Space, DatePicker } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { get, upperFirst, has, isArray, flatMap } from 'lodash';

import actionUtils, {
  TFtlShipmentAction,
  TARGET_TYPE_FTL_SHIPMENT,
  TARGET_TYPE_LTL_SHIPMENT,
} from '@/components/Tasks/Interfaces/ActionInterface';

import SendPOD from './SendPOD';
import SendBOL from './SendBOL';
import { EditAction } from '../EditAction';
import EditSR from '../EditSR';
import EditRate from '../EditRate';
import RebillButton from './RebillButton';
import { ConfirmBeforeUpdate } from '../ConfirmBeforeUpdate';
import moment from 'moment';
import { RescheduledAction } from '../RescheduledAction';
import Upload from '../Upload';
import SendConfirmation from './SendConfirmation';
import InputAvailableTime from '../InputAvailableTime';
import { EditLink } from '@/components/Tasks/Actions/EditLink';
import { FTLShipmentInterface } from '@/pages/truckload/ftl/shipments/Interfaces/ShipmentInterface';
import { ShipmentBusinessStatus } from '@/pages/truckload/ftl/constants';
import ShowText from '../ShowText';

const FTLTaskActions: React.FC<TFtlShipmentAction> = ({
  task,
  targetModel: ftlShipment,
  onDone,
  onSaveOperation,
}) => {
  const [updatingData, setUpdatingData] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleOnSaveOperation = async (
    action: string,
    update_name: string,
    value?: string | null,
  ) => {
    await onSaveOperation(
      task,
      action,
      update_name,
      value,
      (loading: boolean) => setLoading(loading),
      onDone,
    );
  };

  const toSaveConfirmOperation = async (
    action: string,
    update_name: string,
    value?: string | null,
  ) => {
    await onSaveOperation(
      task,
      action,
      update_name,
      value,
      (loading: boolean) => setLoading(loading),
      onDoneConfirmOperation,
    );
  };

  const onDoneConfirmOperation = () => {
    setUpdatingData({});
    onDone(task, ftlShipment as FTLShipmentInterface);
  };

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  const operation = useMemo(() => task?.operation, [task]);

  const _actions = useMemo(() => {
    if (!operation?.is_done) {
      return task?.actions;
    }

    const taskActions = task?.actions ? flatMap(task.actions, 'action') : [];

    const newActions = task?.actions.filter((actionItem) =>
      actionUtils.ALWAY_SHOW_ACTIONS.includes(actionItem.action),
    );

    if (
      !actionUtils.FORBIDDEN_UNDO_TASKIDS.includes(task.id) ||
      (![ShipmentBusinessStatus.CANCELED].includes(
        ftlShipment.business_status,
      ) &&
        !actionUtils.FORBIDDEN_UNDO_ACTIONS.some((action) =>
          taskActions.includes(action),
        ))
    ) {
      newActions.push({ action: actionUtils.UNDO, update_name: null });
    }

    return newActions;
  }, [task]);

  return (
    <>
      <Space>
        {_actions?.map((actionRecord: any) => {
          const { action, update_name, avaliable_time_name } = actionRecord;

          const actionDisabled =
            task?.disabled ||
            (action == actionUtils.UNDO ? false : task.disabled);
          let value;

          if (isArray(update_name)) {
            if (has(update_name, 'time')) {
              value = get(ftlShipment, update_name[get(update_name, 'time')]);
            }
          } else {
            value = get(ftlShipment, update_name);
          }

          const toSetUpdateData = (value: string | null) =>
            setUpdatingData((prev) => {
              const prevTask = prev && prev[task.id] ? prev[task.id] : {};

              const currentTaskAction = {
                task_id: task.id,
                action,
                name: update_name,
                value: value,
              };

              return {
                ...prev,
                [task.id]: {
                  ...prevTask,
                  [action]: currentTaskAction,
                },
              };
            });

          switch (action) {
            case actionUtils.INPUT_SR:
              return <ShowText text={value} />;
            case actionUtils.INPUT: {
              return (
                <EditAction
                  task={task}
                  targetModel={ftlShipment}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  action={action}
                  name={update_name}
                  value={value}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            }
            case actionUtils.EDIT_SR:
              return (
                <EditSR
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  task={task}
                  action={action}
                  name={update_name}
                  onDone={onDone}
                />
              );
            case actionUtils.EDIT_BR:
              return (
                <EditRate
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  task={task}
                  action={action}
                  name={update_name}
                  onDone={onDone}
                />
              );
            case actionUtils.REBILL:
              return (
                <RebillButton
                  task={task}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  onDone={onDone}
                />
              );
            case actionUtils.INPUT_DATE:
              return (
                <ConfirmBeforeUpdate
                  key={task.id + '_' + action}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  task={task}
                  onDone={onDone}
                  toSave={() =>
                    handleOnSaveOperation(
                      action,
                      update_name,
                      updatingData.value,
                    )
                  }
                  updatingData={updatingData}
                >
                  <DatePicker
                    disabled={task?.disabled}
                    getPopupContainer={(trigger: any) => trigger.parentElement}
                    size="small"
                    value={value ? moment(value) : null}
                    onChange={(value) => {
                      setUpdatingData({
                        action,
                        name: update_name,
                        value: value ? value.format('YYYY-MM-DD') : null,
                      });
                    }}
                  />
                </ConfirmBeforeUpdate>
              );
            case actionUtils.INPUT_AVAILABLE_TIME:
              return (
                <InputAvailableTime
                  action={action}
                  task={task}
                  name={update_name}
                  availableTimeName={avaliable_time_name}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  disabled={task?.disabled}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.RESCHEDULE:
              return (
                <RescheduledAction
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  task={task}
                  onSaveOperation={onSaveOperation}
                  onDone={onDone}
                />
              );

            case actionUtils.UPLOAD:
              return (
                <Upload
                  task={task}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.SEND_POD:
              return (
                <SendPOD
                  task={task}
                  targetModel={ftlShipment}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.SEND_BOL:
              return (
                <SendBOL
                  task={task}
                  targetModel={ftlShipment}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.SEND_CONFIRMATION:
              return (
                <SendConfirmation
                  task={task}
                  targetModel={ftlShipment}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation} // 添加这一行
                />
              );
            case actionUtils.INPUT_CACENL_LINK:
              return (
                <ConfirmBeforeUpdate
                  key={task.id + '_' + action}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  task={task}
                  toSave={() => {
                    const value =
                      updatingData[task.id] && updatingData[task.id][action]
                        ? updatingData[task.id][action].value
                        : null;
                    toSaveConfirmOperation(action, update_name, value);
                  }}
                  updatingData={
                    updatingData &&
                    updatingData[task.id] &&
                    updatingData[task.id][action]
                      ? updatingData[task.id][action]
                      : null
                  }
                >
                  <EditLink
                    value={value}
                    placeholder="Enter Cancel Link"
                    onChange={(value) => toSetUpdateData(value)}
                  />
                </ConfirmBeforeUpdate>
              );
            default: {
              if (
                actionUtils.CANCEL_TASKIDS.includes(task.id) &&
                action != actionUtils.UNDO
              ) {
                return (
                  <ConfirmBeforeUpdate
                    key={task.id + '_' + action}
                    target={TARGET_TYPE_FTL_SHIPMENT}
                    targetModel={ftlShipment}
                    task={task}
                    toSave={() => {
                      toSaveConfirmOperation(action, update_name);
                    }}
                    updatingData={
                      updatingData &&
                      updatingData[task.id] &&
                      updatingData[task.id][action]
                        ? updatingData[task.id][action]
                        : null
                    }
                  >
                    <Button
                      key={action}
                      size="small"
                      disabled={task.disabled || actionDisabled}
                      onClick={(e: any) => toSetUpdateData(null)}
                    >
                      {upperFirst(action)}
                    </Button>
                  </ConfirmBeforeUpdate>
                );
              } else {
                return (
                  <Button
                    key={action}
                    size="small"
                    disabled={task.disabled || actionDisabled}
                    onClick={() => {
                      handleOnSaveOperation(action, update_name);
                    }}
                  >
                    {upperFirst(action)}
                  </Button>
                );
              }
            }
          }
        })}
      </Space>
    </>
  );
};

export default FTLTaskActions;
