import AutoResizeTable from '@/components/AutoResizeTable';
import { EditableSelect } from '@/components/Editable';
import { LogActivities } from '@/components/LogActivities';
import { TARGET_TYPE_FTL_SHIPMENT } from '@/components/Tasks/Interfaces/ActionInterface';
import { TodoList } from '@/components/Tasks/Render/TodoList';
import { InfoCircleOutlined, NodeIndexOutlined } from '@ant-design/icons';
import { Space, message, Tooltip, Tag, Button } from 'antd';
import { Upload } from '../../components/Upload';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import { get } from 'lodash';
import { BUSINESS_STATUS_OPTIONS, CUSTOMER_STATUS_OPTIONS } from '..';
import { AccountingModal } from '../../components/AccountingModal';
import cache, { CACHE_ADMIN_KEY, CACHE_USER_SELECT_KEY } from '@/stores/cache';
import {
  ShipmentBusinessStatus,
  ApStatusLabelMap,
  ArStatusLabelMap,
  RebillStatusLabelMap,
  generateOptions,
  ShipmentStatus,
  ShipmentCustomerStatus,
} from '../../constants';
import { useEffect, useMemo, useState } from 'react';
import { ShipmentItemInterface } from '../Interfaces/ShipmentItemInterface';

import { FTLShipmentInterface } from '../Interfaces/ShipmentInterface';
import { TrackTable } from '../../components/TrackTable';
import {
  transferTasksIconColor,
  ACCOUNT_ICON,
} from '@/components/Tasks/Interfaces/TaskIconColorInterface';
import FtlShipmentIdColumn from './ShipmentIdColumn';
import VendorShipmentIdColumn from './VendorShipmentIdColumn';
import LocationCell from '@/pages/truckload/components/LocationCell';
import { TableRowSelection } from 'antd/lib/table/interface';
import { CopyButton } from '@/components/CopyButton';
import EditableUserSelect from '@/components/EditableUserSelect';
import EditableCommonDatePicker from '@/components/EditableCommonDatePicker';
import ShipmentPronumberColumn from './ShipmentPronumberColumn';
import EditableCommonInputColumn from '@/pages/truckload/ltl/shipments/ShipmentTable/EditableCommonInputColumn';
import TimeText from '@/components/AvailableTime/TimeText';
import { ShipmentDisputeMemo } from '@/components/Memo/ShipmentDisputeMemo';
import { EditableBusinessStatusSelect } from './EditableBusinessStatusSelect';
import { EditableCustomerStatusSelect } from './EditableCustomerStatusSelect';
import SSMemo from '@/pages/truckload/components/SSMemo';
import DirectCarrierContactModal from '../../../components/DirectCarrierContactModal';

export interface ShipmentsTableProps {
  pagination?: false | TablePaginationConfig | undefined;
  columns?: ColumnsType<ShipmentItemInterface>;
  data: any;
  handleTableChange: (pagination: any, filters: any, sorter: any) => void;
  handleShow: (record: any) => void;
  handlePatchUpdate: (id: number, model: string, data: any) => void;
  handleUpdateStatus: (id: number, values: any) => void;
  fetchData: (pagination?: any, filterValues?: any[]) => void;
  refreshFtlShipment: (id: number) => void;
  openId?: number;
  setDefaultColumns?: (columns: ColumnsType<FTLShipmentInterface>) => void;
  loading?: boolean;
  rowSelection?: TableRowSelection<FTLShipmentInterface>;
  selectedRows?: FTLShipmentInterface[];
}

const tableComponents: TableProps<any>['components'] = {
  body: {
    // onMouseEnter 和 onMouseLeave 在数据量多时严重阻塞表格单元格渲染，严重影响性能
    cell: ({ onMouseEnter, onMouseLeave, ...props }) => <td {...props} />,
  },
};

const OP_USER_SELECT_PATH = ['operator_id'];
const PROCUREMENT_USER_SELECT_PATH = ['procurement_id'];
const AGENT_USER_SELECT_PATH = ['agent_id'];
const BD_USER_SELECT_PATH = ['business_development_id'];
const SALES_USER_SELECT_PATH = ['sales_id'];
const SALES_SUPPORT_USER_SELECT_PATH = ['sales_support_id'];
const DRIVER_NAME_INPUT_PATH = ['driver_name'];
const DRIVER_PHONE_INPUT_PATH = ['driver_phone'];

const ShipmentsTable = ({
  pagination = false,
  columns,
  data,
  handleTableChange,
  handleShow,
  handlePatchUpdate,
  handleUpdateStatus,
  fetchData,
  refreshFtlShipment,
  setDefaultColumns,
  openId = 0,
  loading,
  rowSelection,
  selectedRows,
}: ShipmentsTableProps) => {
  const trailerTypeOptions = [
    { label: 'Dry Van', value: 'Dry Van' },
    { label: 'Reefer', value: 'Reefer' },
    { label: 'Box Truck', value: 'Box Truck' },
    { label: 'Flatbed', value: 'Flatbed' },
    { label: 'Stepdeck', value: 'Stepdeck' },
    { label: 'Conestoga', value: 'Conestoga' },
    { label: 'Power - Only', value: 'Power - Only' },
    { label: 'Maxi/A-Trains', value: 'Maxi/A-Trains' },
    { label: 'B- Train', value: 'B- Train' },
    { label: 'RGN', value: 'RGN' },
    { label: 'Extendable RGN', value: 'Extendable RGN' },
    { label: 'Lowboy', value: 'Lowboy' },
  ];

  const adminUsers = useMemo(() => cache.data[CACHE_ADMIN_KEY], [
    cache.data[CACHE_ADMIN_KEY],
  ]);
  useEffect(() => {
    if (!adminUsers || adminUsers.length === 0) {
      cache.fetch(CACHE_ADMIN_KEY);
    }
  }, [adminUsers]);

  const users = useMemo(() => cache.data[CACHE_USER_SELECT_KEY], [
    cache.data[CACHE_USER_SELECT_KEY],
  ]);
  useEffect(() => {
    if (!users || users.length === 0) {
      cache.fetch(CACHE_USER_SELECT_KEY);
    }
  }, [users]);

  const [carrierModalVisible, setCarrierModalVisible] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState<any>(null);

  const handleCarrierClick = (record: any) => {
    if (record?.carrier) {
      setSelectedCarrier(record.carrier);
      setCarrierModalVisible(true);
    }
  };

  const defaultColumns: ColumnsType<FTLShipmentInterface> = useMemo(
    () => [
      {
        title: (
          <Space>
            <span>ID</span>
            <CopyButton
              value={
                (selectedRows?.length
                  ? data?.data?.filter((item: FTLShipmentInterface) =>
                      selectedRows?.some(
                        (selectedRow) => selectedRow.id == item.id,
                      ),
                    )
                  : data?.data
                )
                  ?.map((item: FTLShipmentInterface) => item.uid)
                  .join('\r\n') || ''
              }
            />
            <span>VID</span>
            <CopyButton
              value={
                (selectedRows?.length
                  ? data?.data?.filter((item: FTLShipmentInterface) =>
                      selectedRows?.some(
                        (selectedRow) => selectedRow.id == item.id,
                      ),
                    )
                  : data?.data
                )
                  ?.map((item: FTLShipmentInterface) => item.vendor_shipment_id)
                  .join('\r\n') || ''
              }
            />
            <span>Pro#</span>
            <CopyButton
              value={
                (selectedRows?.length
                  ? data?.data?.filter((item: FTLShipmentInterface) =>
                      selectedRows?.some(
                        (selectedRow) => selectedRow.id == item.id,
                      ),
                    )
                  : data?.data
                )
                  ?.map((item: FTLShipmentInterface) => item.pro_number)
                  .join('\r\n') || ''
              }
            />
          </Space>
        ),
        dataIndex: 'uid',
        key: 'id',
        fixed: 'left',
        width: 160,
        sorter: false,
        render: (text, record) => {
          return (
            <FtlShipmentIdColumn
              shipment={record}
              handleShow={handleShow}
              fetchData={fetchData}
            />
          );
          // return (
          //   <Space>
          //     <a onClick={() => handleShow(record)}>{record.uid}</a>
          //     <ColorTags
          //       tlId={record.id}
          //       tags={record.tags || []}
          //       onApply={() => fetchData && fetchData()}
          //     />
          //   </Space>
          // );
        },
      },
      {
        title: 'Dispatch Status',
        dataIndex: 'business_status',
        key: 'business_status',
        width: 200,
        fixed: 'left',
        align: 'center',
        render: (text, record) => (
          <EditableBusinessStatusSelect
            record={record}
            patchUpdate={handlePatchUpdate}
          />
        ),
      },
      {
        title: 'Customer Status',
        dataIndex: 'customer_status',
        key: 'customer_status',
        width: 200,
        align: 'center',
        render: (text, record) => (
          <EditableCustomerStatusSelect
            record={record}
            patchUpdate={handlePatchUpdate}
          />
        ),
      },
      {
        title: 'OP',
        dataIndex: 'operator_id',
        key: 'operator_id',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (value, record) => (
          <EditableUserSelect
            data={adminUsers}
            record={record}
            path={OP_USER_SELECT_PATH}
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                operator_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'Procurement',
        dataIndex: 'procurement_id',
        key: 'procurement_id',
        width: 150,
        render: (text, record) => (
          <EditableUserSelect
            data={adminUsers}
            record={record}
            path={PROCUREMENT_USER_SELECT_PATH}
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                procurement_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'Agent',
        dataIndex: 'agent_id',
        key: 'agent_id',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <EditableUserSelect
            data={users}
            record={record}
            type="user"
            path={AGENT_USER_SELECT_PATH}
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                agent_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'BD',
        dataIndex: 'business_development_id',
        key: 'business_development_id',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <EditableUserSelect
            data={adminUsers}
            record={record}
            path={BD_USER_SELECT_PATH}
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                business_development_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'Sales',
        dataIndex: 'sales_id',
        key: 'sales_id',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (value, record) => (
          <EditableUserSelect
            data={adminUsers}
            record={record}
            path={SALES_USER_SELECT_PATH}
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                sales_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'Sales Support',
        dataIndex: 'sales_support_id',
        key: 'sales_support_id',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (value, record) => (
          <EditableUserSelect
            data={adminUsers}
            record={record}
            path={SALES_SUPPORT_USER_SELECT_PATH}
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                sales_support_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'Order Status',
        dataIndex: 'order_status_name',
        key: 'order_status',
        width: 120,
      },
      {
        title: 'AR Status',
        dataIndex: 'ar_status',
        key: 'ar_status',
        width: 120,
        render: (text: string, record: any) => (
          <EditableSelect
            name="ar_status"
            value={record.ar_status}
            options={generateOptions(ArStatusLabelMap)}
            onChange={(name: string, value: any) => {
              const callback = () =>
                handleUpdateStatus(record.id, {
                  [name]: value,
                });
              callback();
            }}
          />
        ),
      },
      {
        title: 'AP Status',
        dataIndex: 'ap_status',
        key: 'ap_status',
        width: 120,
        render: (text: string, record: any) => (
          <EditableSelect
            name="ap_status"
            value={record.ap_status}
            options={generateOptions(ApStatusLabelMap)}
            onChange={(name: string, value: any) => {
              const callback = () =>
                handleUpdateStatus(record.id, {
                  [name]: value,
                });
              callback();
            }}
          />
        ),
      },
      {
        title: 'Rebill Status',
        dataIndex: 'rebill_status',
        key: 'rebill_status',
        width: 120,
        render: (text: string, record: any) => (
          <EditableSelect
            name="rebill_status"
            value={record.rebill_status}
            options={generateOptions(RebillStatusLabelMap)}
            onChange={(name: string, value: any) => {
              const callback = () =>
                handlePatchUpdate(record.id, 'shipments', {
                  [name]: value ? value : null,
                });
              callback();
            }}
          />
        ),
      },
      {
        title: 'Vendor Shipment ID',
        dataIndex: 'vendor_shipment_id',
        key: 'vendor_shipment_id',
        width: 150,
        render: (text, record) => {
          return <VendorShipmentIdColumn shipment={record} />;
        },
      },
      {
        title: 'Customer Ref#',
        dataIndex: 'customer_reference_number',
        key: 'customer_reference_number',
        width: 160,
        ellipsis: {
          showTitle: false,
        },
        render: (customer_reference_number) => (
          <Tooltip placement="topLeft" title={customer_reference_number}>
            {customer_reference_number}
          </Tooltip>
        ),
      },
      {
        title: 'From Type',
        dataIndex: 'from_type',
        key: 'from_type',
        width: 120,
        render: (text, record) => (
          <Space direction="vertical" align="center">
            <div style={{ width: '100%' }}>{text}</div>
            <div style={{ width: '100%' }}>{record.quote_number}</div>
          </Space>
        ),
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
        width: 160,
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip
            placement="topLeft"
            title={`${record.company_code} (${record.user_name})`}
          >
            {`${record.company_code}(${record.user_name})`}
          </Tooltip>
        ),
      },
      {
        title: 'Quote Rate',
        dataIndex: 'quote_rate',
        key: 'quote_rate',
        width: 100,
      },
      {
        title: 'Buy Rate',
        dataIndex: 'vendor_rate',
        key: 'vendor_rate',
        width: 100,
      },
      {
        title: 'Sell Rate',
        dataIndex: 'sell_rate',
        key: 'sell_rate',
        width: 100,
      },
      {
        title: 'Cargo Value',
        dataIndex: 'cargo_value',
        key: 'cargo_value',
        width: 100,
      },
      {
        title: 'Premium',
        dataIndex: 'premium',
        key: 'premium',
        width: 100,
      },
      {
        title: 'Total SR',
        dataIndex: 'total_sell_rate',
        key: 'total_sell_rate',
        width: 100,
      },
      {
        title: 'Rebill Amount',
        dataIndex: 'rebill_amount',
        key: 'rebill_amount',
        width: 100,
      },
      {
        title: 'Est Margin',
        dataIndex: 'margin_rate',
        key: 'margin_rate',
        width: 150,
        render: (text, record) => {
          return (
            record.margin_rate.toFixed(2) +
            '/' +
            record.margin_percent.toFixed(2) +
            '%'
          );
        },
      },
      {
        title: 'AR',
        dataIndex: 'invoice_amount',
        key: 'invoice_amount',
        width: 100,
      },
      {
        title: 'AP',
        dataIndex: 'bill_amount',
        key: 'bill_amount',
        width: 100,
      },
      {
        title: 'Act Profit',
        dataIndex: 'profit_amount_total',
        key: 'profit_amount_total',
        width: 100,
      },
      {
        title: 'Vendor',
        dataIndex: 'vendor_name',
        key: 'vendor_name',
        width: 150,
      },
      {
        title: 'Carrier',
        dataIndex: 'vendor_carrier_name',
        key: 'vendor_carrier_name',
        sorter: true,
        width: 150,
        render: (text, record) => (
          <a onClick={() => handleCarrierClick(record)}>{text}</a>
        ),
      },
      {
        title: 'Pro Number',
        dataIndex: 'pro_number',
        key: 'pro_number',
        width: 180,
        render: (text, record) => (
          <ShipmentPronumberColumn
            shipment={record}
            handleEdit={handlePatchUpdate}
          />
        ),
      },
      {
        title: 'Driver Name',
        dataIndex: 'driver_name',
        key: 'driver_name',
        width: 180,
        render: (value, record) => (
          <EditableCommonInputColumn
            record={record}
            path={DRIVER_NAME_INPUT_PATH}
            onSave={(value) =>
              handlePatchUpdate(record.id, 'shipments', {
                driver_name: value || null,
              })
            }
          />
        ),
      },
      {
        title: 'Driver Phone',
        dataIndex: 'driver_phone',
        key: 'driver_phone',
        width: 180,
        render: (value, record) => (
          <EditableCommonInputColumn
            record={record}
            path={DRIVER_PHONE_INPUT_PATH}
            onSave={(value) =>
              handlePatchUpdate(record.id, 'shipments', {
                driver_phone: value || null,
              })
            }
          />
        ),
      },
      {
        title: 'Est Pickup Date',
        dataIndex: 'estimated_pickup_date',
        key: 'estimated_pickup_date',
        width: 150,
        sorter: true,
        render: (value, record) => (
          <div>
            <div>{value}</div>
            <div>
              <TimeText
                time={record.estimated_pickup_date_available_time?.value}
              />
            </div>
          </div>

          // <EditableCommonDatePicker
          //   value={value}
          //   onChange={(date, dateString) =>
          //     handlePatchUpdate(record.id, 'shipments', {
          //       estimated_pickup_date: dateString || null,
          //     })
          //   }
          // />
        ),
      },
      {
        title: 'Est Delivery Date',
        dataIndex: 'estimated_delivery_date',
        key: 'estimated_delivery_date',
        width: 150,
        sorter: true,
        render: (value, record) => (
          <div>
            <div>{value}</div>
            <div>
              <TimeText
                time={record.estimated_delivery_date_available_time?.value}
              />
            </div>
          </div>
          // <EditableCommonDatePicker
          //   value={value}
          //   onChange={(date, dateString) =>
          //     handlePatchUpdate(record.id, 'shipments', {
          //       estimated_delivery_date: dateString || null,
          //     })
          //   }
          // />
        ),
      },
      {
        title: 'Act Pickup Date',
        dataIndex: 'actual_pickup_date',
        key: 'actual_pickup_date',
        width: 150,
        sorter: true,
        render: (value, record) => (
          <EditableCommonDatePicker
            value={value}
            onChange={(date, dateString) =>
              handlePatchUpdate(record.id, 'shipments', {
                actual_pickup_date: dateString || null,
              })
            }
          />
        ),
      },
      {
        title: 'Act Delivery Date',
        dataIndex: 'actual_delivery_date',
        key: 'actual_delivery_date',
        width: 150,
        sorter: true,
        render: (value, record) => (
          <EditableCommonDatePicker
            value={value}
            onChange={(date, dateString) =>
              handlePatchUpdate(record.id, 'shipments', {
                actual_delivery_date: dateString || null,
              })
            }
          />
        ),
      },
      {
        title: 'Pick up Location',
        dataIndex: 'pickup_address1',
        key: 'pickup_address1',
        sorter: true,
        width: 320,
        render: (text, record) => {
          return (
            <LocationCell
              location={`${record.pickup_address1}
          ${record.pickup_address2} ${record.pickup_city},
          ${record.pickup_state} ${record.pickup_zipcode} ${record.pickup_country}`}
              contact={`${record.pickup_company_name} ${record.pickup_contact_name} ${record.pickup_contact_phone} ${record.pickup_contact_email}`}
            ></LocationCell>
          );
        },
      },
      {
        title: 'Delivery Location',
        dataIndex: 'destination_address1',
        key: 'destination_address1',
        width: 320,
        sorter: true,
        render: (text, record) => {
          return (
            <LocationCell
              location={`${record.destination_address1}
          ${record.destination_address2} ${record.destination_city},
          ${record.destination_state} ${record.destination_zipcode} ${record.destination_country}`}
              contact={`${record.destination_company_name} ${record.destination_contact_name} ${record.destination_contact_phone} ${record.destination_contact_email}`}
            ></LocationCell>
          );
        },
      },
      {
        title: 'API Stage',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        render: (text, record) => {
          switch (record.status) {
            case ShipmentStatus.PROCESSING:
              return <Tag color="default">PROCESSING</Tag>;
            case ShipmentStatus.WAIT_REQUOTE:
              return <Tag color="yellow">WAIT_REQUOTE</Tag>;
            case ShipmentStatus.WAIT_CONFIM:
              return <Tag color="yellow">WAIT_CONFIM</Tag>;
            case ShipmentStatus.FAILED:
              return <Tag color="red">FAILED</Tag>;
            case ShipmentStatus.SUCCESS:
              return <Tag color="blue">SUCCESS</Tag>;
            case ShipmentStatus.SYNCED:
              return <Tag color="green">SYNCED</Tag>;
            case ShipmentStatus.SYNC_FAILED:
              return <Tag color="gray">SYNC FAILED</Tag>;
            default:
              return <Tag color="default">Unknown</Tag>;
          }
        },
      },
      {
        title: 'Trailer Type',
        dataIndex: 'trailer_type',
        key: 'trailer_type',
        width: 150,
        render: (text, record) => (
          <EditableSelect
            name="trailer_type"
            value={record.trailer_type || 'Dry Van'}
            options={trailerTypeOptions}
            onChange={(name: string, value: any) => {
              handlePatchUpdate(record.id, 'shipments', {
                [name]: value,
              });
            }}
          />
        ),
      },
      {
        title: 'Order Created Date',
        dataIndex: 'create_date',
        key: 'create_date',
        sorter: true,
        align: 'center',
        width: 150,
      },
      {
        title: 'SS Memo',
        dataIndex: 'ss_memo',
        key: 'ss_memo',
        align: 'center',
        width: 200,
        render: (text, record) => (
          <SSMemo
            shipment={record}
            onUpdated={async (id, value) => {
              await handlePatchUpdate(id, 'shipments', {
                ss_memo: value,
              });
              refreshFtlShipment(id);
            }}
          />
        ),
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 250,
        render: (text, record) => (
          <Space>
            <Upload shipment={record} />
            <TodoList
              target={TARGET_TYPE_FTL_SHIPMENT}
              targetModel={record}
              color={get(record, 'task_icon_colors.todoList', 'gray')}
              onDone={() => refreshFtlShipment(record.id)}
            />
            <Button type="link" size="small">
              <ShipmentDisputeMemo
                onSaved={() => fetchData && fetchData()}
                model={record}
                initialValue={record.internal_memo}
                open={openId == record.id}
                type="ftl"
              />
            </Button>
            <Button type="link" size="small">
              <div className="text-gray">
                <AccountingModal
                  ftlShipmentId={record.id}
                  showAccountingTips
                  accountingActionTipsProps={{
                    color: get(
                      record,
                      'task_icon_colors.' + ACCOUNT_ICON,
                      'gray',
                    ),
                    list: transferTasksIconColor(record.task_icon_colors),
                  }}
                  onChanged={() => refreshFtlShipment(record.id)}
                />
              </div>
            </Button>

            <Button type="link" size="small">
              <LogActivities
                id={record.id}
                icon={
                  <div className="text-gray">
                    <InfoCircleOutlined />
                  </div>
                }
                type="ftlShipments"
              />
            </Button>
            <Button type="link" size="small">
              <TrackTable
                oredrId={record.id}
                icon={
                  record.tracking_is_expired ? (
                    <div className="text-danger">
                      <NodeIndexOutlined />
                    </div>
                  ) : record?.tracking_histories?.length === 0 ? (
                    <div className="text-gray">
                      <NodeIndexOutlined />
                    </div>
                  ) : (
                    <div className="text-primary">
                      <NodeIndexOutlined />
                    </div>
                  )
                }
                type="ftl"
              />
            </Button>
          </Space>
        ),
      },
    ],
    [data, selectedRows],
  );

  useEffect(() => {
    setDefaultColumns && setDefaultColumns(defaultColumns);
  }, [defaultColumns]);

  return (
    <>
      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        columns={columns || defaultColumns}
        onChange={handleTableChange}
        dataSource={data?.data || []}
        sticky
        bordered
        rowSelection={rowSelection}
        scroll={{
          x: 1500,
        }}
        components={tableComponents}
      />

      <DirectCarrierContactModal
        visible={carrierModalVisible}
        onClose={() => setCarrierModalVisible(false)}
        carrier={selectedCarrier}
      />
    </>
  );
};

export default ShipmentsTable;
