import { Button } from 'antd';
import { useApp } from '@/utils/useapp';
import { useEffect, useState } from 'react';
import React from 'react';
import {
  TLtlShipmentAction,
  TARGET_TYPE_LTL_SHIPMENT,
} from '../../../Interfaces/ActionInterface';
import SendPodConfirmEmailModal from './SendPodEmailModal';
import { use } from 'echarts';

const SendPOD: React.FC<TLtlShipmentAction> = ({
  task,
  targetModel: ltlShipment,
  onDone,
  onSaveOperation,
}) => {
  const app = useApp();
  const [open, setOpen] = useState(false);

  const handleCloseOpen = () => {
    setOpen(false);
  };

  const handleOnSent = () => {
    onDone();
    handleCloseOpen();
  };

  return (
    <>
      <Button
        disabled={task?.disabled}
        size="small"
        onClick={() => setOpen(true)}
      >
        Send POD
      </Button>
      {open && ltlShipment && (
        <SendPodConfirmEmailModal
          open={open}
          admin={app.store.auth.account}
          task={task}
          targetModel={ltlShipment}
          target={TARGET_TYPE_LTL_SHIPMENT}
          onClose={handleCloseOpen}
          onDone={handleOnSent}
        />
      )}
    </>
  );
};

export default SendPOD;
