import * as Sentry from '@sentry/react';

Sentry.init({
  enabled: IS_PROD,
  dsn:
    'https://3fb7da31e40968697fbca2765441ba05@o4506187848613888.ingest.us.sentry.io/4507370661412864',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/admin\.drayeasy\.com/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  ignoreErrors: [/ResizeObserver loop/, /401/],
});

// disable scroll on number input
document.addEventListener('wheel', function (event) {
  if (document?.activeElement?.type === 'number') {
    document?.activeElement?.blur();
  }
});
