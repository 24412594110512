import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  message,
  Space,
  Table,
  TablePaginationConfig,
  Row,
  Form,
  Col,
  Input,
  DatePicker,
  Select,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import { useForm } from 'antd/lib/form/Form';
import { TLogActivity, TCollection, TAccount } from '@/types';
import usePagination from '@/components/usePagination';
import moment from 'moment';
import { LogActivitiesTableColumns } from '@/components/LogActivitiesTableColumns';
import AutoResizeTable from '@/components/AutoResizeTable';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TLogActivity>>();
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('logActivities', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        showErrorMessage(e);
      }

      setLoading(false);
    },
    [],
  );

  return (
    <div className={styles.main}>
      <Header title="LogActivitys"></Header>

      <div className={styles.filter}>
        <Form
          layout="vertical"
          className="w100"
          form={filter}
          onFinish={fetchData}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item required name="operator_id" label="Operator">
                <UserSyncSelect type="admin" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="created_at_start_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="created_at_end_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Date" required shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const created_at_start_date = getFieldValue(
                    'created_at_start_date',
                  );
                  const created_at_end_date = getFieldValue(
                    'created_at_end_date',
                  );
                  return (
                    <DatePicker.RangePicker
                      value={
                        created_at_start_date && created_at_end_date
                          ? [
                              moment(created_at_start_date),
                              moment(created_at_end_date),
                            ]
                          : undefined
                      }
                      onChange={(value) =>
                        setFieldsValue({
                          created_at_start_date: value
                            ? value[0]?.format('YYYY-MM-DD')
                            : null,
                          created_at_end_date: value
                            ? value[1]?.format('YYYY-MM-DD')
                            : null,
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button onClick={() => filter.resetFields()}>
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={LogActivitiesTableColumns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
    </div>
  );
};

export default Index;
