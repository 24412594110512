import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get } from 'lodash';
import React, { useMemo, useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { UserForm } from './UserForm';
import { CACHE_USER_KEY, CACHE_USER_SELECT_KEY } from '@/stores/cache';
import { serialize } from 'object-to-formdata';
import { showErrorMessage } from '@/utils/show-error-message';

export type TUserDrawerProps = {
  id?: number;
  open: boolean;
  disabled?: boolean;
  onClose: () => void;
  onSaved?: (user: any) => void;
};

export const UserDrawer: React.FC<TUserDrawerProps> = ({
  id = 0,
  open,
  disabled = false,
  onClose,
  onSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);

  const [form] = useForm();

  const fetchData = async () => {
    if (!id) {
      return;
    }

    try {
      const resp = await app.service.get(`users/${id}`);
      await form.setFieldsValue(resp.data);
    } catch (error: any) {
      showErrorMessage(error);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    values.vendor_ids = values.vendors ? values.vendors.map((v) => v.id) : [];
    delete values.vendors;

    setLoading(true);

    // prevent formdata from sending boolean value
    for (const key in values) {
      if (typeof values[key] === 'boolean') {
        values[key] = values[key] ? 1 : 0;
      }
    }

    const config = {
      data: serialize(
        {
          ...values,
        },
        { indices: true, nullsAsUndefineds: true },
      ),
      requestType: 'form',
    };

    const request = !values.id
      ? app.service.post('users', config)
      : app.service.put(`users/${values.id}`, { data: values });

    request
      .then((resp: any) => {
        message.success('Saved');
        // form.setFieldsValue(resp.data.data);
        onSaved && onSaved(resp.data);
        app.store.cache.fetch(CACHE_USER_KEY, true);
        app.store.cache.fetch(CACHE_USER_SELECT_KEY, true);
        handleClose();
      })
      .catch((err: any) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const initForm = () => {
    form.resetFields();
    const rate_rules = [
      {
        type: 'fix',
        value: '',
        name: 'base_rate',
      },
      {
        type: 'fix',
        value: '',
        name: 'chassis_fee',
      },
      {
        type: 'fix',
        value: '',
        name: 'chassis_split_fee',
      },
    ];
    form.setFieldsValue({
      status: 2,
      type: 2,
      billing_interval: 'monthly',
      can_order: true,
      rate_rules: rate_rules,
      ltl_request_limit_decay_seconds: 86400,
      rate_search_limit_decay_seconds: 86400,
      requested_rate_create_limit_decay_seconds: 86400,
      statistics_route_search_limit_decay_seconds: 86400,
      is_multi_devices_login: false,
    });
  };

  useEffect(() => {
    if (open) {
      if (!id) {
        initForm();
      } else {
        fetchData();
      }
    }
  }, [open, id]);

  return (
    <>
      <Drawer
        title={`${id ? 'Edit' : 'Create'} User`}
        placement="right"
        width="80%"
        onClose={handleClose}
        destroyOnClose={true}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <UserForm form={form} onSubmit={handleSave} />
      </Drawer>
    </>
  );
};
