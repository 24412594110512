import { Space, Tag } from 'antd';
import React, { useMemo } from 'react';
import actionUtil, {
  TLtlShipmentAction,
} from '../../Interfaces/ActionInterface';
import { TEXT_COLOR_MAP } from '@/pages/setting/containerTasks/components/data';
import { ActionIcon } from './ActionIcon';
import OperationLogs from '../OperationLogs';
import { WhoseTaskTag } from './WhoseTaskTag';
import { SOPLink } from '@/components/Tasks/SOPLink';
import { TARGET_LTL_SHIPMENT } from '@/components/Memo/data';

const LtlShipmentTaskColumn: React.FC<
  TLtlShipmentAction & { showLog: boolean }
> = ({ task, targetModel: ltlShipment, showLog = true }) => {
  const operation = useMemo(() => task?.operation, [task]);
  const nameColor = useMemo(
    () => (task?.sequential_execution ? 'text-primary' : ''),
    [task],
  );

  return (
    <Space size={'small'}>
      <ActionIcon operation={operation} />
      <div>
        <WhoseTaskTag whoseTask={task?.whose_task} />
        <span
          className={[
            nameColor,
            task?.is_filter_status ? 'text-line-through' : '',
          ].join(' ')}
        >
          {task?.sop ? (
            <SOPLink title={task.name} taskId={task.id} color={nameColor} />
          ) : (
            task?.name
          )}
        </span>
      </div>

      {operation && operation.action in actionUtil.ACTION_COLORS && (
        <Tag
          color={
            actionUtil.ACTION_COLORS[
              operation.action as keyof typeof actionUtil.ACTION_COLORS
            ]
          }
        >
          {operation.action}
        </Tag>
      )}

      {showLog && (
        <>
          {!!operation?.action_by && (
            <small className="text-gray">{operation.action_by.name}</small>
          )}
          {!!operation?.action_by && (
            <small className="text-gray">{operation.action_at}</small>
          )}

          {operation?.id && (
            <OperationLogs
              target={TARGET_LTL_SHIPMENT}
              targetModel={ltlShipment}
              operationId={operation?.id}
            />
          )}
        </>
      )}
    </Space>
  );
};

export default LtlShipmentTaskColumn;
