import AutoResizeTable, {
  AutoResizeTableProps,
} from '@/components/AutoResizeTable';
import { Rating } from '@/types';
import { useApp } from '@/utils/useapp';
import { Button, message, Popconfirm, Rate, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import useNewRatingModal from './NewRatingModal';
import { showErrorMessage } from '@/utils/show-error-message';

const WaringTag = () => <Tag color="warning">warning</Tag>;

const Index = ({
  loading,
  dataSource,
  onFinish,
  ...rest
}: AutoResizeTableProps & { onFinish?: () => void }) => {
  const app = useApp();
  const { openNewRatingModal, NewRatingModal } = useNewRatingModal();

  const hasPermission = (permission: string, adminId: number) => {
    const adminAuthId = app.store.auth.account?.id;
    const hasAuthPermission = app.store.auth.access(
      `${permission}.vendorRating`,
    );
    return adminId === adminAuthId && hasAuthPermission;
  };

  const deleteItem = async (id: number) => {
    try {
      await app.service.delete(`vendorRatings/${id}`);
      message.success('deleted');
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      onFinish && onFinish();
    }
  };

  const columns: ColumnsType<Rating> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 200,
      render: (text: string, record) => {
        return (
          <div>
            {text}
            {record.is_warning && <WaringTag />}
          </div>
        );
      },
    },
    {
      title: 'Ratings',
      dataIndex: 'items',
      width: 300,
      render: (text, record) => {
        return (
          <Space direction="vertical">
            {Object.entries(record.items).map(([key, value]) => (
              <Space key={key}>
                {key}
                <Rate defaultValue={value} disabled />
              </Space>
            ))}
          </Space>
        );
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      width: 100,
    },
    {
      title: 'Weights',
      dataIndex: 'weights',
      width: 100,
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      width: 100,
    },
    {
      title: 'Data',
      width: 100,
      // TODO key
      render: (text, record) => {
        return (
          <>
            <div>{record.created_at}</div>
            <div>{record.updated_at}</div>
          </>
        );
      },
    },
    {
      title: 'Admin',
      dataIndex: ['admin', 'username'],
      width: 100,
    },
    {
      title: 'Actions',
      width: 100,
      render: (_, record) => {
        return (
          <Space direction="vertical">
            {hasPermission('update', record.admin_id) && (
              <Button
                type="link"
                onClick={() =>
                  openNewRatingModal(record.vendor_id, record.id, { ...record })
                }
              >
                Update
              </Button>
            )}
            {hasPermission('delete', record.admin_id) && (
              <Popconfirm
                title="Are you sure to delete this record?"
                onConfirm={() => {
                  deleteItem(record.id);
                }}
              >
                <Button type="link">Delete</Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <AutoResizeTable
        {...rest}
        title={() => ''}
        className="spot-rates-drawer-table"
        loading={loading}
        pagination={false}
        size="small"
        columns={columns}
        dataSource={dataSource}
        scroll={{
          x: 'auto',
        }}
      />
      <NewRatingModal onOk={() => onFinish && onFinish()} />
    </>
  );
};

export default Index;
