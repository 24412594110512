import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Select,
  Drawer,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { VendorInsuranceDrawer } from './components/VendorInsuranceDrawer';
import { useForm } from 'antd/lib/form/Form';
import { TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import moment from 'moment-timezone';
import AutoResizeTable from '@/components/AutoResizeTable';
import AmountFormatter from '@/components/AmountFormatter';
import { MinimunInsuranceAmount } from './components/data';
import Decimal from 'decimal.js';
import { showErrorMessage } from '@/utils/show-error-message';

const today = moment().tz('America/Los_Angeles').format('YYYY-MM-DD');
const RenderAmount: React.FC<{ amount: number; minAmount: number }> = ({
  amount,
  minAmount,
}) => {
  const isLessThanMinimum = new Decimal(amount).lessThan(
    new Decimal(minAmount),
  );

  return (
    <div className={isLessThanMinimum ? 'text-danger' : ''}>
      <AmountFormatter amount={amount} />
    </div>
  );
};

const RenderExpiredAt: React.FC<{ expiredAt: string }> = ({ expiredAt }) => {
  if (expiredAt == today) {
    return <>{expiredAt}</>;
  }

  const isExpired = moment(expiredAt).isBefore(
    moment(today).tz('America/Los_Angeles'),
    'day',
  );
  return <div className={isExpired ? 'text-danger' : ''}>{expiredAt}</div>;
};

type TChassisType = {
  id: number;
  name: string;
  type: string;
  cntr_size: Array<number>;
};
const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TChassisType>>();
  const [vendorInsuranceId, setVendorInsuranceId] = React.useState(0);
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('vendor_insurances', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        showErrorMessage(e);
      }

      setLoading(false);
    },
    [],
  );

  const addNew = () => {
    setVisible(true);
    setVendorInsuranceId(0);
  };

  const handleSaved = async (data: any) => {
    fetchData();
    handleClose();
  };

  const handleClose = () => {
    setVisible(false);
    setVendorInsuranceId(0);
  };
  const handleRemove = async (id: number) => {
    setLoading(true);

    try {
      await app.service.delete(`vendor_insurances/${id}`);

      message.success('Deleted');

      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleEdit = async (record: any) => {
    setVisible(true);
    setVendorInsuranceId(record.id);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        render: (text, record, index) => <>{record.id}</>,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
      },
      {
        title: 'Mc Number',
        dataIndex: 'mc_number',
        key: 'mc_number',
        width: 200,
      },
      {
        title: 'General Liability Amount',
        dataIndex: 'general_liability_amount',
        key: 'general_liability_amount',
        width: 200,
        render: (text, record) => {
          return (
            <div>
              <RenderAmount
                amount={text}
                minAmount={MinimunInsuranceAmount.GENERAL_LIABILITY}
              />

              {/* <div>{record.general_liability_amount_expired_at}</div> */}
              <RenderExpiredAt
                expiredAt={record.general_liability_amount_expired_at}
              />
            </div>
          );
        },
      },
      {
        title: 'Cargo Insurance Amount',
        dataIndex: 'cargo_insurance_amount',
        key: 'cargo_insurance_amount',
        width: 200,
        render: (text, record) => {
          return (
            <div>
              <RenderAmount
                amount={text}
                minAmount={MinimunInsuranceAmount.CARGO_INSURANCE}
              />

              {/* <div>{record.cargo_insurance_amount_expired_at}</div> */}
              <RenderExpiredAt
                expiredAt={record.cargo_insurance_amount_expired_at}
              />
            </div>
          );
        },
      },
      {
        title: 'Automobile Insurance Amount',
        dataIndex: 'automobile_insurance_amount',
        key: 'automobile_insurance_amount',
        width: 200,
        render: (text, record) => {
          return (
            <div>
              <RenderAmount
                amount={text}
                minAmount={MinimunInsuranceAmount.AUTOMOBILE_INSURANCE}
              />
              {/* <div>{record.automobile_insurance_amount_expired_at}</div> */}
              <RenderExpiredAt
                expiredAt={record.automobile_insurance_amount_expired_at}
              />
            </div>
          );
        },
      },
      {
        title: 'Expired',
        dataIndex: 'expired',
        key: 'expired',
        width: 100,
        render: (text, record) => {
          if (text) {
            return <Tag color="red">Yes</Tag>;
          }
          return <Tag color="green">No</Tag>;
        },
      },
      {
        title: 'Agent or Headquarter',
        dataIndex: 'agent_or_headquarter',
        key: 'agent_or_headquarter',
        width: 150,
      },
      {
        title: 'Memo',
        dataIndex: 'memo',
        key: 'memo',
        width: 200,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            <Popconfirm
              placement="left"
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}
            >
              <a>Delete</a>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Vendor Insurances"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New Vendor Insurance
            </Button>
          </div>
        }
      ></Header>

      <div className="ml-lg">
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <Row gutter={16}>
            <Col>
              <Form.Item name="name" label="Name">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="mc_number" label="MC#">
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="expired" label="Expired">
                <Select allowClear>
                  <Select.Option value="">All</Select.Option>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="vendor_blacklisted" label="Blacklisted Vendor">
                <Select allowClear>
                  <Select.Option value="">All</Select.Option>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button onClick={() => filter.resetFields()}>
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <VendorInsuranceDrawer
        visible={visible}
        id={vendorInsuranceId}
        onClose={handleClose}
        onSaved={handleSaved}
      />
    </div>
  );
};

export default Index;
