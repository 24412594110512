import { Divider, Modal, Space, Spin, message } from 'antd';
import React, { useMemo } from 'react';
import { CreditCardOutlined } from '@ant-design/icons';
import { InvoiceTable } from './InvoiceTable';
import { BillTable } from './BillTable';
import { useApp } from '@/utils/useapp';
import { TBill } from '@/types';
import AccountingActionTips from '@/pages/dispatchs/components/AccountingActionTips';
import Decimal from 'decimal.js';
import { INVOICE_TYPE_CFS } from '@/pages/accounting/invoices/components/data';
import { BILL_TYPE_CFS } from '@/pages/accounting/bills/components/data';
import { showErrorMessage } from '@/utils/show-error-message';

export const AccountingModal: React.FC<{
  shipmentId: number;
  onChanged?: () => void;
  showAccountingTips?: boolean;
  accountingActionTipsProps?: {
    color: string;
    list: any[];
  };
}> = ({
  shipmentId,
  onChanged,
  showAccountingTips = false,
  accountingActionTipsProps,
}) => {
  const app = useApp();

  const [open, setOpen] = React.useState<boolean>(false);

  const [invoicesTotal, setInvoicesTotal] = React.useState(0);
  const [billsTotal, setBillsTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [allInvoices, setAllInvoices] = React.useState<Array<any>>([]);
  const [allBills, setAllBills] = React.useState<TBill[]>([]);
  const [invoicesSelected, setInvoiceSelected] = React.useState<any>([]);
  const fetchInvoicesData = async () => {
    setLoading(true);
    try {
      const response = await app.service.get('orderInvoices', {
        params: {
          invoiceable_id: shipmentId,
          invoiceable_type: INVOICE_TYPE_CFS,
          per_page: 1000,
        },
      });

      setAllInvoices(response.data);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchBillsData = async () => {
    setLoading(true);
    try {
      const response = await app.service.get('orderBills', {
        params: {
          billable_id: shipmentId,
          billable_type: BILL_TYPE_CFS,
          per_page: 1000,
        },
      });

      setAllBills(response.data);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSavedBill = () => {
    fetchBillsData();
    onChanged && onChanged();
  };

  const handleSavedInvoice = () => {
    fetchInvoicesData();
    onChanged && onChanged();
  };

  const handleSettled = () => {
    fetchInvoicesData();
  };

  const invoices = React.useMemo(() => {
    return allInvoices;
  }, [allInvoices]);

  const bills = React.useMemo(() => {
    return allBills;
  }, [allBills]);

  const total = useMemo(() => {
    return new Decimal(invoicesTotal).minus(new Decimal(billsTotal));
  }, [invoicesTotal, billsTotal]);

  React.useEffect(() => {
    if (open && shipmentId) {
      // fetchContainers();
      fetchBillsData();
      fetchInvoicesData();
    }
  }, [open, shipmentId]);

  return (
    <>
      {showAccountingTips ? (
        <AccountingActionTips {...accountingActionTipsProps}>
          <div onClick={() => setOpen(!open)}>
            <CreditCardOutlined />
          </div>
        </AccountingActionTips>
      ) : (
        <div onClick={() => setOpen(!open)}>
          <CreditCardOutlined />
        </div>
      )}
      {open && (
        <>
          <Modal
            title="Accounting"
            open={open}
            onCancel={() => setOpen(!open)}
            width="80vw"
            footer={
              <Space className="w100" direction="vertical" align="end">
                <h3>Total {total.toFixed(2)}</h3>
              </Space>
            }
          >
            <Spin spinning={loading}>
              <>
                <InvoiceTable
                  id={shipmentId}
                  invoices={invoices}
                  rowSelection={{
                    onSelect: (a: any, b: any, selectedRows: any) =>
                      setInvoiceSelected(selectedRows),
                    onSelectAll: (a: any, selectedRows: any) =>
                      setInvoiceSelected(selectedRows),
                  }}
                  activeSelectedInvoices={invoicesSelected}
                  onSaved={handleSavedInvoice}
                  onDeleted={handleSavedInvoice}
                  onSettled={handleSettled}
                  setInvoiceTotal={(total: number) => setInvoicesTotal(total)}
                />
                <Divider />
                <BillTable
                  bills={bills}
                  onSaved={handleSavedBill}
                  id={shipmentId}
                  setBillsTotal={(total: number) => setBillsTotal(total)}
                />
              </>
            </Spin>
          </Modal>
        </>
      )}
    </>
  );
};
