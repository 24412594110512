import { useApp } from '@/utils/useapp';
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useObserver } from 'mobx-react';
import { SubTabInterface } from '../..';
import { OPdashboardSubTabInterface } from '@/Interfaces/OPdashboardTab/OPdashboardSubTabInterface';
import { ManageColumns } from '@/pages/dispatchs/components/ManageColumns';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import update from 'immutability-helper';
import useDispatch from '@/pages/dispatchs/components/Table/useDispatch';
import ShipmentsTable from '../../../shipments/ShipmentTable';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { CACHE_LTL_COLOR_TAG_KEY } from '@/stores/cache';
import { ShipmentInterface } from '../../../shipments/Interfaces/ShipmentInterface';
import ShipmentDrawer from '@/pages/truckload/ltl/components/ShipmentDrawer';
import {
  convertFilterValuesToQueryParams,
  Filter as LTLShipmentFilter,
} from '@/pages/truckload/ltl/components/Filter';
import { TCollection } from '@/types';
import _, { get } from 'lodash';
import moment from 'moment-timezone';
import { useLocation } from 'umi';
import { message } from 'antd';
import usePagination from '@/components/usePagination';
import { showErrorMessage } from '@/utils/show-error-message';

const ShipmentSubTab: React.FC<
  {
    searchQuery?: string | null;
    searchQueryType?: string;
    opdashboardFilters: any;
    subTab: OPdashboardSubTabInterface;
    selectedDate?: string | null;
    updateCustomProperties: (properties: any) => void;
  } & SubTabInterface
> = ({
  searchQuery,
  searchQueryType,
  opDashboardTab,
  subTab,
  selectedDate,
  updateCustomProperties,
  refresh = true,
  setRefresh,
  goToOperationTable,
}) => {
  const app = useApp();
  useEffect(() => {
    app.store.cache.fetch(CACHE_LTL_COLOR_TAG_KEY, true);
  }, []);

  const { sortOrder, toFilterSortValue, SORT_MAP } = useDispatch();
  const [data, setData] = useState<TCollection<ShipmentInterface>>();
  const [shipformVisible, setShipformVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<ShipmentInterface>();
  const [openId, setOpenId] = useState(0);

  const [defaultColumns, setDefaultColumns] = useState<any[]>([]);

  const activeFilters = useMemo(() => {
    return get(opDashboardTab, `custom_properties.${subTab?.id}.filters`, []);
  }, [opDashboardTab?.custom_properties, subTab]);

  const location = useLocation();
  const pagination = usePagination(data);

  const fetchData = useCallback(
    async (
      _pagination?: TablePaginationConfig,
      _filters?: any,
      isEmptyQuery?: boolean,
      sorter?:
        | SorterResult<ShipmentInterface>
        | SorterResult<ShipmentInterface>[],
      query?: string,
    ) => {
      setLoading(true);

      _filters = _filters || activeFilters || [];
      const sort = _filters?.find((f: any) => f.attribute === 'sort') || null;
      const params =
        (query || searchQuery) && !isEmptyQuery
          ? {
              query: query || searchQuery,
              query_type: searchQueryType,
              ...convertFilterValuesToQueryParams([sort || {}]),
            }
          : convertFilterValuesToQueryParams(_filters);
      let opdashboardParams = {};

      if (!params.query) {
        opdashboardParams = {
          opdashboard_tab_id: opDashboardTab.id,
          sub_tab: subTab.id,
          opdashboard_date: selectedDate
            ? selectedDate
            : moment().tz('America/Los_Angeles').format('YYYY-MM-DD'),
        };
      }

      try {
        const resp = await app.service.get('tl/ltl/shipments', {
          params: {
            ...params,
            page: _pagination ? _pagination.current : pagination.current,
            per_page: _pagination ? _pagination.pageSize : pagination.pageSize,
            ...opdashboardParams,
            sort_by: Array.isArray(sorter)
              ? sort?.value?.split('|')?.[0]
              : sorter?.columnKey ||
                sorter?.field ||
                sort?.value?.split('|')?.[0],
            sort_value: Array.isArray(sorter)
              ? sort?.value?.split('|')?.[1]
              : sorter?.order === 'ascend'
              ? 'asc'
              : sorter?.order === 'descend'
              ? 'desc'
              : sort?.value?.split('|')?.[1],
          },
        });

        setData(resp);
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setLoading(false);
        refresh && setRefresh(false);
      }
    },
    [
      activeFilters,
      searchQuery,
      searchQueryType,
      pagination,
      opDashboardTab,
      subTab,
      selectedDate,
      refresh,
    ],
  );

  const refreshLtlShipment = useCallback(
    async (id: number) => {
      if (!data || !id) {
        return;
      }
      const _shipment = await app.service.get(`tl/ltl/shipments/${id}`);

      const collection = data.data.map((item) => {
        if (item.id === _shipment.data.id) {
          return _shipment.data;
        }
        return item;
      });
      setData({ ...data, data: collection });
    },
    [data],
  );

  const handleShow = (m: any) => {
    setModel(m);
    setShipformVisible(true);
  };

  const handlePatchUpdate = useCallback(
    async (shipmentId: number, model: string, values: any) => {
      setLoading(true);
      try {
        await app.service.patch(`tl/ltl/${model}/${shipmentId}`, {
          data: values,
        });

        fetchData();
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setLoading(false);
      }
    },
    [fetchData],
  );

  const activeColumns = useMemo(() => {
    return get(opDashboardTab, `custom_properties.${subTab?.id}.columns`, []);
  }, [opDashboardTab?.custom_properties, subTab]);

  const checkedColumns = useMemo(() => {
    if (activeColumns.length === 0) {
      return defaultColumns?.map((c: any) => c.key);
    }

    return activeColumns;
  }, [defaultColumns, activeColumns]);

  const toSaveFilters = (filters: any) => {
    updateCustomProperties({ filters });
  };

  const handleRemoveFilter = useCallback(
    (index: number) => {
      toSaveFilters(update(activeFilters, { $splice: [[index, 1]] }));
    },
    [activeFilters],
  );

  const handleRemoveSortInFilterValues = (sortBy: string) => {
    if (!activeFilters) {
      return;
    }
    const index = activeFilters.findIndex((fv) => fv.attribute === 'sort');
    if (index === -1) {
      return;
    }
    const sortIndex = activeFilters[index].value.indexOf(`${sortBy}|`);
    if (sortIndex === -1) {
      return;
    }
    handleRemoveFilter(index);
  };

  const columns: ColumnsType<any> = React.useMemo(() => {
    const sort = activeFilters?.find((f) => f.attribute === 'sort') || null;
    const _columns: ColumnsType<any> = defaultColumns?.map((c: any) => ({
      ...c,
      sortOrder: sortOrder(sort, c.key),
    }));

    if (activeColumns?.length === 0) {
      return _columns;
    }

    return _columns.filter((c) => activeColumns.includes(c.key));
  }, [defaultColumns, activeColumns, activeFilters]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ShipmentInterface>,
  ) => {
    const sortValue = SORT_MAP[sorter.order as keyof typeof SORT_MAP] || '';
    const sortBy = sorter.field;
    // 禁用排序
    if (!sortValue) {
      handleRemoveSortInFilterValues(sortBy);
      fetchData(pagination, null, !searchQuery, sorter);
      return;
    }
    const column = columns.find((c) => c.key === sortBy);
    if (!column) {
      handleRemoveSortInFilterValues(sortBy);
      fetchData(pagination, null, !searchQuery, sorter);
      return;
    }
    const filterSortValue = toFilterSortValue(column?.title, sortBy, sortValue);
    if (!activeFilters) {
      fetchData(pagination, null, !searchQuery, sorter);
      return;
    }
    const index = activeFilters.findIndex((fv) => fv.attribute === 'sort');
    if (index !== -1) {
      toSaveFilters(
        update(activeFilters, { [index]: { $set: filterSortValue } }),
      );
    } else {
      toSaveFilters([...activeFilters, filterSortValue]);
    }
  };

  const handleVerificationChange = useCallback(
    async (shipmentId: number, key: string, checked: boolean) => {
      setLoading(true);
      try {
        await app.service.patch(`tl/ltl/shipments/${shipmentId}/verify`, {
          data: {
            key,
            verified: checked,
          },
        });
        await refreshLtlShipment(shipmentId);
        message.success('Verification status updated successfully');
      } catch (err: any) {
        console.error('Verification update error:', err);
        showErrorMessage(err, {
          fallback: 'Failed to update verification status',
        });
      } finally {
        setLoading(false);
      }
    },
    [app.service, refreshLtlShipment],
  );

  useEffect(() => {
    // console.log(subTab, opDashboardTab);
    if (subTab?.id && opDashboardTab?.id) {
      fetchData();
    }
  }, [subTab, opDashboardTab]);

  useEffect(() => {
    // console.log(selectedDate, subTab, opDashboardTab);
    if (subTab?.id && opDashboardTab?.id && selectedDate) {
      fetchData();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (refresh && subTab?.id && opDashboardTab?.id) {
      fetchData();
    }
  }, [refresh]);

  useEffect(() => {
    if (searchQuery) {
      fetchData();
    }
  }, [searchQuery, searchQueryType]);

  return useObserver(() => {
    return (
      <>
        <div className="flex justify-between p-xs">
          <div>
            <LTLShipmentFilter
              show={false}
              values={[]}
              onChange={(v: any) => {
                toSaveFilters(v);
              }}
              removeAll={() => toSaveFilters([])}
              removeOne={(index: number) =>
                toSaveFilters(update(activeFilters, { $splice: [[index, 1]] }))
              }
              activeFilters={activeFilters || []}
              hideFilter={() => setIsShowFilter(false)}
              loading={loading}
            />
          </div>
          <div>
            <ManageColumns
              items={defaultColumns?.map((c: any) => {
                return (
                  {
                    key: c.key,
                    name: c.title instanceof Function ? c.label : c.title,
                  } || []
                );
              })}
              checkedColumns={checkedColumns}
              onChange={(list) => {
                updateCustomProperties({ columns: list });
              }}
            />
          </div>
        </div>

        <div
        //style={{ maxHeight: '50vh' }}
        >
          <ShipmentsTable
            data={data}
            pagination={pagination}
            fetchData={fetchData}
            tabId={opDashboardTab?.id}
            columns={columns}
            handleTableChange={handleTableChange}
            handleShow={handleShow}
            handlePatchUpdate={handlePatchUpdate}
            refreshLtlShipment={refreshLtlShipment}
            setDefaultColumns={setDefaultColumns}
            // onSetSelected={(
            //   selectedRowKeys: React.Key[],
            //   selectedRows: any[],
            // ) => {
            //   setSelectedRows(selectedRows);
            // }}
            openId={openId}
            onVerificationChange={handleVerificationChange}
            // loading={loading}
          />

          <ShipmentDrawer
            visible={shipformVisible}
            // setVisible={setShipformVisible}
            model={model}
            onSaved={(id: number, close = true) => {
              if (id) {
                refreshLtlShipment(id);
              } else {
                fetchData(pagination);
              }
              close && setShipformVisible(false);
            }}
            onBooked={(id: number) => refreshLtlShipment(id)}
            onClose={() => {
              // fetchData();
              setShipformVisible(false);
            }}
          />
        </div>
      </>
    );
  });
};

export default ShipmentSubTab;
