import { useEffect, useState } from 'react';
import { useMutationObserver } from 'ahooks';
import { useApp } from '@/utils/useapp';

const src = 'https://udify.app/embed.min.js';

const updateOpenIcon = () => {
  const openIcon = document.querySelector(
    '#dify-chatbot-bubble-button #openIcon',
  );
  if (!openIcon) {
    return;
  }
  const el = document.querySelector('#dify-chatbot-bubble-button > div');
  if (!el) {
    return;
  }
  el.innerHTML =
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" color="#fff"><path d="M4 15.5a2 2 0 1 1 0-4m16 4a2 2 0 1 0 0-4M7 7V4m10 3V4"/><circle cx="7" cy="3" r="1"/><circle cx="17" cy="3" r="1"/><path d="M13.5 7h-3c-2.828 0-4.243 0-5.121.909S4.5 10.281 4.5 13.207s0 4.389.879 5.298c.878.909 2.293.909 5.121.909h1.025c.792 0 1.071.163 1.617.757c.603.657 1.537 1.534 2.382 1.738c1.201.29 1.336-.111 1.068-1.256c-.076-.326-.267-.847-.066-1.151c.113-.17.3-.212.675-.296c.591-.132 1.079-.348 1.42-.701c.879-.91.879-2.372.879-5.298s0-4.389-.879-5.298C17.743 7 16.328 7 13.5 7"/><path d="M9.5 15c.57.607 1.478 1 2.5 1s1.93-.393 2.5-1m-5.491-4H9m6.009 0H15"/></g></svg>';
};

export function useDifyChat() {
  const app = useApp();

  const account = app.store.auth.account;

  const [session, setSession] = useState<string>();

  const destroy = () => {
    // @ts-expect-error No types.
    window.difyChatbotConfig = undefined;

    document.querySelector(`script#${DIFY_CHAT_TOKEN}`)?.remove();
    // 我们不再移除样式，避免样式跳动
  };

  const create = () => {
    // @ts-expect-error No types.
    window.difyChatbotConfig = {
      token: DIFY_CHAT_TOKEN,
      dynamicScript: true,
      inputs: {
        session,
      },
    };

    // 检查是否已经插入 script，避免重复插入
    let script = document.querySelector(
      `script#${DIFY_CHAT_TOKEN}`,
    ) as HTMLScriptElement | null;
    if (!script) {
      script = document.createElement('script');
      script.src = src;
      script.id = DIFY_CHAT_TOKEN;
      script.defer = true;
      document.body.appendChild(script);
    }

    // 检查是否已经插入 style，避免重复插入
    let style = document.querySelector(
      'style#dify-chatbot-style',
    ) as HTMLStyleElement | null;
    if (!style) {
      style = document.createElement('style');
      style.id = 'dify-chatbot-style';
      // 修改 #dify-chatbot-bubble-button 跟随 Frontapp Chat 样式，Frontapp Chat 打开时可以覆盖按钮
      // 修改 #dify-chatbot-bubble-window 确保面板出现在上方而不是下方
      // 要获取高亮可以安装 tobermory.es6-string-html
      style.innerHTML = /*css*/ `
				#dify-chatbot-bubble-button {
					--dify-chatbot-bubble-button-bg-color: #007bff;
					z-index: 998;
				}
			`;
      document.body.appendChild(style);
    }
  };

  useEffect(() => {
    app.service.post('aiChatSessions').then((res) => {
      setSession(res.session);
    });
  }, []);

  useEffect(() => {
    if (!DIFY_CHAT_ENABLE) {
      destroy();
      return;
    }

    if (!account?.id || !session) {
      destroy();
      return;
    }

    create();

    return () => {
      destroy();
    };
  }, [account, session]);

  // 监听 body 变化，初次插入时更新 open icon
  useMutationObserver(updateOpenIcon, document.body, {
    childList: true,
  });

  // 监听 dify chat 显隐，显示 open icon 时更新 open icon
  useMutationObserver(
    updateOpenIcon,
    document.querySelector('#dify-chatbot-bubble-button > div'),
    {
      childList: true,
    },
  );
}
