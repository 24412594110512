import React, { useEffect } from 'react';
import {
  Form,
  FormInstance,
  Input,
  Row,
  Col,
  Switch,
  Select,
  Space,
} from 'antd';

import { ContactList } from '@/components/ContactList';
import TextArea from 'antd/lib/input/TextArea';
import { Documents } from '@/components/Upload/Documents';
import { advancedTermsRule } from '@/utils/advancedTerms';
import { TIER_MAP } from '@/pages/entity/intermodalRegions/components/data';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { DocumentsStoreLocalFormItem } from '@/components/Upload/DocumentsStoreLocalFormItem';
import { MarginPercentageChangeLog } from '../MarginPercentageChangeLog';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useWatch } from 'antd/lib/form/Form';
interface Props {
  form: FormInstance;
  onSubmit: () => void;
}

export const CompanyForm: React.FC<Props> = ({ form, onSubmit }) => {
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const id = useWatch(['id'], form);

  const formatBillingAddress = () => {
    const name = form.getFieldValue('name');
    const address = form.getFieldValue('address');
    return `${name}\n${address}`;
  };

  const onValuesChange = (changedValues: any) => {
    if (changedValues.name || changedValues.address) {
      form.setFieldsValue({ billing_address: formatBillingAddress() });
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={onValuesChange}
      onFinish={onSubmit}
    >
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true, message: 'Please Input Code' }]}
          >
            <Input id="user_form_code" placeholder="Enter code" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="English Name"
            name="name"
            rules={[{ required: true, message: 'Please Input Name' }]}
          >
            <Input id="user_form_name" placeholder="Enter name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Legal Name"
            name="legal_name"
            rules={[{ required: true, message: 'Please Input Legal Name' }]}
          >
            <Input id="legal_form_name" placeholder="Enter legal name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Terms"
            name="terms"
            extra="Please input terms in the format of 15 or 30 or 25+28 or 28+5 ..."
            rules={[
              { required: true, message: 'Please Input terms' },
              advancedTermsRule,
            ]}
          >
            <Input placeholder="Enter terms" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: 'Please Input Address' }]}
          >
            <TextArea rows={6} placeholder="Enter company" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Billing Address"
            name="billing_address"
            rules={[
              { required: true, message: 'Please Input Billing Address' },
              // add validator to check if billing address includes input name
              {
                validator: (rule, value) => {
                  const name = form.getFieldValue('name');
                  if (value.includes(name)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'Billing Address must include English Name',
                  );
                },
                message: 'Billing Address must include English Name',
              },
            ]}
          >
            <TextArea rows={6} placeholder="Enter company" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Has Contract" name="has_contract">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item
                label={
                  <Space>
                    LTL Margin Percent
                    <MarginPercentageChangeLog
                      id={id}
                      icon={<InfoCircleOutlined color="blue" />}
                      type="tl_margin_percent"
                    />
                  </Space>
                }
                name="tl_margin_percent"
                extra="Please input LTL margin percent."
                rules={[
                  {
                    required: true,
                    message: 'Please Input LTL margin percent',
                  },
                  advancedTermsRule,
                ]}
                initialValue={10}
              >
                <Input
                  addonAfter={'%'}
                  placeholder="Enter LTL margin percent"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={
                  <Space>
                    FTL Margin Percent
                    <MarginPercentageChangeLog
                      id={id}
                      icon={<InfoCircleOutlined color="blue" />}
                      type="ftl_margin_percent"
                    />
                  </Space>
                }
                name="ftl_margin_percent"
                extra="Please input FTL margin percent."
                rules={[
                  {
                    required: true,
                    message: 'Please Input FTL margin percent',
                  },
                  advancedTermsRule,
                ]}
                initialValue={5}
              >
                <Input
                  addonAfter={'%'}
                  placeholder="Enter FTL margin percent"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={
                  <Space>
                    CFS Margin Percent
                    <MarginPercentageChangeLog
                      id={id}
                      icon={<InfoCircleOutlined color="blue" />}
                      type="cfs_margin_percent"
                    />
                  </Space>
                }
                name="cfs_margin_percent"
                extra="Please input CFS margin percent."
                rules={[
                  {
                    required: true,
                    message: 'Please Input CFS margin percent',
                  },
                  advancedTermsRule,
                ]}
                initialValue={15}
              >
                <Input
                  addonAfter={'%'}
                  placeholder="Enter CFS margin percent"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="tier_rate"
                label={
                  <Space>
                    Drayage Tier Rate
                    <MarginPercentageChangeLog
                      id={id}
                      icon={<InfoCircleOutlined color="blue" />}
                      type="tier_rate"
                    />
                  </Space>
                }
              >
                <Select placeholder="Select tier" allowClear>
                  {Object.keys(TIER_MAP).map((key: any) => (
                    <Select.Option key={key} value={key}>
                      {TIER_MAP[(key as unknown) as keyof typeof TIER_MAP]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) =>
              getFieldValue('id') ? (
                <Documents
                  title="Documents"
                  target="company"
                  targetId={getFieldValue('id')}
                />
              ) : (
                <DocumentsStoreLocalFormItem form={form} />
              )
            }
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Agent" name="agent_id">
            <UserSyncSelect type="user" allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Form.Item
                name="contacts"
                key="form_contacts"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <ContactList
                  data={getFieldValue('contacts') || []}
                  updateData={(list) =>
                    handleChange({ target: { name: 'contacts', value: list } })
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
