import {
  Button,
  message,
  Steps,
  Drawer,
  Form,
  Space,
  Divider,
  Card,
  Row,
  Col,
} from 'antd';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/es/form/Form';
import { SearchVendors } from './SearchVendors';
import { ChooseVendors } from './ChooseVendors';
import { SendEmail } from './SendEmail';
import styles from 'res/css/ui.scss';
import { get, reduce } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';

export const DrawerAutoSend: React.FC<{
  rates: any;
  isRemind: boolean;
  onSent: () => void;
  onClose?: () => void;
  visible: boolean;
}> = ({ rates, isRemind, onSent, onClose, visible }) => {
  const app = useApp();

  const [showEmailForm, setShowEmailForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [vendorData, setVendorData] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filter, setFilter] = useState<any>();
  const [showAllFilter, setShowAllFilter] = useState(false);

  const intermodalRegion = useMemo(() => get(rates[0], 'intermodal_region'), [
    rates,
  ]);
  const requestWarehouses = useMemo(
    () =>
      reduce(
        rates,
        (result, rate) => {
          const name = get(rate, 'to_city.full_name');
          const zipcode = get(rate, 'to_zipcode');
          const to_city_id = get(rate, 'to_city.id');
          if (
            !result.find((w: any) => w.name == name && w.zipcode == zipcode)
          ) {
            result.push({
              to_city_id,
              name,
              zipcode,
            });
          }
          return result;
        },
        [],
      ),
    [rates],
  );

  const [form] = useForm();

  const handleFilterChange = (values: any) => {
    setFilter(values);
  };

  const initial = () => {
    setSending(false);
    setShowEmailForm(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setFilter(null);
  };
  const handleOnSent = () => {
    initial();
    onSent();
  };

  const onSend = () => {
    setSending(true);
  };

  const onCancelSend = () => {
    setSending(false);
  };

  const handleSearch = async () => {
    const _filter = { ...filter };
    if (_filter.city) {
      _filter.city_id = _filter.city.id;
      delete _filter.city;
    }

    setLoading(true);
    try {
      const result = await app.service.get('searchAutoSendVendors', {
        params: {
          ..._filter,
          isRemind: isRemind,
          requested_rate_ids: rates.map((r) => r.id),
          intermodal_region_id: !isRemind ? intermodalRegion.id : undefined,
          per_page: 10000,
          page: 1,
        },
      });

      const _selectedRowKeys = result.data.filter((d: any) => {
        if (selectedRowKeys?.includes(d.id)) {
          return true;
        }

        if (isRemind) {
          if (get(d, 'rate_received')) {
            return false;
          } else {
            return true;
          }
        } else {
          return get(d, 'favorited', false);
        }
      });

      if (_selectedRowKeys.length > 0) {
        const selectedIds = _selectedRowKeys.map((s: any) => s.id);
        form.setFieldValue('vendor_ids', selectedIds);
        setSelectedRowKeys(selectedIds);
      }

      setVendorData(result.data);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePrev = () => {
    setShowEmailForm(false);
  };

  const handleConfirm = async () => {
    setShowEmailForm(true);
  };

  const handleClose = () => {
    initial();
    onClose && onClose();
  };

  useEffect(() => {
    if (rates) {
      setShowEmailForm(false);
      form.setFieldValue(
        'requested_rate_ids',
        rates.map((r: any) => r.id),
      );
    }
  }, [rates]);

  useEffect(() => isRemind && handleSearch(), [isRemind]);

  return (
    <>
      {' '}
      <Drawer
        title={isRemind ? 'Remind' : 'Auto Send'}
        placement={'right'}
        width={'90%'}
        onClose={handleClose}
        destroyOnClose={true}
        open={visible}
      >
        <Form form={form}>
          <Form.Item name="requested_rate_ids" hidden noStyle />
          <Form.Item name="vendor_ids" hidden noStyle shouldUpdate />
        </Form>

        {!showEmailForm && (
          <>
            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <SearchVendors
                  rates={rates}
                  intermodalRegion={intermodalRegion}
                  requestWarehouses={requestWarehouses}
                  filter={filter}
                  onChange={(values) => handleFilterChange(values)}
                  loading={loading}
                  handleSearch={handleSearch}
                  clearAll={() => setFilter({})}
                  isRemind={isRemind}
                  setShowAllFilter={(show) => setShowAllFilter(show)}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <ChooseVendors
                  data={vendorData}
                  selectedRowKeys={selectedRowKeys}
                  loading={loading}
                  showAllFilter={showAllFilter}
                  onSelect={(selectedRowKeys) => {
                    setSelectedRowKeys(selectedRowKeys);
                    form.setFieldValue('vendor_ids', selectedRowKeys);
                  }}
                />
                <div className="flex justify-start mt-md">
                  <Button
                    type="primary"
                    onClick={handleConfirm}
                    disabled={
                      form.getFieldValue('vendor_ids') &&
                      form.getFieldValue('vendor_ids').length > 0
                        ? false
                        : true
                    }
                  >
                    Confirm
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
        {showEmailForm && (
          <Row gutter={16} style={{ height: '89vh' }}>
            <Col className="gutter-row h100" span={24}>
              <div style={{ height: '80vh' }}>
                <SendEmail
                  rates={rates}
                  intermodalRegion={intermodalRegion}
                  requestWarehouses={requestWarehouses}
                  sending={sending}
                  onSending={(sending: boolean) => setSending(sending)}
                  form={form}
                  onCancelSend={onCancelSend}
                  onSent={handleOnSent}
                />
                <div></div>
                <div className="flex justify-start mt-md">
                  <Space>
                    <Button type="primary" onClick={onSend} loading={loading}>
                      Send
                    </Button>
                    <Button onClick={handlePrev}>Previous</Button>
                  </Space>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Drawer>
    </>
  );
};
