import React, { useEffect, useMemo } from 'react';

import {
  Form,
  Popover,
  Typography,
  Space,
  message,
  TimePicker,
  Radio,
  Spin,
  Button,
  PopoverProps,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { get, isDate } from 'lodash';
import moment, { Moment } from 'moment';

import { ClockCircleOutlined } from '@ant-design/icons';
import { useForm, useWatch } from 'antd/lib/form/Form';
import DefaultIcon from './DefaultIcon';
import TimeDetail from './TimeDetail';
import TimeText from './TimeText';

import {
  RESERVER_TIME_TYPE_ANYTIME,
  RESERVER_TIME_TYPE_RANGE,
  RESERVER_TIME_TYPE_TIME,
  RESERVER_TIME_TYPE_MAP,
} from '../data';
import { time } from 'echarts';
import { showErrorMessage } from '@/utils/show-error-message';

const TIME_FORMATE = 'HH:mm';
export const FIELD = 'reserve_time';
export const RerserveTime: React.FC<{
  disabled?: boolean;
  trigger?: string;
  placement?: PopoverProps['placement'];
  name: string;
  value?: {
    type: string;
    time: string | string[];
  };
  is_append_to_default?: boolean;
  is_show_time_detail?: boolean;
  record: any;
  isContainer?: boolean;
  saveText?: string;
  customUpdate?: (data: any) => void;
  refreshContainer?: (id: number) => void;
}> = ({
  disabled = false,
  trigger = 'click',
  placement = 'right',
  value,
  name,
  record,
  is_append_to_default = true,
  is_show_time_detail = false,
  isContainer = true,
  saveText = 'Save',
  customUpdate,
  refreshContainer,
}) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);

  const initialValues = useMemo(
    () =>
      value ? value : get(record, is_append_to_default ? FIELD : name, {}),
    [record, name, value],
  );

  const values = useMemo(
    () =>
      initialValues
        ? is_append_to_default
          ? get(initialValues[name], 'time')
          : get(initialValues, 'time')
        : null,
    [initialValues],
  );

  const [form] = useForm();

  const handlePatchUpdate = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    const updateValues = is_append_to_default
      ? {
          ...get(record, FIELD, {}),
          [name]: values,
        }
      : values;

    if (customUpdate) {
      return customUpdate(updateValues);
    }

    if (!isContainer || !record.id) {
      return;
    }
    setLoading(true);
    try {
      await app.service.patch(`containers/${record.id}`, {
        data: {
          [is_append_to_default ? FIELD : name]: updateValues,
        },
      });

      refreshContainer && refreshContainer(record.id);
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const isThisType = (type: string) => {
    if (is_append_to_default) {
      return (
        initialValues &&
        initialValues[name] &&
        initialValues[name]['type'] == type
      );
    } else {
      return initialValues && initialValues['type'] == type;
    }
  };

  const isAnyTimeType = (type: string) => type == RESERVER_TIME_TYPE_ANYTIME;

  const getTime = (type: string) => {
    let time;

    if (isThisType(type)) {
      time = is_append_to_default
        ? initialValues[name]['time']
        : initialValues['time'];
    } else {
      time = isAnyTimeType(type) ? RESERVER_TIME_TYPE_ANYTIME : null;
    }

    return time;
  };

  const handleChangeType = (e: any) => {
    const type = e.target.value;
    // const isAnyTimeType = type == RESERVER_TIME_TYPE_ANYTIME;
    const time = getTime(type);

    // if (isThisType(type)) {
    //     time = is_append_to_default
    //         ? initialValues[name]['time']
    //         : initialValues['time'];
    // } else {
    //     time = isAnyTimeType ? RESERVER_TIME_TYPE_ANYTIME : null;
    // }

    form.setFieldsValue({
      time: time,
    });

    if (isAnyTimeType(type)) {
      // handlePatchUpdate();
    }
  };

  const momentTime = (time?: string) =>
    time ? moment(time, TIME_FORMATE) : null;

  useEffect(() => {
    if (is_append_to_default) {
      const values = get(record, FIELD, {});

      if (values && values[name]) {
        form.setFieldsValue(values[name]);
      }
    } else {
      const values = get(record, name, {});

      if (values) {
        form.setFieldsValue(values);
      }
    }
  }, [record, name, is_append_to_default]);

  return (
    <Space>
      {disabled ? (
        <TimeText time={values} />
      ) : (
        <Popover
          trigger={trigger}
          placement={placement}
          content={
            <Spin spinning={loading}>
              <Form
                layout="vertical"
                form={form}
                initialValues={{ type: 'time', value: momentTime() }}
              >
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, setFieldsValue }) => (
                    <Form.Item
                      name="type"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Radio.Group onChange={handleChangeType}>
                        {Object.keys(RESERVER_TIME_TYPE_MAP).map(
                          (key, index) => (
                            <Radio key={+index} value={key}>
                              {
                                RESERVER_TIME_TYPE_MAP[
                                  (key as unknown) as keyof typeof RESERVER_TIME_TYPE_MAP
                                ]
                              }
                            </Radio>
                          ),
                        )}
                      </Radio.Group>
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item name="time" required noStyle></Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, setFieldValue }) => {
                    if (getFieldValue('type') == RESERVER_TIME_TYPE_ANYTIME) {
                      return <></>;
                    }
                    return (
                      <>
                        <Form.Item
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          {getFieldValue('type') == RESERVER_TIME_TYPE_TIME && (
                            <>
                              <TimePicker
                                allowClear
                                format={TIME_FORMATE}
                                showNow={true}
                                style={{ width: '100%' }}
                                value={
                                  Array.isArray(getFieldValue('time'))
                                    ? momentTime()
                                    : momentTime(getFieldValue('time'))
                                }
                                onChange={(value: any) => {
                                  if (!value) {
                                    setFieldValue('time', null);
                                    return;
                                  }

                                  setFieldValue(
                                    'time',
                                    moment(value).format(TIME_FORMATE),
                                  );
                                  // handlePatchUpdate();
                                }}
                              />
                            </>
                          )}
                          {getFieldValue('type') ==
                            RESERVER_TIME_TYPE_RANGE && (
                            <>
                              <TimePicker.RangePicker
                                allowClear
                                value={
                                  getFieldValue('time')
                                    ? [
                                        momentTime(getFieldValue('time')[0]),
                                        momentTime(getFieldValue('time')[1]),
                                      ]
                                    : [momentTime(), momentTime()]
                                }
                                onChange={(value: any) => {
                                  if (!value) {
                                    setFieldValue('time', null);
                                    return;
                                  }

                                  setFieldValue('time', [
                                    moment(value[0]).format(TIME_FORMATE),
                                    moment(value[1]).format(TIME_FORMATE),
                                  ]);
                                  // handlePatchUpdate();
                                }}
                                format={TIME_FORMATE}
                              />
                            </>
                          )}
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {/* {({ getFieldValue, setFieldValue }) => ( */}
                  <div className="flex justify-end">
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={handlePatchUpdate}
                    >
                      {' '}
                      {saveText}
                    </Button>
                  </div>
                  {/* )} */}
                </Form.Item>
              </Form>
            </Spin>
          }
        >
          <Typography.Link>
            {is_show_time_detail && <TimeDetail time={values} />}
            {!is_show_time_detail && (
              <DefaultIcon className={values ? '' : 'text-gray'} />
            )}
          </Typography.Link>
        </Popover>
      )}
    </Space>
  );
};
