import { Button, Form, message, Modal } from 'antd';

import { useState } from 'react';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';

export const InAccurateReportModal: React.FC<{
  onSave: (a: any) => void;
}> = ({ onSave }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    onSave(values);
  };

  return (
    <>
      <Button className="text-gray" onClick={showModal}>
        Inaccurate
      </Button>
      <Modal
        cancelText="Close"
        footer={
          <>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </>
        }
        title="Reason"
        open={isModalVisible}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label=""
            name="reason"
            rules={[{ required: false, message: 'Reason is required' }]}
          >
            <TextArea rows={6} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
