import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { ExportAudit } from './interface/data';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Tag,
  Modal,
  DatePicker,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import usePagination from '@/components/usePagination';
import AutoResizeTable from '@/components/AutoResizeTable';
import { TCollection } from '@/types';
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { showErrorMessage } from '@/utils/show-error-message';

const { RangePicker } = DatePicker;

const ExportAudits: React.FC = () => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState<any>(null);

  const [data, setData] = useState<TCollection<ExportAudit>>({
    data: [],
    meta: {
      total: 0,
      current_page: 1,
      per_page: 20,
      last_page: 1,
    },
  });
  const [filter] = useForm();

  const pagination = usePagination(data);

  const showModal = (title: string, content: any) => {
    setModalTitle(title);
    setModalContent(content);
    setModalVisible(true);
  };

  const fetchData = useCallback(
    async (pagination?: any) => {
      setLoading(true);
      try {
        const values = filter.getFieldsValue();
        const params: any = {
          ...values,
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 20,
        };

        // 处理日期范围
        if (values.created_at) {
          params.created_at_start = dayjs(values.created_at[0]).format(
            'YYYY-MM-DD',
          );
          params.created_at_end = dayjs(values.created_at[1]).format(
            'YYYY-MM-DD',
          );
          delete params.created_at;
        }

        const result = await app.service.get('export-audits', {
          params,
        });
        setData({
          data: result.data || [],
          meta: {
            total: result.meta?.total || 0,
            current_page: result.meta?.current_page || 1,
            per_page: result.meta?.per_page || 20,
            last_page: result.meta?.last_page || 1,
          },
        });
      } catch (e: any) {
        showErrorMessage(e);
        setData({
          data: [],
          meta: {
            total: 0,
            current_page: 1,
            per_page: 20,
            last_page: 1,
          },
        });
      }
      setLoading(false);
    },
    [filter],
  );

  const handleClearAll = async () => {
    filter.resetFields();
    await fetchData();
  };

  const handleDownload = async (id: number) => {
    try {
      const file = await app.service.get(`export-audits/download/${id}`, {
        responseType: 'blob',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } catch (e: any) {
      showErrorMessage(e, { fallback: 'Download failed' });
    }
  };

  const columns: ColumnsType<ExportAudit> = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
      },
      {
        title: 'Operator',
        dataIndex: 'admin_id',
        key: 'admin_id',
        width: 200,
        editable: false,
        render: (text: any, record: any) => (
          <>{record.admin_user && record.admin_user.name}</>
        ),
      },
      {
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
        width: 250,
        ellipsis: true,
      },
      {
        title: 'Method',
        dataIndex: 'request_method',
        key: 'request_method',
        width: 100,
        render: (text) => (
          <Tag color={text === 'GET' ? 'green' : 'blue'}>{text}</Tag>
        ),
      },
      {
        title: 'Parameters',
        dataIndex: 'request_params',
        key: 'request_params',
        width: 200,
        render: (text) => (
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => showModal('Request Parameters', text)}
          >
            View Parameters
          </Button>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'response_status_code',
        key: 'response_status_code',
        width: 100,
        render: (code) => (
          <Tag color={code >= 200 && code < 300 ? 'success' : 'error'}>
            {code}
          </Tag>
        ),
      },
      {
        title: 'Response Time(s)',
        dataIndex: 'response_time',
        key: 'response_time',
        width: 120,
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 180,
        render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: 'Action',
        key: 'action',
        width: 100,
        render: (_, record) =>
          record.response_status_code == 200 && (
            <Button
              type="link"
              icon={<DownloadOutlined />}
              onClick={() => handleDownload(record.id)}
            >
              Download
            </Button>
          ),
      },
    ],
    [],
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="Export Audits"></Header>
      <div
        style={{
          width: '80%',
          padding: '10px',
        }}
      >
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item name="url" label="URL">
                <Input placeholder="Enter URL" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="admin_id" label="Operator">
                <UserSyncSelect
                  type="admin"
                  placeholder="Select Operator"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="created_at" label="Created At">
                <RangePicker
                  format="YYYY-MM-DD"
                  placeholder={['Start Date', 'End Date']}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={' '}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button onClick={handleClearAll}>Clear All</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data.data}
        sticky
        scroll={{
          x: 1200,
        }}
      />

      <Modal
        title={modalTitle}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        <pre
          style={{
            maxHeight: '600px',
            overflow: 'auto',
            backgroundColor: '#f5f5f5',
            padding: '16px',
            borderRadius: '4px',
            fontSize: '14px',
          }}
        >
          {JSON.stringify(modalContent, null, 2)}
        </pre>
      </Modal>
    </div>
  );
};

export default ExportAudits;
