import React from 'react';
import { Tooltip, Space, message, Modal, Button, Form, Input } from 'antd';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import { TUser } from '@/types';
import { showErrorMessage } from '@/utils/show-error-message';

export const UserMemoModal: React.FC<{
  open: boolean;
  user: TUser;
  onSaved: () => void;
  onClose: () => void;
}> = ({ open, user, onSaved, onClose }) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);

  const [form] = useForm();

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);

    try {
      await app.service.patch(`users/${user.id}/updateMemo`, { data: values });
      message.success('Saved');
      onSaved();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };
  return (
    <Modal
      cancelText="Close"
      footer={
        <>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      }
      title="Memo"
      open={open}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          memo: user.memo,
        }}
      >
        <Form.Item name="memo" label="Memo">
          <Input.TextArea
            value={form.getFieldValue('memo')}
            rows={4}
            placeholder="Enter memo"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
