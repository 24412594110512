import { useApp } from '@/utils/useapp';
import { UserSyncSelect } from './UserSyncSelect';

import { Select } from 'antd';

const AccessControlledAdminSelect = ({ ...props }: { [key: string]: any }) => {
  const app = useApp();
  const auth = app.store.auth;

  return (
    <>
      {auth.hasProfitReportAllAccess ? (
        <UserSyncSelect type="admin" allowClear {...props} />
      ) : (
        <Select allowClear {...props}>
          <Select.Option value={auth.user?.id}>{auth.user?.name}</Select.Option>
        </Select>
      )}
    </>
  );
};

export default AccessControlledAdminSelect;
