import classnames from 'classnames';
import Decimal from 'decimal.js';

const RenderTransactionAmount: React.FC<{ amount: number }> = ({ amount }) => {
  if (!amount) {
    return <>{'-'}</>;
  }

  if (amount == 0) {
    return <>{amount}</>;
  }

  return (
    <div
      className={classnames(
        'ml-sm',
        amount > 0 ? 'text-success' : 'text-danger',
      )}
    >
      {amount > 0 ? '+' : '-'}
      {Math.abs(amount)?.toFixed(2)}
    </div>
  );
};

export default RenderTransactionAmount;
