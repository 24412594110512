import React, { useState, useCallback, useMemo } from 'react';
import moment from 'moment';

import {
  Form,
  Select,
  message,
  Switch,
  Drawer,
  Space,
  Button,
  Input,
  Tag,
  DatePicker,
} from 'antd';

import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';

import { BulkUpdateFormItem } from '@/components/BulkUpdateFormItem';
import {
  ORDER_STATES,
  ORDER_STATE_CANCELED,
  ORDER_SALES_TYPE_MAP,
} from '@/pages/orders/components/data';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { ConsigneeSyncSelect } from '@/components/ConsigneeSyncSelect';
import {
  DISPATCH_STATUS_OPTIONS,
  DISPATCH_ACTION_V2_OPTIONS,
  CUSTOMER_STATUS_OPTIONS,
  OCEAN_PORT,
  RAMP_PORT,
} from './data';
import { EditableText, EditableSelect } from '@/components/Editable';
import { IntermodalRegionOceanSelect } from '@/components/IntermodalRegionOceanSelect';
import { TerminalSelect } from '@/components/TerminalSelect';
import { WarehouseSelect } from '@/components/WarehouseSelect';
import { chain, uniq } from 'lodash';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import TextArea from 'antd/lib/input/TextArea';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  containers: any;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}
const CONTAINER_KEY = 'CONTAINER_';
const ORDER_KEY = 'ORDER_';

export const DrawerFormBulkUpdate: React.FC<Props> = ({
  containers = [],
  open = false,
  onClose,
  onSubmit,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [names, setNames] = useState<Array<string>>([]);
  const orderIds = useMemo(() => uniq(containers.map((c: any) => c.order_id)), [
    containers,
  ]);

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields(names);
    } catch {
      return;
    }

    const containerValues: any = chain(
      names.filter((n) => -1 !== n.indexOf(CONTAINER_KEY)),
    )
      .reduce(
        (result, n) => ({
          ...result,
          [n.replace(CONTAINER_KEY, '')]: values[n],
        }),
        {},
      )
      .value();

    const orderValues: any = chain(
      names.filter((n) => -1 !== n.indexOf(ORDER_KEY)),
    )
      .reduce(
        (result, n) => ({
          ...result,
          [n.replace(ORDER_KEY, '')]: values[n],
        }),
        {},
      )
      .value();

    if (containerValues.terminal) {
      containerValues.terminal_id = containerValues.terminal.id;
      delete containerValues.terminal;
    }

    if (containerValues.warehouse) {
      containerValues.warehouse_id = containerValues.warehouse.id;
      delete containerValues.warehouse;
    }

    if (orderValues.consignee) {
      orderValues.consignee_id = values.consignee.id;
      delete values.consignee;
    }

    setLoading(true);

    try {
      await app.service.put('dispatch/bulk', {
        data: {
          orders:
            Object.keys(orderValues).length > 0
              ? orderIds.map((id: any) => ({
                  id,
                  ...orderValues,
                }))
              : [],
          containers:
            Object.keys(containerValues).length > 0
              ? containers.map((c: any) => ({
                  id: c.id,
                  ...containerValues,
                }))
              : [],
        },
      });
      message.success('Saved');
      form.resetFields();
      setNames([]);
      onSubmit();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    form.resetFields();
    setNames([]);
    onClose();
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const handleListenNamesRequired = (name: string, required: boolean) => {
    const _names = Object.assign([], names);
    if (_names.find((n) => n == name)) {
      !required && setNames(_names.filter((n) => n != name));
    } else {
      _names.push(name);
      required && setNames(_names);
    }
  };

  return (
    <>
      <Drawer
        title={'Bulk Update'}
        placement={'right'}
        width={'50%'}
        onClose={handleClose}
        destroyOnClose={true}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        Selected:
        <div style={{ marginBottom: '10px' }}>
          {containers?.map((c: any) => (
            <Tag key={c.order.uid + '-' + c.number} color="processing">
              {c.order.uid} <br />
              {c.number}
            </Tag>
          ))}
        </div>
        <Form layout="vertical" form={form} onFinish={handleSave}>
          <Form.Item name="isSync" noStyle>
            <Input hidden></Input>
          </Form.Item>
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}dispatch_status`}
            label="Dispatch Status"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Select
                disabled={
                  names?.includes(`${CONTAINER_KEY}dispatch_status`)
                    ? false
                    : true
                }
                onChange={(val) => {
                  handleChange({
                    target: {
                      name: `${CONTAINER_KEY}dispatch_status`,
                      value: val,
                    },
                  });
                }}
              >
                {DISPATCH_ACTION_V2_OPTIONS.map((o) => (
                  <Select.Option key={o.value} value={o.value}>
                    {o.label}
                  </Select.Option>
                ))}
              </Select>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}customer_status`}
            label="Customer Status"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Select
                disabled={
                  names?.includes(`${CONTAINER_KEY}customer_status`)
                    ? false
                    : true
                }
                onChange={(val) => {
                  handleChange({
                    target: {
                      name: `${CONTAINER_KEY}customer_status`,
                      value: val,
                    },
                  });
                }}
              >
                {CUSTOMER_STATUS_OPTIONS.map((o) => (
                  <Select.Option key={o.value} value={o.value}>
                    {o.label}
                  </Select.Option>
                ))}
              </Select>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${ORDER_KEY}sales_type_id`}
            label="Sales Type"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Select
                disabled={
                  names?.includes(`${ORDER_KEY}sales_type_id`) ? false : true
                }
                onChange={(val) =>
                  handleChange({
                    target: {
                      name: `${ORDER_KEY}sales_type_id`,
                      value: val,
                    },
                  })
                }
              >
                {Object.keys(ORDER_SALES_TYPE_MAP).map((key) => (
                  <Select.Option key={key} value={key}>
                    {
                      ORDER_SALES_TYPE_MAP[
                        (key as unknown) as keyof typeof ORDER_SALES_TYPE_MAP
                      ]
                    }
                  </Select.Option>
                ))}
              </Select>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${ORDER_KEY}operator_id`}
            label="Operator"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <UserSyncSelect
                disabled={
                  names?.includes(`${ORDER_KEY}operator_id`) ? false : true
                }
                type="admin"
              />
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${ORDER_KEY}operator_assistant_id`}
            label="OP Assiant"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <UserSyncSelect
                disabled={
                  names?.includes(`${ORDER_KEY}operator_assistant_id`)
                    ? false
                    : true
                }
                type="admin"
              />
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${ORDER_KEY}sales_id`}
            label="Sales"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <UserSyncSelect
                disabled={
                  names?.includes(`${ORDER_KEY}sales_id`) ? false : true
                }
                type="admin"
              />
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${ORDER_KEY}sales_support_id`}
            label="Sales Support"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <UserSyncSelect
                disabled={
                  names?.includes(`${ORDER_KEY}sales_support_id`) ? false : true
                }
                type="admin"
              />
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${ORDER_KEY}user_id`}
            label="Customer"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <UserSyncSelect
                disabled={names?.includes(`${ORDER_KEY}user_id`) ? false : true}
              />
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${ORDER_KEY}consignee_id`}
            label="Consignee"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <ConsigneeSyncSelect
                disabled={
                  names?.includes(`${ORDER_KEY}consignee_id`) ? false : true
                }
              />
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${ORDER_KEY}customer_reference_number`}
            label="REF#"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <EditableText
                disabled={
                  names?.includes(`${ORDER_KEY}customer_reference_number`)
                    ? false
                    : true
                }
                name={`${ORDER_KEY}customer_reference_number`}
                value={''}
                onChange={(name: string, value: any) => {
                  handleChange({
                    target: {
                      name,
                      value: value,
                    },
                  });
                }}
              />
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}port_of_discharge_id`}
            label="POD"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <IntermodalRegionOceanSelect
                className="w100"
                disabled={
                  names?.includes(`${CONTAINER_KEY}port_of_discharge_id`)
                    ? false
                    : true
                }
                onChange={(id) => {
                  handleChange({
                    target: {
                      name: `${CONTAINER_KEY}port_of_discharge_id`,
                      value: id,
                    },
                  });
                }}
              />
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}final_port_id`}
            label="IR"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <IntermodalRegionSelect
                className="w100"
                disabled={
                  names?.includes(`${CONTAINER_KEY}final_port_id`)
                    ? false
                    : true
                }
                onChange={(id) => {
                  handleChange({
                    target: {
                      name: `${CONTAINER_KEY}final_port_id`,
                      value: id,
                    },
                  });
                }}
              />
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}terminal`}
            label="Terminal"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <TerminalSelect
                    selected={getFieldValue(`${CONTAINER_KEY}terminal`)}
                    disabled={
                      names?.includes(`${CONTAINER_KEY}terminal`) ? false : true
                    }
                    redirectable={false}
                    onSelect={(terminal: any) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}terminal`, //TODO terminal
                          value: terminal,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${ORDER_KEY}destination_type`}
            label="Destination Type"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Select
                disabled={
                  names?.includes(`${ORDER_KEY}destination_type`) ? false : true
                }
                onChange={(val) =>
                  handleChange({
                    target: {
                      name: `${ORDER_KEY}destination_type`,
                      value: val,
                    },
                  })
                }
              >
                <Select.Option value={OCEAN_PORT}>Ocean Port</Select.Option>
                <Select.Option value={RAMP_PORT}>Ramp Port</Select.Option>
              </Select>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}port_of_discharge_eta`}
            label="POD ETA"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}port_of_discharge_eta`)
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}port_of_discharge_eta`)
                        ? moment(
                            getFieldValue(
                              `${CONTAINER_KEY}port_of_discharge_eta`,
                            ),
                          )
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}port_of_discharge_eta`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}final_port_eta`}
            label="IR ETA"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}final_port_eta`)
                        ? false
                        : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}final_port_eta`)
                        ? moment(
                            getFieldValue(`${CONTAINER_KEY}final_port_eta`),
                          )
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}final_port_eta`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}lfd`}
            label="LFD"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <DatePicker
                    disabled={
                      names?.includes(`${CONTAINER_KEY}lfd`) ? false : true
                    }
                    style={{ width: '100%' }}
                    value={
                      getFieldValue(`${CONTAINER_KEY}lfd`)
                        ? moment(getFieldValue(`${CONTAINER_KEY}lfd`))
                        : null
                    }
                    onChange={(date, dateString) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}lfd`,
                          value: dateString,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}warehouse`}
            label="WHS"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <WarehouseSelect
                    disabled={
                      names?.includes(`${CONTAINER_KEY}warehouse`)
                        ? false
                        : true
                    }
                    selected={getFieldValue(`${CONTAINER_KEY}warehouse`)}
                    onSelect={(warehouse: any) => {
                      handleChange({
                        target: {
                          name: `${CONTAINER_KEY}warehouse`,
                          value: warehouse,
                        },
                      });
                    }}
                  />
                )}
              </Form.Item>
            }
          />
          {/* <BulkUpdateFormItem
            form={form}
            name="consignee_id"
            label="Who to SKD"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <ConsigneeSyncSelect
                disabled={names?.includes('consignee_id') ? false : true}
              />
            }
          /> */}
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}live_or_drop`}
            label="Live or Drop"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Select
                disabled={
                  names?.includes(`${CONTAINER_KEY}live_or_drop`) ? false : true
                }
                onChange={(val) =>
                  handleChange({
                    target: {
                      name: `${CONTAINER_KEY}live_or_drop`,
                      value: val,
                    },
                  })
                }
              >
                <Select.Option value={0}> </Select.Option>
                <Select.Option value={1}>Live</Select.Option>
                <Select.Option value={2}>Drop</Select.Option>
              </Select>
            }
          />
          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}internal_memo`}
            label="Memo"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <TextArea
                rows={3}
                disabled={
                  names?.includes(`${CONTAINER_KEY}internal_memo`)
                    ? false
                    : true
                }
                onChange={(val) => handleChange(val)}
              ></TextArea>
            }
          />
        </Form>
      </Drawer>
    </>
  );
};
