import React, { useState } from 'react';
import {
  Form,
  Select,
  message,
  Switch,
  Drawer,
  Space,
  Button,
  Input,
  Checkbox,
  Dropdown,
  Modal,
} from 'antd';

import { useApp } from '@/utils/useapp';
import { AntDDatePicker } from 'antd-datepicker';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  ids: any;
  onDone: () => void;
}

enum Action {
  ACTION_SALES_CONFIRM = 'SALES_CONFIRM',
  ACTION_CANCEL_SALES_CONFIRM = 'CANCEL_SALES_CONFIRM',
  ACTION_CUSTOMER_CONFIRM = 'CUSTOMER_CONFIRM',
  ACTION_CANCEL_CUSTOMER_CONFIRM = 'CANCEL_CUSTOMER_CONFIRM',
}

const ActionCustomerConfirm = ({ onOk }) => {
  const [value, setValue] = useState<any>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (!value) {
      return message.error('Please enter date');
    }

    onOk(value ? moment(value).format('YYYY-MM-DD') : null);
    handleClose();
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setValue(null);
  };

  return (
    <>
      <a onClick={showModal}>Customer Confirm</a>
      {isModalOpen && (
        <Modal
          title="Customer Confirm"
          open={isModalOpen}
          onOk={handleOk}
          cancelText="Close"
          onCancel={handleClose}
        >
          <AntDDatePicker
            value={value}
            onChange={(value: any) => setValue(value)}
          />
        </Modal>
      )}
    </>
  );
};

const ActionCancelCustomerConfirm = ({ onOk }) => {
  const [value, setValue] = useState<any>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (!value) {
      return message.error('Please enter reason');
    }

    onOk(value);
    handleClose();
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setValue(null);
  };

  return (
    <>
      <a onClick={showModal}>Cancel Customer Confirm</a>
      {isModalOpen && (
        <Modal
          title="Customer Confirm"
          open={isModalOpen}
          onOk={handleOk}
          cancelText="Close"
          onCancel={handleClose}
        >
          <TextArea
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
          />
        </Modal>
      )}
    </>
  );
};

export const BulkUpdate: React.FC<Props> = ({ ids = [], onDone }) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);

  const handleSave = async (action: Action, values: any = {}) => {
    setLoading(true);

    try {
      await app.service.put('orderInvoices/bulkUpdate', {
        data: {
          ids: ids.map((id: any) => id.id),
          action,
          values,
        },
      });
      message.success('Saved');
      onDone();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const items = React.useMemo(() => {
    return [
      {
        key: Action.ACTION_SALES_CONFIRM,
        label: (
          <a onClick={() => handleSave(Action.ACTION_SALES_CONFIRM)}>
            Sales Confirm
          </a>
        ),
      },
      {
        key: Action.ACTION_CANCEL_SALES_CONFIRM,
        label: (
          <a onClick={() => handleSave(Action.ACTION_CANCEL_SALES_CONFIRM)}>
            Cancel Sales Confirm
          </a>
        ),
      },
      {
        key: Action.ACTION_CUSTOMER_CONFIRM,
        label: (
          <ActionCustomerConfirm
            onOk={(value) =>
              handleSave(Action.ACTION_CUSTOMER_CONFIRM, { value })
            }
          />
        ),
      },
      {
        key: Action.ACTION_CANCEL_CUSTOMER_CONFIRM,
        label: (
          <ActionCancelCustomerConfirm
            onOk={(value) =>
              handleSave(Action.ACTION_CANCEL_CUSTOMER_CONFIRM, { value })
            }
          />
        ),
      },
    ];
  }, [ids]);

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button type="primary" loading={loading}>
          Bulk Update
        </Button>
      </Dropdown>
    </>
  );
};
