import React, { useEffect, useMemo } from 'react';
import { Button, message, Rate, Space, Tabs, Tooltip } from 'antd';

import { ColumnsType } from 'antd/lib/table';
import AutoResizeTable from '@/components/AutoResizeTable';
import { useApp } from '@/utils/useapp';
import QuestionnarieDrawer from '@/pages/questionnaires/components/QuestionnarieDrawer';
import { TContainer } from '@/types';
import { meanBy, truncate } from 'lodash';
import moment from 'moment';
import { RatingTag } from '@/components/RatingTag';
import Table from 'antd/es/table';
import { showErrorMessage } from '@/utils/show-error-message';

const STATUS_MAP = ['Closed', 'Open'];

const AvgRate: React.FC<{
  response: any[];
  id: number;
}> = ({ response = [], id }) => {
  const v = meanBy(
    response.filter((resp) => resp.status == 0),
    `responses.${id}`,
  );
  return <Rate disabled value={v} allowHalf />;
};

const VendorRating: React.FC<{
  vendor_id: number;
  score?: number;
  container?: TContainer | null;
  refreshContainer?: () => void;
}> = ({ vendor_id, refreshContainer, container, score = 'N/A' }) => {
  const [data, setData] = React.useState<any[]>([]);
  const [isAll, setIsAll] = React.useState<boolean>(false);
  const [questionnarieId, setQuestionnarieId] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [questionnaires, setQuestionnaires] = React.useState<any[]>([]);
  const [openResponseId, setOpenResponseId] = React.useState<number>(0);
  const [month, setMonth] = React.useState<number>(0);
  const app = useApp();

  const fetchData = async () => {
    setLoading(true);
    try {
      if (!vendor_id) {
        return;
      }
      const resp = await app.service.get('vendorRatingsV2', {
        params: {
          vendor_id,
        },
      });
      setData(resp.data);
      setLoading(false);
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      // console.log('test');
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      openResponseId
        ? await app.service.put(`vendorRatingsV2/${openResponseId}`, {
            data: {
              responses: values,
            },
          })
        : await app.service.post('vendorRatingsV2', {
            data: {
              questionnaire_id: questionnarieId,
              model_id: vendor_id,
              responses: values,
              container_id: container?.id,
            },
          });
      setQuestionnarieId(0);
      setOpenResponseId(0);
      fetchData();
      refreshContainer && refreshContainer();
      message.success('Submitted');
    } catch (e: any) {
      setQuestionnarieId(0);
      setOpenResponseId(0);
      showErrorMessage(e);
    }
  };

  const fetachAllQuestionnaire = async () => {
    try {
      if (!vendor_id) {
        return;
      }
      const resp = await app.service.get('questionnaires');
      setQuestionnaires(resp.data);
      setLoading(false);
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      // console.log('test');
    }
  };

  React.useEffect(() => {
    fetchData();
    fetachAllQuestionnaire();
  }, []);

  const questions = useMemo(() => {
    return questionnaires[0]?.enabled_questions || [];
  }, [questionnaires]);

  const filteredData = useMemo(() => {
    const oneMonthAgo = moment().subtract(month, 'months');
    const filteredData = month
      ? data.filter((data) => {
          // Parse created_at string using Moment.js
          const createdAtDate = moment(data.created_at);
          // Check if created_at is within the past 1 month
          return createdAtDate.isSameOrAfter(oneMonthAgo, 'day');
        })
      : data;
    return filteredData;
  }, [data, month]);

  const defaultColumns: ColumnsType<any> = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, record) => {
        return STATUS_MAP[record.status] || '';
      },
    },
    {
      title: 'Score',
      key: 'score',
      dataIndex: 'score',
    },
    {
      title: 'Comment',
      key: 'comment',
      dataIndex: 'comment',
      render: (text, record) => {
        return record.responses.comment &&
          record.responses.comment.length > 10 ? (
          <Tooltip title={record.responses.comment}>
            {truncate(record.responses.comment || '', {
              length: 10,
            })}
          </Tooltip>
        ) : (
          record.responses.comment || ''
        );
      },
    },
    {
      title: 'CNTR#',
      key: 'container',
      render: (text, record) => {
        return record.container?.number;
      },
    },
    {
      title: 'Operator',
      key: 'operator',
      render: (text, record) => {
        return record.operator?.name || 'System';
      },
    },
    {
      title: 'Created At',
      key: 'created_at',
      dataIndex: 'created_at',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setQuestionnarieId(record.questionnaire_id);
              setOpenResponseId(record.id);
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  // const items: MenuProps['items'] = useMemo(() => {
  //   return questionnaires.map((q: any) => {
  //     return {
  //       key: q.id,
  //       label: (
  //         <Button type="link" onClick={() => setQuestionnarieId(q.id)}>
  //           {q.name}
  //         </Button>
  //       ),
  //     };
  //   });
  // }, [questionnaires]);

  const _data: any[] = useMemo(() => {
    return data;
    // if (isAll || !container) {
    //   return data;
    // } else {
    //   return data.filter((c: any) => c.container_id == container?.id);
    // }
  }, [data, isAll]);

  const handleClose = () => {
    setQuestionnarieId(0);
    setOpenResponseId(0);
  };

  const handleAutoRating = (questionId: number, questionnarieId: number) => {
    return app.service.get(`questionnaires/${questionnarieId}/autoRating`, {
      params: {
        questionId: questionId,
        containerId: container?.id,
      },
    });
  };

  return (
    <div
      style={{
        width: '850px',
      }}
    >
      <Space>
        <strong>
          Average Rating <RatingTag rating={+score || null} />
        </strong>
        {container && (
          <Button type="link" onClick={() => setQuestionnarieId(1)}>
            New Score
          </Button>
        )}
      </Space>
      <br />
      <Space direction="horizontal">
        <Tabs
          defaultActiveKey="0"
          onChange={(v) => setMonth(+v)}
          items={[
            {
              label: 'All',
              key: '0',
            },
            {
              label: 'Past 1 Month',
              key: '1',
            },
            {
              label: 'Past 3 Month',
              key: '3',
            },
            {
              label: 'Past 6 Month',
              key: '6',
            },
            {
              label: 'Past 12 Month',
              key: '12',
            },
          ]}
        />
      </Space>
      <br />
      <Space direction="vertical">
        {questions.map((q: any, index: number) => {
          return (
            <Space key={q.id}>
              <span style={{ width: '100px' }}>
                {index + 1} {q.title}
              </span>
              <AvgRate response={filteredData} id={q.id} />
            </Space>
          );
        })}
      </Space>
      <br />
      <strong>Rating History</strong>
      <Table
        scroll={{
          y: 500,
        }}
        loading={loading}
        size="small"
        // pagination={}
        columns={defaultColumns}
        dataSource={_data}
      />
      {!!questionnarieId && (
        <QuestionnarieDrawer
          questionnarieId={questionnarieId}
          onClose={handleClose}
          onSumbit={handleSubmit}
          openResponseId={openResponseId}
          autoRating={handleAutoRating}
        />
      )}
    </div>
  );
};

export default VendorRating;
