import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message, Alert } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get } from 'lodash';
import React, { useMemo, useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { SellRateForm } from './SellRateForm';
import { BuyRateDrawer } from '@/pages/rates/buyRates/components/buyRateDrawer';
import { TModifyProps } from '@/components/Rate/data';
import { showErrorMessage } from '@/utils/show-error-message';

export const SELLRATE_UID_PREFIX = 'S';

export type TFormProps = {
  customer_id?: number;
  base_rate?: number;
  cargo_type_id?: number;
  visible?: boolean;
  intermodal_region_id?: number;
  to_city?: number;
};

export const SellRateDrawer: React.FC<
  {
    // form: FormInstance;
    id: number;
    visible: boolean;
    copyRecord?: any;
    initialValues?: TFormProps;
    onClose: () => void;
    onSaved?: (response?: any) => void;
  } & TModifyProps
> = ({
  id,
  visible,
  disabled = false,
  banModifyFields = [],
  copyRecord,
  initialValues,
  onClose,
  onSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);

  const [showBuyRateDrawer, setShowBuyRateDrawer] = React.useState(false);
  const [buyRateId, setBuyRateId] = React.useState(0);

  const [form] = useForm();

  const sellRateId = useMemo(() => {
    if (!copyRecord) {
      return id;
    } else {
      return 0;
    }
  }, [id, copyRecord]);

  const fetchData = async () => {
    if (!id) {
      return;
    }

    try {
      const resp = await app.service.get(`sellRates/${id}`);
      form.setFieldsValue(resp.data);
    } catch (error: any) {
      showErrorMessage(error);
    }
    setLoading(false);
  };

  const fetchBuyRate = async () => {
    if (!buyRateId) {
      return;
    }

    try {
      const response = await app.service.get(`rates/${buyRateId}`);
      form.setFieldsValue(response.data);
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  const handleShowBuyRateDetail = (buyRate: any) => {
    setBuyRateId(buyRate.id);
    setShowBuyRateDrawer(true);
  };

  const handleOnCloseBuyRateDrawer = () => {
    setShowBuyRateDrawer(false);
  };

  const handleOnSavedBuyRateDrawer = async () => {
    const response = app.service.get(`rates/${buyRateId}`);

    response
      .then((res: any) => {
        const buyRates = form.getFieldValue('buy_rates');
        const newBuyRates = buyRates.map((buyRate: any) =>
          buyRate.id == buyRateId ? res.data : buyRate,
        );
        form.setFieldValue('buy_rates', newBuyRates);

        setShowBuyRateDrawer(false);
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    let data;
    try {
      data = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);
    const formValue = Object.assign({}, data);

    formValue.to_city_id = get(formValue, 'to_city.id', 0);
    const _id = formValue.id;

    delete formValue.vendor;
    delete formValue.to_city;

    const request = _id
      ? app.service.put(`sellRates/${_id}`, { data: formValue })
      : app.service.post('sellRates', { data: formValue });

    request
      .then((resp) => {
        message.success('Saved');
        form.setFieldsValue(resp.data);
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showAsPDF = async () => {
    if (!id) {
      return;
    }
    setLoading(true);
    try {
      await app.service.preview(`sellRates/${id}/pdf`);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const downloadAsPDF = async () => {
    if (!id) {
      return;
    }
    setLoading(true);
    try {
      await app.service.download(`sellRates/${id}/pdf`);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (sellRateId) {
      fetchData();
    } else {
      if (initialValues) {
        form.setFieldsValue({ ...initialValues });
      } else {
        form.setFieldsValue({
          cargo_type_id: 1,
          visible: true,
        });
      }
    }
  }, [sellRateId]);

  useEffect(() => form.setFieldsValue({ ...initialValues }), [initialValues]);

  useEffect(() => {
    copyRecord && form.setFieldsValue({ ...copyRecord, id: undefined });
  }, [copyRecord]);

  useEffect(() => {
    id && fetchBuyRate();
  }, [buyRateId]);

  return (
    <>
      <Drawer
        title={`Sell Rate ${sellRateId}`}
        placement={'right'}
        width={'80%'}
        onClose={handleClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
            {!!id && (
              <>
                <Button
                  type="primary"
                  loading={loading}
                  ghost
                  onClick={showAsPDF}
                >
                  View PDF
                </Button>
                <Button
                  type="primary"
                  loading={loading}
                  ghost
                  onClick={downloadAsPDF}
                >
                  Download
                </Button>
              </>
            )}
          </Space>
        }
      >
        <SellRateForm
          form={form}
          rateId={id}
          disabled={disabled}
          banModifyFields={banModifyFields}
          showBuyRateDetail={handleShowBuyRateDetail}
        />
      </Drawer>

      <BuyRateDrawer
        // form={buyRateForm}
        isNew={false}
        open={showBuyRateDrawer}
        id={buyRateId}
        onClose={handleOnCloseBuyRateDrawer}
        onSaved={handleOnSavedBuyRateDrawer}
      />
    </>
  );
};
