import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import { TCity, TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import moment from 'moment';
import AutoResizeTable from '@/components/AutoResizeTable';
import { get, join, truncate } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TCity>>();
  const [form] = useForm();
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('cities', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        showErrorMessage(e);
      }

      setLoading(false);
    },
    [],
  );

  const addNew = () => {
    form.resetFields();
    setVisible(true);
  };

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);

    try {
      !values.id
        ? await app.service.post('cities', { data: values })
        : await app.service.put(`cities/${values.id}`, { data: values });
      message.success('Saved');

      fetchData();
      setVisible(false);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleRemove = async (id: number) => {
    setLoading(true);

    try {
      await app.service.delete(`cities/${id}`);

      message.success('Deleted');

      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleEdit = async (city: TCity) => {
    setLoading(true);

    try {
      form.resetFields();
      setVisible(true);
      form.setFieldsValue(city);
      // const resp = await app.service.get(`cities/${city.id}`);
      // form.setFieldsValue(resp.data);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        render: (text, record, index) => <>{record.id}</>,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 250,
      },
      {
        title: 'Alias',
        dataIndex: 'alias',
        key: 'alias',
        width: 200,
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        width: 120,
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        width: 150,
      },
      {
        title: 'UNLOCODE',
        dataIndex: 'unlocode',
        key: 'unlocode',
        width: 120,
      },
      {
        title: 'Has Population',
        dataIndex: 'has_population',
        key: 'has_population',
        width: 120,
        render: (text) => (text ? <Tag color="primary">Yes</Tag> : <></>),
      },
      {
        title: 'Zipcodes',
        dataIndex: 'full_zipcode',
        key: 'full_zipcode',
        width: 200,
        render: (text, record) =>
          truncate(join(get(record, 'full_zipcode', []), ', '), {
            length: 100,
          }),
      },
      {
        title: 'Coordinate',
        dataIndex: 'coordinate',
        key: 'coordinate',
        width: 200,
        render: (_, record) => record.latitude + ',' + record.longitude,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            <Popconfirm
              placement="left"
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}
            >
              <a>Delete</a>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Cities"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New City
            </Button>
          </div>
        }
      ></Header>

      <div className={styles.filter}>
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <Row gutter={16}>
            <Col>
              <Form.Item name="query" label="Query">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="start_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="end_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Date" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const startDate = getFieldValue('start_date');
                  const endDate = getFieldValue('end_date');
                  return (
                    <DatePicker.RangePicker
                      value={
                        startDate && endDate
                          ? [moment(startDate), moment(endDate)]
                          : undefined
                      }
                      onChange={(value) =>
                        setFieldsValue({
                          start_date: value
                            ? value[0]?.format('YYYY-MM-DD')
                            : null,
                          end_date: value
                            ? value[1]?.format('YYYY-MM-DD')
                            : null,
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button onClick={() => filter.resetFields()}>
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title=""
        placement="right"
        width="40%"
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <DrawerForm form={form} onSubmit={handleSave} />
      </Drawer>
    </div>
  );
};

export default Index;
