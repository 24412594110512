import { UserSyncSelect } from '@/components/UserSyncSelect';
import { showErrorMessage } from '@/utils/show-error-message';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Card,
  Col,
  DatePicker,
  message,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { Row } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';

interface RankData {
  brokerRank: {
    broker: string;
    carrier: string;
    shipments: number;
  }[];
  customerRank: {
    customer: string;
    customer_company_name: string;
    customer_company_code: string;
    shipments: number;
    pallets: number;
    volume: number;
    weight: number;
  }[];
  salesRank: {
    sales: string;
    shipments: number;
    customers: string;
  }[];
  opRank: {
    operator: string;
    shipments: number;
  }[];
  salesSupportRank: {
    salesSupport: string;
    shipments: number;
  }[];
  arRank: {
    sales: string;
    shipments: number;
  }[];
}

interface RankProps {
  startDate: string;
  endDate: string;
}

const MAX_ROWS = 5;

const DataTable: FC<{
  title: string;
  data: any[] | undefined;
  columns: any[];
  loading: boolean;
}> = ({ title, data, columns, loading }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [defaultRows, setDefaultRows] = useState<Array<any>>([]);
  const [hideRows, setHideRows] = useState<Array<any>>([]);

  const changeTableData = () => {
    if (isShowMore) {
      setTableData([...defaultRows, ...hideRows]);
    } else {
      setTableData([...defaultRows]);
    }
  };

  const handleShowMoreOrLess = () => {
    changeTableData();
    setIsShowMore(!isShowMore);
  };

  useEffect(() => {
    setDefaultRows([...(data || []).slice(0, MAX_ROWS)]);

    setHideRows([...(data || []).slice(MAX_ROWS)]);

    setTableData(defaultRows);

    if (data && data.length > MAX_ROWS) {
      setIsShowMore(true);
    } else {
      setIsShowMore(false);
    }
  }, [data, title, columns]);

  return (
    <Card title={title}>
      <Table
        size="small"
        rowKey={(record) => record.name}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        loading={loading}
        scroll={{ y: '36vh' }}
      ></Table>
      {data && data.length > MAX_ROWS && (
        <div className="flex justify-center text-align mt-md">
          <Button onClick={handleShowMoreOrLess}>
            {isShowMore ? 'Show More' : 'Show Less'}
          </Button>
        </div>
      )}
    </Card>
  );
};

export const Rank: FC<RankProps> = ({ startDate, endDate }: RankProps) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [rankData, setRankData] = useState<RankData>();
  const [dateRange, setDateRange] = useState<[string, string]>(['', '']);
  const [customerId, setCustomerId] = useState<number>();
  const fetchRankData = async (startDate: string, endDate: string) => {
    setLoading(true);
    try {
      const resp = await app.service.get('tl/ltl/dashboard/rank', {
        params: {
          start_date: startDate,
          end_date: endDate,
          customer_id: customerId,
        },
      });
      setRankData(resp.data);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setDateRange([startDate, endDate]);
    fetchRankData(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Space>
          <UserSyncSelect
            style={{ width: 200 }}
            type="user"
            value={customerId}
            allowClear
            onSelect={(user: any) => setCustomerId(user?.id)}
          />
          <DatePicker.RangePicker
            value={[moment(dateRange[0]), moment(dateRange[1])]}
            onChange={(dates: any, dateStrings: [string, string]) => {
              if (dates) {
                setDateRange(dateStrings);
              }
            }}
          />
          <Button
            type="primary"
            onClick={() => fetchRankData(dateRange[0], dateRange[1])}
          >
            Search
          </Button>
        </Space>
      </Col>
      <Col span={8}>
        <DataTable
          title="Broker - Carrier top5"
          data={rankData?.brokerRank}
          loading={loading}
          columns={[
            { title: 'Broker', dataIndex: 'broker' },
            { title: 'Carrier', dataIndex: 'carrier' },
            { title: 'Shipments', dataIndex: 'shipments' },
          ]}
        />
      </Col>
      <Col span={8}>
        <DataTable
          title="Customer - Shipment top5"
          loading={loading}
          data={rankData?.customerRank}
          columns={[
            {
              title: 'Customer',
              dataIndex: 'customer',
              render: (text: string, record: any) => {
                return (
                  <Tooltip title={record.customer_company_name}>
                    {`${text}(${record.customer_company_code})`}
                  </Tooltip>
                );
              },
            },
            { title: 'Shipments', dataIndex: 'shipments' },
            { title: 'Pallet', dataIndex: 'pallets' },
            { title: 'Volume', dataIndex: 'volume' },
            { title: 'Weight', dataIndex: 'weight' },
          ]}
        />
      </Col>
      <Col span={8}>
        <DataTable
          title={'Sales - Shipment top5'}
          loading={loading}
          data={rankData?.salesRank}
          columns={[
            { title: 'Sales', dataIndex: 'sales' },
            { title: 'Shipments', dataIndex: 'shipments' },
            { title: 'Customer', dataIndex: 'customers' },
          ]}
        />
      </Col>
      <Col span={8}>
        <DataTable
          title={'OP top5'}
          loading={loading}
          data={rankData?.opRank}
          columns={[
            { title: 'Operator', dataIndex: 'operator' },
            { title: 'Shipments', dataIndex: 'shipments' },
          ]}
        />
      </Col>
      <Col span={8}>
        <DataTable
          title={'Sales Support top5'}
          loading={loading}
          data={rankData?.salesSupportRank}
          columns={[
            { title: 'Sales Support', dataIndex: 'salesSupport' },
            { title: 'Shipments', dataIndex: 'shipments' },
          ]}
        />
      </Col>
      <Col span={8}>
        <DataTable
          title={'AR Statistics'}
          loading={loading}
          data={rankData?.arRank}
          columns={[
            { title: 'Sales', dataIndex: 'sales' },
            { title: 'Shipments', dataIndex: 'shipments' },
          ]}
        />
      </Col>
    </Row>
  );
};
