import { UserSyncSelect } from '@/components/UserSyncSelect';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  FormProps,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import moment from 'moment';
import styles from 'res/css/ui.scss';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';
import { useWatch } from 'antd/lib/form/Form';
import { toStatusLabelOptions } from './helper';
import { EProfitType } from './constants';
import AccessControlledAdminSelect from '@/components/AccessControlledAdminSelect';

interface FilterProps extends FormProps {
  form: FormInstance<any>;
  onSearch: () => void;
  loading: boolean;
  onExport: () => void;
  type: EProfitType;
}

const Index = ({ type, form, onSearch, loading, onExport }: FilterProps) => {
  const by = useWatch('by', form);
  return (
    <Form
      form={form}
      layout="vertical"
      className={styles.filter}
      initialValues={{ by: 'admin' }}
    >
      <Row gutter={16} style={{ width: '100%' }}>
        <Col md={5}>
          <Form.Item name="by" label="&nbsp;">
            <Radio.Group
              options={[
                {
                  label: 'By Admin',
                  value: 'admin',
                },
                {
                  label: 'By Company',
                  value: 'company',
                },
                {
                  label: 'By Customer',
                  value: 'customer',
                },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Col>
        {by === 'admin' && (
          <>
            <Col md={2}>
              <Form.Item name="operator_id" label="Admin">
                <AccessControlledAdminSelect />
              </Form.Item>
            </Col>
            <Col md={2}>
              <Form.Item label="Agent" name="agent_id">
                <UserSyncSelect type="user" />
              </Form.Item>
            </Col>
          </>
        )}

        {by === 'customer' && (
          <Col md={4}>
            <Form.Item
              name="user_id"
              label="Customer"
              rules={[{ required: true, message: 'required' }]}
            >
              <UserSyncSelect />
            </Form.Item>
          </Col>
        )}
        {by === 'company' && (
          <Col md={4}>
            <Form.Item
              name="company_id"
              label="Company"
              rules={[{ required: true, message: 'required' }]}
            >
              <CompanySyncSelect />
            </Form.Item>
          </Col>
        )}
        <Col md={4}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => {
              const start = getFieldValue('created_at_start_date');
              const end = getFieldValue('created_at_end_date');
              return (
                <>
                  <Form.Item hidden name="created_at_start_date" />
                  <Form.Item hidden name="created_at_end_date" />
                  <Form.Item label="Time">
                    <DatePicker.RangePicker
                      value={[
                        start ? moment(start) : null,
                        end ? moment(end) : null,
                      ]}
                      style={{ width: '100%' }}
                      onChange={(date, dataStrings) => {
                        setFieldsValue({
                          created_at_start_date: dataStrings[0],
                          created_at_end_date: dataStrings[1],
                        });
                      }}
                    />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        </Col>
        <Col md={2}>
          <Form.Item name="status" label="Status">
            <Select allowClear mode="multiple">
              {toStatusLabelOptions(type).map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={2}>
          <Form.Item name="settled" label="Settled">
            <Select allowClear>
              <Select.Option value="">{''}</Select.Option>
              <Select.Option value={true}>{'yes'}</Select.Option>
              <Select.Option value={false}>{'no'}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col md={2}>
          <Form.Item name="exception" label="Exception">
            <Select allowClear>
              <Select.Option value="">{''}</Select.Option>
              <Select.Option value={true}>{'yes'}</Select.Option>
              <Select.Option value={false}>{'no'}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item label=" ">
            <Space>
              <Button type="primary" loading={loading} onClick={onSearch}>
                Search
              </Button>
              <Button onClick={() => form.resetFields()}>Clear</Button>
              <Button onClick={() => onExport()}>Export</Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Index;
