import { Button, DatePicker, Form, Modal } from 'antd';
import { FC, useState } from 'react';
import { PrepayOrderInterface } from '@/Interfaces/PrepayOrderInterface';
import TextArea from 'antd/lib/input/TextArea';
import { ExclamationCircleFilled } from '@ant-design/icons';
import moment from 'moment-timezone';
import { useForm } from 'antd/lib/form/Form';
// import UserBalance from '../../invoices/components/UserBalance';
import UserWallet from '../../invoices/components/UserWallet';

interface IPreChargeButtonProps {
  order: PrepayOrderInterface;
  onSubmit: (data: {
    notes: string;
    paymentReceivedDate: string | null;
  }) => Promise<void>;
}

const PreChargeButton: FC<IPreChargeButtonProps> = ({ order, onSubmit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = useForm();

  const showModal = () => {
    setIsModalOpen(true);
    form.resetFields();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      // console.log(values);
      await onSubmit({
        notes: values.notes,
        paymentReceivedDate: values.payment_received_at.format('YYYY-MM-DD'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button size="small" type="link" onClick={showModal}>
        Approve
      </Button>
      {isModalOpen && (
        <Modal
          title="Approve Payment"
          open={isModalOpen}
          onOk={handleSubmit}
          onCancel={() => setIsModalOpen(false)}
          className="ant-modal-confirm ant-modal-confirm-confirm"
          width={720}
          okText="OK"
          cancelText="Cancel"
          confirmLoading={loading}
        >
          <div className="ant-modal-confirm-body-wrapper">
            <div className="ant-modal-confirm-body">
              <ExclamationCircleFilled
                className="ant-modal-confirm-icon"
                style={{ color: '#faad14' }}
              />
              <div className="ant-modal-confirm-title">Approve Payment</div>
              <div className="ant-modal-confirm-content">
                <div>
                  User: <strong>{order.user_wallet.user.name}</strong>
                </div>
                <div>
                  <UserWallet userId={order.user_wallet.user.id} />
                </div>
                <div className="mt-md">
                  Prepaid Amount:{' '}
                  <strong>
                    {order.prepaid_amount} ({order.prepaid_currency})
                  </strong>
                </div>
                <div>
                  Adjustment Amount: <strong>{order.adjustment} (USD)</strong>
                </div>
                <div>
                  Credit Amount: <strong>{order.amount} (USD)</strong>
                </div>
                <Form
                  className="mt-md"
                  layout="vertical"
                  form={form}
                  initialValues={{
                    payment_received_at: moment().tz('America/Los_Angeles'),
                  }}
                >
                  <Form.Item
                    label="Payment Received Date (US Timezone)"
                    name="payment_received_at"
                    rules={[
                      {
                        required: true,
                        message: 'Please select payment received date',
                      },
                    ]}
                  >
                    <DatePicker allowClear={false} />
                  </Form.Item>
                  <div className="mt-sm text-success">
                    Please confirm the prepayment. After the prepayment, the
                    customer prepayment amount will increase
                  </div>
                  {order.bank.currency === 'USD' && (
                    <Form.Item name="notes">
                      <TextArea
                        rows={4}
                        placeholder="Please enter notes"
                        maxLength={300}
                        showCount={{
                          formatter: ({ count, maxLength }) =>
                            `${count}/${maxLength} characters`,
                        }}
                        className="mt-sm"
                      />
                    </Form.Item>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PreChargeButton;
