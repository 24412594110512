import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Space,
  Table,
  Tag,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import { DrawerForm } from './components/DawerForm';
import type { ColumnsType } from 'antd/lib/table';
import { TBankAccount, TCollection } from '@/types';
import { useForm } from 'antd/lib/form/Form';
import { get } from 'lodash';
import AutoResizeTable from '@/components/AutoResizeTable';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [title, setTitle] = React.useState('Create BankAccount');
  const [data, setData] = React.useState<TCollection<TBankAccount>>();
  const [form] = useForm();
  const [filter] = useForm();

  const fetchData = React.useCallback(async () => {
    setLoading(true);
    try {
      const result = await app.service.get('bankAccounts', {
        params: {
          ...filter.getFieldsValue(),
        },
      });
      setData(result);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  }, []);

  const addNew = () => {
    setTitle('Create BankAccount');
    form.resetFields();
    setVisible(true);
  };

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }
    setLoading(true);

    try {
      !values.id
        ? await app.service.post('bankAccounts', { data: values })
        : await app.service.put(`bankAccounts/${values.id}`, {
            data: values,
          });
      setVisible(false);
      message.success('Saved');
      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleEdit = async (bank_account: TBankAccount) => {
    setTitle('Edit BankAccount');
    setLoading(true);

    try {
      form.resetFields();
      setVisible(true);
      await form.setFieldsValue(bank_account);
      // console.log('form', form.getFieldsValue());
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };
  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 100,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: (text, record) => {
          const type = get(record, 'type', false);
          const type_name = get(record, 'type_name', '');
          if (!type) {
            return <></>;
          }
          switch (type) {
            case 1:
              return <Tag color="success">{type_name}</Tag>;
            case 3:
              return <Tag color="processing">{type_name}</Tag>;
            case 5:
              return <Tag color="cyan">{type_name}</Tag>;
            case 10:
              return <Tag color="default">{type_name}</Tag>;
            default:
              return <></>;
          }
        },
      },
      {
        title: 'GL Code',
        dataIndex: 'general_ledger_code',
        key: 'gl_code',
        width: 200,
        render: (text, record) => (
          <>
            {record.general_ledger_code && record.general_ledger_code.full_name}
          </>
        ),
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
        width: 100,
        render: (text, record) => {
          const { currency } = record;
          switch (currency) {
            case 'USD':
              return <Tag color="purple">{currency}</Tag>;
            case 'CNY':
              return <Tag color="magenta">{currency}</Tag>;
            default:
              return <Tag color="default">{currency}</Tag>;
          }
        },
      },
      {
        title: 'Balance',
        dataIndex: 'current_balance',
        key: 'current_balance',
        width: 200,
      },
      {
        title: 'Memo',
        dataIndex: 'memo',
        key: 'memo',
        width: 200,
      },
      {
        title: 'Is Prepaid Account',
        dataIndex: 'is_prepaid_account',
        key: 'is_prepaid_account',
        width: 200,
        render: (v) => {
          return v ? 'Yes' : 'No';
        },
      },
      {
        title: 'Last Time Balance Changed',
        dataIndex: 'last_time_balance_changed',
        key: 'last_time_balance_changed',
        width: 200,
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 100,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="BankAccount"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New
            </Button>
          </div>
        }
      ></Header>

      <div className={styles.filter}>
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item name="name" label="Name">
                <Input />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      filter.resetFields();
                      fetchData();
                    }}
                  >
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={false}
        size="small"
        rowKey={'id'}
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title={title}
        placement="right"
        width="60%"
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <DrawerForm form={form} onSubmit={handleSave} />
      </Drawer>
    </div>
  );
};

export default Index;
