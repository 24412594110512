import {
  Button,
  Form,
  List,
  Modal,
  Avatar,
  message,
  Space,
  Select,
} from 'antd';
import { CACHE_ADMIN_KEY } from '@/stores/cache';
import { useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { AlignLeftOutlined } from '@ant-design/icons';
import { useApp } from '@/utils/useapp';
import { observer } from 'mobx-react';
import { MentionsInput, Mention } from 'react-mentions';

import WithMentionHighlighter from '@/components/WithMentionHighlighter';
import { STATUS_MAP as STATUS_MAP_BILL } from '@/pages/accounting/bills/components/data';
import { STATUS_MAP as STATUS_MAP_INVOICE } from '@/pages/accounting/invoices/components/data';
import { showErrorMessage } from '@/utils/show-error-message';

export const DISPUTE_TYPE_BILL = 'orderBills';
export const DISPUTE_TYPE_INVOICE = 'orderInvoices';
export const DISPUTE_TYPE_VENDOR = 'vendors';
export const DISPUTE_TYPE_LTL_SHIPMENTS = 'tl/ltl/shipments';
export const DISPUTE_TYPE_FTL_SHIPMENTS = 'ftl/shipments';
export const DISPUTE_TYPE_CFS_SHIPMENTS = 'cfs/shipments';
export const DISPUTE_TYPE_LTL_CARRIERS = 'tl/ltl/carriers';
export const DISPUTE_TYPE_FTL_CARRIERS = 'ftl/carriers';
export const DISPUTE_TYPE_CFS_CARRIERS = 'cfs/carriers';

const style = {
  control: {
    backgroundColor: '#fff',
    fontSize: 16,
    // fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      // fontFamily: 'monospace',
      minHeight: 60,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

export const DisputeMemo: React.FC<{
  onSaved?: (data: any) => void;
  icon?: any;
  model: any;
  open?: boolean;
  type: string;
  withStatus?: boolean;
  title?: string;
}> = observer(
  ({
    onSaved,
    model,
    open = false,
    icon,
    type = 0,
    withStatus = true,
    title = 'Dispute Memo',
  }) => {
    const [isModalVisible, setIsModalVisible] = useState(open);
    const [comments, setComments] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const app = useApp();

    useEffect(() => {
      if (open) {
        setIsModalVisible(true);
        markAsRead();
      }
    }, [open]);
    // const [form] = useForm();
    // const location = useLocation();

    const markAsRead = useCallback(async () => {
      const ids = app.store.bufferCache.getUnreadCountByContainerId(
        model?.id || 0,
      )?.m_ids;
      if (ids?.length) {
        await app.service.post('notifications/markAsReadByIds', {
          data: { ids },
        });
        const resp = await app.service.get('notifications/unreadCount');
        app.store.notification.setUnreadCount(resp.data);
        app.store.bufferCache.markAsRead(model?.id || 0);
      }
    }, [app.store.bufferCache.data]);

    const showModal = async () => {
      setIsModalVisible(true);
      markAsRead();
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const handleSave = async () => {
      setLoading(true);
      try {
        const resp = await app.service.post(`${type}/${model.id}/newMemo`, {
          data: { comment },
        });
        onSaved && onSaved(model.id);
        setComment('');
        const data = resp.data;
        setComments([{ ...data, pin: 0 }, ...comments]);
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setLoading(false);
      }
    };

    const fetchMemo = async () => {
      setLoading(true);
      try {
        const resp = await app.service.get(`${type}/${model.id}/memos`);
        setComments(resp.data);
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setLoading(false);
      }
    };

    const statusMap = useMemo(() => {
      let data = {};
      if (type == DISPUTE_TYPE_BILL) {
        data = STATUS_MAP_BILL;
      } else if (type == DISPUTE_TYPE_INVOICE) {
        data = STATUS_MAP_INVOICE;
      }
      return data;
    }, [type]);

    // const pinConment = async (item: any, pin: boolean) => {
    //   setLoading(true);
    //   try {
    //     await app.service.put(`adminComments/${item.id}`, {
    //       data: appendMemoAttributeToData(
    //         {
    //           pin,
    //           container_id: container.id,
    //         },
    //         MEMO_ATTRIBUTE_CONTAINER_INTERNAL,
    //       ),
    //     });
    //     fetchMemo();
    //   } catch (err: any) {
    //     showErrorMessage(err);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    const _comments = useMemo(() => {
      // return sortBy(comments, ['pin', 'pin_at']).reverse();
      return comments;
    }, [comments]);

    useEffect(() => {
      if (isModalVisible && model) {
        fetchMemo();
      }
    }, [isModalVisible]);

    const rows = app.store.cache.data[CACHE_ADMIN_KEY];

    if (rows === undefined) {
      app.store.cache.fetch(CACHE_ADMIN_KEY);
    }

    const handleChangeStatus = async (value: string) => {
      if (model.dispatch_status == value) {
        return;
      }
      try {
        const resp = await app.service.put(`${type}/${model.id}/dispute`, {
          data: {
            status: value,
          },
        });
        setComments([{ ...resp.data, pin: 0 }, ...comments]);
        onSaved && onSaved(resp.data);
      } catch (err: any) {
        showErrorMessage(err);
      }
    };

    // const count = useMemo(() => {
    //   return app.store.bufferCache.getUnreadCountByContainerId(
    //     model?.id || 0,
    //   );
    // }, [app.store.bufferCache.data]);

    return (
      <>
        <div
          className="text-gray"
          style={{
            cursor: 'pointer',
          }}
          onClick={showModal}
        >
          {icon ? icon : <AlignLeftOutlined />}
        </div>
        {isModalVisible && (
          <Modal
            cancelText="Close"
            footer={false}
            title={title}
            width={1000}
            open={isModalVisible}
            onCancel={handleCancel}
          >
            {withStatus && (
              <Form.Item label="Status">
                <Select
                  style={{
                    width: '300px',
                  }}
                  placeholder="Change Status"
                  onChange={handleChangeStatus}
                >
                  {statusMap &&
                    Object.keys(statusMap).map((key) => (
                      <Select.Option key={key} value={key}>
                        {statusMap[(key as unknown) as keyof typeof statusMap]}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            <Form onFinish={handleSave}>
              <Form.Item label="Memo">
                <MentionsInput
                  defaultValue={''}
                  value={comment}
                  onChange={(e) => setComment(e.target.value || '')}
                  placeholder="Mention any admin by typing `@` followed by at least one char"
                  required
                  style={style}
                >
                  <Mention
                    displayTransform={(id, display) => `@${display}`}
                    trigger="@"
                    data={(rows || []).map((admin) => ({
                      id: admin.id,
                      display: admin.name,
                    }))}
                    style={{ backgroundColor: '#1dcae02e', color: '#024d65' }}
                  />
                </MentionsInput>
              </Form.Item>
              <Space>
                <Button disabled={loading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Space>
            </Form>
            <List
              loading={loading}
              className="demo-loadmore-list"
              itemLayout="horizontal"
            >
              {_comments.map((item) => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={
                      item.admin?.avatar_url ? (
                        <Avatar src={item.admin.avatar_url} />
                      ) : (
                        <Avatar>
                          {item.admin?.display_name.charAt(0) || 'S'}{' '}
                        </Avatar>
                      )
                    }
                    title={
                      <>
                        <span>{item.admin?.display_name || 'System'}</span> @
                        <span className="text-gray">{item.created_at} </span>
                      </>
                    }
                    description={
                      <WithMentionHighlighter
                        content={item.comment}
                        highlighterColor={'#000000'}
                      />
                    }
                  />
                </List.Item>
              ))}
            </List>
          </Modal>
        )}
      </>
    );
  },
);
