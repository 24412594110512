import { Space, Button, message, Table, Spin, Drawer, Divider } from 'antd';
import React, { useMemo, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { useApp } from '@/utils/useapp';
import { InvoiceReminds } from './InvoiceReminds';
import { uniq } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';

type TEstimate = {
  code: string;
  name: string;
  rate: number;
  qty: number;
  amount: number;
};
export const EstimatePreviewDrawer: React.FC<{
  disabled: boolean;
  open: boolean;
  containers: any;
  onClose: () => void;
  onImport: (data: any, reminds: any) => any;
}> = ({ disabled = false, open, containers, onClose, onImport }) => {
  const app = useApp();

  const charges = useMemo(() => {
    return containers.map((container: any) => ({
      containerNumber: container.number,
    }));
  }, [containers]);
  const [data, setData] = useState<TEstimate[]>([]);
  const [reminds, setReminds] = useState<any>({});
  const [selected, setSelected] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(730);

  React.useEffect(() => {
    const containerHeight = containers?.length * 20;
    if (containerHeight < 100) {
      setHeight(730 - containerHeight);
    } else {
      setHeight(730 - 100);
    }
  }, [containers]);

  const fetchEstimateData = async () => {
    setLoading(true);
    try {
      let _charges: any[] = [];
      const _reminds: any = {};

      for (const i in containers) {
        const response = await await app.service.get(
          `containers/${containers[i].id}/estimate`,
          {
            params: {},
          },
        );

        const rows: any = [];
        const containerReminds: any = response?.reminds || [];

        const initialID = Number(i) * 50;
        response?.data?.map((d: any, index: number) => {
          rows.push({
            containerNumber: containers[i].number,
            id: initialID + Number(index + 1),
            ...d,
          });

          d.remind && containerReminds.push(d.remind);
        });

        _reminds[containers[i].number] = uniq(containerReminds);
        _charges = _charges.concat(rows);
      }

      setData(_charges);
      setReminds(_reminds);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleImport = () => {
    if (selected?.length <= 0) {
      message.error('Please select data to import');
      return;
    }

    const selectedData: any = [];
    const selectedReminds: any = [];
    data
      ?.filter((d: any) => selected.includes(d.id))
      .map((d: any) => {
        const { id, ...rest } = d;
        selectedData.push({
          ...rest,
          locked: false,
          reason: '',
        });
        if (!selectedReminds[d.containerNumber] && reminds[d.containerNumber]) {
          selectedReminds[d.containerNumber] = reminds[d.containerNumber];
        }
      });

    onImport(selectedData, selectedReminds);
  };

  const columns: ColumnsType<TEstimate> = [
    {
      title: 'CNTR#',
      dataIndex: 'containerNumber',
      key: 'containerNumber',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  React.useEffect(() => {
    open && fetchEstimateData();
  }, [open]);

  return (
    <Drawer
      width={'80%'}
      title="Estimate AR"
      placement="right"
      onClose={onClose}
      extra={
        <Space>
          <Button onClick={onClose}>Close</Button>
          <Button disabled={disabled} type="primary" onClick={handleImport}>
            Import
          </Button>
        </Space>
      }
      open={open}
    >
      <Spin spinning={loading}>
        <div style={{ maxHeight: 100 }}>
          <InvoiceReminds
            containers={containers}
            charges={charges}
            reminds={reminds}
          />
        </div>

        <Divider></Divider>
        <Table
          size="small"
          pagination={false}
          rowKey="id"
          columns={columns}
          dataSource={data}
          rowSelection={{
            onSelect: (a, b, selectedRows) => {
              setSelected(selectedRows.map((r: any) => r.id));
            },
            onSelectAll: (a, selectedRows) => {
              setSelected(selectedRows.map((r: any) => r.id));
            },
          }}
          footer={() => {
            let total = 0;
            data.forEach(({ rate, qty }) => {
              total += parseFloat(rate || 0) * parseFloat(qty || 0);
            });

            return (
              <Space className="w100" direction="vertical" align="end">
                <h4>Total {total.toFixed(2)}</h4>
              </Space>
            );
          }}
          scroll={{ y: height }}
        />
      </Spin>
    </Drawer>
  );
};
