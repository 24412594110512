import { Col, message, Row, Card, Space } from 'antd';
import { saveAs } from 'file-saver';
import { useApp } from '@/utils/useapp';
import { showErrorMessage } from '@/utils/show-error-message';
export interface CustomerData {
  newCustomer: number;
  lostCustomer: number;
}

export const Customer = ({ newCustomer, lostCustomer }: CustomerData) => {
  const app = useApp();
  const handleExport = async () => {
    try {
      const file = await app.service.get(
        'tl/ltl/dashboard/export-changed-customers',
        {
          responseType: 'blob',
        },
      );
      const filename = `New Customers & Lost Customers in Last 30days - ${new Date().toDateString()}.xlsx`;
      saveAs(file, filename);
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  return (
    <Row gutter={8}>
      <Col span={12}>
        <Card
          size="small"
          title={
            <Space>
              <span style={{ fontWeight: 'bold' }}>New Customers</span>
              <a href="#" onClick={handleExport}>
                Download
              </a>
            </Space>
          }
          headStyle={{ fontWeight: 'bold' }}
          bodyStyle={{ paddingTop: '8px', paddingBottom: '0px' }}
        >
          <h2>Last 30 days: {newCustomer}</h2>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          size="small"
          title={
            <Space>
              <span style={{ fontWeight: 'bold' }}>Lost Customers</span>
              <a href="#" onClick={handleExport}>
                Download
              </a>
            </Space>
          }
          headStyle={{ fontWeight: 'bold' }}
          bodyStyle={{ paddingTop: 'px', paddingBottom: '0px' }}
        >
          <h2>Last 30 days: {lostCustomer}</h2>
        </Card>
      </Col>
    </Row>
  );
};
