import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  TablePaginationConfig,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { ChassisTypeDrawer } from './components/ChassisTypeDrawer';
import { useForm } from 'antd/lib/form/Form';
import { TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import moment from 'moment';
import AutoResizeTable from '@/components/AutoResizeTable';
import { get, join, truncate } from 'lodash';
import { CNTR_SIZE_MAP } from '@/components/PossibleCharges';
import { showErrorMessage } from '@/utils/show-error-message';

type TChassisType = {
  id: number;
  name: string;
  type: string;
  cntr_size: Array<number>;
};
const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TChassisType>>();
  const [chassisTypeId, setChassisTypeId] = React.useState(0);
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('chassis_types', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        showErrorMessage(e);
      }

      setLoading(false);
    },
    [],
  );

  const addNew = () => {
    setVisible(true);
    setChassisTypeId(0);
  };

  const handleSaved = async (data: any) => {
    fetchData();
    handleClose();
  };

  const handleClose = () => {
    setVisible(false);
    setChassisTypeId(0);
  };
  const handleRemove = async (id: number) => {
    setLoading(true);

    try {
      await app.service.delete(`chassis_types/${id}`);

      message.success('Deleted');

      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleEdit = async (record: any) => {
    setVisible(true);
    setChassisTypeId(record.id);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        render: (text, record, index) => <>{record.id}</>,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 200,
      },
      {
        title: 'CNTR Size',
        dataIndex: 'cntr_size',
        key: 'cntr_size',
        width: 200,
        render: (text, record) =>
          get(record, 'cntr_size', [])
            .map(
              (key: number) =>
                CNTR_SIZE_MAP[(key as unknown) as keyof typeof CNTR_SIZE_MAP],
            )
            .join(','),
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            <Popconfirm
              placement="left"
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}
            >
              <a>Delete</a>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Chassis Types"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New Chassis Type
            </Button>
          </div>
        }
      ></Header>

      <div className={styles.filter}>
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <Row gutter={16}>
            <Col>
              <Form.Item name="name" label="Name">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button onClick={() => filter.resetFields()}>
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <ChassisTypeDrawer
        visible={visible}
        id={chassisTypeId}
        onClose={handleClose}
        onSaved={handleSaved}
      />
    </div>
  );
};

export default Index;
