import { Button, InputNumber, message, Radio, Select, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  fetchItemFreightClass,
  freightClassRequiredFields,
  // imperialFreightClass,
  inToCm,
  kgToLb,
  m3ToCm3,
  m3ToFt3,
  m3ToIn3,
  MeasureType,
  packageingTypes,
  QuoteItem,
  UnitType,
} from '@/utils/freight';
import { useImmer } from 'use-immer';
import { useState } from 'react';
import { useApp } from '@/utils/useapp';
import { showErrorMessage } from '@/utils/show-error-message';

export interface QuickLoadProps {
  disabled?: boolean;
  handleQuickLoad: (item: QuoteItem) => void;
}

const QuickLoad = ({ disabled = false, handleQuickLoad }: QuickLoadProps) => {
  const app = useApp();

  const [unitType, setUnitType] = useState(UnitType.Imperial);

  const [data, updateData] = useImmer({
    volume: 0,
    volume_measure: MeasureType.Total,
    total_weight: 0,
    weight_measure: MeasureType.Total,
    package_type: 'Pallet',
    length: 48,
    width: 40,
    units: 1,
  });
  const onQuickLoad = () => {
    const volumPerPallet =
      data.volume_measure === MeasureType.Total
        ? data.volume / data.units
        : (data.volume / data.units) * 1.3;
    const weightPerPallet =
      data.weight_measure === MeasureType.Total
        ? data.total_weight / data.units
        : data.total_weight / data.units + 18.14;

    const length =
      unitType === UnitType.Metric ? inToCm(data.length) : data.length;
    const width =
      unitType === UnitType.Metric ? inToCm(data.width) : data.width;
    const height =
      unitType === UnitType.Metric
        ? m3ToCm3(volumPerPallet) / (length * width)
        : m3ToIn3(volumPerPallet) / (length * width);
    const totalWeightPerItem =
      unitType === UnitType.Metric ? weightPerPallet : kgToLb(weightPerPallet);

    // const classId = imperialFreightClass(
    //   kgToLb(weightPerPallet),
    //   m3ToFt3(volumPerPallet),
    // );
    // handleQuickLoad({
    //   units: data.units,
    //   package_type: 'Pallet',
    //   length,
    //   width,
    //   height,
    //   total_weight: totalWeightPerItem * data.units,
    //   pieces: 1,
    //   class: '',
    //   weight_unit: unitType === UnitType.Metric ? 'kg' : 'lbs',
    //   dimension_unit: unitType === UnitType.Metric ? 'cm' : 'in',
    // } as QuoteItem);
    const item = {
      units: data.units,
      package_type: 'Pallet',
      length,
      width,
      height,
      total_weight: totalWeightPerItem * data.units,
      pieces: 1,
      class: '',
      weight_unit: unitType === UnitType.Metric ? 'kg' : 'lbs',
      dimension_unit: unitType === UnitType.Metric ? 'cm' : 'in',
    } as QuoteItem;
    if (freightClassRequiredFields.every((field) => !!item[field])) {
      fetchItemFreightClass(item)
        .then((response) => (item.class = response.class?.toString() ?? ''))
        .catch((error) => {
          console.error(error);
          showErrorMessage('Failed to fetch freight class');
        })
        .finally(() => {
          handleQuickLoad(item);
        });
    } else {
      handleQuickLoad(item);
    }
  };

  return (
    <Space size="large" align="baseline">
      <Space direction="vertical">
        <Space align="baseline" size="small">
          <Text>Volume</Text>
          <InputNumber
            max={99.99}
            precision={2}
            value={data.volume}
            onChange={(value) =>
              updateData((draft) => {
                draft.volume = value ?? 0;
              })
            }
          />
          <Text>
            m<sup>3</sup>
          </Text>
        </Space>
        <Radio.Group
          size="small"
          defaultValue={MeasureType.Total}
          value={data.volume_measure}
          onChange={(e) =>
            updateData((draft) => {
              draft.volume_measure = e.target.value ?? MeasureType.Total;
            })
          }
        >
          <Radio value={MeasureType.Cargo}>Cargo</Radio>
          <Radio value={MeasureType.Total}>Total</Radio>
        </Radio.Group>
      </Space>
      <Space direction="vertical">
        <Space align="baseline" size="small">
          <Text>Weight</Text>
          <InputNumber
            max={9999}
            value={data.total_weight}
            onChange={(value) =>
              updateData((draft) => {
                draft.total_weight = value ?? 0;
              })
            }
          />
          <Text>kg</Text>
        </Space>
        <Radio.Group
          size="small"
          defaultValue={MeasureType.Total}
          value={data.weight_measure}
          onChange={(e) =>
            updateData((draft) => {
              draft.weight_measure = e.target.value;
            })
          }
        >
          <Radio value={MeasureType.Cargo}>Cargo</Radio>
          <Radio value={MeasureType.Total}>Total</Radio>
        </Radio.Group>
      </Space>
      <Space align="baseline" size="small">
        <Text>Type</Text>
        <Select
          style={{ width: 120 }}
          defaultValue={'Pallet'}
          value={data.package_type}
          onChange={(value) =>
            updateData((draft) => {
              draft.package_type = value;
            })
          }
          options={packageingTypes.map((item) => ({
            value: item,
            label: item,
          }))}
        />
      </Space>
      <Space align="baseline" size="small">
        <InputNumber
          defaultValue={48}
          value={data.length}
          onChange={(value) =>
            updateData((draft) => {
              draft.length = value ?? 0;
            })
          }
        />
        <Text>*</Text>
        <InputNumber
          defaultValue={40}
          value={data.width}
          onChange={(value) =>
            updateData((draft) => {
              draft.width = value ?? 0;
            })
          }
        />
      </Space>
      <Space align="baseline" size="small">
        <Text>Count</Text>
        <InputNumber
          defaultValue={1}
          value={data.units}
          onChange={(value) =>
            updateData((draft) => {
              draft.units = value ?? 0;
            })
          }
        />
      </Space>
      <Space align="baseline">
        <Text>Item Unit</Text>
        <Select
          size="small"
          style={{ width: 120 }}
          options={[
            { value: UnitType.Imperial, label: 'IN/LB' },
            { value: UnitType.Metric, label: 'CM/KG' },
          ]}
          value={unitType}
          onChange={(value) => setUnitType(value)}
        />
      </Space>
      <Button type="primary" disabled={disabled} onClick={onQuickLoad}>
        Quick Load
      </Button>
    </Space>
  );
};

export default QuickLoad;
