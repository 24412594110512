import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  Drawer,
  message,
  Popconfirm,
  Space,
  Table,
  Tag,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { Header } from './components/Header';
import { Filter } from './components/Filter';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { get } from 'lodash';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import AutoResizeTable from '@/components/AutoResizeTable';
import { convertFilterValuesToQueryParams } from '@/pages/dispatchs/components/Filter';
import usePagination from '@/components/usePagination';
import { TCollection } from '@/types';
import { showErrorMessage } from '@/utils/show-error-message';

export const TYPES = [
  'Ocean Ports',
  'Equipment Depots',
  'Railroad Terminals',
  'Customs Container Station',
  'Bonded Warehouse',
  'Inspection Facility',
  'Customs Admin Site',
  'Exam Site',
];

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [data, setData] = useState<TCollection<any[]>>();
  const [terminal, setTerminal] = useState<any>(null);
  const [form] = useForm();

  const pagination = usePagination(data);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setTerminal(null);
    form.resetFields();
  };

  const handleNewVendor = () => {
    setTerminal(null);
    showDrawer();
  };

  const handleFilterChange = (values: any) => {
    setFilter(values);
  };

  // const fetchData = async ({ page } = { page: 1 }) => {
  //   const _filter = Object.assign({}, filter);
  //   if (_filter.city) {
  //     _filter.city_id = _filter.city.id;
  //   }
  //   delete _filter.city;
  //   setLoading(true);
  //   try {
  //     const result = await app.service.get('terminals', {
  //       params: { ..._filter },
  //     });
  //     // setPagination({ current: result.meta.current_page, pageSize: result.meta.per_page, total: result.meta.total });
  //     setData(result.data);
  //   } catch (err: any) {
  //     showErrorMessage(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      const _filter = Object.assign({}, filter);
      if (_filter.city) {
        _filter.city_id = _filter.city.id;
      }
      if (_filter.intermodal_region) {
        _filter.intermodal_region_id = _filter.intermodal_region.id;
      }
      delete _filter.city;
      delete _filter.intermodal_region;
      setLoading(true);

      try {
        const result = await app.service.get('terminals', {
          params: {
            ..._filter,
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        showErrorMessage(e);
      }

      setLoading(false);
    },
    [filter],
  );

  const isNew = useMemo(() => {
    return !terminal;
  }, [terminal]);

  const handleSave = async () => {
    let data;
    try {
      data = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);
    const formValue = Object.assign({}, data);

    formValue.city_id = get(formValue, 'city.id', 0);
    delete formValue.city;
    const request = isNew
      ? app.service.post('terminals', { data: formValue })
      : app.service.put(`terminals/${terminal}`, { data: formValue });

    request
      .then((resp) => {
        fetchData();
        setTerminal(null);
        onClose();
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemove = (id: number) => {
    setLoading(true);

    app.service
      .delete(`terminals/${id}`)
      .then((resp) => {
        fetchData();
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    const item = data?.data.find((item) => item?.id === id);
    if (!item) {
      return;
    }
    setVisible(true);
    setTerminal(id);
    form.setFieldsValue({ ...item });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Firms',
      dataIndex: 'firms',
      key: 'firms',
      width: 120,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (text) => (text ? <Tag color="success">Active</Tag> : 'No'),
      width: 80,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 200,
    },
    {
      title: 'Facility Type',
      dataIndex: 'facility_type',
      key: 'facility_type',
      width: 200,
    },
    {
      title: 'Intermodal Region',
      dataIndex: 'intermodal_region',
      key: 'intermodal_region',
      width: 200,
      render: (text, record, index) => (
        <>{get(record, 'intermodal_region.name', '')}</>
      ),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 200,
      render: (text, record, index) => <>{get(record, 'city.name', '')}</>,
    },
    {
      title: 'Street',
      dataIndex: 'street',
      key: 'street',
      width: 200,
    },
    {
      title: 'Zipcode',
      dataIndex: 'zipcode',
      key: 'zipcode',
      width: 120,
    },
    {
      title: 'Coordinate',
      dataIndex: 'coordinate',
      key: 'coordinate',
      width: 180,
      render: (_, record) => {
        if (record.latitude && record.longitude) {
          const c = `${record.latitude},${record.longitude}`;
          return (
            <a
              href={`http://maps.google.co.uk/maps?q=${c}`}
              target="_blank"
              rel="noreferrer"
            >
              {c}
            </a>
          );
        } else {
          return '-';
        }
      },
    },
    {
      title: 'D/O Address',
      dataIndex: 'delivery_order_address',
      key: 'delivery_order_address',
      width: 300,
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      key: 'memo',
      width: 200,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const handleSearch = () => {
    fetchData();
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Terminals"
        rightElement={
          <div>
            <Button type="primary" onClick={handleNewVendor}>
              New Terminal
            </Button>
          </div>
        }
      ></Header>

      <Filter
        loading={loading}
        filter={filter}
        onSearch={handleSearch}
        onChange={handleFilterChange}
      >
        {' '}
      </Filter>
      <div>
        <AutoResizeTable
          pagination={pagination}
          loading={loading}
          size="small"
          rowKey="id"
          columns={columns}
          onChange={fetchData}
          dataSource={data?.data || []}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>
      <Drawer
        title=""
        placement={'right'}
        width={'80%'}
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <DrawerForm form={form} isNew={isNew} updateData={setTerminal} />
      </Drawer>
    </div>
  );
};

export default Index;
