import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Row,
  message,
  Space,
  Popover,
  Divider,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { TAction } from '../Interfaces/ActionInterface';
import { CheckOutlined, EnterOutlined } from '@ant-design/icons';

export const EditLink: React.FC<{
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
}> = ({ value, placeholder, onChange }) => {
  const [link, setLink] = useState(value);
  return (
    <Input
      size="small"
      value={link}
      allowClear
      onChange={(e) => {
        setLink(e.target.value);
      }}
      placeholder={placeholder ?? 'Enter'}
      addonBefore={
        value ? (
          <a href={value} target="_blank" rel="noreferrer">
            <EnterOutlined />
          </a>
        ) : (
          ''
        )
      }
      addonAfter={
        <span className="text-pointer" onClick={() => onChange(link)}>
          <CheckOutlined />
        </span>
      }
    />
  );
};
