import React, { ReactElement } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  message,
  Row,
  Space,
  Table,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { kebabCase } from 'lodash';
import AutoResizeTable from '@/components/AutoResizeTable';
import { showErrorMessage } from '@/utils/show-error-message';

type TableItem = {
  key: string;
  title: string;
  value?: string | number | ReactElement;
  children?: any;
};
type TableDataProps = TableItem[];

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<TableDataProps>();
  const [filter] = useForm();

  const solveDataList = (dataSource: any, data: TableDataProps) => {
    for (const key in dataSource) {
      const rowItem: TableItem = {
        key: kebabCase(key + Math.random()),
        title: key,
        value: typeof dataSource[key] !== 'object' ? dataSource[key] : '',
        children: [],
      };

      if (typeof dataSource[key].children === 'object') {
        solveDataList(dataSource[key].children, rowItem.children);
      }

      rowItem.children.length > 0 && !rowItem.value && delete rowItem.value;
      rowItem.children.length == 0 && delete rowItem.children;
      data.push(rowItem);
      if (Object.prototype.hasOwnProperty.call(dataSource[key], 'total')) {
        data.push({
          key: kebabCase(key + '_total' + Math.random()),
          title: '',
          value: <strong>{key + ' Total ' + dataSource[key].total}</strong>,
        });
      }
    }
  };

  const handleSave = async () => {
    const values = await filter.validateFields();
    setLoading(true);

    try {
      const result = await app.service.post('accountings/balanceSheet', {
        data: values,
      });
      const data: TableDataProps = [];
      solveDataList(result, data);
      setData(data);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  return (
    <div className={styles.main}>
      <Header title="Balance Sheet"></Header>
      <div className={styles.filter}>
        <Form layout="vertical" form={filter} onFinish={handleSave}>
          <Row gutter={16}>
            <Col span={20}>
              <Form.Item name="end_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Invoice Date As Of" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const end_date = getFieldValue('end_date');
                  return (
                    <DatePicker
                      style={{ width: '100%' }}
                      value={end_date ? moment(end_date) : null}
                      onChange={(value) =>
                        setFieldsValue({
                          end_date: value ? value?.format('YYYY-MM-DD') : null,
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Card loading={loading} style={{ width: '98%', margin: '20px' }}>
        {data && data.length > 0 ? (
          <div style={{ margin: '0 8rem 0 8rem' }}>
            <AutoResizeTable
              defaultExpandAllRows={true}
              pagination={false}
              showHeader={false}
              rowKey={'key'}
              dataSource={data ? data : []}
              scroll={{
                x: 'auto',
              }}
            >
              <Table.Column dataIndex={'title'} align="left" />
              <Table.Column dataIndex={'value'} align="right"></Table.Column>
            </AutoResizeTable>
          </div>
        ) : (
          <div className={styles.empty_table}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </Card>
    </div>
  );
};

export default Index;
