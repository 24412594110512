import { Button, Drawer, message, Space, Tag } from 'antd';
import React, { useEffect } from 'react';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/es/form/Form';
import { get } from 'lodash';
import { SendDelPptDrawer } from './SendDelPptDrawer';
import { WarehouseForm } from './WarehouseForm';
import { showErrorMessage } from '@/utils/show-error-message';

export const WarehouseDrawer: React.FC<{
  id: number | null;
  container?: any;
  disabled?: boolean;
  forbidToModfiySpecifyField?: boolean;
  onSaved?: (data?: any) => void;
  onSent?: () => void;
  onClosed?: () => void;
  visible: boolean;
}> = ({
  id,
  container = null,
  disabled = false,
  forbidToModfiySpecifyField = false,
  onSaved,
  onSent,
  onClosed,
  visible,
}) => {
  const app = useApp();
  const [warehouse, setWarehouse] = React.useState<any>(null);
  const [verifyMessage, setVerifyMessage] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();
  const [openSendDelApt, setOpenSendDelApt] = React.useState(false);
  const [isPreDelAppt, setIsPreDelAppt] = React.useState(false);

  const fetchData = async (id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.get(`warehouses/${id}`);
      const verify_message = get(resp.data, 'verify_message', null);
      setWarehouse(resp.data);
      setVerifyMessage(verify_message);
      form.setFieldsValue({
        ...resp.data,
        user_id: resp.data?.user_id ? resp.data.user_id : '',
      });
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    fetchData(id);
  }, [id]);

  const handleSave = async () => {
    let data;
    try {
      data = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);
    const formValues = Object.assign({}, data);

    formValues.city_id = get(formValues, 'city.id', 0);
    formValues.special_requirements =
      formValues.special_requirements?.map((s: any) => s.id) || [];
    delete formValues.city;
    try {
      const resp = !id
        ? await app.service.post('warehouses', { data: formValues })
        : await app.service.put(`warehouses/${id}`, { data: formValues });
      onSaved && onSaved(resp.data);
      onClosed && onClosed();
      form.resetFields();
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async () => {
    await app.service
      .patch(`warehouses/${id}`)
      .then((resp) => {
        const verify_message = get(resp.data, 'verify_message', null);
        setVerifyMessage(verify_message);
        message.success('Verified');
      })
      .catch((err) => {
        showErrorMessage(err);
      });
  };

  const handleClose = () => {
    onClosed && onClosed();
    form.resetFields();
    setVerifyMessage(null);
  };

  const handleSendPreDelAppt = () => {
    setIsPreDelAppt(true);
    setOpenSendDelApt(true);
  };

  const handleSendDelApt = () => {
    setOpenSendDelApt(true);
  };

  const handleClostSendDelApt = () => {
    setIsPreDelAppt(false);
    setOpenSendDelApt(false);
  };

  const handleSent = () => {
    setIsPreDelAppt(false);
    setOpenSendDelApt(false);
    id && fetchData(id);
    onSent && onSent();
  };

  return (
    <>
      <Drawer
        title={
          <Space>
            {!!id && (
              <>
                <Button type="primary" ghost onClick={handleSendPreDelAppt}>
                  Send PRE-DEL APPT
                </Button>

                <Button type="primary" onClick={handleSendDelApt}>
                  Send DEL-APT
                </Button>
              </>
            )}
            {warehouse?.is_pre_del_appt_sent && (
              <Tag color="processing">{`Pre Alert Already sent at ${
                warehouse?.pre_del_appt_sent_at
              } by ${get(warehouse, 'pre_del_appt_sender.name')}`}</Tag>
            )}
          </Space>
        }
        placement={'right'}
        width={'80%'}
        onClose={handleClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button
              disabled={disabled}
              type="primary"
              loading={loading}
              onClick={handleSave}
            >
              Save
            </Button>
          </Space>
        }
      >
        <WarehouseForm
          id={id}
          form={form}
          disabled={disabled}
          forbidToModfiySpecifyField={forbidToModfiySpecifyField}
          verifyMessage={verifyMessage}
          handleVerify={handleVerify}
        />
      </Drawer>
      {warehouse && openSendDelApt && (
        <SendDelPptDrawer
          isPreDelAppt={isPreDelAppt}
          open={openSendDelApt}
          container={container}
          warehouse={warehouse}
          onClose={handleClostSendDelApt}
          onSent={handleSent}
        />
      )}
    </>
  );
};
