// hooks/useCityList.ts
import { useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';
import { useMemoizedFn } from 'ahooks';
import { message } from 'antd';
import { TCity } from '@/types';
import { showErrorMessage } from '@/utils/show-error-message';

export const useCityList = (zipcode: string) => {
  const app = useApp();
  const [cityList, setCityList] = useState<TCity[]>([]);

  const fetchCityList = useMemoizedFn(async () => {
    if (!zipcode) {
      return;
    }

    try {
      const resp = await app.service.get(`tl/tools/citylist/${zipcode}`);
      setCityList(resp.data);
    } catch (e: any) {
      showErrorMessage(e);
    }
  });

  const getCityById = (cityId: number) => {
    return cityList.find((city) => city.id === cityId);
  };

  const getFirstCityId = () => {
    return cityList[0]?.id;
  };

  useEffect(() => {
    fetchCityList();
  }, [zipcode]);

  return {
    cityList,
    fetchCityList,
    getCityById,
    getFirstCityId,
  };
};
