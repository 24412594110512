import { showErrorMessage } from '@/utils/show-error-message';
import { useApp } from '@/utils/useapp';
import { message } from 'antd';
import React from 'react';

const useActionTasks = () => {
  const app = useApp();

  const fetchData = async (
    containerId: number,
    taskType: string,
    setLoading: (loading: boolean) => void,
  ) => {
    if (!containerId || !taskType) {
      return null;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(`containers/${containerId}/tasks`, {
        params: {
          task_type: taskType,
        },
      });
      return resp.data;
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const updateContainerTask = async (
    containerId: number,
    data: { [key: string]: any },
    afterUpdated: (resp: any) => void,
  ) => {
    try {
      const resp = await app.service.put(
        `containers/${containerId}/updateTask`,
        {
          data: { ...data },
        },
      );
      afterUpdated(resp);
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  const checkTimeValid = async (
    containerId: number,
    data: { [key: string]: any },
  ) => {
    try {
      const resp = await app.service.get(
        `containers/${containerId}/checkTimeValid`,
        {
          params: { ...data },
        },
      );
      return resp;
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  return {
    fetchData,
    updateContainerTask,
    checkTimeValid,
  };
};
export default useActionTasks;
