import { Button, Dropdown, message, Modal, Spin, Space, Form } from 'antd';
import { useApp } from '@/utils/useapp';
import { useEffect, useState } from 'react';
import React, { FC } from 'react';
import OrderEmaiForm from './OrderEmaiForm';
import LtlShipmentEmaiForm from './LtlShipmentEmaiForm';
import FtlShipmentEmaiForm from './FtlShipmentEmaiForm';
import { get, uniq } from 'lodash';
import { useForm } from 'antd/lib/form/Form';
import { serialize } from 'object-to-formdata';
import {
  INVOICE_TYPE_LTL,
  INVOICE_TYPE_FTL,
  INVOICE_TYPE_ORDER,
  INVOICE_TYPE_CFS,
} from '@/pages/accounting/invoices/components/data';
import { STATE_ESTIMATE } from '@/pages/accounting/invoices/components/data';
import CfsShipmentEmaiForm from './CfsShipmentEmaiForm';
import { showErrorMessage } from '@/utils/show-error-message';

interface ISendInvoiceDropdown {
  invoiceableId?: number;
  invoiceableType?: string;
  invoices: any[];
  onSaved?: () => void;
}

const SendInvoiceDropdown: FC<ISendInvoiceDropdown> = ({
  invoiceableType,
  invoiceableId,
  invoices,
  onSaved,
}) => {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailForm] = useForm();

  const isDifferentCustomers = async () => {
    if (!invoices || invoices?.length === 1) {
      return false;
    }

    try {
      const isDifferent = await app.service.get(
        'orderInvoices/isDifferentCustomers',
        {
          params: {
            invoiceIds: uniq(invoices.map((c) => c.id)).join(','),
            invoiceableId: invoiceableId,
          },
        },
      );

      if (isDifferent) {
        message.error('Can’t send emails due to different customers');
        return true;
      }

      return false;
    } catch (err: any) {
      showErrorMessage(err);
      return true;
    }
  };

  const openEmailModal = async () => {
    if (invoices?.length == 0) {
      message.error('No invoices to send.');
      return;
    }

    if (invoices?.find((iv) => iv.state == STATE_ESTIMATE)) {
      return message.error('Unable to send estimated invoice');
    }

    if (await isDifferentCustomers()) {
      return false;
    }

    setShowEmailModal(true);
  };

  const handleEmailSent = () => {
    setShowEmailModal(false);
    onSaved && onSaved();
  };

  const handleOnCloseEmail = () => {
    setShowEmailModal(false);
  };

  const markAsSent = async () => {
    if (!invoices || invoices?.length === 0) {
      message.error('No invoices to send.');
      return;
    }

    setLoading(true);

    try {
      if (invoices.length == 0) {
        return message.error('Please select at least one invoice.');
      }
      const invoiceIds = uniq(invoices?.map((i) => i.id));

      await app.service.post('orderInvoices/markAsSent', {
        data: {
          invoice_ids: invoiceIds,
        },
      });
      message.success('Mark as sent.');

      onSaved && onSaved();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      const data = await emailForm.validateFields();

      data.invoiceIds = uniq(invoices?.map((i) => i.id));

      const config = {
        data: serialize(data, { indices: true, nullsAsUndefineds: true }),
        requestType: 'form',
      };

      await app.service.post('orderInvoices/sendEmail', config);

      message.success('Sent');

      emailForm.resetFields();

      handleEmailSent();
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              key: 1,
              label: <div onClick={openEmailModal}>Send Email</div>,
            },
            {
              key: 2,
              label: <div onClick={markAsSent}>Mark as sent</div>,
            },
          ],
        }}
      >
        <Button loading={loading}>Send Invoice</Button>
      </Dropdown>

      {showEmailModal && (
        <Modal
          zIndex={1001}
          title="Send Invoice Email"
          width={'60%'}
          onCancel={handleOnCloseEmail}
          destroyOnClose={true}
          open={showEmailModal}
          footer={
            <>
              <Space>
                <Button disabled={loading} onClick={handleOnCloseEmail}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  disabled={loading}
                  onClick={handleSendEmail}
                >
                  Send
                </Button>
              </Space>
            </>
          }
        >
          <div style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
            <Spin spinning={loading}>
              <Form form={emailForm} layout="vertical">
                {invoiceableType == INVOICE_TYPE_ORDER && (
                  <OrderEmaiForm
                    orderId={invoiceableId}
                    invoices={invoices}
                    emailForm={emailForm}
                  />
                )}
                {invoiceableType == INVOICE_TYPE_LTL && (
                  <LtlShipmentEmaiForm
                    ltlShipmentId={invoiceableId}
                    invoices={invoices}
                    emailForm={emailForm}
                  />
                )}

                {invoiceableType == INVOICE_TYPE_FTL && (
                  <FtlShipmentEmaiForm
                    shipmentId={invoiceableId}
                    invoices={invoices}
                    emailForm={emailForm}
                  />
                )}
                {invoiceableType == INVOICE_TYPE_CFS && (
                  <CfsShipmentEmaiForm
                    cfsShipmentId={invoiceableId}
                    invoices={invoices}
                    emailForm={emailForm}
                  />
                )}
              </Form>
            </Spin>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SendInvoiceDropdown;
