import { showErrorMessage } from '@/utils/show-error-message';
import { useApp } from '@/utils/useapp';
import { Column } from '@ant-design/charts';
import { Col, message, Space } from 'antd';
import { Row } from 'antd';
import { Card } from 'antd';
import saveAs from 'file-saver';
import { useState } from 'react';

const columnConfig = {
  xField: 'week',
  yField: 'value',
  color: '#1890ff',
  label: {
    style: {
      fill: '#FFFFFF',
      opacity: 0.6,
    },
  },
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: false,
    },
  },
  height: 200,
};

const stackColumnConfig = {
  isStack: true,
  xField: 'week',
  yField: 'value',
  seriesField: 'type',
  height: 200,
  label: {
    layout: [
      {
        type: 'interval-adjust-position',
      },
      {
        type: 'interval-hide-overlap',
      },
      {
        type: 'adjust-color',
      },
    ],
    style: {
      fill: '#FFFFFF',
      opacity: 0.6,
    },
  },
};

interface ChartData {
  week: string;
  value: number;
}

export interface EfficiencyData {
  bol: ChartData[];
  pickup: ChartData[];
  delivery: ChartData[];
}

export const Efficiency = ({ bol, pickup, delivery }: EfficiencyData) => {
  const app = useApp();
  const [bolLoading, setBolLoading] = useState(false);
  const [pickupLoading, setPickupLoading] = useState(false);
  const [deliveryLoading, setDeliveryLoading] = useState(false);

  const handleExportBOLEfficiency = async () => {
    setBolLoading(true);
    try {
      const file = await app.service.get(
        'tl/ltl/dashboard/export-bol-efficiency',
        {
          responseType: 'blob',
        },
      );
      const filename = `BOL Efficiency - ${new Date().toDateString()}.xlsx`;
      saveAs(file, filename);
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      setBolLoading(false);
    }
  };

  const handleExportPickupEfficiency = async () => {
    setPickupLoading(true);
    try {
      const file = await app.service.get(
        'tl/ltl/dashboard/export-pickup-efficiency',
        {
          responseType: 'blob',
        },
      );
      const filename = `Pickup Efficiency - ${new Date().toDateString()}.xlsx`;
      saveAs(file, filename);
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      setPickupLoading(false);
    }
  };

  const handleExportDeliveryEfficiency = async () => {
    setDeliveryLoading(true);
    try {
      const file = await app.service.get(
        'tl/ltl/dashboard/export-delivery-efficiency',
        {
          responseType: 'blob',
        },
      );
      const filename = `Delivery Efficiency - ${new Date().toDateString()}.xlsx`;
      saveAs(file, filename);
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      setDeliveryLoading(false);
    }
  };

  return (
    <Row gutter={8}>
      <Col span={8}>
        <Card
          loading={bolLoading}
          title={
            <Space>
              <span style={{ fontWeight: 'bold' }}>BOL Efficiency</span>
              <a href="#" onClick={handleExportBOLEfficiency}>
                Download
              </a>
            </Space>
          }
        >
          <Column {...stackColumnConfig} data={bol} />
        </Card>
      </Col>
      <Col span={8}>
        <Card
          loading={pickupLoading}
          title={
            <Space>
              <span style={{ fontWeight: 'bold' }}>Pickup Efficiency</span>
              <a href="#" onClick={handleExportPickupEfficiency}>
                Download
              </a>
            </Space>
          }
        >
          <Column {...columnConfig} data={pickup} />
        </Card>
      </Col>
      <Col span={8}>
        <Card
          loading={deliveryLoading}
          title={
            <Space>
              <span style={{ fontWeight: 'bold' }}>Delivery Efficiency</span>
              <a href="#" onClick={handleExportDeliveryEfficiency}>
                Download
              </a>
            </Space>
          }
        >
          <Column {...columnConfig} data={delivery} />
        </Card>
      </Col>
    </Row>
  );
};
