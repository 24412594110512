import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message, Row, Col, Divider, Card } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { ChassisTypeForm } from './ChassisTypeForm';
import { serialize } from 'object-to-formdata';
import { get } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';
interface IChassisTypeDrawer {
  id: number;
  visible: boolean;
  onClose: () => void;
  onSaved?: (data: any) => void;
}

export const ChassisTypeDrawer: React.FC<IChassisTypeDrawer> = ({
  id,
  visible,
  onClose,
  onSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();

  const fetchData = async (id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.get(`chassis_types/${id}`);
      form.setFieldsValue({
        ...resp.data,
      });
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    fetchData(id);
  }, [id]);

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);

    try {
      const resp = !values.id
        ? await app.service.post('chassis_types', { data: values })
        : await app.service.put(`chassis_types/${values.id}`, {
            data: values,
          });
      message.success('Saved');
      onSaved && onSaved(resp.data);
      handleClose();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };
  return (
    <Drawer
      title={form.getFieldValue('uid')}
      width={'65%'}
      onClose={handleClose}
      destroyOnClose={true}
      open={visible}
      extra={
        <Space>
          <Button onClick={handleClose}>Close</Button>
          <Button type="primary" loading={loading} onClick={handleSave}>
            Save
          </Button>
        </Space>
      }
    >
      <ChassisTypeForm form={form} />
    </Drawer>
  );
};
