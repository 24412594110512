import { get, has } from 'lodash';

import React, { useEffect, useMemo } from 'react';
import taskTypeUtil from '../Interfaces/TaskType';
import {
  TAction,
  TARGET_TYPE_LTL_SHIPMENT,
  TARGET_TYPE_FTL_SHIPMENT,
  TARGET_TYPE_CFS_SHIPMENT,
} from '../Interfaces/ActionInterface';

const TaskTypeColumn: React.FC<
  {
    colors: { [key: string]: string };
  } & TAction
> = ({ colors, target, targetModel, task }) => {
  if (!task) {
    return <></>;
  }

  const TaskTypeKeys = useMemo(() => {
    switch (target) {
      case TARGET_TYPE_LTL_SHIPMENT:
        return taskTypeUtil.LTLSHIPEMNT_TASK_TYPES[
          task.task_type as keyof typeof taskTypeUtil.LTLSHIPEMNT_TASK_TYPES
        ];
      case TARGET_TYPE_FTL_SHIPMENT:
        return taskTypeUtil.FTLSHIPEMNT_TASK_TYPES[
          task.task_type as keyof typeof taskTypeUtil.FTLSHIPEMNT_TASK_TYPES
        ];
      case TARGET_TYPE_CFS_SHIPMENT:
        return taskTypeUtil.CFS_TASK_TYPES[
          task.task_type as keyof typeof taskTypeUtil.CFS_TASK_TYPES
        ];
      default:
        return null;
    }
  }, [target]);

  const color = useMemo(
    () => (has(colors, task.task_type) ? get(colors, task.task_type) : 'gray'),
    [colors],
  );

  return (
    <span className={`text-${color}`}>
      {TaskTypeKeys &&
        Object.keys(TaskTypeKeys).map(
          (key) => TaskTypeKeys[key as keyof typeof TaskTypeKeys],
        )}
    </span>
  );
};

export default TaskTypeColumn;
