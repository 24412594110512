import { Spin, Space, Empty, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { BuyRateForm } from '@/pages/rates/buyRates/components/buyRateDrawer/BuyRateForm';
import { get } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { RateLink } from '@/components/Rate/RateLink';
import { OPEN_TYPE_BUY_RATE } from '@/components/Rate/OpenBuyRateOrSellRateDrawer';
import { useApp } from '@/utils/useapp';
import { showErrorMessage } from '@/utils/show-error-message';

interface IBuyRateFormCard {
  orderId: number;
  container?: any;
}

export const BuyRateFormCard: React.FC<IBuyRateFormCard> = ({
  orderId,
  container,
  ...props
}) => {
  const app = useApp();

  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);

  const [buyRateId, setBuyRateId] = React.useState(0);

  const [showBuyRateForm, setShowBuyRateForm] = React.useState(false);

  const fetchData = async () => {
    if (!get(container, 'id')) {
      return;
    }

    setLoading(true);

    try {
      const resp = await app.service.get(`containers/${container.id}/buyRate`);

      if (resp.data) {
        form.setFieldsValue(resp.data);

        setBuyRateId(resp.data.id);

        setShowBuyRateForm(true);
      } else {
        setShowBuyRateForm(false);
      }
    } catch (e: any) {
      setShowBuyRateForm(false);
      showErrorMessage(e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (get(container, 'buy_rate_id')) {
      fetchData();
    }
  }, [container]);

  // useEffect(() => {
  //   if (!get(container, 'buy_rate')) {
  //     return;
  //   }
  //   form.setFieldsValue(container.buy_rate);
  // }, [container]);

  return (
    <>
      <Space>
        <h3>Buy Rate</h3>
        <RateLink
          type={OPEN_TYPE_BUY_RATE}
          rateUid={get(container, 'buy_rate.uid')}
          hasBanModifyFields={true}
        />
      </Space>

      <Spin spinning={loading}>
        {showBuyRateForm ? (
          <div style={{ height: 'calc(100vh - 150px)', overflowY: 'auto' }}>
            <BuyRateForm
              form={form}
              isNew={false}
              id={get(container, 'buy_rate_id')}
              disabled={true}
            />
          </div>
        ) : (
          <Empty />
        )}
      </Spin>
    </>
  );
};
