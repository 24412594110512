import { useApp } from '@/utils/useapp';
import { message, Select, SelectProps, Spin } from 'antd';
import { capitalize, debounce } from 'lodash';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { concat } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';

const { Option } = Select;

type SelectedItem = {
  id: number;
};

export const ModelSelect: React.FC<{
  selected: any;
  onSelect: (id: string) => void;
  model: string;
  uri?: string;
  isPathParams?: boolean;
  searchParams?: any;
  uriKey?: string;
  renderOption?: (a?: any) => ReactNode;
  dropdownStyle?: any;
  multiple?: boolean;
  notFoundContent?: ReactNode | null;
  disabled?: boolean;
  className?: any;
  style?: any;
}> &
  SelectProps = ({
  selected,
  onSelect,
  model,
  renderOption,
  uri,
  isPathParams = false,
  searchParams,
  uriKey = 'key',
  dropdownStyle = { minWidth: '500px', zIndex: 10010 },
  multiple = false,
  notFoundContent = null,
  disabled = false,
  ...props
}) => {
  const app = useApp();
  const [options, setOptions] = React.useState(
    selected ? (multiple ? selected : [selected]) : [],
  );
  const [isLoading, setIsLoading] = React.useState(false);

  function onChange(id: any) {
    if (!multiple) {
      return onSelect(options.find((c: SelectedItem) => c.id == id));
    }

    let selectedOptions;

    if (selected) {
      const selectedIds = selected
        ? selected.map((item: SelectedItem) => item.id)
        : [];

      const dataNotSelected = options.filter(
        (d: SelectedItem) => !selectedIds.includes(d.id) && d,
      );

      selectedOptions = Object.assign([], concat(selected, dataNotSelected));
    } else {
      selectedOptions = Object.assign([], options);
    }

    return onSelect(
      selectedOptions.filter((c: SelectedItem) => id.includes(c.id) && c),
    );
  }

  useEffect(() => {
    if (multiple) {
      setOptions(selected ? selected : []);
    } else {
      setOptions(selected ? [selected] : []);
    }
  }, [selected]);

  const _props = useMemo(() => {
    let valueItem = {};
    if (selected) {
      valueItem = {
        value: multiple
          ? selected?.map((option: SelectedItem) => option.id)
          : selected?.id,
      };
    }

    return {
      ...props,
      ...valueItem,
    };
  }, [props, selected, multiple]);

  const debounceFetcher = useMemo(() => {
    const handleSearch = async (text: string, params = { limit: 50 }) => {
      setOptions([]);

      if (text.length < 3) {
        return;
      }

      text = encodeURIComponent(text);

      let _params = {};
      !isPathParams && (_params = { name: text, ...params });
      searchParams && (_params = { ...searchParams, ..._params });

      setIsLoading(true);
      try {
        const resp = await app.service.get(
          !(isPathParams && uri)
            ? `${model}/search`
            : uri.replace(`:${uriKey}`, text),
          { params: _params },
        );

        setOptions(resp.data);
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setIsLoading(false);
      }
    };
    return debounce(handleSearch, 500);
  }, [searchParams]);

  useEffect(() => {
    return () => {
      debounceFetcher.cancel();
    };
  }, []);
  return (
    <Select
      disabled={disabled}
      // autoComplete="new-password"
      // placeholder={`Select ${capitalize(model)}`}
      placeholder={'Select'}
      {..._props}
      showSearch
      filterOption={false}
      onChange={onChange}
      onSearch={debounceFetcher}
      showArrow
      loading={isLoading}
      dropdownStyle={dropdownStyle}
      allowClear
      notFoundContent={isLoading ? <Spin size="small" /> : notFoundContent}
    >
      {options.map((v: any) => (
        <Option key={v?.id || ''} value={v?.id || ''}>
          {renderOption ? renderOption(v) : <strong>{v.name}</strong>}
        </Option>
      ))}
    </Select>
  );
};
