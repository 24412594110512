import { useApp } from '@/utils/useapp';
import { UploadProps } from 'antd';
import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useEffect } from 'react';
import { DraggerWithPaste } from './DraggerWithPaste';
import { ACCEPT_FILE_TYPES } from './data';
import { checkFileSize, FILE_MAX_SIZE } from './Validate';
import { showErrorMessage } from '@/utils/show-error-message';

type TOptions = {
  container_id: number;
  containerIds?: Array<number>;
  billing_code?: string;
  document_type?: string;
};
export const Documents: React.FC<{
  options?: TOptions;
  title: string;
  target: string;
  targetId: number;
  disabled?: boolean;
}> = ({
  options = {} as TOptions,
  title = '',
  target,
  targetId,
  disabled = false,
}) => {
  const [fileList, setFileList] = React.useState<UploadProps['fileList']>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const app = useApp();

  const fetchData = async () => {
    app.service
      .get(`documents/${target}/${targetId}`, {
        params: options ? { ...options } : {},
      })
      .then((resp) => {
        setFileList(
          resp.data.map((document: any) => ({
            uid: document.id,
            name: document.file_name,
            status: 'done',
            url: `${API_URL}/documents/${document.id}/url`,
          })),
        );
      })
      .catch((err) => {
        showErrorMessage(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (options?.billing_code || options?.container_id) {
      fetchData();
    }
  }, [options?.billing_code, options?.container_id]);

  const beforeUpload = (file: RcFile) => {
    if (disabled) {
      return false;
    }

    const sizeOk = checkFileSize(file);

    if (!sizeOk) {
      message.error(`File must smaller than ${FILE_MAX_SIZE}MB!`);
    }
    return sizeOk;
  };

  const uploadToServer = async (formData: FormData, action: string) => {
    if (options.billing_code) {
      formData.append('billing_code', options.billing_code);
    }

    if (options.container_id) {
      formData.append('container_id', options.container_id);
    }

    if (options.containerIds) {
      options.containerIds.map((id: number, index) =>
        formData.append(`containerIds[${index}]`, id),
      );
    }

    if (options.document_type) {
      formData.append('document_type', options.document_type);
    }

    setIsLoading(true);
    try {
      const resp = await app.service.post(action, {
        data: formData,
      });
      setFileList(
        resp.data.map((document: any) => ({
          uid: document.id,
          name: document.file_name,
          status: 'done',
          url: `${API_URL}/documents/${document.id}/url`,
        })),
      );
      message.success('Document uploaded successfully');
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setIsLoading(false);
    }
  };

  const props: UploadProps = {
    name: 'documents[0]',
    multiple: true,
    beforeUpload,
    accept: ACCEPT_FILE_TYPES,
    action: `documents/${target}/${targetId}`,
    customRequest: async (options) => {
      const formData = new FormData();
      formData.append('documents[0]', options.file);

      await uploadToServer(formData, options.action);
    },
    onRemove: async (file) => {
      if (disabled) {
        return;
      }
      setIsLoading(true);
      try {
        await app.service.delete(`documents/${file.uid}`);
        setFileList(fileList?.filter((item) => item.uid !== file.uid));
        message.success(`${file.name} removed`);
      } catch (err: any) {
        showErrorMessage(err);
      } finally {
        setIsLoading(false);
      }
    },
    onPreview: async (file) => {
      const resp = await app.service.get(`documents/${file.uid}/url`);
      const url = resp;
      const w = window.open(url, '_blank');
    },
  };

  const handlePasteFiles = async (pasteFiles: any) => {
    const formData = new FormData();
    pasteFiles?.map((pasteFile: any, i: number) =>
      formData.append('documents[' + i + ']', pasteFile),
    );
    await uploadToServer(formData, `documents/${target}/${targetId}`);
  };

  return (
    <>
      <DraggerWithPaste
        title={title}
        acceptFileTypes={ACCEPT_FILE_TYPES}
        uploadProp={props}
        disabled={disabled || isLoading}
        fileList={fileList}
        handlePasteFiles={handlePasteFiles}
      />
    </>
  );
};
