import { TRole } from '@/types';
import { showErrorMessage } from '@/utils/show-error-message';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Row,
  Input,
  message,
  Modal,
  Space,
  Tree,
} from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { DataNode } from 'antd/lib/tree';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

interface IRoleDetailProps {
  role: TRole;
  open: boolean;
  onClose: () => void;
}

const RoleDetailView: FC<IRoleDetailProps> = ({ open, role, onClose }) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const rolePermissionIds = useMemo(() => role.permissions.map((p) => p.id), [
    role,
  ]);

  const treeData = useMemo(() => {
    const nodes: DataNode[] = [];
    const map: any = {};
    for (const row of data) {
      if (rolePermissionIds.indexOf(row.id) === -1) {
        continue;
      }

      const [cando, name] = row.name.split('.');
      if (map[name]) {
        map[name].children.push({
          title: cando,
          key: row.id,
        });
      } else {
        map[name] = {
          title: name,
          key: name,
          children: [
            {
              title: cando,
              key: row.id,
            },
          ],
        };
      }
    }
    Object.values(map).map((v: any) => nodes.push(v));
    return nodes;
  }, [data, rolePermissionIds]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const result = await app.service.get('roles/permissions');
        setData(result.data);
      } catch (e: any) {
        showErrorMessage(e, { fallback: 'Failed to fetch data' });
      } finally {
        setLoading(false);
      }
    })();
  }, [role]);

  return (
    <Drawer width={900} open={open} closable={true} onClose={onClose}>
      <Row>
        <Space>
          <span>Role Name: </span>
          {role.name}
        </Space>
      </Row>
      <Divider />
      <Tree treeData={treeData} />
    </Drawer>
  );
};

export default RoleDetailView;
