import { Button, message, Popover } from 'antd';
import { useApp } from '@/utils/useapp';
import { useEffect, useState } from 'react';
import React from 'react';
import actionUtils, { TAction } from '../../Interfaces/ActionInterface';
import { RebillableType } from '@/components/Rebill/data';
import RebillTable from '@/components/Rebill/RebillTable';

const RebillButton: React.FC<TAction> = ({
  task,
  targetModel: shipment,
  onDone,
}) => {
  const [open, setOpen] = useState(false);

  const handleCloseOpen = () => {
    setOpen(false);
  };

  const handleOnSaved = () => {
    onDone?.(task, shipment);
    setOpen(false);
  };

  return (
    <Popover
      title={'Rebill - ' + shipment.uid}
      mouseEnterDelay={0.05}
      mouseLeaveDelay={0.05}
      trigger="click"
      onOpenChange={(open: boolean) => setOpen(open)}
      open={open}
      placement="right"
      zIndex={1000}
      destroyTooltipOnHide
      content={
        <div style={{ width: '50vw' }}>
          <RebillTable
            rebillableType={RebillableType.CFS}
            rebillableId={shipment.id}
            onSaved={handleOnSaved}
            options={{
              task_id: task.id,
              action: actionUtils.REBILL,
            }}
          />
        </div>
      }
    >
      <Button type="default" size="small">
        {actionUtils.REBILL}
      </Button>
    </Popover>
  );
};

export default RebillButton;
