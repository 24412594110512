import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  DrawerProps,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { OceanCarrierSelect } from '@/components/OceanCarrierSelect';
import { PortSelect } from '@/components/PortSelect';
import { CitySelect } from '@/components/CitySelect';
import { Header } from '@/components/CommonHeader';
import { ContainerTypeSelect } from '@/components/ContainerTypeSelect';
import { useApp } from '@/utils/useapp';
import moment from 'moment';
import styles from 'res/css/ui.scss';
import useLoadSpotTemplateModal from './LoadSpotTemplateModal';
import useSaveAsTemplateModal from './SaveAsTemplateModal';
import { UserSyncSelect } from '../../UserSyncSelect';
import { defaultData } from './defaultData';
import RatesTable from './RatesTable';
import AdditionalChargesTable from './AdditionalChargesTable';
import { AntDDatePicker, presetDates } from 'antd-datepicker';
import { PossibleCharges } from '@/components/PossibleCharges';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { showErrorMessage } from '@/utils/show-error-message';

const { Option } = Select;

interface Props {
  quoteId?: string | undefined;
  visible: boolean;
  onSubmit?: () => void;
  onClose?: DrawerProps['onClose'];
}

const Index: React.FC<Props> = ({ onSubmit, quoteId, visible, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [targetQuoteId, setTargetQuoteId] = useState('');
  const [form] = Form.useForm();
  const app = useApp();
  const {
    openLoadSpotTemplateModal,
    LoadSpotTemplateModal,
  } = useLoadSpotTemplateModal();
  const {
    openSaveAsTemplateModal,
    SaveAsTemplateModal,
  } = useSaveAsTemplateModal();

  const getQuoteDataByQuoteId = (quoteId: string | undefined) => {
    if (!quoteId) {
      return;
    }
    app.service
      .get(`spotRates/${quoteId}`)
      .then(({ data }) => {
        form.setFieldsValue(data);
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  useEffect(() => {
    getQuoteDataByQuoteId(quoteId);
  }, [quoteId]);

  const handleCopy = () => {
    if (!targetQuoteId) {
      return;
    }

    getQuoteDataByQuoteId(targetQuoteId);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      onSubmit && onSubmit();

      await form.validateFields();
      const values = form.getFieldsValue(true);
      !values.id
        ? await app.service.post('spotRates', { data: { ...values } })
        : await app.service.put(`spotRates/${values.id}`, {
            data: { ...values },
          });
      message.success('Saved');
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleDownloadExcel = async () => {
    const response = await app.service.get(`spotRates/${quoteId}/excel`, {
      responseType: 'blob',
      getResponse: true,
    });

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const href = window.URL.createObjectURL(blob); // 创建下载的链接
    const disposition = response.response.headers.get('Content-Disposition');
    const filename = disposition
      ? decodeURI(disposition.split(';')[1].split('=')[1])
      : `${quoteId}.xlsx`;

    const downloadElement = document.createElement('a'); // 创建a标签
    downloadElement.href = href;
    downloadElement.download = filename; // 下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); // 点击下载
    document.body.removeChild(downloadElement); // 下载完成移除元素
    window.URL.revokeObjectURL(href); // 释放掉blob对象
  };

  return (
    <Drawer
      title=""
      placement="right"
      width="80%"
      open={visible}
      onClose={onClose}
      destroyOnClose={true}
    >
      <Form
        layout="vertical"
        form={form}
        className={styles.main}
        initialValues={defaultData}
      >
        <Space>
          <Button type="primary" htmlType="submit" onClick={handleSave}>
            Save
          </Button>
          <Input.Group compact>
            <Input
              id="search_quote_id"
              style={{ width: 'calc(100% - 66px)' }}
              placeholder="Quote ID"
              onChange={(e) => setTargetQuoteId(e.target.value)}
            />
            <Tooltip title="copy">
              <Button onClick={handleCopy}>Copy</Button>
            </Tooltip>
          </Input.Group>
          <Button onClick={handleDownloadExcel}>Download Excel</Button>
          <Button>Download PDF</Button>
          <Button>View PDF</Button>
          <Button onClick={() => openLoadSpotTemplateModal()}>
            Load From Template
          </Button>
          <Button onClick={() => openSaveAsTemplateModal()}>
            Save as Template
          </Button>
        </Space>

        <Row gutter={24} className="mt-lg">
          <Col flex="1 1 0">
            <Row gutter={24}>
              <Col flex="1 1 0">
                <Form.Item name="name" label="Quote Name">
                  <Input placeholder="Quote Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col flex="1 1 0">
                <Form.Item name="id" label="Quote ID">
                  <Input placeholder="Quote ID" disabled />
                </Form.Item>
              </Col>
              <Col flex="1 1 0">
                <Form.Item
                  name="quote_date"
                  label="Quote Date"
                  rules={[
                    { required: true, message: 'please input this field' },
                  ]}
                >
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldValue }) => {
                      const value = getFieldValue('quote_date');
                      return (
                        <AntDDatePicker
                          presetDates={presetDates}
                          style={{ width: '100%' }}
                          value={value ? moment(value) : null}
                          onChange={(v) => {
                            setFieldValue(
                              'quote_data',
                              v?.format('YYYY-MM-DD'),
                            );
                          }}
                        />
                      );
                    }}
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col flex="1 1 0">
                <Form.Item
                  name="expired_date"
                  label="Quote Expire Date"
                  rules={[
                    { required: true, message: 'please input this field' },
                  ]}
                >
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldValue }) => {
                      const value = getFieldValue('expired_date');
                      return (
                        <AntDDatePicker
                          presetDates={presetDates}
                          style={{ width: '100%' }}
                          value={value ? moment(value) : null}
                          onChange={(v) => {
                            setFieldValue(
                              'expired_date',
                              v?.format('YYYY-MM-DD'),
                            );
                          }}
                        />
                      );
                    }}
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col flex="1 1 0">
                <Form.Item name="type" label="Quote Type">
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldValue }) => (
                      <Select
                        value={getFieldValue('type')}
                        onChange={(v) => setFieldValue('type', v)}
                      >
                        <Option value="">{''}</Option>
                        <Option value="Customer">Customer</Option>
                        <Option value="Agent">Agent</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col flex="1 1 0">
                <Form.Item shouldUpdate noStyle>
                  {() => (
                    <Form.Item name="sales_id" label="Sales Person">
                      <UserSyncSelect type="admin" />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col flex="1 1 0">
                <Form.Item shouldUpdate noStyle>
                  {() => (
                    <Form.Item
                      name="rate_id"
                      label="DE Rate ID"
                      rules={[
                        { required: true, message: 'please input this field' },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col flex="1 1 0">
                <Form.Item
                  name="user_id"
                  label="Customer"
                  rules={[
                    { required: true, message: 'please input this field' },
                  ]}
                >
                  <UserSyncSelect />
                </Form.Item>
              </Col>
              <Col flex="1 1 0"></Col>
              <Col flex="1 1 0"></Col>
            </Row>
          </Col>
          <Col flex="1 1 0">
            <Row gutter={24}>
              <Col flex="1 1 0">
                <Form.Item shouldUpdate noStyle>
                  {() => (
                    <Form.Item name="carrier_code" label="Carrier">
                      <OceanCarrierSelect getValueForOption="code" />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col flex="1 1 0">
                <Form.Item
                  name="pod_id"
                  label="POD"
                  rules={[
                    { required: true, message: 'please input this field' },
                  ]}
                >
                  <IntermodalRegionSelect />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col flex="1 1 0">
                <Form.Item
                  name="del_id"
                  label="DEL(IR)"
                  rules={[
                    { required: true, message: 'please input this field' },
                  ]}
                >
                  <IntermodalRegionSelect />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col flex="1 1 0">
                <Form.Item noStyle shouldUpdate>
                  {() => (
                    <Form.Item label="F.Dest">
                      <CitySelect
                        selected={form.getFieldValue('fdest')}
                        onSelect={(v) => {
                          form.setFieldValue('fdest', v);
                          form.setFieldValue('fdest_id', v?.id);
                        }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col flex="1 1 0">
                <Form.Item name="fdest_zipcode" label="F.Dest Zipcode">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Form.List name="containers">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <Header
                        title="Container"
                        style={{ alignItems: 'baseline' }}
                        rightElement={
                          <Button
                            type="primary"
                            style={{ marginLeft: '6px' }}
                            onClick={() => {
                              add();
                            }}
                            icon={<PlusOutlined />}
                          />
                        }
                      />
                      {fields.map((field, index) => (
                        <Space
                          key={field.key}
                          align="baseline"
                          style={{ width: '100%', paddingLeft: '16px' }}
                        >
                          <Form.Item
                            shouldUpdate
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: 'please input this field',
                              },
                            ]}
                          >
                            {() => (
                              <Form.Item
                                name={[field.name, 'size_type']}
                                style={{ width: '100px' }}
                              >
                                <ContainerTypeSelect getValueForOption="code" />
                              </Form.Item>
                            )}
                          </Form.Item>

                          <Form.Item
                            name={[field.name, 'qty']}
                            rules={[
                              {
                                required: true,
                                message: 'please input this field',
                              },
                            ]}
                          >
                            <InputNumber />
                          </Form.Item>

                          {[
                            ['is_dg', 'DG'],
                            ['is_overweight', 'OW'],
                            ['is_reefer', 'REEFER'],
                            ['is_soc', 'SOC'],
                          ].map((item, idx) => (
                            <Form.Item key={idx} noStyle shouldUpdate>
                              {({ getFieldValue, setFieldValue }) => (
                                <Form.Item>
                                  <Switch
                                    checked={getFieldValue([
                                      'containers',
                                      field.name,
                                      item[0],
                                    ])}
                                    onChange={(v) =>
                                      setFieldValue(
                                        ['containers', field.name, item[0]],
                                        v,
                                      )
                                    }
                                  />
                                  {item[1]}
                                </Form.Item>
                              )}
                            </Form.Item>
                          ))}

                          <MinusCircleOutlined onClick={() => remove(index)} />
                        </Space>
                      ))}
                    </>
                  );
                }}
              </Form.List>
            </Row>
          </Col>
        </Row>
        <Row gutter={24} className="mt-lg">
          <Form.List name="rates">
            {(fields, { add, remove }) => {
              return (
                <RatesTable
                  loading={loading}
                  dataSource={fields}
                  add={add}
                  form={form}
                  remove={remove}
                />
              );
            }}
          </Form.List>
        </Row>
        <Row gutter={24} className="mt-lg">
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldValue }) => (
              <Form.Item
                name="additional_charges"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <PossibleCharges
                  intermodal_region_id={getFieldValue('del_id') || 0}
                  data={getFieldValue('additional_charges') || []}
                  arrayName="additional_charges"
                  updateData={(list) => {
                    setFieldValue('additional_charges', list);
                  }}
                  extra={<></>}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Row>
        <Row gutter={24} className="mt-lg">
          <Form.Item
            name="internal_remark"
            label="Internal Remark"
            style={{ width: '100%' }}
          >
            <Input.TextArea autoSize={{ maxRows: 5 }}></Input.TextArea>
          </Form.Item>
        </Row>
        <Row gutter={24}>
          <Form.Item
            name="customer_remark"
            label="Customer Remark"
            style={{ width: '100%' }}
          >
            <Input.TextArea disabled autoSize={{ maxRows: 5 }}></Input.TextArea>
          </Form.Item>
        </Row>
        <Row gutter={24}>
          <Form.Item
            name="agent_remark"
            label="Agent Remark"
            style={{ width: '100%' }}
          >
            <Input.TextArea disabled autoSize={{ maxRows: 5 }}></Input.TextArea>
          </Form.Item>
        </Row>
        <Row gutter={24}>
          <Form.Item
            name="external_remark"
            label="External Remark"
            style={{ width: '100%' }}
          >
            <Input.TextArea autoSize={{ maxRows: 5 }}></Input.TextArea>
          </Form.Item>
        </Row>
      </Form>
      <LoadSpotTemplateModal
        handleOk={(item) => {
          form.resetFields();
          form.setFieldsValue(item?.value);
        }}
      />
      <SaveAsTemplateModal
        handleOk={(value) => {
          app.service
            .post('spotRateTemplates', {
              data: {
                value: form.getFieldsValue(true),
                ...value,
              },
            })
            .then(() => {
              message.success('success');
            })
            .catch((err) => {
              showErrorMessage(err);
            });
        }}
      />
    </Drawer>
  );
};

export default Index;
