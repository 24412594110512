import { Button, Drawer, message, Modal, Space } from 'antd';
import ShipmentForm from './ShipmentForm';
import {
  ShipmentBusinessStatus,
  ShipmentFromType,
  ShipmentTimeType,
} from '../constants';
import { FC, useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { ShipmentParams } from './QuoteForm';
import { isEqual, omit } from 'lodash';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { showErrorMessage } from '@/utils/show-error-message';

export interface ShipmentDrawerProps {
  visible: boolean;
  model?: any;
  params?: ShipmentParams | null;
  onSaved?: (close?: boolean) => void;
  onBooked?: (id: number) => void;
  onClose: () => void;
}

const ShipmentDrawer: FC<ShipmentDrawerProps> = ({
  visible,
  model,
  params,
  onSaved,
  onBooked,
  onClose,
}) => {
  const manualMode = !params;
  const id = model?.id;
  const rate = params?.rate;
  const [loading, setLoading] = useState(false);
  const app = useApp();
  const [form] = useForm();
  const [formInitialValues, setFormInitialValues] = useState<any>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`ftl/shipments/${id}`);
      // console.log(resp.data);

      const pickupWindowTimeData =
        resp.data.pickup_open_time && resp.data.pickup_close_time
          ? {
              pickup_open_time: moment(resp.data.pickup_open_time, 'HH:mm'),
              pickup_close_time: moment(resp.data.pickup_close_time, 'HH:mm'),
            }
          : {
              pickup_open_time: moment('09:00', 'HH:mm'),
              pickup_close_time: moment('21:00', 'HH:mm'),
            };

      const destinationWindowTimeData =
        resp.data.destination_open_time && resp.data.destination_close_time
          ? {
              destination_open_time: moment(
                resp.data.destination_open_time,
                'HH:mm',
              ),
              destination_close_time: moment(
                resp.data.destination_close_time,
                'HH:mm',
              ),
            }
          : {
              destination_open_time: moment('09:00', 'HH:mm'),
              destination_close_time: moment('21:00', 'HH:mm'),
            };

      const pickupSpecificTimeData = resp.data.pickup_specific_time
        ? {
            pickup_specific_time: moment(
              resp.data.pickup_specific_time,
              'HH:mm',
            ),
          }
        : {};

      const destinationSpecificTimeData = resp.data.destination_specific_time
        ? {
            destination_specific_time: moment(
              resp.data.destination_specific_time,
              'HH:mm',
            ),
          }
        : {};

      const data = {
        ...resp.data,
        pickup_accessorials: resp.data.pickup_accessorials ?? [],
        destination_accessorials: resp.data.destination_accessorials ?? [],
        ...pickupWindowTimeData,
        ...destinationWindowTimeData,
        ...pickupSpecificTimeData,
        ...destinationSpecificTimeData,
      };
      form.setFieldsValue({ ...data });
      // 读取表单数据并设置为初始值
      // 避免设置表单外的值，进而导致后续判断是否修改不准确
      setFormInitialValues({ ...form.getFieldsValue() });
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handelSave = async (close = true) => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    if (values.is_insurance_entrusted && !values.cargo_value) {
      message.error('Please enter cargo value');
      return;
    } else if (!values.is_insurance_entrusted) {
      delete values.cargo_value;
    }

    setLoading(true);

    if (
      values.pickup_time_type === ShipmentTimeType.WINDOW &&
      values.pickup_open_time &&
      values.pickup_close_time &&
      values.pickup_open_time >= values.pickup_close_time
    ) {
      message.error('Pickup open time must be before close time');
      setLoading(false);
      return;
    }

    if (
      values.destination_time_type === ShipmentTimeType.WINDOW &&
      values.destination_open_time &&
      values.destination_close_time &&
      values.destination_open_time >= values.destination_close_time
    ) {
      message.error('Destination open time must be before close time');
      setLoading(false);
      return;
    }

    const pickupOpemTimeData = values.pickup_open_time
      ? {
          pickup_open_time: moment(values.pickup_open_time).format('HH:mm'),
        }
      : {};

    const pickupCloseTimeData = values.pickup_close_time
      ? {
          pickup_close_time: moment(values.pickup_close_time).format('HH:mm'),
        }
      : {};

    const pickupSpecificTimeData = values.pickup_specific_time
      ? {
          pickup_specific_time: moment(values.pickup_specific_time).format(
            'HH:mm',
          ),
        }
      : {};

    const destinationOpenTimeData = values.destination_open_time
      ? {
          destination_open_time: moment(values.destination_open_time).format(
            'HH:mm',
          ),
        }
      : {};

    const destinationCloseTimeData = values.destination_close_time
      ? {
          destination_close_time: moment(values.destination_close_time).format(
            'HH:mm',
          ),
        }
      : {};

    const destinationSpecificTimeData = values.destination_specific_time
      ? {
          destination_specific_time: moment(
            values.destination_specific_time,
          ).format('HH:mm'),
        }
      : {};

    const timeData = {
      ...pickupOpemTimeData,
      ...pickupCloseTimeData,
      ...pickupSpecificTimeData,
      ...destinationOpenTimeData,
      ...destinationCloseTimeData,
      ...destinationSpecificTimeData,
    };

    const totalWeight =
      values.weight_unit === 'kg'
        ? Math.round(values.total_weight / 0.453592)
        : values.total_weight;
    try {
      if (id) {
        const data = {
          ...values,
          ...timeData,
          total_weight: totalWeight,
        };
        await app.service.patch(`ftl/shipments/${id}`, {
          data,
        });
      } else {
        const addtion = manualMode
          ? {
              from_type: ShipmentFromType.MANUAL,
            }
          : {
              from_type: ShipmentFromType.QUOTE,
              quote_id: rate?.quote_id,
              rate_id: rate?.id,
              vendor_rate_id: rate?.vendor_rate_id,
            };
        await app.service.post('ftl/shipments', {
          data: {
            ...(manualMode ? values : omit(values, ['quote_rate'])),
            ...addtion,
            ...timeData,
            total_weight: totalWeight,
          },
        });
      }

      onSaved && onSaved(close);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmBook = async () => {
    setLoading(true);
    try {
      await app.service.patch(`ftl/shipments/${id}/confirm-book`);
      fetchData();
      onBooked && onBooked(id);
      message.success('Shipment has been booked successfully');
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };
  const handleBook = () => {
    const isFormChanged = !isEqual(formInitialValues, form.getFieldsValue());
    if (!isFormChanged) {
      handleConfirmBook();
    } else {
      Modal.confirm({
        content:
          'The form has been changed. Are you sure you want to save and book?',
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          handelSave(false).then(() => handleConfirmBook());
        },
      });
    }
  };

  useEffect(() => {
    // 1. 设置 model 并打开 drawer，visible 为 truthy => 触发 useEffect 一次，设置 form 数据
    // 2. 关闭 drawer，visible 为 falsy =>
    //    resetFields 后，model / id 仍然有值，
    //    if block 外后续逻辑会继续执行，设置 form 数据 =>
    //    数据回显错误

    if (!visible) {
      form.resetFields();
      // 3. 以下 return 可以阻止后续逻辑执行，避免数据设置错误
      return;
    }

    if (id) {
      fetchData();
    } else {
      form.setFieldsValue({
        is_insurance_entrusted: false,
      });
      // 读取表单数据并设置为初始值
      // 避免设置表单外的值，进而导致后续判断是否修改不准确
      setFormInitialValues({ ...form.getFieldsValue() });
    }
  }, [id, visible]);

  return (
    <Drawer
      title={model?.uid ? `Shipment ${model?.uid}` : 'New Shipment'}
      placement="right"
      width="90%"
      onClose={() => {
        onClose();
      }}
      destroyOnClose={true}
      open={visible}
      extra={
        manualMode ? (
          <Space>
            <Button
              type="primary"
              loading={loading}
              onClick={() => handelSave()}
            >
              Save
            </Button>
            {!model?.vendor_shipment_id &&
              model?.business_status === ShipmentBusinessStatus.CONFIRMING &&
              (model?.from_type === ShipmentFromType.QUOTE ||
                model?.from_type === ShipmentFromType.CLIENT_QUOTE) && (
                <Button
                  loading={loading}
                  type="primary"
                  onClick={() => model && handleBook()}
                >
                  Book
                </Button>
              )}
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
          </Space>
        ) : (
          <Space>
            <Button
              type="primary"
              loading={loading}
              onClick={() => handelSave()}
            >
              Save
            </Button>
            <Button onClick={onClose}>Close</Button>
          </Space>
        )
      }
    >
      <ShipmentForm
        loading={loading}
        form={form}
        params={params}
        model={model}
      />
    </Drawer>
  );
};

export default ShipmentDrawer;
