import { Button, message } from 'antd';
import { useApp } from '@/utils/useapp';
import { useState } from 'react';
import React from 'react';
import RatingDrawer from '@/components/Rating/RatingDrawer';
import { ModelTarget } from '@/components/Rating/Interfaces/ModelType';
import { ResponseTarget } from '@/components/Rating/Interfaces/RepsonseType';
import { CFSCarrierInterface } from './Interfaces/CFSCarrierInterface';
import { showErrorMessage } from '@/utils/show-error-message';

const RatingButton: React.FC<{
  carrier: CFSCarrierInterface;
  onSaved: () => void;
}> = ({ carrier, onSaved }) => {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState<any>();

  const fetchResponse = async () => {
    if (!carrier.id) {
      return;
    }

    try {
      const resp = await app.service.get(
        `ratings/${ModelTarget.CfsCarrier}/${carrier.id}/fetchRating`,
        {
          params: {
            responsible_id: carrier.id,
            responsible_target: ResponseTarget.CfsCarrier,
          },
        },
      );

      setRating(resp.data);
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  const handleOpen = async () => {
    await fetchResponse();
    setOpen(true);
  };

  const handleCloseOpen = () => {
    setOpen(false);
  };

  const handleOnSaved = () => {
    setOpen(false);
    onSaved();
  };

  return (
    <>
      <Button type="link" onClick={handleOpen}>
        Rating
      </Button>
      {open && carrier && carrier.id && (
        <RatingDrawer
          modelId={carrier.id}
          model={carrier}
          modelTarget={ModelTarget.CfsCarrier}
          responsibleTarget={ResponseTarget.CfsCarrier}
          responsible={carrier}
          ratingId={rating?.id}
          onSaved={handleOnSaved}
          onClose={handleCloseOpen}
          open={open}
        />
      )}
    </>
  );
};

export default RatingButton;
