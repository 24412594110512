import React from 'react';
import { Button, Form, Input, Space, Table, Modal } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { ContactInfo } from '@/components/Tasks/Interfaces/ContactInfoInterface';
import { noop } from 'lodash';

interface ContactManagerProps {
  contacts: ContactInfo[];
  onChange: (contacts: ContactInfo[]) => void;
  readOnly?: boolean;
}

const ContactManager: React.FC<ContactManagerProps> = ({
  contacts,
  onChange,
  readOnly = false,
}) => {
  const [contactModalVisible, setContactModalVisible] = React.useState(false);
  const [editingContactIndex, setEditingContactIndex] = React.useState<
    number | null
  >(null);
  const [contactForm] = Form.useForm();

  const handleAddContact = () => {
    if (readOnly) {
      return;
    }
    contactForm.resetFields();
    setEditingContactIndex(null);
    setContactModalVisible(true);
  };

  const handleEditContact = (index: number) => {
    if (readOnly) {
      return;
    }
    const contact = contacts[index];
    contactForm.setFieldsValue(contact);
    setEditingContactIndex(index);
    setContactModalVisible(true);
  };

  const handleContactSave = () => {
    contactForm
      .validateFields()
      .then((values) => {
        if (editingContactIndex !== null) {
          // Update existing contact
          const newContacts = [...contacts];
          newContacts[editingContactIndex] = values;
          onChange(newContacts);
        } else {
          // Add new contact
          onChange([...contacts, values]);
        }
        setContactModalVisible(false);
        contactForm.resetFields();
      })
      .catch(noop);
  };

  const handleDeleteContact = (index: number) => {
    if (readOnly) {
      return;
    }
    const newContacts = [...contacts];
    newContacts.splice(index, 1);
    onChange(newContacts);
  };

  const baseColumns: ColumnType<ContactInfo>[] = [
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email Address / WeChat',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const actionColumn: ColumnType<ContactInfo> = {
    title: 'Action',
    key: 'action',
    render: (_: any, __: any, index: number) => (
      <Space>
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={() => handleEditContact(index)}
        >
          Edit
        </Button>
        <Button
          type="text"
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteContact(index)}
        >
          Delete
        </Button>
      </Space>
    ),
  };

  const contactColumns: ColumnType<ContactInfo>[] = readOnly
    ? baseColumns
    : [...baseColumns, actionColumn];

  return (
    <>
      {!readOnly && (
        <Button
          type="dashed"
          onClick={handleAddContact}
          style={{ marginBottom: 16 }}
          icon={<PlusOutlined />}
        >
          Add Contact
        </Button>
      )}

      {contacts.length > 0 && (
        <Table
          dataSource={contacts}
          columns={contactColumns}
          pagination={false}
          rowKey={(record, index) => index?.toString() || '0'}
          size="small"
        />
      )}

      {/* Contact Modal */}
      <Modal
        title={
          editingContactIndex !== null
            ? 'Edit Contact Information'
            : 'Add Contact Information'
        }
        open={contactModalVisible}
        onOk={handleContactSave}
        onCancel={() => setContactModalVisible(false)}
        destroyOnClose
      >
        <Form form={contactForm} layout="vertical">
          <Form.Item label="Position" name="position">
            <Input placeholder="Position" />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input name' }]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Email Address / WeChat"
            name="email"
            rules={[
              { required: true, message: 'Please input email or WeChat' },
            ]}
          >
            <Input placeholder="Email Address / WeChat" />
          </Form.Item>
          <Form.Item label="Phone" name="phone">
            <Input placeholder="Phone" />
          </Form.Item>
          <Form.Item label="Remark" name="remark">
            <Input.TextArea rows={4} placeholder="Remark" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ContactManager;
