import { Modal, Space, Empty, message } from 'antd';
import React, { useMemo } from 'react';
import { RateLink } from '@/components/Rate/RateLink';
import { THasBanModifyFields } from '@/components/Rate/data';
import { SnapshotCardContent } from './SnapshotCardContent';
import { useApp } from '@/utils/useapp';
import { showErrorMessage } from '@/utils/show-error-message';

export const SnashotRateModal: React.FC<
  {
    rateUid?: string;
    snapshotableId: number;
    snapshotableType: string;
    open: boolean;
    onClose: () => void;
  } & THasBanModifyFields
> = ({
  rateUid,
  snapshotableId,
  snapshotableType,
  open,
  hasBanModifyFields,
  onClose,
}) => {
  const app = useApp();
  const handleCancel = () => {
    onClose();
  };

  const [sellRate, setSellRate] = React.useState<any>(null);

  // if rateUid is present and its prefix is S, then fetch the sell rate

  React.useEffect(() => {
    const fechSellRate = async () => {
      if (rateUid && rateUid.startsWith('S')) {
        // remove S from rateUid
        const sid = rateUid.slice(1);

        try {
          const resp = await app.service.get(`sellRates/${sid}`);
          setSellRate(resp.data);
        } catch (e: any) {
          showErrorMessage(e);
        }
      }
    };

    fechSellRate();
  }, [rateUid]);

  return (
    <>
      <Modal
        title={
          <Space>
            <span>Snapshot Rate</span>

            {sellRate && (
              <div>
                Terminal:{' '}
                {sellRate.terminals && sellRate.terminals.length > 0
                  ? sellRate.terminals.map((t) => `${t.firms}|${t.name}`)
                  : 'All'}
              </div>
            )}

            {rateUid && (
              <RateLink
                rateUid={rateUid}
                label={'View Current Rate'}
                showLabel={true}
                hasBanModifyFields={hasBanModifyFields}
              />
            )}
          </Space>
        }
        open={open}
        onCancel={handleCancel}
        style={{ maxWidth: '70%', minWidth: '50%' }}
        footer={
          <Space className="w100" direction="vertical" align="end"></Space>
        }
      >
        {snapshotableId && snapshotableType ? (
          <SnapshotCardContent
            snapshotableId={snapshotableId}
            snapshotableType={snapshotableType}
          />
        ) : (
          <Empty description={'No Snapshot'} />
        )}
      </Modal>
    </>
  );
};
