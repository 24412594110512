import { TSpotRate } from '@/types';
import { showErrorMessage } from '@/utils/show-error-message';
import { useApp } from '@/utils/useapp';
import { message, Modal, ModalProps, Select } from 'antd';
import { useEffect, useState } from 'react';

type TSpotTemplate = {
  created_at: string;
  description: string;
  id: number;
  name: string;
  updated_at: string;
  value: TSpotRate;
};

type TModal = {
  handleOk?: (item: TSpotTemplate | undefined) => void;
  handleCancel?: (item: TSpotTemplate | undefined) => void;
};

const useLoadSpotTemplateModal = () => {
  const [_visible, _setVisible] = useState(false);

  const openLoadSpotTemplateModal = () => _setVisible(true);

  const LoadSpotTemplateModal = (props: ModalProps & TModal) => {
    const { visible, handleOk, handleCancel } = props;
    const [options, setOptions] = useState<TSpotTemplate[]>([]);
    const [id, setId] = useState();
    const app = useApp();

    const _handleOk = (id: number | undefined) => {
      const item = options.find((i) => i.id === id);
      handleOk && handleOk(item);
      _setVisible(false);
    };

    const _handleCancel = (id: number | undefined) => {
      const item = options.find((i) => i.id === id);
      handleCancel && handleCancel(item);
      _setVisible(false);
    };

    useEffect(() => {
      app.service
        .get('spotRateTemplates?all=true')
        .then(({ data }) => setOptions(data))
        .catch(showErrorMessage);
    }, []);

    return (
      <Modal
        title="Load SpotRate Template"
        open={visible ?? _visible}
        okText="Load"
        onOk={() => _handleOk(id)}
        onCancel={() => _handleCancel(id)}
      >
        <Select
          value={id}
          onSelect={setId}
          style={{ width: '100%' }}
          showSearch
        >
          {options.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              <div>{item.name}</div>
              <div>{item.description}</div>
            </Select.Option>
          ))}
        </Select>
      </Modal>
    );
  };

  return { openLoadSpotTemplateModal, LoadSpotTemplateModal };
};

export default useLoadSpotTemplateModal;
