import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  TablePaginationConfig,
  message,
  Space,
  Popconfirm,
  Table,
  Tag,
  Dropdown,
  Menu,
  MenuProps,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import { TEmailTemplate, TCollection } from '@/types';
import { get, truncate, findKey } from 'lodash';
import { EmailTemlateDrawer } from '@/components/EmailTemplateDrawer';
import {
  BELONGABLE_TYPE_MAP,
  BELONG_MODEL_REQUESTED_RATE,
  BELONG_MODEL_WAREHOUSE,
} from './components/data';
import usePagination from '@/components/usePagination';
import AutoResizeTable from '@/components/AutoResizeTable';
import { Filter } from './components/Filter';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [title, setTitle] = React.useState('Create EmailTemplate');
  const [data, setData] = React.useState<TCollection<TEmailTemplate>>();
  const [target, setTarget] = React.useState(BELONG_MODEL_REQUESTED_RATE);
  const [form] = useForm();
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('emailTemplates', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        showErrorMessage(e);
      }

      setLoading(false);
    },
    [],
  );

  const handleRemove = async (id: number) => {
    setLoading(true);

    try {
      await app.service.delete(`emailTemplates/${id}`);

      message.success('Delete');

      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(true);
  };

  const handleEdit = async (emailTemplate: TEmailTemplate) => {
    setLoading(true);

    try {
      setTitle('Edit EmailTemplate');
      form.resetFields();
      await form.setFieldsValue(emailTemplate);
      setTarget(
        BELONGABLE_TYPE_MAP[
          (emailTemplate.belongable_type as unknown) as keyof typeof BELONGABLE_TYPE_MAP
        ],
      );
      setVisible(true);
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        width: 150,
        render: (text, record) =>
          truncate(get(record, 'subject', ''), {
            length: 100,
          }),
      },
      {
        title: 'body',
        dataIndex: 'body',
        key: 'body',
        width: 800,
        render: (text, record) =>
          truncate(get(record, 'body_str', ''), {
            length: 200,
          }),
      },
      {
        title: 'Belong',
        dataIndex: 'belongable_type',
        key: 'belongable_type',
        width: 150,
        render: (text, record) =>
          record.belongable_type.replace('App\\Models\\', ''),
      },
      {
        title: 'Creator',
        dataIndex: ['creator', 'name'],
        key: 'creator',
        width: 150,
      },
      {
        title: 'Enabled',
        dataIndex: 'enabled',
        key: 'enabled',
        width: 200,
        render: (text, record) => {
          const enabled = get(record, 'enabled');
          if (true === enabled) {
            return <Tag color="success">{'YES'}</Tag>;
          } else {
            return <Tag color="red">{'NO'}</Tag>;
          }
        },
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            <Popconfirm
              placement="left"
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}
            >
              <a>Delete</a>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [],
  );

  const onMenuClick: MenuProps['onClick'] = ({ key }) => {
    form.resetFields();
    form.setFieldsValue({
      enabled: true,
      belongable_id: 0,
    });
    setTarget(key);
    setTitle('Create EmailTemplate');
    setVisible(true);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Requested Rate',
      key: BELONG_MODEL_REQUESTED_RATE,
      onClick: onMenuClick,
    },
    {
      label: 'Warehouse',
      key: BELONG_MODEL_WAREHOUSE,
      onClick: onMenuClick,
    },
  ];
  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="EmailTemplates"
        rightElement={
          <div>
            <Dropdown menu={{ items }}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>New EmailTemplate</Space>
              </a>
            </Dropdown>
          </div>
        }
      ></Header>
      <Filter filter={filter} loading={loading} onSearch={fetchData} />

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      {visible && (
        <EmailTemlateDrawer
          target={target}
          targetId={form.getFieldValue('belongable_id')}
          form={form}
          title={title}
          visible={visible}
          onSaved={fetchData}
          setVisible={(value) => setVisible(value)}
        />
      )}
    </div>
  );
};

export default Index;
