import React, { useState } from 'react';
import {
  Form,
  FormInstance,
  Input,
  Select,
  DatePicker,
  Space,
  Button,
  message,
} from 'antd';
import { BillingCodeSelect } from '@/components/BillingCodeSelect';
import moment from 'moment';
import { DocumentsStoreLocalFormItem } from '@/components/Upload/DocumentsStoreLocalFormItem';
import { Documents } from '@/components/Upload/Documents';
import Decimal from 'decimal.js';
import { useApp } from '@/utils/useapp';
import { showErrorMessage } from '@/utils/show-error-message';
interface Props {
  container: any;
  form: FormInstance;
  disabled: boolean;
}

export const ExtraCostForm: React.FC<Props> = ({
  container,
  form,
  disabled,
}) => {
  const [isImporting, setIsImporting] = useState(false);

  const app = useApp();

  const handleImportAmountFromBuyRate = async () => {
    const billingCode = form.getFieldValue('billing_code');
    if (!billingCode) {
      message.error('Billing code is Required');
      return false;
    }
    setIsImporting(true);
    try {
      const resp = await app.service.get(
        `containers/${container.id}/extraCosts/importAmount`,
        {
          params: {
            billing_code: billingCode.code,
          },
        },
      );

      if (resp.amount) {
        form.setFieldsValue({
          amount: resp.amount,
        });
        message.success('Import Success');
      }
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setIsImporting(false);
    }
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.amount || changedValues.qty) {
      if (allValues.amount && allValues.qty) {
        const amountTotal = new Decimal(allValues.amount).mul(allValues.qty);
        form.setFieldValue('amount_total', amountTotal.toString());
      }
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        disabled={disabled}
        onValuesChange={handleValuesChange}
        initialValues={{
          qty: 1,
        }}
      >
        <Form.Item name="id" noStyle>
          <Input hidden />
        </Form.Item>
        <Form.Item name="container_id" noStyle>
          <Input hidden />
        </Form.Item>
        <Form.Item
          required
          label="Billing Code"
          rules={[{ required: true, message: 'Billing Code' }]}
          shouldUpdate
        >
          {({ getFieldValue, setFieldsValue }) => (
            <>
              <Form.Item name="billing_code" hidden />
              <Form.Item
                noStyle
                // hasFeedback
                rules={[{ required: true, message: '' }]}
              >
                <BillingCodeSelect
                  value={getFieldValue('billing_code')?.code}
                  category="DRAY"
                  style={{ width: '100%' }}
                  onSelect={(c: any) => {
                    setFieldsValue({
                      billing_code: c,
                    });
                  }}
                />
              </Form.Item>
            </>
          )}
        </Form.Item>
        {/* <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: 'Type is required' }]}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) => {
              return (
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              );
            }}>
            <Select.Option value=""> </Select.Option>
            {Object.keys(CONTAINER_EXTRA_COST_TYPE_MAP).map((type) => (
              <Select.Option key={type} value={+type}>
                {
                  CONTAINER_EXTRA_COST_TYPE_MAP[
                    type as unknown as keyof typeof CONTAINER_EXTRA_COST_TYPE_MAP
                  ]
                }
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item
          name="amount"
          label={
            <Space>
              Amount
              {!!container.id && (
                <Button
                  type="primary"
                  size="small"
                  ghost
                  disabled={disabled || isImporting}
                  onClick={handleImportAmountFromBuyRate}
                >
                  Import From Buy Rate
                </Button>
              )}
            </Space>
          }
          rules={[{ required: true, message: 'Amount is required' }]}
        >
          <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
          name="qty"
          label={<Space>Qty</Space>}
          rules={[{ required: true, message: 'Qty is required' }]}
        >
          <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
          name="amount_total"
          label={<Space>Amount Total</Space>}
          rules={[{ required: true, message: 'Amount Total is required' }]}
        >
          <Input disabled placeholder="input placeholder" />
        </Form.Item>
        <Form.Item name="start_at" noStyle rules={[{ required: true }]}>
          <Input hidden />
        </Form.Item>
        <Form.Item label="Start at" shouldUpdate rules={[{ required: true }]}>
          {({ getFieldValue, setFieldValue }) => {
            const time = getFieldValue('start_at');
            return (
              <DatePicker
                className="w100"
                value={time ? moment(time) : null}
                onChange={(date, dateString) => {
                  setFieldValue('start_at', dateString);
                }}
              />
            );
          }}
        </Form.Item>

        <Form.Item name="end_at" noStyle rules={[{ required: true }]}>
          <Input hidden />
        </Form.Item>
        <Form.Item label="End at" shouldUpdate rules={[{ required: true }]}>
          {({ getFieldValue, setFieldValue }) => {
            const time = getFieldValue('end_at');
            return (
              <DatePicker
                className="w100"
                value={time ? moment(time) : null}
                onChange={(date, dateString) => {
                  setFieldValue('end_at', dateString);
                }}
              />
            );
          }}
        </Form.Item>

        <Form.Item label="Reason" name={'reason'} rules={[{ required: true }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Front Link"
          name={'front_link'}
          rules={[{ required: false }]}
        >
          <Input placeholder="Front Link" />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue('id') ? (
              <Documents
                title="Documents"
                disabled={disabled}
                target="container_extra_cost"
                targetId={getFieldValue('id')}
              />
            ) : (
              <DocumentsStoreLocalFormItem form={form} />
            )
          }
        </Form.Item>
      </Form>
    </>
  );
};
