import { useApp } from '@/utils/useapp';
import { List, Button, Card, Row, Col, message, Space } from 'antd';
import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';

import { FormInstance } from 'antd/es/form';
import auth from '@/utils/auth';
import { SubTabInterface } from '..';
import { showErrorMessage } from '@/utils/show-error-message';

const TASK_WARNING_OUTGATE_DAYS_EQ_4 = 'outgate_days_eq_4';
const TASK_WARNING_OUTGATE_DAYS_EQ_5 = 'outgate_days_eq_5';
const TASK_WARNING_OUTGATE_DAYS_BETWEEN_6_AND_9 =
  'outgate_days_between_6_and_9';
const TASK_WARNING_OUTGATE_DAYS_BETWEEN_10_AND_13 =
  'outgate_days_between_10_and_13';
const TASK_WARNING_OUTGATE_DAYS_GT_14 = 'outgate_days_gt_14';

export type OpDataItem = {
  task: string;
  task_id: number;
  whose_task: number;
  intend_count: number;
  intend_container_ids: Array<number>;
  overdue_count: number;
  overdue_container_ids: Array<number>;
};

export const TaskWarning: React.FC<
  {
    isTaskTab: boolean;
    goToTaskWarningDispatch: (warningType: string) => void;
  } & SubTabInterface
> = ({
  activeOpdashboardTab,
  isTaskTab = false,
  refresh = true,
  setRefresh,
  goToTaskWarningDispatch,
}) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [counts, setCounts] = React.useState({
    outgate_days_equal_to_4: 0,
    outgate_days_equal_to_5: 0,
    outgate_days_between_6_and_9: 0,
    outgate_days_between_10_and_13: 0,
    outgate_days_more_than_14: 0,
  });

  const fetchCounts = async () => {
    if (!activeOpdashboardTab?.id || loading) {
      return;
    }

    setLoading(true);

    try {
      const resp = await app.service.get(
        `opdashboard/taskWarning/${activeOpdashboardTab.id}`,
        {
          params: {
            opdashboard_tab_id: activeOpdashboardTab.id,
          },
        },
      );

      setCounts(resp.data);
      refresh && setRefresh(false);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const goDispatchDetail = (warningType: string) => {
    goToTaskWarningDispatch(warningType);
  };

  useEffect(() => {
    if (activeOpdashboardTab?.id && isTaskTab) {
      fetchCounts();
    }
  }, [isTaskTab, activeOpdashboardTab]);

  useEffect(() => {
    if (activeOpdashboardTab?.id && isTaskTab && refresh) {
      fetchCounts();
    }
  }, [refresh]);

  return useObserver(() => {
    return (
      <Card
        title="Task Warning"
        loading={loading}
        bodyStyle={{ padding: '16px' }}
        // style={{ height: '60vh', overflow: 'scroll' }}
      >
        <List>
          {counts.outgate_days_equal_to_4 > 0 && (
            <List.Item>
              <div> Outgate days equal to 4</div>
              <div>
                <Button
                  type="link"
                  onClick={() =>
                    goDispatchDetail(TASK_WARNING_OUTGATE_DAYS_EQ_4)
                  }
                >
                  {counts.outgate_days_equal_to_4}
                </Button>
              </div>
            </List.Item>
          )}
          {counts.outgate_days_equal_to_5 > 0 && (
            <List.Item>
              <div> Outgate days equal to 5</div>
              <div>
                <Button
                  type="link"
                  onClick={() =>
                    goDispatchDetail(TASK_WARNING_OUTGATE_DAYS_EQ_5)
                  }
                >
                  {counts.outgate_days_equal_to_5}
                </Button>
              </div>
            </List.Item>
          )}
          {counts.outgate_days_between_6_and_9 > 0 && (
            <List.Item>
              <div>Outgate days between 6 and 9</div>
              <div>
                <Button
                  type="link"
                  onClick={() =>
                    goDispatchDetail(TASK_WARNING_OUTGATE_DAYS_BETWEEN_6_AND_9)
                  }
                >
                  {counts.outgate_days_between_6_and_9}
                </Button>
              </div>
            </List.Item>
          )}
          {counts.outgate_days_between_10_and_13 > 0 && (
            <List.Item>
              <div> Outgate days between 10 and 13</div>
              <div>
                <Button
                  type="link"
                  onClick={() =>
                    goDispatchDetail(
                      TASK_WARNING_OUTGATE_DAYS_BETWEEN_10_AND_13,
                    )
                  }
                >
                  {counts.outgate_days_between_10_and_13}
                </Button>
              </div>
            </List.Item>
          )}
          {counts.outgate_days_more_than_14 > 0 && (
            <List.Item>
              <div> Outgate days more than 14</div>
              <div>
                <Button
                  type="link"
                  onClick={() =>
                    goDispatchDetail(TASK_WARNING_OUTGATE_DAYS_GT_14)
                  }
                >
                  {counts.outgate_days_more_than_14}
                </Button>
              </div>
            </List.Item>
          )}
        </List>
      </Card>
    );
  });
};
