import { useApp } from '@/utils/useapp';
import React, { useEffect, useState } from 'react';
import { ModelType } from './Interfaces/ModelType';
import { ResponseTarget, ResponseType } from './Interfaces/RepsonseType';
import { showErrorMessage } from '@/utils/show-error-message';

const useRating = () => {
  const app = useApp();
  const [loading, setLoading] = useState(false);

  const fetchQuestions = async ({
    modelId,
    modelTarget,
    responsibleTarget,
  }: ModelType & ResponseType) => {
    console.log('modelId', modelId);
    console.log('modelTarget', modelTarget);
    console.log('responsibleTarget', responsibleTarget);

    setLoading(true);

    const params = {
      responsible_target: responsibleTarget,
    };

    try {
      const result = await app.service.get(
        `ratings/${modelTarget}/${modelId}/questions`,
        { params },
      );
      return result.data;
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchQuestions,
  };
};
export default useRating;
