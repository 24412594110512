import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message, Row, Col, Divider, Card } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import React, { useEffect, useMemo } from 'react';
import { VendorContractForm } from './VendorContractForm';
import { serialize } from 'object-to-formdata';
import { get } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';

interface TProps {
  id: number;
  visible: boolean;
  mc_number?: string;
  onClose: () => void;
  onSaved?: (data: any) => void;
}

export const VendorContractDrawer: React.FC<TProps> = ({
  id,
  visible,
  mc_number,
  onClose,
  onSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();
  const title = useMemo(() => `${!id ? 'Create' : 'Edit'} Vendor Contract`, [
    id,
  ]);

  const fetchData = async (id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.get(`vendorContracts/${id}`);
      form.setFieldsValue({
        ...resp.data,
      });
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    fetchData(id);
  }, [id]);

  useEffect(() => {
    !id &&
      mc_number &&
      !form.getFieldValue('mc_number') &&
      form.setFieldValue('mc_number', mc_number);
  }, [mc_number, id]);

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);

    const config = {
      data: serialize(
        {
          ...values,
        },
        { indices: true, nullsAsUndefineds: true },
      ),
      requestType: 'form',
    };

    try {
      const resp = !values.id
        ? await app.service.post('vendorContracts', config)
        : await app.service.put(`vendorContracts/${values.id}`, {
            data: values,
          });
      message.success('Saved');
      onSaved && onSaved(resp.data);
      handleClose();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };
  return (
    <Drawer
      title={title}
      width={'65%'}
      onClose={handleClose}
      destroyOnClose={true}
      open={visible}
      extra={
        <Space>
          <Button onClick={handleClose}>Close</Button>
          <Button type="primary" loading={loading} onClick={handleSave}>
            Save
          </Button>
        </Space>
      }
    >
      <VendorContractForm form={form} />
    </Drawer>
  );
};
