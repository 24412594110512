import { useCallback, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { TRoute } from '@/types';
import AutoResizeTable from '@/components/AutoResizeTable';
import { Filter } from './components/Filter';
import { get } from 'lodash';
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Popover,
  Space,
} from 'antd';
import ReactECharts from 'echarts-for-react';
import UpdateForSpecificCustomer from './components/UpdateForSpecificCustomer';
import { showErrorMessage } from '@/utils/show-error-message';

const Index = () => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TRoute>();
  const [filter] = useForm();
  const [form] = useForm();

  const intermodalRegionId = useWatch(['intermodal_region_id'], filter);
  const city = useWatch(['to_city'], filter);

  const fetchData = useCallback(async ({ update = false } = {}) => {
    const _filter = Object.assign([], filter.getFieldsValue());
    const to_city_id = get(_filter, 'to_city.id', 0);
    if (to_city_id) {
      _filter.to_city_id = get(_filter, 'to_city.id', 0);
      delete _filter.to_city;
    }

    setLoading(true);

    try {
      const result = await app.service.get('rateEngine/routes', {
        params: {
          ..._filter,
          update,
        },
      });

      setData(result.data);

      form.setFieldsValue({
        base_rate: result?.data?.final_rate,
        toll_fee: result?.data?.toll_fee,
      });
    } catch (e: any) {
      showErrorMessage(e);
    }

    setLoading(false);
  }, []);

  const updateRateForThisRoute = () => {
    fetchData({ update: true });
  };

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'From',
        dataIndex: ['ir_latitude', 'ir_longitude'],
        key: 'ir_longitude',
        width: 200,
        render: (text, record: any) => (
          <>
            {get(record, 'ir_latitude', '')},{get(record, 'ir_longitude', '')}
          </>
        ),
      },
      {
        title: 'To',
        dataIndex: ['city_latitude', 'city_longitude'],
        key: 'city_longitude',
        width: 200,
        render: (text, record: any) => (
          <>
            {get(record, 'city_latitude', '')},
            {get(record, 'city_longitude', '')}
          </>
        ),
      },
      {
        title: 'Distance',
        dataIndex: 'distance',
        key: 'distance',
        width: 200,
        render: (text, record: any) =>
          get(record, 'distance', '') && get(record, 'distance', '') + ' miles',
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        width: 200,
      },
      {
        title: 'Lowest Rate(Base + Fuel)',
        dataIndex: 'lowest_rate',
        key: 'lowest_rate',
        width: 200,
      },
      {
        title: 'Median Rate(Base + Fuel)',
        dataIndex: 'midpoint_rate',
        key: 'midpoint_rate',
        width: 200,
      },
      {
        title: 'Highest Rate(Base + Fuel)',
        dataIndex: 'highest_rate',
        key: 'highest_rate',
        width: 200,
      },
      {
        title: 'Average Rate(Base + Fuel)',
        dataIndex: 'average_rate',
        key: 'average_rate',
        width: 200,
      },
    ],
    [],
  );

  const chartOption = useMemo(() => {
    return {
      xAxis: {
        type: 'value',
        name: 'Distance / Miles',
      },
      yAxis: {
        type: 'value',
        name: 'Price / Dollar',
      },
      tooltip: {
        formatter: 'Miles, Rate: {c}',
      },
      series: [
        {
          symbolSize: 5,
          data: data ? data.points : [],
          type: 'scatter',
        },
      ],
    };
  }, [data]);

  const handleSearch = () => {
    fetchData();
  };

  return (
    <div className={styles.main}>
      <Header title="Statistics Routes"></Header>
      <Filter loading={loading} filter={filter} onSearch={handleSearch}>
        {' '}
      </Filter>

      <AutoResizeTable
        loading={loading}
        pagination={false}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data ? [data] : []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      {!!data && (
        <>
          <div className="mt-lg p-md">
            <div>
              <strong>Rate Predict:</strong>
            </div>
            <pre>{get(data, 'automation')}</pre>
            <div>
              <Space>
                <Button onClick={updateRateForThisRoute}>
                  Update rate for all customers
                </Button>
                <UpdateForSpecificCustomer
                  baseRate={data.final_rate}
                  tollFee={data.toll_fee}
                  intermodalRegionId={intermodalRegionId}
                  city={city}
                />
              </Space>
            </div>
            {!!data.rates && data.rates.length > 0 && (
              <div className="mt-lg">
                {data.rates.map((rate) => (
                  <p key={rate.uid}>{rate.uid} is just updated successfully.</p>
                ))}
              </div>
            )}
          </div>
        </>
      )}

      <div className="mt-lg">
        {data && data.points && data.points.length > 0 && (
          <ReactECharts option={chartOption} style={{ height: 600 }} />
        )}
      </div>
    </div>
  );
};

export default Index;
