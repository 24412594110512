import { useEffect, useState, useCallback } from 'react';
import ShipmentsTable from '.';
import { useApp } from '@/utils/useapp';
import { message } from 'antd';
import ShipmentDrawer from '../../components/ShipmentDrawer';
import { showErrorMessage } from '@/utils/show-error-message';

interface IShipmentTableInstance {
  ids: number[];
  tabId: number;
}

const ShipmentTableInstance = ({ ids, tabId }: IShipmentTableInstance) => {
  const [data, setData] = useState<any>({});
  const [shipformVisible, setShipformVisible] = useState(false);
  const [model, setModel] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const app = useApp();

  const refreshLtlShipment = async (id: number) => {
    if (!data || !id) {
      return;
    }
    const _shipment = await app.service.get(`tl/ltl/shipments/${id}`);

    const collection = data.data.map((item: any) => {
      if (item.id === _shipment.data.id) {
        return _shipment.data;
      }
      return item;
    });
    setData({ ...data, data: collection });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('tl/ltl/shipments', {
        params: {
          ids,
          page: 1,
          per_page: 1000,
        },
      });
      setData(resp);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePatchUpdate = async (
    shipmentId: number,
    model: string,
    values: any,
  ) => {
    setLoading(true);
    try {
      await app.service.patch(`tl/ltl/${model}/${shipmentId}`, {
        data: values,
      });

      fetchData();
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleShow = (m: any) => {
    setModel(m);
    setShipformVisible(true);
  };

  const handleVerificationChange = useCallback(
    async (shipmentId: number, key: string, checked: boolean) => {
      setLoading(true);
      try {
        const response = await app.service.patch(
          `tl/ltl/shipments/${shipmentId}/verify`,
          {
            data: {
              key,
              verified: checked,
            },
          },
        );
        await refreshLtlShipment(shipmentId);
        message.success('Verification status updated successfully');
      } catch (err: any) {
        console.error('Verification update error:', err);
        showErrorMessage(err, {
          fallback: 'Failed to update verification status',
        });
      } finally {
        setLoading(false);
      }
    },
    [app.service, refreshLtlShipment],
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ShipmentsTable
        loading={loading}
        data={data}
        fetchData={fetchData}
        pagination={false}
        columns={undefined}
        handleTableChange={fetchData}
        handleShow={handleShow}
        handlePatchUpdate={handlePatchUpdate}
        refreshLtlShipment={refreshLtlShipment}
        onVerificationChange={handleVerificationChange}
        tabId={tabId}
        openId={0}
        onSetSelected={undefined}
        setDefaultColumns={undefined}
      />
      <ShipmentDrawer
        visible={shipformVisible}
        // setVisible={setShipformVisible}
        model={model}
        // onCreated={() => {
        //   fetchData();
        //   setShipformVisible(false);
        // }}
        onClose={() => {
          fetchData();
          setShipformVisible(false);
        }}
      />
    </>
  );
};

export default ShipmentTableInstance;
