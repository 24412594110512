import { useApp } from '@/utils/useapp';
import { message, Modal } from 'antd';
import React from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import update from 'immutability-helper';

import { get, has } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';

const SORT_MAP = {
  ascend: 'asc',
  descend: 'desc',
};

const useDispatch = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);

  const fetchData = async (
    _pagination?: TablePaginationConfig,
    params?: any,
  ) => {
    setLoading(true);
    try {
      const resp = await app.service.get('dispatch', { params });
      resp?.data?.map((record: any) => resolveOrder(record));
      return resp;
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const resolveOrder = (record: any) => {
    const order = get(record, 'order');
    const deliveryOrders = get(record, 'delivery_orders', []);
    order['delivery_orders'] = deliveryOrders;
  };

  const sortOrder = (
    sort: { attribute: string; value: string },
    attribute: string,
  ) => {
    if (!sort) {
      return null;
    }

    const sortAttributeAndValue = sort.value.split('|');

    if (sortAttributeAndValue[0] != attribute) {
      return null;
    }

    if (sortAttributeAndValue[1] == 'asc') {
      return 'ascend';
    }

    if (sortAttributeAndValue[1] == 'desc') {
      return 'descend';
    }
  };

  const checkIfStatusToBeAllIssueWithMessage = async (
    uri: string,
    ids: any[],
    fn: () => any,
    cancelFn?: () => any,
  ) => {
    setLoading(true);
    try {
      const resp = await app.service.put(`containers/${uri}`, {
        data: {
          container_ids: ids,
        },
      });

      if (resp.data && resp.data.length > 0) {
        Modal.confirm({
          title: 'Confirm',
          content:
            resp.data.join('\n') + '\n Are you sure you want to continue?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk: fn,
          onCancel: cancelFn,
        });
      } else {
        return fn();
      }
    } catch (err: any) {
      showErrorMessage(err);
    }

    setLoading(false);
  };

  const checkIfArStatusToBeAllIssueWithMessage = (
    ids: any[],
    fn: () => any,
    cancelFn?: () => any,
  ) => {
    checkIfStatusToBeAllIssueWithMessage(
      'checkIfArStatusToBeAllIssueWithMessage',
      ids,
      fn,
      cancelFn,
    );
  };

  const checkIfApStatusToBeAllIssueWithMessage = (
    ids: any[],
    fn: () => any,
    cancelFn?: () => any,
  ) => {
    checkIfStatusToBeAllIssueWithMessage(
      'checkIfApStatusToBeAllIssueWithMessage',
      ids,
      fn,
      cancelFn,
    );
  };

  const getTabColumns = (
    tab: any,
    filterValues: any,
    defaultColumns: any,
  ): ColumnsType<any> => {
    const sort = filterValues?.find((f) => f.attribute === 'sort') || null;
    const _columns: ColumnsType<any> = defaultColumns?.map((c: any) => ({
      ...c,
      sortOrder: sortOrder(sort, c.key),
    }));

    const _defaultColumns = _columns?.map((c) => c.key);
    return _columns.filter((c) =>
      (tab?.columns || _defaultColumns)?.includes(c.key),
    );
  };

  const toFilterValue = (
    text: string,
    attribute: string,
    value: string[] | number[] | boolean | string,
  ) => {
    return {
      text,
      attribute,
      value,
    };
  };

  const toFilterSortValue = (
    label: string,
    sortBy: string,
    sortValue: string,
  ) => {
    const flag = sortValue.toUpperCase();
    return toFilterValue(`${label} ${flag}`, 'sort', `${sortBy}|${sortValue}`);
  };

  const onSort = (
    tabId: number,
    columns: any,
    filterValues: any,
    pagination: any,
    sortBy: string,
    sortValue: string,
    updateTabCallback?: (tab?: any) => any,
    fetchData: (pagination?: any, params?: any) => any,
  ) => {
    if (!sortValue) {
      handleRemoveSortInFilterValues(
        tabId,
        filterValues,
        sortBy,
        updateTabCallback,
      );
      return;
    }

    const label: any = columns.find((c) => c.key == sortBy);
    if (!label) {
      handleRemoveSortInFilterValues(
        tabId,
        filterValues,
        sortBy,
        updateTabCallback,
      );
      return;
    }

    const filterSortValue = toFilterSortValue(label.title, sortBy, sortValue);
    if (!filterValues) {
      fetchData(pagination, filterValues);
      return;
    }

    const index = filterValues.findIndex((fv) => fv.attribute === 'sort');
    if (index !== -1) {
      saveUpdateTab(
        tabId,
        {
          filters: update(filterValues, { [index]: { $set: filterSortValue } }),
        },
        updateTabCallback,
      );
    } else {
      saveUpdateTab(
        tabId,
        { filters: [...filterValues, filterSortValue] },
        updateTabCallback,
      );
    }
  };

  const handleRemoveSortInFilterValues = (
    tabId: number,
    filterValues: any,
    sortBy: string,
    updateTabCallback?: (tab?: any) => any,
  ) => {
    if (!filterValues) {
      return;
    }

    const index = filterValues.findIndex((fv) => fv.attribute === 'sort');
    if (-1 === index) {
      return;
    }
    const sortIndex = filterValues[index].value.indexOf(`${sortBy}|`);
    if (-1 === sortIndex) {
      return;
    }
    saveUpdateTab(
      tabId,
      { filters: update(filterValues, { $splice: [[index, 1]] }) },
      updateTabCallback,
    );
  };

  const saveUpdateTab = async (
    id: number,
    data: any,
    updateTabCallback?: (resp?: any) => any,
  ) => {
    const resp = await app.service.put(`dispatchTabs/${id}`, {
      data: data,
    });

    if (updateTabCallback) {
      updateTabCallback(resp);
    }

    return resp;
  };

  const fetchTabCounts = async (tabMode: number) => {
    try {
      const resp = await app.service.get('dispatchTabs/tabCounts', {
        params: { mode: tabMode },
      });

      return resp;
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  return {
    loading,
    fetchData,
    getTabColumns,
    sortOrder,
    onSort,
    SORT_MAP,
    saveUpdateTab,
    resolveOrder,
    toFilterValue,
    toFilterSortValue,
    // fetchTabCounts,

    checkIfArStatusToBeAllIssueWithMessage,
    checkIfApStatusToBeAllIssueWithMessage,
  };
};
export default useDispatch;
