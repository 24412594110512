import React, {
  ReactElement,
  ReactHTMLElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EmailTemplateEditor } from '@/components/EmailTemplateEditor';
import { useApp } from '@/utils/useapp';
import {
  Typography,
  FormInstance,
  Tabs,
  Radio,
  Form,
  message,
  Space,
  RadioChangeEvent,
  Tooltip,
  Row,
  Col,
  Input,
  TablePaginationConfig,
  Pagination,
} from 'antd';
import { get, truncate, has } from 'lodash';
import { TEmailTemplate, TCollection } from '@/types';
import { NormalList } from './NormalList';
import { CopyOutlined } from '@ant-design/icons';
import { TEMPLATE_TYPE_NORMAL, TEMPLATE_TYPE_SPECIFICTABS } from './data';
import { useForm } from 'antd/lib/form/Form';
import usePagination from '@/components/usePagination';
import { showErrorMessage } from '@/utils/show-error-message';

interface ISpecificTabs {
  form: FormInstance;
  target: string;
  targetId: number;
  templateData: TCollection<TEmailTemplate> | undefined;
  fetchTemplates: () => void;
  handleCreateTemplate: () => void;
  handleEditTemplate: (template: TEmailTemplate) => void;
  onChange: (template: TEmailTemplate | undefined) => void;
}

export const SpecificTabs: React.FC<ISpecificTabs> = ({
  form,
  target,
  targetId,
  templateData,
  fetchTemplates,
  handleCreateTemplate,
  handleEditTemplate,
  onChange,
}) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [coping, setCoping] = useState(false);
  const [allTemplateData, setAllTemplateData] = useState<
    TCollection<TEmailTemplate>
  >();
  const [activeKey, setActiveKey] = useState(TEMPLATE_TYPE_NORMAL);
  const [searchValue, setSearchValue] = useState('');
  const pagination = usePagination(allTemplateData);

  const fetchAllTemplates = async (pagination?: TablePaginationConfig) => {
    setLoading(true);
    if (!target) {
      return;
    }

    const filter: any = {
      isExcludeLogger: true,
    };
    searchValue && (filter.subject = searchValue);
    try {
      const resp = await app.service.get(`emailTemplates/${target}/0`, {
        params: {
          ...filter,
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 10,
        },
      });
      resp && setAllTemplateData(resp);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTab = (activeKey: string) => {
    setActiveKey(activeKey);
  };

  const handleCopy = async (e: any, template: TEmailTemplate) => {
    e.preventDefault();
    e.stopPropagation();

    if (!template.id) {
      return false;
    }

    setCoping(true);
    try {
      const resp = await app.service.post(
        `emailTemplates/${template.id}/${target}/${targetId}/copy`,
      );
      message.success('Copied');
      fetchTemplates();
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setCoping(false);
    }
  };

  const handleOnChange = (e: RadioChangeEvent) => {
    const data =
      activeKey == TEMPLATE_TYPE_NORMAL ? templateData : allTemplateData;
    onChange(
      data?.data?.find(
        (template: TEmailTemplate) => template.id === e.target.value,
      ),
    );
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    fetchAllTemplates();
  };

  const handleChangePagination = (page: any, pageSize: any) => {
    pagination.current = page;
    pagination.pageSize = pageSize;
    fetchAllTemplates(pagination);
  };

  useEffect(() => {
    fetchAllTemplates();
  }, [target, targetId]);

  return (
    <Tabs
      type="card"
      items={[
        {
          label: 'Your Templates',
          key: TEMPLATE_TYPE_NORMAL,
          children: (
            <NormalList
              form={form}
              templateData={templateData}
              onChange={onChange}
              handleCreateTemplate={handleCreateTemplate}
              handleEditTemplate={handleEditTemplate}
            />
          ),
        },
        {
          label: 'All Templates',
          key: TEMPLATE_TYPE_SPECIFICTABS,
          children: (
            <>
              <Row className="mb-md">
                <Input.Search
                  placeholder="Search Subject"
                  allowClear
                  onSearch={handleSearch}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  style={{ width: '100%' }}
                />
              </Row>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item name="email_template_id" noStyle label="">
                    <Radio.Group onChange={handleOnChange} className="template">
                      <Space direction="vertical">
                        {allTemplateData &&
                          allTemplateData.data?.map(
                            (template: TEmailTemplate, index: number) => (
                              <Radio
                                key={index}
                                value={template.id}
                                style={{
                                  width: '100%',
                                  borderBottom: '1px solid rgb(242 242 242)',
                                  paddingBottom: '10px',
                                }}
                              >
                                <div
                                  className="flex justify-between"
                                  style={{ minWidth: '40vw' }}
                                >
                                  <Space>
                                    <h4>
                                      {truncate(get(template, 'subject', ''), {
                                        length: 50,
                                      })}
                                    </h4>
                                    <Tooltip title="Copy to your templates">
                                      <Typography.Link
                                        disabled={coping}
                                        onClick={(e: any) =>
                                          handleCopy(e, template)
                                        }
                                      >
                                        <CopyOutlined />
                                      </Typography.Link>
                                    </Tooltip>
                                  </Space>
                                  <div>
                                    created
                                    {has(template, 'creator.name') &&
                                      ` by ${get(template, 'creator.name')} `}
                                    at {template.created_at}
                                  </div>
                                </div>

                                <div>
                                  {truncate(get(template, 'body_str', ''), {
                                    length: 300,
                                  })}
                                </div>
                              </Radio>
                            ),
                          )}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                )}
              </Form.Item>
              <Row className="mt-md">
                <Pagination onChange={handleChangePagination} {...pagination} />
              </Row>
            </>
          ),
        },
      ]}
      onChange={handleChangeTab}
    />
  );
};
