import styles from 'res/css/ui.scss';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  message,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { groupBy, mapValues, uniq } from 'lodash';
import update from 'immutability-helper';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { useForm } from 'antd/lib/form/Form';
import Slider from '@/components/Slider';
import { CitySelect } from '@/components/CitySelect';
import { ManagerFilterItems } from './ManagerFilterItems';
import { VendorDivisionSyncSelect } from '@/components/VendorDivisionSyncSelect';
import { FINANCIAL_STATUS_MAP } from '../../../vendors/components/data';
import { showErrorMessage } from '@/utils/show-error-message';

const SINGLE_UPDATE_ATTRIBUTE = [
  'has_driver_count',
  'has_rating',

  'overweight_ability',
  'hazmat_ability',
  'fba_delivery',
  'residential_ability',
  'liquor_alcohol_ability',
  'household_goods_ability',
  'tsa_ability',
  'chains_ability',
  'tank_endorsed_drivers_ability',
  'iso_tank_drayage_ability',
  'day_cab_ability',

  'reefer_ability',
  'out_of_gauge_ability',
  'open_top_ability',
  'flat_rack_ability',
  'menards_approval_ability',

  'transloading_ability',
  'boned_trucker_ability',
  'gate_door_with_locked',
  'surveillance_cameras',
  'locked_with_pinlock',

  'live_security_guards',
  'security_alarm_systems',
  'has_own_chassis',
  'authority_verified',
  'insurance_verified',

  'w9_verified',
  'constract_verified',
  'has_container_yard',
  'sent_do_count',
  'favorited',
  'promo',
  'rates_count',
  'will_pay_clean_truck_fee',
  'will_pay_pierpass',
];

type TFilter = {
  // checkedFilterItems: string[];
  values: any[];
  // accountingMode?: boolean;
  removeAll: () => void;
  removeOne: (item: any) => void;
  onChange: (t: any) => void;
  // onChangeFilterItems: (checkedItems: string[]) => void;
  activeFilters: any[];
  show: boolean;
  loading: boolean;
  hideFilter: () => void;
};

export const convertFilterValuesToQueryParams = (values: any[]) => {
  if (values?.find((v) => v.meta)) {
    values = [
      ...values,
      ...(Object.entries(values?.find((v) => v.meta)?.meta)?.map((v) => ({
        attribute: v[0],
        value: v[1],
      })) || []),
    ];
  }
  return mapValues(groupBy(values, 'attribute'), (items) => {
    return items.length > 1 ? items.map((i) => i.value) : items[0].value;
  });
};

export const toFilterValue = (
  text: string,
  attribute: string,
  value: string[] | number[] | boolean | string,
) => {
  return {
    text,
    attribute,
    value,
  };
};

const BINARY_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const Filter: React.FC<TFilter> = ({
  onChange,
  removeAll,
  removeOne,
  activeFilters,
  show,
  hideFilter,
  loading,
}) => {
  const app = useApp();

  const [checkedFilterItems, setCheckedFilterItem] = useState<Array<string>>(
    [],
  );
  const [newValues, setNewValues] = useState<Array<any>>([]);
  const [settingContainerTasks, setSettingContainerTasks] = useState<
    Array<any>
  >([]);

  const [localActiveFilters, setLocalActiveFilters] = useState<Array<any>>([]);

  useEffect(() => {
    if (show) {
      setLocalActiveFilters(activeFilters);
    }
  }, [show, activeFilters]);

  const [filter] = useForm();

  useEffect(() => {
    const _meta: any = activeFilters.find((n) => n.meta);

    if (show) {
      // const _meta: any = activeFilters.find((n) => n.meta);
      if (_meta?.meta) {
        filter.setFieldsValue(_meta?.meta);
      } else {
        filter.resetFields(RESET_FIELDS);
      }

      if (!settingContainerTasks) {
        fetchSettingContainerTasks();
      }
    } else {
      filter.setFieldsValue(_meta?.meta);
    }
  }, [show, activeFilters]);

  const removeLocalFilters = (index: number) => {
    const copy = [...localActiveFilters];

    copy.splice(index, 1);

    setLocalActiveFilters([...copy]);
  };

  const fetchSettingContainerTasks = async () => {
    try {
      const resp = await app.service.get('dispatch/settingContainerTasks/');
      setSettingContainerTasks(resp.data);
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  useEffect(() => {
    fetchSettingContainerTasks();
  }, []);

  const handleEtaChange = ({
    etaStart,
    etaEnd,
  }: {
    etaStart: string;
    etaEnd: string;
  }) => {
    const index = newValues.findIndex((v) => v.attribute === 'eta');
    const value = toFilterValue(`ETA ${etaStart} - ${etaEnd}`, 'eta', [
      etaStart,
      etaEnd,
    ]);

    if (index !== -1) {
      setNewValues(update(newValues, { [index]: { $set: value } }));
    } else {
      setNewValues([...newValues, value]);
    }
  };

  const handleMultipleSelectChange = (
    values: any,
    options: any,
    attribute: string,
    textPrefix?: string,
  ) => {
    if (options) {
      const newFilterValues = newValues.filter(
        (v) => v.attribute !== attribute || values.includes(v.value),
      );

      options.map((o: any) => {
        const index = newFilterValues.findIndex(
          (v) => v.attribute === attribute && v.value == o.value,
        );

        const text = textPrefix ? `${textPrefix}: ${o.label}` : `${o.label}`;

        const value = toFilterValue(text, attribute, o.value);

        if (index !== -1) {
          update(newFilterValues, { [index]: { $set: value } });
        } else {
          newFilterValues.push(value);
        }
      });
      setNewValues(newFilterValues);
    }
  };

  // const handleVendorChange = (vendor: any) => {
  //   if (vendor) {
  //     const vendorIds = vendor.map((v: any) => v.id);
  //     handleMultipleSelectChange(
  //       vendorIds,
  //       vendor.map((v: any) => ({ value: v.id, label: v.name })),
  //       'vendor_id',
  //       'Vendor',
  //     );
  //   }
  // };

  const handleVendorDivisionChange = (vd: any) => {
    if (vd) {
      handleMultipleSelectChange(
        [vd.id],
        [{ value: vd.id, label: vd.name }],
        'vendor_division_id',
        'Vendor Division',
      );
    }
  };

  const handleCityChange = (city: any) => {
    if (city) {
      handleMultipleSelectChange(
        [city.id],
        [{ value: city.id, label: city.full_name }],
        'city_id',
        'City',
      );
    }
  };

  const handleInterModalRegionChange = (ir: any) => {
    if (ir) {
      handleMultipleSelectChange(
        [ir.id],
        [{ value: ir.id, label: ir.name }],
        'intermodal_region_id',
        'IR',
      );
    }
  };

  const handleInputChange = (
    value: any,
    attribute: string,
    textPrefix: string,
  ) => {
    handleChange(
      {
        target: {
          value,
          attribute,
        },
      },
      {
        textPrefix,
      },
    );
  };

  const handleInputEnter = (e: any, attribute: string, textPrefix: string) => {
    if (e.key == 'Enter') {
      handleInputChange(e.target.value, attribute, textPrefix);
      handleApply();
    }
  };

  const handleBinaryChange = (v: any, key: string, title: string) => {
    const index = newValues.findIndex((v) => v.attribute === key);
    const value = toFilterValue(
      (title || key) + (v === true ? ' Yes' : ' No'),
      key,
      v === true,
    );

    if (index !== -1) {
      setNewValues(update(newValues, { [index]: { $set: value } }));
    } else {
      setNewValues([...newValues, value]);
    }
  };

  const rows = [
    {
      title: 'ID',
      item: (
        <Form.Item className="mb0" name="id">
          <Input
            size="small"
            allowClear
            placeholder="Enter ID"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'id',
                  },
                },
                {
                  textPrefix: 'ID',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'id',
    },
    {
      title: 'Has Google API',
      isBinary: true,
      enableIsNot: false,
      key: 'has_google_api',
    },
    {
      title: 'Name',
      item: (
        <Form.Item className="mb0" name="name">
          <Input
            size="small"
            allowClear
            placeholder="Enter Name"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'name',
                  },
                },
                {
                  textPrefix: 'Name',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: false,
      key: 'name',
    },
    {
      title: 'Vendor Division',
      item: (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item name="vendor_division_id" className="mb0">
              <VendorDivisionSyncSelect
                editable={false}
                size="small"
                style={{ width: '100%' }}
                selected={getFieldValue('vendor_division')}
                placeholder="Select Vendor Division"
                onSelect={(vd) => {
                  filter.setFieldsValue({
                    vendor_division_id: vd.id,
                    vendor_division: vd,
                  });
                  handleVendorDivisionChange(vd);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'vendor_division_id',
    },
    {
      title: 'City',
      item: (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item name="city_id" className="mb0">
              <CitySelect
                size="small"
                style={{ width: '100%' }}
                selected={getFieldValue('city_id')}
                placeholder="Select City"
                onSelect={(city) => {
                  filter.setFieldsValue({ city });
                  handleCityChange(city);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'city_id',
    },
    {
      title: 'Phone',
      item: (
        <Form.Item className="mb0" name="phone">
          <Input
            size="small"
            allowClear
            placeholder="Enter Phone"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'phone',
                  },
                },
                {
                  textPrefix: 'Phone',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'phone',
    },
    {
      title: 'Contact',
      item: (
        <Form.Item className="mb0" name="contact">
          <Input
            size="small"
            allowClear
            placeholder="Enter Contact"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'contact',
                  },
                },
                {
                  textPrefix: 'Contact',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'contact',
    },
    {
      title: 'Email',
      item: (
        <Form.Item className="mb0" name="email">
          <Input
            size="small"
            allowClear
            placeholder="Enter Email"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'email',
                  },
                },
                {
                  textPrefix: 'Email',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'email',
    },
    {
      title: 'Enabled',
      isBinary: true,
      enableIsNot: false,
      key: 'enabled',
    },
    {
      title: 'Blacklisted',
      isBinary: true,
      enableIsNot: false,
      key: 'blacklisted',
    },
    {
      title: 'Established Credit',
      isBinary: true,
      enableIsNot: false,
      key: 'established_credit',
    },
    {
      title: 'Has Credit',
      isBinary: true,
      enableIsNot: false,
      key: 'has_credit',
    },
    {
      title: 'Financial Status',
      item: (
        <Form.Item className="mb0" name="financial_status">
          <Select
            size="small"
            allowClear
            style={{ width: '100%' }}
            placeholder="Select Financial Status"
            onChange={(v) => {
              handleInputChange(v, 'financial_status', 'Financial Status');
            }}
          >
            {Object.keys(FINANCIAL_STATUS_MAP).map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'financial_status',
    },
    {
      title: 'US DOT',
      item: (
        <Form.Item className="mb0" name="us_dot">
          <Input
            size="small"
            allowClear
            placeholder="Enter US DOT"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'us_dot',
                  },
                },
                {
                  textPrefix: 'US DOT',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: false,
      key: 'us_dot',
    },
    {
      title: 'Trucker MC',
      item: (
        <Form.Item className="mb0" name="trucker_mc">
          <Input
            size="small"
            allowClear
            placeholder="Enter Trucker MC"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'trucker_mc',
                  },
                },
                {
                  textPrefix: 'Trucker MC',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'trucker_mc',
    },
    {
      title: 'SCAC Code',
      item: (
        <Form.Item className="mb0" name="scac_code">
          <Input
            size="small"
            allowClear
            placeholder="Enter SCAC Code"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'scac_code',
                  },
                },
                {
                  textPrefix: 'SCAC Code',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'scac_code',
    },
    {
      title: 'Has Driver Count',
      isBinary: true,
      enableIsNot: false,
      key: 'has_driver_count',
    },
    {
      title: 'Has Rating',
      isBinary: true,
      enableIsNot: false,
      key: 'has_rating',
    },
    {
      title: 'IR',
      item: (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item name="intermodal_region_id" className="mb0">
              <IntermodalRegionSelect
                size="small"
                style={{ width: '100%' }}
                selected={getFieldValue('intermodal_region_id')}
                placeholder="Select IR"
                onSelect={(ir) => {
                  filter.setFieldsValue({ ir });
                  handleInterModalRegionChange(ir);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'intermodal_region_id',
    },
    {
      title: 'Overweight Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'overweight_ability',
    },
    {
      title: 'Hazmat Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'hazmat_ability',
    },
    {
      title: 'FBA Delivery',
      isBinary: true,
      enableIsNot: false,
      key: 'fba_delivery',
    },
    {
      title: 'Residential Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'residential_ability',
    },
    {
      title: 'Liquor Alcohol Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'liquor_alcohol_ability',
    },
    {
      title: 'Household Goods Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'household_goods_ability',
    },
    {
      title: 'Tsa Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'tsa_ability',
    },
    {
      title: 'Chains Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'chains_ability',
    },
    {
      title: 'Tank Endorsed Drivers Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'tank_endorsed_drivers_ability',
    },
    {
      title: 'Iso Tank Drayage Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'iso_tank_drayage_ability',
    },
    {
      title: 'Day Cab Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'day_cab_ability',
    },
    {
      title: 'Out of Gauge Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'out_of_gauge_ability',
    },
    {
      title: 'Open Top Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'open_top_ability',
    },
    {
      title: 'Flat Rack Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'flat_rack_ability',
    },
    {
      title: 'Menards Approval Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'menards_approval_ability',
    },
    {
      title: 'Transloading Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'transloading_ability',
    },
    {
      title: 'Boned Trucker Ability',
      isBinary: true,
      enableIsNot: false,
      key: 'boned_trucker_ability',
    },
    {
      title: 'Gate Door With Locked',
      isBinary: true,
      enableIsNot: false,
      key: 'gate_door_with_locked',
    },
    {
      title: 'Surveillance Cameras',
      isBinary: true,
      enableIsNot: false,
      key: 'surveillance_cameras',
    },
    {
      title: 'Locked With Pinlock',
      isBinary: true,
      enableIsNot: false,
      key: 'locked_with_pinlock',
    },
    {
      title: 'Live Security Guards',
      isBinary: true,
      enableIsNot: false,
      key: 'live_security_guards',
    },
    {
      title: 'Security Alarm Systems',
      isBinary: true,
      enableIsNot: false,
      key: 'security_alarm_systems',
    },
    {
      title: 'Has Own Chassis',
      isBinary: true,
      enableIsNot: false,
      key: 'has_own_chassis',
    },
    {
      title: 'Authority Verified',
      isBinary: true,
      enableIsNot: false,
      key: 'authority_verified',
    },
    {
      title: 'Insurance Verified',
      isBinary: true,
      enableIsNot: false,
      key: 'insurance_verified',
    },
    {
      title: 'W9 Verified',
      isBinary: true,
      enableIsNot: false,
      key: 'w9_verified',
    },
    {
      title: 'Contract Verified',
      isBinary: true,
      enableIsNot: false,
      key: 'constract_verified',
    },
    {
      title: 'Has Container Yard',
      isBinary: true,
      enableIsNot: false,
      key: 'has_container_yard',
    },
    {
      title: 'Sent DO Count',
      isBinary: true,
      enableIsNot: false,
      key: 'sent_do_count',
    },
    {
      title: 'Favorited',
      isBinary: true,
      enableIsNot: false,
      key: 'favorited',
    },
    {
      title: 'Promo',
      isBinary: true,
      enableIsNot: false,
      key: 'promo',
    },
    {
      title: 'Has Rates',
      isBinary: true,
      enableIsNot: false,
      key: 'has_rate_count',
    },
    {
      title: 'Sent DO Greater Than',
      item: (
        <Form.Item className="mb0" name="sent_do_greater_than">
          <Input
            size="small"
            allowClear
            placeholder="Enter Sent DO Greater Than"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'sent_do_greater_than',
                  },
                },
                {
                  textPrefix: 'Sent DO >= ',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: false,
      key: 'sent_do_greater_than',
    },
    {
      title: 'Sent DO Less Than',
      item: (
        <Form.Item className="mb0" name="sent_do_less_than">
          <Input
            size="small"
            allowClear
            placeholder="Enter Sent DO Less Than"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'sent_do_less_than',
                  },
                },
                {
                  textPrefix: 'Sent DO <= ',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: false,
      key: 'sent_do_less_than',
    },
    {
      title: 'Terms Greater Than',
      item: (
        <Form.Item className="mb0" name="terms_greater_than">
          <Input
            size="small"
            allowClear
            placeholder="Enter Terms Greater Than"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'terms_greater_than',
                  },
                },
                {
                  textPrefix: 'Terms >= ',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: false,
      key: 'terms_greater_than',
    },
    {
      title: 'Terms Less Than',
      item: (
        <Form.Item className="mb0" name="terms_less_than">
          <Input
            size="small"
            allowClear
            placeholder="Enter Terms Less Than"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'terms_less_than',
                  },
                },
                {
                  textPrefix: 'Terms <= ',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: false,
      key: 'terms_less_than',
    },
    {
      title: 'Will Pay Clean Truck Fee',
      isBinary: true,
      enableIsNot: false,
      key: 'will_pay_clean_truck_fee',
    },
    {
      title: 'Will Pay Pierpass',
      isBinary: true,
      enableIsNot: false,
      key: 'will_pay_pierpass',
    },
  ];

  useEffect(() => {
    if (!show) {
      return;
    }

    if (
      (!checkedFilterItems || checkedFilterItems.length == 0) &&
      localActiveFilters.length > 0
    ) {
      setCheckedFilterItem(localActiveFilters.map((f) => f.attribute));
    }
  }, [checkedFilterItems, localActiveFilters]);

  useEffect(() => {
    setNewValues([]);
    setCheckedFilterItem([]);
  }, [show]);

  const handleChangeFilterItems = (checked: string[]) => {
    setCheckedFilterItem(
      Object.assign(
        uniq([
          ...checked,
          ...localActiveFilters.map((f) => f.attribute),
          ...newValues.map((n) => n.attribute),
        ]),
      ),
    );
  };

  const RESET_FIELDS = [
    ...rows.map((item) => item.key),
    ...['warehouse', 'vendor'],
  ];

  const handleChange = async (event: any, options: any = {}) => {
    const { value, attribute } = event.target;
    const { preferText, textPrefix } = options;

    const index = newValues.findIndex((v) => v.attribute === attribute);

    const text = preferText
      ? preferText
      : textPrefix
      ? `${textPrefix}: ${value}`
      : `${value}`;

    const _value = toFilterValue(text, attribute, value);
    if (index !== -1) {
      value && setNewValues(update(newValues, { [index]: { $set: _value } }));
      !value && setNewValues(update(newValues, { $splice: [[index, 1]] }));
    } else {
      setNewValues([...newValues, _value]);
    }
  };

  const handleApply = async () => {
    const filterNewValues = newValues.filter(
      (n) =>
        !localActiveFilters.find(
          (a) => a.attribute == n.attribute && a.value == n.value,
        ),
    );

    const filterActives = localActiveFilters.filter((a) => {
      if (
        SINGLE_UPDATE_ATTRIBUTE.includes(a.attribute) &&
        filterNewValues.find((n) => a.attribute == n.attribute)
      ) {
        return false;
      }

      return a;
    });
    const _meta = localActiveFilters.findIndex((n) => n.meta);
    const meta: any = {};
    const _filter = filter.getFieldsValue();
    for (const key in _filter) {
      if (key.startsWith('is_') && !RESET_FIELDS.includes(key)) {
        meta[key] = _filter[key];
      }
    }

    if (_meta > -1) {
      filterActives[_meta] = {
        meta,
      };
    } else {
      filterNewValues.push({
        meta,
      });
    }

    onChange([...filterActives, ...filterNewValues]);
    filter.resetFields(RESET_FIELDS);
    setNewValues([]);
  };

  const handleReset = () => {
    filter.resetFields(RESET_FIELDS);
    setNewValues([]);
  };

  const FieldTag = ({ field }: { field: string }) =>
    useMemo(() => {
      return (
        <div style={{ width: 'flex' }}>
          {localActiveFilters
            // .filter((item) => item.attribute == field)
            .map((item: any, index) => {
              return item.attribute == field ? (
                <Tag
                  className="ml-sm"
                  closable
                  onClose={() => {
                    removeLocalFilters(index);
                  }}
                  color={
                    filter.getFieldValue(`is_${item.attribute}`)
                      ? 'processing'
                      : ''
                  }
                  key={`tag-${item.text}-${index}`}
                >
                  {item.text}
                </Tag>
              ) : (
                ''
              );
            })}
        </div>
      );
    }, [activeFilters, filter]);

  return (
    <div className={styles.filter}>
      {show && (
        <Modal
          open={show}
          onCancel={() => hideFilter()}
          footer={false}
          width={1400}
        >
          <Form form={filter} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <div style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
              <Row>
                <ManagerFilterItems
                  checkedItems={checkedFilterItems}
                  items={rows.map((item: any) => ({
                    key: item.key,
                    name: item.title,
                  }))}
                  onChange={handleChangeFilterItems}
                />
              </Row>
              <table>
                <tbody>
                  {rows
                    .filter((item) => checkedFilterItems.includes(item.key))
                    .map((item: any) => (
                      <tr key={item.key}>
                        <td width="200">{item.title}</td>
                        <td width="250">
                          {item.isBinary ? (
                            <Form.Item className="mb0" name={item.key}>
                              <Select
                                size="small"
                                allowClear
                                value={undefined}
                                style={{ width: '100%' }}
                                maxTagCount="responsive"
                                onChange={(v) =>
                                  handleBinaryChange(v, item.key, item.title)
                                }
                                options={BINARY_OPTIONS}
                              />
                            </Form.Item>
                          ) : (
                            item.item
                          )}
                        </td>
                        <td width="80">
                          {item.enableIsNot ? (
                            <Form.Item
                              className="mb0"
                              initialValue={1}
                              name={`is_${item.key}`}
                            >
                              <Select size="small">
                                <Select.Option value={1}>Is</Select.Option>
                                <Select.Option value={0}>Is Not</Select.Option>
                              </Select>
                            </Form.Item>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td width="800">
                          <FieldTag field={item.key} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <Row>
              <Col span={4}>
                <Form.Item label="&nbsp;" shouldUpdate>
                  <Space>
                    <Button
                      disabled={
                        (newValues.length > 0 ||
                        filter.isFieldsTouched([], true)
                          ? false
                          : true) || loading
                      }
                      type="primary"
                      onClick={handleApply}
                    >
                      Apply
                    </Button>
                    <Button onClick={handleReset} disabled={loading}>
                      Reset
                    </Button>
                    {activeFilters.length > 0 && (
                      <Button onClick={removeAll} disabled={loading}>
                        Clear All
                      </Button>
                    )}
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {(show || activeFilters.length > 0) && (
        <Row>
          <Col span={1}>
            <div>Filters:</div>
          </Col>
          <Col span={19}>
            <Slider>
              {activeFilters
                // .filter((item) => !item.meta)
                .map((item: any, index) =>
                  !item.meta ? (
                    <li key={`tag-${item.text}-${index}`}>
                      <Tag
                        closable
                        onClose={() => {
                          removeOne(index);
                        }}
                        color={
                          filter.getFieldValue(`is_${item.attribute}`)
                            ? 'processing'
                            : ''
                        }
                      >
                        {item.text}
                      </Tag>
                    </li>
                  ) : (
                    ''
                  ),
                )}
            </Slider>
          </Col>
          <Col span={4}>
            <div>
              {activeFilters.length > 0 && (
                <Button size="small" onClick={removeAll}>
                  Clear All
                </Button>
              )}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
