import React, { FC, useMemo, useState } from 'react';
import { StatusLabelMap, getStatusMapByTarget } from './data';
import { Checkbox, message, Popover } from 'antd';
import { useApp } from '@/utils/useapp';
import TroubleStatusesHistory from './TroubleStatusesHistory';
import { TargetInterface } from './Interfaces/TargetInterface';
import { showErrorMessage } from '@/utils/show-error-message';

const TroubleStatusesPopover: FC<
  TargetInterface & {
    onUpdated: (id: number, data: any) => void;
  }
> = ({ target, targetModel, onUpdated }) => {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const _statusMap = useMemo(() => getStatusMapByTarget(target), [target]);

  const onChange = async (e: any) => {
    if (!targetModel.id) {
      return;
    }
    const { name, type, value, checked } = e.target;

    try {
      const resp = await app.service.post(
        `troubleStatus/${target}/${targetModel.id}`,
        {
          data: {
            status: name,
            occurred: checked,
          },
        },
      );
      onUpdated(targetModel.id, resp.data);
    } catch (err: any) {
      showErrorMessage(err);
    }
  };

  const checkedTroubleStatues: string[] = React.useMemo(() => {
    return targetModel.trouble_statuses
      ? targetModel.trouble_statuses
          .filter((tr) => tr.occurred)
          .map((tr) => tr.status)
      : [];
  }, [targetModel.trouble_statuses]);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={
        <>
          <div>
            <b>Current</b>
          </div>
          {Object.keys(_statusMap).map((field, index) => (
            <div key={index}>
              <Checkbox
                name={field}
                checked={checkedTroubleStatues.includes(field)}
                onChange={onChange}
              >
                {_statusMap[(field as unknown) as keyof typeof _statusMap]}
              </Checkbox>
            </div>
          ))}
          <hr />
          <div>
            <b>History</b>
          </div>
          <TroubleStatusesHistory
            histories={targetModel?.trouble_statuses_histories || []}
          />
        </>
      }
      title="Trouble Status"
      trigger="click"
      placement="left"
      visible={open}
      onOpenChange={handleOpenChange}
    >
      <div className="cursor-pointer">
        {checkedTroubleStatues && checkedTroubleStatues.length > 0 ? (
          <>
            {checkedTroubleStatues.map((status) => {
              return (
                <div key={status}>
                  {StatusLabelMap[status as keyof typeof StatusLabelMap]}
                </div>
              );
            })}
          </>
        ) : (
          '-'
        )}
      </div>
    </Popover>
  );
};

export default TroubleStatusesPopover;
