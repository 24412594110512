import { useApp } from '@/utils/useapp';
import {
  message,
  Form,
  Modal,
  Space,
  Button,
  DatePicker,
  Input,
  Select,
  Row,
  Col,
  Divider,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Documents } from '@/components/Upload/Documents';
import { DocumentsStoreLocalFormItem } from '@/components/Upload/DocumentsStoreLocalFormItem';
import { TItemProps } from '../..';
import {
  RerserveTime,
  FIELD as rerseveTimeField,
} from '@/pages/dispatchs/components/RerserveTime';
import { serialize } from 'object-to-formdata';
import { RESCHEDULED_BY_MAP } from './data';
import { HistoryReschedules } from './HistoryReschedules';
import { EnterOutlined } from '@ant-design/icons';
import { showErrorMessage } from '@/utils/show-error-message';

export type TActionInfo = {
  type: string;
  data: { [key: string]: string };
};

interface IRescheduledModal {
  container: any;
  open: boolean;
  actionInfo: TActionInfo;
  onDone?: () => void;
  onClose: () => void;
}
export const RescheduledModal: React.FC<IRescheduledModal & TItemProps> = ({
  container,
  open,
  item,
  actionInfo,
  onDone,
  onClose,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [toRefresh, setToRefresh] = useState(false);

  const [form] = useForm();

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };
  const handleSave = async () => {
    if (!item?.id || !container?.id) {
      return false;
    }

    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);

    try {
      if (!values?.id) {
        const config = {
          data: serialize(
            { ...values, taskAction: actionInfo },
            { indices: true, nullsAsUndefineds: true },
          ),
          requestType: 'form',
        };
        await app.service.post(
          `containers/${container.id}/reschedules`,
          config,
        );
      } else {
        await app.service.put(
          `containers/${container.id}/reschedules/${values.id}`,
          {
            data: { ...values, taskAction: actionInfo },
          },
        );
      }

      message.success('Reschedules Success');

      onDone && onDone();
      setToRefresh(true);
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (record: any) => {
    form.setFieldsValue(record);
  };

  return (
    <>
      <Modal
        title={'Rescheduled'}
        width={'80%'}
        onCancel={handleOnClose}
        destroyOnClose={true}
        open={open}
        footer={<></>}
      >
        <Row>
          <Col span={11}>
            <h2>History</h2>
            <HistoryReschedules
              container={container}
              item={item}
              toRefresh={toRefresh}
              onEdit={handleEdit}
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" />
          </Col>
          <Col span={12}>
            <Form layout="vertical" form={form}>
              <Form.Item name="id" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item
                name="reschedule_by"
                label="Reschedule due to"
                rules={[
                  { required: true, message: 'Reschedule due to is required' },
                ]}
              >
                <Select allowClear>
                  <Select.Option value=""> </Select.Option>
                  {Object.keys(RESCHEDULED_BY_MAP).map((rescheduled_by) => (
                    <Select.Option key={rescheduled_by} value={+rescheduled_by}>
                      {
                        RESCHEDULED_BY_MAP[
                          (rescheduled_by as unknown) as keyof typeof RESCHEDULED_BY_MAP
                        ]
                      }
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="front_link"
                label={
                  <Space>
                    Front Link
                    {form.getFieldValue('front_link') && (
                      <span className="mt-sm mr-xs">
                        <a
                          href={form.getFieldValue('front_link')}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <EnterOutlined />
                        </a>
                      </span>
                    )}
                  </Space>
                }
                rules={[{ required: true, message: 'Front Link is required' }]}
              >
                <Input placeholder="input placeholder" />
              </Form.Item>
              <Form.Item
                name="reschedule_at"
                noStyle
                rules={[{ required: true }]}
              >
                <Input hidden />
              </Form.Item>
              <Form.Item
                name="reschedule_at_reserve_time"
                noStyle
                rules={[{ required: false }]}
              >
                <Input hidden />
              </Form.Item>

              <Form.Item label="Reschedule at" shouldUpdate required>
                {({ getFieldValue, setFieldValue }) => {
                  const time = getFieldValue('reschedule_at');
                  return (
                    <Row>
                      <Col span={12}>
                        <DatePicker
                          className="w100"
                          value={time ? moment(time) : null}
                          onChange={(date, dateString) => {
                            setFieldValue('reschedule_at', dateString);
                          }}
                        />
                      </Col>
                      <Col span={11} className="ml-md">
                        <RerserveTime
                          name={'reschedule_at_reserve_time'}
                          placement={'top'}
                          value={getFieldValue('reschedule_at_reserve_time')}
                          record={container}
                          is_append_to_default={false}
                          is_show_time_detail={true}
                          saveText="Ok"
                          customUpdate={(value) => {
                            setFieldValue('reschedule_at_reserve_time', value);
                          }}
                          refreshContainer={() => onDone()}
                        />
                      </Col>
                    </Row>
                  );
                }}
              </Form.Item>

              <Form.Item
                label="Reason"
                name={'reason'}
                rules={[{ required: true }]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>

              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue('id') ? (
                    <Documents
                      title="Documents"
                      target="container_reschedule" // TO update
                      targetId={getFieldValue('id')}
                    />
                  ) : (
                    <DocumentsStoreLocalFormItem form={form} />
                  )
                }
              </Form.Item>
              <Form.Item label={' '}>
                <Space>
                  {/* <Button disabled={loading} onClick={handleOnClose}>
                    Cancel
                  </Button> */}
                  <Button
                    type="primary"
                    disabled={loading}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
