import { useApp } from '@/utils/useapp';
import {
  Popover,
  DatePicker,
  message,
  Button,
  Modal,
  Switch,
  Row,
  Input,
  Space,
} from 'antd';
import React, { useState } from 'react';
import { BaseButtonProps } from 'antd/lib/button/button';
import moment from 'moment';
import { auth } from '@/stores';
import { showErrorMessage } from '@/utils/show-error-message';

type TProps = {
  isUpdateToSave?: boolean;
  open: boolean;
  text?: string;
  confirmed_at: string;
  cancel_customer_confirmed_reason?: string;
  orderInvoiceId: number;
  onSetLoading?: (loading: boolean) => void;
  onSaved: (orderInvoiceId: number) => void;
  onOpen: () => void;
  onClose: () => void;
};

export const CustomerConfirmModal: React.FC<TProps & BaseButtonProps> = ({
  isUpdateToSave = true,
  text,
  open = false,
  confirmed_at,
  cancel_customer_confirmed_reason,
  orderInvoiceId,
  onSetLoading,
  onSaved,
  onOpen,
  onClose,
  ...props
}) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [confirmedAt, setConfirmedAt] = useState(confirmed_at ?? null);

  const [cancelConfirmedReason, setCancelConfirmedReason] = useState(
    cancel_customer_confirmed_reason ?? '',
  );

  const toSetLoading = (loading: boolean) => {
    setLoading(loading);
    onSetLoading && onSetLoading(loading);
  };

  const handleConfirm = async () => {
    if (!confirmedAt) {
      return message.error('Please select date');
    }

    if (!orderInvoiceId) {
      return false;
    }
    toSetLoading(true);
    try {
      await app.service.patch(`orderInvoices/${orderInvoiceId}/confirm`, {
        data: {
          confirmed_at: confirmedAt,
        },
      });

      message.success('Saved');
      onSaved(orderInvoiceId);
      onClose();
    } catch (e: any) {
      showErrorMessage(e);
    }

    toSetLoading(false);
  };

  const handleCancelConfirm = async () => {
    if (!cancelConfirmedReason) {
      return message.error('Please input cancel confirm reason');
    }

    toSetLoading(true);
    if (!orderInvoiceId) {
      return false;
    }

    try {
      await app.service.patch(`orderInvoices/${orderInvoiceId}/cancelConfirm`, {
        data: {
          cancel_customer_confirmed_reason: cancelConfirmedReason,
        },
      });

      message.success('Saved');
      setConfirmedAt(null);
      onSaved(orderInvoiceId);
      onClose();
    } catch (e: any) {
      showErrorMessage(e);
    }

    toSetLoading(false);
  };

  const handleChange = async (date: any, dateString: string) => {
    setConfirmedAt(dateString);
    // Modal.confirm({
    //   title: 'Customer Confirm',
    //   content: date ? 'Sure to confirm?' : 'Sure to cancel confirm?',
    //   okText: 'Confirm',
    //   onOk: async () => {
    //     await handleConfirm(dateString);
    //   },
    // });
  };

  return (
    <Modal
      title={text ? text : 'Customer Confirm'}
      open={open}
      onCancel={onClose}
      footer={
        <Space>
          <Button
            type={'primary'}
            disabled={loading}
            onClick={confirmed_at ? handleCancelConfirm : handleConfirm}
            {...props}
          >
            {text}
          </Button>

          <Button type="default" onClick={onClose}>
            Close
          </Button>
        </Space>
      }
    >
      {confirmed_at ? (
        <>
          <Row className="mb-md">
            <div className="ant-col ant-form-item-label mb-xs">
              Confirm At: {moment(confirmedAt).format('Y-M-D')}
            </div>
          </Row>

          <Row className="mb-md" style={{ width: '100%' }}>
            <div className="ant-col ant-form-item-label mb-xs">
              Cancel Customer Confirm Reason
            </div>
            <Input.TextArea
              onChange={(e) => setCancelConfirmedReason(e.target.value)}
              rows={3}
              placeholder="Input cancel confirm reason"
            />
          </Row>
        </>
      ) : (
        <Row className="mb-md">
          <div className="ant-col ant-form-item-label mb-xs">Confirm At</div>
          <DatePicker
            placeholder="Customer Confirm"
            style={{ width: '100%' }}
            value={confirmedAt ? moment(confirmedAt) : null}
            onChange={handleChange}
            disabled={!auth.hasRole(['accounting', 'controller'])}
          />
        </Row>
      )}
    </Modal>
  );
};
