import React from 'react';
import { Form, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';

interface WillPayPierpassProps {
  form: FormInstance;
}

const WillPayPierpass: React.FC<WillPayPierpassProps> = ({ form }) => {
  return (
    <Form.Item
      name="will_pay_pierpass"
      label="Will Pay Pierpass"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
  );
};

export default WillPayPierpass;
