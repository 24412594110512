import { useApp } from '@/utils/useapp';
import { Button, Form, InputNumber, Modal, message } from 'antd';
import { useState } from 'react';
import { TUserAccount } from '..';
import { showErrorMessage } from '@/utils/show-error-message';

const SetCreditModal: React.FC<{
  account: TUserAccount;
  onCancel?: () => void;
  onOk?: () => void;
}> = ({ onCancel, account, onOk }) => {
  const app = useApp();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const cancel = () => {
    setVisible(false);
    onCancel && onCancel();
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await app.service.put(`userAccounts/${account.id}/addCredit`, {
        data: values,
      });
      message.success('Credit added');
      onOk && onOk();
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      setLoading(false);
      setVisible(false);
    }
  };

  return (
    <>
      <Button type="link" onClick={() => setVisible(true)}>
        {' '}
        Set Credit{' '}
      </Button>
      {visible && (
        <Modal
          title="Set Credit"
          open={visible}
          onCancel={cancel}
          footer={null}
        >
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item
              label="Amount"
              name="amount"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default SetCreditModal;
