import { Button, Drawer, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { get } from 'lodash';
import { Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { SendEmailUsingEditor } from '@/components/EmailForm/SendEmailUsingEditor';
import { useApp } from '@/utils/useapp';

const { Text } = Typography;

type TSOCVerificationDrawerProps = {
  open: boolean;
  container: any;
  onClose: () => void;
  onSent: () => void;
  actionInfo: {
    [key: string]: any;
  };
};

const SendSOCEmptyReturnDepotVerificationDrawer: React.FC<TSOCVerificationDrawerProps> = ({
  open,
  container,
  onClose,
  actionInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const [emailForm] = useForm();

  const app = useApp();

  const handleOnClose = () => {
    emailForm.resetFields();
    setLoading(false);
    onClose();
  };

  const socReturnLocationEmail = useMemo(() => {
    return get(container, 'soc_return_location.email', '');
  }, [container]);

  const isValidEmail = useMemo(() => {
    return socReturnLocationEmail.match(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    );
  }, [socReturnLocationEmail]);

  const initForm = () => {
    if (!container) {
      return;
    }

    const salesEmail = get(container, 'order.sales.email', '');
    const salesSupportEmail = get(container, 'order.sales_support.email', '');
    const operatorEmail = get(container, 'order.operator.email', '');
    const containerNumber = get(container, 'number', '');

    emailForm.setFieldsValue({
      sendToList: [socReturnLocationEmail],
      ccList: [
        app.store.auth.user?.email,
        'gzhou@drayeasy.com',
        'joan@drayeasy.com',
        'jacy.y@drayeasy.com',
        salesEmail,
        salesSupportEmail,
        operatorEmail,
        'doc@drayeasy.com',
      ].filter((i) => i),
      subject: `VERIFICATION OF DETAILS FOR SOC DEPOT CNTR#: ${containerNumber}`,
      body: `
<div>
<p>Hi Team,</p>
<p>I hope you are all doing well!</p>
<p>Can you please help us verify and confirm the following details:</p>

<p>
Facility Name: <strong>${get(
        container,
        'soc_return_location.name',
        '',
      )}</strong><br />
Facility Address: <strong>${get(
        container,
        'soc_return_location.address',
        '',
      )}</strong><br />
Contact person: <strong>${get(
        container,
        'soc_return_location.contact',
        '',
      )}</strong><br />
Contact No.: <strong>${get(
        container,
        'soc_return_location.phone',
        '',
      )}</strong><br />
Working Hours:
</p>

<p>Also, please help us confirm if this container will be turned in to your depot.</p>

<p>
Container No.: <strong>${containerNumber}</strong><br />
Reference code: <strong></strong>
</p>

<p>Do we need to secure a return appt at the depot? _________</p>

<p>Your response will be highly appreciated.</p>

<p>Thank you so much.</p>
</div>
      `,
    });
  };

  useEffect(() => {
    if (open && container) {
      initForm();
    }
  }, [open, container]);

  return (
    <Drawer
      title="Send SOC Empty Return Depot Verification"
      width={'70%'}
      destroyOnClose={true}
      open={open}
      onClose={handleOnClose}
      footer={
        <>
          <Space>
            <Button disabled={loading} onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={loading}
              onClick={() => setLoading(true)}
            >
              Send
            </Button>
          </Space>
        </>
      }
    >
      <SendEmailUsingEditor
        target={'container'}
        targetId={container.id}
        form={emailForm}
        sending={loading}
        onSent={() => handleOnClose()}
        sendURI={`containers/${container.id}/sendEmailFromContainerTask`}
        onSending={(sending) => setLoading(sending)}
        onCancelSend={() => handleOnClose()}
        options={{
          taskAction: actionInfo,
        }}
        alertMessage={
          !isValidEmail ? (
            <Text type="danger">
              Please enter a valid email address and update the email of the SOC
              return location.
            </Text>
          ) : (
            <></>
          )
        }
        docs={null}
      />
    </Drawer>
  );
};

export default SendSOCEmptyReturnDepotVerificationDrawer;
