import { useApp } from '@/utils/useapp';
import { Modal, Space, Typography, Button, Alert, message } from 'antd';
import React from 'react';

// TriggerNewAlgoModal Component
const TriggerNewAlgoModal = ({
  rateEngineConfigId,
}: {
  rateEngineConfigId: string | number | null;
}) => {
  const [messages, setMessages] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState<
    'idle' | 'running' | 'success' | 'failed'
  >('idle');
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const [visible, setVisible] = React.useState(false);
  const app = useApp();

  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  // React.useEffect(() => {
  //   scrollToBottom();
  // }, []);

  const startProcess = () => {
    if (!rateEngineConfigId) {
      message.error('Invalid configuration ID');
      return;
    }

    setLoading(true);
    setStatus('running');
    setMessages(['Starting algorithm generation process...']);

    // Function to process SSE data chunks
    const processChunk = (chunk: string) => {
      if (!chunk.trim()) {
        return;
      }

      // EventSource data is prefixed with "data: "
      if (chunk.startsWith('data: ')) {
        const jsonData = chunk.substring(6); // Remove "data: " prefix
        try {
          const data = JSON.parse(jsonData);

          if (data.message) {
            setMessages((prev) => [...prev, data.message]);
          }

          if (data.status) {
            setStatus(data.status === 'success' ? 'success' : 'failed');
            setLoading(false);
          }
        } catch (e) {
          message.error('Failed to parse SSE data');
        }
      }
    };

    // Use fetch with headers and stream processing
    fetch(
      `${API_URL}/rateEngine/triggerNewAlgo?intermodal_region_id=${rateEngineConfigId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${app.store.auth.token}`,
          Accept: 'text/event-stream',
          'Cache-Control': 'no-cache',
        },
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        setMessages((prev) => [...prev, 'Connection established with server.']);

        // Get the reader from the response body stream
        const reader = response.body!.getReader();
        const decoder = new TextDecoder();
        let buffer = '';

        // Reading function that processes chunks as they arrive
        function read() {
          reader
            .read()
            .then(({ done, value }) => {
              if (done) {
                // Handle any remaining data in buffer
                if (buffer) {
                  processChunk(buffer);
                }
                return;
              }

              // Decode the chunk and add to buffer
              const chunk = decoder.decode(value, { stream: true });
              buffer += chunk;

              // Process complete events (separated by double newlines)
              const lines = buffer.split('\n\n');
              // Keep the last potentially incomplete chunk in the buffer
              buffer = lines.pop() || '';

              // Process complete chunks
              lines.forEach(processChunk);

              // Continue reading
              read();
            })
            .catch((error) => {
              if (error.name !== 'AbortError') {
                setMessages((prev) => [
                  ...prev,
                  `Error: ${
                    error.message || 'Connection lost or failed to connect.'
                  }`,
                ]);
                setStatus('failed');
                setLoading(false);
              }
            });
        }
        read();
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          setMessages((prev) => [
            ...prev,
            `Error: ${
              error.message || 'Connection lost or failed to connect.'
            }`,
          ]);
          setStatus('failed');
          setLoading(false);
        }
      });
  };

  const handleClose = () => {
    if (loading) {
      message.warning('Please wait for the process to complete.');
      return;
    }

    setVisible(false);
  };

  return (
    <>
      <Button size="small" onClick={() => setVisible(true)}>
        Trigger New Algo
      </Button>
      <Modal
        title="Trigger New Algorithm"
        open={visible}
        onCancel={handleClose}
        width={700}
        footer={[
          <Button key="close" onClick={handleClose}>
            Close
          </Button>,
          <Button
            key="start"
            type="primary"
            loading={loading}
            onClick={startProcess}
            disabled={loading || !rateEngineConfigId}
          >
            Start Process
          </Button>,
        ]}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {status === 'running' && (
            <Alert message="Process is running..." type="info" showIcon />
          )}
          {status === 'success' && (
            <Alert
              message="Process completed successfully!"
              type="success"
              showIcon
            />
          )}
          {status === 'failed' && (
            <Alert
              message="Process failed. Check the logs for details."
              type="error"
              showIcon
            />
          )}

          <div style={{ marginTop: '16px' }}>
            <Typography.Text strong>Process Logs:</Typography.Text>
          </div>

          <div
            style={{
              height: '300px',
              overflowY: 'auto',
              padding: '8px',
              background: '#f5f5f5',
              borderRadius: '4px',
              fontFamily: 'monospace',
              whiteSpace: 'pre-wrap',
              fontSize: '12px',
            }}
          >
            {messages.length === 0 ? (
              <div style={{ textAlign: 'center', paddingTop: '120px' }}>
                <Typography.Text type="secondary">
                  Click &quot;Start Process&quot; to begin algorithm generation
                </Typography.Text>
              </div>
            ) : (
              <>
                {messages.map((msg, index) => (
                  <div key={index} style={{ marginBottom: '4px' }}>
                    {msg}
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </>
            )}
          </div>
        </Space>
      </Modal>
    </>
  );
};

export default TriggerNewAlgoModal;
