import {
  Space,
  Button,
  message,
  Tabs,
  Table,
  Empty,
  Spin,
  Drawer,
  Divider,
} from 'antd';
import React, { useMemo, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { useApp } from '@/utils/useapp';
import { ALL_NUMBER } from '.';
import { InvoiceReminds } from '@/pages/accounting/invoices/components/InvoiceDrawer/InvoiceReminds';
import { uniq } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';

type TEstimate = {
  code: string;
  name: string;
  rate: number;
  qty: number;
  amount: number;
};
export const EstimateDrawer: React.FC<{
  open: boolean;
  container: any;
  onClose: () => void;
}> = ({ open, container, onClose }) => {
  const app = useApp();

  const charges = useMemo(() => {
    return [
      {
        containerNumber: container.number,
      },
    ];
  }, []);
  const [data, setData] = useState<TEstimate[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reminds, setReminds] = useState([]);

  const fetchEstimateData = async () => {
    setLoading(true);
    try {
      const response = await app.service.get(
        `containers/${container.id}/estimate`,
        {
          params: {},
        },
      );

      const _reminds: any = (response?.reminds || []).concat(
        response.data.filter((d: any) => d.remind)?.map((d: any) => d.remind),
      );

      setData(response.data);
      setTotal(response.total);
      setReminds(uniq(_reminds));
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<TEstimate> = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  React.useEffect(() => {
    open && fetchEstimateData();
  }, [open]);

  return (
    <Drawer
      width={'80%'}
      title="Estimate AR"
      placement="right"
      onClose={onClose}
      extra={
        <Space>
          <Button onClick={onClose}>Close</Button>
        </Space>
      }
      open={open}
    >
      <Spin spinning={loading}>
        <InvoiceReminds
          containers={[container]}
          charges={charges}
          reminds={{ [container.number]: reminds }}
        />

        <Divider />
        <Table
          size="small"
          pagination={false}
          rowKey="id"
          columns={columns}
          dataSource={data}
          footer={() => (
            <Space className="w100" direction="vertical" align="end">
              <h4>Total {total.toFixed(2)}</h4>
            </Space>
          )}
        />
      </Spin>
    </Drawer>
  );
};
