import React from 'react';
import { Modal, Button, message } from 'antd';
import { useApp } from '@/utils/useapp';
import { saveAs } from 'file-saver';
import { Excels } from '@/components/Upload/Excels';
import { showErrorMessage } from '@/utils/show-error-message';

const Import: React.FC<{ onUploaded: () => void }> = ({ onUploaded }) => {
  const app = useApp();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [enableEmptyUpload, setEnableEmptyUpload] = React.useState(false);

  const showModal = () => {
    setEnableEmptyUpload(true);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const downloadTemplate = async () => {
    try {
      const file = await app.service.get('rates/template/download', {
        responseType: 'blob',
      });
      const filename = 'DrayEasyRateBulkUploadTemplate.xlsx';
      saveAs(file, filename);
    } catch (e: any) {
      showErrorMessage(e);
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Bulk Upload
      </Button>
      <Modal
        title="Bulk Upload"
        open={isModalVisible}
        onOk={downloadTemplate}
        okText="Download Template"
        onCancel={handleCancel}
      >
        <Excels
          uploadAction="rates/bulk"
          enableEmptyUpload={enableEmptyUpload}
          onUploaded={onUploaded}
        />
      </Modal>
    </>
  );
};

export default Import;
