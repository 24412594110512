export const ACTION_DONE = 'DONE';
export const ACTION_NA = 'NA';
export const ACTION_UNDO = 'UNDO';
export const ACTION_YES = 'YES';
export const ACTION_NO = 'NO';
export const ACTION_SEND_PRE_DEL_APPT = 'SEND PRE DEL APPT';
export const ACTION_CREATE_DO = 'ACTION_CREATE_DO';
export const ACTION_SEND_DO = 'SEND DO';
export const ACTION_INPUT = 'INPUT';
export const ACTION_INPUTANDACTION = 'INPUTANDACTION';
export const ACTION_DATE = 'DATE';
export const ACTION_DATE_TIME = 'DATE_TIME';
export const ACTION_DATE_TIME_RANGE = 'DATE_TIME_RANGE';
export const ACTION_ONLY_ONE = 'SEND';
export const ACTION_SEND_POD = 'SEND POD';
export const ACTION_EDIT = 'EDIT';
export const ACTION_SELLING_RATE_LINK = 'Selling Rate link';
export const ACTION_BUY_RATE_LINK = 'Buy Rate link';
export const ACTION_USE_CHASSIS_FRONT_LINK = 'Use chasssis front link';
export const ACTION_EDIT_SELL_RATE_ID = 'EDIT Sell Rate';
export const ACTION_EDIT_BUY_RATE_ID = 'EDIT Buy Rate';
export const ACTION_SEND_PU_REQUEST = 'SEND PU REQUEST';
export const ACTION_UPLOAD = 'UPLOAD';
export const ACTION_STREET_TURN = 'Street Turn';
export const ACTION_RESCHEDULE = 'Reschedule';
export const ACTION_CONFIRM_DO = 'Confirm DO';
export const ACTION_SEND_VERIFICATION = 'Send Verification';

export const ACTION_VENDOR_RATING = 'Vendor Rating';

export const TASK_TYPE_FILE = 'file';
export const TASK_TYPE_VENDOR = 'vendor';
export const TASK_TYPE_WAREHOUSE = 'warehouse';
export const TASK_TYPE_TODOLIST = 'todoList'; //only for frontend

export const TASK_TYPES = [
  TASK_TYPE_FILE,
  TASK_TYPE_VENDOR,
  TASK_TYPE_WAREHOUSE,
];

export const TEXT_COLOR_DEFAULT = 0;
export const TEXT_COLOR_BLUE = 2;
export const TEXT_COLOR_RED = 5;
export const TEXT_COLOR_MAP = {
  [TEXT_COLOR_DEFAULT]: '',
  [TEXT_COLOR_BLUE]: 'primary',
  [TEXT_COLOR_RED]: 'danger',
};
