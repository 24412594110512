import { useApp } from '@/utils/useapp';
import { Modal, Table, message } from 'antd';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import React from 'react';
import type { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TitleSlaTooltip } from '../ActionTasks/TitleSlaTooltip';
import { showErrorMessage } from '@/utils/show-error-message';

export const TYPE_DISPATCH = 'dispatch';
export const TYPE_CUSTOMER = 'customer';
export const TYPE_AR = 'ar';

export const StatusSlaHistory: React.FC<{
  container: any;
  type: 'dispatch' | 'customer' | 'ar';
}> = ({ container, type }) => {
  const app = useApp();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const title = useMemo(() => {
    switch (type) {
      case TYPE_CUSTOMER:
        return 'Customer Status Sla';
      case TYPE_DISPATCH:
        return 'Dispatch Status Sla';
      case TYPE_AR:
        return 'AR Status Sla';
    }
  }, [type]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible && container.id && type) {
      setLoading(true);
      app.service
        .get(`containers/${container.id}/statusSla/${type}`)
        .then((resp) => {
          setData(resp.data);
        })
        .catch((err: any) => {
          showErrorMessage(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isModalVisible]);

  const columns: ColumnsType<any> = [
    {
      title: 'Status',
      key: 'status_name',
      dataIndex: 'status_name',
      width: 300,
    },
    {
      title: 'Start At',
      key: 'start_at',
      dataIndex: 'start_at',
    },
    {
      title: 'Due At',
      key: 'due_at',
      dataIndex: 'due_at',
    },
    {
      title: <TitleSlaTooltip />,
      key: 'sla_text',
      dataIndex: 'sla_text',
    },
    {
      title: 'Finished At',
      key: 'finished_at',
      dataIndex: 'finished_at',
    },
    {
      title: 'Aging',
      key: 'aging',
      dataIndex: 'aging',
    },
  ];

  return (
    <>
      <div className="text-gray cursor-pointer py-sm" onClick={showModal}>
        <InfoCircleOutlined />
      </div>

      {isModalVisible && (
        <Modal
          cancelText="Close"
          footer={false}
          width={1000}
          style={{
            maxHeight: '700px',
            overflowY: 'auto',
          }}
          title={title}
          open={isModalVisible}
          onCancel={handleCancel}
        >
          <Table
            loading={loading}
            sticky
            pagination={false}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={data}
          />
        </Modal>
      )}
    </>
  );
};
