import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  message,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TableColumnsType,
  UploadFile,
  UploadProps,
  Popover,
  Typography,
} from 'antd';
import { VendorSelect } from '@/components/VendorSelect';
import moment from 'moment';
import { TBillCharge } from '@/types';
import { useApp } from '@/utils/useapp';
import { BillingCodeSelect } from '@/components/BillingCodeSelect';
import Decimal from 'decimal.js';
import { MinusCircleOutlined } from '@ant-design/icons';

import { useWatch } from 'antd/lib/form/Form';
import { TerminalSelect } from '@/components/TerminalSelect';
import { OceanCarrierSelect } from '@/components/OceanCarrierSelect';
import { DraggerWithPaste } from '@/components/Upload/DraggerWithPaste';
import { concat, get } from 'lodash';
import { FormInstance } from 'antd/es/form';
import { CopyButton } from '@/components/CopyButton';
import { debounce, uniq } from 'lodash';
import {
  BILL_TYPE_CFS,
  BILL_TYPE_FTL,
  BILL_TYPE_LTL,
  BILL_TYPE_ORDER,
  BILLABLE_TYPE_TLVENDOR_MAP,
} from '../data';
import { TLVendorSelect } from '@/components/TLVendorSelect';
import { TLType } from '@/components/constants';
import { showErrorMessage } from '@/utils/show-error-message';

const { Text } = Typography;

interface Props {
  form: FormInstance;
  containers: Array<any>;
  containerNumber?: string;
  buyRate?: any;
  disabled?: boolean;
  loading?: boolean;
  onChangingContainerNumbers?: (numbers: any) => void;
  billableType: string;
  initialFiles?: Array<{
    name: string;
    content: string;
    type: string;
  }>;
}

// 为 File 对象添加自定义属性类型
interface CustomFile extends File {
  uid: string;
}

const BillForm: React.FC<Props> = ({
  form,
  containers,
  containerNumber,
  buyRate,
  disabled = false,
  loading = false,
  onChangingContainerNumbers,
  billableType,
  initialFiles,
}) => {
  const app = useApp();

  const files = useWatch('files', form);
  const [newFiles, setNewFiles] = React.useState<CustomFile[]>([]);
  const [similarInvoiceNumbers, setSimilarInvoiceNumbers] = useState([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);

  const BILLCODE_CATEGORY_MAP: Record<string, string> = {
    [BILL_TYPE_ORDER]: 'DRAY',
    [BILL_TYPE_LTL]: 'LTL',
    [BILL_TYPE_FTL]: 'FTL',
    [BILL_TYPE_CFS]: 'CFS',
  };

  const BILL_TO_MAP: Record<string, string> = {
    [BILL_TYPE_ORDER]: 'App\\Models\\Vendor',
    [BILL_TYPE_LTL]: 'App\\Domains\\TL\\Models\\LTLVendor',
    [BILL_TYPE_FTL]: 'App\\Domains\\FTL\\Models\\FTLVendor',
    [BILL_TYPE_CFS]: 'App\\Domains\\CFS\\Models\\CFSVendor',
  };

  const isApproved = useWatch('is_approved', form);
  const billId = useWatch('id', form);

  const _containers = React.useMemo(() => {
    return containers.filter(
      (c) => !containerNumber || c.number == containerNumber,
    );
  }, [containers, containerNumber]);

  const fileList: UploadFile[] = React.useMemo(() => {
    const urls: string[] = [];
    const list = [
      ...(files || []).map((file: string, index: number) => ({
        uid: file,
        name: file,
        status: 'done',
        url: file,
        index,
      })),
      ...(newFiles ?? []).map((file) => {
        const url = URL.createObjectURL(file);
        urls.push(url);
        return {
          uid: file.uid || Date.now().toString(),
          name: file.name,
          size: file.size,
          type: file.type,
          originFileObj: file,
          status: 'done',
          url: url,
        };
      }),
    ];
    setFileUrls(urls);
    return list;
  }, [files, newFiles]);

  const setVendorToBillTo = (vendor: any) => {
    const terms = vendor?.terms;
    const values: any = {
      bill_to: vendor,
      bill_to_id: vendor?.id,
      bill_to_type: BILL_TO_MAP[billableType],
    };

    if (terms) {
      values.terms = terms;
      values.due_at = moment(form.getFieldValue('billed_at'))
        .add(terms, 'days')
        .format('YYYY-MM-DD');
    } else {
      values.terms = null;
      values.due_at = null;
    }
    form.setFieldsValue(values);
  };

  const handleFilesChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'removed') {
      if (info.file.originFileObj) {
        setNewFiles(newFiles.filter((f) => f.name !== info.file.name));
      } else {
        form.setFieldsValue({
          files: form
            .getFieldValue('files')
            .filter((f: string) => f !== info.file.name),
        });
      }
    }
    // add new files
    if (info.file instanceof File) {
      const fileWithUid = info.file as CustomFile;
      fileWithUid.uid = Date.now().toString();
      setNewFiles([...newFiles, fileWithUid]);
    }
  };

  const handlePasteFiles = async (pasteFiles: any) => {
    pasteFiles && setNewFiles(concat(newFiles, pasteFiles));
  };

  const toChangeContainerNumbers = (allCharges: any) => {
    const numbers = uniq(allCharges.map((c: any) => c.containerNumber));
    onChangingContainerNumbers && onChangingContainerNumbers(numbers);
  };

  const debounceFetcher = useMemo(() => {
    const handleCheckNameSimilarity = async (invoiceNumber: string) => {
      setSimilarInvoiceNumbers([]);
      if (invoiceNumber.length < 3) {
        return;
      }

      try {
        const resp = await app.service.get(
          'orderBills/checkInvoiceNumberSimilarity',
          {
            params: {
              invoice_number: invoiceNumber,
              order_bill_id: form.getFieldValue('id'),
            },
          },
        );

        setSimilarInvoiceNumbers(resp.data);
      } catch (err: any) {
        showErrorMessage(err);
      }
    };
    return debounce(handleCheckNameSimilarity, 500);
  }, []);

  const columns: TableColumnsType<any> = React.useMemo(() => {
    const cols: TableColumnsType<any> = [
      {
        title: 'Code',
        width: 120,
        render: (_, row: TBillCharge, index) => (
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name={['charges', index, 'code']}
                noStyle
                hasFeedback
                rules={[{ required: true, message: '' }]}
              >
                <BillingCodeSelect
                  disabled={isApproved}
                  style={{ width: '100%' }}
                  value={getFieldValue(['charges', index, 'code'])}
                  category={BILLCODE_CATEGORY_MAP[billableType]}
                  onSelect={(c) =>
                    setFieldsValue({
                      charges: getFieldValue('charges').map((charge, i) =>
                        i == index
                          ? { ...charge, code: c?.code, name: c?.name }
                          : charge,
                      ),
                    })
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
        ),
      },
      {
        title: 'Name',
        width: 120,
        render: (_, row: TBillCharge, index) => (
          <Form.Item
            name={['charges', index, 'name']}
            noStyle
            hasFeedback
            rules={[{ required: true, message: '' }]}
          >
            <Input disabled={isApproved} style={{ width: '100%' }} />
          </Form.Item>
        ),
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        width: 120,
        render: (_, row: TBillCharge, index) => (
          <Form.Item
            name={['charges', index, 'rate']}
            noStyle
            hasFeedback
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber disabled={isApproved} style={{ width: '100%' }} />
          </Form.Item>
        ),
      },
      {
        title: 'Qty',
        dataIndex: 'qty',
        width: 80,
        render: (_, row: TBillCharge, index) => (
          <Form.Item
            name={['charges', index, 'qty']}
            noStyle
            hasFeedback
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber disabled={isApproved} style={{ width: '100%' }} />
          </Form.Item>
        ),
      },
      {
        title: () => {
          return (
            <Space>
              <span>Amount</span>
            </Space>
          );
        },
        width: 120,
        render: (_, row: TBillCharge, index) => (
          <Input
            style={{ width: '100%' }}
            value={new Decimal(row.qty || 0)
              .times(new Decimal(row.rate || 0))
              .toFixed(2)}
            disabled
          />
        ),
      },
      {
        title: '',
        width: 80,
        render: (_, row: TBillCharge, index) => (
          <Button
            type="text"
            disabled={isApproved}
            size="small"
            onClick={() => {
              const list = [...form.getFieldValue('charges')];
              list.splice(index, 1);
              form.setFieldsValue({ charges: list });
              toChangeContainerNumbers(list);
            }}
          >
            <MinusCircleOutlined />
          </Button>
        ),
      },
    ];
    if (billableType == BILL_TYPE_ORDER) {
      cols.unshift({
        title: () => (
          <Space>
            <span>CNTR#</span>
          </Space>
        ),
        width: 180,
        render: (_, row: TBillCharge, index) => (
          <Space>
            <Form.Item
              name={['charges', index, 'containerNumber']}
              noStyle
              hasFeedback
              rules={[{ required: true, message: '' }]}
            >
              <Select
                disabled={isApproved}
                style={{ width: 155 }}
                options={_containers.map((cntr) => ({
                  container_id: cntr.id,
                  value: cntr.number,
                  text: cntr.number,
                }))}
              />
            </Form.Item>
            {form.getFieldValue(['charges', index, 'containerNumber']) && (
              <CopyButton
                value={form.getFieldValue([
                  'charges',
                  index,
                  'containerNumber',
                ])}
              />
            )}
          </Space>
        ),
      });
    }

    return cols;
  }, [containers]);

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.charges) {
      toChangeContainerNumbers(allValues.charges);
      return;
    }
  };

  useEffect(() => {
    const vendor = get(buyRate, 'vendor');
    if (vendor && !form.getFieldValue('id')) {
      setVendorToBillTo(vendor);
    }
  }, [buyRate]);

  React.useEffect(() => {
    form && form.setFieldsValue({ new_files: newFiles });
  }, [newFiles]);

  React.useEffect(() => {
    if (billableType === BILL_TYPE_LTL) {
      form &&
        form.setFieldsValue({
          bill_to_type: 'App\\Domains\\TL\\Models\\LTLVendor',
        });
    }

    if (billableType === BILL_TYPE_FTL) {
      form &&
        form.setFieldsValue({
          bill_to_type: 'App\\Domains\\FTL\\Models\\FTLVendor',
        });
    }
  }, [billableType]);

  // 处理初始文件
  useEffect(() => {
    if (initialFiles?.length) {
      const processedFiles = initialFiles.map((file) => {
        // 从 base64 字符串创建 Blob
        const byteString = atob(file.content.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: `application/${file.type}` });

        // 创建 File 对象
        const fileObject = new File([blob], file.name, {
          type: `application/${file.type}`,
        });

        // 添加 uid 属性
        Object.defineProperty(fileObject, 'uid', {
          value: Date.now().toString(),
          writable: true,
        });

        return fileObject as CustomFile;
      });

      setNewFiles(processedFiles);
      form.setFieldsValue({
        new_files: processedFiles,
      });
    }
  }, [initialFiles]);

  // 清理函数
  useEffect(() => {
    return () => {
      fileUrls.forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, [fileUrls]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          disabled={disabled}
          onValuesChange={handleValuesChange}
          initialValues={{ bill_to_type: 'App\\Models\\Vendor', charges: [] }}
        >
          <Form.Item name="id" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name="billable_id" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name="billable_type" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name="is_approved" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item label="Bill From" required shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => {
              return (
                <>
                  {billableType !== BILL_TYPE_ORDER ? (
                    <>
                      <Form.Item
                        name="bill_to_type"
                        hidden
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: 'Bill From Type is required.',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="bill_to_id"
                        noStyle
                        rules={[
                          {
                            type: 'number',
                            required: true,
                            message: 'Bill From is required.',
                          },
                        ]}
                      >
                        <TLVendorSelect
                          tlType={
                            BILLABLE_TYPE_TLVENDOR_MAP[
                              billableType as keyof typeof BILLABLE_TYPE_TLVENDOR_MAP
                            ]
                          }
                          selected={getFieldValue('bill_to_id')}
                          onSelect={(row: any) => {
                            setVendorToBillTo(row);
                          }}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Input.Group compact style={{ width: '100%' }}>
                      <Form.Item
                        name="bill_to_type"
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: 'Bill From Type is required.',
                          },
                        ]}
                      >
                        <Select
                          style={{ width: '20%' }}
                          value={getFieldValue('bill_to_type')}
                          onChange={(value) =>
                            setFieldsValue({ bill_to_type: value })
                          }
                        >
                          <Select.Option value="App\Models\Vendor">
                            Vendor
                          </Select.Option>
                          <Select.Option value="App\Models\Terminal">
                            Terminal
                          </Select.Option>
                          <Select.Option value="App\Models\OceanCarrier">
                            OceanCarrier
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <div style={{ width: '80%' }}>
                        <Form.Item
                          name="bill_to_id"
                          noStyle
                          rules={[
                            {
                              type: 'number',
                              required: true,
                              message: 'Bill From is required.',
                            },
                          ]}
                        >
                          {getFieldValue('bill_to_type') ===
                            'App\\Models\\Vendor' && (
                            <VendorSelect
                              selected={getFieldValue('bill_to')}
                              onSelect={(row: any) => {
                                setVendorToBillTo(row);
                              }}
                            />
                          )}
                          {getFieldValue('bill_to_type') ===
                            'App\\Models\\Terminal' && (
                            <TerminalSelect
                              selected={getFieldValue('bill_to')}
                              onSelect={(row) =>
                                setFieldsValue({
                                  bill_to: row,
                                  bill_to_id: row?.id,
                                  bill_to_type: 'App\\Models\\Terminal',
                                })
                              }
                            />
                          )}
                          {getFieldValue('bill_to_type') ===
                            'App\\Models\\OceanCarrier' && (
                            <OceanCarrierSelect
                              style={{ width: '100%' }}
                              value={getFieldValue('bill_to_id')}
                              onSelect={(row) =>
                                setFieldsValue({
                                  bill_to: row,
                                  bill_to_id: row?.id,
                                  bill_to_type: 'App\\Models\\OceanCarrier',
                                })
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Input.Group>
                  )}
                </>
              );
            }}
          </Form.Item>
          <Form.Item
            name="invoice_number"
            label="INV#"
            rules={[{ required: true, message: 'INV# is required.' }]}
          >
            <Input onBlur={(e) => debounceFetcher(e.target.value)} />
          </Form.Item>
          <div>
            {similarInvoiceNumbers.length > 0 && (
              <div className="mb-xl">
                <Space>
                  <Popover
                    placement="bottom"
                    content={
                      <Space direction="vertical">
                        {similarInvoiceNumbers.map((invoiceNumber) => (
                          <Text type="secondary" key={invoiceNumber}>
                            {invoiceNumber}
                          </Text>
                        ))}
                      </Space>
                    }
                  >
                    <span className="text-link text-underline">
                      Click to view similar INV#
                    </span>
                  </Popover>
                  <Text type="danger">
                    Similar invoice numbers are detected. Are you sure to
                    continue?.{' '}
                  </Text>
                </Space>
              </div>
            )}
          </div>
          <Row gutter={24}>
            <Col md={8}>
              <Form.Item
                label="Bill Date"
                name="billed_at"
                required
                rules={[{ required: true, message: 'Bill Date is required' }]}
              >
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.billed_at !== curValues.billed_at
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value = getFieldValue('billed_at');
                    const terms = getFieldValue('terms') || 0;
                    return (
                      <DatePicker
                        style={{ width: '100%' }}
                        value={value ? moment(value) : null}
                        onChange={(v) =>
                          setFieldsValue({
                            billed_at: v?.format('YYYY-MM-DD'),
                            due_at: v?.add(terms, 'days').format('YYYY-MM-DD'),
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Terms"
                name="terms"
                required
                rules={[{ required: true, message: 'Terms is required' }]}
              >
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.terms !== curValues.terms
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value = getFieldValue('terms');
                    const billedAt = getFieldValue('billed_at');
                    return (
                      <InputNumber
                        style={{ width: '100%' }}
                        value={value}
                        onChange={(v) =>
                          setFieldsValue({
                            terms: v,
                            due_at: billedAt
                              ? moment(billedAt)
                                  .add(v, 'days')
                                  .format('YYYY-MM-DD')
                              : null,
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Due Date"
                name="due_at"
                required
                rules={[{ required: true, message: 'Due Date is required' }]}
              >
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.due_at !== curValues.due_at
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value = getFieldValue('due_at');
                    return (
                      <DatePicker
                        style={{ width: '100%' }}
                        value={value ? moment(value) : null}
                        onChange={(v) =>
                          setFieldsValue({
                            due_at: v?.format('YYYY-MM-DD'),
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={8}>
              <Form.Item name="amount_total" label="Amount Total">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="amount_due" label="Amount Due">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="amount_paid" label="Amount Paid">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Charges</Divider>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Table
                bordered
                size="small"
                columns={columns}
                dataSource={getFieldValue('charges')}
                scroll={{ y: 300 }}
                pagination={false}
                summary={(pageData) => {
                  let total = new Decimal(0);

                  pageData.forEach(({ rate, qty }) => {
                    total = total.add(
                      new Decimal(rate || 0).times(new Decimal(qty || 0)),
                    );
                  });

                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={1} align="left">
                          <Button
                            size="small"
                            disabled={isApproved}
                            onClick={() => {
                              form.setFieldsValue({
                                charges: [
                                  ...(form.getFieldValue('charges') || []),
                                  {
                                    containerNumber: containerNumber
                                      ? containerNumber
                                      : '',
                                    qty: 1,
                                  },
                                ],
                              });
                            }}
                          >
                            Add Charge
                          </Button>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={0}
                          colSpan={columns.length - 3}
                          align="right"
                        >
                          <strong>Total</strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <strong>{total.toFixed(2)}</strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} colSpan={100} />
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
            )}
          </Form.Item>

          <Form.Item name="files" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name="new_files" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item className="mt-md" label="Files">
            <DraggerWithPaste
              disabled={disabled}
              uploadProp={{
                multiple: true,
                beforeUpload: () => false,
                fileList: fileList,
                onPreview: async (file: UploadFile) => {
                  if (file.originFileObj) {
                    // 如果是新上传的文件，直接预览
                    const url = URL.createObjectURL(file.originFileObj);
                    window.open(url);
                  } else if (billId && file.index !== undefined) {
                    // 如果是已存在的文件，从服务器获取
                    const resp = await app.service.get(
                      `orderBills/${billId}/files/${file.index}`,
                    );
                    window.open(resp.url);
                  }
                },
                onChange: handleFilesChange,
              }}
              handlePasteFiles={handlePasteFiles}
            />
          </Form.Item>

          <Form.Item className="mt-md" name="memo" label="Memo">
            <Input.TextArea rows={5} />
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
export default BillForm;
