import React from 'react';
import { Button, message, Drawer, Space } from 'antd';
import { useApp } from '@/utils/useapp';
import { TInvoice } from '@/types';
import { useForm } from 'antd/lib/form/Form';
import PaymentForm from './PaymentForm';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  onSaved: () => void;
  invoices: TInvoice[];
}

const Payment: React.FC<Props> = ({ onSaved, invoices = [] }) => {
  const app = useApp();

  const [visible, setVisible] = React.useState(false);
  const [form] = useForm();
  const [submitting, setSubmitting] = React.useState(false);

  const showDrawer = () => {
    if (invoices.length === 0) {
      return message.error('Please select at least one invoice');
    }

    const userId = invoices[0]?.user_id;
    const hasDifferentUser = invoices.some(
      (invoice) => invoice.user_id !== userId,
    );

    if (hasDifferentUser) {
      return message.error('Please select invoices from the same user');
    }

    form.resetFields();

    form.setFieldValue('user_id', userId);

    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setSubmitting(true);

    try {
      !values.id
        ? await app.service.post('orderInvoicePayments', { data: values })
        : await app.service.put(`orderInvoicePayments/${values.id}`, {
            data: values,
          });
      message.success('Saved');

      onSaved && onSaved();
      onClose();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setSubmitting(false);
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        Receive Payment
      </Button>
      <Drawer
        width={'80%'}
        title="Receive Payment"
        placement="right"
        onClose={onClose}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type="primary" loading={submitting} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <PaymentForm form={form} invoices={invoices} />
      </Drawer>
    </>
  );
};

export default Payment;
