import { useApp } from '@/utils/useapp';
import {
  Space,
  Button,
  message,
  Drawer,
  Divider,
  Popconfirm,
  Input,
} from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import styles from 'res/css/ui.scss';
import VendorForm from './components/VendorForm';
import { TCollection } from '@/types';
import AutoResizeTable from '@/components/AutoResizeTable';
import { LogActivities } from '@/components/LogActivities';
import { showErrorMessage } from '@/utils/show-error-message';

interface Vendor {
  id: number;
  name: string;
  code: string;
  endpoint: string;
  token: string;
  enable: boolean;
}

const Index: React.FC = () => {
  const app = useApp();
  const [data, setData] = useState<TCollection<Vendor>>();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<Vendor>();

  const fetchData = async (
    pagination?: TablePaginationConfig,
    query?: string,
  ) => {
    setLoading(true);
    try {
      const resp = await app.service.get('tl/ltl/vendors', {
        params: {
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 50,
          query,
        },
      });
      setData(resp);
      setLoading(false);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetchData(pagination);
  };

  const handleNewVendor = () => {
    console.log('new tl vendor');
    setModel(undefined);
    setDrawerVisible(true);
  };

  const handleEdit = (m: any) => {
    setModel(m);
    setDrawerVisible(true);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await app.service.delete(`tl/ltl/vendors/${id}`);
      fetchData();
    } catch (e: any) {
      showErrorMessage(e);
    }
    setLoading(false);
  };

  const handleSearch = (value: string) => {
    fetchData(undefined, value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns: ColumnsType<Vendor> = [
    {
      title: 'Vendor#',
      dataIndex: 'id',
      sorter: true,
      width: 150,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Endpoint',
      dataIndex: 'endpoint',
    },
    {
      title: 'Customer Enabled',
      dataIndex: 'customer_portal_enable',
      render: (text) => (text ? 'Yes' : 'No'),
      width: 150,
    },
    {
      title: 'Admin Enabled',
      dataIndex: 'admin_portal_enable',
      render: (text) => (text ? 'Yes' : 'No'),
      width: 150,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <Space>
          <LogActivities id={record.id} type="ltlVendor" icon={<a>log</a>} />
          <Divider type="vertical" />
          <a onClick={() => handleEdit(record)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => {
              handleDelete(record.id);
            }}
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="TL Vendor"
        rightElement={
          <Space align="end" direction="horizontal">
            <Input.Search
              style={{ width: '320px' }}
              placeholder="Search by name or code"
              allowClear
              disabled={loading}
              onSearch={(value: string) => handleSearch(value)}
            />
            <Button type="primary" onClick={handleNewVendor}>
              New TL Vendor
            </Button>
          </Space>
        }
      />

      <AutoResizeTable
        loading={loading}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={handleTableChange}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
      <Drawer
        title=""
        placement="right"
        width="90%"
        onClose={() => {
          fetchData();
          setDrawerVisible(false);
        }}
        destroyOnClose={true}
        open={drawerVisible}
      >
        <VendorForm
          model={model}
          onCreated={() => {
            fetchData();
            setDrawerVisible(false);
          }}
          onClose={() => setDrawerVisible(false)}
        />
      </Drawer>
    </div>
  );
};

export default Index;
