import { useApp } from '@/utils/useapp';
import { Button, Divider, Form, message, Radio, Rate, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FC, useEffect, useMemo, useState } from 'react';
import { Skeleton } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Input from 'antd/lib/input/Input';
import { RatingTag } from '@/components/RatingTag';
import { showErrorMessage } from '@/utils/show-error-message';

const QuestionnarieForm: FC<{
  questionnarieId: number;
  onSumbit: (values: any, id: number) => void;
  openResponseId?: number;
  autoRating?: any;
  openByVendorContainer?: {
    container_id: number;
    vendor_id: number;
  };
  // onAutoRating?: Promise<any>;
  // container?: any;
}> = ({
  questionnarieId,
  onSumbit,
  openResponseId,
  autoRating,
  openByVendorContainer,
}) => {
  const app = useApp();
  const [data, setData] = useState<any>({});
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [response, setResponse] = useState<any>({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await app.service.get(`questionnaires/${questionnarieId}`);

      if (openResponseId) {
        const resp = await app.service.get(`vendorRatingsV2/${openResponseId}`);
        form.setFieldsValue(resp.responses);
        setResponse(resp);
      }
      if (openByVendorContainer) {
        const resp = await app.service.get(
          'vendorRatingsV2/byVendorContainer',
          {
            params: openByVendorContainer,
          },
        );
        form.setFieldsValue(resp.data.responses);
        setResponse(resp.data);
      }
      setData(result.data);
    } catch (e: any) {
      showErrorMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const hiddenItem = useMemo(() => {
    return !!openByVendorContainer || !openResponseId;
  }, [openByVendorContainer, openResponseId]);

  const handleSave = async (values: any) => {
    setSubmitting(true);
    try {
      await onSumbit(values, response.id);
    } catch (err: any) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Form layout="vertical" form={form} onFinish={handleSave}>
          {!hiddenItem && <h4>Overall</h4>}
          <>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item hidden={hiddenItem} noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => (
                <Space align="center">
                  <Form.Item
                    // noStyle
                    name={'overall'}
                  >
                    <RatingTag rating={getFieldValue('overall')} />
                    {/* <Rate
                      disabled={true}
                      style={{ fontSize: 36 }}
                      allowHalf
                      tooltips={desc}
                      onChange={(v) => setFieldValue('overall', v)}
                      value={getFieldValue('overall')}
                    /> */}
                  </Form.Item>
                </Space>
              )}
            </Form.Item>
            <Divider />
            {(data.enabled_questions || []).map(
              (question: any, idx: number) => {
                return (
                  <div key={`questions_${idx}`}>
                    {!(
                      hiddenItem &&
                      question?.custom_properties['allowAutoRating']
                    ) && (
                      <h4>
                        {idx + 1} . {question.title}{' '}
                        <span className="text-gray">
                          ({question.description})
                        </span>
                      </h4>
                    )}
                    <Form.Item
                      hidden={
                        hiddenItem &&
                        question?.custom_properties['allowAutoRating']
                      }
                      noStyle
                      shouldUpdate
                    >
                      {({ getFieldValue, setFieldValue }) => (
                        <Space align="start">
                          <Form.Item
                            initialValue={
                              question.custom_properties['allowAutoRating']
                                ? null
                                : ''
                            }
                            name={question.id}
                            rules={[
                              {
                                required: !question.custom_properties[
                                  'allowAutoRating'
                                ],
                                message: 'Please rate',
                              },
                            ]}
                          >
                            <Rate
                              disabled={
                                question?.custom_properties['allowAutoRating']
                              }
                              allowHalf
                              tooltips={desc}
                              onChange={(v) => setFieldValue(question.id, v)}
                              value={getFieldValue(question.id)}
                            />
                          </Form.Item>
                          {!question?.custom_properties['allowAutoRating'] ? (
                            <Button
                              // disabled={
                              //   question?.custom_properties['allowAutoRating']
                              // }
                              type="link"
                              onClick={() => setFieldValue(question.id, null)}
                            >
                              Clear
                            </Button>
                          ) : (
                            <Button disabled type="link">
                              (Auto Rating, No Action Needed)
                            </Button>
                          )}
                        </Space>
                      )}
                    </Form.Item>
                  </div>
                );
              },
            )}
            <Form.Item label="Comment" name="comment">
              <TextArea rows={6} />
            </Form.Item>
          </>
          <Button
            // disabled={!editable}
            loading={submitting}
            type={'primary'}
            htmlType="submit"
          >
            Save
          </Button>
        </Form>
      )}
    </>
  );
};

export default QuestionnarieForm;
