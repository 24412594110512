import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useApp } from '@/utils/useapp';
import type { Moment } from 'moment';
import moment from 'moment-timezone';
import { DateRender } from './DateRender';
import styles from 'res/css/calendar.scss';
import { SubTabInterface } from '../..';
import Calendar from '@/components/Calendar';
import {
  OPdashboardCalendarInterface as CalendarItem,
  LTLCalendarItem,
} from '@/Interfaces/OPdashboardTab/OPdashboardCalendarInterface';
import { showErrorMessage } from '@/utils/show-error-message';
const _Calendar: React.FC<
  {
    isCalendarTab: boolean;
  } & SubTabInterface
> = ({
  opDashboardTab,
  isCalendarTab = false,
  refresh = true,
  setRefresh,
  goToOperationTable,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CalendarItem & LTLCalendarItem[]>();

  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').startOf('week').format('YYYY-MM-DD'),
    end: moment().endOf('month').endOf('week').format('YYYY-MM-DD'),
  });

  const fetchData = async () => {
    if (!opDashboardTab?.id || loading) {
      return;
    }

    setData(undefined);

    setLoading(true);

    try {
      const resp = await app.service.get(
        `tl/opdashboard/${opDashboardTab.id}/calendar`,
        {
          params: {
            date_range: dateRange,
          },
        },
      );

      setData(resp.data || []);

      refresh && setRefresh(false);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (opDashboardTab?.id && isCalendarTab && !refresh) {
      fetchData();
    }
  }, [isCalendarTab, opDashboardTab]);

  useEffect(() => {
    if (opDashboardTab?.id && isCalendarTab && refresh) {
      fetchData();
    }
  }, [refresh]);

  const dateCellRender = (value: Moment) => {
    const today = moment().tz('America/Los_Angeles');
    const valueInLA = moment(value).tz('America/Los_Angeles');
    const valueString = valueInLA.format('DD');
    const isToday = valueInLA.isSame(today, 'day');

    const record = data?.find(
      (row) => row.date == valueInLA.format('YYYY-MM-DD'),
    );

    return (
      <div className={`${styles.customDateCell}`}>
        <div onClick={() => console.log('')}>
          <div className={styles.dateRow}>
            <div className={`${isToday ? styles.customToday : styles.date}`}>
              {' '}
              {valueString}
            </div>
          </div>
          <DateRender
            currentData={record}
            today={today}
            goToOperationTable={goToOperationTable}
            isPre={valueInLA.isBefore(today, 'day')}
            isToday={isToday}
            isFuture={valueInLA.isAfter(today, 'day')}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  return (
    <Calendar
      loading={loading}
      dateCellRender={dateCellRender}
      updateDataRange={setDateRange}
    />
  );
};

export default _Calendar;
