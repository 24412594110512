import React from 'react';
import {
  TTarget,
  TARGET_TYPE_LTL_SHIPMENT,
  TARGET_TYPE_FTL_SHIPMENT,
  TARGET_TYPE_CFS_SHIPMENT,
} from '../Interfaces/ActionInterface';

import LTLActions from '../Actions/LTL';
import FTLActions from '../Actions/FTL';
import CFSTaskActions from '../Actions/CFS';

import {
  OnDoneInterface,
  onSaveOperationInterface,
} from '../Interfaces/TaskInterface';

function withConditionalRendering(
  WrappedComponentLTL,
  WrappedComponentFTL,
  WrappedComponentCFS,
) {
  return function ConditionalRenderer(
    props: TTarget & OnDoneInterface & onSaveOperationInterface,
  ) {
    switch (props.target) {
      case TARGET_TYPE_LTL_SHIPMENT:
        return <WrappedComponentLTL {...props} />;
      case TARGET_TYPE_FTL_SHIPMENT:
        return <WrappedComponentFTL {...props} />;
      case TARGET_TYPE_CFS_SHIPMENT:
        return <WrappedComponentCFS {...props} />;
      default:
        return null;
    }
  };
}

const ConditionalShipmentTaskActions = withConditionalRendering(
  LTLActions,
  FTLActions,
  CFSTaskActions,
);

const ActionColumn: React.FC<TTarget> = (props) => {
  return <ConditionalShipmentTaskActions {...props} />;
};

export default ActionColumn;
