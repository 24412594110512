import { Address, UsedAddressShipment } from '@/components/AddressVerify/types';
import { UsedTable } from '@/components/AddressVerify/UsedTable';
import {
  DeploymentUnitOutlined,
  HistoryOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Modal, Space, Spin, Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { ShipmentBusinessStatus } from '../ltl/constants';
import { ShipmentBusinessStatusTag } from '../ltl/components/ShipmentBusinessStatusTag';
import { useApp } from '@/utils/useapp';

export interface LocationCellExtraProps {
  address: Address;
  currentShipmentId?: number;
  transitInfo?: {
    transitDays?: number;
    distance?: number;
  };
  showDrawer: (record: any) => void;
}

const LocationCellExtra = ({
  address,
  transitInfo,
  currentShipmentId,
  showDrawer,
}: LocationCellExtraProps) => {
  const app = useApp();
  const [showModal, setShowModal] = useState(false);
  const [usedAddressShipments, setUsedAddressShipments] = useState<
    UsedAddressShipment[] | null
  >(null);
  async function getUsedAddressShipments(
    address: Address,
  ): Promise<UsedAddressShipment[]> {
    const resp = await app.service.post('tl/tools/used-address-shipments', {
      data: {
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        cityId: address.cityId,
        zipcode: address.zipcode,
        currentShipmentId,
      },
    });
    return resp.data;
  }

  const usedReferences = {
    columns: [
      {
        title: 'Shipment#',
        dataIndex: 'order_number',
        render: (value: any, record: any) => (
          <Typography.Link onClick={() => showDrawer(record)}>
            {value}
          </Typography.Link>
        ),
      },
      { title: 'Vendor', dataIndex: 'vendor_name' },
      { title: 'Carrier', dataIndex: 'carrier_name' },
      {
        title: 'Location Memo',
        key: 'location_memo',
        render: (text: string, record: UsedAddressShipment) => {
          return (
            <Space>
              {record.address_match_type === 'pickup' ? (
                <span>{record.pickup_location_memo}</span>
              ) : record.address_match_type === 'destination' ? (
                <span>{record.destination_location_memo}</span>
              ) : (
                <></>
              )}
            </Space>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'business_status',
        render: (text: number) => {
          return (
            <ShipmentBusinessStatusTag
              business_status={text as ShipmentBusinessStatus}
            />
          );
        },
      },
      {
        title: 'Delivery Date',
        dataIndex: 'delivery_date',
      },
      {
        title: 'BOL',
        key: 'bol_url',
        dataIndex: 'bol_url',
        render: (text: string) => (
          <Space>
            <a href={text}>Download</a>
          </Space>
        ),
      },
    ],
    getUsedReferences: getUsedAddressShipments,
  };
  return (
    <>
      <Space size={8}>
        {transitInfo && (
          <Tooltip
            title={`${transitInfo.distance ?? '-'} miles; ${
              transitInfo.transitDays ?? '-'
            } days`}
          >
            {<DeploymentUnitOutlined />}
          </Tooltip>
        )}
        <Tooltip
          onOpenChange={async (open) => {
            if (open) {
              const shipments = await getUsedAddressShipments(address);
              setUsedAddressShipments(shipments);
            }
          }}
          title={
            <>
              {usedAddressShipments === null ? (
                <Spin
                  indicator={<LoadingOutlined style={{ color: 'white' }} />}
                />
              ) : usedAddressShipments.length > 0 ? (
                `This address has been used for ${usedAddressShipments.length} times.`
              ) : (
                'This address has never been used.'
              )}
            </>
          }
        >
          <div onClick={() => setShowModal(true)}>{<HistoryOutlined />}</div>
        </Tooltip>
      </Space>
      <Modal
        cancelText="Close"
        footer={false}
        width={1000}
        title="Tracking Information"
        open={showModal}
        onCancel={() => setShowModal(false)}
      >
        <UsedTable<UsedAddressShipment>
          address={address}
          getUsedReferences={usedReferences.getUsedReferences}
          columns={usedReferences.columns}
          directLoad={true}
        />
      </Modal>
    </>
  );
};

export default LocationCellExtra;
