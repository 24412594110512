import { useApp } from '@/utils/useapp';
import {
  Drawer,
  Input,
  Form,
  message,
  Select,
  InputNumber,
  Modal,
  Space,
  FormInstance,
} from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { DocumentsStoreLocalFormItem } from '@/components/Upload/DocumentsStoreLocalFormItem';
import { Documents } from '@/components/Upload/Documents';

import {
  RebillReasonOptions,
  RebillStatus,
  RebillStatusOptions,
} from '@/components/Tasks/Actions/data';
import { useWatch } from 'antd/lib/form/Form';

const RebillForm: FC<{
  form: FormInstance;
  disabled: boolean;
}> = ({ form, disabled, ...props }) => {
  const isEdit = useWatch('id', form);

  return (
    <Form
      layout="vertical"
      form={form}
      disabled={disabled}
      initialValues={{
        status: RebillStatus.Confirmed,
      }}
      {...props}
    >
      <Form.Item hidden name="id" />
      <Form.Item label="Reason" name="reason" rules={[{ required: true }]}>
        <Select disabled={isEdit} options={RebillReasonOptions} />
      </Form.Item>
      <Form.Item
        label="Amount"
        name={'amount'}
        required
        rules={[
          {
            required: true,
            validator: (_, value) => {
              if (value <= 0) {
                return Promise.reject(
                  new Error('Amount must be greater than 0'),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <InputNumber disabled={isEdit} min={0} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item label="Status" name="status" required>
        <Select options={RebillStatusOptions} />
      </Form.Item>
      <Form.Item label="Memo" name="memo" rules={[{ required: true }]}>
        <Input.TextArea disabled={isEdit} />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          getFieldValue('id') ? (
            <Documents
              title="Documents"
              target="rebill"
              targetId={getFieldValue('id')}
              disabled={disabled}
            />
          ) : (
            <DocumentsStoreLocalFormItem form={form} />
          )
        }
      </Form.Item>
    </Form>
  );
};

export default RebillForm;
