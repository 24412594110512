import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Tabs, Dropdown, Button, Spin, Empty, message } from 'antd';
import React, { FC, useEffect, useState } from 'react';

import type { MenuProps } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import {
  OPdashboardTabModelType,
  OPdashboardTabType,
} from '@/Interfaces/OPdashboardTab/OPdashboardTabType';
import { EditableText } from '@/components/Editable';
import useOpdashboardTab from './useOpdashboardTab';

import LTLFilter from './LTLFilter';
import { TabFilterType } from '@/Interfaces/TabFilterType';
import { OPdashboardTabInterface } from '@/Interfaces/OPdashboardTab/OPdashboardTabInterface';
import { showErrorMessage } from '@/utils/show-error-message';

export interface SubTabContentProps {
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
}
const Index: FC<{
  modelType: OPdashboardTabModelType;
  toSetActiveTab: (tab: OPdashboardTabInterface) => void;
  defaultActiveTab?: OPdashboardTabInterface | undefined;
  closeRefresh: boolean;
  toRefresh: () => void;
  SubTabContent: React.ReactNode & { props: SubTabContentProps };
}> = ({
  modelType,
  toSetActiveTab,
  defaultActiveTab,
  closeRefresh,
  SubTabContent,
  toRefresh,
}) => {
  const app = useApp();
  const { updateOpdashboardTab } = useOpdashboardTab();
  const [loading, setLoading] = useState(false);

  const [tabs, setTabs] = React.useState<OPdashboardTabInterface[]>([]);

  const [initShowFilter, setInitShowFilter] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<OPdashboardTabInterface>(
    defaultActiveTab,
  );

  const [editTab, setEditTab] = React.useState<number>(0);

  const fetchTabs = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`opdashboardTabs/${modelType}`);
      if (resp.data?.tabs.length > 0) {
        setTabs(resp.data.tabs || []);
        if (!defaultActiveTab?.id) {
          handleToSetActiveTab(resp.data.tabs[0]);
        }
      }
      setInitShowFilter(true);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleToSetActiveTab = (tab: OPdashboardTabInterface) => {
    setActiveTab(tab);
    toSetActiveTab(tab);
  };

  const handleAddTab = async () => {
    if (!modelType) {
      return;
    }
    setLoading(true);
    try {
      const reps = await app.service.post(`opdashboardTabs/${modelType}`, {
        data: { name: `Tab ${tabs.length}` },
      });
      setTabs([...tabs, { ...reps.data, filters: [] }]);

      setInitShowFilter(true);

      handleToSetActiveTab(reps.data);
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTab = async (id: number) => {
    if (tabs.length == 1) {
      return;
    }

    setLoading(true);
    try {
      await app.service.delete(`opdashboardTabs/${id}`);
      setTabs(tabs.filter((t) => t.id != id));

      message.success('Tab has been delete');
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const tabMenu = (id: number): MenuProps['items'] => {
    const items: MenuProps['items'] = [
      {
        label: 'Edit',
        key: 'edit' + id,
        onClick: () => {
          setEditTab(id);
        },
      },
      {
        label: 'Delete',
        key: 'delete' + id,
        onClick: () => {
          handleDeleteTab(id);
        },
      },
    ];

    return items;
  };

  const handleChangeCustomTab = async (key: string) => {
    const activeTab = tabs.find((tab) => tab.id == Number(key));

    if (!activeTab) {
      return;
    }

    handleToSetActiveTab(activeTab);

    setInitShowFilter(true);
  };

  const handleUpdateTab = async (
    name: string,
    value: string | any[] | Record<string, any>,
    id?: number,
  ) => {
    id = id || editTab;

    const tab = tabs.find((tab) => tab.id === id);
    const newTab = await updateOpdashboardTab(name, value, tab);
    if (newTab) {
      setTabs(tabs.map((t) => (t.id == id ? newTab : t)));
      handleToSetActiveTab(newTab);
    }

    if (id == editTab) {
      setEditTab(0);
    }

    return newTab;
  };

  const handleApply = async (filters: TabFilterType[]) => {
    const tab = await handleUpdateTab('filters', filters, activeTab?.id);

    handleToSetActiveTab(tab);
  };

  const handleSearch = () => {
    toRefresh();
  };

  useEffect(() => {
    fetchTabs();
  }, []);

  useEffect(() => {
    if (closeRefresh) {
      setRefresh(false);
    }
  }, [closeRefresh]);

  return (
    <>
      <Tabs
        key={'tabs'}
        activeKey={activeTab?.id.toString()}
        onChange={handleChangeCustomTab}
        tabBarExtraContent={
          <Button type="link" onClick={handleAddTab}>
            New Tab
          </Button>
        }
      >
        {tabs?.length > 0 &&
          tabs?.map((tab: OPdashboardTabInterface) => (
            <TabPane
              tab={
                editTab !== tab.id ? (
                  <Dropdown
                    menu={{ items: tabMenu(tab.id) }}
                    trigger={['contextMenu']}
                  >
                    <div>{tab.name}</div>
                  </Dropdown>
                ) : (
                  <EditableText
                    value={tab.name}
                    onChange={handleUpdateTab}
                    onHide={() => setEditTab(0)}
                    name="name"
                    autoFocus
                  />
                )
              }
              key={tab.id}
            ></TabPane>
          ))}
      </Tabs>

      <Spin spinning={loading}>
        {tabs?.length > 0 ? (
          <>
            <div className="mb-md">
              {modelType == OPdashboardTabModelType.LtlShipment && (
                <LTLFilter
                  loading={loading}
                  initShow={initShowFilter}
                  setInitShow={setInitShowFilter}
                  activeFilters={activeTab?.filters || []}
                  onSearch={handleSearch}
                  onApply={handleApply}
                />
              )}

              {React.cloneElement(SubTabContent as React.ReactElement, {
                refresh: refresh,
                setRefresh: setRefresh,
              })}
            </div>
          </>
        ) : (
          <Empty
            description={'Click New Tab to start your OP Dashboard journey.'}
          ></Empty>
        )}
      </Spin>
    </>
  );
};

export default Index;
