import { FC, useEffect } from 'react';
import { Provider as StoreProvider } from 'mobx-react';
import * as stores from '@/stores';
import { ConfigProvider, message } from 'antd';
import 'res/css/global.scss';
import { includes } from 'lodash';
import UILayout from '@/components/UILayout/index';
import { IRouteComponentProps, useHistory } from 'umi';
import NewVersion from './version';
import { useApp } from '@/utils/useapp';
import MaintenanceAnnouncement from './maintenance-announcement';

const Layout: FC<IRouteComponentProps> = (props) => {
  const pathname = props.location.pathname;
  const loadUILayoutComponent = !includes(
    ['/user/login', '/user/reset'],
    pathname,
  );

  const app = useApp();
  const history = useHistory();

  useEffect(() => {
    if (!app.store.auth.user || pathname == '/user/login') {
      return;
    }

    if (
      IS_PROD &&
      app.store.auth.user &&
      !app.store.auth.enabled2Fa &&
      pathname != '/setting/2fa'
    ) {
      message.warning('You must enable 2FA to access all services.');
      return history.push('/setting/2fa');
    }
  }, [pathname]);

  return (
    <StoreProvider {...stores}>
      <ConfigProvider>
        <NewVersion />
        <MaintenanceAnnouncement
          beijingDateTime="2024/12/1 2PM"
          duration={{ hours: 2 }}
        />
        {loadUILayoutComponent ? (
          <UILayout>{props.children}</UILayout>
        ) : (
          props.children
        )}
      </ConfigProvider>
    </StoreProvider>
  );
};

export default Layout;
