import { useEffect, useState } from 'react';
import { Col, message, Row } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import DocumentUpload from './components/DocumentUpload';
import FilePreview from './components/FilePreview';
import RecognitionResults from './components/recognition-results';
import { useRecognition } from '@/pages/accounting/bill-ai-import/hooks/useRecognition';
import type {
  FileStatus,
  InvoiceType,
  RecognitionResult,
} from './interface/types';
import './styles.css';
import { useDocumentClassify } from './hooks/useDocumentClassify';
import { useShipmentData } from './hooks/useShipmentData';
import { TLType } from '@/components/constants';
import { useApp } from '@/utils/useapp';

interface FileInfo {
  url: string;
  type: string;
  content?: string;
  hash?: string;
}

// Calculate SHA-256 hash of the file
const calculateFileHash = async (file: File): Promise<string> => {
  const buffer = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

// Check if the file has been processed before and return processed info
const checkFileProcessed = async (
  hash: string,
): Promise<{ exists: boolean; result?: any }> => {
  const app = useApp();
  try {
    const result = await app.service.get('billingCode/ai/checkFile', {
      params: { hash },
    });
    return {
      exists: result?.data?.id > 0,
      result: result?.data,
    };
  } catch (error) {
    console.error('Failed to check file processing status:', error);
    return { exists: false };
  }
};

// Mark file as processed with recognition result
const markFileAsProcessed = async (
  hash: string,
  result: { invoice_number: string; invoice_type: string },
): Promise<boolean> => {
  const app = useApp();
  try {
    console.log('Marking file as processed:', hash, result);
    await app.service.post('billingCode/ai/markProcessed', {
      data: {
        hash,
        result,
      },
    });
    return true;
  } catch (error) {
    console.error('Failed to mark file as processed:', error);
    return false;
  }
};

const Index = () => {
  const [, setFileList] = useState<UploadFile[]>([]);
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [fileStatuses, setFileStatuses] = useState<FileStatus[]>([]);
  const [previewFiles, setPreviewFiles] = useState<Record<string, FileInfo>>(
    {},
  );
  const [processingCount, setProcessingCount] = useState(0);
  const MAX_CONCURRENT_PROCESSING = 3; // 最大并发处理数

  const { classifyDocument, classifyFeedback } = useDocumentClassify();
  const {
    recognitionResults,
    startRecognition,
    updateRecognitionResult,
    saveRecognitionResult,
  } = useRecognition();

  const selectedFile: FileStatus | null = selectedFileId
    ? fileStatuses.find((f) => f.fileId === selectedFileId) || null
    : null;

  const referenceNumber =
    selectedFileId && recognitionResults[selectedFileId]
      ? selectedFile?.invoiceType === TLType.LTL.toUpperCase() ||
        selectedFile?.invoiceType === TLType.FTL.toUpperCase()
        ? recognitionResults[selectedFileId].reference_number
        : recognitionResults[selectedFileId].container_number
      : undefined;

  const shipmentData = useShipmentData(
    selectedFile?.invoiceType,
    referenceNumber,
  );

  const handleUpload = async (file: File) => {
    // 如果当前正在处理的文件数量达到最大值,则将文件添加到队列但暂不处理
    const shouldProcess = processingCount < MAX_CONCURRENT_PROCESSING;
    const fileId = Date.now().toString();
    const previewUrl = URL.createObjectURL(file);

    try {
      // Calculate file hash
      const hash = await calculateFileHash(file);

      // Check if file has been processed
      const {
        exists: isProcessed,
        result: processedInfo,
      } = await checkFileProcessed(hash);

      // Read file content
      const fileContent = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });

      setPreviewFiles((prev) => ({
        ...prev,
        [fileId]: {
          url: previewUrl,
          type: file.type,
          content: fileContent as string,
          hash,
        },
      }));

      const newFileStatus: FileStatus = {
        fileId,
        fileName: file.name,
        uploadTime: new Date().toLocaleString(),
        status: isProcessed
          ? 'already_processed'
          : shouldProcess
          ? 'processing'
          : 'uploaded',
        hash,
        ...(isProcessed && processedInfo
          ? {
              result: processedInfo,
              documentType: processedInfo.invoice_type ? 'invoice' : undefined,
              invoiceType: processedInfo.invoice_type,
              billCreated: true,
            }
          : {}),
      };

      setFileStatuses((prev) => [...prev, newFileStatus]);
      setSelectedFileId(fileId);

      if (!isProcessed && shouldProcess) {
        await processFile(file, fileId);
      } else if (isProcessed) {
        message.info(`File ${file.name} has already been processed`);
      }
    } catch (error) {
      console.error('Failed to process file:', error);
      message.error('Failed to process file');
    }

    return false;
  };

  const processFile = async (file: File, fileId: string) => {
    setProcessingCount((count) => count + 1);

    try {
      const {
        file_type: documentType,
        invoice_type: invoiceType,
        original_text: originalText,
        record_id: recordId,
      } = await classifyDocument(file);

      setFileStatuses((prev) =>
        prev.map((fs) =>
          fs.fileId === fileId
            ? {
                ...fs,
                documentType,
                invoiceType,
                invoiceTypeRecordId: recordId,
                status:
                  documentType === 'invoice' && !invoiceType
                    ? 'uploaded'
                    : 'processing',
              }
            : fs,
        ),
      );

      if (documentType === 'invoice' && invoiceType) {
        const result = await startRecognition(
          file,
          fileId,
          invoiceType,
          originalText,
        );
        setFileStatuses((prev) =>
          prev.map((fs) =>
            fs.fileId === fileId
              ? {
                  ...fs,
                  status: 'completed',
                  result: result,
                }
              : fs,
          ),
        );
      }
    } catch (error) {
      setFileStatuses((prev) =>
        prev.map((fs) =>
          fs.fileId === fileId
            ? {
                ...fs,
                status: 'error',
                errorMessage: (error as Error)?.message ?? 'Unknown error',
              }
            : fs,
        ),
      );
    } finally {
      setProcessingCount((count) => count - 1);

      // 检查是否有等待处理的文件
      const waitingFile = fileStatuses.find(
        (fs) => fs.status === 'uploaded' && !fs.documentType,
      );
      if (waitingFile) {
        const file = previewFiles[waitingFile.fileId];
        if (file?.content) {
          const blobData = file.content.split(',')[1];
          const byteCharacters = atob(blobData);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: file.type });
          const newFile = new File([blob], waitingFile.fileName, {
            type: file.type,
          });
          processFile(newFile, waitingFile.fileId);
        }
      }
    }
  };

  const handleFileDelete = (fileId: string) => {
    setPreviewFiles((prev) => {
      const newFiles = { ...prev };
      URL.revokeObjectURL(newFiles[fileId].url);
      delete newFiles[fileId];
      return newFiles;
    });

    setFileStatuses((prev) => prev.filter((fs) => fs.fileId !== fileId));

    if (selectedFileId === fileId) {
      const remainingFiles = fileStatuses.filter((fs) => fs.fileId !== fileId);
      setSelectedFileId(
        remainingFiles.length > 0 ? remainingFiles[0].fileId : null,
      );
    }

    setFileList((prev) => prev.filter((file) => file.uid !== fileId));
  };

  const handleResultChange = (
    fileId: string,
    key: string,
    value: string | any[] | null,
  ) => {
    updateRecognitionResult(fileId, key, value);
  };

  const updateFileStatus = (fileId: string) => {
    setFileStatuses((prev) =>
      prev.map((fs) =>
        fs.fileId === fileId
          ? {
              ...fs,
              status: 'completed',
              billCreated: true,
              invoiceTypeFeedback: true,
            }
          : fs,
      ),
    );
  };

  const handleInvoiceTypeChange = async (fileId: string, type: InvoiceType) => {
    const currentFile = fileStatuses.find((f) => f.fileId === fileId);
    if (!currentFile) {
      return;
    }

    // 如果文件状态是 error 或者 completed，允许切换类型并重新识别
    if (currentFile.status === 'error' || currentFile.status === 'completed') {
      // 发送反馈，表明AI的分类结果不准确
      if (currentFile.invoiceTypeRecordId && !currentFile.invoiceTypeFeedback) {
        // 异步调用，不等待结果
        classifyFeedback(
          currentFile.invoiceTypeRecordId,
          false, // 不采纳
          {
            before: {
              invoice_type: currentFile.invoiceType,
            },
            after: {
              invoice_type: type,
            },
          }, // 修正后的结果
          `user changed invoice type from ${currentFile.invoiceType} to ${type}`,
        ).catch((error) => {
          console.error('Failed to send classification feedback:', error);
        });
      }

      setFileStatuses((prev) =>
        prev.map((fs) =>
          fs.fileId === fileId
            ? {
                ...fs,
                invoiceType: type,
                status: 'processing',
                billCreated: false, // 重置账单创建状态
                errorMessage: undefined,
                invoiceTypeFeedback: true,
              }
            : fs,
        ),
      );

      const file = previewFiles[fileId];
      if (file?.content) {
        const blobData = file.content.split(',')[1];
        const byteCharacters = atob(blobData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: file.type });
        const newFile = new File([blob], currentFile.fileName, {
          type: file.type,
        });

        try {
          const result = await startRecognition(newFile, fileId, type);
          setFileStatuses((prev) =>
            prev.map((fs) =>
              fs.fileId === fileId
                ? {
                    ...fs,
                    status: 'completed',
                    result: result,
                  }
                : fs,
            ),
          );
        } catch (error) {
          setFileStatuses((prev) =>
            prev.map((fs) =>
              fs.fileId === fileId
                ? {
                    ...fs,
                    status: 'error',
                    errorMessage:
                      (error as Error)?.message ?? 'Recognition failed',
                  }
                : fs,
            ),
          );
        }
      }
    } else {
      // 如果文件正在上传或其他状态，只更新类型
      setFileStatuses((prev) =>
        prev.map((fs) =>
          fs.fileId === fileId
            ? {
                ...fs,
                invoiceType: type,
              }
            : fs,
        ),
      );
    }
  };

  const handleSaveResult = async (result: RecognitionResult) => {
    if (selectedFileId) {
      const selectedFile = fileStatuses.find(
        (f) => f.fileId === selectedFileId,
      );
      const selectedFileHash = selectedFile?.hash;

      try {
        // 发送正面反馈，表明AI的分类结果被采纳
        if (
          selectedFile?.invoiceTypeRecordId &&
          !selectedFile.invoiceTypeFeedback &&
          selectedFile.invoiceType === result.invoice_type
        ) {
          // 异步调用，不等待结果
          classifyFeedback(
            selectedFile.invoiceTypeRecordId,
            true, // 采纳
            {},
            'user saved the recognition result, indicating the classification result is correct',
          ).catch((error) => {
            console.error('Failed to send classification feedback:', error);
          });
        }

        // Save recognition result
        await saveRecognitionResult(result);

        // Update file processing status
        if (selectedFileHash) {
          const marked = await markFileAsProcessed(selectedFileHash, {
            invoice_number: result.invoice_number,
            invoice_type: result.invoice_type,
          });

          if (!marked) {
            throw new Error('Failed to mark file as processed');
          }
        }

        updateFileStatus(selectedFileId);
        message.success('Successfully saved and marked as processed');
      } catch (error) {
        console.error('Failed to save recognition result:', error);
        message.error('Failed to save recognition result');
      }
    }
  };

  const handleRetry = async (fileId: string) => {
    const file = previewFiles[fileId];
    if (file?.content) {
      const blobData = file.content.split(',')[1];
      const byteCharacters = atob(blobData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: file.type });
      const newFile = new File(
        [blob],
        fileStatuses.find((f) => f.fileId === fileId)?.fileName || 'file',
        { type: file.type },
      );

      setFileStatuses((prev) =>
        prev.map((fs) =>
          fs.fileId === fileId
            ? {
                ...fs,
                status: 'processing',
                errorMessage: undefined,
              }
            : fs,
        ),
      );

      await processFile(newFile, fileId);
    }
  };

  useEffect(() => {
    return () => {
      Object.values(previewFiles).forEach((file) => {
        URL.revokeObjectURL(file.url);
      });
    };
  }, []);

  return (
    <div className="bill-import-container">
      <Row gutter={12}>
        <Col span={5}>
          <DocumentUpload
            fileStatuses={fileStatuses}
            selectedFileId={selectedFileId}
            onUpload={handleUpload}
            onFileSelect={setSelectedFileId}
            onFileDelete={handleFileDelete}
            onInvoiceTypeChange={handleInvoiceTypeChange}
            onRetry={handleRetry}
          />
        </Col>
        <Col span={11}>
          <FilePreview
            file={
              selectedFileId
                ? fileStatuses.find((f) => f.fileId === selectedFileId) || null
                : null
            }
            previewUrl={
              selectedFileId ? previewFiles[selectedFileId]?.url : undefined
            }
          />
        </Col>
        <Col span={8}>
          <RecognitionResults
            result={selectedFileId ? recognitionResults[selectedFileId] : null}
            onResultChange={(key, value) =>
              selectedFileId && handleResultChange(selectedFileId, key, value)
            }
            file={selectedFile}
            shipmentData={shipmentData}
            fileContent={
              selectedFileId ? previewFiles[selectedFileId]?.content : undefined
            }
            onSaveResult={handleSaveResult}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Index;
