export const DOCUMENT_FILE_TYPE_DO = 'DO';
export const DOCUMENT_FILE_TYPE_BOL = 'BOL';
export const DOCUMENT_FILE_TYPE_LABELS = 'Labels';
export const DOCUMENT_FILE_TYPE_DELIVERY_RECEIPT = 'Delivery Receipt';
export const DOCUMENT_FILE_TYPE_POD = 'POD';
export const DOCUMENT_FILE_TYPE_INSURANCE_CERTIFICATE = 'Insurance Certificate';
export const DOCUMENT_FILE_TYPE_CARRIER_INSPECTION_REPORT =
  'Carrier Inspection Report';
export const DOCUMENT_FILE_TYPE_OTHER = 'Other';

export const DOCUMENT_FILE_TYPES = [
  DOCUMENT_FILE_TYPE_DO,
  DOCUMENT_FILE_TYPE_BOL,
  DOCUMENT_FILE_TYPE_LABELS,
  DOCUMENT_FILE_TYPE_DELIVERY_RECEIPT,
  DOCUMENT_FILE_TYPE_POD,
  DOCUMENT_FILE_TYPE_INSURANCE_CERTIFICATE,
  DOCUMENT_FILE_TYPE_CARRIER_INSPECTION_REPORT,
];

export const CUSTOMER_VISIBLE = true;
export const CUSTOMER_INVISIBLE = false;

export const DOCUMENT_FILE_TYPES_DEFAULT_CUSTOMER_VISIBLE_MAP = {
  [DOCUMENT_FILE_TYPE_DO]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_BOL]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_LABELS]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_DELIVERY_RECEIPT]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_POD]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_INSURANCE_CERTIFICATE]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_CARRIER_INSPECTION_REPORT]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_OTHER]: CUSTOMER_VISIBLE,
};
