import { message } from 'antd';
import { ReactNode } from 'react';

export type ConfigDuration = number | (() => void);
export type ConfigOnClose = () => void;

export const showErrorMessage = (
  error: any,
  {
    duration,
    onClose,
    fallback = 'System Error',
  }: {
    duration?: ConfigDuration;
    onClose?: ConfigOnClose;
    fallback?: ReactNode;
  } = {},
) =>
  message.error(
    error?.data?.message ||
      error?.data?.error ||
      error?.message ||
      error?.error ||
      fallback,
    duration,
    onClose,
  );
