import { Button, Modal, Space, message } from 'antd';
import React from 'react';

import { useApp } from '@/utils/useapp';
import { isEmpty } from 'lodash';
import { showErrorMessage } from '@/utils/show-error-message';

interface IAddressConfirmModal {
  open: boolean;
  deliveryOrderId: number;
  address: any;
  onUpdated: (address: any) => void;
  onClose: () => void;
}

export const AddressConfirmModal: React.FC<IAddressConfirmModal> = ({
  open,
  deliveryOrderId,
  address,
  onUpdated,
  onClose,
}) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);

  const handleOnClose = () => {
    setLoading(false);
    onClose();
  };

  const handleConfirm = async () => {
    if (!deliveryOrderId || isEmpty(address)) {
      return false;
    }

    setLoading(true);
    try {
      const resp = await app.service.patch(
        `deliveryOrders/${deliveryOrderId}/updateAddress`,
        {
          data: address,
        },
      );

      message.success('Updated');
      onUpdated(address);
    } catch (err: any) {
      showErrorMessage(err, { fallback: 'Please check your form' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title=""
      destroyOnClose
      cancelText="Close"
      zIndex={3000}
      footer={
        <Space>
          <Button disabled={loading} onClick={handleOnClose}>
            No
          </Button>
          <Button type="primary" disabled={loading} onClick={handleConfirm}>
            Confirm
          </Button>
        </Space>
      }
      open={open}
      onCancel={handleOnClose}
    >
      <p>
        {address.pickup_address && 'The terminal address has been changed.'}
      </p>
      <p>
        {address.original_pickup_address &&
          `Current Address: ${address.original_pickup_address}`}
      </p>
      <p>
        {address.pickup_address && `New Address: ${address.pickup_address}`}
      </p>
      <p>
        {address.delivery_address && 'The warehouse address has been changed.'}
      </p>
      <p>
        {address.original_delivery_address &&
          `Current Address: ${address.original_delivery_address}`}
      </p>
      <p>
        {address.delivery_address && `New Address: ${address.delivery_address}`}
      </p>
      <p>
        The address will be updated when you click the {"'Confirm'"} button.
      </p>
    </Modal>
  );
};
