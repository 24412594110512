import React, { useEffect, useState } from 'react';
import { TItemProps, TOnDoneProps, TContainerProps } from '.';
import { RenderTaskColumn } from './RenderTaskColumn';
import { RenderActions } from './RenderActions';
import useActionTasks from './useActionTasks';
import { useApp } from '@/utils/useapp';
import { message, List, Space } from 'antd';
import { showErrorMessage } from '@/utils/show-error-message';

export const SHORTCUT_DISPATCH_MEMO = 'SHORTCUT_DISPATCH_MEMO';

export const RenderTaskShortCut: React.FC<
  { shortCutType: string } & TContainerProps & TOnDoneProps
> = ({ shortCutType, container, onDone }) => {
  const app = useApp();

  const { updateContainerTask } = useActionTasks();

  const [tasks, setTasks] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    if (!container.id || !shortCutType) {
      return null;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(
        `containers/${container.id}/shortCutTasks`,
        {
          params: {
            short_cut_type: shortCutType,
          },
        },
      );
      setTasks(resp.data);
    } catch (error: any) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };
  const handleOnCheck = async (data: { [key: string]: any }) => {
    if (!container.id) {
      return;
    }

    await updateContainerTask(container.id, data, afterUpdated);
  };

  const afterUpdated = () => {
    fetchData();
    onDone && onDone();
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (tasks) {
    return (
      <>
        <List
          pagination={false}
          size="small"
          dataSource={tasks}
          renderItem={(task) => (
            <List.Item>
              <Space>
                <RenderTaskColumn
                  item={task}
                  container={container}
                  showLog={false}
                />
                <RenderActions
                  taskType={task.task_type}
                  item={task}
                  container={container}
                  onCheck={handleOnCheck}
                  onDone={onDone}
                />
              </Space>
            </List.Item>
          )}
        />
      </>
    );
  } else {
    return <></>;
  }
};
