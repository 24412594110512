import React from 'react';
import { Button, message, Drawer, Space, Modal } from 'antd';
import { useApp } from '@/utils/useapp';
import { TBill } from '@/types';
import { useForm } from 'antd/lib/form/Form';
import PaymentForm from './PaymentForm';
import { uniq } from 'lodash';
import getCanceledBillables from '@/utils/getCanceledBillables';
import { showErrorMessage } from '@/utils/show-error-message';

interface Props {
  onSaved: () => void;
  bills: TBill[];
}

const Payment: React.FC<Props> = ({ onSaved, bills = [] }) => {
  const app = useApp();

  const [visible, setVisible] = React.useState(false);
  const [form] = useForm();
  const [submitting, setSubmitting] = React.useState(false);

  const showDrawer = () => {
    if (bills.find((b) => !b.is_approved)) {
      return message.error('There has unapproved bill');
    }

    if (bills.length === 0) {
      return message.error('Please select at least one bill');
    }

    const cancelOrderUid = getCanceledOrderUids(bills);

    if (cancelOrderUid?.length > 0) {
      Modal.confirm({
        title: 'Warning',
        content: `Include cancel order, Bill might be wrong. Please check with OP. Cancel Order#: ${cancelOrderUid.join(
          ', ',
        )}`,
        onOk: () => {
          form.resetFields();
          setVisible(true);
        },
        okText: 'Continue',
        cancelText: 'Cancel',
        maskClosable: true,
      });
    } else {
      form.resetFields();
      setVisible(true);
    }
  };

  const getCanceledOrderUids = (_bills: TBill[]) => {
    const canceledBillables = getCanceledBillables(_bills);
    if (canceledBillables?.length > 0) {
      return canceledBillables?.map((b) => b.uid);
    } else {
      return [];
    }
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleSave = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setSubmitting(true);

    try {
      !values.id
        ? await app.service.post('orderBillPayments', { data: values })
        : await app.service.put(`orderBillPayments/${values.id}`, {
            data: values,
          });
      message.success('Saved');

      onSaved && onSaved();
      onClose();
    } catch (e: any) {
      showErrorMessage(e);
    }

    setSubmitting(false);
  };

  const toSave = () => {
    const canceledBillables = getCanceledBillables(bills);

    if (canceledBillables?.length > 0) {
      const orderInvoiceNumbers = uniq(
        canceledBillables.map((b) => b.invoice_number),
      );

      Modal.confirm({
        title: 'Warning',
        content: `Include cancel order, Bill might be wrong, please check with OP. Cancel Order INV#: ${orderInvoiceNumbers.join(
          ', ',
        )}`,
        onOk: handleSave,
        okText: 'Continue',
        cancelText: 'Cancel',
        maskClosable: true,
      });
    } else {
      handleSave();
    }
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        Make Payment
      </Button>
      <Drawer
        width={'80%'}
        title="Receive Payment"
        placement="right"
        onClose={onClose}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type="primary" loading={submitting} onClick={toSave}>
              Save
            </Button>
          </Space>
        }
      >
        <PaymentForm form={form} bills={bills} />
      </Drawer>
    </>
  );
};

export default Payment;
