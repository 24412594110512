import { TAccount } from '@/types';

import { useApp } from '@/utils/useapp';
import { useState } from 'react';
import { Button, Switch, message, Form, Modal, Card } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { chain, get, trim, has, uniq } from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import EmailForm from '@/components/EmailForm';
import { showErrorMessage } from '@/utils/show-error-message';

interface ICancelDOModal {
  DOId: number;
  deliveryOrder: any;
  admin: TAccount | null;
  open: boolean;
  onConfirmed: () => void;
  onCancel: () => void;
}

export const CancelDOModal: React.FC<ICancelDOModal> = ({
  DOId,
  deliveryOrder,
  admin,
  open = false,
  onConfirmed,
  onCancel,
}) => {
  const app = useApp();
  const [form] = useForm();
  const [emailForm] = useForm();
  const [loading, setLoading] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);

  const handleConfirm = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setLoading(true);

    const data: Record<string, any> = {
      reason: get(values, 'reason'),
      isSendCancelEmail: get(values, 'isSendCancelEmail', false),
    };

    if (showEmailForm) {
      const cancelEmail = await emailForm.validateFields();
      data.sendToList = get(cancelEmail, 'sendToList');
      data.ccList = get(cancelEmail, 'ccList');
      data.subject = get(cancelEmail, 'subject');
      data.body = get(cancelEmail, 'body');
    }

    try {
      await app.service.put(`deliveryOrders/${DOId}/cancel`, { data });

      message.success('Canceled');
      emailForm.resetFields();

      onConfirmed();
    } catch (err: any) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    form.resetFields();
    emailForm.resetFields();
    setShowEmailForm(false);
    onCancel();
  };

  const handleIsShowEmailForm = async (checked: any) => {
    if (checked) {
      const containers = chain(get(deliveryOrder, 'containers', []))
        .map((container) => container.number)
        .value()
        .join(',');
      const orderId = get(deliveryOrder, 'order.uid');
      const ir = get(deliveryOrder, 'final_port.name');
      const warehouseCity = get(deliveryOrder, 'warehouse.city.full_name');
      const zipcode = get(deliveryOrder, 'warehouse.zipcode', '');
      const mbl = get(deliveryOrder, 'mbl_number');
      const eta =
        get(deliveryOrder, 'final_port_eta') ||
        get(deliveryOrder, 'port_of_discharge_eta');

      let pathInfoSubject = '';
      ir && (pathInfoSubject += ` ${ir}`);
      warehouseCity && (pathInfoSubject += ` to ${warehouseCity}`);
      zipcode && (pathInfoSubject += ` ${zipcode}`);

      emailForm.setFieldsValue({
        sendToList: [deliveryOrder.vendor.email],
        ccList: [admin?.email],
        subject: `Order Cancelled: ${orderId} / ${containers} / ${pathInfoSubject} / ETA ${eta}`,
        body: toCancelDOBody(),
      });
    }

    setShowEmailForm(checked);
  };

  const toCancelDOBody = () => {
    const orderId = get(deliveryOrder, 'order.uid');

    const mbl = get(deliveryOrder, 'mbl_number');
    const containers = chain(get(deliveryOrder, 'containers', []))
      .map((container) => container.number)
      .value()
      .join(',');
    return trim(`
Hi Dispatch,

Please be kindly informed that the following is cancelled.

Order ID: ${orderId}
MBL: ${mbl}
Container #: ${containers}

Feel free to reply this email if you have any questions.

DrayEasy, Inc.
800 N Haven Ave #210,
Ontario, CA 91764
Phone: 1-818-826-2850
E-mail: doc@drayeasy.com
          `);
  };

  return (
    <Modal
      zIndex={3000}
      width={'50%'}
      cancelText="Close"
      footer={
        <>
          <Button danger onClick={handleConfirm}>
            Confirm
          </Button>
        </>
      }
      title="Reason"
      open={open}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label=""
          name="reason"
          rules={[{ required: true, message: 'Reason is required' }]}
        >
          <TextArea rows={6}></TextArea>
        </Form.Item>
        <Form.Item
          label="Send Cancel Email to Vendor"
          name="isSendCancelEmail"
          valuePropName="checked"
        >
          <Switch onChange={handleIsShowEmailForm} />
        </Form.Item>
      </Form>
      {showEmailForm && (
        <Card style={{ maxHeight: '45vh', overflowY: 'scroll' }}>
          <Form form={emailForm} layout="vertical">
            <EmailForm
              form={emailForm}
              has={{ from: false, attachment: false }}
              bodyExtra={
                <>
                  {/* <div>
                    <Button
                      size="small"
                      type="link"
                      onClick={() =>
                        emailForm.setFieldsValue({ body: toCancelDOBody() })
                      }>
                      Cancel Do Body
                    </Button>
                  </div> */}
                </>
              }
            />
          </Form>
        </Card>
      )}
    </Modal>
  );
};
