import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  message,
  Space,
  TablePaginationConfig,
  Form,
  Row,
  Col,
  Input,
  Tag,
  Select,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { CompanyDrawer } from './components/CompanyDrawer';
import { useForm } from 'antd/lib/form/Form';
import { TCollection } from '@/types';
import AutoResizeTable from '@/components/AutoResizeTable';
import { MemoAction } from '@/components/Memo/MemoAction';
import {
  MEMO_ATTRIBUTE_COMPANY_ACCOUNTING,
  TARGET_COMPANY,
} from '@/components/Memo/data';
import usePagination from '@/components/usePagination';
import { TIER_MAP } from '@/pages/entity/intermodalRegions/components/data';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { showErrorMessage } from '@/utils/show-error-message';

interface TCompany {
  id: number;
  name: string;
  address: string;
  billing: string;
  contacts: any[];
}

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TCompany>>();
  const [currentCompany, setCurrentCompany] = React.useState<
    TCompany | undefined
  >();

  const pagination = usePagination(data);

  const [form] = useForm();
  const [filter] = useForm();

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('companies/list', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 50,
          },
        });

        setData(result);
      } catch (e: any) {
        showErrorMessage(e);
      }

      setLoading(false);
    },
    [],
  );

  const refreshCompany = React.useCallback(
    async (id: number) => {
      if (!data) {
        return;
      }
      const _company = await app.service.get(`companies/${id}`);
      const collection = data.data.map((item) => {
        if (item.id === _company.data.id) {
          return _company.data;
        }
        return item;
      });
      setCurrentCompany(_company.data);
      setData({ ...data, data: collection });
    },
    [data, setData],
  );

  const addNew = () => {
    form.resetFields();
    setVisible(true);
  };

  const handleSavedCompany = (data: any) => {
    fetchData();
    handleCloseCompanyDrawer();
  };

  const handleCloseCompanyDrawer = () => {
    setVisible(false);
    setCurrentCompany(undefined);
  };

  const handleEdit = async (company: TCompany) => {
    setCurrentCompany(company);
    setVisible(true);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 32,
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: 80,
      },
      {
        title: 'English Name',
        dataIndex: 'name',
        key: 'name',
        width: 160,
        ellipsis: true,
      },
      {
        title: 'Legal Name',
        dataIndex: 'legal_name',
        key: 'legal_name',
        width: 160,
        ellipsis: true,
      },
      {
        title: 'Terms',
        dataIndex: 'terms',
        key: 'terms',
        width: 48,
      },
      {
        title: 'CNTR Count',
        dataIndex: 'containers_count',
        key: 'containers_count',
        width: 82,
        sorter: (a, b) => a.containers_count - b.containers_count,
      },
      {
        title: 'Tier',
        dataIndex: 'tier_rate',
        key: 'tier_rate',
        width: 100,
        render: (value, record) => {
          return <>{TIER_MAP[(value as unknown) as keyof typeof TIER_MAP]}</>;
        },
      },
      {
        title: 'Agent',
        dataIndex: 'agent',
        key: 'agent',
        width: 100,
        eclipse: true,
        render: (value, record) => {
          return <>{value?.name || '-'}</>;
        },
      },
      {
        title: 'Contract',
        dataIndex: 'has_contract',
        key: 'has_contract',
        width: 48,
        render: (text, record) => {
          return record.has_contract ? (
            <Tag color="success">Yes</Tag>
          ) : (
            <Tag color="error">No</Tag>
          );
        },
        sorter: (a, b) => a.has_contract - b.has_contract,
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        width: 160,
        ellipsis: true,
      },
      {
        title: 'Act Memo',
        dataIndex: 'accounting_memo',
        key: 'accounting_memo',
        width: 48,
        render: (text, record) => (
          <MemoAction
            initialValue={record.accounting_memo}
            targetId={record.id}
            target={TARGET_COMPANY}
            memoAttribute={MEMO_ATTRIBUTE_COMPANY_ACCOUNTING}
            onSaved={(id: number) => {
              refreshCompany(id);
            }}
          />
        ),
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 48,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
          </Space>
        ),
      },
    ],
    [data],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Companies"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New Company
            </Button>
          </div>
        }
      ></Header>
      <div
        style={{
          width: '60%',
          padding: '10px',
        }}
      >
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="search" label="Search">
                <Input placeholder="English Name or Legal Name or Code" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="sales_id" label="Sales">
                <UserSyncSelect type="admin" allowClear />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="has_contract" label="Contract">
                <Select allowClear>
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      filter.resetFields();
                      fetchData();
                    }}
                  >
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        // pagination={false}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 1500,
        }}
      />

      {visible && (
        <CompanyDrawer
          id={currentCompany?.id}
          open={visible}
          onClose={handleCloseCompanyDrawer}
          onSaved={handleSavedCompany}
        />
      )}
    </div>
  );
};

export default Index;
